import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {MonitoringDetailsService} from '../../monitoring-details.service';
import {Subject} from 'rxjs/internal/Subject';
import {PointOptionsType} from 'highcharts';
import {MetricType} from '../../../../../models-monitoring';
import {ChartPoint} from '../../../../../models';
import {NetworkUsageService} from './network-usage.service';

@Component({
    selector: 'app-network-usage',
    templateUrl: './network-usage.component.html',
    styleUrls: ['./network-usage.component.scss']
})
export class NetworkUsageComponent implements OnChanges, OnDestroy {

    @Input()
    serverId: number;

    @Input()
    metricType: MetricType;

    @Input()
    begin: Date;

    @Input()
    end: Date;

    @Input()
    resolution: number;

    @Input()
    baseServerId: number;

    dataSubject = new Subject<PointOptionsType[]>();

    chartPointList: ChartPoint[] = [];

    constructor(private service: NetworkUsageService,
                private monitoringDetailsService: MonitoringDetailsService) {
        this.monitoringDetailsService.changePath('monitoring-details', 'network');
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.chartPointList = [];
        this.service.loadFromDbAndWebSocket(this.begin, this.end, this.metricType,  this.serverId, this.baseServerId, this.resolution, this.chartPointList, this.dataSubject);
    }

    ngOnDestroy(): void {
        this.chartPointList = [];
        this.dataSubject = new Subject<PointOptionsType[]>();
        this.service.unSubscribe();
    }
}

import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {BaseServer, ServerType} from '../../../../../models';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {VpnMonitoringConfigurationDaoService} from './vpn-monitoring-configuration-dao.service';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {validIpv4OrIpv6OfFormArrayOfFormGroupValidator} from '../../../../../validators/ipv4-or-ipv6-of-form-array-of-form-group-validator';
import {uniqueFieldValueOfFormArrayOfFormGroupValidator} from '../../../../../validators/unique-field-value-of-form-array-of-form-group-validator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {sortListByName} from '../../../../../helpers/kluh';
import {VpnMonitoringConfiguration} from '../../../../../models-monitoring';

@Component({
    selector: 'app-vpn-monitoring-configuration',
    templateUrl: './vpn-monitoring-configuration.component.html',
    styleUrls: ['./vpn-monitoring-configuration.component.scss'],
    queries: {
        'vpnContentRef': new ViewChild('vpnContentRef', {static: false})
    },
})
export class VpnMonitoringConfigurationComponent extends ComponentCleaner {
    // desktopServer: DesktopServer;
    // linuxServer: LinuxServer;
    baseServer: BaseServer;
    // serverId: number;
    serverType: ServerType;
    myForm: FormGroup;
    myFormArray: FormArray;
    vpnContentRef!: ElementRef;
    myFormControls: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<VpnMonitoringConfigurationComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private vpnMonitoringConfigurationDaoService: VpnMonitoringConfigurationDaoService
    ) {
        super();
        this.baseServer = data.baseServer;
        this.serverType = data.serverType;
        this.myFormArray = this.fb.array([]);
        this.myForm = this.fb.group({myFormArray: this.myFormArray});

        this.myForm.setValidators([
            validIpv4OrIpv6OfFormArrayOfFormGroupValidator({clientVpnIp: 'IP ou DNS Inválido'}, 'myFormArray'),
            uniqueFieldValueOfFormArrayOfFormGroupValidator({clientVpnIp: 'IP ou DNS Duplicado'}, 'myFormArray')
        ]);
        this.addVpnMonitoringConfigurationListInPageByServerId(this.baseServer.id, this.serverType);
        this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;

    }


    buildVpn(vpn?: VpnMonitoringConfiguration): FormGroup {
        let vpnObj = {
            id: null, name: null, active: true, createdAt: null, updatedAt: null,
            serverId: this.baseServer.id, serverType: this.serverType, clientVpnIp: '',
            comment: null
        };
        if (vpn) {
            vpnObj = vpn;
        }
        return this.fb.group(vpnObj);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onAddVpnMonitoringConfiguration(vpn?: VpnMonitoringConfiguration): void {
        this.myFormArray.push(this.buildVpn(vpn));
        setTimeout(() => {
            this.vpnContentRef.nativeElement.scrollTo(0, this.vpnContentRef.nativeElement.scrollHeight);
        }, 50);
    }

    onDelete(vpnInput: VpnMonitoringConfiguration, indexFormArray: number): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover essa VNP do monitoramento? <br>' +
                    '<h2 class="warn-A700-fg">' + vpnInput.name +
                    '<br>' + vpnInput.clientVpnIp + '</h2>' +
                    '<br>' + vpnInput.id + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                if (vpnInput?.id) {
                    this.vpnMonitoringConfigurationDaoService.remove(vpnInput.id).subscribe((vpn) => {
                        this.dialogRef.close(vpn);
                    });
                } else {
                    this.myFormArray.removeAt(indexFormArray);
                }
            }
        });
        this.addSubscription(subscription);

    }

    onSubmit(): void {
        const vpnList = this.myForm.value.myFormArray;
        const vpnCreateList = [];
        const vpnSaveList = [];

        // console.log(vpnList);
        for (const vpn of vpnList) {
            if (vpn.id) {
                vpnSaveList.push(vpn);
            } else {
                vpnCreateList.push(vpn);
            }
        }
        let vpnMonitoringSave$: Observable<VpnMonitoringConfiguration[]>;
        if (!vpnSaveList || vpnSaveList.length < 1) {
            vpnMonitoringSave$ = of([]);
        } else {
            vpnMonitoringSave$ = this.vpnMonitoringConfigurationDaoService.saveAll(vpnSaveList);
        }
        let vpnMonitoringCreate$: Observable<VpnMonitoringConfiguration[]>;
        if (!vpnCreateList || vpnCreateList.length < 1) {
            vpnMonitoringCreate$ = of([]);
        } else {
            vpnMonitoringCreate$ = this.vpnMonitoringConfigurationDaoService.createAll(vpnCreateList);
        }
        forkJoin([vpnMonitoringCreate$, vpnMonitoringSave$]).subscribe((vpnArray) => {
            const allVpn = [];
            const vpnCreatedList = vpnArray[0];
            const vpnSavedList = vpnArray[1];
            if (vpnCreatedList) {
                for (const vpn of vpnCreatedList) {
                    allVpn.push(vpn);
                }
            }
            if (vpnSavedList) {
                for (const vpn of vpnSavedList) {
                    allVpn.push(vpn);
                }
            }
            this.dialogRef.close(allVpn);
        });
    }

    addVpnMonitoringConfigurationListInPageByServerId(serverId: number, serverType: ServerType): void {
        this.vpnMonitoringConfigurationDaoService.findAllByServerIdAndServerType(serverId, serverType).subscribe(vpnMonitoringConfigurationList => {
            if (vpnMonitoringConfigurationList) {
                for (const vpn of sortListByName(vpnMonitoringConfigurationList)) {
                    this.onAddVpnMonitoringConfiguration(vpn);
                }
            } else {
                this.onAddVpnMonitoringConfiguration();
            }
        });
    }


    getControlsOfFormGroup(vpn: AbstractControl): FormGroup {
        return vpn as FormGroup;
    }
}

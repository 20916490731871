import {Component, Inject} from '@angular/core';
import {ADUserPool, ADUserPoolInfo} from '../../../../models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AdUserPoolDaoService} from '../../ad-user-pool-dao.service';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {AdAdminDaoService} from '../../../ad-admin/ad-admin.dao.service';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../../component-cleaner';
import {EntityHistoryComponent} from '../../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-ad-user-pool-info',
    templateUrl: './ad-user-pool-info.component.html',
    styleUrls: ['./ad-user-pool-info.component.scss']
})
export class AdUserPoolInfoComponent extends ComponentCleaner {
    adUserPool: ADUserPool;
    adUserPoolInfoList: ADUserPoolInfo[] = [];
    appCanViewPasswordList: PermissionWrapper[];
    adUserPw = '';

    constructor(
        private dialogRef: MatDialogRef<AdUserPoolInfoComponent>,
        private adUserPoolDaoService: AdUserPoolDaoService,
        private customerGroupService: CustomerGroupService,
        private adminService: R2CloudAdminService,
        private adAdminDaoService: AdAdminDaoService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {
        super();
        this.appCanViewPasswordList = [
            {type: 'ADUserPool', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUserPool', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
        this.adUserPool = data.adUserPool;
        if (this.adUserPool?.id && this.adUserPool?.login) {
            this.adUserPoolDaoService.findAllADUserPoolInfo(this.adUserPool?.id, this.adUserPool?.login).subscribe((adUserPoolInfoList) => {
                if (adUserPoolInfoList) {
                    this.adUserPoolInfoList = adUserPoolInfoList;
                }
            });
        }

    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onSetRandomPassword(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo gerar uma senha aleatória para esse usuário pool (<b><u>' + this.adUserPool.login + '</u></b>) do dominio?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adUserPoolDaoService.setRandomPassword(this.adUserPool.id).subscribe(() => {
                    this.dialogRef.close(this.adUserPool);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onSetLegacyRandomPassword(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo gerar uma senha <b><u>LEGADA</u></b> aleatória para esse usuário pool (<b><u>' + this.adUserPool.login + '</u></b>) do dominio?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adUserPoolDaoService.setLegacyRandomPassword(this.adUserPool.id).subscribe(() => {
                    this.dialogRef.close(this.adUserPool);
                });
            }
        });
        this.addSubscription(subscription);
    }

    findByLoginPw(adUserPool: ADUserPool): void {
        this.adUserPoolDaoService.findByLoginPw(adUserPool.login, adUserPool.adDomainId).subscribe((adUserPw) => {
            this.adUserPw = adUserPw.adUserPassword;
        });
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.adUserPool.id, this.adUserPoolDaoService, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.adUserPool.id, this.adUserPoolDaoService, this.dialog);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {AdAdminDaoService} from './ad-admin.dao.service';
import {OpenModalAdAdminComponent} from './open-modal-ad-admin/open-modal-ad-admin.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        KluhSharedModule,
        NgxMaskModule.forRoot(),
        MatToolbarModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule
    ],
    providers: [AdAdminDaoService],
    declarations: [OpenModalAdAdminComponent],
    entryComponents: [OpenModalAdAdminComponent]
})
export class AdAdminModule {
}

<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">R2 Security</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
         fxLayoutAlign="space-between stretch" *appIfPermission="canEditList; else cannotAccess">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-8" matDialogContent>

            <mat-form-field appearance="outline" class="mt-30">
                <mat-label>Projeto onde está o R2 Security Server</mat-label>
                <mat-select placeholder="Selecione o Projeto onde está o R2 Security Server"
                            [formControl]="customerGroupForm">
                    <mat-option *ngFor="let customerGroup of customerGroups"
                                [value]="customerGroup.id">
                        {{ customerGroup.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>R2 Security Server</mat-label>
                <mat-select placeholder="Selecione o R2 Security Server" [formControl]="gatewayServerForm">
                    <mat-option *ngFor="let gatewayServer of gatewayServers"
                                [value]="gatewayServer.id">
                        {{ gatewayServer.name }} | {{ gatewayServer.fqdn }}:{{ gatewayServer.port }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start start">

                <mat-form-field appearance="outline" *ngIf="ServerConnectionType" style="width: 340px;">
                    <mat-label>Server Connection Type</mat-label>
                    <mat-select placeholder="Escolha o tipo de conexão" formControlName="serverConnectionType">
                        <mat-option [value]="ServerConnectionType?.PUBLIC_IP">
                            {{ ServerConnectionType?.PUBLIC_IP }}
                        </mat-option>
                        <mat-option [value]="ServerConnectionType?.PRIVATE_IP">
                            {{ ServerConnectionType?.PRIVATE_IP }}
                        </mat-option>
                        <mat-option [value]="ServerConnectionType?.CONNECTION_FQDN">
                            {{ ServerConnectionType?.CONNECTION_FQDN }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 300px;" class="mb-10 ml-25" [hidden]="!activateCustomPortControl.value">
                    <mat-label>Porta NAT customizada</mat-label>
                    <input matInput formControlName="serverConnectionCustomPort" pattern="[0-9]*" max="50000" min="1">
                    <mat-error *ngIf="myForm.get('serverConnectionCustomPort')?.errors?.pattern">
                        Por favor, insira apenas números.
                    </mat-error>
                    <mat-error *ngIf="myForm.get('serverConnectionCustomPort')?.errors?.required">
                        Este campo é obrigatório.
                    </mat-error>
                    <mat-error
                        *ngIf="myForm.get('serverConnectionCustomPort')?.errors?.max || myForm.get('serverConnectionCustomPort')?.errors?.min">
                        Por favor, insira um número entre 1 e 50000.
                    </mat-error>
                    <mat-error *ngIf="myForm.errors">
                        {{ myForm.errors | error }}
                    </mat-error>
                </mat-form-field>

                <mat-slide-toggle [formControl]="activateCustomPortControl" class="mt-20 ml-20">
                    Usar porta customizada
                </mat-slide-toggle>

            </div>

            <div fxLayout="row" fxLayoutAlign="start start">

                <mat-form-field appearance="outline" style="width: 340px;" class="mb-10" *ngIf="gatewayServerForm.value">
                    <mat-label>Porta de destino do R2 Security</mat-label>
                    <input matInput formControlName="destinationPort" placeholder="Porta de destino de 4000 a 6000"
                           pattern="[0-9]*" max="6000" min="4000">
                    <mat-error *ngIf="myForm.get('destinationPort')?.errors?.pattern">
                        Por favor, insira apenas números.
                    </mat-error>
                    <mat-error *ngIf="myForm.get('destinationPort')?.errors?.required">
                        Este campo é obrigatório.
                    </mat-error>
                    <mat-error
                        *ngIf="myForm.get('destinationPort')?.errors?.max || myForm.get('destinationPort')?.errors?.min">
                        Por favor, insira um número entre 4000 e 6000.
                    </mat-error>
                    <mat-error *ngIf="myForm.errors">
                        {{ myForm.errors | error }}
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline" *ngIf="gatewayServerForm.value" style="width:100px;" class="ml-20">
                    <mat-label>Protocolo</mat-label>
                    <mat-select placeholder="Escolha o protocolo" formControlName="protocol">
                        <mat-option [value]="'tcp'">TCP</mat-option>
                        <mat-option [value]="'udp'">UDP</mat-option>
                        <mat-option [value]="'tcp/udp'">TCP/UDP</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>




            <mat-slide-toggle formControlName="openAccess" name="openAccess" class="mt-10 mb-40">
                Acesso Liberado para todos
            </mat-slide-toggle>



        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <div matTooltip="Salvar">
                <button type="submit" mat-raised-button color="accent" aria-label="Salvar"
                        [disabled]="!myForm.valid || !myForm.dirty || sending || !isValidCustomPort()">
                    <span *ngIf="gatewayServerNatConfig?.id;else newUser">Salvar</span>
                    <ng-template #newUser>Criar</ng-template>
                </button>
            </div>

            <div *ngIf="gatewayServerNatConfig?.id">
                <button type="button" mat-raised-button color="accent" (click)="onResend()" [disabled]="sending">
                    Reenviar Configuração
                </button>
            </div>

            <div *ngIf="gatewayServerNatConfig?.id">
                <button type="button" mat-raised-button color="warn" (click)="onRemove()">Remover</button>
            </div>

            <div *ngIf="gatewayServerNatConfig?.id">
                <button type="button" mat-raised-button color="warn" (click)="onForceRemove()">Delete Forçado</button>
            </div>

            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
            </div>
        </div>
    </div>
    <ng-template #cannotAccess>
        <div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
             fxLayoutAlign="space-between stretch">
            <h2 class="text-align-center width-100-percent">
                Sem permissão
            </h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </ng-template>
</form>
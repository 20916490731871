import {Component, OnInit} from '@angular/core';
import {ADDomain} from '../../../models';
import {AdDomainWizardComponent} from '../../ad-domain/ad-domain-wizard/ad-domain-wizard.component';
import {filter} from 'rxjs/operators';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {AdDomainCreateComponent} from '../../ad-domain/ad-domain-create/ad-domain-create.component';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';

@Component({
    selector: 'app-r2-cloud-admin-addomain',
    templateUrl: './r2-cloud-admin-addomain.component.html',
    styleUrls: ['./r2-cloud-admin-addomain.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminADDomainComponent extends ComponentCleaner implements OnInit {
    appCanCreateList: PermissionWrapper[];
    showHint: boolean;
    canAccess = false;

    constructor(
        private adDomainDao: AdDomainDaoService,
        private desktopServerDao: DesktopServerDaoService,
        public customerGroupService: CustomerGroupService,
        private userAuthorityDao: UserAuthorityDaoService,
        public adminService: R2CloudAdminService,
        private dialog: MatDialog
    ) {
        super();
        setTimeout(() => {
            this.showHint = true;
        }, 2000);
        this.appCanCreateList = [
            {type: 'ADDomain', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADDomain', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
/*
        this.userAuthorityDao.hasParentPermission('CustomerGroup', this.customerGroupService.customerGroup.id, 'ADDomain', 'CREATE').subscribe(userHasPermissionADUser => {
            if (userHasPermissionADUser && userHasPermissionADUser.value) {
                this.canAccess = true;
            } else {
                this.userAuthorityDao.hasParentPermission('SubProject', this.adminService.subProject.id, 'ADDomain', 'CREATE').subscribe(userHasPermission => {
                    if (userHasPermission && userHasPermission.value) {
                        this.canAccess = true;
                    }
                });
            }
        });
        */
    }

    ngOnInit(): void {

    }

    onCreateAdDomain(): void {
        // const dialogRef = this.dialog.open(AdDomainCreateComponent, {
        this.adDomainDao.get().subscribe((result) => {
            const dialogRef = this.dialog.open(AdDomainWizardComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog',
                data: {
                    adDomain: null,
                    adDomains: result,
                    subProject: this.adminService.subProject,
                    customerGroup: this.customerGroupService.customerGroup
                }
            });
            const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((crudOperation: CrudOperationWrapper) => {
                if (crudOperation) {
                    const adDomain: ADDomain = crudOperation.data;
                    if (adDomain) {
                        this.adminService.addADDomain(adDomain);
                    }
                }
            });
            this.addSubscription(subscription);
        });
    }


    onEditAdDomain(adDomain: ADDomain): void {
        const dialogRef = this.dialog.open(AdDomainCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomain: adDomain,
                desktopServers: this.adminService.desktopServers,
                subProject: this.adminService.subProject
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((adDomainFromDAO) => {
            if (adDomainFromDAO) {

                this.adminService.replaceADDomain(adDomainFromDAO);

                this.desktopServerDao.filter({'subProjectId': this.adminService.subProject.id}).subscribe((desktopServers) => {
                    this.adminService.desktopServers = desktopServers;
                });

            }

        });
        this.addSubscription(subscription);
    }


}

<div id="desktop-server-list" fxLayout="column" fxLayoutAlign="start stretch">
  <button type="button" class="mb-8" mat-raised-button (click)="onDesktopServerCreate()">Criar</button>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let desktopServer of desktopServers">
    <div>
      {{getAdDomain(desktopServer.adDomainId)?.domainName}}
    </div>
    <div>
      {{desktopServer.hostname}}
    </div>
    <div>
      {{desktopServer.clientId}}
    </div>
    <div>
      {{desktopServer.publicIp}}
    </div>
    <div>
      {{desktopServer.privateIp}}
    </div>
    <div>
      <span *ngIf="getDesktopServerAgentStatus(desktopServer.id); else offline">
        online
      </span>
      <ng-template #offline>
        <span>
          offline
        </span>
      </ng-template>
    </div>
  </div>
</div>

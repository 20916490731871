<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Usuário Pool de acesso {{defaultCustomPortalTemplate?.title}}</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">

    <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">

        <ng-container>

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="w-400 mt-15"
                     *ngIf="adDomains && adDomains.length > 0">
                    <div class="font-weight-900">Domínio:</div>
                    <div>{{adDomain.domainName}}</div>

                    <div class="font-weight-900" style="margin-top: 15px;">E-mail:</div>
                    <div>{{managerUser.email}}</div>

                    <div class="font-weight-900" style="margin-top: 15px;">Filial:</div>
                    <div>{{customer?.name}}</div>

                    <div class="font-weight-900" style="margin-top: 15px;">Login:</div>
                    <div>{{adUserPool.login}}</div>


                    <div *ngIf="appCanViewPasswordList">
                        <div *appIfPermission="appCanViewPasswordList">
                            <div class="font-weight-900" style="margin-top: 15px;">Senha:</div>
                            <div *ngIf="loggedManagerUserIsADDomainAdmin || currentEditManagerUserIsTheSameUser || loggedManagerUserIsAdmin">
                                <div *ngIf="adUserPw ==''">
                                    <button type="button" mat-raised-button
                                            (click)="findByLoginPw(adUserPool)">
                                        Mostrar senha
                                    </button>
                                </div>
                                <div *ngIf="adUserPw !=''">
                                    {{adUserPw}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="saving-groups">
                        <div fxLayout="column" fxLayoutAlign="start end">
                            <mat-form-field appearance="outline">
                                <mat-label>Grupos</mat-label>
                                <mat-select placeholder="Grupos" [formControl]="adGroupIds" multiple>
                                    <mat-option *ngFor="let group of adminService.adGroups" [value]="group.id">
                                        {{group.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button type="button" mat-raised-button color="accent"
                                    [disabled]="adGroupIds.pristine || adGroupListHasChange()"
                                    (click)="savingGroupsInUser()">
                                Salvar grupos
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="appCanOpenDesktopServerWithCustomADUserList">
                        <div *appIfPermission="appCanOpenDesktopServerWithCustomADUserList">
                        <div>
                            <mat-form-field class="width-100-percent">
                                <mat-select placeholder="Selecione o Servidor"
                                            [formControl]="desktopServerFilterControl">
                                    <mat-option
                                        *ngFor="let desktopServer of getDesktopServerListByADDomainId(adUserPool.adDomainId)"
                                        [value]="desktopServer">
                                        {{desktopServer.hostname}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="start end">
                            <div class="mt-5">
                                <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                        *ngIf="!isLoading('onUserReset')"
                                        mat-raised-button color="primary"
                                        (click)="onUserLogOff(adUserPool.login)">
                                    Desconectar
                                </button>
                                <mat-spinner *ngIf="isLoading('onUserReset')" [diameter]="24" color="primary"></mat-spinner>
                            </div>
                            <div class="mt-5">
                                <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                        *ngIf="!isLoading('onUserReset')"
                                        mat-raised-button color="primary"
                                        (click)="onUserReset(adUserPool.login)">Forçar
                                    Desconexão
                                </button>
                                <mat-spinner *ngIf="isLoading('onUserReset')" [diameter]="24" color="primary"></mat-spinner>
                            </div>


                            <div class="mt-15">
                                <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                        *ngIf="!isLoading('onCheckEnableUserADAccount')"
                                        mat-raised-button color="primary"
                                        (click)="onCheckEnableUserADAccount(adUserPool.login)">
                                    Checar bloqueio
                                </button>
                                <mat-spinner *ngIf="isLoading('onCheckEnableUserADAccount')" [diameter]="24" color="primary"></mat-spinner>
                            </div>
                            <div class="mt-5">
                                <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                        *ngIf="!isLoading('onUnlockUserADAccount')"
                                        mat-raised-button color="primary"
                                        (click)="onUnlockUserADAccount(adUserPool.login)">
                                    Desbloquear usuário no AD
                                </button>
                                <mat-spinner *ngIf="isLoading('onUnlockUserADAccount')" [diameter]="24" color="primary"></mat-spinner>
                            </div>

                            <div class="mt-5">
                                <button [disabled]="!desktopServerFilterControl?.value?.id"
                                        *ngIf="!isLoading('onEnableUserADAccount')"
                                        type="button"
                                        mat-raised-button color="primary"
                                        (click)="onEnableUserADAccount(adUserPool.login)">
                                    Ativar usuário no AD
                                </button>
                                <mat-spinner *ngIf="isLoading('onEnableUserADAccount')" [diameter]="24" color="primary"></mat-spinner>
                            </div>


                            <div class="mt-15">
                                <div *ngIf="loggedManagerUserIsADDomainAdmin || currentEditManagerUserIsTheSameUser || loggedManagerUserIsAdmin">
                                <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                        mat-raised-button color="accent"
                                        (click)="openDesktopServerCustomADUserPool()">
                                    Abrir como esse usuário
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>


        </ng-container>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

        <button type="submit" mat-raised-button color="warn" aria-label="Excluir" (click)="onRemove()">Excluir</button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

        <div *ngIf="adUserPool?.id">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" style="max-width: 472px" >
                <button mat-menu-item *appIfPermission="'AUDIT'; data: adUserPool; type: 'ADUserPool'"
                        (click)="onJaversHistory()">
                    <mat-icon>history</mat-icon>
                    <span>Histórico</span>
                </button>
                <button mat-menu-item *appIfPermission="'AUDIT'; data: adUserPool; type: 'ADUserPool'"
                        (click)="onJaversAllHistory()">
                    <mat-icon>history</mat-icon>
                    <span>Histórico completo</span>
                </button>
                <button mat-menu-item *appIfPermission="'AUDIT'; data: adUserPoolRelationship; type: 'ADUserPoolRelationship'"
                        (click)="onJaversHistoryRelationship()">
                    <mat-icon>history</mat-icon>
                    <span>Histórico do relacionamento</span>
                </button>
                <button mat-menu-item *appIfPermission="'AUDIT'; data: adUserPoolRelationship; type: 'ADUserPoolRelationship'"
                        (click)="onJaversAllHistoryRelationship()">
                    <mat-icon>history</mat-icon>
                    <span>Histórico completo do relacionamento</span>
                </button>
            </mat-menu>
        </div>

    </div>

</div>

<mat-card class="card">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                Versões de Plugins
            </div>
            <div style="height: 36px;">

            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let serverPluginFile of serverPluginService.serverPluginFile$ | async; let i = index"
             class="row-item" [ngClass]="{'mt-8': i > 0, 'even': i % 2 === 0, 'odd': i % 2 != 0}">
            <div>
                {{getPluginName(serverPluginFile)}}-{{serverPluginFile.version}}
            </div>
            <div>
                <button type="button" mat-mini-fab color="accent" (click)="onDownload(serverPluginFile)">
                    <mat-icon>
                        cloud_download
                    </mat-icon>
                </button>
                <button type="button" class="ml-10" mat-mini-fab color="warn" (click)="onDelete(serverPluginFile)">
                    <mat-icon>
                        delete
                    </mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<div *ngFor="let project of projects$ | async">
  <app-project-item [project]="project"  (eventProject)="editProject($event)">

  </app-project-item>
  <!--<app-customer-group-item [project]="project">-->

  <!--</app-customer-group-item>-->
  <!--<div *ngFor="let host of customer.hosts | async">-->
  <!--<p>-&#45;&#45; {{host.name}} &#45;&#45; {{host.type}}</p>-->
  <!--</div>-->

</div>

<div>
  <p>Snapshots</p>
  <table>
    <thead>
    <tr>
      <th>
        Operação
      </th>
      <th>
        Estado
      </th>
      <th>
        Autor
      </th>
      <th>
        Data
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let projectSnapshot of projectsSnapshots$ | async">
      <td>
        {{projectSnapshot.type}}
      </td>
      <td>
        {{projectSnapshot.state | json}}
      </td>
      <td>
        {{projectSnapshot.commitMetadata?.author}}
      </td>
      <td>
        {{projectSnapshot.commitMetadata?.commitDate}}
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div>
  <p>Shadows</p>
  <table>
    <thead>
    <tr>
      <th>
        Objeto
      </th>
      <th>
        Autor
      </th>
      <th>
        Data
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let projectShadow of projectsShadows$ | async">
      <td>
        {{projectShadow.it | json}}
      </td>
      <td>
        {{projectShadow.commitMetadata?.author}}
      </td>
      <td>
        {{projectShadow.commitMetadata?.commitDate}}
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div>
  <p>Changes</p>
  <table>
    <thead>
    <tr>
      <th>
        Objeto
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let projectChange of projectsChanges$ | async">
      <td>
        {{projectChange | json}}
      </td>
    </tr>
    </tbody>
  </table>
</div>

import {Injectable} from '@angular/core';
import {MarketplaceProductComment} from '../../../../models';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceProductCommentService {
    constructor() {
    }

    initMarketplaceProductComment(): MarketplaceProductComment {
        return {
            id: null,
            active: true,
            comment: null,
            marketplaceProductId: null,
            managerUserId: null,
            email: null,
            rating: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
    }

    delete(comment: MarketplaceProductComment, commentList: MarketplaceProductComment[]): MarketplaceProductComment[] {
        const index = commentList.findIndex((o) => o.id === comment.id);
        if (index > -1) {
            commentList.splice(index, 1);
        }
        return commentList;
    }

    update(comment: MarketplaceProductComment, commentList: MarketplaceProductComment[]): MarketplaceProductComment[] {
        const index = commentList.findIndex((o) => o.id === comment.id);
        if (index > -1) {
            commentList[index] = comment;
        }
        return commentList;
    }
}

import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {RemoteAppDaoService} from '../remote-app-dao.service';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {ComponentCleaner} from '../../../component-cleaner';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {debounceTime, distinctUntilChanged, filter, startWith, switchMap} from 'rxjs/operators';
import {ADDomain, ADGroup, ADUser, ADUserServiceExternal, DesktopServer, ManagerUser, RemoteApp, ServerStatusType, SubProject} from '../../../models';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {RemoteAppFileBrowserComponent} from '../remote-app-file-browser/remote-app-file-browser.component';
import {DesktopServerStatusService} from '../../../ws/desktop-server-status.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../image-file/image-file-service';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ValueWrapper} from '../../../helpers/value-wrapper';
import {AdUserServiceExternalDaoService} from '../../ad-user-service-external/ad-user-service-external-dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';

@Component({
    selector: 'app-remote-app-create-edit',
    templateUrl: './remote-app-create-edit.component.html',
    styleUrls: ['./remote-app-create-edit.component.scss']
})
export class RemoteAppCreateEditComponent extends ComponentCleaner {
    desktopServers: DesktopServer[];
    adDomains: ADDomain[];
    remoteApp: RemoteApp;
    desktopServer: DesktopServer;
    private serviceApp: boolean;
    private adUsersByType: ADUser[];
    adUserExternalList: ADUser[] = [];
    adUsers: ADUser[] = [];
    adGroups: ADGroup[] = [];
    managerUsers: ManagerUser[] = [];
    subProject: SubProject;

    adGroupsFromData: ADGroup[] = [];
    adUsersFromData: ADUser[] = [];
    adUserServiceExternalsFromData: ADUserServiceExternal[] = [];
    adUsersServiceFromData: ADUser[] = [];

    myForm: FormGroup;
    imageFileElement: any = null;
    desktopServerStompUsername: string | null;
    private desktopServerStatusSubscription: Subscription;

    searchIconsCtrl: FormControl = new FormControl();

    imageFileIdSelected;
    showSuggestions = false;
    imageUUIDList: ValueWrapper[] = [];
    currentSearchValue = '';
    loading = false;

    @ViewChild('inputFile')
    inputFile: ElementRef;
    appCanCreateADUserPoolRelationship: PermissionWrapper[];


    constructor(public dialogRef: MatDialogRef<RemoteAppCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private remoteAppDaoService: RemoteAppDaoService,
                private adUserServiceExternalDaoService: AdUserServiceExternalDaoService,
                public imageFileService: ImageFileService,
                public domainPathService: DomainPathService,
                private desktopServerStatusService: DesktopServerStatusService,
                public adminService: R2CloudAdminService,
                public customerGroupService: CustomerGroupService,
                private dialog: MatDialog) {
        super();

        this.appCanCreateADUserPoolRelationship = [
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
        this.remoteApp = data.remoteApp;
        this.imageFileIdSelected = this.remoteApp.imageUUID;
        this.serviceApp = this.remoteApp.serviceApp;
        this.desktopServers = data.desktopServers;
        if (this.desktopServers) {
            this.desktopServers = this.desktopServers.filter(x => x.active === true);
        }
        if (this.remoteApp && this.remoteApp.desktopServerId) {
            const desktopServer = this.desktopServers.find(x => x.id === this.remoteApp.desktopServerId);
            this.setDesktopServer(desktopServer);
        }
        this.adDomains = data.adDomains;
        if (this.adDomains) {
            this.adDomains = this.adDomains.filter(x => x.active === true);
        }

        if (data.adUsers) {
            this.adUsersFromData = data.adUsers;
        }

        if (data.adUsersService) {
            this.adUsersServiceFromData = data.adUsersService;
        }

        if (data.adUserServiceExternals) {
            this.adUserServiceExternalsFromData = data.adUserServiceExternals;
        }

        if (data.adGroups) {
            this.adGroupsFromData = data.adGroups;
        }

        if (data.managerUsers) {
            this.managerUsers = data.managerUsers;
        }
        this.subProject = data.subProject;

        this.addSubscription(this.adUserServiceExternalDaoService.findAllADUserBySubProjectId(this.subProject.id).subscribe((adUserExternalList) => {
            if (adUserExternalList) {
                this.adUserExternalList = adUserExternalList;
            }


            this.myForm = this.fb.group(convertToFormGroup(this.remoteApp), {asyncValidator: this.remoteAppDaoService.validator});
            const serviceApp$ = this.myForm.get('serviceApp').valueChanges.pipe(startWith(this.remoteApp.serviceApp), distinctUntilChanged());
            const desktopServerId$ = this.myForm.get('desktopServerId').valueChanges.pipe(
                startWith(this.remoteApp.desktopServerId),
                distinctUntilChanged(),
                filter(desktopServerId => !!desktopServerId));
            const subscription = combineLatest([serviceApp$, desktopServerId$])
                .subscribe((value) => {
                    const serviceApp = value[0];
                    const desktopServerId = value[1];
                    this.onDesktopServerIdChange(desktopServerId, serviceApp);
                });
            this.addSubscription(subscription);
            this.setADUsersAndGroups(this.serviceApp);

            this.myForm.get('serviceApp').valueChanges.subscribe(val => {
                if (val) {
                    this.myForm.get('app').setValue('serviceApp');
                } else {
                    this.myForm.get('app').setValue('');
                }
            });

            this.myForm.get('name').valueChanges.pipe(
                debounceTime(1500)
            ).subscribe(() => {
                this.onChangeRemoteAppName();
            });

            combineLatest([
                this.myForm.get('adGroupIds').valueChanges,
                this.myForm.get('adUserPoolRelationshipIds')?.valueChanges
            ]).subscribe(() => {
                this.myForm.get('adUserIds').setErrors(null);
                this.myForm.get('adUserPoolRelationshipIds').setErrors(null);
            });

            this.searchIconsCtrl.valueChanges.pipe(
                debounceTime(500)
            ).subscribe(() => {
                if (this.myForm.get('name').value !== this.searchIconsCtrl.value) {
                    this.searchIcons(this.searchIconsCtrl.value, false);
                }
            });
        }));
    }

    findADUser(adUserId: number): ADUser {
        return this.adUserExternalList.find(x => x.id === adUserId);
    }

    getManagerUserEmail(managerUserId: number): string {
        let result = '';
        if (this.managerUsers) {
            const managerUser = this.managerUsers.find((x) => x.id === managerUserId);
            if (managerUser) {
                result = managerUser?.email;
            }
        }
        return result;
    }

    getAdDomainName(adDomainId: number): string {
        let result = '';
        if (this.adDomains) {
            const adDomain = this.adDomains.find((object) => {
                return object.id === adDomainId;
            });
            if (adDomain) {
                result = adDomain.domainName;
            }
        }
        return result;
    }

    onDesktopServerIdChange(desktopServerId: number, serviceApp: boolean): void {
        let previousADDomainId = null;
        if (this.desktopServer) {
            previousADDomainId = this.desktopServer.adDomainId;
        }
        if (!this.desktopServer || (this.desktopServer.id !== desktopServerId)) {
            const desktopServer = this.desktopServers.find(x => x.id === desktopServerId);
            this.setDesktopServer(desktopServer);
        }
        if (previousADDomainId !== this.desktopServer.adDomainId || this.serviceApp !== serviceApp) {
            // this.adDomain = this.adDomains.find(x => x.id === this.desktopServer.adDomainId);
            this.setADUsersAndGroups(serviceApp);
            this.myForm.setControl('adUserIds', this.fb.control([]));
            this.myForm.setControl('adGroupIds', this.fb.control([]));
            this.myForm.get('serviceApp').updateValueAndValidity();
        }
        this.serviceApp = serviceApp;
    }

    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
                this.imageFileIdSelected = null;
            };
            reader.readAsBinaryString(file);
            this.myForm.markAsDirty();
            this.myForm.controls['name'].updateValueAndValidity();
        }
    }

    onSubmit(): void {
        const imageData = this.imageFileElement;
        const remoteAppSave: RemoteApp = this.myForm.value;
        remoteAppSave.name = remoteAppSave.name.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');
        remoteAppSave.subProjectId = this.subProject.id;
        let operation: CrudOperation;
        let remoteAppObservable: Observable<RemoteApp>;
        if (remoteAppSave.imageUUID !== this.imageFileIdSelected) {
            remoteAppSave.imageUUID = this.imageFileIdSelected;
        }
        if (imageData) {
            remoteAppSave.imageUUID = null;
        }
        if (remoteAppSave.id) {
            operation = 'SAVE';
            remoteAppObservable = this.remoteAppDaoService.save(remoteAppSave);
        } else {
            operation = 'CREATE';
            if (remoteAppSave.name) {
                remoteAppSave.alias = remoteAppSave.name.replace(/[^A-Z0-9]/ig, '').toLowerCase() + Math.floor(Math.random() * 999999);
            }
            remoteAppObservable = this.remoteAppDaoService.create(remoteAppSave);
        }
        remoteAppObservable.pipe(switchMap((remoteAppFromDAO) => {
            let imageFile$: Observable<ValueWrapper>;
            if (imageData) {
                imageFile$ = this.imageFileService.saveRemoteAppPicture(remoteAppFromDAO.id, imageData);
            } else {
                imageFile$ = of(null);
            }
            return forkJoin([imageFile$, of(remoteAppFromDAO)]);
        })).subscribe((remoteApp) => {
            if (!this.imageFileIdSelected && remoteApp[0]?.value) {
                remoteApp[1].imageUUID = remoteApp[0].value;
            }
            this.dialogRef.close({
                data: remoteApp[1],
                operation: operation
            } as CrudOperationWrapper);
        });
    }

    onCancel(): void {
        this.dialogRef.close({
            operation: 'CANCEL'
        } as CrudOperationWrapper);
    }

    onForceDelete(): void {

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<h2 class="warn-800-fg">Esse procedimento não tem volta.</h2> ' +
                    '<p>Quer mesmo <span class="warn-800-fg">EXCLUIR ESSE APLICATIVO</span> de forma forçada?.<br>' +
                    'Este procedimento removerá o aplicativo apenas do R2.</p>' +
                    '<br><b>Confirme</b> a exclusão digitando: <b>' + this.remoteApp.alias + '</b> no campo abaixo <br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline',
                confirmFieldValue: this.remoteApp.alias
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.remoteAppDaoService.forceDelete(this.remoteApp.id).subscribe(() => {
                    this.dialogRef.close({
                        data: this.remoteApp.id,
                        operation: 'DELETE'
                    } as CrudOperationWrapper);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo remover esse Aplicativo?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.remoteAppDaoService.remove(this.remoteApp.id).subscribe(() => {
                    this.dialogRef.close({
                        data: this.remoteApp.id,
                        operation: 'DELETE'
                    } as CrudOperationWrapper);
                });
            }
        });
        this.addSubscription(subscription);
    }


    onOpenFileBrowser(): void {
        const subscription = this.dialog.open(RemoteAppFileBrowserComponent, {
            disableClose: true,
            data: {
                currentPath: this.myForm.get('app').value,
                desktopServer: this.desktopServer,
                desktopServerStompUsername: this.desktopServerStompUsername
            },
            panelClass: 'generic-edit-dialog-x-large'
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.myForm.get('app').setValue(result);
            }
        });
        this.addSubscription(subscription);
    }

    onFocusSearch(): void {
        this.showSuggestions = true;
        this.searchIcons(this.searchIconsCtrl.value, true);
    }

    onFocusOutSearch(): void {
        this.showSuggestions = false;
    }

    selectImageFile(imageUUID: string): void {
        this.imageFileElement = null;
        this.inputFile.nativeElement.value = null;
        this.myForm.markAsDirty();
        this.myForm.controls['name'].updateValueAndValidity();
        this.imageFileIdSelected = imageUUID;
    }

    private onChangeRemoteAppName(): void {
        const name = this.myForm.get('name').value;
        if (!this.searchIconsCtrl.value) {
            this.searchIconsCtrl.setValue(name);
        }
        this.searchIcons(name, false);
    }

    private searchIcons(name: string, ignoreImageFileElement: boolean): void {
        if (this.currentSearchValue !== name) {
            this.imageUUIDList = [];
            this.loading = true;
            if (name && (!this.imageFileElement || ignoreImageFileElement)) {
                this.remoteAppDaoService.findAllImagesByRemoteAppName(name).subscribe((imageFileIdList) => {
                    setTimeout(() => {
                        this.loading = false;
                        this.currentSearchValue = name;
                        this.imageUUIDList = imageFileIdList;
                        if (!this.imageFileIdSelected) {
                            this.imageFileIdSelected = this.imageUUIDList[0]?.value;
                        }
                    }, 2000);

                });
            }
        }
    }

    private setADUsersAndGroups(serviceApp: boolean): void {
        if (serviceApp) {
            this.adUsersByType = this.adUsersServiceFromData.concat(this.adUsersFromData);
            this.adUserServiceExternalsFromData.forEach((adUserService) => {
                const index = this.adUsersByType.findIndex(x => x?.id === adUserService.adUserId);
                if (index < 0) {
                    this.adUsersByType.push(this.findADUser(adUserService.adUserId));
                }
            });
        } else {
            this.adUsersByType = this.adUsersFromData;
        }
        if (this.desktopServer) {
            this.adGroups = this.adGroupsFromData.filter(x => x?.adDomainId === this.desktopServer.adDomainId);
            if (this.adUsersByType) {
                this.adUsers = this.adUsersByType.filter(x => x?.adDomainId === this.desktopServer.adDomainId);
            } else {
                this.adUsers = [];
            }
        } else {
            this.adUsers = [];
            this.adGroups = [];
        }
    }

    private setDesktopServer(desktopServer: DesktopServer): void {
        this.desktopServer = desktopServer;
        this.desktopServerStompUsername = null;
        if (this.desktopServerStatusSubscription) {
            this.desktopServerStatusSubscription.unsubscribe();
            this.desktopServerStatusSubscription = null;
        }
        this.desktopServerStatusSubscription = this.desktopServerStatusService
            .onDesktopServerStatus(this.desktopServer.id).subscribe((result) => {
                if (result.status === ServerStatusType.CONNECTED) {
                    this.desktopServerStompUsername = result.username;
                } else {
                    this.desktopServerStompUsername = null;
                }
            });
        this.addSubscription(this.desktopServerStatusSubscription);
    }

    onChangeSelect(isADUserPool: boolean): void {
        this.myForm.get('adUserIds').setErrors(null);
        this.myForm.get('adUserPoolRelationshipIds').setErrors(null);
        if (isADUserPool) {
            this.myForm.get('adUserIds').setValue([]);
        } else {
            this.myForm.get('adUserPoolRelationshipIds').setValue([]);
        }
        this.myForm.updateValueAndValidity();
    }

    customNameADUserService(adUser: ADUser): string {
        if (adUser.serviceUser) {
            return '(serviço) ' + adUser.login;
        } else {
            return '(comum) ' + adUser.login;
        }
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.remoteApp.id, this.remoteAppDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.remoteApp.id, this.remoteAppDaoService, this.dialog);
    }
}

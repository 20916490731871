import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {RemoteAppLog} from '../../../models';
import {BaseIdStringDao} from '../../../base-id-string-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {Page} from '../../../helpers/Page';

@Injectable()
export class RemoteAppLogDaoService extends BaseIdStringDao<RemoteAppLog> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'remote-app-log');
    }

    findRemoteAppLogByCustomerGroupId(
        customerGroupId: number,
        managerUserId: number,
        serviceUser: boolean,
        from: string,
        to: string,
        currentPage: number,
        pageSize: number
    ): Observable<Page<RemoteAppLog>> {
        return this.http.get<Page<RemoteAppLog>>(
            this.url + '/list?customerGroupId=' + customerGroupId + '&managerUserId=' + managerUserId + '&serviceUser=' + serviceUser + '&from=' + from + '&to=' + to + '&currentPage=' +
            currentPage + '&pageSize=' + pageSize
        );
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NewUserWizardComponent} from '../../manager-user-and-ad-user/new-user-wizard/new-user-wizard.component';
import {ADUser, ManagerUser, RoleIdSubProjectIdManagerUserId} from '../../../models';
import {convertToFormGroup, CrudOperationWrapper, markAsTouched, validateEmail} from '../../../helpers/kluh';
import {AuthService} from '../../../auth.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {Observable, of, Subject} from 'rxjs';
import {CustomerCreateEditComponent} from '../../customer/customer-create-edit/customer-create-edit.component';
import {debounceTime, delay, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {RoleSubProjectManagerUserDAOService} from '../role-sub-project-manager-user-dao.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ManagerUserService} from '../../manager-user/manager-user-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'app-manager-user-administrator',
    templateUrl: './manager-user-administrator.component.html',
    styleUrls: ['./manager-user-administrator.component.scss']
})
export class ManagerUserAdministratorComponent extends ComponentCleaner implements OnInit {

    public myForm: FormGroup;
    public currentRoleId: number;
    private validation$: Subject<boolean> = new Subject();

    constructor(public dialogRef: MatDialogRef<NewUserWizardComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public adminService: R2CloudAdminService,
                private dialog: MatDialog,
                private authService: AuthService,
                private daoManagerUser: ManagerUserDaoService,
                private managerUserService: ManagerUserService,
                private roleSubProjectManagerUserDao: RoleSubProjectManagerUserDAOService,
                private userAuthorityDao: UserAuthorityDaoService) {
        super();
    }

    ngOnInit(): void {
        let managerUser: ManagerUser;
        let roleIdSubProjectIdManagerUserId: RoleIdSubProjectIdManagerUserId;
        let roleId;
        let email;
        if (this.data && this.data.roleIdSubProjectIdManagerUserId) {
            roleIdSubProjectIdManagerUserId = this.data.roleIdSubProjectIdManagerUserId;
            managerUser = this.adminService.getManagerUserAdministrator(roleIdSubProjectIdManagerUserId.managerUserId);
            email = managerUser.email;
            roleId = this.adminService.getRole(roleIdSubProjectIdManagerUserId.roleId).id;
            this.currentRoleId = roleId;
        } else {
            managerUser = this.managerUserService.initManagerUser();
            email = '';
            roleId = '';
        }
        const roleIdForm = this.fb.control(roleId);
        const emailForm = this.fb.control(email, [Validators.email]);
        const managerUserForm = this.fb.group(convertToFormGroup(managerUser));
        managerUserForm.disable();
        this.myForm = this.fb.group({
            managerUserForm: managerUserForm,
            emailForm: emailForm,
            roleIdForm: roleIdForm
        }, {asyncValidator: this.validate()});
        const emailForm$ = emailForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (value && validateEmail(value)) {
                this.daoManagerUser.findByEmail(value).subscribe(managerUserFromDao => {
                    if (managerUserFromDao) {
                        managerUserForm.setValue(managerUserFromDao);
                        managerUserForm.disable();
                    } else {
                        managerUserForm.setValue(this.managerUserService.initManagerUser());
                        managerUserForm.enable();
                    }
                });
            } else {
                if (managerUserForm.value.id) {
                    managerUserForm.setValue(this.managerUserService.initManagerUser());
                    managerUserForm.disable();
                }
            }
        });
        this.addSubscription(emailForm$);
    }


    validate(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            this.validation$.next(true);
            this.validation$ = new Subject();
            const entity: RoleIdSubProjectIdManagerUserId = {
                roleId: control.get('roleIdForm').value,
                managerUserId: control.get('managerUserForm').value.id,
                subProjectId: this.adminService.subProject.id
            };
            return this.roleSubProjectManagerUserDao.validate(entity).pipe(
                takeUntil(this.validation$),
                delay(500),
                map((response) => {
                    const result = {};
                    for (const error of response) {
                        if (error.field && error.field === 'roleId') {
                            result['roleIdForm'] = error.defaultMessage;
                        }
                    }
                    return result;
                }));
        };
    }

    onSubmit(): void {
        const roleId: number = Number(this.myForm.get('roleIdForm').value);
        const email: string = this.myForm.get('emailForm').value;
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        managerUser.email = email;
        let managerUser$: Observable<ManagerUser>;
        if (managerUser.id) {
            managerUser$ = of(managerUser);
        } else {
            managerUser$ = this.daoManagerUser.create(managerUser);
        }

        managerUser$.subscribe((managerUserAdministrator) => {
            this.adminService.addManagerUserAdministrator(managerUserAdministrator);
            this.adminService.addManagerUser(managerUserAdministrator);
            if (this.currentRoleId) {
                const roleIdSubProjectIdManagerUserId = this.getRoleIdSubProjectIdManagerUserId();
                roleIdSubProjectIdManagerUserId.roleId = this.currentRoleId;
                this.roleSubProjectManagerUserDao.delete(roleIdSubProjectIdManagerUserId).subscribe(v => {
                    this.createRoleSubProjectManagerUser(roleId, managerUserAdministrator, roleIdSubProjectIdManagerUserId);
                });
            } else {
                this.createRoleSubProjectManagerUser(roleId, managerUserAdministrator, null);
            }
        });
    }

    getRoleIdSubProjectIdManagerUserId(): RoleIdSubProjectIdManagerUserId {
        const roleId: number = this.myForm.get('roleIdForm').value;
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        return {
            roleId: roleId,
            managerUserId: managerUser.id,
            subProjectId: this.adminService.subProject.id
        };
    }

    createRoleSubProjectManagerUser(roleId: number, managerUser: ManagerUser, oldRoleIdSubProjectIdManagerUserId: RoleIdSubProjectIdManagerUserId | null): void {
        const roleIdSubProjectIdManagerUserId: RoleIdSubProjectIdManagerUserId = {
            roleId: roleId,
            managerUserId: managerUser.id,
            subProjectId: this.adminService.subProject.id
        };
        this.roleSubProjectManagerUserDao.create(roleIdSubProjectIdManagerUserId).subscribe((roleSubProjectManagerUserSaved) => {
            if (oldRoleIdSubProjectIdManagerUserId) {
                this.adminService.replaceRoleSubProjectGroupManagerUser(oldRoleIdSubProjectIdManagerUserId, roleSubProjectManagerUserSaved);
            } else {
                this.adminService.addRoleSubProjectManagerUser(roleSubProjectManagerUserSaved);
            }
            this.dialogRef.close();
        });
    }

    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover ' + this.myForm.get('managerUserForm').value.email + ' da administração ?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const roleIdSubProjectIdManagerUserId = this.getRoleIdSubProjectIdManagerUserId();
                roleIdSubProjectIdManagerUserId.roleId = this.currentRoleId;
                this.roleSubProjectManagerUserDao.delete(roleIdSubProjectIdManagerUserId).subscribe(v => {
                    this.adminService.removeRoleSubProjectManagerUser(roleIdSubProjectIdManagerUserId);
                    this.dialogRef.close();

                });
            }
        });
        this.addSubscription(subscription);
    }

    onCancel(): void {
        const adUser: ADUser = null;
        const crudOperation: CrudOperationWrapper = {
            operation: 'CANCEL',
            data: adUser
        };
        this.dialogRef.close(crudOperation);
    }

    onCreateCustomer(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.adminService.addCustomer(result.data);

            this.myForm.get('managerUserForm').get('customerId').setValue(result.data.id);
            markAsTouched(this.myForm);
        } else if (result.operation === 'SAVE') {

        } else if (result.operation === 'DELETE') {

        }
    }


}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faLinux, faWindows} from '@fortawesome/free-brands-svg-icons';
import {BaseServer, ChartPoint, ServerType} from '../../../../models';
import * as moment from 'moment';
import {HdMonitoringConfiguration, MetricType, ServerOverview, ServerUptime} from '../../../../models-monitoring';
import {Subscription} from 'rxjs';
import {MonitoringStompService} from '../../websocket-stomp/monitoring-stomp.service';


@Component({
    selector: 'app-server-overview-accordion',
    templateUrl: './server-overview-accordion.component.html',
    styleUrls: ['./server-overview-accordion.component.scss']
})

export class ServerOverviewAccordionComponent implements OnInit, OnDestroy {
    faWindows = faWindows;
    faLinux = faLinux;
    panelOpenState = false;

    cpuAverage = -1;
    memoryAverage = -1;
    hdList: HdMonitoringConfiguration[] = [];
    serverOverview: ServerOverview;

    loadingOverViewText = 'carregando...' ;
    serverUptime: ServerUptime;
    hdAverages: Array<number> = [];

    metricType: MetricType = null;
    metricId = 0;

    private subUptime: Subscription;

    statusOverview = false;

    @Input()
    baseServer: BaseServer;

    @Input()
    serverType: ServerType;

    ServerType = ServerType;

    constructor(public webStompService: MonitoringStompService
    ) {
    }

    ngOnInit(): void {

        setTimeout(() => {
            this.loadingOverViewText = 'monitoramento desconectado';
        }, 20000);


        this.metricId = this.baseServer.id;
        if (this.serverType === ServerType.LINUX) {
            this.metricType = MetricType.LINUX_SERVER;
        } else if (this.serverType === ServerType.WINDOWS) {
            this.metricType = MetricType.WINDOWS_SERVER;
        }
        this.getServerUptime();
    }

    getServerUptime(): void {
        this.subUptime = this.webStompService.stompSubscribe<ChartPoint[]>(
            '/topic/ServerUptime.' + this.metricType + '.' + this.metricId + '.' + this.metricId)
            .subscribe((chartPoint) => {
                if (chartPoint) {
                    this.serverUptime = {
                        value: chartPoint[0].y,
                        metricId: 0,
                        metricType: this.metricType,
                        createdAt: new Date(chartPoint[0].x)
                    };
                }
            });
    }

    checksIfTheServerIsOnline(serverUptime: ServerUptime): boolean {
        const lastDataReadingTime = moment(serverUptime.createdAt);
        const now = moment();
        return now.add(-2, 'minutes').isBefore(lastDataReadingTime);
    }

    ngOnDestroy(): void {
        if (this.subUptime) {
            this.subUptime.unsubscribe();
        }
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DotNetServerPluginTemplateDaoService} from '../services/server-plugin-template-dao.service';
import {DotNetServerPlugin, DotNetServerPluginTemplate} from '../../../models';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {convertToFormGroup} from '../../../helpers/kluh';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-server-plugin-template-create-edit',
    templateUrl: './server-plugin-template-create-edit.component.html',
    styleUrls: ['./server-plugin-template-create-edit.component.scss']
})
export class DotNetServerPluginTemplateCreateEditComponent extends ComponentCleaner implements OnInit {
    myForm: FormGroup;
    dotNetServerPluginTemplate: DotNetServerPluginTemplate;

    constructor(public dialogRef: MatDialogRef<DotNetServerPluginTemplateCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                public serverPluginService: DotNetServerPluginService,
                private dialog: MatDialog,
                private serverPluginTemplateDao: DotNetServerPluginTemplateDaoService
    ) {
        super();
        this.dotNetServerPluginTemplate = data.serverPluginTemplate;
        this.myForm = this.fb.group(convertToFormGroup(data.serverPluginTemplate), {asyncValidator: this.serverPluginTemplateDao.validator});
        if (!this.getServerPluginIdsControl().value) {
            this.getServerPluginIdsControl().setValue([]);
        }
    }

    ngOnInit(): void {
    }


    onSubmit(): void {
        const serverPluginTemplate: DotNetServerPluginTemplate = this.myForm.value;
        this.serverPluginService.saveServerPluginTemplate(serverPluginTemplate).subscribe(() => {
            this.dialogRef.close();
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    isSelected(serverPlugin: DotNetServerPlugin): boolean {
        const idList: number[] = this.getServerPluginIdsControl().value;
        return idList.findIndex(o => o === serverPlugin.id) > -1;
    }

    private getServerPluginIdsControl(): FormControl {
        return this.myForm.get('serverPluginIds') as FormControl;
    }

    toggleServerPlugin(serverPlugin: DotNetServerPlugin): void {
        const serverPluginIdsControl = this.getServerPluginIdsControl();
        let idList = serverPluginIdsControl.value as number[];
        if (this.isSelected(serverPlugin)) {
            idList = idList.filter(o => o !== serverPlugin.id);
        } else {
            idList.push(serverPlugin.id);
        }
        serverPluginIdsControl.setValue(idList);
        serverPluginIdsControl.markAsDirty();
        serverPluginIdsControl.updateValueAndValidity();
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.dotNetServerPluginTemplate.id, this.serverPluginTemplateDao, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.dotNetServerPluginTemplate.id, this.serverPluginTemplateDao, this.dialog);
    }
}

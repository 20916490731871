<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>AdGroups (Changing ou Deleting)</div>
            <button *ngIf="!loading" mat-raised-button type="button" color="primary" class="md-small" (click)="getData()">
                <span *ngIf="!alreadyRead">Show</span>
                <span *ngIf="alreadyRead">Update</span>
            </button>
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th></th>
                <th class="w-115"></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let adGroup of adGroupsPendingOrDeleting">
                <td>
                    <div *ngIf="statusServer.getAdDomain(adGroup.adDomainId, adDomains) as adDomain">
                        <div><b>{{adGroup.name}} - {{adGroup.prefix}}</b></div>
                        <div class="font-size-12">
                            domínio: {{adDomain?.name}}</div>
                        <div class="font-size-12">{{adGroup.updatedAt}}</div>
                        <ul *ngIf="statusServer.getSubProject(adGroup.subProjectId, subProjects) as subProject">
                            <li>ID:<b> {{statusServer.getCustomerGroup(subProject?.id, customerGroups)?.id}} </b></li>
                            <li>Empresa:<b>{{statusServer.getCustomerGroup(subProject?.id, customerGroups)?.name}} </b></li>
                            <li>subProject:<b> {{subProject?.name}} </b></li>
                        </ul>
                    </div>
                </td>
                <td>
                    {{adGroup.updatedAt | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
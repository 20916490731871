import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DesktopServerPool} from '../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {MatDialogRef} from '@angular/material/dialog';
import {CrudOperationWrapper, isNumberGreaterThanZero} from '../../../helpers/kluh';
import {DesktopServerPoolDaoService} from '../desktop-server-pool-dao.service';

@Component({
    selector: 'app-desktop-server-pool-choice',
    templateUrl: './desktop-server-pool-choice.component.html',
    styleUrls: ['./desktop-server-pool-choice.component.scss']
})
export class DesktopServerPoolChoiceComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DesktopServerPoolChoiceComponent>,
        public adminService: R2CloudAdminService,
        private desktopServerPoolDaoService: DesktopServerPoolDaoService,
    ) {
    }

    useExistingPoolControl: FormControl = new FormControl();
    desktopServerPoolIdControl: FormControl = new FormControl();
    desktopServerPoolList: DesktopServerPool[] = [];

    readonly isNumberGreaterThanZero = isNumberGreaterThanZero;

    ngOnInit(): void {
        const projectId = this.adminService.subProject.projectId;
        this.desktopServerPoolDaoService.findAllByProjectId(projectId).subscribe((desktopServerPoolList: DesktopServerPool[]) => {
            this.desktopServerPoolList = desktopServerPoolList;
        });
    }

    onCreateNew(): void {
        const crudOperation: CrudOperationWrapper = {
            data: 'NEW_R2_SCALING',
            operation: 'CREATE'
        };
        this.dialogRef.close(crudOperation);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        const desktopServerPoolId = +this.desktopServerPoolIdControl.value;
        const subProjectId = this.adminService.subProject.id;
        this.desktopServerPoolDaoService.saveDesktopServerPoolRelationshipSubProject(desktopServerPoolId, subProjectId).subscribe((desktopServerPoolSaved) => {
            const crudOperation: CrudOperationWrapper = {
                data: desktopServerPoolSaved,
                operation: 'SAVE'
            };
            this.dialogRef.close(crudOperation);
        });
    }
}

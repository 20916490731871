import {Component} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {ComponentCleaner} from '../../component-cleaner';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {Period} from '../../helpers/kluh';
import {R2CloudAdminService} from '../r2-cloud-admin/r2-cloud-admin.service';
import {MatTabChangeEvent} from '@angular/material/tabs';


@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})


export class StatisticsComponent extends ComponentCleaner {
    public period = Period.today;
    public periodFilterControl: FormControl = new FormControl();
    public managerUsersFilterControl: FormControl = new FormControl();

    public periodList: Array<any> = [
        {
            name: 'Hoje',
            url: Period.today
        },
        {
            name: 'Ontem',
            url: Period.yesterday
        },
        {
            name: 'Últimos 7 dias',
            url: Period.last7days
        },
        {
            name: 'Últimos 30 dias',
            url: Period.last30days
        },
        {
            name: 'Últimos 60 dias',
            url: Period.last60days
        },
        {
            name: 'Esse ano',
            url: Period.thisYear
        }
    ];

    managerUserIdSearch = 0;


    constructor(
        public adminService: R2CloudAdminService,
        private customerGroupService: CustomerGroupService,
        private managerUserDaoService: ManagerUserDaoService,
    ) {
        super();
        this.periodFilterControl.setValue(this.periodList[0]);
        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (customerGroup && customerGroup.id) {
                this.managerUserDaoService.filter({managerUsersInCustomerGroupId: customerGroup.id}).subscribe(managerUsers => {
                    if (managerUsers) {
                        this.adminService.managerUsers = managerUsers;
                    } else {
                        this.adminService.managerUsers = [];
                    }
                });
            }
        }));

        this.addSubscription(this.periodFilterControl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe((periodSelected: any) => {
            this.period = periodSelected.url;
        }));

        this.addSubscription(this.managerUsersFilterControl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe((managerUserId: any) => {
            this.managerUserIdSearch = managerUserId;
        }));
    }

    onSelectOrderType($event: MatTabChangeEvent): void {
        console.log('$event', $event);
    }
}


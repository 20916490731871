<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0" mat-dialog-title>
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Adicionar Permissões ao Papel</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-dialog-content class="mat-white-bg no-padding" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <mat-form-field appearance="outline" class="m-20">
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" id="iamadd-permissions-filter">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="masterToggle()"  id="iamadd-permissions-select-toggle">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="name"> Nome</th>
                <td mat-cell *matCellDef="let row" class="name"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição</th>
                <td mat-cell *matCellDef="let row"> {{row.comment}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
         matDialogActions>
        <button type="button" mat-raised-button [disabled]="selection.selected.length === 0"
                (click)="selection.selected.length > 0 && onSubmit()" id="iamadd-permissions-ok-button">OK
        </button>
        <button type="button" mat-raised-button (click)="onCancel()" id="iamadd-permissions-cancel-button">Cancel</button>
    </div>
</div>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {AdUserPoolDaoService} from './ad-user-pool-dao.service';
import {AdUserPoolEditComponent} from './ad-user-pool-edit/ad-user-pool-edit.component';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {AdUserPoolListComponent} from './ad-user-pool-edit/ad-user-pool-list/ad-user-pool-list.component';
import {AdUserPoolInfoComponent} from './ad-user-pool-edit/ad-user-pool-info/ad-user-pool-info.component';
import {MatDialogModule} from '@angular/material/dialog';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';


const routes = [
    {
        path: 'users-pool',
        component: AdUserPoolEditComponent,
        canActivate: [AuthService],
        data: {
            permissions: ['ADUserPool.CREATE'],
            preCondition: true
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        MatIconModule,
        MatCheckboxModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatRadioModule,
        MatDialogModule,
        _MatMenuDirectivesModule,
        MatMenuModule
    ],
    providers: [
        AdUserPoolDaoService
    ],
    declarations: [AdUserPoolEditComponent, AdUserPoolListComponent, AdUserPoolInfoComponent]
})
export class AdUserPoolModule {
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataShareService} from '../../../data-share.service';
import {AuthService} from '../../../auth.service';
import {CustomerGroupDaoPublicService} from '../../customer-group/customer-group-dao-public.service';
import {CustomerGroupTemplateDaoService} from '../../custom-layout/customer-group-template/customer-group-template-dao.service';
import {Title} from '@angular/platform-browser';
import {BackgroundImageTemplate, CustomerGroupTemplate} from '../../../models-custom-layout';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {customLayoutUrlCookieName} from '../login.component';
import {CookieService} from 'ngx-cookie-service';
import {Subject} from 'rxjs/internal/Subject';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';

@Component({
    selector: 'fuse-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class FuseForgotPasswordComponent implements OnInit, OnDestroy {
    forgotPasswordForm: FormGroup;
    forgotPasswordFormErrors: any;
    forgotPasswordSuccess = false;
    forgotPasswordError = false;
    forgotPasswordSending = false;

    customerGroupImageUUID: string = null;
    backgroundImageTemplate: BackgroundImageTemplate;
    displayOriginalPortal = false;
    customerGroupTemplate: CustomerGroupTemplate;
    loadingCustomLayout = true;
    customLayoutUrl: string;
    loginTransition: string;
    private _unsubscribeAll: Subject<any> = new Subject();

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private titleService: Title,
                private dataShare: DataShareService,
                private customerGroupDaoPublicService: CustomerGroupDaoPublicService,
                private customerGroupTemplateDaoService: CustomerGroupTemplateDaoService,
                private activatedRoute: ActivatedRoute,
                private cookieService: CookieService,
                public imageFileService: ImageFileService,
                private managerUserDaoService: ManagerUserDaoService,
                private auth: AuthService) {

        this.forgotPasswordFormErrors = {
            email: {}
        };

        this.activatedRoute.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: Params) => {
            const userUrl = params['url'];
            this.customLayoutUrl = this.cookieService.get(customLayoutUrlCookieName);
            if (userUrl) {
                this.setCustomLayout(userUrl);
            } else if (this.customLayoutUrl) {
                this.setCustomLayout(this.customLayoutUrl);
            } else {
                this.setCustomLayout('');
                // this.displayOriginalPortal = true;
            }
        });
    }

    ngOnInit(): void {
        const forgotPasswordEmail = this.dataShare.getForgotPasswordEmail();
        this.forgotPasswordForm = this.formBuilder.group({
            email: [forgotPasswordEmail, [Validators.required, Validators.email]]
        });
        if (forgotPasswordEmail) {
            const emailControl = this.forgotPasswordForm.get('email');
            emailControl.markAsDirty();
            emailControl.updateValueAndValidity();
        }

        this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.onForgotPasswordFormValuesChanged();
        });
    }

    ngOnDestroy(): void {
        const emailControl = this.forgotPasswordForm.get('email');
        if (emailControl.valid) {
            this.dataShare.setForgotPasswordEmail(emailControl.value);
        }
    }

    onForgotPasswordFormValuesChanged(): void {
        for (const field in this.forgotPasswordFormErrors) {
            if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.forgotPasswordFormErrors[field] = {};

            // Get the control
            const control = this.forgotPasswordForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.forgotPasswordFormErrors[field] = control.errors;
            }
        }
    }

    onSubmit(): void {
        this.forgotPasswordSending = true;
        this.forgotPasswordError = false;
        const email = this.forgotPasswordForm.get('email').value;
        this.managerUserDaoService.publicEmailExists(email).subscribe(emailExists => {
            if (emailExists) {
                this.auth.sendResetPassword(email).subscribe(() => {
                    this.forgotPasswordSending = false;
                    this.forgotPasswordSuccess = true;
                });
            } else {
                this.forgotPasswordSending = false;
                this.forgotPasswordError = true;
            }
        });
    }


    setCustomLayout(userUrl: string): void {
        if (userUrl) {
            this.customLayoutUrl = userUrl;
            this.customerGroupDaoPublicService.getImageUUID(userUrl).subscribe((imageUUID) => {
                if (imageUUID) {
                    this.customerGroupImageUUID = imageUUID.value;
                }
            });
        }
        this.customerGroupTemplateDaoService.findOneByCustomerGroupUrl(userUrl).subscribe((customerGroupTemplate) => {
                if (customerGroupTemplate) {
                    this.customerGroupTemplate = customerGroupTemplate;
                    if (this.customerGroupTemplate?.customPortalTemplate?.title) {
                        this.titleService.setTitle(this.customerGroupTemplate?.customPortalTemplate.title);
                    }
                } else {
                    this.displayOriginalPortal = true;
                }
                this.loadingCustomLayout = false;
            }, () => {
                this.loadingCustomLayout = false;
                this.displayOriginalPortal = true;
            }
        );
    }


    onGoToLogin(): void {
        if (this.customLayoutUrl) {
            this.router.navigate(['login'], {queryParams: {url: this.customLayoutUrl}});
        } else {
            this.router.navigate(['login']);
        }
    }
}

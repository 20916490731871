import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MonitoringComponent} from './monitoring.component';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MonitoringDetailsComponent} from './monitoring-details/monitoring-details.component';
import {ProcessDetailsTableComponent} from './monitoring-details/process-details-table/process-details-table.component';
import {UserDetailsTableComponent} from './monitoring-details/user-details-table/user-details-table.component';
import {ServicesDetailsTableComponent} from './monitoring-details/services-details-table/services-details-table.component';
import {NetworkUsageComponent} from './monitoring-details/network/network-usage/network-usage.component';
import {ServerOverviewAccordionComponent} from './monitoring-details/server-overview-accordion/server-overview-accordion.component';
import {VpnOverviewAccordionComponent} from './monitoring-details/vpn-monitoring/vpn-overview-accordion/vpn-overview-accordion.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {ProcessorUsageDaoService} from './monitoring-details/processor-usage/processor-usage-dao.service';
import {ServerUptimeComponent} from './monitoring-details/server-uptime/server-uptime.component';
import {ProcessorUsageModule} from './monitoring-details/processor-usage/processor-usage.module';
import {MemoryUsageModule} from './monitoring-details/memory-usage/memory-usage.module';
import {HdUsageModule} from './monitoring-details/hd-monitoring/hd-usage/hd-usage.module';
import {VpnUsageModule} from './monitoring-details/vpn-monitoring/vpn-usage/vpn-usage.module';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {NetworkConfigurationComponent} from './monitoring-details/network/network-configuration/network-configuration.component';
import {ServerCpuMenHdComponent} from './monitoring-details/server-overview-accordion/server-cpu-men-hd/server-cpu-men-hd.component';
import {FooterModule} from '../../../layout/components/footer/footer.module';


const routes = [
    {
        path: 'monitoring',
        component: MonitoringComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/processor',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/memory',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/hd',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/vpn',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/processes',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/users',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/services',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    },
    {
        path: 'monitoring-details/network',
        component: MonitoringDetailsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    }
];


@NgModule({
    imports: [
        // ChartsModule,
        CommonModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        MatExpansionModule,
        NgBytesPipeModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        ProcessorUsageModule,
        VpnUsageModule,
        MemoryUsageModule,
        HdUsageModule,
        FooterModule
    ],
    providers: [
        ProcessorUsageDaoService
    ],
    entryComponents: [],
    declarations: [
        MonitoringComponent,
        MonitoringDetailsComponent,
        ProcessDetailsTableComponent,
        UserDetailsTableComponent,
        ServicesDetailsTableComponent,
        NetworkUsageComponent,
        ServerOverviewAccordionComponent,
        VpnOverviewAccordionComponent,
        ServerUptimeComponent,
        NetworkConfigurationComponent,
        ServerCpuMenHdComponent
    ],

})
export class MonitoringModule {
}

import {Component, Inject} from '@angular/core';
import {DesktopServer} from '../../../models';
import {ComponentCleaner} from '../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';

@Component({
  selector: 'app-open-modal-ad-admin',
  templateUrl: './open-modal-ad-admin.component.html',
  styleUrls: ['./open-modal-ad-admin.component.scss']
})
export class OpenModalAdAdminComponent extends ComponentCleaner {

    desktopServer: DesktopServer;

  constructor(private stomp: R2CloudStompService,
              public dialogRef: MatDialogRef<OpenModalAdAdminComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      super();
      this.desktopServer = data.desktopServer;


  }




    public openDesktopServer(desktopServer: DesktopServer): void {
        this.stomp.send('/stomp/open-local-admin', {id: desktopServer.id});
        this.dialogRef.close();

    }

    openDesktopServerADAdmin(desktopServer: DesktopServer): void {
        this.stomp.send('/stomp/open-ad-admin', {id: desktopServer.id});
        this.dialogRef.close();
    }


    onCancel(): void {
        this.dialogRef.close();
    }


}

<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">
            <span *ngIf="aiUserGroup?.id">Editar</span>
            <span *ngIf="!aiUserGroup?.id">Criar</span>
            Grupo</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div matDialogContent>
        <div fxLayout="column" class="p-15" fxLayoutAlign="start start">
            <div [hidden]="aiUserGroup?.id">
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Nome do Grupo" [formControl]="aiUserGroupNameControl">
                </mat-form-field>
            </div>
            <div [hidden]="!aiUserGroup?.id" class="full-width">
                <h2>
                    Nome: <b>{{ aiUserGroup.name }}</b>
                </h2>
                <div *appIsAdmin>
                    <mat-form-field appearance="outline" class="full-width mt-15">
                        <mat-label>instructions</mat-label>
                        <textarea matInput [formControl]="instructionsControl" rows="4"></textarea>
                    </mat-form-field>
                </div>
                <div class="w-300 mb-15"  *appIsAdmin>
                    <mat-form-field class="full-width mt-15" appearance="outline" *appIsAdmin>
                        <mat-label>model</mat-label>
                        <mat-select placeholder="model" [formControl]="modelControl">
                            <mat-option *ngFor="let model of models" [value]="model.name">
                                {{ model?.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="full-width">
                <mat-form-field *ngIf="managerUsers" appearance="outline">
                    <mat-select placeholder="Usuários" [formControl]="managerUserIdsIdsControl" multiple>
                        <mat-option *ngFor="let managerUser of managerUsers" [value]="managerUser.id">
                            {{ managerUser?.email }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <mat-spinner *ngIf="loading" [diameter]="24" color="primary" class="mt-10"></mat-spinner>
        <button *ngIf="!loading" type="button" mat-raised-button color="accent" (click)="onSave()" [disabled]="!isValidForm()">Salvar</button>
        <button *ngIf="!loading" type="button" mat-raised-button color="warn" (click)="onDelete()" [hidden]="!aiUserGroup?.id" >Excluir</button>
        <button *ngIf="!loading" type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

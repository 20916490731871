import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {R2CloudStompService} from '../../../../ws/r2-cloud-stomp.service';

@Component({
    selector: 'app-send-stomp-message',
    templateUrl: './send-stomp-message.component.html',
    styleUrls: ['./send-stomp-message.component.scss']
})
export class SendStompMessageComponent implements OnInit {
    private readonly destination: string;
    private readonly username: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<SendStompMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private managerStomp: R2CloudStompService
    ) {
        this.username = data.username;
        this.destination = data.destination;
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        const destinationSplit = this.destination.split('/');
        const prefix = destinationSplit[1];
        const suffix = destinationSplit.slice(2).join('/');
        const userDestination = '/' + prefix + '/' + this.username + '/' + suffix;
        this.managerStomp.send(userDestination, JSON.parse(this.message));
        this.dialogRef.close();
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}

import {Injectable} from '@angular/core';
import {DotNetServerAgentFile} from '../../../models';
import {BaseDao} from '../../../base-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class DotNetServerAgentFileDaoService extends BaseDao<DotNetServerAgentFile> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'server-agent-file');
    }

    upload(formData: FormData): Observable<DotNetServerAgentFile> {
        return this.http.upload(formData, this.url + `/upload`);
    }

    download(id: number): Observable<Blob> {
        return this.http.download(this.url + `/${id}/download`);
    }

    updateAllServerAgent(): Observable<any> {
        // return this.http.get(this.url + '/test');
        return this.http.get(this.url + '/update-all-server-agent');
    }

    sendUpdate(desktopServerId: number): Observable<any> {
        return this.http.get(this.url + '/send-update/' + desktopServerId);
    }
}

import {Component} from '@angular/core';
import {RoleCustomerGroupManagerUserDAOService} from '../role-customer-group-manager-user-dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {RoleDAOService} from '../role-dao.service';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {RoleCustomerGroupManagerUserEditComponent} from './role-customer-group-manager-user-edit/role-customer-group-manager-user-edit.component';
import {CustomerGroup, RoleIdCustomerGroupIdManagerUserId} from '../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-role-customer-group-manager-user',
    templateUrl: './role-customer-group-manager-user.component.html',
    styleUrls: ['./role-customer-group-manager-user.component.scss']
})
export class RoleCustomerGroupManagerUserComponent extends ComponentCleaner {
    public customerGroup: CustomerGroup = {
        id: null,
        comment: null,
        active: null,
        name: null,
        subProjectIds: null,
        managerGroupIds: null,
        softwareCompanyIds: [],
        customerIds: null,
        contractedUsers: 3,
        imageFileId: null,
        customPortalTemplateId: null,
        backgroundImageTemplateId: null,
        htmlId: null,
        url: null,
        modified: null,
        optlock: null,
        imageUUID: null,
        exceededMaximumUsersMessage: null,
        partnerCustomerGroupId: null,
        blockAllAccess: null,
        blockAllAccessMessage: null,
        localAdminUsersOnly: null,
    };

    constructor(private customerGroupService: CustomerGroupService,
                private roleCustomerGroupManagerUserDao: RoleCustomerGroupManagerUserDAOService,
                public adminService: R2CloudAdminService,
                private managerUserDao: ManagerUserDaoService,
                private roleDao: RoleDAOService,
                private dialog: MatDialog) {
        super();
        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (customerGroup && customerGroup.id) {
                this.customerGroup = customerGroup;
                this.roleDao.get().subscribe((roles) => {
                    if (roles) {
                        this.adminService.roles = roles;
                        this.managerUserDao.filter({managerUsersInRoleCustomerGroupId: customerGroup.id}).subscribe((managerUsersCustomerGroup) => {
                            if (managerUsersCustomerGroup) {
                                this.adminService.managerUsersCustomerGroup = managerUsersCustomerGroup;
                                this.roleCustomerGroupManagerUserDao.getByParentId(customerGroup.id).subscribe((roleSubProjectManagerUserList) => {
                                    if (roleSubProjectManagerUserList) {
                                        this.adminService.roleCustomerGroupManagerUsers = roleSubProjectManagerUserList;
                                    } else {
                                        this.unloadCustomerGroupPermission();
                                    }
                                });
                            } else {
                                this.unloadCustomerGroupPermission();
                            }
                        });
                    } else {
                        this.unloadCustomerGroupPermission();
                    }
                });
            }
        }));
    }

    private unloadCustomerGroupPermission(): void {
        this.adminService.roles = [];
        this.adminService.roleCustomerGroupManagerUsers = [];
        this.adminService.managerUsersAdministrator = [];
    }

    openManagerUserCustomerGroupCreateModal(): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog'
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[] | CrudOperationWrapper | null) => {
            if (result && result.constructor === Array) {
                const managerUserOperation = result[0];
                const adUserOperation = result[1];
                if (managerUserOperation.operation === 'CREATE' || adUserOperation.operation === 'CREATE') {
                    const managerUserCheck = this.adminService.managerUsers.find(x => x.id === managerUserOperation.data.id);
                    if (!managerUserCheck) {
                        this.adminService.addManagerUser(managerUserOperation.data);
                    }
                    const adUserCheck = this.adminService.adUsers.find(x => x.id === adUserOperation.data.id);
                    if (!adUserCheck) {
                        this.adminService.addADUser(adUserOperation.data);
                    }
                }
            }
        });
        this.addSubscription(subscription);
    }


    openManagerUserCustomerEditModal(roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                roleIdCustomerGroupIdManagerUserId: roleIdCustomerGroupIdManagerUserId
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[] | CrudOperationWrapper | null) => {
            if (result && result.constructor === Array) {
                const managerUserOperation = result[0];
                const adUserOperation = result[1];

                if (managerUserOperation.operation === 'CREATE' || adUserOperation.operation === 'CREATE') {
                    const managerUserCheck = this.adminService.managerUsers.find(x => x.id === managerUserOperation.data.id);
                    if (!managerUserCheck) {
                        this.adminService.addManagerUser(managerUserOperation.data);
                    }
                }
            }
        });
        this.addSubscription(subscription);
    }


}

import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appDragDropFile]'
})
export class DragDropFileDirective {

    @Output()
    fileDropped = new EventEmitter<any>();

    constructor() {
    }


    @HostBinding('style.background-color') private background = '';
    @HostBinding('style.opacity') private opacity = '1';

    @HostListener('dragover', ['$event'])
    public dragOver(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#9ecbec';
        this.opacity = '0.8';
    }

    @HostListener('dragleave', ['$event'])
    public dragLeave(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '';
        this.opacity = '1';
    }

    @HostListener('drop', ['$event'])
    public drop(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '';
        this.opacity = '1';
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}

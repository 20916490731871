import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EntityHistoryComponent} from './entity-history/entity-history.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MglTimelineModule} from 'angular-mgl-timeline';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {FuseHighlightModule} from '../../../../@fuse/components';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        KluhSharedModule,
        BrowserAnimationsModule,
        MglTimelineModule,
        FuseHighlightModule,
        MatToolbarModule,
        MatButtonModule,
        MatDialogModule
    ],
    entryComponents: [EntityHistoryComponent],
    declarations: [EntityHistoryComponent]
})
export class JaversModule {
}

<div fxLayout="row" fxLayoutAlign="center start" class="box-search">
    <div class="search">
        <input type="text" class="searchTerm" placeholder="Buscar processos" [formControl]="searchMultiFilterCtrl"
               matInput autocomplete="off">
        <button type="submit" class="searchButton">
            <mat-icon class="bt-icon">search</mat-icon>
        </button>
    </div>
</div>
<div fxLayoutAlign="end start">
    <div>Total: <b>{{serviceDataSource.data.length}}</b></div>
</div>
<table matSort mat-table [dataSource]="serviceDataSource" class="width-100-percent mt-25">

    <ng-container matColumnDef="displayName" class="w-210">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
        <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome do serviço</th>
        <td mat-cell *matCellDef="let element"><small>{{element.name}}</small></td>
    </ng-container>

    <ng-container matColumnDef="pathName" class="w-210">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Caminho</th>
        <td mat-cell *matCellDef="let element"><small style="overflow-wrap: anywhere">{{element.pathName}}</small></td>
    </ng-container>

    <ng-container matColumnDef="startName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuário do serviço</th>
        <td mat-cell *matCellDef="let element"><small class="ml-5">{{element.startName}}</small></td>
    </ng-container>

    <ng-container matColumnDef="startMode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> inicialização</th>
        <td mat-cell *matCellDef="let element"><small>{{element.startMode}}</small></td>
    </ng-container>

    <ng-container matColumnDef="logOnAs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Log On As</th>
        <td mat-cell *matCellDef="let element"> {{element.logOnAs}} </td>
    </ng-container>

    <ng-container matColumnDef="firstSeenAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Primeira vez em
        </th>
        <td mat-cell *matCellDef="let element"> {{element.firstSeenAt | date: 'dd/MM/yyyy HH:mm' }} </td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef style="width: 156px;" mat-sort-header> Ação</th>
        <td mat-cell *matCellDef="let element" class="padding-none">
            <div [ngClass]="{'hidden': element.state === ServiceState.CONTINUE_PENDING}">
                <button type="button" *ngIf="element.state === ServiceState.STOPPED"
                        style="width: 34px;"  class="ml-5"  color="accent" mat-raised-button (click)="onStartStopService(element)">
                    Iniciar
                </button>

                <button type="button" *ngIf="element.state === ServiceState.RUNNING
                && (element.name.indexOf('r2cloud-linux-server-agent') < 0)
                && (element.name.indexOf('r2-cloud-server-agent') < 0) "
                        style="width: 34px;" class="ml-5" color="warn" mat-raised-button (click)="onStartStopService(element)">
                    Parar
                </button>
                <button type="button" *ngIf="element.state === ServiceState.RUNNING"
                        class="ml-5 w-80" color="primary" mat-raised-button (click)="onRestartService(element)">
                    Restart
                </button>
            </div>
            <img *ngIf="element.state === ServiceState.CONTINUE_PENDING"
                 src="{{domainPathService.staticURL}}icons/loading.gif" width="15"
                 height="15" alt="Atualizando">
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="serviceTable"></tr>
    <tr mat-row *matRowDef="let row; columns: serviceTable;"></tr>
</table>

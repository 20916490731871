import {Injectable} from '@angular/core';
import {ChartDataService} from '../../chart-data.service';
import {NetworkUsageDaoService} from './network-usage-dao.service';
import {MonitoringStompService} from '../../../websocket-stomp/monitoring-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkUsageService extends ChartDataService<NetworkUsageDaoService> {
    constructor(
        webStompService: MonitoringStompService,
        service: NetworkUsageDaoService,
    ) {
        super(
            'NetworkUsage',
            service,
            webStompService,
        );
    }
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Histórico</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
        <mgl-timeline [toggle]="false" [alternate]="false" *ngIf="snapshotList && snapshotList.length > 0">
            <mgl-timeline-entry *ngFor="let wrapper of snapshotList">
                <mgl-timeline-entry-header>
                    {{wrapper.snapshot.commitMetadata.commitDate | date:'medium'}}
                </mgl-timeline-entry-header>
                <mgl-timeline-entry-content>
                    <fuse-highlight lang="json" [code]="getChangedContent(wrapper.snapshot) | json">

                    </fuse-highlight>
                </mgl-timeline-entry-content>
                <mgl-timeline-entry-dot size="50" class="accent">
                    <img class="avatar history-avatar"
                         src="{{imageFileService.getManagerUserPicture(wrapper.managerUserSimple)}}?w=40&square=true"
                         width="40" height="40">
                </mgl-timeline-entry-dot>
                <mgl-timeline-entry-side fxLayout="column" fxLayoutAlign="space-between start" class="history-side">
                    <div>
                        Autor: {{wrapper.managerUserSimple.displayName}}
                    </div>
                    <div>
                        Email: {{wrapper.managerUserSimple.email}}
                    </div>
                    <div>
                        Operação: {{wrapper.snapshot.type}}
                    </div>
                </mgl-timeline-entry-side>
            </mgl-timeline-entry>
        </mgl-timeline>
    </div>
    <div fxLayout="row" fxLayoutAlign="center end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="submit" mat-raised-button color="accent" aria-label="Fechar" (click)="onClose()">
            Fechar
        </button>
    </div>
</div>
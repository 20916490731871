<table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef class="w-50"></th>
        <td mat-cell *matCellDef="let element">
            <img
                src="{{imageFileService.getPictureUrlFromPath(element.managerUserPicture, 40)}}"
                width="40" height="40" style="max-width:40px;margin-right: 15px;">
        </td>
    </ng-container>

    <ng-container matColumnDef="managerUserId">
        <th mat-header-cell *matHeaderCellDef class="w-300">Usuário</th>
        <td mat-cell *matCellDef="let element">
            <div class="pt-8 pb-8">
                <div class="mb-5">{{element.managerUserEmail}}</div>
                <table class="font-size-10">
                    <tr>
                        <td>Empresa</td>
                        <td>: {{element.managerUserCustomerGroupName}}</td>
                    </tr>
                    <tr>
                        <td>IP</td>
                        <td>: {{element.managerUserIp}}</td>
                    </tr>
                    <tr>
                        <td>Browser</td>
                        <td>: {{statisticsService.detectBrowserVersion(element.userAgent)}}
                        </td>
                    </tr>
                    <tr>
                        <td>S.O</td>
                        <td>: {{statisticsService.osDetectByUserAgent(element.userAgent)}}
                        </td>
                    </tr>
                </table>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="serverHostname">
        <th mat-header-cell *matHeaderCellDef>Servidor</th>
        <td mat-cell *matCellDef="let element" class="w-300">
            <div class="pt-5 pb-5">
                <div class="mb-5">{{element.serverHostname}}</div>
                <table class="font-size-10">
                    <tr>
                        <td>FQDN</td>
                        <td>: {{element.serverFQDN}}</td>
                    </tr>
                    <tr>
                        <td>IP</td>
                        <td>: {{element.serverIp}}
                        </td>
                    </tr>
                    <tr>
                        <td>S.O</td>
                        <td>: {{element.serverType}}
                        </td>
                    </tr>
                </table>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="adUserLogin">
        <th mat-header-cell *matHeaderCellDef>ADUser</th>
        <td mat-cell *matCellDef="let element" class="w-150">
            <div class="pt-5 pb-5">
                <div class="mb-5" *ngIf="!element.localAdmin">{{element.adUserLogin}}</div>
                <div class="mb-5" *ngIf="element.localAdmin">R2LocalAdmin</div>
                <table class="font-size-10">
                    <tr>
                        <td>Domínio</td>
                        <td>: {{element.adUserDomain}}</td>
                    </tr>
                    <tr>
                        <td>Projeto</td>
                        <td>: {{element.subProjectName}}
                        </td>
                    </tr>
                </table>
            </div>
        </td>
    </ng-container>



    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data do acesso</th>
        <td mat-cell *matCellDef="let element" class="w-190">
            {{element.createdAt | date: 'dd/MM/yyyy HH:mm - EEE'}}
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'yellow-200-bg': adminService.isExternalManagerUserId(row.managerUserId)}"
    ></tr>
</table>
<mat-paginator [pageSizeOptions]="[50, 100]" [length]="totalDocs"></mat-paginator>

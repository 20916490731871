import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {ADUser, ADUserServiceExternal, ADUserWithPassword, ManagerUser} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class AdUserServiceExternalDaoService extends BaseDao<ADUserServiceExternal> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'ad-user-service-external');
    }

    saveAdUserServiceExternalWithCheck(adUserWithPassword: ADUserWithPassword): Observable<ADUserServiceExternal> {
        return this.http.put<ADUserServiceExternal>(this.url + '/save-with-check', adUserWithPassword);
    }

    findByADUserIdAndSubProjectId(adUserId: number, subProjectId: number): Observable<ADUserServiceExternal> {
        return this.http.get<ADUserServiceExternal>(this.url + '/find-by-ad-user-id-and-sub-project-id/' + adUserId + '/' + subProjectId);
    }

    findAllBySubProjectId(subProjectId: number): Observable<ADUserServiceExternal[]> {
        return this.http.get<ADUserServiceExternal[]>(this.url + '/find-all-by-sub-project-id/' + subProjectId);
    }

    findAllADUserBySubProjectId(subProjectId: number): Observable<ADUser[]> {
        return this.http.get<ADUser[]>(this.url + '/find-all-ad-user-by-sub-project-id/' + subProjectId);
    }

    findAllManagerUserBySubProjectId(subProjectId: number): Observable<ManagerUser[]> {
        return this.http.get<ManagerUser[]>(this.url + '/find-all-manager-user-by-sub-project-id/' + subProjectId);
    }
}

<table mat-table [dataSource]="dataSource"
       class="rows-pointer mat-elevation-z0 width-100-percent bulk-update-table">

    <ng-container matColumnDef="new">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="p-10">
            <div *ngIf="unReadList" class="new-message mr-10">Nova</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element" class="pr-16 w-30">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="managerUserName">
        <th mat-header-cell *matHeaderCellDef>Usuário</th>
        <td mat-cell *matCellDef="let element" class="p-10 width-100-percent">
            <div>
                <div fxFlex fxLayout="row" fxLayoutAlign="start start">
                    <div *ngIf="(adminService.getManagerUser(element.managerUserId)) as managerUser; else notFound">
                        <div>
                            <img class="avatar"
                                 src="{{imageFileService.getManagerUserPicture(managerUser)}}?w=40&square=true"/>
                        </div>
                        <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="mt-3">
                            <div>{{managerUser?.displayName}}</div>
                            <small>{{managerUser?.email}}</small>
                            <small class="text-max-350">{{element.description}}</small>
                        </div>
                    </div>
                    <ng-template #notFound>
                        <div>
                            <img class="avatar"
                                 src="{{imageFileService.getManagerUserPictureUrl(0, 40)}}"/>
                        </div>
                        <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="mt-3">
                            <div>{{element.email}}</div>
                            <small class="text-max-350">{{element.description}}</small>
                        </div>
                    </ng-template>
                </div>
            </div>
        </td>
    </ng-container>


    <ng-container matColumnDef="marketplaceProductAvatarId">
        <th mat-header-cell *matHeaderCellDef>Serviço</th>
        <td mat-cell *matCellDef="let element" class="p-10 w-400">
            <table>
                <tr>
                    <td>
                        <img
                            src="{{imageFileService.getMarketPlaceProductAvatarPictureUrl(marketplaceService.findById(element.marketplaceProductId, marketplaceProductList)?.avatarUUID)}}"
                            width="80"/>
                    </td>
                    <td>
                        <div>{{marketplaceService.findById(element.marketplaceProductId, marketplaceProductList)?.company}}</div>
                        <div class="text-max-350">
                            <small> {{marketplaceService.findById(element.marketplaceProductId, marketplaceProductList)?.name}}</small>
                        </div>
                    </td>
                </tr>
            </table>
        </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Criado em</th>
        <td mat-cell *matCellDef="let element" class="w-120">
            <small>{{element.createdAt | date: 'dd/MM/yyyy HH:mm' }}</small>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="marketplace-order-order"
        (click)="onOpenRequest(row)"></tr>
</table>

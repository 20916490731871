import {Component} from '@angular/core';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {DotNetServerAgentUpdaterFileDaoService} from '../services/server-agent-updater-file-dao.service';
import {DotNetServerAgentUpdaterFile} from '../../../models';
import {ConfirmDialogComponent, createErrorModal} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-server-agent-updater-file-card',
    templateUrl: './server-agent-updater-file-card.component.html',
    styleUrls: ['./server-agent-updater-file-card.component.scss']
})
export class DotNetServerAgentUpdaterFileCardComponent {

    constructor(
        public serverPluginService: DotNetServerPluginService,
        public serverAgentUpdaterFileDao: DotNetServerAgentUpdaterFileDaoService,
        public dialog: MatDialog
    ) {
    }

    onFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget && currentTarget.files && currentTarget.files.length === 1) {
            this.setFileFormData(currentTarget.files);
        }
    }

    private setFileFormData(files: FileList): void {
        const file = files[0];
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const serverAgentUpdaterFile: DotNetServerAgentUpdaterFile = {
            active: true, comment: undefined, id: undefined, md5: undefined, version: undefined,
            modified: null,
            optlock: null,
            fileUUID: null

        };
        const json = JSON.stringify(serverAgentUpdaterFile);
        formData.append('json', json);
        this.serverPluginService.onServerAgentUpdaterFileUploadFinished(this.serverAgentUpdaterFileDao.upload(formData))
            .subscribe();
    }

    onDownload(serverAgentUpdaterFile: DotNetServerAgentUpdaterFile): void {
        this.serverAgentUpdaterFileDao.download(serverAgentUpdaterFile.id).subscribe((data) => {
            if (data) {
                const blob = new Blob([data], {type: 'application/octet-stream'});
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, this.getFileName(serverAgentUpdaterFile));
                } else {
                    const a = document.createElement('a') as HTMLAnchorElement;
                    a.style['display'] = 'none';
                    a.href = URL.createObjectURL(blob);
                    a.download = this.getFileName(serverAgentUpdaterFile);
                    a.click();
                }
            }
        });
    }

    getFileName(serverAgentUpdaterFile: DotNetServerAgentUpdaterFile): string {
        return 'R2CloudServerAgentUpdater.exe';
    }

    fileDrop(files: FileList): void {
        if (files.length > 1) {
            createErrorModal(this.dialog, 'Apenas um arquivo é suportado.');
            return;
        }
        this.setFileFormData(files);
    }

    onDelete(serverAgentUpdaterFile: DotNetServerAgentUpdaterFile): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa versão do Atualizador do Agente?<br><br>' + serverAgentUpdaterFile.version,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverAgentUpdaterFileDao.remove(serverAgentUpdaterFile.id).subscribe(deleted => {
                    this.serverPluginService.deleteFromList(serverAgentUpdaterFile.id, this.serverPluginService.serverAgentUpdaterFiles);
                });
            }
        });
    }
}

import {Component} from '@angular/core';
import {RemoteAppDaoService} from '../remote-app-dao.service';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {RemoteAppCreateEditComponent} from '../remote-app-create-edit/remote-app-create-edit.component';
import {FormBuilder, FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, startWith} from 'rxjs/operators';
import {ComponentCleaner} from '../../../component-cleaner';
import {combineLatest} from 'rxjs';
import {ADDomain, DesktopServer, RemoteApp} from '../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-remote-app-list',
    templateUrl: './remote-app-list.component.html',
    styleUrls: ['./remote-app-list.component.scss']
})
export class RemoteAppListComponent extends ComponentCleaner {
    public remoteApps: RemoteApp[];
    public filteredRemoteApps: RemoteApp[];
    public adDomains: ADDomain[];
    public desktopServers: DesktopServer[];
    public remoteAppNameFilterControl: FormControl;
    public serverNameFilterControl: FormControl;
    public domainNameFilterControl: FormControl;

    constructor(private dao: RemoteAppDaoService,
                private adDomainDao: AdDomainDaoService,
                private desktopServerDao: DesktopServerDaoService,
                private dialog: MatDialog,
                private fb: FormBuilder) {
        super();
        this.dao.get().subscribe((remoteApps) => {
            this.remoteApps = remoteApps;
            if (!this.remoteApps) {
                this.remoteApps = [];
            }
            this.filteredRemoteApps = this.remoteApps;
        });
        this.adDomainDao.get().subscribe((adDomains) => {
            this.adDomains = adDomains;
        });
        this.desktopServerDao.get().subscribe((desktopServers) => {
            this.desktopServers = desktopServers;
        });
        this.remoteAppNameFilterControl = this.fb.control(['']);
        this.serverNameFilterControl = this.fb.control(['']);
        this.domainNameFilterControl = this.fb.control(['']);
        const remoteAppNameFilter$ = this.remoteAppNameFilterControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(<string>null)
        );
        const serverNameFilter$ = this.serverNameFilterControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(<string>null)
        );
        const domainNameFilter$ = this.domainNameFilterControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(<string>null)
        );
        const subscription = combineLatest([remoteAppNameFilter$, serverNameFilter$, domainNameFilter$]).subscribe((value) => {
            if (this.remoteApps) {
                const remoteAppNameFilter: string = value[0];
                const serverNameFilter: string = value[1];
                const domainNameFilter: string = value[2];
                let result: RemoteApp[] = this.remoteApps;
                let filteredDesktopServers: DesktopServer[] = this.desktopServers;
                if (domainNameFilter && this.adDomains && filteredDesktopServers) {
                    const domains = this.adDomains.filter((input) => {
                        return input.domainName.toLowerCase().indexOf(domainNameFilter.toLowerCase()) !== -1;
                    });
                    filteredDesktopServers = filteredDesktopServers.filter((input) => {
                        for (const domain of domains) {
                            if (input.adDomainId === domain.id) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                if (serverNameFilter || filteredDesktopServers) {
                    if (serverNameFilter) {
                        filteredDesktopServers = filteredDesktopServers.filter((input) => {
                            return input.hostname.toLowerCase().indexOf(serverNameFilter.toLowerCase()) !== -1;
                        });
                    }
                    result = result.filter((input) => {
                        for (const server of filteredDesktopServers) {
                            if (input.desktopServerId === server.id) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                if (remoteAppNameFilter) {
                    result = result.filter((input) => {
                        return input.name.toLowerCase().indexOf(remoteAppNameFilter.toLowerCase()) !== -1;
                    });
                }
                this.filteredRemoteApps = result;
            }
        });
        this.addSubscription(subscription);
    }

    /*
    onRemoteAppCreated(remoteApp: RemoteApp): void {
        if (remoteApp && this.remoteApps) {
            this.remoteApps.push(remoteApp);
        }
    }
    */

    getServerFQDN(remoteApp: RemoteApp): string {
        let result = '';
        if (remoteApp && this.desktopServers) {
            const desktopServer = this.desktopServers.find((object) => {
                return remoteApp.desktopServerId === object.id;
            });
            if (desktopServer && this.adDomains) {
                const adDomain = this.adDomains.find((object) => {
                    return desktopServer.adDomainId === object.id;
                });
                if (adDomain) {
                    result = desktopServer.hostname + '.' + adDomain.domainName;
                }
            }
        }
        return result;
    }

    onEdit(remoteApp: RemoteApp): void {
        this.openModal(remoteApp);
    }

    onCreate(): void {
        const remoteApp: RemoteApp = {
            id: null,
            active: true,
            comment: null,
            name: null,
            app: null,
            desktopServerId: null,
            adGroupIds: [],
            adUserIds: [],
            adUserPoolRelationshipIds: [],
            appArgs: null,
            serviceApp: false,
            subProjectId: null,
            alias: null,
            openDelay: 0,
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null,
            imageBase64: null,
            imageUUID: null,
            localUser: null
        };
        this.openModal(remoteApp);
    }

    private openModal(remoteApp: RemoteApp): void {
        const dialogRef = this.dialog.open(RemoteAppCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                remoteApp: remoteApp,
                adDomains: this.adDomains,
                desktopServers: this.desktopServers
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((filteredRemoteApp) => {
            const index = this.remoteApps.findIndex((y) => y.id === filteredRemoteApp.id);
            if (remoteApp.id) {
                this.remoteApps[index] = filteredRemoteApp;
            } else {
                this.remoteApps.push(filteredRemoteApp);
            }
        });
        this.addSubscription(subscription);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {StatisticsComponent} from './statistics.component';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import {SessionLogComponent} from './session-log/session-log.component';
import {RemoteAppLogComponent} from './remote-app-log/remote-app-log.component';
import {ServerLogComponent} from './server-log/server-log.component';
import {RemoteAppLogDaoService} from './remote-app-log/remote-app-log-dao.service';
import {ServerLogDaoService} from './server-log/server-log-dao.service';

const routes = [
    {
        path: 'statistics',
        component: StatisticsComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.AUDIT']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule

    ],
    declarations: [StatisticsComponent, SessionLogComponent, RemoteAppLogComponent, ServerLogComponent],
    providers: [RemoteAppLogDaoService, ServerLogDaoService]
})
export class StatisticsModule {
}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {Thread} from '../../../models-ai';
import {SearchCriteria} from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class ThreadDaoService extends BaseDao<any> {
    private aiURL = '';

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator,
    ) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    getThreads(customerGroup: number, searchCriteria: SearchCriteria): Observable<Thread[]> {
        return this.http.put<Thread[]>(this.aiURL + 'threads/' + customerGroup, searchCriteria , true);
    }

    getThreadByProviderThreadId(providerThreadId: string): Observable<Thread> {
        return this.http.get<Thread>(this.aiURL + 'threads/provider-thread-id/' + providerThreadId, null, null, true);
    }

    findById(threadId: number): Observable<Thread> {
        return this.http.get<Thread>(this.aiURL + 'threads/id/' + threadId, null, null, true);
    }

    deleteThread(thread: Thread): Observable<void> {
        return this.http.delete(this.aiURL + 'threads/', thread, true);
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {JaversModule} from '../javers/javers.module';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {RemoteAppRelationshipDaoService} from './remote-app-relationship-dao.service';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        JaversModule,
        MatTableModule,
        NgBytesPipeModule,
        FontAwesomeModule
    ],
    declarations: [],
    providers: [
        RemoteAppRelationshipDaoService
    ],
    entryComponents: []
})
export class RemoteAppRelationshipModule {
}

<div class="fuse-card" *ngFor="let product of marketplaceProductList">
    <img src="{{imageFileService.getMarketPlaceProductAvatarPictureUrl(product.avatarUUID)}}" width="344" height="195" />
    <div class="p-16">
        <div class="h1">{{product.name}}</div>
        <div class="h4 secondary-text">{{product.company}}</div>
    </div>

    <div class="p-16 pt-0 line-height-1.75 description">
        {{product.shortDescription}}
    </div>

    <div class="p-14" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="accent"
                class="marketplace-product-list-product-detail"
                id="{{product.name}}"
                (click)="onShowProductDetail(product.id)">
            Conhecer
        </button>
        <div>
            <mat-icon
                class="{{marketplaceService.getStarRating(product,1)}}">{{marketplaceService.checkIfIsHalfStar(product, 1)}}</mat-icon>
            <mat-icon
                class="{{marketplaceService.getStarRating(product,2)}}">{{marketplaceService.checkIfIsHalfStar(product, 2)}}</mat-icon>
            <mat-icon
                class="{{marketplaceService.getStarRating(product,3)}}">{{marketplaceService.checkIfIsHalfStar(product, 3)}}</mat-icon>
            <mat-icon
                class="{{marketplaceService.getStarRating(product,4)}}">{{marketplaceService.checkIfIsHalfStar(product, 4)}}</mat-icon>
            <mat-icon
                class="{{marketplaceService.getStarRating(product,5)}}">{{marketplaceService.checkIfIsHalfStar(product, 5)}}</mat-icon>
        </div>
    </div>

</div>

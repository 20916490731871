export enum SessionEvent {
    ViewSizeChanged = 0,
    WillConnect = 1,
    DidConnect = 2,
    WillDisconnect = 3,
    DidDisconnect = 4,
    LoginCompleted = 5,
    ConnectionHealthStateChanged = 6,
    MousePointerChanged = 7,
    ShowDefaultMousePointer = 8,
    GetRemoteClipboardContent = 9,
    ClipboardContentRequest = 10,
    SetRemoteClipboardFormatsComplete = 11,
    FocusChanged = 12,
    WindowIconChanged = 13,
    WindowTitleChanged = 14,
    WindowLaunched = 15,
    ApplicationIdChanged = 16,
    CredentialsNeeded = 17,
    Error = 18,
    TrustChallenge = 19,
    DisplayDesktopBackground = 20
}

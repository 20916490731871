import {AfterViewInit, Component} from '@angular/core';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {GatewayServerDaoService} from '../gateway-server-dao.service';
import {CustomerGroup, GatewayServer} from '../../../models';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {ComponentCleaner} from '../../../component-cleaner';
import {filter} from 'rxjs/operators';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {GatewayServerEditComponent} from '../gateway-server-edit/gateway-server-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {GatewayServerInUseComponent} from '../gateway-server-in-use/gateway-server-in-use.component';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';

@Component({
    selector: 'app-gateway-server-list',
    templateUrl: './gateway-server-list.component.html',
    styleUrls: ['./gateway-server-list.component.scss']
})
export class GatewayServerListComponent extends ComponentCleaner implements AfterViewInit {
    loading: boolean[] = [];
    constructor(
        private customerGroupService: CustomerGroupService,
        private gatewayServerDaoService: GatewayServerDaoService,
        private dialog: MatDialog,
        private dialogRef: MatDialog,
    ) {
        super();
        this.appCanCreateList = [
            {type: 'GatewayServer', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
    }

    appCanCreateList: PermissionWrapper[];
    gatewayServerList: GatewayServer[] = [];
    customerGroup: CustomerGroup;

    protected readonly faCircle = faCircle;

    ngAfterViewInit(): void {
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.customerGroup = customerGroup;
                this.loadGatewayServerListFromDao(customerGroup);
            }
        });
        this.addTimeout(setInterval(() => {
            this.loadGatewayServerListFromDao(this.customerGroup);
        }, 60000));
    }

    private loadGatewayServerListFromDao(customerGroup: CustomerGroup): void {
        this.gatewayServerList = [];
        if (customerGroup.id) {
            this.gatewayServerDaoService.findAllBy(customerGroup.id, customerGroup.name).subscribe(gatewayServerList => {
                this.gatewayServerList = gatewayServerList;
            });
        }
    }

    edit(gatewayServer: GatewayServer): void {
        const dialogRef = this.dialog.open(GatewayServerEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                gatewayServer: gatewayServer,
                customerGroupId: this.customerGroup.id,
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.data) {
                const index = this.gatewayServerList.findIndex(value => value.id === result.data.id);
                this.gatewayServerList[index] = result.data;
            }
            this.loading[gatewayServer.id] = true;
            this.addTimeout(setTimeout(() => {
                this.loading[gatewayServer.id] = false;
                this.loadGatewayServerListFromDao(this.customerGroup);
            }, 8000));
        }));
    }

    gatewayServerInUseModal(gatewayServer: GatewayServer): void {
        const dialogRef = this.dialog.open(GatewayServerInUseComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                gatewayServer: gatewayServer
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((_: CrudOperationWrapper) => {
            this.dialogRef.closeAll();
        }));
    }

    showStatusData(gatewayServer: GatewayServer): void {
        const lastStatusUpdatedAt = moment(gatewayServer.lastStatusUpdatedAt).format('DD/MM/YYYY HH:mm:ss');
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<h2>Última data de mudança do status:</h2><h2>' + lastStatusUpdatedAt + '</h2>',
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((_) => {
        });
        this.addSubscription(subscription);
    }
}

    <div fxLayout="column" fxLayoutAlign="center stretch">
        <div>
            <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
                Escolha um R2 Scaling existente ou crie um novo
            </mat-toolbar>
        </div>
        <ng-container
                      *ngIf="(desktopServerPoolList.length > 0) && adminService.subProject.projectId">


            <mat-slide-toggle [formControl]="useExistingPoolControl"
                              style="padding:40px 60px 60px 310px;">
                Usar um R2 Scaling existente
            </mat-slide-toggle>

            <div *ngIf="desktopServerPoolList && useExistingPoolControl.value"
                 class=" grey-50-bg border-top" style="padding: 10px 60px 10px 60px;">


                <div fxLayout="column" fxLayoutAlign="space-evenly stretch"
                     style="height: 140px; margin-top: 20px;   margin-bottom: 20px;">

                    <mat-form-field *ngIf="desktopServerPoolList && desktopServerPoolList.length > 0">
                        <mat-select placeholder="R2 Scaling existentes" [formControl]="desktopServerPoolIdControl">
                            <mat-option *ngFor="let desktopServerPool of desktopServerPoolList" [value]="desktopServerPool.id">
                                {{desktopServerPool.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </ng-container>
        <div *ngIf="!(desktopServerPoolList && useExistingPoolControl.value)" fxLayout="row"
             fxLayoutAlign="space-around end" fxFill class="p-50 grey-50-bg border-top">
            <div>
                <button mat-raised-button color="accent" (click)="onCreateNew()">Criar novo R2 Scaling</button>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around center"
             fxFill class="p-16 grey-50-bg border-top">

            <button type="button" mat-raised-button color="accent" (click)="onSave()"
            [disabled]="!desktopServerPoolIdControl.value && !isNumberGreaterThanZero(desktopServerPoolIdControl.value)"
            >Salvar</button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>

    </div>

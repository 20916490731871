import {Component, Inject, OnInit} from '@angular/core';
import {ErrorDetailsComponent} from '../error-details/error-details.component';
import {MatDialog} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'app-error-snack-bar',
    templateUrl: 'error-snack-bar-component.html',
    styleUrls: ['error-snack-bar-component.scss']
})
export class ErrorSnackBarComponent implements OnInit {
    public message: string;
    constructor(@Inject(MAT_SNACK_BAR_DATA) private data: any, private dialog: MatDialog){}

    ngOnInit(): void {
        if (this.data && this.data.err){
            if (this.data.err.status === 403){
                this.message = 'Requisição não autorizada.';
            } else {
                this.message = 'Ocorreu um erro.';
            }
        }
    }

    onDetails(): void {
        this.dialog.open(ErrorDetailsComponent, {
            panelClass: 'generic-edit-dialog-large',
            data: {
                err: this.data.err,
                req: this.data.req
            }
        });
    }
}

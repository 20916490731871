<div class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
         style="z-index: 99;margin-top: 12px;">
        <div fxLayout="row" fxLayoutAlign="start center" style="min-height: 92px;">
            <div>
                <mat-icon class="mr-10 font-size-24">brush</mat-icon>
                <span class="h1 m-0">Temas do layout </span>
            </div>
            <button  type="button" *ngIf="!clearingCache"
                     class="m-20"
                     mat-stroked-button
                     (click)="clearCache()"
                     >Limpar cache
            </button>
            <mat-progress-spinner *ngIf="clearingCache" diameter="27" mode="indeterminate" class="progress-spinner ml-25"></mat-progress-spinner>
        </div>
        <div>
            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="search" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input placeholder="buscar tema"
                           [formControl]="searchMultiFilterCtrl"
                           matInput fxFlex
                           autocomplete="off">
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="custom-layout">


            <mat-tab-group animationDuration="0ms" disableRipple="true" id="custom-layout-tab" *ngIf="customerGroup">
                <mat-tab label="Temas imagens de fundo" id="custom-layout-background" style="padding-left: 0px">
                    <h2 class="text-align-center" *ngIf="search"><span class="primary-200-fg">Resultado de busca por: </span>"{{search}}"</h2>
                    <app-background-image-template-list
                        [backgroundImageTemplateList]="backgroundImageTemplateFilterList"
                        [customerGroup]="customerGroup"
                        [isAdmin] = "isAdmin"></app-background-image-template-list>
                </mat-tab>

                <mat-tab label="Temas do portal" id="custom-layout-portal" style="padding-left: 0px">
                    <h2 class="text-align-center"  *ngIf="search"><span class="primary-200-fg">Resultado de busca por: </span>"{{search}}"</h2>
                    <app-custom-portal-list
                        [customPortalTemplateList]="customPortalTemplateFilterList"
                        [customerGroup]="customerGroup"
                        [isAdmin] = "isAdmin"></app-custom-portal-list>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

import {Logger} from './logger';
import {ObjectHelper} from './object-helper';

export class CanvasGraphicsSink {
    private canvasElement: HTMLCanvasElement;
    private canvas: HTMLCanvasElement;
    private viewPort: any;

    constructor(private graphicsSinkDescriptor: any, private isFocused: boolean, private logger: Logger) {
        this.canvasElement = document.createElement('canvas');
        this.canvasElement.id = 'hidden-canvas-' + new ObjectHelper().createGuid();
        this.canvasElement.width = this.graphicsSinkDescriptor.width, this.canvasElement.height = this.graphicsSinkDescriptor.height;
        this.canvas = null;
        this.viewPort = null;
    }

    setCanvas(canvas: any): void {
        this.canvas = canvas;
    }

    Destroy(): void {
        console.debug('(CanvasGraphicsSink) destroy');
        this.graphicsSinkDescriptor &&
        (this.graphicsSinkDescriptor.delete(), this.graphicsSinkDescriptor = null),
        this.viewPort && (this.viewPort.delete(), this.viewPort = null), this.canvasElement && (this.canvasElement = null);
    }

    GetCanvas(aa: boolean): any {
        return aa ? this.canvas : this.canvasElement;
    }

    Present(b: any): void {
        console.debug('(CanvasGraphicsSink) Present');
        console.debug(b);
        if (this.canvasElement && this.canvas && this.viewPort) {
            const c = b.get(0);
            for (let d = 1; d < b.size(); d++) {
                const h = b.get(d);
                c.extend_rect(h), h.delete();
            }
            c.intersects(this.viewPort) && (c.clip(this.viewPort),
                requestAnimationFrame(() => {
                    this.refreshCanvas(c, this.viewPort);
                    c.delete();
                }));
        } else {
            this.logger.error('[CanvasGraphicsSinkFactory] Not attached to a canvas');
        }
        b.delete();
    }

    refreshCanvas(aa: any, b: any): void {
        if (this.isFocused && this.canvas && this.canvasElement) {
            const ctx = this.canvas.getContext('2d', {alpha: false});
            ctx.drawImage(this.canvasElement,
                aa.left,
                aa.top,
                aa.width(),
                aa.height(),
                aa.left - b.left,
                aa.top - b.top,
                aa.width(),
                aa.height());
        }
    }

    refreshWholeCanvas(): void {
        this.canvasElement && this.canvas && this.viewPort && requestAnimationFrame(() => this.refreshCanvas(this.viewPort, this.viewPort));
    }

    GetDescriptor(): any {
        return this.graphicsSinkDescriptor;
    }

    SetViewport(rectangle: any): void {
        rectangle ? this.logger.debug('[CanvasGraphicsSinkFactory] Setting graphics sink viewport: width = ' +
            rectangle.width() + ' height = ' + rectangle.height()) : this.logger.debug('[CanvasGraphicsSinkFactory] Removing graphics sink viewport'), this.viewPort = rectangle;
    }
}

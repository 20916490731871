<mat-sidenav-container id="main-desktop" class="page-layout simple tabbed " fxLayout="column">
    <mat-sidenav-content id="main-desktop-content">

        <app-background type="dynamic" [delayMs]="3000"></app-background>
        <div id="client-config" *ngIf="myForm">
            <button title="Configurações de impressora, Disco local e VPN" type="button" mat-mini-fab color="primary"
                    (click)="sidenav.open(); findDesktopServerOnline();">
                <mat-icon class="mat-24">
                    settings
                </mat-icon>
            </button>
        </div>
        <div class="fuse-card">
            <div *ngIf="isWindowsOS()">
                <div class="card-supporting-text">
                    <div *ngIf="subProjectIdList.length > 1">
                        <mat-form-field class="full-width" style="padding:14px 14px 0 14px;" appearance="outline">
                            <mat-label>Projetos</mat-label>
                            <mat-select placeholder="Projetos" [formControl]="subProjectFilterControl">
                                <mat-option *ngFor="let subProjectId of subProjectIdList" [value]="subProjectId">
                                    {{ findSubProjectById(subProjectId).name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="stompConnectionStatus === StompConnectionStatus.OK">
                        <div fxLayout="row wrap" *ngIf="clientConnected; else clientNotConnected">
                            <ng-container *ngFor="let remoteAppWrapper of remoteAppWrapperCustomList">
                                <div
                                    *ngIf="remoteAppWrapper.remoteAppReadyStatus !== 'DELETING' && (!subProjectFilterControl.value || subProjectFilterControl.value == remoteAppWrapper.subProjectId)"
                                    [title]="remoteAppWrapper?.name"
                                    [matTooltip]="getTooltip(remoteAppWrapper)"
                                    [matTooltipDisabled]="remoteAppWrapper.remoteAppReadyStatus === 'READY'"
                                    appDebounceClick
                                    (debounceClick)="!remoteAppWrapper.loading && onOpen(remoteAppWrapper)"
                                    [class.remote-app-error]="remoteAppWrapper.remoteAppReadyStatus === 'SERVER_NOT_CONNECTED'"
                                    [class.remote-app-warn]="remoteAppWrapper.remoteAppReadyStatus === 'CLIENT_NOT_CONNECTED' || remoteAppWrapper.remoteAppReadyStatus === 'PREPARING'"
                                    [class.transparent]="remoteAppWrapper.loading"
                                    [class.remote-app-ok]="remoteAppWrapper.remoteAppReadyStatus === 'READY'"
                                    class="m-14 remote-app" fxLayout="column" fxLayoutAlign="center center">
                                    <div class="loader"></div>
                                    <img
                                        alt="{{remoteAppWrapper.name}}"
                                        [class.remote-app-image-error]="remoteAppWrapper.remoteAppReadyStatus === 'SERVER_NOT_CONNECTED'"
                                        [class.remote-app-image-warn]="remoteAppWrapper.remoteAppReadyStatus === 'CLIENT_NOT_CONNECTED'  || remoteAppWrapper.remoteAppReadyStatus === 'PREPARING'"
                                        [src]=imageFileService.getRemoteAppPictureUrl(remoteAppWrapper.imageUUID)

                                        width="64" height="64">
                                    <div class="remote-app-name mt-8">{{remoteAppWrapper.name}}</div>
                                </div>
                            </ng-container>
                            <div *ngIf="remoteAppWrapperCustomList.length < 1 && !timeoutLoadApps" class="transparent"
                                 style="margin-top: 25px;margin-left: 15px;">
                                <div class="loader" style="width: 30px;height: 30px;"></div>
                            </div>
                            <h2 style="padding-left: 20px"
                                *ngIf="remoteAppWrapperCustomList.length < 1 && timeoutLoadApps">
                                Nenhum aplicativo cadastrado.
                            </h2>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="stompConnectionStatus === StompConnectionStatus.DUPLICATE_LOGIN">
                        <div class="padding-text">
                            <div>
                                <h2 style="margin-top: 0;">Login Duplicado.</h2>
                                <span>
                    Seu usuário foi aberto em outro navegador.
                      </span>
                            </div>
                            <div style="margin-top: 30px;">
                                <button type="button" mat-raised-button (click)="connectStomp()">Abrir por aqui</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="stompConnectionStatus === StompConnectionStatus.DISCONNECTED">
                        <div class="padding-text" *ngIf="showReconnectButton">
                            <div *ngIf="!waiting">
                      <span>
<!--                       Não Conectado-->
                      </span>
                                <div>
                                    <button *ngIf="!messageLoadingShow" color="accent" style="margin: 40px;"
                                            id="restartApps" type="button" mat-raised-button
                                            (click)="restartApps()">Mostrar Aplicações
                                    </button>
                                    <mat-spinner *ngIf="messageLoadingShow" [diameter]="24"
                                                 color="primary" style="margin: 40px;"></mat-spinner>
                                </div>
                            </div>
                            <div *ngIf="waiting">
                                <button style="margin: 15px;" color="primary" type="button" mat-raised-button
                                        (click)="refreshBrowser()">Recarregar
                                </button>
                                Conectando... {{waitingTimer}}
                            </div>

                        </div>


                        <div class="padding-text" *ngIf="!showReconnectButton">
                            Carregando...
                        </div>


                    </ng-container>
                    <ng-template #clientNotConnected>
                        <div class="padding-text">
                            <ng-container *ngIf="!showInstall && !afterInstall && !clientUpdate">
                                <div>Iniciando comunicação com Agente de Conexão.</div>
                                <mat-progress-bar mode="query"></mat-progress-bar>
                                <div *ngIf="waitingCommunicationRestartButtonShow">
                                    <button style="margin-bottom: 30px;" type="button" mat-raised-button
                                            (click)="downloadLatestVersion()">Download
                                    </button>
                                    <button color="accent" style="margin: 40px;"
                                            type="button" mat-raised-button
                                            (click)="startClient($event)">Tentar abrir
                                    </button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="clientUpdate">
                                <h2> Atualização em Andamento.</h2>
                            </ng-container>


                            <ng-container *ngIf="showInstall && !clientConnectedOnce">


                                <div class="box-message-home">
                                    <h2>O {{defaultCustomPortalTemplate?.title}} não está instalado nesse computador</h2>
                                    <div style="margin-bottom: 20px;">faça o download e instale o agente do {{defaultCustomPortalTemplate?.title}}:
                                    </div>
                                    <button style="margin-bottom: 30px;" type="button" mat-raised-button
                                            (click)="downloadLatestVersion()">Download
                                    </button>
                                </div>
                                <hr style="    margin-bottom: 30px;">
                                <div class="box-message-home">
                                    <h2>Já instalei o {{defaultCustomPortalTemplate?.title}} nesse computador</h2>
                                    <div style="margin-bottom: 20px;">
                                        Caso já o tenha instalado, clique em abrir para tentar novamente.
                                    </div>
                                    <button style="margin-bottom: 15px" color="accent" type="button" mat-raised-button
                                            (click)="startClient($event)">Tentar abrir
                                    </button>
                                </div>
                            </ng-container>


                            <ng-container *ngIf="afterInstall">
                                <h2>Após a instalação, clique em abrir para tentar novamente.</h2>
                                <button type="button" mat-raised-button (click)="startClient($event)">Abrir</button>
                            </ng-container>
                            <ng-container *ngIf="!clientUpdate && clientConnectedOnce">
                                <button *ngIf="!messageLoadingShow" color="accent" style="margin: 40px;"
                                        id="startClient" type="button" mat-raised-button
                                        (click)="loadingShow()">
                                    <span>Mostrar Aplicações.</span>
                                </button>
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="!isWindowsOS()">
                <div class="card-supporting-text" *ngIf="!customerGroup?.localAdminUsersOnly">
                    <h2 class="ml-20">Por favor utilize o (Desktop HTML5).
                    </h2>
                    <button style="margin: 0 20px 20px 20px;" [routerLink]="['/webclient-html5']" type="button"
                            mat-raised-button color="accent"
                            aria-label="Entrar por Desktop HTML5">
                        Entrar por Desktop HTML5
                    </button>
                </div>
                <div class="card-supporting-text" *ngIf="customerGroup?.localAdminUsersOnly">
                    <h2 class="ml-20">Para ter acesso, por favor entre em contato com o suporte.
                    </h2>

                    <button style="margin: 0 20px 20px 20px;"  (click)="helpLink()" type="button"
                            mat-raised-button color="accent"
                            aria-label="Suporte">
                        Suporte
                    </button>

                </div>
            </div>
        </div>
    </mat-sidenav-content>
    <mat-sidenav position="end" #sidenav class="client-config-sidenav">
        <mat-spinner *ngIf="desktopServerLoading" [diameter]="24" color="primary"></mat-spinner>
        <div
            [hidden]="!desktopServerList || !desktopServerService.existsDesktopServerConnectedOfList(desktopServerList)">
            <h2>Desconectar de servidores</h2>
            <table class="mb-30">
                <thead>
                <tr class="bg-white">
                    <th class="w-130">Servidor</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>

                <tr *ngFor="let desktopServer of desktopServerList"
                    [hidden]="!desktopServerService.desktopServerConnected(desktopServer.id) ||
                    !(desktopServerService.getAdUser(desktopServer.adDomainId) || existsDesktopServerIdInMyADUserPoolRelationshipDesktopServerIdList(desktopServer.id))">
                    <td>
                        <div class="text-max-120">{{desktopServer.hostname}}</div>
                    </td>
                    <td>
                        <mat-spinner *ngIf="desktopServerService.disconnectLoading[desktopServer.id]" [diameter]="24"
                                     color="primary" style="margin-left: 48px;"></mat-spinner>
                        <button mat-button color="accent"
                                *ngIf="!desktopServerService.disconnectLoading[desktopServer.id]"
                                (click)="logoffMyUserOnDesktopServer(desktopServer, false)">
                            Desconectar
                        </button>
                    </td>
                    <td>
                        <mat-spinner *ngIf="desktopServerService.forceDisconnectLoading[desktopServer.id]"
                                     [diameter]="24"
                                     color="primary" style="margin-left: 48px;"></mat-spinner>
                        <button mat-button color="accent"
                                *ngIf="!desktopServerService.forceDisconnectLoading[desktopServer.id]"
                                (click)="logoffMyUserOnDesktopServer(desktopServer, true)">
                            Forçar Desconexão
                        </button>
                    </td>
                </tr>
            </table>

            <div fxLayout="row" fxLayoutAlign="space-evenly start" class="mt-10 mb-30">
                <button type="button" mat-raised-button color="primary" (click)="onCancel(); sidenav.close()">Cancelar
                </button>
            </div>
            <mat-divider></mat-divider>
        </div>

        <div class="mt-10">
            <form *ngIf="myForm" [formGroup]="myForm"
                  (submit)="myForm.valid && myForm.dirty && onSubmit(); sidenav.close()">

                <div fxLayout="column" fxLayoutAlign="space-evenly">
                    <h2>Configurações</h2>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-evenly" class="client-config-form">

                    <mat-checkbox formControlName="enableAudio">
                        Habilitar Reprodução de Audio.
                    </mat-checkbox>

                    <mat-checkbox formControlName="enableMic">
                        Habilitar Gravação de Audio.
                    </mat-checkbox>

                    <mat-checkbox formControlName="autoQuality">
                        Qualidade Automática.
                    </mat-checkbox>
                    <mat-checkbox formControlName="enableClipboard">
                        Habilitar Área de transferência.
                    </mat-checkbox>
                    <mat-checkbox formControlName="enablePrint">
                        Habilitar Impressão.
                    </mat-checkbox>

                    <mat-checkbox formControlName="enableAllDrives">
                        Habilitar todos os Discos locais.
                    </mat-checkbox>

                    <mat-checkbox formControlName="connectUsingVpn" (click)="onChangeVpnAccess()">
                        Usar através da VPN
                    </mat-checkbox>

                    <mat-checkbox formControlName="multiMon">
                        Habilitar vários monitores.
                    </mat-checkbox>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-evenly start" style="margin-top: 15px;">
                    <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                            aria-label="Salvar">Salvar
                    </button>
                    <button type="button" mat-raised-button color="primary" (click)="onCancel(); sidenav.close()">
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    </mat-sidenav>
</mat-sidenav-container>

import {Injectable} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {ScriptExecutionDaoService} from '../script-execution/script-execution-dao.service';
import {ScriptExecutionType, ServerType} from '../../models';


@Injectable({
    providedIn: 'root'
})
export class LinuxServerService extends ComponentCleaner {

    constructor(private scriptExecutionDaoService: ScriptExecutionDaoService) {
        super();
    }

    userLogoffByServerIdAndServerType(serverId: number, serverType: ServerType, aDLogin: string): void {
        this.scriptExecutionDaoService.userLogoffByServerIdAndServerType(serverId, serverType, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onUserLogOff: ' + response);
            }
        });
    }

    killProcessByServerIdAndServerTypeAndProcessId(serverId: number, serverType: ServerType, processId: number): void {
        this.scriptExecutionDaoService.killProcessByServerIdAndServerTypeAndProcessId(serverId, serverType, processId).subscribe((response) => {
            if (response) {
                console.debug('killProcess: ' + response);
            }
        });
    }

    handlerServiceProcessByServerIdAndServerTypeAndProcessId(serverId: number, serverType: ServerType, serviceName: string, scriptExecutionType: ScriptExecutionType): void {
        this.scriptExecutionDaoService.handlerServiceProcessByServerIdAndServerTypeAndProcessId(serverId, serverType, serviceName, scriptExecutionType).subscribe((response) => {
            if (response) {
                console.debug('handlerService: ' + scriptExecutionType);
            }
        });
    }

    sendWhichModuleIsOpen(serverId: number, serverType: ServerType, moduleName: string): void {
        this.scriptExecutionDaoService.sendWhichModuleIsOpen(serverId, serverType, moduleName).subscribe((response) => {
            if (response) {
                console.debug('sendWhichModuleIsOpen: ' + moduleName);
            }
        });
    }



}

import {Component, OnInit} from '@angular/core';
import {CrudOperationWrapper} from '../../helpers/kluh';
import {MarketplaceProductCreateAndEditComponent} from './marketplace-product-create-and-edit/marketplace-product-create-and-edit.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../component-cleaner';
import {MarketplaceProductComponent} from './marketplace-product/marketplace-product.component';
import {MarketplaceService} from './marketplace.service';
import {MarketplaceCategoryCreateAndEditComponent} from './marketplace-category-create-and-edit/marketplace-category-create-and-edit.component';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {MarketplaceCategoryService} from './marketplace-category-create-and-edit/marketplace-category.service';
import {CustomerGroup, MarketplaceCategory, MarketplaceProduct} from '../../models';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {MarketplaceCategoryDaoService} from './marketplace-category-create-and-edit/marketplace-category.dao.service';
import {ImageFileService} from '../image-file/image-file-service';
import {MarketplaceProductDaoService} from './marketplace-product/marketplace-product.dao.service';
import {MarketplaceProductService} from './marketplace-product/marketplace-product.service';
import {PermissionWrapper} from '../../directives/if-permission.directive';
import {FormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {MarketplaceOrderService} from './marketplace-order/marketplace-order.service';

@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['../../styles/tabs-search-blue-top.scss', './marketplace.component.scss']
})
export class MarketplaceComponent extends ComponentCleaner implements OnInit {

    marketplaceProductList: MarketplaceProduct[] = [];
    marketplaceProductFilterList: MarketplaceProduct[] = [];
    marketplaceCategoryList: MarketplaceCategory[] = [];
    customerGroups: CustomerGroup[] = [];
    appHasPermissionList: PermissionWrapper[];
    canCreate = false;

    public searchMultiFilterCtrl: FormControl = new FormControl();
    public filteredSearchMulti: ReplaySubject<MarketplaceCategory[]> = new ReplaySubject<MarketplaceCategory[]>(1);
    protected _onDestroy = new Subject<void>();


    constructor(
        public dialogRef: MatDialogRef<MarketplaceComponent>,
        private dialog: MatDialog,
        private marketplaceOrderService: MarketplaceOrderService,
        private marketplaceCategoryDaoService: MarketplaceCategoryDaoService,
        private marketplaceProductDaoService: MarketplaceProductDaoService,
        private marketplaceProductService: MarketplaceProductService,
        public marketplaceService: MarketplaceService,
        public imageFileService: ImageFileService,
        public customerGroupService: CustomerGroupService,
        public marketplaceCategoryService: MarketplaceCategoryService,
    ) {
        super();
        this.findAllMarketplaceCategory();
        this.findAllMarketplaceProduct();
    }

    ngOnInit(): void {
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.appHasPermissionList = [
                    {type: 'MarketplaceProduct', permission: 'CREATE', parentType: 'CustomerGroup', parent: customerGroup},
                ];
            }
        });
        this.marketplaceOrderService.getAmountOfUnreadOrdersAndPutOnMenuBadge();
    }

    protected filterBanksMulti(): void {
        let search = this.searchMultiFilterCtrl.value;
        if (!search) {
            this.marketplaceProductFilterList = this.marketplaceProductList;
            return;
        } else {
            search = search.toLowerCase();
        }
        this.marketplaceProductFilterList = this.marketplaceProductList.filter(
            (bank) => {
                return (
                    bank.name.toLowerCase().indexOf(search) > -1 ||
                    bank.company.toLowerCase().indexOf(search) > -1 ||
                    bank.companyWebsite.toLowerCase().indexOf(search) > -1 ||
                    bank.description.toLowerCase().indexOf(search) > -1 ||
                    bank.shortDescription.toLowerCase().indexOf(search) > -1 ||
                    ('managerUserId:' + bank.managerUserId).toLowerCase().indexOf(search) > -1 ||
                    bank.shortDescription.toLowerCase().indexOf(search) > -1
                );
            });
    }

    findAllMarketplaceCategory(): void {
        this.marketplaceCategoryDaoService.get().subscribe(marketPlaceCategoryList => {
            this.marketplaceCategoryList = marketPlaceCategoryList;
        });
    }

    findAllMarketplaceProduct(): void {
        this.marketplaceProductDaoService.get().subscribe(marketPlaceProductList => {
            this.marketplaceProductList = marketPlaceProductList;
            this.filterBanksMulti();
            this.searchMultiFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterBanksMulti();
                });
        });
    }

    getArray(amount: number, start: number): number[] {
        const numbers: number[] = [];
        for (let i = start; i <= start + amount; i++) {
            numbers.push(i);
        }
        return numbers;
    }

    onCreateNewProduct(): void {
        const dialogRef = this.dialog.open(MarketplaceProductCreateAndEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {}
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result && result.operation === 'CREATE') {
                this.marketplaceProductList.unshift(result.data);
                this.onShowProductDetail(result.data.id);
            }
            // this.dialogRef.close(result);
        });
    }

    onCreateNewCategory(): void {
        const dialogRef = this.dialog.open(MarketplaceCategoryCreateAndEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'CREATE') {
                this.marketplaceCategoryDaoService.create(result.data).subscribe(marketplaceCategory => {
                    if (marketplaceCategory) {
                        this.marketplaceCategoryList.unshift(marketplaceCategory);
                    }
                });
            }
        });
    }

    onEditCategory(marketplaceCategory: MarketplaceCategory): void {
        const dialogRef = this.dialog.open(MarketplaceCategoryCreateAndEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                marketplaceCategory: marketplaceCategory
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'SAVE') {
                this.marketplaceCategoryDaoService.save(result.data).subscribe(marketplaceCategoryFromDAO => {
                    if (marketplaceCategoryFromDAO) {
                        this.marketplaceCategoryList = this.marketplaceCategoryService.save(marketplaceCategoryFromDAO, this.marketplaceCategoryList);
                    }
                });
            }
        });
    }

    onDeleteCategory(marketplaceCategory: MarketplaceCategory): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa categoria?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.marketplaceCategoryDaoService.remove(marketplaceCategory.id).subscribe(marketplaceCategoryFromDAO => {
                    this.marketplaceCategoryList = this.marketplaceCategoryService.delete(marketplaceCategory, this.marketplaceCategoryList);
                });
            }
        });
    }

    onShowProductDetail(id: number): void {
        const dialogRef = this.dialog.open(MarketplaceProductComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                id: id
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result && result.operation === 'DELETE') {
                this.marketplaceProductService.delete(result.data, this.marketplaceProductList);
            } else if (result && result.operation === 'SAVE') {
                this.marketplaceProductService.update(result.data, this.marketplaceProductList);
            }
        });
    }

    findAllMarketplaceProductByCategoryId(categoryId: number): MarketplaceProduct[] {
        return this.marketplaceProductFilterList?.filter(
            (o) => o.marketplaceCategoryId === categoryId && o.active === true
        );
    }

    findAllMarketplaceProductActiveFalse(): MarketplaceProduct[] {
        return this.marketplaceProductFilterList?.filter(
            (o) => o.active === false
        );
    }

    findAllMarketplaceProductActive(): MarketplaceProduct[] {
        return this.marketplaceProductFilterList?.filter(
            (o) => o.active === true
        );
    }

}

<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center stretch" [style.min-height.px]="this.height">
    <div fxLayout="column" fxLayoutAlign="center stretch">
        <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
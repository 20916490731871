import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DigitalCertificateInstall} from '../../../models';
import {BaseSearchDao} from '../../../base-search-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';


@Injectable()
export class DigitalCertificateInstallDaoService extends BaseSearchDao<DigitalCertificateInstall> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'digital-certificate-install');
    }

    filter(params?: any): Observable<DigitalCertificateInstall[]> {
        return this.http.get<DigitalCertificateInstall[]>(this.url + '/filter', params);
    }

    findAllByDigitalCertificateIdAndDesktopServerId(digitalCertificateId: number, desktopServerId: number): Observable<DigitalCertificateInstall[]> {
        return this.http.get<DigitalCertificateInstall[]>(this.url + '/find-all-by-digital-certificate-id/' + digitalCertificateId + '/' + desktopServerId);
    }

    checkInstallation(digitalCertificateInstall: DigitalCertificateInstall): Observable<void> {
        return this.http.post<void>(this.url + '/check-installation/', digitalCertificateInstall);
    }

    checkInstallations(digitalCertificateInstallList: DigitalCertificateInstall[]): Observable<void> {
        return this.http.post<void>(this.url + '/check-installations/', digitalCertificateInstallList);
    }
}


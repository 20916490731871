import {Injectable} from '@angular/core';
import {SoftwareCompany} from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class SoftwareCompanyService {
    constructor() {
    }

    initSoftwareCompany(): SoftwareCompany {
        return {
            id: null,
            comment: null,
            active: true,
            name: null,
            cnpj: null,
            province: null,
            city: null,
            neighborhood: null,
            address: null,
            addressAddOn: null,
            addressNumber: null,
            zipCode: null,
            phone: null,
            imageFileId: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null,
            imageUUID: null
        };
    }

    update(softwareCompany: SoftwareCompany, softwareCompanyList: SoftwareCompany[]): SoftwareCompany[] {
        const index = softwareCompanyList.findIndex((o) => o.id === softwareCompany.id);
        if (index > -1) {
            softwareCompanyList[index] = softwareCompany;
        }
        return softwareCompanyList;
    }

    delete(softwareCompany: SoftwareCompany, softwareCompanyList: SoftwareCompany[]): SoftwareCompany[] {
        const index = softwareCompanyList.findIndex((o) => o.id === softwareCompany.id);
        if (index > -1) {
            softwareCompanyList.splice(index, 1);
        }
        return softwareCompanyList;
    }

    findById(id: number, softwareCompanyList: SoftwareCompany[]): SoftwareCompany {
        const index = softwareCompanyList.findIndex((o) => o.id === id);
        if (index > -1) {
            return softwareCompanyList[index];
        }
        return null;
    }
}

<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">R2 Security Server Edit</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-8" matDialogContent>
            <div fxLayout="row" fxLayoutAlign="start start" style="margin-top: 20px;">

                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="name" placeholder="Nome">
                    <mat-error>
                        {{myForm.get('name').errors | error}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 170px">
                    <mat-label>Protocolo</mat-label>
                    <mat-select formControlName="secure">
                        <mat-option [value]="true">https://</mat-option>
                        <mat-option [value]="false">http://</mat-option>
                    </mat-select>
                    <mat-error>
                        {{myForm.get('secure').errors | error}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>fqdn</mat-label>
                    <input matInput formControlName="fqdn" placeholder="fqdn">
                    <mat-error>
                        {{myForm.get('fqdn').errors | error}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 340px;">
                    <mat-label>API Port (padrão 10000)</mat-label>
                    <input matInput formControlName="port" pattern="[0-9]*" max="65535">
                    <mat-error *ngIf="myForm.get('port')?.errors?.pattern">
                        Por favor, insira apenas números.
                    </mat-error>
                    <mat-error *ngIf="myForm.get('port')?.errors?.required">
                        Este campo é obrigatório.
                    </mat-error>
                    <mat-error *ngIf="myForm.get('port')?.errors?.max">
                        Por favor, insira um número menor ou igual a 65535.
                    </mat-error>
                </mat-form-field>
            </div>




            <mat-form-field appearance="outline">
                <mat-label>login</mat-label>
                <input matInput formControlName="login" placeholder="login">
                <mat-error>
                    {{myForm.get('login').errors | error}}
                </mat-error>
            </mat-form-field>
            <div class="full-width" [hidden]="!gatewayServer?.id || changePassword" >
                <button type="button" mat-raised-button color="primary" (click)="setChangePassword()">Mudar senha</button>
            </div>
            <div class="full-width" [hidden]="gatewayServer?.id && !changePassword" >
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" [formControl]="gatewayServerPasswordForm"
                           placeholder="Senha do usuário de serviço">
                    <mat-error *ngIf="gatewayServerPasswordForm.errors">
                        Senha inválida
                    </mat-error>
                </mat-form-field>
                <div style="clear: both"></div>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Repita a senha</mat-label>
                    <input matInput type="password" [formControl]="gatewayServerPasswordRepeatForm"
                           placeholder="Repita a senha">
                    <mat-error *ngIf="gatewayServerPasswordRepeatForm.errors">
                        Senhas não são iguais
                    </mat-error>
                </mat-form-field>
            </div>

            <hr/>

            <mat-form-field class="full-width min-width-230">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Lista de IPs de administração de acesso ao gateway (separar por quebra de linha) - ({{getAmountOfIps()}} IPs)</mat-label>
                    <textarea rows="6" matInput formControlName="administrationIps" class="full-width"></textarea>
                    <mat-error *ngIf="myForm.get('administrationIps').hasError('invalidIpv4OrIpv6OrFqdn')">
                        <div class="mb-30">Existe algum IP ou FQDN inválido!</div>
                    </mat-error>
                    <mat-error *ngIf="myForm.get('administrationIps').hasError('invalidLastCharacterIsNumber')">
                        <div class="mb-30">Existe FQDN com o último caracter como número!</div>
                    </mat-error>
                </mat-form-field>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start start" fxFill class="mb-40" *ngIf="gatewayServer.id">
                <button type="button" mat-raised-button color="primary" (click)="checkIpsOnGateway()" class="mr-20"
                        [disabled]="(!canSendRequestToGatewayServer)" >Checar IPs de administração</button>

                <button type="button" *appIsAdmin mat-raised-button color="primary" (click)="checkIpsLoggedUserOnGateway()" class="mr-20"
                        [disabled]="(!canSendRequestToGatewayServer)" >Checar IPs de usuarios logados</button>
            </div>


            <mat-form-field class="full-width min-width-230">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Observações</mat-label>
                    <textarea rows="3" matInput formControlName="comment"
                              class="full-width"></textarea>
                </mat-form-field>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <div matTooltip="Salvar">
                <button type="submit" mat-raised-button color="accent" aria-label="Salvar"
                        [disabled]="!isValidPassword() || !myForm.valid || !myForm.dirty">
                    <span *ngIf="gatewayServer.id;else newUser">Salvar</span>
                    <ng-template #newUser>Criar</ng-template>
                </button>
            </div>

            <button type="button" mat-raised-button color="primary" (click)="checkGatewayCommunication()" *ngIf="gatewayServer.id"
                    [disabled]="(!canSendRequestToGatewayServer)" >Checar comunicação</button>

            <button *ngIf="gatewayServer.id" type="button" mat-raised-button color="warn" (click)="onRemove()">Excluir</button>

            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>


            <div *ngIf="gatewayServer?.id">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px" >
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: gatewayServer; type: 'GatewayServer'"
                            (click)="onJaversHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: gatewayServer; type: 'GatewayServer'"
                            (click)="onJaversAllHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico completo</span>
                    </button>
                </mat-menu>
            </div>

        </div>
    </div>
</form>
import {Component, Input} from '@angular/core';
import {ADDomain, ServerPresence, ServerType, SubProject} from '../../../models';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {StatusService} from '../status-service';
import {faLinux, faWindows} from '@fortawesome/free-brands-svg-icons';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-servers-offline',
    templateUrl: './servers-offline.component.html',
    styleUrls: ['./servers-offline.component.scss', '../status.component.scss']
})
export class ServersOfflineComponent extends ComponentCleaner {

    faWindows = faWindows;
    faLinux = faLinux;
    searchFieldControl: FormControl = new FormControl();
    serversOffline: ServerPresence[] = [];
    serversOfflineWrapperListFilter: ServerPresence[] = [];

    @Input()
    adDomains: ADDomain[] = [];

    @Input()
    subProjects: SubProject[] = [];
    serverWindows: ServerType = ServerType.WINDOWS;
    serverLinux: ServerType = ServerType.LINUX;

    constructor(private desktopServerDaoService: DesktopServerDaoService,
                private linuxServerDaoService: LinuxServerDaoService,
                private statusService: StatusService
    ) {
        super();
        this.desktopServerDaoService.getServersOffLine().subscribe((desktopServersOffline) => {
            if (desktopServersOffline) {
                for (const desktopServerOffline of desktopServersOffline) {
                    this.serversOffline.push(desktopServerOffline);
                    this.serversOfflineWrapperListFilter.push(desktopServerOffline);
                }
            }
        });
        this.linuxServerDaoService.getServersOffLine().subscribe((linuxServersOffline) => {
            if (linuxServersOffline) {
                for (const linuxServerOffline of linuxServersOffline) {
                    this.serversOffline.push(linuxServerOffline);
                    this.serversOfflineWrapperListFilter.push(linuxServerOffline);
                }
            }
        });
        const subscription2 = this.searchFieldControl.valueChanges.subscribe(() => {
            const searchField = this.searchFieldControl.value;
            if (searchField && searchField.length > 0) {
                this.serversOfflineWrapperListFilter = this.serversOffline.filter(
                    serverOffline => {
                        const search = searchField.toString().toLowerCase();
                        if (serverOffline.hostname.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOffline;
                        }
                        if (serverOffline.connectionFQDN.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOffline;
                        }
                        if (serverOffline.connectionPort?.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOffline;
                        }
                        if (serverOffline.serverType.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOffline;
                        }
                        for (const subProjectId of serverOffline.subProjectIds) {
                            const subProject = this.subProjects.filter(subProjectIn => {
                                return subProjectIn.id === subProjectId;
                            });
                            if (subProject.length > 0 && subProject[0].name.toString().toLowerCase().indexOf(search) > -1) {
                                if (subProject[0].name.toString().toLowerCase().indexOf(search) > -1) {
                                    return serverOffline;
                                }
                            }
                        }
                    });
            } else {
                this.serversOfflineWrapperListFilter = this.serversOffline;
            }
        });
        this.addSubscription(subscription2);
    }

}

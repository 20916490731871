import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorPipe} from './pipes/error.pipe';
import {BackgroundComponent} from './background/background.component';
import {IfErrorDirective} from './directives/if-error.directive';


import {ConfirmDialogComponent} from './helpers/confirm-dialog/confirm-dialog.component';
import {ShowAlwaysErrorStateMatcher} from './show-always-error-state-matcher';
import {BackgroundImageService} from './helpers/background-image.service';
import {AuthService} from './auth.service';
import {DataShareService} from './data-share.service';
import {KluhManagerValidator} from './validators/kluh-manager-validator';
import {InitDirective} from './directives/init.directive';
import {FilterArgsPipe} from './pipes/filter-args.pipe';
import {FuseSharedModule} from '../../@fuse/shared.module';

import {DebounceClickDirective} from './directives/debounce-click.directive';
import {QueryParamsService} from './query-params.service';
import {ArraySortPipe} from './pipes/array-sort-pipe';
import {IsAdminDirective} from './directives/is-admin.directive';
import {CanCreateCustomerGroupDirective} from './directives/can-create-customer-group.directive';
import {IfPermissionDirective} from './directives/if-permission.directive';
import {FirebaseService} from './firebase.service';
import {CustomerGroupToolBarComponent} from './main/customer-group/customer-group-tool-bar/customer-group-tool-bar.component';
import {EnumToArrayPipe} from './pipes/enum-to-array';
import {WaitDialogComponent} from './helpers/wait-dialog/wait-dialog.component';
import {TimeDiffPipe} from './pipes/time-diff-pipe';
import {TodayDiffPipe} from './pipes/today-diff.pipe';
import {DragDropFileDirective} from './directives/drag-drop-file.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {HighchartsComponent} from './helpers/highcharts/highcharts.component';
import {MinutesToStringInfoTimePipe} from './pipes/minutes-to-string-info-time-pipe';
import {IntToHourMinutes} from './pipes/int-to-hours-and-minutes-pipe';
import {TwoZerosInHourOrMinutePipe} from './pipes/two-zeros-in-hour-or-minute-pipe';
import {SecondsToStringInfoTimePipe} from './pipes/seconds-to-string-info-time-pipe';
import {R2CloudStompService} from './ws/r2-cloud-stomp.service';
import {RemoveCharsPipe} from './pipes/remove-chars-pipe';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {errorInterceptorFactory} from '../app.module';


const sharedModules: any[] = [
    IfPermissionDirective,
    IsAdminDirective,
    CanCreateCustomerGroupDirective,
    DragDropFileDirective,
    DebounceClickDirective,
    IfErrorDirective,
    InitDirective,

    ErrorPipe,
    TimeDiffPipe,
    IntToHourMinutes,
    RemoveCharsPipe,
    TwoZerosInHourOrMinutePipe,
    FilterArgsPipe,
    ArraySortPipe,
    EnumToArrayPipe,
    TodayDiffPipe,
    MinutesToStringInfoTimePipe,
    SecondsToStringInfoTimePipe,

    BackgroundComponent,
    CustomerGroupToolBarComponent,
    ConfirmDialogComponent,
    WaitDialogComponent,
    HighchartsComponent

];

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatDialogModule,
        FuseSharedModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    declarations: sharedModules,
    exports: sharedModules,
    entryComponents: [ConfirmDialogComponent, WaitDialogComponent],
    providers: [
        AuthService,
        FirebaseService,
        KluhManagerValidator,
        ShowAlwaysErrorStateMatcher,
        BackgroundImageService,
        DataShareService,
        R2CloudStompService,
        QueryParamsService,
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: errorInterceptorFactory,
            multi: true,
            deps: [Injector]
        },
    ],
})
export class KluhSharedModule {
}


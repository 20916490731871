import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {MarketplaceProduct, MarketplaceProductScreen} from '../../../../models';
import {MarketplaceProductScreenDaoService} from './marketplace-product-screen.dao.service';
import {ImageFileService} from '../../../image-file/image-file-service';

@Component({
    selector: 'app-marketplace-product-detail-screen',
    templateUrl: './marketplace-product-screen.component.html',
    styleUrls: ['./marketplace-product-screen.component.scss', '../marketplace-product.component.scss']
})
export class MarketplaceProductScreenComponent implements OnInit, OnChanges {

    @Input()
    marketplaceProduct: MarketplaceProduct;

    @Input()
    reloadData: number;

    @Output()
    eventSendEdit = new EventEmitter();

    marketPlaceProductScreenList: MarketplaceProductScreen[] = [];

    constructor(
        private marketplaceProductScreenDaoService: MarketplaceProductScreenDaoService,
        public imageFileService: ImageFileService
    ) {
    }

    ngOnInit(): void {
        this.loadScreens();
    }

    ngOnChanges(): void {
        this.loadScreens();
    }

    loadScreens(): void {
        this.marketplaceProductScreenDaoService.findAllByMarketplaceProductId(this.marketplaceProduct.id).subscribe(marketplaceProductScreenList => {
            if (marketplaceProductScreenList) {
                this.marketPlaceProductScreenList = marketplaceProductScreenList;
            }
        });
    }

    onEdit(): void {
        this.eventSendEdit.emit(this.marketplaceProduct.id);
    }
}

import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {ManagerUserPermissionsDaoService} from '../main/manager-user/manager-user-permissions/manager-user-permissions-dao.service';

@Directive({
  selector: '[appCanCreateCustomerGroup]'
})
export class CanCreateCustomerGroupDirective {
    private hasView: boolean;

    constructor(private managerUserPermissionsDao: ManagerUserPermissionsDaoService,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
        this.managerUserPermissionsDao.getMyUserPermissions().subscribe((managerUserPermissions) => {
            if (managerUserPermissions.customerGroupCreator && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!managerUserPermissions.customerGroupCreator && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        });
    }
}

export interface CustomKeyboardEvent {
    altKey: boolean;
    metaKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    key: string;
    keyCode: number;
    code: string;
    which: number;
}

export function fromKeyboardEvent(event: KeyboardEvent): CustomKeyboardEvent {
    return {
        altKey: event.altKey,
        code: event.code,
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        key: event.key,
        keyCode: event.keyCode,
        metaKey: event.metaKey,
        which: event.which
    };
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {

    transform(date: any, texTemplate: any = '', dateCompared: any = null): any {
        if (date) {
            const s = 1000;
            const m = s * 60;
            const h = m * 60;
            const day = h * 24;
            if (typeof (date) !== 'undefined') {

                const dateIn: Date = new Date(date);
                let today: Date = new Date(Date.now());
                if (dateCompared) {
                    today = new Date(dateCompared);
                }
                const d = Math.abs(today.getTime() - dateIn.getTime());

                const totalSeconds = Math.floor(d / s);
                const months = Math.floor(totalSeconds / (3600 * 24 * 30));
                const days = Math.floor(totalSeconds / (3600 * 24));
                const hours = Math.floor(totalSeconds % (3600 * 24) / 3600);
                const minutes = Math.floor(totalSeconds % 3600 / 60);
                // const seconds = Math.floor(totalSeconds % 60);
                if (texTemplate === 'large') {
                    if (months >= 1) {
                        return 'há ' + months + ' meses atrás';
                    } else if (months === 1) {
                        return 'há ' + months + ' mês atrás';
                    } else if (days >= 1 && days < 32) {
                        return 'há ' + days + ' dias atrás';
                    } else if (days === 1) {
                        return 'há ' + days + ' dia atrás';
                    } else if (hours > 1 && hours < 24) {
                        return 'há ' + hours + ' horas atrás';
                    } else if (hours === 1) {
                        return 'há ' + hours + ' hora atrás';
                    } else if (totalSeconds >= 45 && minutes < 59) {
                        if (minutes > 1) {
                            return 'há ' + minutes + ' minutos atrás';
                        } else {
                            return 'há ' + minutes + ' minuto atrás';
                        }
                    } else if (totalSeconds < 45) {
                        return 'há segundos atrás';
                    } else {
                        return '-';
                    }
                } else if (texTemplate === 'medium') {

                    if (months >= 1) {
                        return 'há ' + months + ' meses';
                    } else if (months === 1) {
                        return 'há ' + months + ' mês';
                    } else if (days >= 1 && days < 32) {
                        return 'há ' + days + ' dias';
                    } else if (days === 1) {
                        return 'há ' + days + ' dia';
                    } else if (hours > 1 && hours < 24) {
                        return 'há ' + hours + ' horas';
                    } else if (hours === 1) {
                        return 'há ' + hours + ' hora';
                    } else if (totalSeconds >= 45 && minutes < 59) {
                        if (minutes > 1) {
                            return 'há ' + minutes + ' minutos';
                        } else {
                            return 'há ' + minutes + ' minuto';
                        }
                    } else if (totalSeconds < 45) {
                        return 'há segundos';
                    } else {
                        return '-';
                    }

                } else if (texTemplate === 'small') {
                    if (d > day) {
                        return (days + ' dias e ' + hours + ':' + minutes);
                    } else if (d > h) {
                        return (hours + 'h ' + minutes + 'm');
                    } else if (d > m) {
                        if (minutes > 1) {
                            return (minutes + ' minutos');
                        } else {
                            return (minutes + ' minuto');
                        }
                    } else if (d > s) {
                        return '' + Math.floor(d / s) + ' segundos ';
                    }
                    return Math.floor(d) + ' ms ';
                } else {
                    if (d > day) {
                        return (days + ' dias e ' + hours + ':' + minutes);
                    } else if (d > h) {
                        return (hours + 'h ' + minutes + 'm');
                    } else if (d > m) {
                        if (minutes > 1) {
                            return (minutes + ' minutos');
                        } else {
                            return (minutes + ' minuto');
                        }
                    } else if (d > s) {
                        return 'há ' + Math.floor(d / s) + ' segundos ';
                    }
                    return Math.floor(d) + ' ms ';
                }
            }
        }
        return '-';
    }
}

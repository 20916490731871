import {Injectable} from '@angular/core';
import {BackgroundImageTemplate} from '../../../models-custom-layout';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {R2CloudHttpCustomLayoutService} from '../../../r2-cloud-http-custom-layout.service';
import {BaseGenericDao} from '../../../base-generic-dao';

@Injectable({
    providedIn: 'root'
})
export class BackgroundImageTemplateDaoService extends BaseGenericDao<BackgroundImageTemplate> {
    constructor(private httpAngular: HttpClient,
                http: R2CloudHttpCustomLayoutService,
                validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'background-image-template');
    }

    findOneByCustomerGroupUrl(userUrl: string): Observable<BackgroundImageTemplate> {
        return this.http.get<BackgroundImageTemplate>(this.url + '/find-one-by-customer-group-url/' + userUrl);
    }

    findMyList(): Observable<BackgroundImageTemplate[]> {
        return this.http.get<BackgroundImageTemplate[]>(this.url + '/find-my-list/');
    }
}


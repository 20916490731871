import {AfterViewInit, Directive, Input} from '@angular/core';

@Directive({
  selector: '[appInit]'
})
export class InitDirective implements AfterViewInit{
  @Input() appInit;

  ngAfterViewInit(): void {
    if (this.appInit){
      this.appInit();
    }
  }


  constructor() { }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {ProjectDaoService} from './project-dao.service';
import {ProjectComponent} from './project.component';
import {ProjectListComponent} from './project-list/project-list.component';
import {ProjectItemComponent} from './project-item/project-item.component';
import {ProjectCreateComponent} from './project-create/project-create.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const routes = [
    {
        path: 'project',
        component: ProjectComponent,
        canActivate: [AuthService],
        data: {permissions: ['Project.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        KluhSharedModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSelectModule
    ],
    providers: [
        ProjectDaoService
    ],
    declarations: [ProjectListComponent, ProjectComponent, ProjectItemComponent, ProjectCreateComponent],
    entryComponents: [
        ProjectCreateComponent
    ]
})
export class ProjectModule {
}

<div class="scrollbar-accordion">
<mat-accordion>
    <mat-expansion-panel *ngFor="let vpn of vpnMonitoringConfigurationList">
        <mat-expansion-panel-header>
            <mat-panel-title class="time-vpn"
                             [ngClass]="{
                             'uptime-vpn': getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.onlineSince && statusVpnMonitoring ,
                             'downtime-vpn': getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.offlineSince && statusVpnMonitoring,
                             'timeout-vpn': !statusVpnMonitoring
                             }">
                <mat-icon class="arrow-top" title="onLine" *ngIf="statusVpnMonitoring && getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.onlineSince">
                    check_circle
                </mat-icon>
                <mat-icon class="arrow-top" title="onLine" *ngIf="statusVpnMonitoring && getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.offlineSince">
                    arrow_drop_down_circle
                </mat-icon>
                <mat-icon class="arrow-top" title="onLine" *ngIf="!statusVpnMonitoring">
                    error
                </mat-icon>
                <div class="text-box text-max-200">
                    <div class="font-weight-bold">{{vpn.name}}</div>
                    <small *ngIf="getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.onlineSince && statusVpnMonitoring">
                        <mat-icon class="arrow-inner" title="Online">
                            arrow_upward
                        </mat-icon>
                        {{ getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.onlineSince | timeDiff }}
                    </small>
                    <small *ngIf="getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.offlineSince && statusVpnMonitoring">
                        <mat-icon class="arrow-inner" title="Offline">
                            arrow_downward
                        </mat-icon>
                        {{ getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.offlineSince | timeDiff }}
                    </small>
                    <small *ngIf="!statusVpnMonitoring">
                        <mat-icon class="arrow-inner" title="Offline">
                            arrow_downward
                        </mat-icon>
                        {{ getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpn.id)?.updatedAt | timeDiff }}
                    </small>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <div>
                <p>
                    IP VPN servidor<br>
                    <span class="accent-A700-fg">
                        <span *ngIf="baseServer?.privateIp"> {{baseServer?.privateIp}}</span>
                        <span *ngIf="!baseServer?.privateIp && baseServer?.publicIp"> {{baseServer?.publicIp}}</span>
                    </span><br>
                </p>
                <p>
                    IP/DNS monitorado<br>
                    <span class="accent-A700-fg">{{vpn.clientVpnIp}}</span>
                </p>
            </div>

            <hr *ngIf="vpn.comment && vpn.comment.length > 1">
            <p>{{vpn.comment}}</p>
        </div>
    </mat-expansion-panel>
</mat-accordion>
</div>
<button mat-raised-button style="float: right;margin: 20px 30px 20px 0px;" color="accent" (click)="onEditVPN(baseServer, serverType)">Editar</button>
<div style="display: block; clear: both"></div>


<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header fxLayout="column" fxLayoutAlign="start stretch">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 7px;">
            <div style="margin-bottom: 42px;"> Estatísticas de acesso geral</div>
            <mat-form-field appearance="outline" style="width: 300px;margin-top: -22px;margin-right: 20px;" [hidden]="!showChart">
                <mat-label>Período</mat-label>
                <mat-select placeholder="Sub Projetos" [formControl]="periodFilterControl">
                    <mat-option *ngFor="let period of periodList" [value]="period">
                        {{period.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="showChart">
            <div>
                <div class="col-md-12">
                    <div style="display: block">
                        <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
                        <app-highcharts [hidden]="loading" [data$]="dataSubject" chartTitle="Usuários Online"></app-highcharts>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="!showChart">
            <button *ngIf="!loading" mat-raised-button type="button" color="primary" class="md-small"
                    (click)="getData()">
                <span>Show</span>
            </button>
        </div>
    </mat-card-content>
</mat-card>

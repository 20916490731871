import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GatewayServer, GatewayServerInUse} from '../../../models';
import {GatewayServerDaoService} from '../gateway-server-dao.service';

@Component({
    selector: 'app-gateway-server-in-use',
    templateUrl: './gateway-server-in-use.component.html',
    styleUrls: ['./gateway-server-in-use.component.scss']
})
export class GatewayServerInUseComponent implements OnInit {

    gatewayServer: GatewayServer;
    gatewayServerInUseList: GatewayServerInUse[] = [];

    constructor(
        public dialogRef: MatDialogRef<GatewayServerInUseComponent>,
        private gatewayServerDaoService: GatewayServerDaoService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.gatewayServer = data.gatewayServer;
        this.gatewayServerDaoService.findWhereGatewayServerInUseById(this.gatewayServer.id).subscribe((gatewayServerInUseList) => {
            if (gatewayServerInUseList) {
               this.gatewayServerInUseList = gatewayServerInUseList;
            }
        });
    }

    ngOnInit(): void {
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}

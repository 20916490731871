import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {RemoteAppRelationship} from '../../models';
import {BaseSearchDao} from '../../base-search-dao';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {Observable} from 'rxjs/internal/Observable';


@Injectable()
export class RemoteAppRelationshipDaoService extends BaseSearchDao<RemoteAppRelationship> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'remote-app-relationship');
    }

    findAllByRemoteAppIds(remoteAppIds: number[]): Observable<RemoteAppRelationship[]> {
        return this.http.post<RemoteAppRelationship[]>(this.url + '/find-all-by-remote-app-ids', remoteAppIds);
    }

}

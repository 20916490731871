<mat-card class="padding-none width-100-percent mt-20 with-search-box">
    <mat-card-header fxLayout="column" fxLayoutAlign="start stretch">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 7px;">
            <div>Servidores OnLine
                <span *ngIf="serversOnline && serversOnline.length > 0; else noServers"
                      class="nav-link-badge ng-star-inserted red-A700-bg red-A700">
                      {{serversOnlineWrapperListFilter.length}}
                </span>
                <ng-template #noServers>
                    <span class="nav-link-badge ng-star-inserted red-A700-bg red-A700">0</span>
                </ng-template>
            </div>
            <div>
                <!--                <button type="button" mat-stroked-button (click)="getDesktopServersOnline()"><mat-icon>update</mat-icon></button>-->
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="pr-16">
            <input matInput class="search-box" [formControl]="searchFieldControl" autocomplete="off"
                   *ngIf="serversOnline && serversOnline.length > 0"
                   placeholder="Busca">
        </div>
        <div  class="scroll-content">
            <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0" *ngIf="serversOnline && serversOnline.length > 0">
            <thead>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th class="w-115"></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let serverOnline of serversOnlineWrapperListFilter">
                <td>
                    <fa-icon class="s-20 mr-8  windows-icon" [icon]="faWindows"
                             *ngIf="serverOnline.metricType == serverWindows"
                             style="color: #2E8DEF"></fa-icon>

                    <fa-icon class="s-20 mr-8  windows-icon" [icon]="faLinux"
                             *ngIf="serverOnline.metricType == serverLinux"
                             style="color: #333333"></fa-icon>
                </td>
                <td>
                    <div>
                        <div><b>{{serverOnline.hostname}}</b></div>
                        <div class="font-size-12">{{serverOnline.connectionFQDN}}
                            <span *ngIf="serverOnline.connectionPort">:
                                {{serverOnline.connectionPort}}
                            </span>
                        </div>
                        <div class="font-size-12">
                            {{statusServer.getAdDomain(serverOnline.adDomainId, adDomains)?.name}}
                        </div>
                    </div>
                </td>
                <td>
                    <div>
                        {{serverOnline.clientVersion}}
                    </div>
                </td>
                <td>
                    <div *ngFor="let subProjectId of serverOnline.subProjectIds">
                        <a (click)="statusServer.redirectToR2cloud(statusServer.getSubProject(subProjectId, subProjects))">
                            {{statusServer.getSubProject(subProjectId, subProjects)?.name}}
                        </a>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </mat-card-content>
</mat-card>

import {Component, OnInit} from '@angular/core';
import {CustomerGroupDaoService} from '../customer-group-dao.service';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {CustomerGroupCreateEditComponent} from '../customer-group-create-edit/customer-group-create-edit.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerGroup} from '../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-customer-group-list',
    templateUrl: './customer-group-list.component.html',
    styleUrls: ['./customer-group-list.component.scss']
})
export class CustomerGroupListComponent extends ComponentCleaner implements OnInit {
    public customerGroups: CustomerGroup[];

    constructor(private customerGroupDao: CustomerGroupDaoService, private dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.customerGroupDao.getAllCache().subscribe((result) => {
        // this.customerGroupDao.get().subscribe((result) => {
            this.customerGroups = result;
            if (!this.customerGroups) {
                this.customerGroups = [];
            }
        });
    }

    onAdd(): void {
        const dialogRef = this.dialog.open(CustomerGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                customerGroup: null
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    onEdit(customerGroup: CustomerGroup): void {
        const dialogRef = this.dialog.open(CustomerGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                customerGroup: customerGroup
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.customerGroups.push(result.data);
        } else if (result.operation === 'SAVE') {
            const index = this.customerGroups.findIndex(value => value.id === result.data.id);
            this.customerGroups[index] = result.data;
        } else if (result.operation === 'DELETE') {
            this.customerGroups = this.customerGroups.filter(value => value.id === result.data);
        }
    }

}

import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {MarketplaceService} from '../../marketplace.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {convertToFormGroup, CrudOperationWrapper} from '../../../../helpers/kluh';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MarketplaceProductCommentEditComponent} from './marketplace-product-comment-edit/marketplace-product-comment-edit.component';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {FirebaseUserDetails, ManagerUser, MarketplaceProduct, MarketplaceProductComment} from '../../../../models';
import {Observable} from 'rxjs/internal/Observable';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {ImageFileService} from '../../../image-file/image-file-service';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {MarketplaceProductCommentService} from './marketplace-product-comment.service';
import {MarketplaceProductCommentDaoService} from './marketplace-product-comment.dao.service';

@Component({
    selector: 'app-marketplace-product-detail-comment',
    templateUrl: './marketplace-product-comment.component.html',
    styleUrls: ['./marketplace-product-comment.component.scss', '../marketplace-product.component.scss']
})
export class MarketplaceProductCommentComponent implements OnInit {

    @Input()
    marketplaceProduct: MarketplaceProduct;

    @Input()
    reloadData: number;

    user$: Observable<FirebaseUserDetails>;
    user: FirebaseUserDetails = null;
    myComment: MarketplaceProductComment;

    marketplaceProductCommentList: MarketplaceProductComment[] = [];
    commentRating = 0;
    myForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<MarketplaceProductCommentComponent>,
                public marketplaceService: MarketplaceService,
                public marketplaceProductDetailCommentService: MarketplaceProductCommentService,
                public marketplaceProductCommentDaoService: MarketplaceProductCommentDaoService,
                public managerUserDaoService: ManagerUserDaoService,
                public elRef: ElementRef,
                private dialog: MatDialog,
                public imageFileService: ImageFileService,
                public adminService: R2CloudAdminService,
                private userAuthorityDao: UserAuthorityDaoService,
                private fb: FormBuilder,
                public render: Renderer2) {


    }

    ngOnInit(): void {
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
            this.marketplaceProductCommentDaoService.findAllByMarketplaceProductId(this.marketplaceProduct.id).subscribe(commentList => {
                if (commentList && commentList.length > 0) {
                    const managerUserIds: number[] = [];
                    for (const comment of commentList) {
                        managerUserIds.push(comment.managerUserId);
                    }
                    this.managerUserDaoService.findAllByIdList(managerUserIds).subscribe(managerUserList => {
                        if (managerUserList) {
                            managerUserList.map(managerUser => {
                                if (!this.adminService.getManagerUser(managerUser.id)) {
                                    this.adminService.addManagerUser(managerUser);
                                }
                            });
                            this.marketplaceProductCommentList = commentList;
                            this.checkIfManagerUserAlreadyMadeAComment(this.user.managerUser);
                        }
                    });
                }
            });
        });
        const ratingAndComment: MarketplaceProductComment = {
            id: null,
            active: true,
            marketplaceProductId: this.marketplaceProduct.id,
            managerUserId: null,
            email: null,
            rating: null,
            comment: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
        this.myForm = this.fb.group(convertToFormGroup(ratingAndComment));
        this.commentRating = 0;
    }

    checkIfManagerUserAlreadyMadeAComment(managerUser: ManagerUser): void {
        this.myComment = null;
        for (const comment of this.marketplaceProductCommentList) {
            if (comment.managerUserId === managerUser.id) {
                this.myComment = comment;
                break;
            }
        }
    }

    onRatingSelected(star: number, totalStars: number): void {
        this.commentRating = star;
        this.marketplaceService.onRatingSelected(star, totalStars, this.render, this.elRef);
    }

    onSubmit(): void {
        const ratingAndComment: MarketplaceProductComment = {
            id: null,
            active: true,
            marketplaceProductId: this.marketplaceProduct.id,
            managerUserId: this.user.managerUser.id,
            email: this.user.managerUser?.email,
            createdAt: null,
            updatedAt: null,
            rating: this.commentRating,
            comment: this.myForm.value.comment,
            modified: null,
            optlock: null
        };
        this.marketplaceProductCommentDaoService.create(ratingAndComment).subscribe(comment => {
            if (comment) {
                this.dialogRef.close(false);
            }
        });
    }

    onEdit(comment: MarketplaceProductComment): void {
        const dialogRef = this.dialog.open(MarketplaceProductCommentEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                marketplaceProduct: this.marketplaceProduct,
                comment: comment
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result && result.operation === 'SAVE') {
                const newAndOldComment: MarketplaceProductComment[] = result.data;
                const newComment = newAndOldComment[0];
                const oldComment = newAndOldComment[1];
                this.marketplaceProductCommentDaoService.save(newComment).subscribe(commentFromDAO => {
                    if (commentFromDAO) {
                        this.marketplaceProductCommentList = this.marketplaceProductDetailCommentService.update(commentFromDAO, this.marketplaceProductCommentList);
                        this.checkIfManagerUserAlreadyMadeAComment(this.user.managerUser);
                    }
                });
            }
        });
    }

    onDelete(comment: MarketplaceProductComment): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse comentário?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.marketplaceProductCommentDaoService.remove(comment.id).subscribe(commentRemoved => {
                    this.marketplaceProductDetailCommentService.delete(comment, this.marketplaceProductCommentList);
                    this.checkIfManagerUserAlreadyMadeAComment(this.user.managerUser);
                });
            }
        });
    }

    getManagerUserPictureUrl(managerUserId: number): any {
        this.managerUserDaoService.getOne(managerUserId).subscribe(managerUser => {
            return this.imageFileService.getManagerUserPictureUrl(managerUser.imageUUID);
        });
    }


}

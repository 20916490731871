import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsStoreService {
    private b: Storage;

    constructor() {
        this.b  = window.sessionStorage;
        if (!this.b) { throw new Error('Persistent storage is not available!'); }
        this.c('initialized', !1) || this.InitializeSettings();
    }

    private c = (a: any, c: any): any => {
        try {
            return JSON.parse(this.b.getItem('RdWebAppSettings::' + a));
        } catch (a) {
            return c;
        }
    };

    private d = (a: any, c: any): void => {
        this.b.setItem('RdWebAppSettings::' + a, JSON.stringify(c));
    };

    InitializeSettings(): void {
        this.telemetryEnabled = !0, this.d('initialized', !0);
    }

    get telemetryEnabled(): any {
        return !!this.c('telemetryEnabled', !0);
    }

    set telemetryEnabled(a: any) {
        this.d('telemetryEnabled', a);
    }
}

import {Injectable} from '@angular/core';
import {ServerStatus, ServerStatusType} from '../models';
import {StompConnectionStatus} from './stomp-connection-status.enum';
import {merge, Observable} from 'rxjs';
import {TOPIC} from '../../../main';
import {filter, finalize, map, publishReplay, refCount} from 'rxjs/operators';
import {R2CloudStompService} from './r2-cloud-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class DesktopServerStatusService {

    private obDict = {};
    private connectionStatus$: Observable<ServerStatus>;

    constructor(private stomp: R2CloudStompService) {
        this.connectionStatus$ = this.stomp.connectionStatus$.pipe(filter((result) => {
            return result !== StompConnectionStatus.OK;
        }), map(() => {
            return {desktopServerId: 0, username: null, status: ServerStatusType.STOMP_DISCONNECT, clientVersion: '-'} as ServerStatus;
        }));
    }

    private static getDestination(desktopServerId: number): string {
        return `${TOPIC}/DesktopServer.${desktopServerId}.status`;
    }

    onDesktopServerStatus(desktopServerId: number): Observable<ServerStatus> {
        if (!(this.obDict.hasOwnProperty(desktopServerId))) {
            const destination = DesktopServerStatusService.getDestination(desktopServerId);
            const stompObservable: Observable<ServerStatus> = this.stomp.stompTopic<ServerStatus>(destination).pipe(
                publishReplay(1),
                refCount()
            );
            this.obDict[desktopServerId] = merge(this.connectionStatus$, stompObservable)
                .pipe(
                    // watch('onDesktopServerStatus id' + desktopServerId, 600),
                    finalize(() => {
                        delete this.obDict[desktopServerId];
                    })
                );
        }
        return this.obDict[desktopServerId];
    }
}

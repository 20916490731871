<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Enviar arquivos</span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="p-16 message" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div class="full-width">
            <div *ngIf="hasBlockedFile(localUploadFiles)">
                <mat-card style="background-color: #f44336;color: #ffffff; margin: 15px 15px 30px 15px;">
                    <mat-card-content fxLayoutAlign="start start" matDialogContent style="padding-left: 0;">
                        <mat-icon>error</mat-icon>
                        <div class="pl-10 pt-3">
                            <div>
                                Arquivos com extenções bloqueadas ou com tamanho maior que 50MB, não serão enviados.
                            </div>
                            <div class="mt-10">
                                <span class="font-size-10" *ngFor="let extension of forbiddenExtensions">
                                    {{extension}} |
                                </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <hr style="border-top: 0 solid #eee;margin-bottom: 25px;">
            </div>
            <table *ngIf="localUploadFiles" class="app-table">
                <tr *ngFor="let customFile of localUploadFiles;" [ngClass]="{'blocked': customFile.blocked}">
                    <td>{{customFile.name}}</td>
                    <td>{{customFile.size | bytes}}</td>
                    <td class="w-30 pr-10">
                        <mat-spinner *ngIf="customFile.status === FileStatus.SENDING" [diameter]="24"
                                     color="primary"></mat-spinner>
                        <mat-icon *ngIf="customFile.status === FileStatus.TO_SEND">pause_circle_outline</mat-icon>
                        <mat-icon *ngIf="customFile.status === FileStatus.SENT" class="success-icon">check_circle
                        </mat-icon>
                        <button *ngIf="!sendingFiles"
                                mat-mini-fab color="warn" aria-label="delete"
                                (click)="delete(customFile.position)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
    <button type="button" mat-raised-button color="accent" *ngIf="!sendingFiles && !hasBlockedFile(localUploadFiles)" (click)="onSending()">Enviar</button>
    <button type="button" mat-raised-button color="primary" (click)="onCancel(finished)">
        <span *ngIf="finished">Concluído</span>
        <span *ngIf="!finished">Cancelar</span>
    </button>
</div>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManagerUserListComponent} from './manager-user-list/manager-user-list.component';
import {ManagerUserCreateComponent} from './manager-user-create/manager-user-create.component';
import {ManagerUserDaoService} from './manager-user-dao.service';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

import {KluhSharedModule} from '../../kluh-shared.module';
import {ManagerUserPermissionsComponent} from './manager-user-permissions/manager-user-permissions.component';
import {NgxMaskModule} from 'ngx-mask';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {ManagerUserCreateInBatchComponent} from './manager-user-create-in-batch/manager-user-create-in-batch.component';

const routes = [
    {
        path: 'manager-user',
        component: ManagerUserListComponent,
        canActivate: [AuthService],
        data: {permissions: ['ManagerUser.READ']}
    },
    {
        path: 'manager-user-permissions',
        component: ManagerUserPermissionsComponent,
        canActivate: [AuthService],
        data: {permissions: ['ManagerUserPermissions.WRITE']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatCardModule,
        MatIconModule,
        MatCardModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatToolbarModule,
        NgxMaskModule.forRoot(),
        MatMenuModule
    ],
    entryComponents: [ManagerUserCreateComponent],
    declarations: [ManagerUserListComponent, ManagerUserCreateComponent, ManagerUserPermissionsComponent, ManagerUserCreateInBatchComponent],
    providers: [ManagerUserDaoService]
})
export class ManagerUserModule {
}

import {Component, Inject} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {FormControl, Validators} from '@angular/forms';
import {invalidEmail} from '../../../utils/utils-kluh';
import {AdUserDaoService} from '../ad-user-dao.service';
import {ADDomain, ADGroup, ManagerUserEmailsSubProjectIdADDomainId} from '../../../models';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';

@Component({
    selector: 'app-ad-user-batch-create',
    templateUrl: './ad-user-batch-create.component.html',
    styleUrls: ['./ad-user-batch-create.component.scss']
})
export class AdUserBatchCreateComponent extends ComponentCleaner {
    emailsControl: FormControl = new FormControl();
    adDomains: ADDomain[];
    adGroups: ADGroup[];
    adDomainId: number = null;
    adDomainControl: FormControl = new FormControl();
    adGroupControl: FormControl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<AdUserBatchCreateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        public adminService: R2CloudAdminService,
        private adUserDaoService: AdUserDaoService,
        private managerUserDaoService: ManagerUserDaoService,
        public customerGroupService: CustomerGroupService,
    ) {
        super();
        this.emailsControl.setValidators([invalidEmail()]);
        this.adDomains = data.adDomains;
        if (this.adDomains.length === 1) {
            this.adDomainId = this.adDomains[0].id;
        } else {
            this.emailsControl.setValidators(Validators.required);
        }
    }

    getAmountOfEmails(): number {
        let emailsStr = '';
        if (this.emailsControl.value) {
            emailsStr = this.emailsControl.value;
        }
        const emails = emailsStr?.match(/\n/g);
        return emails ? (emails.length + 1) : 0;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onCreate(): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja inserir todos esses usuários no projeto (' + this.data.subProject.name + ')?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {

                const emails = this.emailsControl.value?.trim().split(/\n/g);
                if (this.adDomainId == null) {
                    this.adDomainId = +this.adDomainControl.value;
                }
                const managerUserEmailsSubProjectIdADDomainId: ManagerUserEmailsSubProjectIdADDomainId = {
                    emails: emails,
                    subProjectId: this.data.subProject.id,
                    adDomainId: this.adDomainId,
                    adGroupId: this.adGroupControl.value,
                };
                this.managerUserDaoService.findAllByEmailAndSubProjectIdList(emails, this.data.subProject.id).subscribe((emailAndSubProjectIdList) => {
                    const openAlert = emailAndSubProjectIdList.some(e => e.exists === false);
                    if (openAlert) {
                        const noExists = emailAndSubProjectIdList.filter(e => e.exists === false).map(e => '<div>' + e.email + '</div>').join('');
                        if (noExists.length > 0) {
                            this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                                disableClose: true,
                                data: {
                                    message: '<h1>Atenção</h1>' +
                                        '<h3>E-mails não cadastrados na plataforma, serão ignorados na inserção dos usuários no projeto</h3>' +
                                        '<div class="pt-25 pb-40">' +
                                        '<div>' +
                                        '<div  class="w-620">' +
                                        '<div>' +
                                        '<div><b>Não cadastrados:</b></div>' +
                                        noExists +
                                        '</div>' +
                                        '</div>' +
                                        '</div>' +
                                        '</div>',
                                    disableCancel: false,
                                    confirmButtonValue: 'OK',
                                    cancelButtonValue: 'Cancelar',
                                    icon: 'error_outline'
                                }
                            }).afterClosed().subscribe((result2) => {
                                if (result2) {
                                    this.adUserDaoService.addManagerUserInBatchToSubProject(managerUserEmailsSubProjectIdADDomainId).subscribe(adUsers => {
                                        this.dialogRef.close(adUsers);
                                    });
                                }
                            }));
                        }
                    } else {
                        this.adUserDaoService.addManagerUserInBatchToSubProject(managerUserEmailsSubProjectIdADDomainId).subscribe(adUsers => {
                            this.dialogRef.close(adUsers);
                        });
                    }
                });


            }
        }));
    }
}

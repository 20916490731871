import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ValueWrapper} from '../../helpers/value-wrapper';
import {ManagerUserLoginToken} from '../../models';

@Injectable()
export class TwoFactorAuthDaoService {

    constructor(
        private http: HttpClient
    ) {

    }

    generateToken(email: string): Observable<ValueWrapper> {
        return this.http.get<ValueWrapper>('/api/manager-users/generate-login-token?email=' + email);
    }

    checkToken(managerUserLoginToken: ManagerUserLoginToken): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>('/api/manager-users/check-login-token', managerUserLoginToken);
    }
}

<mat-card class="mb-20 width-100-percent mr-8">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Lista de atualizações</div>
            <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onExecuteAllTasks()">
                        <mat-icon>play_circle_outline</mat-icon>
                        <span>Atualizar todos agora</span>
                    </button>

                    <button mat-menu-item (click)="onSendMessageToAll()">
                        <mat-icon>mode_comment</mat-icon>
                        <span>Enviar mensagem para todos</span>
                    </button>

                    <button mat-menu-item (click)="onDisableAllTasks()">
                        <mat-icon>sync_disabled</mat-icon>
                        <span>Desativar todas as atualizaçãoes</span>
                    </button>

                    <button mat-menu-item (click)="onEnableAllTasks()">
                        <mat-icon>sync</mat-icon>
                        <span>Ativar todas as atualizaçãoes</span>
                    </button>
                </mat-menu>
            </div>


        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="no-padding">
        <div fxLayoutAlign="center center">
            <div class="search-field">
                <div class="inputContainer">
                    <mat-icon aria-hidden="false">search</mat-icon>
                    <input matInput [formControl]="bulkUpdateDetailFormControl" autocomplete="off"
                           class="search-bulk-update-detail" placeholder="Busca">
                </div>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 width-100-percent bulk-update-table blue-link">

            <ng-container matColumnDef="customerGroup">
                <th mat-header-cell *matHeaderCellDef>Empresa</th>
                <td mat-cell *matCellDef="let element" class="pr-16" [ngClass]="getClass(element)">
                    <div title="Abrir a página de administração do projeto" *ngFor="let customerGroup of
                                        getCustomerGroupByDesktopServerId(element.desktopServerId)"
                         fxLayout="row" fxLayoutAlign="start center"
                         class="mb-5 mt-5 cursor-pointer hover-underline"
                         (click)="goToProjectPage(customerGroup.id)">
                        <div class="mr-8"><img
                            src="{{imageFileService.getCustomerGroupPictureUrl(customerGroup.imageUUID, 34)}}"
                            width="34"
                            height="34">
                        </div>
                        <div>
                            <div>{{customerGroup.name}}</div>
                            <div>server: {{findDesktopServer(element.desktopServerId)?.hostname}}</div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="schedule">
                <th mat-header-cell *matHeaderCellDef class="w-275"> Agendamento</th>
                <td mat-cell *matCellDef="let element" class="pr-16" [ngClass]="getClass(element)">
                    <table class="width-100-percent bulk-update-table no-hover">
                        <thead>
                        <tr>
                            <th>SEG</th>
                            <th>TER</th>
                            <th>QUA</th>
                            <th>QUI</th>
                            <th>SEX</th>
                            <th>SÁB</th>
                            <th>DOM</th>
                        </tr>
                        </thead>
                        <tr>
                            <td>
                                <div *ngIf="element.mondayStartHours">
                                    {{element.mondayStartHours | twoZeros}}:{{element.mondayStartMinutes | twoZeros}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="element.tuesdayStartHours">
                                    {{element.tuesdayStartHours | twoZeros}}:{{element.tuesdayStartMinutes | twoZeros}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="element.wednesdayStartHours">
                                    {{element.wednesdayStartHours | twoZeros}}
                                    :{{element.wednesdayStartMinutes | twoZeros}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="element.thursdayStartHours">
                                    {{element.thursdayStartHours | twoZeros}}
                                    :{{element.thursdayStartMinutes | twoZeros}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="element.fridayStartHours">
                                    {{element.fridayStartHours | twoZeros}}:{{element.fridayStartMinutes | twoZeros}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="element.saturdayStartHours">
                                    {{element.saturdayStartHours | twoZeros}}
                                    :{{element.saturdayStartMinutes | twoZeros}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="element.sundayStartHours">
                                    {{element.sundayStartHours | twoZeros}}:{{element.sundayStartMinutes | twoZeros}}
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </ng-container>

            <ng-container matColumnDef="updateModules">
                <th mat-header-cell *matHeaderCellDef class="w-150">Atualizar</th>
                <td mat-cell *matCellDef="let element" class="pr-16" [ngClass]="getClass(element)">
                    <div *ngFor="let rootPathAndChild of generateRootPathAndChildList(element.hipcomBulkUpdateCurrentSoftwareVersionIds); let i = index;"
                        class="pl-15">
                        <div>{{rootPathAndChild.rootPath}}</div>
                        <ul class="pl-10 pt-0 mt-5 ml-5">
                            <li *ngFor="let childPath of rootPathAndChild.childPaths;">
                                {{childPath}}
                            </li>
                        </ul>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="modules">
                <th mat-header-cell *matHeaderCellDef class="w-120">Módulos<br>encontrados</th>
                <td mat-cell *matCellDef="let element" [ngClass]="getClass(element)">
                    <div *ngIf="element.hipcomBulkUpdateCurrentSoftwareVersionIds.length > 0">
                        <div>{{element.hipcomBulkUpdateCurrentSoftwareVersionIds.length}} módulos</div>
                    </div>
                    <div *ngIf="element.hipcomBulkUpdateCurrentSoftwareVersionIds.length < 1">
                        Sem módulo
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="checkedAt">
                <th mat-header-cell *matHeaderCellDef class="w-120">Última<br>verificação</th>
                <td mat-cell *matCellDef="let element" [ngClass]="getClass(element)">
                    <div>{{findLatestUpdatedModule(element)?.checkedAt | timeDiff: 'medium'}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="finishedAt">
                <th mat-header-cell *matHeaderCellDef class="w-120">Última<br>atualização</th>
                <td mat-cell *matCellDef="let element" [ngClass]="getClass(element)">
                    {{element.finishedAt | timeDiff: 'medium'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="online">
                <th mat-header-cell *matHeaderCellDef class="w-70"> Online</th>
                <td mat-cell *matCellDef="let element" [ngClass]="getClass(element)" class="pl-12">
                    <div class="client-connected" title="Online"
                         *ngIf="checkDesktopServerIsOnline(element.desktopServerId); else offline">
                        <fa-icon [icon]="faCircle"></fa-icon>
                    </div>
                    <ng-template #offline>
                        <div class="client-not-connected" title="Offline">
                            <fa-icon [icon]="faCircle"></fa-icon>
                        </div>
                    </ng-template>
                </td>
            </ng-container>


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="w-100 text-align-center"> Status</th>
                <td mat-cell *matCellDef="let element" [ngClass]="getClass(element)">
                    <div *ngIf="element.active">
                        <div>
                            <div class="mr-5" *ngIf="element.status == statusType.UPDATING">
                                <div
                                    *ngIf="checkIfDateIsGreaterThanNowMoreMinutes(element.startAt, maxUpdateTimeInMinutes);else error">
                                    <div>
                                        ERRO
                                    </div>
                                    <div>Demorando muito</div>
                                    <div>mais de {{maxUpdateTimeInMinutes}} min</div>

                                </div>
                                <ng-template #error>
                                    <div fxLayout="row" fxLayoutAlign="space-around center">
                                        <div>
                                            <img src="{{domainPathService.staticURL}}icons/loading.gif" width="15"
                                                 height="15" alt="Atualizando">
                                        </div>
                                        <div *ngIf="element.status == statusType.UPDATING" class="font-size-12">
                                            Atualizando
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <div *ngIf="element.status == statusType.STARTED">Novo</div>

                            <div *ngIf="element.status == statusType.OK">OK</div>
                        </div>
                    </div>
                    <div *ngIf="!element.active">
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <del>{{element.status}}</del>
                            <div>Desativado</div>
                        </div>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="action" class="w-30 no-padding">
                <th mat-header-cell *matHeaderCellDef class="w-30 no-padding"></th>
                <td mat-cell *matCellDef="let element" class="w-30 no-padding" [ngClass]="getClass(element)">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onExecuteTask(element)"
                                *ngIf="showExecuteButton(element)">
                            <mat-icon>play_circle_outline</mat-icon>
                            <span>Executar</span>
                        </button>

                        <button mat-menu-item [matMenuTriggerFor]="subMenuForcedExecution"
                                *ngIf="checkDesktopServerIsOnline(element.desktopServerId)">
                            <mat-icon>menu</mat-icon>
                            <span>Forçar atualização</span>
                        </button>

                        <mat-menu #subMenuForcedExecution="matMenu">
                            <button mat-menu-item [matMenuTriggerFor]="subMenuModules">
                                <mat-icon>menu</mat-icon>
                                <span>Escolher módulo</span>
                            </button>
                            <button mat-menu-item (click)="onForcedUpdateAllModules(element)">
                                <mat-icon>system_update</mat-icon>
                                <span>Todos os módulos</span>
                            </button>
                        </mat-menu>

                        <mat-menu #subMenuModules="matMenu">
                            <button mat-menu-item
                                    *ngFor="let id of element.hipcomBulkUpdateCurrentSoftwareVersionIds; let i = index;"
                                    (click)="onForcedUpdateOnModule(id, element)">
                                <mat-icon>system_update</mat-icon>
                                <span>
                                    {{findPathByid(findHipcomBulkUpdateCurrentSoftwareVersionById(id)?.hipcomBulkUpdatePathId)?.path}}\{{findHipcomBulkUpdateLatestSoftwareVersionById(findHipcomBulkUpdateCurrentSoftwareVersionById(id)?.hipcomBulkUpdateLatestSoftwareVersionId)?.name}}
                                </span>
                            </button>
                        </mat-menu>

                        <button mat-menu-item (click)="onScanModulesAgain(element)"
                                *ngIf="element.status !== statusType.UPDATING && checkDesktopServerIsOnline(element.desktopServerId)">
                            <mat-icon>play_circle_outline</mat-icon>
                            <span>Escanear módulos novamente</span>
                        </button>

                        <button mat-menu-item (click)="onSendMessage(element)"
                                *ngIf="checkDesktopServerIsOnline(element.desktopServerId)">
                            <mat-icon>mode_comment</mat-icon>
                            <span>Enviar mensagem</span>
                        </button>
                        <button mat-menu-item (click)="onCreateEditSchedule(element)">
                            <mat-icon>access_time</mat-icon>
                            <span>Agendamento</span>
                        </button>
                        <button mat-menu-item (click)="onViewLog(element)">
                            <mat-icon>assignment</mat-icon>
                            <span>Log</span>
                        </button>


                        <button mat-menu-item (click)="onToggleTask(element, false)" *ngIf="element.active">
                            <mat-icon>delete</mat-icon>
                            <span>Desativar</span>
                        </button>

                        <button mat-menu-item (click)="onToggleTask(element, true)" *ngIf="!element.active">
                            <mat-icon>check</mat-icon>
                            <span>Ativar</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
</mat-card>

<form [formGroup]="myForm" (submit)="(myForm.valid && myForm.dirty) && onSubmit()">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">VPN - Cadastro para monitoramento</span>
        </mat-toolbar-row>
    </mat-toolbar>


    <div class="mat-elevation-z2 card-list" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div #vpnContentRef fxLayout="column" class="p-16 content-vpn" fxLayoutAlign="none stretch">
            <mat-card>
                <mat-card-content>
                    <table>
                        <tr>
                            <td>Hostname</td>
                            <td>:</td>
                            <td>{{baseServer?.hostname}}</td>
                        </tr>
                        <tr>
                            <td>FQDN</td>
                            <td>:</td>
                            <td>{{baseServer?.connectionFQDN}}</td>
                        </tr>
                        <tr>
                            <td>Private Ip</td>
                            <td>:</td>
                            <td>{{baseServer?.privateIp}}</td>
                        </tr>

                    </table>
                    <div><small class="accent-300-fg">O ping sairá desse servidor</small></div>
                </mat-card-content>
            </mat-card>

            <div formArrayName="myFormArray">
                <mat-card *ngFor="let vpn of myFormControls.controls; let i = index"
                          [formGroupName]="i">
                    <mat-card-content>
                        <div>

                            <mat-form-field appearance="outline" class="mr-30" style="width: 287px">
                                <mat-label>Nome</mat-label>
                                <input matInput formControlName="name" required>
                                <mat-error>
                                    {{getControlsOfFormGroup(vpn).controls.name.errors | error}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 287px">
                                <mat-label>IP VPN ou DNS</mat-label>
                                <input matInput formControlName="clientVpnIp">
                                <mat-error>
                                    {{getControlsOfFormGroup(vpn).controls.clientVpnIp.errors | error}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div>
                            <mat-form-field class="width-100-percent" appearance="outline">
                                <mat-label>Detalhes e informações</mat-label>
                                <textarea rows="4" matInput formControlName="comment"></textarea>
                            </mat-form-field>
                        </div>

                            <button type="button" class="float-right" mat-raised-button color="warn"
                                    (click)="onDelete(vpn.value, i)">Delete
                            </button>
                        <div style="clear: both"></div>
                    </mat-card-content>
                </mat-card>
            </div>

            <button type="button" mat-raised-button color="primary" class="text-align-center mt-20 mb-30"
                    (click)="onAddVpnMonitoringConfiguration()">Add
            </button>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <button type="button" mat-raised-button color="accent" (click)="onSubmit()">Salvar</button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</form>

<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">R2 Security Server em uso ({{gatewayServerInUseList.length}})</span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-8" matDialogContent>
        <div fxLayout="row" fxLayoutAlign="start start" style="margin-top: 20px;">
            <table class="width-100-percent">
                <thead>
                <tr class="bg-white">
                    <th style="width: 40%;">Empresa/Projeto</th>
                    <th style="width: 35%;">Servidor</th>
                    <th style="width: 15%;">Tipo</th>
                    <th style="width: 10%;">Criado</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let gatewayServerInUse of gatewayServerInUseList">
                    <td>
                        {{gatewayServerInUse.customerGroupName}}<br>
                        {{gatewayServerInUse.subProjectName}}
                    </td>
                    <td>{{gatewayServerInUse.gatewayServerNatInfo.hostname}}</td>
                    <td>{{gatewayServerInUse.gatewayServerNatInfo.serverType}}</td>
                    <td>{{gatewayServerInUse.gatewayServerNatInfo.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

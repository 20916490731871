import {R2CloudHttpBase} from './r2-cloud-http.base';
import {KluhManagerValidator} from './validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {AsyncValidatorFn} from '@angular/forms';


export abstract class BaseCreatedAtNumberDao<T extends IBaseCreatedAtNumber> {

    protected url: string;
    validator: AsyncValidatorFn;

    protected constructor(protected http: R2CloudHttpBase, protected validatorDAO: KluhManagerValidator, url: string) {
        this.url = url;
        this.validator = this.validatorDAO.validator(this.url, this.http);
    }


    get(): Observable<T[]> {
        return this.http.get<T[]>(this.url);
    }

    getOne(id: number): Observable<T> {
        return this.http.get<T>(this.url + '/' + id);
    }

    create(entity: T): Observable<T> {
        return this.http.post<T>(this.url + '/', entity);
    }

    save(entity: T): Observable<T> {
        return this.http.put<T>(this.url + '/', entity);
    }

    createAll(entityList: T[]): Observable<T[]> {
        return this.http.post<T[]>(this.url + '/batch-create', entityList);
    }

    delete(entityId: number): Observable<string> {
        return this.http.remove(this.url + '/' + entityId);
    }

}

export interface IBaseCreatedAtNumber {
    createdAt: number | null;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Project} from '../../../models';


@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss']
})
export class ProjectItemComponent implements OnInit {
  @Input()
  public project: Project;
  @Output() eventProject: EventEmitter<Project> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  editProjectChild(project: Project): void {
    this.eventProject.emit(project);
  }

}

import {ElementRef, Injectable, Renderer2} from '@angular/core';
import {MarketplaceProduct} from '../../models';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceService {
    constructor() {
    }

    initMarketplaceProduct(): MarketplaceProduct {
        return {
            id: null,
            name: null,
            comment: null,
            blockedSoftwareCompanyIds: null,
            shortDescription: null,
            marketplaceCategoryId: null,
            managerUserId: null,
            company: null,
            companyWebsite: null,
            description: null,
            avatarId: null,
            bannerId: null,
            totalRating1: 0,
            totalRating2: 0,
            totalRating3: 0,
            totalRating4: 0,
            totalRating5: 0,
            active: true,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null,
            avatarUUID: null,
            bannerUUID: null
        };
    }

    calcStartRating(s1: number, s2: number, s3: number, s4: number, s5: number): number {
        return (5 * s5 + 4 * s4 + 3 * s3 + 2 * s2 + s1) / (s5 + s4 + s3 + s2 + s1);
    }

    getStarRatingComment(rating: number, star: number): string {
        let output = 'primary-50-fg';
        if (rating >= star) {
            output = 'g-yellow';
        }
        return output;
    }

    getStarRating(product: MarketplaceProduct, input: number): string {
        let output = 'primary-50-fg';
        if (product) {
            if (this.calcStartRating(product.totalRating1, product.totalRating2, product.totalRating3, product.totalRating4, product.totalRating5) > input - 1) {
                output = 'g-yellow';
            }
        }
        return output;
    }

    checkIfIsHalfStar(product: MarketplaceProduct, input: number): string {
        let output = 'star';
        if (product) {
            const rating = this.calcStartRating(product.totalRating1, product.totalRating2, product.totalRating3, product.totalRating4, product.totalRating5);
            if (rating < input && rating >= input - 1) {
                output = 'star_half';
            }
        }
        return output;
    }

    checkTotalVotes(product: MarketplaceProduct): number {
        if (product) {
            return product.totalRating1 + product.totalRating2 + product.totalRating3 + product.totalRating4 + product.totalRating5;
        }
        return 0;
    }

    checkPercentOfVotes(product: MarketplaceProduct, star: number): number {
        const totalVotes = this.checkTotalVotes(product);
        return (star * 100) / totalVotes;
    }

    findById(id: number, marketplaceProduct: MarketplaceProduct[]): MarketplaceProduct {
        const index = marketplaceProduct.findIndex((o) => o.id === id);
        if (index > -1) {
            return marketplaceProduct[index];
        }
        return null;
    }

    /*Start Rating*/
    ratingSelect(star: number, totalStars: number, elRef: ElementRef): void {
        for (let i = 1; i <= totalStars; i++) {
            if (i <= star) {
                elRef.nativeElement.querySelector('.star' + i).style.setProperty('color', '#fbbc05');
            } else {
                elRef.nativeElement.querySelector('.star' + i).style.setProperty('color', '#b6b6b6');
            }
        }
    }

    ratingUnSelect(star: number, totalStars: number, elRef: ElementRef): void {
        for (let i = 1; i <= totalStars; i++) {
            elRef.nativeElement.querySelector('.star' + i).style.setProperty('color', '#b6b6b6');
        }
    }

    onRatingSelected(star: number, totalStars: number, render: Renderer2, elRef: ElementRef): void {
        for (let i = 1; i <= totalStars; i++) {
            const starElement = elRef.nativeElement.querySelector('.star' + i);
            if (i <= star) {
                render.addClass(starElement, 'g-yellow-important');
            } else {
                render.removeClass(starElement, 'g-yellow-important');
            }
        }
    }
}

<div class="product-detail-content-tab">
    <div fxFlex fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex class="product-description">
            <div [innerHTML]="marketplaceProduct.description | safeHtml: 'html'"></div>
        </div>
        <div class="company-detail" fxFlex="35">
            <table>
                <tr>
                    <td class="title-td">Empresa :</td>
                    <td>{{marketplaceProduct.company}}</td>
                </tr>
                <tr>
                    <td class="title-td">Site :</td>
                    <td>{{marketplaceProduct.companyWebsite}}</td>
                </tr>
                <tr>
                    <td class="title-td">Pedidos :</td>
                    <td>{{amountOfMarketplaceProductOrders}}</td>
                </tr>
                <tr>
                    <td class="title-td pt-20">Rating :</td>
                    <td class="pt-20">
                        <div class="product-detail-rating mb-10">
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,1)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 1)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,2)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 2)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,3)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 3)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,4)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 4)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,5)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 5)}}</mat-icon>
                            <span class="rating-number">({{marketplaceService.checkTotalVotes(marketplaceProduct)}}
                                )</span>
                        </div>
                        <table>
                            <tr>
                                <td>5 estrelas</td>
                                <td>
                                                    <span class="counter-back">
							                            <span class="counter-bar"
                                                              style="width: {{marketplaceService.checkPercentOfVotes(marketplaceProduct, marketplaceProduct.totalRating5)}}px;"></span>
						                            </span>
                                </td>
                                <td>{{marketplaceProduct.totalRating5}}</td>
                            </tr>
                            <tr>
                                <td>4 estrelas</td>
                                <td>
                                                    <span class="counter-back">
							                            <span class="counter-bar"
                                                              style="width: {{marketplaceService.checkPercentOfVotes(marketplaceProduct, marketplaceProduct.totalRating4)}}px;"></span>
						                            </span>
                                </td>
                                <td>{{marketplaceProduct.totalRating4}}</td>
                            </tr>
                            <tr>
                                <td>3 estrelas</td>
                                <td>
                                                    <span class="counter-back">
							                            <span class="counter-bar"
                                                              style="width: {{marketplaceService.checkPercentOfVotes(marketplaceProduct, marketplaceProduct.totalRating3)}}px;"></span>
						                            </span>
                                </td>
                                <td>{{marketplaceProduct.totalRating3}}</td>
                            </tr>
                            <tr>
                                <td>2 estrelas</td>
                                <td>
                                                    <span class="counter-back">
							                            <span class="counter-bar"
                                                              style="width: {{marketplaceService.checkPercentOfVotes(marketplaceProduct, marketplaceProduct.totalRating2)}}px;"></span>
						                            </span>
                                </td>
                                <td>{{marketplaceProduct.totalRating2}}</td>
                            </tr>
                            <tr>
                                <td>1 estrela</td>
                                <td>
                                    <span class="counter-back">
                                        <span class="counter-bar"
                                              style="width: {{marketplaceService.checkPercentOfVotes(marketplaceProduct, marketplaceProduct.totalRating1)}}px;"></span>
						                </span>
                                </td>
                                <td>{{marketplaceProduct.totalRating1}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

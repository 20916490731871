import {AudioPlaybackType} from './enum/audio-playback-type';
import {BookmarkType} from './enum/bookmark-type';
import {ConnectionProperties} from './connection-properties';
import {RdpConnection} from './rdp-connection';

export class Bookmark {
    id: any = null;
    remoteAppId: number;
    hostName: any = null;
    port: any = null;
    friendlyName: any = null;
    audioPlaybackType: AudioPlaybackType = AudioPlaybackType.PlayOnDevice;
    swapMouseButtons: any = !1;
    adminMode: any = !1;
    bookmarkType: BookmarkType = BookmarkType.Desktop;
    thumbnail: any = null;
    defaultThumbnai: any = 'assets/images/defaultIcon.bc1400ea.png';
    rdpFileString: any = '';
    rdpFileURL: any = null;
    folderNames: any[] = [];
    tenantId: any = '';
    multiTenantMode: any = !1;
    endpointCert: any = null;
    workspaceName: any = null;
    desktopName: any = null;
    // thumbnailBase64: any;
    imageFileId: string = null;
    connectionProperties: ConnectionProperties;
    rdpConnection: RdpConnection;


    label(): any {
        return this.friendlyName || this.hostName;
    }

    desktopLabel(): any {
        return this.desktopName || this.label();
    }

    validate(): any {
        return !0;
    }

    clippedDisplayLabel(): any {
        const a = this.label(),
            b = a.split(' ');
        let c = '',
            d = '';
        if (b.length < 1) {
            return a;
        }
        if (b[0].length > 10) {
            const e = b[0].substring(0, 9) + '-';
            b[0] = b[0].substring(10), b.unshift(e);
        }
        for (let f = 0; f < b.length; f++) {
            const g = b[f];
            (c + ' ' + g).trim().length <= 10 ? c = (c + ' ' + g).trim() : d = (d + ' ' + g).trim();
        }
        return d.length > 10 && (d = d.substring(0, 7).trim() + '...'), c + ' ' + d;
    }
}

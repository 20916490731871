import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {AiUserGroup} from '../../../models-ai';


@Injectable({
    providedIn: 'root'
})
export class AiUserGroupDaoService extends BaseDao<any> {
    private aiURL = '';

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    public init(): AiUserGroup {
        return {
            id: null,
            vectorStoreId: null,
            customerGroupId: null,
            name: null,
            default: null,
            managerUserIds: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    getAll(): Observable<AiUserGroup[]> {
        return this.http.get<AiUserGroup[]>(this.aiURL + 'ai-user-group/', null, null, true);
    }

    getAllByCustomerGroup(customerGroupId: number): Observable<AiUserGroup[]> {
        return this.http.get<AiUserGroup[]>(this.aiURL + 'ai-user-group/customer-group/' + customerGroupId, null, null, true);
    }

    save(entity: AiUserGroup): Observable<AiUserGroup> {
        return this.http.put<AiUserGroup>(this.aiURL + 'ai-user-group/', entity, true);
    }

    create(entity: AiUserGroup): Observable<AiUserGroup> {
        return this.http.post<AiUserGroup>(this.aiURL + 'ai-user-group/', entity, true);
    }

    delete(entity: AiUserGroup): Observable<void> {
        return this.http.delete(this.aiURL + 'ai-user-group/', entity, true);
    }

}

import {Component, Inject, OnInit} from '@angular/core';
import {DesktopServer} from '../../../models';
import {ComponentCleaner} from '../../../component-cleaner';
import {FormBuilder, FormControl} from '@angular/forms';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';

@Component({
    selector: 'app-remote-app-file-browser',
    templateUrl: './remote-app-file-browser.component.html',
    styleUrls: ['./remote-app-file-browser.component.scss'],
    animations: fuseAnimations
})
export class RemoteAppFileBrowserComponent extends ComponentCleaner implements OnInit {

    faLongArrowAltLeft = faLongArrowAltLeft;

    // Files and Directories filter configuration
    fileFilters: string[] = ['.exe', '.bat', '.vbs'];
    invertFileFilters = false;
    directoryFilters: string[] = [':\\Arquivos de Programas', ':\\Documents and Settings', ':\\$Recycle.Bin', ':\\System Volume Information'];
    invertDirectoryFilters = true;
    // (END) Files and Directories filter configuration
    FileTypes = FileType;

    driveElements: FileElement[] = [];
    fileTable: string[] = ['icon', 'name', 'type', 'owner', 'size', 'updated'];
    fileDataSource: FileElement[] = [];
    private desktopServerStompUsername: string;
    desktopServer: DesktopServer;
    username: string;

    private topic = '/topic/remote-app-file-browser-finished';
    rootAlias = '/';
    pathControl: FormControl;
    driveControl: FormControl;
    parentPath: string | null;

    constructor(public dialogRef: MatDialogRef<RemoteAppFileBrowserComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private stomp: R2CloudStompService) {
        super();
        this.pathControl = this.fb.control(null);
        this.driveControl = this.fb.control(null);
        this.addSubscription(
            this.pathControl.valueChanges
                .pipe(
                    debounceTime(500),
                    filter((o) => !!o),
                    distinctUntilChanged()
                )
                .subscribe((result) => {
                    this.send(result);
                })
        );
        this.addSubscription(
            this.driveControl.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe((value: FileElement) => {
                    this.send(value.path);
                })
        );
        this.desktopServer = data.desktopServer;
        this.desktopServerStompUsername = data.desktopServerStompUsername;

        this.stomp.username$.subscribe((username) => {
            this.username = username;
        });
        this.addSubscription(
            this.stomp.stompTopic<RemoteAppFileBrowserEventFinished>('/user' + this.topic)
                .subscribe((result) => {
                    if (!result.drives || result.drives.length === 0) {
                        window.alert('no drives returned');
                    } else {
                        if (result.parentPath) {
                            this.parentPath = result.parentPath;
                        } else {
                            this.parentPath = null;
                        }
                        if (this.driveElements.length !== result.drives.length) {
                            const drive = result.drives[0];
                            const oldDrive: FileElement = this.driveControl.value;
                            this.driveElements = result.drives;
                            if (oldDrive) {
                                const newDrive = this.driveElements.find((o) => o.name === oldDrive.name);
                                this.driveControl.setValue(newDrive, {emitEvent: false});
                            } else {
                                this.driveControl.setValue(drive, {emitEvent: false});
                            }
                        }
                        this.pathControl.setValue(result.currentPath, {emitEvent: false});
                        this.fileDataSource = result.files;
                    }
                    if (result.error) {
                        window.alert(result.error);
                        const value: FileElement = this.driveControl.value;
                        if (value) {
                            this.send(value.path);
                        } else {
                            if (this.driveElements && this.driveElements.length > 0) {
                                this.send(this.driveElements[0].path);
                            }
                        }
                    }
                })
        );
        if (data.currentPath && data.currentPath.length >= 4) {
            const pathSplit = data.currentPath.split('\\');
            const path = pathSplit.slice(0, pathSplit.length - 1).join('\\');
            this.send(path);
        } else {
            this.send(this.rootAlias);
        }
    }

    ngOnInit(): void {
    }

    send(path: string): void {
        const event: RemoteAppFileBrowserEvent = {
            fileFilters: this.fileFilters,
            invertFileFilters: this.invertFileFilters,
            directoryFilters: this.directoryFilters,
            invertDirectoryFilters: this.invertDirectoryFilters,
            path: path,
            topic: '/user/' + this.username + this.topic
        };
        this.stomp.send('/user/' + this.desktopServerStompUsername + '/topic/remote-app-file-browser', event);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        this.dialogRef.close(this.pathControl.value);
    }

    onSelectElement(file: FileElement): void {
        if (file.type === 'Directory') {
            this.send(file.path);
        } else {
            this.pathControl.setValue(file.path, {emitEvent: false});
        }
    }

    validExe(): boolean {
        const value = this.pathControl.value as string;
        if (value) {
            return this.isAValidProgram(value);
        } else {
            return false;
        }
    }


    isAValidProgram(value: any): boolean {
        const fileElement = this.fileDataSource.find((o) => o.path === value);
        if (fileElement) {
            return fileElement.type === FileType.File;
        }
        return false;
    }


    onBack(): void {
        this.send(this.parentPath);
    }
}

export interface RemoteAppFileBrowserEvent {
    topic: string;
    path: string;
    fileFilters: string[];
    invertFileFilters: boolean;
    directoryFilters: string[];
    invertDirectoryFilters: boolean;
}

export interface RemoteAppFileBrowserEventFinished {
    drives: FileElement[];
    parentPath: string | null;
    currentPath: string;
    files: FileElement[];
    error: string | null;
}

export interface FileElement {
    name: string;
    path: string;
    type: FileType;
    owner: string;
    size: number;
    hidden: boolean;
    created: Date;
    updated: Date;
}

export enum FileType {
    Drive = 'Drive',
    Directory = 'Directory',
    File = 'File',
    Document = 'Document'
}

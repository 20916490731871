import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {combineLatest, merge, Subject} from 'rxjs';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';

import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {AuthService} from '../../../app/kluh-manager/auth.service';
import {Permission} from '../../../app/kluh-manager/models';
import {FuseNavigationItem} from '../../types';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private auth: AuthService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        // this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        combineLatest([this.auth.isLoggedIn(), this._fuseNavigationService.onNavigationRegistered.pipe(filter(o => !!o))])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: [boolean, any]) => {
                const isLoggedIn: boolean = value[0];
                if (isLoggedIn) {
                    this._fuseNavigationService.setCurrentNavigation('main');
                } else {
                    this._fuseNavigationService.setCurrentNavigation(null);
                }
            });
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter(o => !!o),
                // switchMap(() => this.auth.getMyPermissions().pipe(take(1)))
            )
            .subscribe(() => {
                const navigation = this._fuseNavigationService.getCurrentNavigation();
                this.hideEmptyGroup(navigation);
                this.navigation = navigation;
                this._changeDetectorRef.markForCheck();
                // if (myPermissions) {
                //
                //     this.hideNavigationByPermissions(myPermissions, navigation);
                //
                //
                //     // Mark for check
                //
                // }
            });


        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    hideNavigationByPermissions(myPermissions: Permission[], navigationList: FuseNavigationItem[]): void {
        if (navigationList) {
            for (const fuseNavigation of navigationList) {
                const result = this.comparePermissions(myPermissions, fuseNavigation.permissions);
                fuseNavigation.hidden = !result;
                // console.debug('fuseNavigation: ' + fuseNavigation.id + ', hidden: ' + !result);
                if (fuseNavigation.children && fuseNavigation.children.length > 0) {
                    this.hideNavigationByPermissions(myPermissions, fuseNavigation.children);
                }
            }
        }
    }

    private hideEmptyGroup(navigationList: FuseNavigationItem[]): void {
        if (navigationList) {
            for (const fuseNavigationItem of navigationList) {
                if (!fuseNavigationItem.permissions || fuseNavigationItem.permissions.length === 0) {
                    if (fuseNavigationItem.children && fuseNavigationItem.children.length > 0) {
                        this.hideEmptyGroup(fuseNavigationItem.children);
                        let hidden = true;
                        for (const child of fuseNavigationItem.children) {
                            if (!child.hidden) {
                                hidden = false;
                                break;
                            }
                        }
                        fuseNavigationItem.hidden = hidden;
                    }
                }
            }
        }
    }

    /**
     *
     * @param userRoles
     * @param roles
     *
     * @deprecated
     */
    compareRoles(userRoles: any[], roles: any[]): boolean {
        let condition = false;
        if (roles) {
            if (userRoles) {
                for (const role of roles) {
                    for (const userRole of userRoles) {
                        if (role === userRole.authority) {
                            condition = true;
                        }
                    }
                }
            }
        } else {
            condition = true;
        }
        return condition;
    }

    comparePermissions(myPermissions: Permission[], navigationPermissions: string[]): boolean {
        if (navigationPermissions) {
            if (myPermissions) {
                for (const permissionName of navigationPermissions) {
                    for (const myPermission of myPermissions) {
                        if (permissionName === myPermission.name) {
                            return true;
                        }
                    }
                }
            }
        } else {
            return true;
        }
        return false;
    }
}

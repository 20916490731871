import {Component, OnInit} from '@angular/core';
import {RoleDAOService} from '../role-dao.service';
import {PermissionDAOService} from '../permission-dao.service';
import {Permission, Role} from '../../../models';
import {Router} from '@angular/router';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends ComponentCleaner implements OnInit {

    roles: Role[];
    permissions: Permission[];

    constructor(private roleDao: RoleDAOService,
                private permissionDao: PermissionDAOService,
                private router: Router,
                private dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.roleDao.get().subscribe((roles) => {
            this.roles = roles;
        });
        this.permissionDao.get().subscribe((permissions) => {
            this.permissions = permissions;
        });
    }

    onEdit(id: number): void {
        this.router.navigate(['/iam/role/' + id]);
    }

    onCreate(): void {
        this.router.navigate(['/iam/role/0']);
    }

    onCopy(id: number): void {
        this.router.navigate(['/iam/role/' + id], {queryParams: {copy: true}});
    }

    onDelete(id: number): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse papel?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.roleDao.remove(id).subscribe((roles) => {
                    this.roles = this.removeRoleOfList(id, this.roles);
                });
            }
        });
        this.addSubscription(subscription);
    }


    public removeRoleOfList(roleId: number, roleList: Role[]): Role[] {
        const index = roleList.findIndex((o) => o.id === roleId);
        if (index > -1) {
            roleList.splice(index, 1);
        }
        return roleList;
    }
}

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {ClientStatusToolbarComponent} from '../../../kluh-manager/client-status-toolbar/client-status-toolbar.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SearchModule} from '../search/search.module';
import {KluhSharedModule} from '../../../kluh-manager/kluh-shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        ToolbarComponent,
        ClientStatusToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        FuseSharedModule,
        FuseSearchBarModule,
        SearchModule,
        FuseShortcutsModule,
        MatAutocompleteModule,
        FontAwesomeModule,
        KluhSharedModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}

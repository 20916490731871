import {Component, ElementRef, Inject, OnInit, Renderer2} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MarketplaceService} from '../../../marketplace.service';
import {convertToFormGroup, CrudOperationWrapper} from '../../../../../helpers/kluh';
import {UserAuthorityDaoService} from '../../../../user-authority/user-authority-dao.service';
import {Observable} from 'rxjs/internal/Observable';
import {FirebaseUserDetails, MarketplaceProduct, MarketplaceProductComment} from '../../../../../models';
import {ImageFileService} from '../../../../image-file/image-file-service';

@Component({
    selector: 'app-marketplace-product-comment-edit',
    templateUrl: './marketplace-product-comment-edit.component.html',
    styleUrls: [
        './marketplace-product-comment-edit.component.scss',
        '../marketplace-product-comment.component.scss',
        '../../marketplace-product.component.scss']
})
export class MarketplaceProductCommentEditComponent implements OnInit {
    marketplaceProduct: MarketplaceProduct;
    comment: MarketplaceProductComment;
    commentRating = 0;
    oldComment: MarketplaceProductComment;
    myForm: FormGroup;
    user$: Observable<FirebaseUserDetails>;
    user: FirebaseUserDetails = null;

    constructor(public dialogRef: MatDialogRef<MarketplaceProductCommentEditComponent>,
                public marketplaceService: MarketplaceService,
                public render: Renderer2,
                public elRef: ElementRef,
                private userAuthorityDao: UserAuthorityDaoService,
                public imageFileService: ImageFileService,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
        });
        if (this.data && this.data.comment) {
            this.comment = this.data.comment;
            this.oldComment = this.data.comment;
            this.marketplaceProduct = this.data.marketplaceProduct;
            this.myForm = this.fb.group(convertToFormGroup(this.comment));
            this.onRatingSelected(this.comment.rating, 5);
        }
    }

    onSubmit(): void {
        this.comment = this.myForm.value;
        this.comment.rating = this.commentRating;
        const newAndOldComment = [this.comment, this.oldComment];
        const crudOperation: CrudOperationWrapper = {
            operation: 'SAVE',
            data: newAndOldComment
        };
        this.dialogRef.close(crudOperation);
    }

    onRatingSelected(star: number, totalStars: number): void {
        this.commentRating = star;
        this.marketplaceService.onRatingSelected(star, totalStars, this.render, this.elRef);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}

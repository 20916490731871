<div id="ad-domain-list" fxLayout="column" fxLayoutAlign="start stretch">
  <button type="button" class="mb-8" mat-raised-button (click)="onCreateAdDomain()">Criar</button>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let adDomain of adDomains">
    <div>
      {{adDomain.name}}
    </div>
    <div>
      {{adDomain.domainName}}
    </div>
    <div>
      {{adDomain.adServerId}}
    </div>
  </div>
</div>

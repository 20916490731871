import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ProjectDaoService} from '../project-dao.service';

import {ProjectCreateComponent} from '../project-create/project-create.component';
import {CustomerGroup, Project} from '../../../models';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {JaversShadow} from '../../../javers/javers-shadow';
import {JaversChanges} from '../../../javers/javers-changes';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
    public projects$: Observable<Project[]>;
    @Input()
    refresh: Subject<void>;
    @Input()
    customerGroups: CustomerGroup[];
    projectsSnapshots$: Observable<JaversSnapshot<Project>[]>;
    projectsShadows$: Observable<JaversShadow<Project>[]>;
    projectsChanges$: Observable<JaversChanges[]>;

    constructor(public projectDAO: ProjectDaoService,
                private snackBar: MatSnackBar, private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.refresh.subscribe(() => {
            this.projects$ = this.projectDAO.get();
            this.projectsSnapshots$ = this.projectDAO.snapshots();
            this.projectsShadows$ = this.projectDAO.shadows();
            this.projectsChanges$ = this.projectDAO.changes();
        });
        this.refresh.next();
    }

    editProject(project: Project): void {
        // console.debug('valendo: ' + project.name);
        //
        // let customerGroups;
        // if (project.customerGroupId) {
        //   customerGroups = [this.findCustomerGroup(project.customerGroupId)];
        // } else {
        //   customerGroups = this.customerGroups;
        // }
        const dialogRef = this.dialog.open(ProjectCreateComponent, {
            disableClose: true,
            panelClass: 'ad-domain-edit-dialog',
            data: {
                project: project,
            }
        });
        // dialogRef.afterClosed().takeUntil(this.destroy$).filter((x) => !!(x)).subscribe((project) => {
        //   // TODO implementar historico de notificação
        //   this.snackBar.open(project.name + ' Criado com Sucesso', null, {duration: 5000});
        //   this.onRefresh.next();
        // });
    }

    findCustomerGroup(customerGroupId: number): CustomerGroup {
        return this.customerGroups.find(value => value.id === customerGroupId);
    }

}

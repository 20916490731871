import {Component, OnInit} from '@angular/core';
import {CrudOperationWrapper, formatStringWithLeadingZero} from '../../../helpers/kluh';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {ManagerUserAccessPeriodCreateEditComponent} from '../../manager-user-access-period/manager-user-access-period-create-edit/manager-user-access-period-create-edit.component';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ManagerUserAccessPeriodProfile, ManagerUserAccessPeriodTime} from '../../../models';
import {ManagerUserAccessPeriodService} from '../../manager-user-access-period/manager-user-access-period.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-register-manager-user-access-period',
    templateUrl: './register-manager-user-access-period.component.html',
    styleUrls: ['./register-manager-user-access-period.component.scss', '../register.component.scss']
})
export class RegisterManagerUserAccessPeriodComponent extends ComponentCleaner implements OnInit {
    appCanCreateList: PermissionWrapper[];

    constructor(public adminService: R2CloudAdminService,
                private dialog: MatDialog,
                public customerGroupService: CustomerGroupService,
                public managerUserAccessPeriodService: ManagerUserAccessPeriodService) {
        super();

        this.appCanCreateList = [
            {type: 'ManagerUserAccessPeriodProfile', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];

    }

    ngOnInit(): void {
    }


    formatStringWithLeadingZero(num: any): string {
        return formatStringWithLeadingZero(num);
    }

    openCreateManagerUserAccessPeriodModal(): void {
        const dialogRef = this.dialog.open(ManagerUserAccessPeriodCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((managerUserOperation: CrudOperationWrapper[]) => {
            if (managerUserOperation) {
                this.addOrReplaceManagerUserAccessPeriodProfile(managerUserOperation[0].data);
                this.addOrReplaceManagerUserAccessPeriodTimes(managerUserOperation[1].data);
            }
        });
        this.addSubscription(subscription);
    }

    editManagerUserAccessPeriod(managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile, managerUserAccessPeriodTimes: ManagerUserAccessPeriodTime[]): void {
        const dialogRef = this.dialog.open(ManagerUserAccessPeriodCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                managerUserAccessPeriodProfile: managerUserAccessPeriodProfile,
                managerUserAccessPeriodTimes: managerUserAccessPeriodTimes
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((managerUserAccessPeriodOperation: CrudOperationWrapper) => {
            if (managerUserAccessPeriodOperation) {
                if (managerUserAccessPeriodOperation[0].operation === 'CREATE' || managerUserAccessPeriodOperation[0].operation === 'SAVE') {
                    this.addOrReplaceManagerUserAccessPeriodProfile(managerUserAccessPeriodOperation[0].data);
                    this.addOrReplaceManagerUserAccessPeriodTimes(managerUserAccessPeriodOperation[1].data);
                } else if (managerUserAccessPeriodOperation[0].operation === 'DELETE') {
                    this.removeManagerUserAccessPeriod(managerUserAccessPeriodOperation[0].data);
                }

            }
        });
        this.addSubscription(subscription);
    }


    getManagerUserAccessPeriodTimeFromProfileId(profileId: number): ManagerUserAccessPeriodTime[] {
        if (profileId && this.managerUserAccessPeriodService.managerUserAccessPeriodTimes) {
            return this.managerUserAccessPeriodService.managerUserAccessPeriodTimes.filter(value => value.managerUserAccessPeriodProfileId === profileId).sort(this.compare);
        }
        return null;
    }

    compare(a: ManagerUserAccessPeriodTime, b: ManagerUserAccessPeriodTime): number {
        if (a.dayOfWeek < b.dayOfWeek) {
            return -1;
        }
        if (a.dayOfWeek > b.dayOfWeek) {
            return 1;
        }
        return 0;
    }


    public addOrReplaceManagerUserAccessPeriodProfile(managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile): void {
        if (!this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles) {
            this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles = [];
        }
        const index = this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles.findIndex((o) => o.id === managerUserAccessPeriodProfile.id);
        if (index < 0) {
            this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles.push(managerUserAccessPeriodProfile);
        } else {
            this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles[index] = managerUserAccessPeriodProfile;
        }
    }

    public addOrReplaceManagerUserAccessPeriodTimes(managerUserAccessPeriodTimes: ManagerUserAccessPeriodTime[]): void {
        if (!this.managerUserAccessPeriodService.managerUserAccessPeriodTimes) {
            this.managerUserAccessPeriodService.managerUserAccessPeriodTimes = [];
        }
        if (managerUserAccessPeriodTimes) {
            for (let i = 0; i < managerUserAccessPeriodTimes.length; i++) {
                const index = this.managerUserAccessPeriodService.managerUserAccessPeriodTimes.findIndex((o) => o.id === managerUserAccessPeriodTimes[i].id);
                if (index < 0) {
                    this.managerUserAccessPeriodService.managerUserAccessPeriodTimes.push(managerUserAccessPeriodTimes[i]);
                } else {
                    this.managerUserAccessPeriodService.managerUserAccessPeriodTimes[index] = managerUserAccessPeriodTimes[i];
                }
            }
        }
    }


    public removeManagerUserAccessPeriod(managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile): void {
        if (!this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles) {
            this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles = [];
        }
        const index = this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles.findIndex((o) => o.id === managerUserAccessPeriodProfile.id);
        if (index > -1) {
            this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles.splice(index, 1);
        }
    }

}

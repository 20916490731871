<div fusePerfectScrollbar class="page-layout simple fullwidth">
    <div class="content p-24">
        <div fxLayout="column">
            <mat-card>
                <mat-card-header>
                    <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                        <div> IAM Papéis</div>
                        <div>
                            <button type="button" mat-raised-button color="accent" id="roles-iam-create" (click)="onCreate()">Criar</button>
                        </div>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <table cellspacing="0" cellpadding="0" class="border_top">
                        <thead>
                        <tr class="no-border">
                            <th class="min-width-250"></th>
                            <th class="width-40"></th>
                        </tr>
                        </thead>
                        <tr *ngFor="let role of roles">
                            <td>
                                <div class="mat-body-2">{{role.name}}</div>
                                <span class="mat-caption">{{role.comment}}</span>
                            </td>
                            <td id="roles-iam-{{role.name}}" >
                                <button mat-button [matMenuTriggerFor]="menu" class="small-button">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="onCopy(role.id)" class="roles-iam-copy-{{role.name}}" >Copiar</button>
                                    <button mat-menu-item  (click)="onEdit(role.id)" class="roles-iam-edit-{{role.name}}" >Editar</button>
                                    <button mat-menu-item (click)="onDelete(role.id)" class="roles-iam-delete-{{role.name}}"  >Excluir</button>
                                </mat-menu>
                            </td>
                        </tr>


                    </table>
                </mat-card-content>
            </mat-card>


        </div>
    </div>
</div>
import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {ADUser, ADUserHtml5, ADUserWithPassword, FilterByEmailAndAdDomainIdAndSubProjectId, ManagerUserEmailsSubProjectIdADDomainId} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class AdUserDaoService extends BaseDao<ADUser> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'ad-user');
    }

    getMyAdUsers(): Observable<ADUser[]> {
        return this.http.get<ADUser[]>(this.url + '/me');
    }

    getMyAdUsersWithoutPassword(): Observable<ADUser[]> {
        return this.http.get<ADUser[]>(this.url + '/get-my-ad-users-without-password');
    }

    findByLogin(login: string, adDomainId: number): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/find-by-login?login=' + login + '&adDomainId=' + adDomainId);
    }

    findByLoginPw(login: string, adDomainId: number): Observable<ADUserWithPassword> {
        return this.http.get<ADUserWithPassword>(this.url + '/find-by-login-pw?login=' + login + '&adDomainId=' + adDomainId);
    }

    createAdUserWithCheckRemoveChanging(adUserWithPassword: ADUserWithPassword): Observable<ADUser> {
        return this.http.post<ADUser>(this.url + '/create-with-check-remove-changing', adUserWithPassword);
    }

    createAdUserWithCheck(adUserWithPassword: ADUserWithPassword): Observable<ADUser> {
        return this.http.post<ADUser>(this.url + '/create-with-check', adUserWithPassword);
    }

    removeAdUserReferences(adUserId: number, subProjectId: number, withoutConfirmation: boolean): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/remove-aduser-references?adUserId=' + adUserId + '&subProjectId=' + subProjectId + '&withoutConfirmation=' + withoutConfirmation);
    }

    resetPasswordAdUser(adUserId: number): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/reset-password?adUserId=' + adUserId);
    }

    setRandomPassword(adUserId: number): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/set-random-password?adUserId=' + adUserId);
    }

    setLegacyRandomPassword(adUserId: number): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/set-legacy-random-password?adUserId=' + adUserId);
    }


    insertAdUserInSubProjectAdUserList(byEmailAndAdDomainIdAndSubProjectId: FilterByEmailAndAdDomainIdAndSubProjectId): Observable<ADUser> {
        return this.http.post<ADUser>(this.url + '/insert-aduser-in-subproject-aduser-list', byEmailAndAdDomainIdAndSubProjectId);
    }


    saveAdUserWithCheck(adUserWithPassword: ADUserWithPassword): Observable<ADUser> {
        return this.http.put<ADUser>(this.url + '/save-with-check', adUserWithPassword);
    }


    saveMyPassword(adUserWithPassword: ADUserWithPassword): Observable<ADUser> {
        return this.http.put<ADUser>(this.url + '/save-my-password', adUserWithPassword);
    }

    create(entity: ADUser): Observable<ADUser> {
        return super.create(entity);
    }

    getOneHtml5(adUserId: number): Observable<ADUserHtml5> {
        return this.http.get<ADUserHtml5>(this.url + '/html5/' + adUserId);
    }

    getAllAdUsersPendingOrDeleting(): Observable<ADUser[]> {
        return this.http.get<ADUser[]>(this.url + '/get-all-ad-users-pending-or-deleting');
    }

    getByEmailAndAdDomainIdAndSubProjectId(email: string, adDomainId: number, subProjectId: number): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/get-by-email-and-ad-domain-id-and-sub-project-id?email='
            + email + '&adDomainId=' + adDomainId + '&subProjectId=' + subProjectId);
    }

    getByManagerUserIdAndAdDomainId(managerUserId: number, adDomainId: number): Observable<ADUser> {
        return this.http.get<ADUser>(this.url + '/get-by-manager-user-id-and-ad-domain-id?managerUserId='
            + managerUserId + '&adDomainId=' + adDomainId);
    }


    saveADUserGroups(adUser: ADUser, subProjectId: number): Observable<ADUser> {
        return this.http.post<ADUser>(this.url + '/save-aduser-groups/' + subProjectId, adUser);
    }

    saveAdUserPasswordWithoutConfirmation(adUserWithPassword: ADUserWithPassword): Observable<ADUser> {
        return this.http.post<ADUser>(this.url + '/save-aduser-password-without-confirmation/', adUserWithPassword);
    }

    addManagerUserInBatchToSubProject(managerUserEmailsSubProjectIdADDomainId: ManagerUserEmailsSubProjectIdADDomainId): Observable<ADUser[]> {
        return this.http.post<ADUser[]>(this.url + '/add-manager-user-in-batch-to-sub-project/', managerUserEmailsSubProjectIdADDomainId);
    }

    findAllBySubProjectId(subProjectId: number, serviceUser: boolean): Observable<ADUser[]> {
        return this.http.get<ADUser[]>(this.url + '/find-all-by-sub-project-id/' + subProjectId + '?serviceUser=' + serviceUser);
    }

}

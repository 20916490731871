import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../base-dao';
import {MarketplaceOrderComment} from '../../../../models';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';

@Injectable()
export class MarketplaceOrderCommentDaoService extends BaseDao<MarketplaceOrderComment> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'marketplace-order-comment');
    }
    findAllByMarketplaceOrderId(marketplaceOrderId: number): Observable<MarketplaceOrderComment[]> {
        return this.http.get<MarketplaceOrderComment[]>(this.url + '/find-all-by-marketplace-order-id/' + marketplaceOrderId);
    }
}

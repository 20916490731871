import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';
import {Router} from '@angular/router';
import {GatewayServerDaoService} from '../gateway-server-dao.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ADDomain, BaseServer, CustomerGroup, DesktopServer, GatewayServer, GatewayServerNatConfig, LinuxServer, SubProject} from '../../../models';
import {FormControl} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {GatewayServerNatConfigDaoService} from '../gateway-server-nat-config/gateway-server-nat-config-dao.service';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {faLinux, faWindows} from '@fortawesome/free-brands-svg-icons';


@Component({
    selector: 'app-gateway-server-base-server-list',
    templateUrl: './gateway-server-base-server-list.component.html',
    styleUrls: ['./gateway-server-base-server-list.component.scss']
})
export class GatewayServerBaseServerListComponent implements OnInit {

    constructor(
        protected customerGroupService: CustomerGroupService,
        protected customerGroupDaoService: CustomerGroupDaoService,
        private router: Router,
        private adDomainDaoService: AdDomainDaoService,
        private gatewayServerDaoService: GatewayServerDaoService,
        private gatewayServerNatConfigDaoService: GatewayServerNatConfigDaoService,
        private desktopServerDaoService: DesktopServerDaoService,
        private linuxServerDaoService: LinuxServerDaoService,
        private subProjectDaoService: SubProjectDaoService,
        public dialogRef: MatDialogRef<GatewayServerBaseServerListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    @ViewChild('allSelected') private allSelected: MatOption;

    customerGroupList: CustomerGroup[] = [];
    gatewayServerList: GatewayServer[] = [];
    partnerCustomerGroups: CustomerGroup[] = [];
    baseServerFilteredList: BaseServer[] = [];
    desktopServerList: DesktopServer[] = [];
    linuxServerList: LinuxServer[] = [];
    gatewayServerNatConfigList: GatewayServerNatConfig[] = [];
    adDomainList: ADDomain[] = [];
    subProjectList: SubProject[] = [];
    partnerCustomerGroupControl: FormControl = new FormControl();
    gatewayServerInUseControl: FormControl = new FormControl();
    gatewayServerNatConfigActiveControl: FormControl = new FormControl();
    serverTypeControl: FormControl = new FormControl();

    protected readonly faCircle = faCircle;
    protected readonly faWindows = faWindows;
    protected readonly faLinux = faLinux;

    ngOnInit(): void {

        this.customerGroupDaoService.getAllFromDao().subscribe((customerGroups) => {
            this.customerGroupList = customerGroups;
            combineLatest([
                this.gatewayServerDaoService.get(),
                this.desktopServerDaoService.get(),
                this.linuxServerDaoService.getAllLinuxServersWithSubProjectIds(),
                this.gatewayServerNatConfigDaoService.get(),
                this.adDomainDaoService.get(),
                this.subProjectDaoService.get()
            ]).subscribe((values) => {
                this.gatewayServerList = values[0];
                this.desktopServerList = values[1];
                this.linuxServerList = values[2].map(li => li.linuxServerDTO);
                this.gatewayServerNatConfigList = values[3];
                this.adDomainList = values[4];
                this.subProjectList = values[5];
                this.desktopServerList.forEach(desktopServer => {
                    const partnerCustomerGroup = this.customerGroupByDesktopServer(desktopServer);
                    if (partnerCustomerGroup?.partnerCustomerGroupId) {
                        const partnerExists = this.partnerCustomerGroups.find(partner => partner.id === partnerCustomerGroup?.partnerCustomerGroupId);
                        if (!partnerExists) {
                            this.partnerCustomerGroups.push(this.customerGroupList.find(g => g.id === partnerCustomerGroup?.partnerCustomerGroupId));
                        }
                    }
                });
                this.linuxServerList.forEach(linuxServer => {
                    const partnerCustomerGroup = this.customerGroupByLinuxServer(linuxServer);
                    if (partnerCustomerGroup?.partnerCustomerGroupId) {
                        const partnerExists = this.partnerCustomerGroups.find(partner => partner.id === partnerCustomerGroup?.partnerCustomerGroupId);
                        if (!partnerExists) {
                            this.partnerCustomerGroups.push(this.customerGroupList.find(g => g.id === partnerCustomerGroup?.partnerCustomerGroupId));
                        }
                    }
                });
                this.partnerCustomerGroupControl.setValue(0);
                this.gatewayServerInUseControl.setValue('ALL');
                this.gatewayServerNatConfigActiveControl.setValue('ALL');
                this.serverTypeControl.setValue('ALL');
            });
            this.partnerCustomerGroupControl.valueChanges.subscribe((customerGroupIds: number[]) => {
                this.changeBaseServerServerFilteredList(
                    customerGroupIds,
                    this.gatewayServerInUseControl.value,
                    this.gatewayServerNatConfigActiveControl.value,
                    this.serverTypeControl.value);
            });
            this.gatewayServerInUseControl.valueChanges.subscribe((inUse: string) => {
                this.changeBaseServerServerFilteredList(
                    this.partnerCustomerGroupControl.value,
                    inUse,
                    this.gatewayServerNatConfigActiveControl.value,
                    this.serverTypeControl.value);
            });
            this.gatewayServerNatConfigActiveControl.valueChanges.subscribe((gatewayServerNatConfigActive: string) => {
                this.changeBaseServerServerFilteredList(
                    this.partnerCustomerGroupControl.value,
                    this.gatewayServerInUseControl.value,
                    gatewayServerNatConfigActive,
                    this.serverTypeControl.value);
            });
            this.serverTypeControl.valueChanges.subscribe((serverType: string) => {
                this.changeBaseServerServerFilteredList(
                    this.partnerCustomerGroupControl.value,
                    this.gatewayServerInUseControl.value,
                    this.gatewayServerNatConfigActiveControl.value,
                    serverType);
            });
        });
    }

    private changeBaseServerServerFilteredList(
        customerGroupIds: number[],
        gatewayServerInUseValue: string,
        gatewayServerNatConfigActive: string,
        serverType: string
    ): void {
        this.baseServerFilteredList = this.filterServersByCustomerGroups(customerGroupIds);
        this.baseServerFilteredList = this.filterServersByNatUsage(gatewayServerInUseValue);
        this.baseServerFilteredList = this.filterServersByNatConfig(gatewayServerNatConfigActive);
        this.baseServerFilteredList = this.filterBaseServersByType(serverType);
    }

    private filterServersByCustomerGroups(customerGroupIds?: number[]): BaseServer[] {
        const baseServerInnerList: BaseServer[] = [];
        if (!customerGroupIds) {
            baseServerInnerList.push(...this.desktopServerList);
            baseServerInnerList.push(...this.linuxServerList);
        } else {
            this.desktopServerList.forEach((desktopServerIn) => {
                const customerGroup = this.customerGroupByDesktopServer(desktopServerIn);
                if (desktopServerIn && customerGroupIds.indexOf(customerGroup?.partnerCustomerGroupId) > -1 ||
                    (customerGroupIds.indexOf(-2) > -1 && !customerGroup.partnerCustomerGroupId) ||
                    customerGroupIds.indexOf(0) > -1) {
                    baseServerInnerList.push(desktopServerIn);
                }
            });
            this.linuxServerList.forEach((linuxServerIn) => {
                const customerGroup = this.customerGroupByLinuxServer(linuxServerIn);
                if (linuxServerIn && customerGroupIds.indexOf(customerGroup?.partnerCustomerGroupId) > -1 ||
                    (customerGroupIds.indexOf(-2) > -1 && !customerGroup.partnerCustomerGroupId) ||
                    customerGroupIds.indexOf(0) > -1) {
                    baseServerInnerList.push(linuxServerIn);
                }
            });
        }
        return baseServerInnerList;
    }


    private filterServersByNatUsage(gatewayServerInUseValue: string): BaseServer[] {
        return this.baseServerFilteredList.filter(baseServer => {
            const linuxServer = this.findLinuxServerByClientId(baseServer.clientId);
            const desktopServer = this.findDesktopServerByClientId(baseServer.clientId);
            const gatewayServerNatConfig = this.gatewayServerNatConfigList.find(nat =>
                nat.linuxServerId === linuxServer?.id || nat.desktopServerId === desktopServer?.id);

            if (gatewayServerInUseValue === 'WITH_NAT') {
                return !!gatewayServerNatConfig;
            } else if (gatewayServerInUseValue === 'WITHOUT_NAT') {
                return !gatewayServerNatConfig;
            }
            return true;
        });
    }


    private filterServersByNatConfig(gatewayServerNatConfigActive: string): BaseServer[] {
        return this.baseServerFilteredList.filter(server => {
            if (gatewayServerNatConfigActive === 'ACTIVE') {
                return server.gatewayServerNatConfigActive === true;
            } else if (gatewayServerNatConfigActive === 'INACTIVE') {
                return server.gatewayServerNatConfigActive === false;
            }
            return true;
        });
    }


    private filterBaseServersByType(serverType: string): BaseServer[] {
        return this.baseServerFilteredList.filter(baseServer => {
            const linuxServer = this.findLinuxServerByClientId(baseServer.clientId);
            const desktopServer = this.findDesktopServerByClientId(baseServer.clientId);
            if (serverType === 'WINDOWS') {
                return !!desktopServer;
            } else if (serverType === 'LINUX') {
                return !!linuxServer;
            }
            return true;
        });
    }


    onCancel(): void {
        this.dialogRef.close();
    }

    tosslePerOne(): void {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
        }
    }

    customerGroupByDesktopServer(desktopServer: DesktopServer): CustomerGroup {
        const subProject = this.subProjectList.find(sp => sp.desktopServerIds.find(desktopServerId => desktopServerId === desktopServer.id));
        return this.customerGroupList.find(cg => cg.id === subProject.customerGroupId);
    }

    customerGroupByLinuxServer(linuxServer: LinuxServer): CustomerGroup {
        const subProject = this.subProjectList.find(sp => sp.linuxServerIds.find(linuxServerId => linuxServerId === linuxServer.id));
        return this.customerGroupList.find(cg => cg.id === subProject.customerGroupId);
    }

    findDesktopServerByClientId(clientId: string): DesktopServer {
        return this.desktopServerList.find(server => server.clientId === clientId);
    }

    findLinuxServerByClientId(clientId: string): LinuxServer {
        return this.linuxServerList.find(server => server.clientId === clientId);
    }

    linuxServerHasNatConfig(linuxServer: LinuxServer): boolean {
        return !!this.gatewayServerNatConfigList.find(nat => nat?.linuxServerId === linuxServer?.id);
    }

    desktopServerHasNatConfig(desktopServer: DesktopServer): boolean {
        return !!this.gatewayServerNatConfigList.find(nat => nat?.desktopServerId === desktopServer?.id);
    }

    amountServerNAT(baseServerList: BaseServer[], withNat: boolean): number {
        return baseServerList.reduce((count, baseServer) => {
            const linuxServer = this.findLinuxServerByClientId(baseServer.clientId);
            const desktopServer = this.findDesktopServerByClientId(baseServer.clientId);
            const hasNat = this.desktopServerHasNatConfig(desktopServer) || this.linuxServerHasNatConfig(linuxServer);
            return count + (hasNat === withNat ? 1 : 0);
        }, 0);
    }

    amountServerActive(baseServerList: BaseServer[], active: boolean): number {
        return baseServerList.filter(baseServer => baseServer.gatewayServerNatConfigActive === active).length;
    }

    toggleAllSelection(): void {
        if (this.allSelected.selected) {
            const partnerCustomerGroups: CustomerGroup[] = [];
            this.partnerCustomerGroups.forEach(val => partnerCustomerGroups.push(Object.assign({}, val)));
            partnerCustomerGroups.push(this.customerGroupService.initCustomerGroups());
            this.partnerCustomerGroupControl
                .patchValue([...partnerCustomerGroups.map(item => item.id), 0]);

        } else {
            this.partnerCustomerGroupControl.patchValue([]);
        }
    }

    openProjectCustomerGroup(customerGroup: CustomerGroup): void {
        this.customerGroupService.set(customerGroup);
        this.router.navigate(['admin']).then(() => {
            this.dialogRef.close();
        });
    }
}

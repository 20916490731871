import {Injectable} from '@angular/core';
import {FuseNavigationService} from '../../../@fuse/components/navigation/navigation.service';
import {UserAuthorityDaoService} from '../main/user-authority/user-authority-dao.service';
import {FuseNavigation} from '../../../@fuse/types';
import {navigation} from 'app/navigation/navigation';
import {Router} from '@angular/router';
import {ManagerUserPermissionsDaoService} from '../main/manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {Permission} from '../models';
import {filter, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {Observable} from 'rxjs/internal/Observable';
import {CustomerGroupService} from '../main/customer-group/customer-group.service';

@Injectable({
    providedIn: 'root'
})
export class R2MenuService {
    private navigation: FuseNavigation[] = navigation;
    customerGroupService: CustomerGroupService;
    private isAdmin = false;

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private userAuthorityDaoService: UserAuthorityDaoService,
        private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService,
        private router: Router,
    ) {

    }


    hideMenuWithoutPermission(customerGroupId: number): void {
        // console.log('menu: teste');
        this.userAuthorityDaoService.getMe().pipe(
            switchMap((user) => {
                if (user) {
                    return this.managerUserPermissionsDaoService.isAdminUser(user.managerUser.id);
                } else {
                    return of(null) as Observable<boolean>;
                }
            }),
            filter((isAdmin) => isAdmin != null),
            switchMap((isAdmin) => {
                // console.log('menu: forkJoin: isAdmin ' + isAdmin);
                return forkJoin([of(isAdmin), this.userAuthorityDaoService.getMyPermissions()]);
            }))
            .subscribe((result) => {
                this.isAdmin = result[0];
                // console.log('menu: this.isAdmin: ' + this.isAdmin);
                const myPermissions = result[1];
                if (myPermissions) {
                    const subProjectIds = this.customerGroupService.customerGroup?.subProjectIds;
                    this.checkPermission(this.navigation, myPermissions, customerGroupId, subProjectIds);
                    // console.log('menu: hideMenuWithoutPermission: ' + customerGroupId);
                    this.customerGroupService.hideDesktopHtml5MenuIfTheSelectedCustomerGroupHasOnlyLocalAdminUsers(this.customerGroupService.customerGroup?.localAdminUsersOnly);
                }
            });
    }

    innitVisibleMenu(): void {
        // console.log('menu: innitVisibleMenu()');
        this.navigation.filter(navi => {
            if (navi.permissions) {
                // console.log('menu: navi.id: ' + navi.id);
                this.setNavigationItemVisibility(navi.id, true);
            }
            navi.children?.filter(children => {
                if (children.permissions) {
                    // console.log('menu: children.id: ' + children.id);
                    this.setNavigationItemVisibility(children.id, true);
                }
            });
        });
    }


    private checkPermission(fuseNavigation: FuseNavigation[], myPermissions: Permission[], customerGroupId: number, subProjectIds: number[]): void {
        fuseNavigation.filter(navi => {
            let hasRootVisible = true;
            if (this.isAdmin) {
                this.setNavigationItemVisibility(navi.id, false);
            } else {
                navi.permissions?.filter(permission => {
                    let index = myPermissions.findIndex((o) => o.name === permission && o.customerGroupIds.indexOf(customerGroupId) > -1);
                    if (index < 0) {
                        index = myPermissions.findIndex((o) => o.name === permission && o.subProjectIds.some(x => subProjectIds.includes(x)));
                    }
                    this.setNavigationItemVisibility(navi.id, index < 0);
                    if (index < 0) {
                        hasRootVisible = false;
                        if (this.router.url === navi.url) {
                            this.router.navigate(['desktop']);
                        }
                    }

                });
            }
            navi.children?.filter(children => {
                if (this.isAdmin) {
                    this.setNavigationItemVisibility(children.id, false);
                } else {
                    children.permissions?.filter(permission => {
                        let index = myPermissions.findIndex((o) => o.name === permission && o.customerGroupIds.indexOf(customerGroupId) > -1);
                        if (index < 0) {
                            index = myPermissions.findIndex((o) => o.name === permission && o.subProjectIds.some(x => subProjectIds.includes(x)));
                        }
                        this.setNavigationItemVisibility(children.id, index < 0);
                        if (index < 0) {
                            if (this.router.url === children.url) {
                                this.router.navigate(['desktop']);
                            }
                        }
                    });
                }
            });
            let hasVisible = false;
            navi.children?.filter(children => {
                if (!children.hidden) {
                    hasVisible = true;
                }
            });
            if (!hasVisible) {
                this.setNavigationItemVisibility(navi.id, true);
            } else if (hasRootVisible) {
                this.setNavigationItemVisibility(navi.id, false);
            }
        });
    }


    private setNavigationItemVisibility(itemId: string, hidden: boolean): void {
        this._fuseNavigationService.updateNavigationItem(itemId, {
                hidden: hidden
            }
        );
    }


}


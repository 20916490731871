export interface GraphResolution {
    name: string;
    value: number;
}

export const resolutions = [
    {
        name: 'Baixa',
        value: 15
    },
    {
        name: 'Baixa2',
        value: 25
    },
    {
        name: 'Média',
        value: 100
    },
    {
        name: 'Alta',
        value: 500
    }
] as GraphResolution[];

import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {GatewayAlias, GatewayServer, GatewayServerInUse, GatewayServerWithPassword} from '../../models';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {ValueWrapper} from '../../helpers/value-wrapper';

@Injectable()
export class GatewayServerDaoService extends BaseDao<GatewayServer> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'gateway-server');
    }

    initGatewayServer(): GatewayServer {
        return {
            id: null,
            name: null,
            comment: null,
            active: true,
            modified: null,
            optlock: null,
            fqdn: null,
            secure: true,
            port: null,
            login: null,
            administrationIps: this.getCurrentHostname(),
            customerGroupId: null,
            createdAt: null,
            updatedAt: null,
            online: null,
            lastErrorMessage: null,
            lastStatusUpdatedAt: null
        };
    }

    private getCurrentHostname(): string {
        return window.location.hostname;
    }

    initGatewayServerWithPassword(): GatewayServerWithPassword {
        return {
            gatewayServerDTO: this.initGatewayServer(),
            password: null,
        };
    }

    createWithCheck(gatewayServerWithPassword: GatewayServerWithPassword): Observable<GatewayServer> {
        return this.http.post<GatewayServer>(this.url + '/create-with-check', gatewayServerWithPassword);
    }
    createWithoutCheck(gatewayServerWithPassword: GatewayServerWithPassword): Observable<GatewayServer> {
        return this.http.post<GatewayServer>(this.url + '/create-without-check', gatewayServerWithPassword);
    }

    saveWithCheck(gatewayServerWithPassword: GatewayServerWithPassword): Observable<GatewayServer> {
        return this.http.put<GatewayServer>(this.url + '/save-with-check', gatewayServerWithPassword);
    }
    saveWithoutCheck(gatewayServerWithPassword: GatewayServerWithPassword): Observable<GatewayServer> {
        return this.http.put<GatewayServer>(this.url + '/save-without-check', gatewayServerWithPassword);
    }
    asyncDelete(gatewayServerWithPassword: GatewayServerWithPassword): Observable<void> {
        return this.http.delete(this.url + '/async-delete', gatewayServerWithPassword);
    }

    findAllBy(customerGroupId: number, customerGroupName: string): Observable<GatewayServer[]> {
        if (!customerGroupId || !customerGroupName) {
            return of();
        }
        return this.http.get<GatewayServer[]>(this.url + '/find-all-by/' + customerGroupId + '/' + customerGroupName);
    }

    suspendGetAlias(gatewayServerId: number, gatewayAliasName: string): Observable<GatewayAlias> {
        return this.http.post<GatewayAlias>(this.url + '/suspend-get-alias/' + gatewayAliasName, gatewayServerId);
    }

    findWhereGatewayServerInUseById(gatewayServerId: number): Observable<GatewayServerInUse[]> {
        return this.http.get<GatewayServerInUse[]>(this.url + '/find-where-gateway-server-in-use-by-id/' + gatewayServerId);
    }

    findWhereGatewayServerInUseByIds(gatewayServerIds: number[]): Observable<GatewayServerInUse[]> {
        return this.http.post<GatewayServerInUse[]>(this.url + '/find-where-gateway-server-in-use-by-ids/', gatewayServerIds);
    }

    suspendCheckCommunication(gatewayServerWithPassword: GatewayServerWithPassword): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(this.url + '/check-communication/', gatewayServerWithPassword);

    }
    checkIfCanCreate(gatewayServerWithPassword: GatewayServerWithPassword): Observable<boolean> {
        return this.http.post<boolean>(this.url + '/check-if-can-create/', gatewayServerWithPassword);
    }
}

import {Injectable} from '@angular/core';
import {SessionStore} from './ts/session-store';

@Injectable({
  providedIn: 'root'
})
export class SessionStoreService extends SessionStore {

  constructor() {
      super();
  }
}

import {Injectable} from '@angular/core';
import {TableDataService} from '../table-data.service';
import {WindowsService} from '../../../../models-monitoring';

@Injectable({
    providedIn: 'root'
})
export class ServicesDetailsTableService extends TableDataService<WindowsService> {

    constructor() {
        super();
    }
}

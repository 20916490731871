import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {debounceTime, map, mergeMap, take} from 'rxjs/operators';
import {R2CloudHttpBase} from '../r2-cloud-http.base';

@Injectable()
export class KluhManagerValidator {
    constructor() { }

    validator(url: String, http: R2CloudHttpBase): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return control.valueChanges.pipe(
                debounceTime(500),
                mergeMap((value) => {
                    return http.put<any[]>(url + '/validate', value).pipe(
                        map((response) => {
                            const result = {};
                            for (const error of response) {
                                result[error.field] = error.defaultMessage;
                            }
                            return result;
                        }));
                }),
                take(1));
        };
    }
}

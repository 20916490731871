import {Injectable} from '@angular/core';
import {ConnectionProperties} from './ts/connection-properties';

@Injectable({
    providedIn: 'root'
})
export class TrustedCollectionsService {
    private a: Window;
    private c: Storage;

    constructor() {
        this.a = window;
        this.c = this.a.sessionStorage;
        if (!this.c) {
            throw new Error('Persistent storage is not available!');
        }
    }

    private d = (): any => {
        try {
            return JSON.parse(this.c.getItem('RdWebAppTrustedCollections')) || [];
        } catch (a) {
            return [];
        }
    };

    clearTrustedCollections(): void {
        this.c.removeItem('RdWebAppTrustedCollections');
    }

    isCollectionTrusted(a: any): any {
        const c = this.d(),
            e = new BookmarkHelper(a);
        for (const f in c) {
            if (c.hasOwnProperty(f)) {
                const g = c[f];
                if (e.isEqual(g)) {
                    return !0;
                }
            }
        }
        return !1;
    }

    trustCollection(a: any): void {
        if (!this.isCollectionTrusted(a)) {
            const f = this.d(),
                g = new BookmarkHelper(a);
            f.push(g), this.c.setItem('RdWebAppTrustedCollections', JSON.stringify(f));
        }
    }
}

export class BookmarkHelper {
    HostName: any;
    Port: any;
    LBInfo: any;
    GatewayHostName: any;
    GatewayPort: any;
    Redirections: any;

    constructor(a: ConnectionProperties) {
        const c = a && a.connectionSettings;
        c && (this.HostName = c.HostName.toLowerCase(), this.Port = c.Port, this.LBInfo = c.LBInfo, this.GatewayHostName = c.GatewayHostName.toLowerCase(),
            this.GatewayPort = c.GatewayPort, this.Redirections = {
            Clipboard: c.EnableClipboard,
            Printing: c.EnablePrinting
        });
    }

    isEqual(a: any): any {
        const c = this.Redirections || {},
            d = a.Redirections || {};
        return this.HostName === a.HostName && this.Port === a.Port && this.LBInfo === a.LBInfo && this.GatewayHostName === a.GatewayHostName &&
            this.GatewayPort === a.GatewayPort && c.Clipboard === d.Clipboard && c.Printing === d.Printing;
    }

}

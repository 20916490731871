import {Component, Inject, OnInit} from '@angular/core';

import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'app-wait-dialog',
    templateUrl: './wait-dialog.component.html',
    styleUrls: ['./wait-dialog.component.scss']
})
export class WaitDialogComponent implements OnInit {
    public message: String;
    public disableCancel: boolean;
    public icon: string;

    constructor(private dialogRef: MatDialogRef<WaitDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.disableCancel = data.disableCancel;

        if (data.message) {
            this.message = data.message;
        } else {
            this.message = 'Por favor, aguarde...';
        }

        if (data.icon) {
            this.icon = data.icon;
        } else {
            this.icon = 'help_outline';
        }

        const ob: Observable<any> = data.ob;
        const now = moment();
        let timeout: number;
        if (data.timeout) {
            timeout = data.timeout;
        } else {
            timeout = 500;
        }
        // const future = now.add(timeout, 'milliseconds');
        ob.pipe(take(1)).subscribe((result) => {
            console.debug('WaitDialogComponent timeout1: ' + timeout);
            const waitResult: WaitResult<any> = {
                cancelled: false, data: result, error: undefined
            };
            console.debug('WaitDialogComponent timeout2: ' + timeout);
            // let number = future.diff(moment());
            // if (number < 0) {
            //     number = 0;
            // }
            setTimeout(() => {
                console.debug('WaitDialogComponent close');
                this.dialogRef.close(waitResult);
            }, timeout);
        }, error => {
            const waitResult: WaitResult<any> = {
                cancelled: false, data: null, error: error
            };
            this.dialogRef.close(waitResult);
        });
    }

    ngOnInit(): void {
    }

    onCancel(): void {
        const waitResult: WaitResult<any> = {
            cancelled: true, data: undefined, error: undefined
        };
        this.dialogRef.close(waitResult);
    }
}

export interface WaitResult<T> {
    data: T | undefined | null;
    cancelled: boolean;
    error: any | undefined | null;
}

export class WaitCancelled extends Error {
    constructor() {
        super('cancelled');
    }
}

// export function createWaitModal<T>(matDialog: MatDialog, ob: Observable<T>): Observable<T> {
//     return createWaitModal(matDialog, ob, {});
// }

export function createWaitModal<T>(
    matDialog: MatDialog,
    ob: Observable<T>,
    {
        waitMilliSeconds = null,
        disableCancel = true,
        message = null,
        icon = null
    }: {
        waitMilliSeconds?: number;
        disableCancel?: boolean;
        message?: string;
        icon?: string;
    } = {}
): Observable<T> {
    return matDialog.open(WaitDialogComponent, {
        disableClose: true,
        data: {
            message: message,
            disableCancel: disableCancel,
            icon: icon,
            ob: ob,
            timeout: waitMilliSeconds
        }
    }).afterClosed().pipe(map((result: WaitResult<T>) => {
        if (result.error) {
            throw result.error;
        }
        if (result.cancelled) {
            throw new WaitCancelled();
        }
        return result.data;
    }));
}

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CustomerGroup, HipcomBulkUpdateMessage} from '../../../../../models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HipcomBulkUpdateMessageDaoService} from '../hipcom-bulk-update-message-edit/hipcom-bulk-update-message-dao.service';
import {convertToFormGroup} from '../../../../../helpers/kluh';
import {ImageFileService} from '../../../../image-file/image-file-service';

@Component({
    selector: 'app-hipcom-bulk-update-message-send-message',
    templateUrl: './hipcom-bulk-update-message-send-message.component.html',
    styleUrls: ['./hipcom-bulk-update-message-send-message.component.scss']
})
export class HipcomBulkUpdateMessageSendMessageComponent {

    myForm: FormGroup;
    hipcomBulkUpdateMessage: HipcomBulkUpdateMessage;
    customerGroups: CustomerGroup[] = [];
    everyone = false;
    desktopServerId: number;
    hipcomBulkUpdateDetailId: number;

    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdateMessageSendMessageComponent>,
                public imageFileService: ImageFileService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private hipcomBulkUpdateMessageDaoService: HipcomBulkUpdateMessageDaoService,
                private fb: FormBuilder) {
        this.hipcomBulkUpdateMessage = {
            id: null,
            comment: null,
            active: true,
            message: null,
            timeInMinutes: 30,
            modified: null,
            optlock: null
        };
        this.customerGroups = this.data.customerGroups;
        this.desktopServerId = this.data.desktopServerId;
        this.hipcomBulkUpdateDetailId = this.data.hipcomBulkUpdateDetailId;
        if (!this.customerGroups) {
            this.everyone = this.data.everyone;
            if (!this.everyone) {
                this.dialogRef.close();
            }
        }

        this.myForm = this.fb.group(convertToFormGroup(this.hipcomBulkUpdateMessage));
    }


    onSubmit(): void {
        const formValue: HipcomBulkUpdateMessage = this.myForm.value;
        if (this.everyone) {
            this.hipcomBulkUpdateMessageDaoService.sendMessageToAll(formValue).subscribe(v => {
                this.dialogRef.close();
            });
        } else {
            this.hipcomBulkUpdateMessageDaoService.sendMessage(formValue, this.hipcomBulkUpdateDetailId).subscribe(v => {
                this.dialogRef.close();
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}

<form [formGroup]="myForm" (ngSubmit)="myForm.valid && onSubmit()">
  <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Cadastro de Projetos</span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
       fxLayoutAlign="space-between stretch" matDialogContent>
    <mat-form-field class="full-width" formGroupName="projectCreateForm">
      <input matInput placeholder="Nome do Projeto" name="name" required formControlName="name">
      <mat-error>
        {{myForm.get('projectCreateForm').get('name').errors | error}}
      </mat-error>
    </mat-form-field>

    <div *ngIf="customerGroups && !myForm.get('projectCreateForm').get('hasSubProjects').value" formArrayName="subProjectsForm" fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column">
        <div *ngFor="let subProject of subProjectsForm.controls; index as i" [formGroupName]="i">
          <div>
            <mat-form-field>
              <mat-select placeholder="Nome do Grupo" formControlName="customerGroupId"
                          style="min-width: 300px;margin-right: 15px">
                <mat-option *ngFor="let customerGroup of customerGroups" [value]="customerGroup.id">
                  {{customerGroup.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <button type="button" mat-raised-button color="accent" (click)="onAddCustomerGroup()">Criar novo Grupo</button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between" fxFill class="p-16" matDialogActions
         formGroupName="projectCreateForm">
      <mat-slide-toggle formControlName="hasSubProjects" name="hasSubProjects">
        Habilitar Sub-Projetos
      </mat-slide-toggle>

      <mat-slide-toggle formControlName="active" name="active">
        Ativo
      </mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
    <button type="submit" mat-raised-button color="accent"
            [disabled]="!myForm.valid || myForm.pristine" aria-label="Salvar">
      <span *ngIf="this.project.id;else newProject">Salvar</span>
      <ng-template #newProject>Criar</ng-template>
    </button>
    <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
  </div>
</form>


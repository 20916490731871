import {Component, Input} from '@angular/core';
import {ADDomain, ServerPresence, ServerType, SubProject} from '../../../models';
import {faLinux, faWindows} from '@fortawesome/free-brands-svg-icons';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {StatusService} from '../status-service';
import {FormControl} from '@angular/forms';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';

@Component({
  selector: 'app-servers-online',
  templateUrl: './servers-online.component.html',
  styleUrls: ['./servers-online.component.scss', '../status.component.scss']
})
export class ServersOnlineComponent extends ComponentCleaner {

    public faWindows = faWindows;
    public faLinux = faLinux;

    searchFieldControl: FormControl = new FormControl();
    serversOnline: ServerPresence[] = [];
    serversOnlineWrapperListFilter: ServerPresence[] = [];

    @Input()
    adDomains: ADDomain[] = [];

    @Input()
    subProjects: SubProject[] = [];

    serverWindows: ServerType = ServerType.WINDOWS;
    serverLinux: ServerType = ServerType.LINUX;

    constructor(private desktopServerDaoService: DesktopServerDaoService,
                private linuxServerDaoService: LinuxServerDaoService,
                private statusServer: StatusService) {
        super();
        this.desktopServerDaoService.getServersOnLine().subscribe((desktopServersOnline) => {
            if (desktopServersOnline) {
                for (const desktopServerOnline of desktopServersOnline) {
                    this.serversOnline.push(desktopServerOnline);
                    this.serversOnlineWrapperListFilter.push(desktopServerOnline);
                }
            }
        });

        this.linuxServerDaoService.getServersOnLine().subscribe((linuxServersOnline) => {
            if (linuxServersOnline) {
                for (const linuxServerOffline of linuxServersOnline) {
                    this.serversOnline.push(linuxServerOffline);
                    this.serversOnlineWrapperListFilter.push(linuxServerOffline);
                }
            }
        });

        const subscription2 = this.searchFieldControl.valueChanges.subscribe(() => {
            const searchField = this.searchFieldControl.value;
            if (searchField && searchField.length > 0) {
                this.serversOnlineWrapperListFilter = this.serversOnline.filter(
                    serverOnline => {
                        const search = searchField.toString().toLowerCase();
                        if (serverOnline.hostname.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOnline;
                        }
                        if (serverOnline.connectionFQDN.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOnline;
                        }
                        if (serverOnline.connectionPort?.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOnline;
                        }
                        if (serverOnline.serverType.toString().toLowerCase().indexOf(search) > -1) {
                            return serverOnline;
                        }
                        for (const subProjectId of serverOnline.subProjectIds) {
                            const subProject = this.subProjects.filter(subProjectIn => {
                                return subProjectIn.id === subProjectId;
                            });
                            if (subProject.length > 0 && subProject[0].name.toString().toLowerCase().indexOf(search) > -1) {
                                if (subProject[0].name.toString().toLowerCase().indexOf(search) > -1) {
                                    return serverOnline;
                                }
                            }
                        }
                    });
            } else {
                this.serversOnlineWrapperListFilter = this.serversOnline;
            }
        });
        this.addSubscription(subscription2);
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {ChatAiComponent} from './chat-ai/chat-ai.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {FooterModule} from '../../../layout/components/footer/footer.module';
import {ThreadService} from './thread/thread.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AiUserGroupDaoService} from './ai-user-group/ai-user-group.dao.service';
import {ThreadDaoService} from './thread/thread.dao.service';
import {AiUserGroupEditComponent} from './ai-user-group/ai-user-group-edit/ai-user-group-edit.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AiConfigComponent} from './ai-config/ai-config.component';
import {AiUserGroupModalComponent} from './ai-user-group/ai-user-group-edit/ai-user-group-modal/ai-user-group-modal.component';
import {AiUserModalComponent} from './ai-user-group/ai-user-group-edit/ai-user-modal/ai-user-modal.component';
import {AiUserDaoService} from './ai-user/ai-user.dao.service';
import {AiUserGroupFileEditComponent} from './ai-user-group-file/ai-user-group-file-edit/ai-user-group-file-edit.component';
import {AiUserGroupFileDaoService} from './ai-user-group-file/ai-user-group-file.dao.service';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AiSoftwareCompanyFileDaoService} from './ai-software-company-file/ai-software-company-file-dao.service';
import {AiSoftwareCompanyFileEditComponent} from './ai-software-company-file/ai-software-company-file-edit/ai-software-company-file-edit.component';
import {AssistantDaoService} from './assistant/assistant.dao.service';
import {ModelDaoService} from './model/model.dao.service';
import {ModelCreateComponent} from './model/create/model-create.component';
import {CustomerGroupAiConfigDaoService} from './ai-config/customer-group-ai-config.dao.service';


const routes = [
    {
        path: 'chat-ai',
        component: ChatAiComponent,
        canActivate: [AuthService],
        data: {permissions: ['ChatAi.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        KluhSharedModule,
        NgxMaskModule.forRoot(),
        RouterModule.forChild(routes),
        MatToolbarModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        FooterModule,
        MatCheckboxModule,
        MatDialogModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        NgBytesPipeModule,
        MatSlideToggleModule
    ],
    providers: [
        ThreadService,
        AiUserGroupDaoService,
        AiUserDaoService,
        ThreadDaoService,
        AiUserGroupFileDaoService,
        AiSoftwareCompanyFileDaoService,
        CustomerGroupAiConfigDaoService,
        AssistantDaoService,
        ModelDaoService
    ],
    declarations: [
        ChatAiComponent,
        AiUserGroupEditComponent,
        AiConfigComponent,
        AiUserGroupModalComponent,
        AiUserModalComponent,
        AiUserGroupFileEditComponent,
        AiSoftwareCompanyFileEditComponent,
        ModelCreateComponent
    ],
    entryComponents: []
})
export class AiModule {
}

import {FormArray, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {findDuplicateValueInArray} from '../utils/utils-kluh';

export function uniqueFieldValueOfFormArrayOfFormGroupValidator(fieldError: any, formArrayName: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const myFormArray: FormArray = group.controls[formArrayName] as FormArray;
        const allIPs: string[] = myFormArray.controls.map(data => data.value).map(data => data.clientVpnIp);

        const formControlsDuplicate: FormGroup[] = (myFormArray.controls.filter(data => data.value)
            .filter(data => findDuplicateValueInArray(allIPs)
                .includes(data.value.clientVpnIp))) as FormGroup[];

        if (formControlsDuplicate.length > 0) {
            const lastFormGroup: FormGroup = formControlsDuplicate[formControlsDuplicate.length - 1];
            lastFormGroup.controls.clientVpnIp.setErrors(fieldError);
        }
        return;
    };
}




import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {ADDomain, ADGroup, CustomerGroup, ManagerUser, SnapshotType, SubProject} from '../../../models';
import {AdGroupDaoService} from '../../ad-group/ad-group-dao.service';
import {StatusService} from '../status-service';
import {FormControl} from '@angular/forms';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-ad-group-snapshots',
    templateUrl: './ad-group-snapshots.component.html',
    styleUrls: ['./ad-group-snapshots.component.scss', '../status.component.scss']
})
export class AdGroupSnapshotsComponent {

    public adGroupsSnapshots: JaversSnapshot<ADGroup>[];

    @Input()
    adDomains: ADDomain[] = [];

    @Input()
    managerUsers: ManagerUser[] = [];

    @Input()
    customerGroups: CustomerGroup[] = [];

    @Input()
    subProjects: SubProject[] = [];

    @Input()
    adGroups: ADGroup[];

    @Output()
    adGroupsRead: EventEmitter<ADGroup[]> = new EventEmitter();

    snapshotTypeControl: FormControl = new FormControl();
    sizeListControl: FormControl = new FormControl();
    snapshotType = SnapshotType;
    loading = false;


    constructor(private adGroupDaoService: AdGroupDaoService, public statusServer: StatusService, private dialog: MatDialog) {
        this.sizeListControl.setValue(5);
        this.snapshotTypeControl.setValue(SnapshotType.ALL);
    }


    private snapshotsByTypeAndLimit(size: number, snapshotType: SnapshotType = SnapshotType.ALL): void {
        this.loading = true;
        this.adGroupsSnapshots = [];
        this.adGroupDaoService.snapshotsByTypeAndLimit(size, snapshotType).subscribe((subProjectsSnapshots) => {
            this.adGroupsSnapshots = subProjectsSnapshots;
            this.loading = false;
        });
    }

    refresh(): void {
        this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    onJaversHistory(id: number): void {
        EntityHistoryComponent.openHistory(id, this.adGroupDaoService, this.dialog);
    }
}

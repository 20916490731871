<mat-card class="card">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                Plugins
            </div>
            <div>
                <button mat-raised-button type="button" color="accent" (click)="onCreate()">Criar</button>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let serverPlugin of serverPluginService.serverPlugins; let i = index"
             class="row-item" [ngClass]="{'mt-8': i > 0, 'even': i % 2 === 0, 'odd': i % 2 != 0}">
            <div>
                {{serverPlugin.name}}
            </div>
            <div>
                <button type="button" mat-raised-button color="accent" (click)="onCreateServerPluginFile(serverPlugin)">
                    Add Versão
                </button>
                <button type="button" mat-raised-button color="accent" class="ml-8" (click)="onEdit(serverPlugin)">
                    Editar
                </button>
                <button type="button" class="ml-10" mat-mini-fab color="warn" (click)="onDelete(serverPlugin)">
                    <mat-icon>
                        delete
                    </mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
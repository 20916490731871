
<table *ngIf="(gatewayServerList && gatewayServerList.length > 0)"
       class="app-table" border="0" cellspacing="0">
    <tr *ngFor="let gatewayServer of gatewayServerList">
        <td class="w-300 p-20">
            {{ gatewayServer.name }}
        </td>
        <td class="w-400 p-20">
            {{ gatewayServer.fqdn }}:{{ gatewayServer.port }}
        </td>
        <td class="w-30 p-20">
            <div (click)="showStatusData(gatewayServer)">
                <div class="client-connected"
                     title="Online {{gatewayServer.lastStatusUpdatedAt | date: 'dd/MM/yyyy HH:mm'}}"
                     *ngIf="gatewayServer.online; else offline">
                    <fa-icon [icon]="faCircle"></fa-icon>
                </div>
                <ng-template #offline>
                    <div class="client-not-connected"
                         title="Offline {{gatewayServer.lastStatusUpdatedAt | date: 'dd/MM/yyyy HH:mm'}}">
                        <fa-icon [icon]="faCircle"></fa-icon>
                    </div>
                </ng-template>
            </div>
        </td>
        <td class="text-align-right p-0">
            <div class="w-150" *ngIf="loading[gatewayServer.id]" >
                <mat-spinner style="margin-left: 60px;" [diameter]="24" color="primary"></mat-spinner>
            </div>
            <div fxLayout="row" fxLayoutAlign="end start" *ngIf="!loading[gatewayServer.id]">
                <div class="mr-10">
                    <button type="button" mat-stroked-button color="primary" class="mat-green-600-bg "
                            (click)="gatewayServerInUseModal(gatewayServer)">Info
                    </button>
                </div>

                <div class="mr-10">
                    <button type="button" mat-stroked-button color="accent" class="mr-10"
                            (click)="edit(gatewayServer)">Edit
                    </button>
                </div>
            </div>
        </td>
    </tr>
</table>

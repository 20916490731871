import {Component, Input} from '@angular/core';
import {CustomerGroup, RemoteApp, SubProject} from '../../../models';
import {RemoteAppDaoService} from '../../remote-app/remote-app-dao.service';
import {StatusService} from '../status-service';
import {ImageFileService} from '../../image-file/image-file-service';

@Component({
    selector: 'app-remote-apps-changing-or-deleting',
    templateUrl: './remote-apps-changing-or-deleting.component.html',
    styleUrls: ['./remote-apps-changing-or-deleting.component.scss', '../status.component.scss']
})
export class RemoteAppsChangingOrDeletingComponent {
    loading = false;
    alreadyRead = false;

    public remoteAppsPendingOrDeleting: RemoteApp[];

    @Input()
    customerGroups: CustomerGroup[] = [];

    @Input()
    subProjects: SubProject[] = [];

    constructor(
        private remoteAppDaoService: RemoteAppDaoService,
        public statusServer: StatusService,
        public imageFileService: ImageFileService,
    ) {
    }

    getData(): void {
        this.loading = true;
        this.alreadyRead = true;
        this.remoteAppDaoService.getAllRemoteAppsPendingOrDeleting().subscribe((remoteAppsPendingOrDeleting) => {
            this.loading = false;
            this.remoteAppsPendingOrDeleting = remoteAppsPendingOrDeleting;
        });
    }

}

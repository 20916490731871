<div class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
         style="z-index: 99;margin-top: 12px;">
        <div fxLayout="row" fxLayoutAlign="start center" style="min-height: 92px;">
            <mat-icon class="mr-10 font-size-24">shopping_basket</mat-icon>
            <span class="h1 m-0">Marketplace </span>
        </div>
        <div>
            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="search" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input placeholder="buscar serviço"
                           id="marketplace-create-new-product-search"
                           [formControl]="searchMultiFilterCtrl" matInput fxFlex
                           autocomplete="off">
                </div>
            </div>
        </div>
        <div style="min-width: 142px">
            <div fxFlex fxLayout="column" fxLayoutAlign="center start">
                <button mat-raised-button (click)="onCreateNewProduct()"
                        id="marketplace-create-new-product"
                        *appIfPermission="appHasPermissionList">
                    Inserir serviço
                </button>
                <button mat-raised-button (click)="onCreateNewCategory()"
                        id="marketplace-create-new-category"
                        class="mt-20" *appIsAdmin>Inserir categoria
                </button>
            </div>
        </div>
    </div>
    <div class="ml-15">
        <div>
            <mat-tab-group animationDuration="0ms" disableRipple="true" id="marketplace-tab-category">
                <mat-tab label="Tudo" id="marketplace-tab-all" >
                    <app-marketplace-product-list
                        [marketplaceProductList]=findAllMarketplaceProductActive()
                        (showProductDetailEvent)="onShowProductDetail($event)">
                    </app-marketplace-product-list>
                </mat-tab>

                <mat-tab label="Desativados" id="marketplace-tab-inactive"  *appIsAdmin>
                    <app-marketplace-product-list
                        [marketplaceProductList]=findAllMarketplaceProductActiveFalse()
                        (showProductDetailEvent)="onShowProductDetail($event)">
                    </app-marketplace-product-list>
                </mat-tab>

                <mat-tab *ngFor="let category of marketplaceCategoryList"
                         id="marketplace-tab-{{category.name}}"
                         label="{{category.name}}">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tr>
                            <td class="width-100-percent">
                                <app-marketplace-product-list
                                    [marketplaceProductList]=findAllMarketplaceProductByCategoryId(category.id)
                                    (showProductDetailEvent)="onShowProductDetail($event)">
                                </app-marketplace-product-list>
                            </td>
                            <td style="vertical-align: top;" *appIsAdmin>
                                <div class="mr-10 ml-10">
                                    <button mat-raised-button
                                            id="marketplace-create-edit-category"
                                            (click)="onEditCategory(category)">
                                        Editar Categoria
                                    </button>
                                    <button mat-raised-button
                                            id="marketplace-create-delete-category"
                                            color="warn" class="mt-20"
                                            (click)="onDeleteCategory(category)">
                                        Excluir Categoria
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

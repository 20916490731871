<div class="dialog-content-wrapper">
    <form [formGroup]="myForm"
          id="marketplace-product-screen-edit-form"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro e edição de imagens</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div #contentRef class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <div formArrayName="myFormArray">
                <mat-card *ngFor="let productScreen of myFormControls.controls; let i = index"
                          [formGroupName]="i" class="mb-20">
                    <mat-card-content>
                        <div>
                            <div class="full-width">
                                <div>
                                    <img src="{{imageFileService.getMarketPlaceProductScreenPictureUrl(productScreen.get('imageUUID')?.value)}}" width="450"
                                         *ngIf="!imageFileElement[i] && productScreen.get('id')?.value"
                                    />
                                    <img [src]="'data:image/jpg;base64,' + imageFileElement[i]" width="450"
                                         *ngIf="imageFileElement[i]">
                                </div>
                                <input (change)="onImageFileChange($event, i)" name="file"
                                       id="bannerUpload{{i}}" accept="image/*" type="file" class="mb-30 mt-30">
                            </div>

                            <mat-form-field appearance="outline" class="mr-30 width-100-percent">
                                <mat-label>
                                    Descrição
                                </mat-label>
                                <textarea matInput formControlName="description"
                                          id="marketplace-product-screen-edit-description-{{i+1}}"
                                          rows="4"></textarea>
                            </mat-form-field>

                        </div>

                        <button type="button" class="float-right" mat-raised-button color="warn"
                                (click)="onRemove(productScreen.value)">Delete
                        </button>
                        <div style="clear: both"></div>
                    </mat-card-content>
                </mat-card>
            </div>

            <button type="button" mat-raised-button color="primary"
                    id="marketplace-product-screen-edit-add"
                    class="text-align-center mt-20 mb-30"
                    (click)="onAddProductScreen()">Add
            </button>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>

            <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="marketplaceProduct.id;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

        </div>
    </form>
</div>

import {Injectable} from '@angular/core';
import {ObjectHelper} from './ts/object-helper';
import {BookmarkType} from './ts/enum/bookmark-type';
import {PlatformInfoService} from './platform-info.service';
import {LoggerService} from './logger.service';
import {AppSettingsStoreService} from './app-settings-store.service';
import {RdpConnection} from './ts/rdp-connection';

declare const Module;

@Injectable({
    providedIn: 'root'
})
export class TelemetryService {
    private d: any;
    private i: any = !1;
    private j: any = !1;
    private k: any = window.localStorage;
    private l: any = null;
    private m: any = null;
    private n: any = null;
    private o: any = {
        loaded: !1,
        multiTenant: !1,
        feedCount: 0,
        publishedFolderCount: 0,
        publishedAppCount: 0,
        publishedDesktopCount: 0
    };

    constructor(private aa: AppSettingsStoreService, private b: PlatformInfoService, private c: LoggerService) {

    }

    onFeedsLoaded(b: any): void {
        // @ts-ignore
        this.d = window.awa || null;
        if (this.aa.telemetryEnabled) {
            this.enable();
            this.B(b);
        }
    }

    private a = (): void => {
        const a = new ObjectHelper();
        if (this.l = a.createGuid(), this.k) {
            try {
                this.k.setItem('RdWebClient::Telemetry::supportID', this.l);
            } catch (a) {
                this.c.warn('Could not write support ID to local storage: ' + a);
            }
        }
    };

    private p = (): void => {
        if (this.d && !this.i) {
            const b = {
                coreData: {
                    appId: 'RdWebClient',
                    sendEvents: !0
                }
            };
            this.k && (this.l = this.k.getItem('RdWebClient::Telemetry::supportID')), this.l || this.a(), this.d.ids.setAppUserId('c:' + this.l), this.d.init(b),
                this.c.log('Telemetry initialized and enabled.'), this.i = !0;
        }
    };

    private q = (): void => {
        if (this.d && this.i) {
            const a = {
                name: 'Microsoft.RDS.Web.Client.DailyDeviceInfo',
                data: {
                    'Microsoft.RDS.Web.Client.DailyDeviceInfo': {
                        eventVersion: 1,
                        appVersion: this.b.clientVersion,
                        locale: this.b.getLocale(),
                        hardwareModeEnabled: this.b.isHardwareModeEnabled()
                    }
                }
            };
            this.d.writeEvent(a);
        }
    };

    private r = (): void => {
        if (this.d && this.i) {
            let a = 0,
                c = 0,
                e = 0,
                f = 0,
                g = 0,
                h = 0,
                j = 0;
            this.o.multiTenant ? (a = this.o.feedCount, e = this.o.publishedDesktopCount, g = this.o.publishedAppCount,
                j = this.o.publishedFolderCount) : (c = this.o.publishedDesktopCount, f = this.o.publishedAppCount, h = this.o.publishedFolderCount);
            const k = {
                name: 'Microsoft.RDS.Web.Client.DailyResourceInfo',
                data: {
                    'Microsoft.RDS.Web.Client.DailyResourceInfo': {
                        eventVersion: 1,
                        appVersion: this.b.clientVersion,
                        feedCountMT: a,
                        publishedDesktopCount: c,
                        publishedDesktopCountMT: e,
                        publishedAppCount: f,
                        publishedAppCountMT: g,
                        publishedFolderCount: h,
                        publishedFolderCountMT: j
                    }
                }
            };
            this.d.writeEvent(k);
        }
    };

    private s = (a: RdpConnection): void => {
        if (this.d && this.i) {
            let c, e = 0;
            const f = a.telemetry.initialWidth + 'x' + a.telemetry.initialHeight,
                g = Math.round(100 * a.telemetry.initialScale);
            a.telemetry.timeConnected && (e = Math.round((a.telemetry.timeConnected - a.telemetry.timeLaunched) / 1e3)),
                c = this.o.multiTenant ? a.isRail ? 'publishedAppMT' : 'publishedDesktopMT' : a.isRail ? 'publishedApp' : 'publishedDesktop';
            const h = {
                name: 'Microsoft.RDS.Web.Client.SessionLaunch',
                data: {
                    'Microsoft.RDS.Web.Client.SessionLaunch': {
                        eventVersion: 1,
                        appVersion: this.b.clientVersion,
                        success: !!a.telemetry.timeConnected,
                        sourceType: c,
                        connectionId: a.id,
                        launchTime: e,
                        correlationId: a.correlationId,
                        resolutionUsed: f,
                        scaleUsed: g
                    }
                }
            };
            this.d.writeEvent(h);
        }
    };

    private t = (a: any): void => {
        if (this.d && this.i) {
            const c = a.disconnectReason.code.value === Module.DisconnectCode.UserInitiated.value,

                f = a.disconnectReason.code.value,
                g = a.disconnectReason.extendedCode;
            let e = 0,
                h = 0;
            a.telemetry.arcSuccess > 0 && (h = Math.round(a.telemetry.totalArcTime / a.telemetry.arcSuccess / 1e3)),
            a.telemetry.timeConnected && (e = Math.round((a.telemetry.timeDisconnected - a.telemetry.timeConnected) / 6e4));
            const j = {
                name: 'Microsoft.RDS.Web.Client.SessionData',
                data: {
                    'Microsoft.RDS.Web.Client.SessionData': {
                        eventVersion: 1,
                        appVersion: this.b.clientVersion,
                        simpleDisconnectCode: f,
                        userInitiated: c,
                        serverStackDisconnectCode: g,
                        correlationId: a.id,
                        minutes: e,
                        arcTriggered: a.telemetry.arcAttempts,
                        arcSuccess: a.telemetry.arcSuccess,
                        arcAvgTimeToConnect: h
                    }
                }
            };
            this.d.writeEvent(j);
        }
    };

    private u = (a: any): void => {
        const c = a.telemetry.appSwitchedCount,
            e = a.telemetry.sessionSwitchedCount;
        if (this.d && this.i) {
            const f = {
                name: 'Microsoft.RDS.Web.Client.InSessionActivity',
                data: {
                    'Microsoft.RDS.Web.Client.InSessionActivity': {
                        eventVersion: 1,
                        appVersion: this.b.clientVersion,
                        correlationId: a.id,
                        sessionSwitched: e,
                        appSwitched: c,
                        resolutionChanged: a.telemetry.resolutionChanged,
                        clipboardUsed: a.telemetry.clipboardUsed,
                        audioUsed: a.telemetry.audioUsed
                    }
                }
            };
            this.d.writeEvent(f);
        }
    };

    private v = (a: any): void => {
        if (this.d && this.i) {
            const c = {
                name: 'Microsoft.RDS.Web.Client.CrashStackTrace',
                data: {
                    'Microsoft.RDS.Web.Client.CrashStackTrace': {
                        eventVersion: 1,
                        appVersion: this.b.clientVersion,
                        exception: a
                    }
                }
            };
            this.d.writeEvent(c);
        }
    };

    private w = (): any => {
        if (!this.m && this.k) {
            try {
                this.m = parseInt(this.k.getItem('RdWebClient::Telemetry::dailyEventTimestamp'), 10);
            } catch (a) {
                this.c.warn('Could not read the daily event timestamp from local storage: ' + a);
            }
        }
        return this.m;
    };

    private x = (a: any): void => {
        if (this.m = a, this.k) {
            try {
                this.k.setItem('RdWebClient::Telemetry::dailyEventTimestamp', this.m.toString());
            } catch (a) {
                this.c.warn('Could not write the daily event timestamp to local storage: ' + a);
            }
        }
    };

    private y = (): void => {
        this.q(), this.r(), this.x((new Date()).getTime()), this.n = setTimeout(() => this.y(), 864e5);
    };

    private z = (): void => {
        if (!this.n && this.o.loaded) {
            let a;
            const b = this.w(),
                c = (new Date).getTime();
            a = !b || c - b >= 864e5 ? 0 : 864e5 - (c - b), this.n = setTimeout(() => this.y(), a);
        }
    };

    private A = (): void => {
        this.n && (clearTimeout(this.n), this.n = null);
    };

    private B = (a: any): void => {
        this.o.loaded = !a.loading && !a.loadFailed, this.o.multiTenant = a.multiTenant, this.o.feedCount = a.workspaces.length, this.o.publishedFolderCount = 0,
            this.o.publishedAppCount = 0, this.o.publishedDesktopCount = 0;
        for (let b = 0; b < a.workspaces.length; b++) {
            this.o.publishedFolderCount += a.workspaces[b].folders.length;
            for (let c = a.workspaces[b], e = 0; e < c.bookmarks.length; e++) {
                c.bookmarks[e].bookmarkType === BookmarkType.RemoteApp ? this.o.publishedAppCount++ : this.o.publishedDesktopCount++;
            }
        }
        this.d && this.i && this.z();
    };

    private C = (): void => {
        this.l = null, this.k.removeItem('RdWebClient::Telemetry::supportID');
    };

    disable(): void {
        this.i && (this.d.getConfig().sendEvents = !1, this.c.log('Telemetry disabled')), this.j = !1, this.C(), this.A();
    }

    enable(): void {
        (this.i && !this.j ? (this.a(), this.d.getConfig().sendEvents = !0, this.c.log('Telemetry enabled')) : this.p(), this.j = !0, this.z());
    }

    onConnected(a: RdpConnection): void {
        0 === a.telemetry.arcAttempts && this.s(a);
    }

    onDisconnected(a: any): void {
        a.telemetry.timeConnected ? (this.t(a), this.u(a)) : (this.s(a), this.t(a));
    }

    onException(a: any): void {
        this.v(a);
    }

    onRdCoreTelemetryEvent(a: any, b: any, d: any): void {
        this.c.log('Core telemetry event: eventName=' + b + ', traceMessage=' + d), 'OnAudioStarted' === b && (a.telemetry.audioUsed = !0);
    }

    getSupportID(): any {
        return this.j ? this.l : null;
    }

}

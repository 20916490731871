import {Injectable, OnDestroy} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {ManagerUserPermissions} from '../../../models';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable, of, Subject} from 'rxjs';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {CacheControl} from '../../../helpers/CacheControl';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class ManagerUserPermissionsDaoService extends BaseDao<ManagerUserPermissions> implements OnDestroy, CacheControl {
    private myUserPermissions$: Observable<ManagerUserPermissions>;
    private onDestroy$ = new Subject();
    private adminUserList$: Observable<boolean[]> = of([]);

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'manager-user-permissions');
    }

    ngOnDestroy(): void {
        this.adminUserList$ = of([]);
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    setCacheControl(ob: Observable<boolean>): void {
        ob.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.clearCache();
        });
    }

    clearCache(): void {
        this.adminUserList$ = of([]);
        this.myUserPermissions$ = null;
    }

    getMyUserPermissions(): Observable<ManagerUserPermissions> {
        if (!this.myUserPermissions$) {
            this.myUserPermissions$ = this.http.get<ManagerUserPermissions>('manager-user-permissions/me').pipe(shareReplay(1));
        }
        return this.myUserPermissions$;
    }


    isAdminUser(managerUserId: number): Observable<boolean> {
        if (!this.adminUserList$[managerUserId]) {
            this.adminUserList$[managerUserId] = this.http.get<boolean>('manager-user-permissions/is-admin-user/' + managerUserId).pipe(shareReplay(1));
        }
        return this.adminUserList$[managerUserId];
    }

    findByManagerUserId(managerUserId: number): Observable<ManagerUserPermissions> {
        return this.http.get<ManagerUserPermissions>('manager-user-permissions/find-by-manager-user-id/' + managerUserId);
    }

}

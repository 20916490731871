import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MarketplaceProduct} from '../../../models';
import {ImageFileService} from '../../image-file/image-file-service';
import {MarketplaceService} from '../marketplace.service';
import {MarketplaceProductService} from '../marketplace-product/marketplace-product.service';

@Component({
    selector: 'app-marketplace-product-list',
    templateUrl: './marketplace-product-list.component.html',
    styleUrls: ['./marketplace-product-list.component.scss']
})
export class MarketplaceProductListComponent implements OnInit {

    @Input()
    marketplaceProductList: MarketplaceProduct[] = [];

    @Output() showProductDetailEvent = new EventEmitter<number>();

    constructor(
        public imageFileService: ImageFileService,
        public marketplaceService: MarketplaceService,
        public marketplaceProductService: MarketplaceProductService
    ) {
    }

    ngOnInit(): void {
    }

    onShowProductDetail(id: number): void {
        this.showProductDetailEvent.next(id);
    }
}

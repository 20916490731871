<mat-card class="card">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                Templates de Plugin
            </div>
            <div>
                <button mat-raised-button type="button" color="accent" (click)="onCreate()">Criar</button>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let serverPluginTemplate of serverPluginService.serverPluginTemplates; let i = index"
             class="row-item" [ngClass]="{'mt-8': i > 0, 'even': i % 2 === 0, 'odd': i % 2 != 0}">
            <div>
                {{serverPluginTemplate.name}}
            </div>
            <div>
                <button type="button" mat-raised-button color="accent" class="ml-8"
                        (click)="onEdit(serverPluginTemplate)">
                    Editar
                </button>
                <button type="button" class="ml-10" mat-mini-fab color="warn" (click)="onDelete(serverPluginTemplate)">
                    <mat-icon>
                        delete
                    </mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
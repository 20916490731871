<button type="button" mat-raised-button
        class="m-20"
        mat-button color="accent"
        (click)="onCreate(null)">Criar novo
</button>

<table mat-table matSort [dataSource]="customPortalTemplateDataSource" matSortActive="memory" matSortDirection="asc"
       class="width-100-percent mt-25">

    <ng-container matColumnDef="logoId">
        <th mat-header-cell *matHeaderCellDef style="width: 100px;"></th>
        <td mat-cell *matCellDef="let element" class="pl-10"  style="width: 100px;">
            <div *ngIf="element.logoId">
                <img src="{{imageFileService.getPictureUrlFromImageFileTemplateId(element.logoId, 50)}}"
                     width="50">
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
        <td mat-cell *matCellDef="let element">
            <div>{{element.name}}</div>
            <div *ngIf="element.public" class="primary-200-fg font-size-10">(público)</div>
        </td>
    </ng-container>


    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="w-250"></th>
        <td mat-cell *matCellDef="let element" class="pl-10">
            <button type="button" mat-raised-button
                    class="no-padding mr-10"
                    mat-button
                    (click)="onDuplicate(element)">Duplicar
            </button>

            <button type="button" mat-raised-button
                    class="no-padding mr-10"
                    *ngIf="element.creatorCustomerGroupId == customerGroup.id || isAdmin"
                    mat-button color="accent"
                    (click)="onCreate(element)">Editar
            </button>

            <button type="button" mat-raised-button
                    *ngIf="!element?.public || isAdmin || element.creatorCustomerGroupId == customerGroup.id"
                    class="no-padding mr-10"
                    mat-button color="warn"
                    (click)="onDelete(element)">Excluir
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="customPortalTemplateDetailsTable"></tr>
    <tr mat-row *matRowDef="let row; columns: customPortalTemplateDetailsTable;"
        [ngClass]="{'yellow-200-bg': row.public}"
    ></tr>
</table>

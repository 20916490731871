import {EventProvider} from './event-provider';
import {ConnectionTelemetry} from './connection-telemetry';
import {ConnectionDelegate} from './connection-delegate';
import {ConnectionState} from './enum/connection-state';
import {ConnectionEvent} from './enum/connection-event';
import {CredentialRequest} from './credential-request';
import {CanvasGraphicsSinkFactory} from './canvas-graphics-sink-factory';
import {ClipboardHandler} from './clipboard-handler';
import {MouseButton} from './enum/mouse-button';
import {TouchState} from './enum/touch-state';
import {Logger} from './logger';
import {TelemetryService} from '../telemetry.service';
import {PrintingService} from '../printing.service';
import {PlatformInfo} from './platform-info';
import {CanvasGraphicsSink} from './canvas-graphics-sink';

declare const Module;

export class RdpConnection {
    id = null;
    correlationId: any = null;
    lastSuccessConnectionId = null;
    tenantId = null;
    eventLogUploadAddress = null;
    isExistingConnection = !1;
    hadSuccessfulConnection = !1;
    disconnectReason = null;
    isRail: any;
    events = new EventProvider();
    telemetry = new ConnectionTelemetry();
    diagnosticActivityinProgress = !1;
    delegate: ConnectionDelegate = new ConnectionDelegate();
    private queryInputController: any;
    private queryRailController: any;
    private queryAudioController: any;
    private queryClipboardController: any;
    private m: any;
    private p: any;
    private r = 0;
    private s = null;
    private n: any;
    private y = 0;
    private focus: boolean = !0;
    private t = !1;
    private u = [];
    private v = [];
    private x: any = null;
    private w = !1;
    clipboardHandler: ClipboardHandler;

    constructor(private connection: any,
                private logger: Logger,
                private audioContext: AudioContext,
                private remoteApplicationMode: boolean,
                private telemetryService: TelemetryService,
                private printingService: PrintingService,
                private platformInfo: PlatformInfo,
                private h: any,
                public remoteAppId: number) {
        this.p = ConnectionState.Initialized;
        this.isRail = remoteApplicationMode;
        connection.SetOnException(this.onException);
        connection.SetOnConnecting(this.onConnecting);
        connection.SetOnConnected(this.onConnected);
        connection.SetOnDisconnecting(this.onDisconnecting);
        connection.SetOnDisconnected(this.onDisconnected);
        connection.SetOnLoginCompleted(this.onLoginCompleted);
        connection.SetOnConnectionHealthStateChanged(this.onConnectionHealthStateChanged);
        connection.SetOnAuthChallenge(this.onAuthChallenge);
        connection.SetOnTrustChallenge(this.onTrustChallenge);
        connection.SetRequestGraphicsSinkDelegate(this.onRequestGraphicsSinkDelegate);
        connection.SetAssociateGraphicsSinkWithMonitorDelegate(this.onAssociateGraphicsSinkWithMonitorDelegate);
        connection.SetUnassociateGraphicsSinkWithMonitor(this.onUnassociateGraphicsSinkWithMonitor);
        printingService && (connection.SetPrintStartDelegate(this.onPrintStartDelegate), connection.SetPrintURLDelegate(this.onPrintURLDelegate));
        this.telemetry.timeLaunched = (new Date).getTime();
        window.addEventListener('unload', () => {
            h && !0 === this.diagnosticActivityinProgress && (h.triggerConnectionCheckPointEvent(this, 'OnLocalSessionEnding'), this.disconnectReason = {
                code: Module.DisconnectCode.UserInitiated
            }, this.triggerConnectionEndDiagnosticEvent(!0));
        });
    }

    private z = (): void => {
        this.r = 0;
        if (null !== this.s) {
            clearTimeout(this.s);
        }
        this.s = null;
    };

    private A = (cc: any): void => {
        let dd = {
            code: Module.DisconnectCode.UnknownError
        };
        this.logger.debug('[Connection] Cleaning up connection');
        this.p = ConnectionState.Disconnected;
        this.events && (cc || (dd = null), this.events.fireEventCallback(ConnectionEvent.ConnectionDestroyed, this, dd), this.events = new EventProvider()),
        this.n && this.n.cancel(), this.connection && (this.connection.delete(), this.connection = null), this.y = 0, this.z();
    };

    private onException = (a2: any): void => {
        this.telemetryService && this.telemetryService.onException(a2);
    };

    private onConnecting = (a2: any): void => {
        switch (a2) {
            case Module.ConnectionStatusUpdates.OpeningRemotePort:
                this.p = ConnectionState.OpeningRemotePort, this.logger.debug('[Connection] Connection state changed to: Opening remote port');
                break;
            case Module.ConnectionStatusUpdates.EstablishingSecureConnection:
                this.p = ConnectionState.EstablishingSecureConnection, this.logger.debug('[Connection] Connection state changed to: Establishing secure connection');
                break;
            case Module.ConnectionStatusUpdates.ConfiguringRemoteConnection:
                this.p = ConnectionState.ConfiguringRemoteConnection, this.logger.debug('[Connection] Connection state changed to: Configuring remote connection');
                break;
            case Module.ConnectionStatusUpdates.DetectingNetworkQuality:
                this.p = ConnectionState.DetectingNetworkQuality, this.logger.debug('[Connection] Connection state changed to: Detecting network quality');
                break;
            case Module.ConnectionStatusUpdates.SessionBrokerFindingDestination:
                this.p = ConnectionState.SessionBrokerFindingDestination, this.logger.debug('[Connection] Connection state changed to: Session broker finding session');
                break;
            case Module.ConnectionStatusUpdates.SessionBrokerLoadingDestination:
                this.p = ConnectionState.SessionBrokerLoadingDestination, this.logger.debug('[Connection] Connection state changed to: Session broker loading destination');
                break;
            case Module.ConnectionStatusUpdates.SessionBrokerBringingSessionOnline:
                this.p = ConnectionState.SessionBrokerBringingSessionOnline, this.logger.debug('[Connection] Connection state changed to: Session broker bringing session online');
                break;
            case Module.ConnectionStatusUpdates.SessionBrokerRedirectingToDestination:
                this.p = ConnectionState.SessionBrokerRedirectingToDestination,
                    this.logger.debug('[Connection] Connection state changed to: Session broker redirecting to destination');
                break;
            case Module.ConnectionStatusUpdates.VirtualMachineLoading:
                this.p = ConnectionState.VirtualMachineLoading, this.logger.debug('[Connection] Connection state changed to: Virtual machine loading');
                break;
            case Module.ConnectionStatusUpdates.VirtualMachineWaking:
                this.p = ConnectionState.VirtualMachineWaking, this.logger.debug('[Connection] Connection state changed to: Virtual machine waking');
                break;
            case Module.ConnectionStatusUpdates.VirtualMachineStarting:
                this.p = ConnectionState.VirtualMachineStarting, this.logger.debug('[Connection] Connection state changed to: Virtual machine starting');
                break;
            case Module.ConnectionStatusUpdates.VirtualMachineRetryingSessionMonitoring:
                this.p = ConnectionState.VirtualMachineRetryingSessionMonitoring,
                    this.logger.debug('[Connection] Connection state changed to: Virtual machine retrying session monitoring');
                break;
            case Module.ConnectionStatusUpdates.VirtualMachineStartingSessionMonitoring:
                this.p = ConnectionState.VirtualMachineStartingSessionMonitoring,
                    this.logger.debug('[Connection] Connection state changed to: Virtual machine starting session monitoring');
        }
        this.delegate.willConnect && this.delegate.willConnect(this.p, this.r),
        !this.hadSuccessfulConnection && this.delegate.displayDesktopBackground && this.delegate.displayDesktopBackground(!1);
    };

    private onConnected = (): void => {
        this.logger.debug('[Connection] Connected');
        this.disconnectReason = null;
        this.z();
        this.hadSuccessfulConnection = !0;
        this.lastSuccessConnectionId = this.correlationId && this.correlationId.ToString(),
            0 === this.telemetry.arcAttempts ? this.telemetry.timeConnected = (new Date).getTime() : (this.telemetry.arcSuccess++,
                this.telemetry.totalArcTime += (new Date).getTime() - this.telemetry.timeDisconnected),
            this.telemetry.timeDisconnected = null, this.telemetryService && this.telemetryService.onConnected(this),
        this.h && this.h.triggerConnectionCheckPointEvent(this, 'OnConnected'), this.p = ConnectionState.Connected, this.delegate.didConnect && this.delegate.didConnect();
    };

    private onDisconnecting = (): void => {
        this.logger.debug('[Connection] Disconnecting'), this.delegate.willDisconnect && this.delegate.willDisconnect();
    };

    private onDisconnected = (cc: any): void => {
        this.disconnectReason = cc, this.r < 20 && this.hadSuccessfulConnection && (cc && cc.code && cc.code.value === Module.DisconnectCode.ConnectionBroken.value ||
            cc && cc.code && cc.code.value === Module.DisconnectCode.GatewayProtocolError.value) ? (0 === this.r && (this.telemetry.timeDisconnected = (new Date).getTime()),
            this.telemetry.arcAttempts++, this.triggerConnectionEndDiagnosticEvent(null), this.r++, this.logger.debug('[Connection] Autoreconnecting... ' + this.r + ' / 20'),
            this.s = setTimeout((): void => {
                this.correlationId.IncrementConnectionID(), this.triggerConnectionStartDiagnosticEvent(), this.connection.Reconnect();
            }, 4e3)) : (this.logger.debug('[Connection] Disconnected'), this.telemetry.timeDisconnected = (new Date).getTime(),
        this.telemetryService && this.telemetryService.onDisconnected(this),
            this.triggerConnectionEndDiagnosticEvent(null), this.delegate.didDisconnect && this.delegate.didDisconnect(cc), this.A(!1));
    };

    private onLoginCompleted = (a2: any, cc: any, dd: any, ee: any): void => {
        this.logger.debug('[Connection] Login completed for server: ' + a2 + ':' + cc + ' username: ' + ee + '\\' + dd),
        this.delegate.loginCompleted && this.delegate.loginCompleted();
    };

    private onConnectionHealthStateChanged = (a2: any): void => {
        this.logger.debug('[Connection] Connection health state changed: ' + a2), this.delegate.connectionHealthStateChanged && this.delegate.connectionHealthStateChanged(a2);
    };

    private onAuthChallenge = (a2: any): void => {
        this.logger.debug('[Connection] Auth challenge'), a2.getAuthType() !== Module.IAuthCompletion_AuthType.UsernameAndPassword &&
        a2.getAuthType() !== Module.IAuthCompletion_AuthType.ClaimsToken && (this.logger.error('Only username & password authentication is currently supported.'),
        this.delegate.error && this.delegate.error('Only username & password authentication is currently supported.'), this.A(!1)),
            this.n = new CredentialRequest(a2), this.delegate.credentialsNeeded && this.delegate.credentialsNeeded(this.n, this);
    };

    private onTrustChallenge = (a2: any): void => {
        this.logger.debug('[Connection] Certificate trust challenge'), this.delegate.trustChallenge && this.delegate.trustChallenge(a2);
    };

    private onRequestGraphicsSinkDelegate = (graphicsSinkCompletion: any): void => {
        this.logger.debug('[Connection] Request graphics sink delegate');
        const graphicsSinkDescriptor = graphicsSinkCompletion.getSinkDescriptor();
        try {
            graphicsSinkDescriptor.sinkType !== Module.GraphicsSinkDescriptor_SinkType.Canvas && (this.logger.error('Only canvas graphics sinks are currently supported.'),
            this.delegate.error && this.delegate.error('Only canvas graphics sinks are currently supported.'));
            const dd = new CanvasGraphicsSinkFactory(this.logger);
            this.m = dd.createGraphicsSink(graphicsSinkDescriptor, this.focus), this.m.isFocused = this.focus, this.invalidateCanvas(), graphicsSinkCompletion.complete(this.m);
        } catch (aaa) {
            this.delegate.error && (this.logger.error(aaa.message), this.delegate.error && this.delegate.error(aaa.message)), graphicsSinkDescriptor.delete(), this.A(!0);
        } finally {
            graphicsSinkCompletion.delete();
        }
    };

    private onAssociateGraphicsSinkWithMonitorDelegate = (canvasGraphicsSink: CanvasGraphicsSink, monitorIndex: number, rectangle: any): void => {
        this.logger.debug('[Connection] Associating graphics sink with monitor');
        try {
            0 !== monitorIndex && (this.logger.error('Only one monitor is implemented'), this.delegate.error && this.delegate.error('Only one monitor is implemented.')),
            this.delegate.viewSizeChanged && this.delegate.viewSizeChanged(rectangle.width(), rectangle.height()), canvasGraphicsSink.SetViewport(rectangle);
        } catch (aaa) {
            this.logger.error(aaa.message), this.delegate.error && this.delegate.error(aaa.message), this.A(!0);
        }
    };

    private onUnassociateGraphicsSinkWithMonitor = (a2: any, cc: any): void => {
        this.logger.debug('[Connection] Unassociating graphics sink with monitor');
        try {
            0 !== cc && (this.logger.error('Only one monitor is implemented.'),
            this.delegate.error && this.delegate.error('Only one monitor is implemented')), a2.SetViewport(null);
        } catch (aaa) {
            this.logger.error(aaa.message), this.delegate.error && this.delegate.error(aaa.message), this.A(!0);
        }
    };

    private onRailActivated = (a: any): void => {
        let c;
        if (this.logger.log('[Connection] Rail activated message received: ' + a), this.t = a, a) {
            for (
                this.delegate.displayDesktopBackground && this.delegate.displayDesktopBackground(!0);
                this.u.length > 0;
            ) {
                c = this.u.pop(), this.logger.log('[Connection] Launching pending app: ' + c.applicationName),
                    this.launchApplication(c.applicationName, c.applicationArguments, c.applicationWorkingDirectory);
            }
        }
    };

    private onWindowCreated = (a: any): void => {
        let c;
        this.logger.log('[Connection] Window Created: ' + a);
        this.v.push(a), c = this.queryRailController.RequestApplicationId(a), this.events.fireEventCallback(ConnectionEvent.WindowCreated, this, a);
    };

    private onWindowDeleted = (a: any): void => {
        const b = this.v.indexOf(a);
        b > -1 && this.v.splice(b, 1), this.events.fireEventCallback(ConnectionEvent.WindowDeleted, a);
    };

    private onWindowIconChanged = (a: any, b: any, c: any): void => {
        this.events.fireEventCallback(ConnectionEvent.WindowIconChanged, a, b, c);
    };

    private onWindowActivated = (a: any): void => {
        this.events.fireEventCallback(ConnectionEvent.WindowActivated, a);
    };

    private onWindowTitleChanged = (a: any, b: any): void => {
        this.events.fireEventCallback(ConnectionEvent.WindowTitleChanged, a, b);
    };

    private onApplicationLaunched = (a: any, c: any): void => {
        this.logger.debug('[Connection] Server reports that ' + a + ' has been launched with result ' + c.value), this.events.fireEventCallback(ConnectionEvent.WindowLaunched,
            this.isExistingConnection, c.value), 0 !== c.value && this.delegate.error && this.delegate.error('Launching application failed with code ' + c.value);
    };

    private onWindowIdToApplicationIdChanged = (a: any, c: any): void => {
        this.logger.debug('[Connection] Window ID: ' + a + ' belongs to application id: ' + c), this.events.fireEventCallback(ConnectionEvent.WindowApplicationIdChanged, a, c);
    };

    private onGetAudioContext = (): any => {
        this.logger.debug('[Connection] onGetAudioContext');
        return this.audioContext;
    };

    private onZOrderChanged = (a: any): void => {
        this.logger.debug('[Connection] ZOrder changed');
        this.logger.debug(a);
        this.events.fireEventCallback(ConnectionEvent.WindowOrderChanged, a);
    };

    private onArcStateChanged = (a: any): void => {
        (this.w = !a) && setTimeout((): void => {
            this.events.fireEventCallback(ConnectionEvent.WindowsLoaded, this.id);
        }, 1500);
    };

    private onAudioStateChanged = (a: any): void => {
        this.logger.debug('[Connection] Audio state changed to ' + a);
    };

    private onShowDefaultMousePointer = (): void => {
        this.delegate.showDefaultMousePointer && this.delegate.showDefaultMousePointer(!0);
    };

    private onHideDefaultMousePointer = (): void => {
        this.delegate.showDefaultMousePointer && this.delegate.showDefaultMousePointer(!1);
    };

    private onPrintStartDelegate = (): void => {
        this.y++, this.printingService.onPrintStart && this.printingService.onPrintStart();
    };

    private onPrintURLDelegate = (a2: any): void => {
        this.y > 0 && this.y--, this.printingService.onPrintUrl && this.printingService.onPrintUrl(a2);
    };

    private ba = (a: any): any => {
        let b = 0;
        return a === MouseButton.Left ? b = Module.ButtonMask.Left : a === MouseButton.Middle ? b = Module.ButtonMask.Middle : a === MouseButton.Right ?
            b = Module.ButtonMask.Right : a === MouseButton.Button4 ? b = Module.ButtonMask.Button4 : a === MouseButton.Button5 && (b = Module.ButtonMask.Button5), b;
    };

    private ca = (a: any): any => {
        let b;
        return a === TouchState.Start ? b = Module.TouchState.Began : a === TouchState.Move ? b = Module.TouchState.Moved : a === TouchState.End ?
            b = Module.TouchState.Ended : a === TouchState.Cancel && (b = Module.TouchState.Cancelled), b;
    };

    private da = (a: any, b: any): any => {
        let c, d, e;
        for (c = new Module.TouchList, d = this.ca(a), e = 0; e < b.length; e++) {
            const f = b[e].adjustedTouchPos,
                g = new Module.HTML5TouchEvent(d, b[e].identifier, f.clientX, f.clientY);
            void 0 !== b[e].radiusX && void 0 !== b[e].radiusY && g.SetRadius(b[e].radiusX, b[e].radiusY), void 0 !== b[e].rotationAngle && g.SetOrientation(b[e].rotationAngle),
            void 0 !== b[e].force && g.SetForce(b[e].force), c.push_back(g), g.delete();
        }
        return c;
    };

    private ea = (b: any, c: any): void => {
        const d = new Module.MonitorList,
            e = new Module.Monitor,
            f = new Module.MonitorBounds;
        f.left = 0, f.top = 0, f.width = b, f.height = c, e.monitorBounds = f, e.fPrimary = !0, d.push_back(e), e.delete(), f.delete(),
        this.connection && this.connection.SetMonitorLayout(d), d.delete();
    };

    updateMonitorLayout(): void {
        let a = {
            width: 800,
            height: 448
        };
        this.delegate.monitorBounds ? a = this.delegate.monitorBounds() ||
            a : this.logger.error('[Connection] Monitorbounds delegate not found, falling back to default monitor bounds'),
            this.p === ConnectionState.Initialized ? (this.telemetry.initialWidth = a.width, this.telemetry.initialHeight = a.height,
            this.platformInfo && (this.telemetry.initialScale = this.platformInfo.getWindowScale())) : this.telemetry.resolutionChanged++, this.ea(a.width, a.height);
    }

    connect(): void {
        this.triggerConnectionStartDiagnosticEvent();
        this.logger.debug('[Connection] Connection.connect called');
        if (this.p === ConnectionState.Disconnected || this.p === ConnectionState.Initialized) {
            try {
                this.updateMonitorLayout();
                this.clipboardHandler = new ClipboardHandler(this, this.telemetryService, this.logger);
                this.queryInputController = this.connection.QueryInputController({
                    OnMousePointerPositionChanged: this.delegate.mousePositionChanged,
                    OnMousePointerShapeChanged: this.delegate.mousePointerChanged,
                    OnShowDefaultMousePointer: this.onShowDefaultMousePointer,
                    OnHideDefaultMousePointer: this.onHideDefaultMousePointer,
                    OnMultiTouchEnabled: this.delegate.multiTouchEnabled
                });
                if (this.remoteApplicationMode) {
                    this.queryRailController = this.connection.QueryRailController({
                        OnWindowCreated: this.onWindowCreated,
                        OnWindowDeleted: this.onWindowDeleted,
                        OnWindowIconChanged: this.onWindowIconChanged,
                        OnWindowTitleChanged: this.onWindowTitleChanged,
                        OnWindowActivated: this.onWindowActivated,
                        OnRailActivated: this.onRailActivated,
                        OnApplicationLaunched: this.onApplicationLaunched,
                        OnWindowIdToApplicationIdChanged: this.onWindowIdToApplicationIdChanged,
                        OnZOrderChanged: this.onZOrderChanged,
                        OnArcStateChanged: this.onArcStateChanged
                    });
                }
                this.queryClipboardController = null;
                this.queryClipboardController = this.connection.QueryClipboardController({
                    OnClipboardProtocolReady: (a1: any) => this.clipboardHandler.clipboardProtocolReady(a1),
                    OnClipboardOwnerChanged: (a1: any) => this.clipboardHandler.clipboardOwnerChanged(a1),
                    OnRemoteClipboardChanged: (a1: any) => this.clipboardHandler.remoteClipboardChanged(a1),
                    OnGetRemoteClipboardContentComplete: (a1: any, a2: any, a3: any) => this.clipboardHandler.getRemoteClipboardContentComplete(a1, a2, a3),
                    OnSetRemoteClipboardFormatsComplete: (a1: any) => this.clipboardHandler.setRemoteClipboardFormatsComplete(a1),
                    OnClipboardContentRequest: (a1: any) => this.clipboardHandler.onClipboardContentRequest(a1)
                });
                this.queryAudioController = null;
                this.queryAudioController = this.connection.QueryAudioController({
                    GetAudioContext: this.onGetAudioContext,
                    OnAudioStateChanged: this.onAudioStateChanged
                });
                this.clipboardHandler.setClipboardController(this.queryClipboardController);
                this.connection.Connect();
            } catch (a) {
                this.logger.error('[Connection] Connection connect throws following exception'), this.logger.error(a), this.disconnectReason = {
                    code: Module.DisconnectCode.UnknownError
                }, this.telemetry.timeDisconnected = (new Date).getTime(), this.telemetryService && this.telemetryService.onDisconnected(this),
                    this.triggerConnectionEndDiagnosticEvent(null),
                    this.events.fireEventCallback(ConnectionEvent.ConnectionConnectFailed, this, {
                        code: Module.DisconnectCode.UnknownError
                    });
            }
        } else {
            this.logger.log('[Connection] Not calling rdpConnection.Connect since connection is in state: ' + this.p);
        }
    }

    disconnect(c: any): void {
        0 === this.v.length || c ? this.connection.Disconnect() &&
            this.A(!1) : this.logger.log('[Connection] Connection has open windows, skipping disconnection until all windows closed');
    }

    dismiss(): void {
        this.p = ConnectionState.Dismissed;
    }

    mouseMove(a: any, b: any): void {
        this.queryInputController.SendMouseMove(a, b);
    }

    mouseDown(a: any, b: any, c: any): void {
        this.queryInputController.SendMouseButton(a, b, this.ba(c), Module.MouseEvent.Down);
    }

    mouseUp(a: any, b: any, c: any): void {
        this.queryInputController.SendMouseButton(a, b, this.ba(c), Module.MouseEvent.Up);
    }

    mouseWheel(a: any, b: any): void {
        this.queryInputController.SendMouseWheel(a, b);
    }

    keyDown(a: number): void {
        this.queryInputController.SendKeycode(a, Module.KeyEvent.Press);
    }

    keyUp(a: number): void {
        this.queryInputController.SendKeycode(a, Module.KeyEvent.Release);
    }

    keyUnicodeDown(a: number): void {
        this.queryInputController.SendUnicode(a, Module.KeyEvent.Press);
    }

    keyUnicodeUp(a: number): void {
        this.queryInputController.SendUnicode(a, Module.KeyEvent.Release);
    }

    sendTouchEvents(a: any, b: any): void {
        const c = this.da(a, b);
        this.queryInputController.SendTouchEvents(c), c.delete();
    }

    setRemoteClipboardsFormats(a: any): void {
        this.queryClipboardController.SetRemoteClipboardFormats(a);
    }

    getRemoteClipboardContent(a: any): void {
        this.queryClipboardController.GetRemoteClipboardContent(a);
    }

    isClipboardReady(): any {
        return this.clipboardHandler.clipboardReady;
    }

    setFocus(c: boolean): void {
        this.focus !== c && (this.focus = c, this.connection && (this.focus ? this.connection.EnableGraphicsUpdates() : this.connection.DisableGraphicsUpdates()),
            this.telemetry.sessionSwitchedCount++,
            this.m ? (this.m.isFocused = this.focus,
            this.focus && this.m.refreshWholeCanvas()) : this.logger.log('[Connection] Graphics sink not set yet, cannot set focus to: ' + this.focus));
    }

    getFocus(): any {
        return this.focus;
    }

    launchApplication(a: any, c: any, d: any): void {
        const e: any = {};
        this.t ? (this.queryRailController.LaunchApplication(a, c, d), this.delegate.displayDesktopBackground && this.delegate.displayDesktopBackground(!0),
            this.h && this.h.triggerConnectionCheckPointEvent(this, 'ClientApplicationLaunch', a)) :
            (this.logger.log('[Connection] Rail is not active adding application to pending list: ' + a), e.applicationName = a, e.applicationArguments = c,
                e.applicationWorkingDirectory = d, this.u.push(e));
    }

    maximizeWindow(a: any): void {
        this.queryRailController.WindowAction(a, Module.RailAction.Maximize);
    }

    minimizeWindow(a: any): void {
        this.queryRailController.WindowAction(a, Module.RailAction.Minimize);
    }

    restoreWindow(a: any): void {
        this.queryRailController.WindowAction(a, Module.RailAction.Restore);
    }

    closeWindow(a: any): void {
        this.queryRailController.WindowAction(a, Module.RailAction.Close);
    }

    activateWindow(a: any, b: any): void {
        b && a !== this.x && this.telemetry.appSwitchedCount++, this.queryRailController.WindowAction(a, Module.RailAction.Activate);
    }

    getState(): any {
        return this.p;
    }

    invalidateCanvas(): void {
        let a;
        this.delegate.canvasNeeded && (a = this.delegate.canvasNeeded(), this.m && (this.m.GetCanvas(!0) || this.m.setCanvas(a), this.m.refreshWholeCanvas()));
    }

    getWindowCount(): any {
        return this.v.length;
    }

    getPendingPrintJobCount(): any {
        return this.y;
    }

    isAutoReconnecting(): any {
        return this.r > 0;
    }

    triggerConnectionEndDiagnosticEvent(a: any): void {
        this.connection = a || !1, this.h && (this.h.triggerConnectionEndEvent(this, a), this.diagnosticActivityinProgress = !1);
    }

    triggerConnectionStartDiagnosticEvent(): void {
        this.h && (this.h.triggerConnectionStartEvent(this), this.diagnosticActivityinProgress = !0);
    }
}

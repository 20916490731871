import {Component, ElementRef, HostListener, Renderer2} from '@angular/core';
import {fromEvent} from 'rxjs';
import {FooterService} from './footer.service';
import {TerminalService} from '../../../kluh-manager/main/terminal/terminal.service';
import {DomainPathService} from '../../../kluh-manager/domain-path/domain-path.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {


    pressDragBar = false;
    maximized = false;
    terminalFooter: any;
    private headerSize = 48;
    private terminalFooterDefaultSize = 300;

    @HostListener('window:message', ['$event'])
    reconnect($event: MessageEvent): void {
        if (this.domainPathService.terminalURL.indexOf($event.origin) < -1) {
            return;
        }
        if ($event.data) {
            if (typeof $event.data.publicIp === 'string') {
                this.terminalService.openWebSSH($event.data);
            } else if ($event.data.logout) {
                this.footerService.closeTerminal();
            }
        }
    }


    constructor(public elRef: ElementRef,
                public render: Renderer2,
                public footerService: FooterService,
                public terminalService: TerminalService,
                private domainPathService: DomainPathService
    ) {
        fromEvent(document.body, 'mousemove').subscribe((e: any) => {
            this.terminalFooter = this.elRef.nativeElement.querySelector('.terminal-footer');
            const heightResize = document.body.offsetHeight - e.pageY;
            if (heightResize >= this.getOffsetHeight()) {
                this.pressDragBar = false;
            }
            if (this.pressDragBar) {
                this.resize(heightResize);
            }
        });
    }

    private getOffsetHeight(): number {
        return document.getElementById('container-1').offsetHeight;
    }

    resize(height: number): void {
        this.render.setStyle(this.terminalFooter, 'height', height + 'px');
    }

    onMousedown(): void {
        this.pressDragBar = true;
    }

    onMouseup(): void {
        this.pressDragBar = false;
    }

    onMinimize(): void {
        this.pressDragBar = false;
        this.maximized = false;
        if (this.headerSize === document.getElementById('terminal-footer').offsetHeight) {
            this.resize(this.terminalFooterDefaultSize);
        } else {
            this.resize(this.headerSize);
        }

    }

    onMaximize(): void {
        this.pressDragBar = false;
        if (this.getOffsetHeight() === document.getElementById('terminal-footer').offsetHeight) {
            this.resize(this.terminalFooterDefaultSize);
            this.maximized = false;
        } else {
            this.resize(this.getOffsetHeight());
            this.maximized = true;
        }
    }
}

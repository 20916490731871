<div class="product-detail-content-tab p-14">
    <div class="p-35" *ngFor="let screen of marketPlaceProductScreenList">
        <div class="pb-8 pt-8" [innerHTML]="screen.description"></div>
        <img src="{{imageFileService.getMarketPlaceProductScreenPictureUrl(screen.imageUUID)}}" class="img-screen" />
    </div>

    <div *ngIf="marketPlaceProductScreenList.length < 1"  fxLayout="row" fxLayoutAlign="center center" fxFill class="mt-30">
        <button type="button" mat-raised-button color="accent" (click)="onEdit()" style="margin-top: 21px" >Criar telas</button>
    </div>
</div>

import {AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {getArrayDateFromPeriod, Period} from '../../../helpers/kluh';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerGroup, ServerLog} from '../../../models';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ImageFileService} from '../../image-file/image-file-service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {MatTabGroup} from '@angular/material/tabs';
import {filter, startWith} from 'rxjs/operators';
import {ServerLogDaoService} from './server-log-dao.service';
import {StatisticsService} from '../statistics.service';


@Component({
    selector: 'app-server-log',
    templateUrl: './server-log.component.html',
    styleUrls: ['./server-log.component.scss']
})
export class ServerLogComponent extends ComponentCleaner implements AfterViewInit, OnChanges, OnDestroy {

    @Input()
    managerUserIdSearch: number;

    @Input()
    period: Period;

    @Input()
    isServiceUser: boolean;

    private customerGroup: CustomerGroup;
    currentPeriod = [];
    dataSource = new MatTableDataSource<ServerLog>();
    displayedColumns: string[] = [
        'avatar', 'managerUserId', 'serverHostname', 'adUserLogin', 'createdAt'
    ];

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    totalDocs = 0;
    pageSize = 50;
    pageIndex = 0;

    private SERVER_LOG_TAB_INDEX = 1;


    constructor(
        private serverLogDaoService: ServerLogDaoService,
        public imageFileService: ImageFileService,
        private customerGroupService: CustomerGroupService,
        public adminService: R2CloudAdminService,
        public statisticsService: StatisticsService,
        public tabGroup: MatTabGroup
    ) {
        super();


        this.currentPeriod = getArrayDateFromPeriod(Period.today);

        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (customerGroup && customerGroup.id) {
                this.customerGroup = customerGroup;
                this.unloadProject();
                this.loadData();
            } else {
                this.unloadProject();
            }
        }));

    }

    private tabSelected(): boolean {
        return this.tabGroup?.selectedIndex === this.SERVER_LOG_TAB_INDEX;
    }

    ngAfterViewInit(): void {
        this.tabGroup?.selectedTabChange.subscribe((value) => {
            if (this.tabSelected()) {
                this.changeTab();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changeTab();
    }

    unloadProject(): void {
        this.dataSource.data = null;
        this.totalDocs = 0;
        this.pageIndex = 0;
        this.managerUserIdSearch = 0;
    }

    private changeTab(): void {
        this.pageIndex = 0;
        this.paginator.pageIndex = this.pageIndex;
        this.currentPeriod = getArrayDateFromPeriod(this.period);
        this.loadData();
    }


    private getServerLog(customerGroupId: number, managerUserId: number, from: string, to: string): void {
        this.serverLogDaoService.findServerLogByCustomerGroupId(
            customerGroupId,
            managerUserId,
            from,
            to,
            this.pageIndex,
            this.pageSize,
        ).subscribe((stompSessionList) => {
            if (stompSessionList) {
                this.totalDocs = stompSessionList.totalElements;
                this.dataSource.data = stompSessionList.content;
            }
        });
    }

    loadData(): void {
        if (this.tabSelected()) {
            this.paginator?.page.pipe(startWith({
                pageIndex: this.paginator.pageIndex,
                pageSize: this.paginator.pageSize,
            }), filter(value => !!value)).subscribe((page: PageEvent) => {
                if (page && this.customerGroup.id) {
                    this.pageIndex = page.pageIndex;
                    this.getServerLog(
                        this.customerGroup.id,
                        this.managerUserIdSearch,
                        this.currentPeriod[0],
                        this.currentPeriod[1],
                    );
                }
            });
        }
    }
    ngOnDestroy(): void {
        // console.log('ngOnDestroy serverLog');
    }

}

<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Salvar senha do terminal service (TS)</span>
    </div>
  </mat-toolbar>
  <div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">

    <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
      <div style="margin-bottom: 15px">
        <h3>
            Digite a nova senha que será utilizada no seu usuário do domínio.
        </h3>
        <table style="margin-bottom: 10px;margin-top: 15px;font-size: 15px;">
          <tr style="height: 40px;">
            <td><b>Domínio</b></td>
            <td><b>:</b></td>
            <td>{{adDomain?.domainName}}</td>
          </tr>
          <tr>
            <td><b>Login</b></td>
            <td><b>:</b></td>
            <td>{{adUser.login}}</td>
          </tr>
        </table>
        <div>

        </div>
      </div>
      <div style="margin-bottom: 25px">
        <div class="mat-form-field-wrapper">
          <div class="mat-form-field-flex">
            <div class="mat-form-field-infix">
              <input style="border-bottom: solid 1px #ccc; font-size: 25px;width: 100%; padding-bottom: 5px;" matInput type="password"
                     placeholder="Digite sua senha" [formControl]="adUserPassword">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

      <div>
        <button type="submit" mat-raised-button color="accent" aria-label="Salvar"
                [disabled]="myForm.pristine || !myForm.valid">Salvar
        </button>
      </div>

      <div>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

      </div>
    </div>

  </div>
</form>

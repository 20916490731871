import {Component, OnDestroy} from '@angular/core';
import {ServicesStatus} from '../../../models';
import {StatusDaoService} from '../status-dao.service';

@Component({
    selector: 'app-k8s',
    templateUrl: './k8s.component.html',
    styleUrls: ['./k8s.component.scss', '../status.component.scss']
})
export class K8sComponent implements OnDestroy {

    servicesStatus: ServicesStatus;
    private clearInterval;

    constructor(private statusDaoService: StatusDaoService) {
        this.getServicesStatus();
        this.clearInterval = setInterval(() => {
            this.getServicesStatus();
        }, 60000);
    }

    ngOnDestroy(): void {
        if (this.clearInterval) {
            clearTimeout(this.clearInterval);
            this.clearInterval = null;
        }
    }

    private getServicesStatus(): void {
        this.statusDaoService.getServicesStatus().subscribe((servicesStatus) => {
            if (servicesStatus) {
                this.servicesStatus = servicesStatus;
            }
        });
    }

}

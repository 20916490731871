import {Subject} from 'rxjs/internal/Subject';
import {MatPaginatorIntl} from '@angular/material/paginator';


export class MatPaginatorTranslator extends MatPaginatorIntl {
    readonly changes: Subject<void> = new Subject<void>();
    firstPageLabel = 'Primeira página';
    itemsPerPageLabel = 'Itens por página';
    lastPageLabel = 'Última página';
    nextPageLabel = 'Próxima página';
    previousPageLabel = 'Página anterior';

    getRangeLabel = (page: number, pageSize: number, _length: number): string => {
        let length = _length;
        if (length === 0 || pageSize === 0) { return `0 de ${length}`; }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} de ${length}`;
    }


}


<div class="messages">
    <div class="message-row m-20">
        <div *ngFor="let comment of marketplaceRequestCommentList">
            <div *ngIf="comment.managerUserId !== user.managerUser.id">
                <div fxLayout="column" fxLayoutAlign="start start">
                    <div fxLayout="row" fxLayoutAlign="start start" class="contact">
                        <div>
                            <img class="avatar"
                                 src="{{imageFileService.getManagerUserPicture(adminService.getManagerUser(comment.managerUserId))}}?w=40&square=true"/>
                        </div>
                        <div class="bubble">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div>
                                        <span class="username " *ngIf="adminService.getManagerUser(comment.managerUserId) as managerUser; else notFound">
                                            <span *ngIf="managerUser?.displayName?.length > 1" class="username"> {{managerUser?.displayName}}</span>
                                            <span *ngIf="managerUser?.displayName?.length < 1" class="username"> {{managerUser?.email}} </span>
                                        </span>
                                        <ng-template #notFound class="username">
                                            <span class="warn-A700-fg">{{comment.email}}</span>
                                        </ng-template>
                                        <span class="time  ml-5 h6">{{comment?.createdAt  | date: 'dd/MM/yyyy HH:mm'}}</span>
                                    </div>
                                </div>
                                <div class="message  mt-5">
                                    {{comment?.comment}}
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between center"
                                     class="actions "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="comment.managerUserId === user.managerUser.id">
                <div fxLayout="column" fxLayoutAlign="start end">
                    <div fxLayout="row" fxLayoutAlign="start start" class="contact me">
                        <div>
                            <img class="avatar"
                                 src="{{imageFileService.getManagerUserPicture(user.managerUser)}}?w=40&square=true"/>
                        </div>
                        <div class="bubble">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div>
                                        <span *ngIf="user.managerUser?.displayName?.length > 1" class="username"> {{user.managerUser?.displayName}} </span>
                                        <span *ngIf="user.managerUser?.displayName?.length < 1" class="username"> {{user.managerUser?.email}} </span>
                                        <span
                                            class="time  ml-5 h6">{{comment.createdAt  | date: 'dd/MM/yyyy HH:mm'}}</span>
                                    </div>
                                </div>
                                <div class="message  mt-5">
                                    {{comment.comment}}
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between center"
                                     class="actions "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    public message: string;
    public disableCancel: boolean;
    public disableConfirmField = true;
    public icon: string;
    public confirmFieldValue: string;
    public confirmButtonValue: string;
    public confirmFieldError: string;
    public cancelButtonValue: string;
    myForm: FormGroup;


    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
        this.message = data.message;
        this.disableCancel = data.disableCancel;
        this.icon = data.icon;

        if (data.confirmButtonValue) {
            this.confirmButtonValue = data.confirmButtonValue;
        } else {
            this.confirmButtonValue = 'Sim';
        }
        if (!this.disableCancel) {
            if (data.cancelButtonValue) {
                this.cancelButtonValue = data.cancelButtonValue;
            } else {
                this.cancelButtonValue = 'Não';
            }
        }

        if (!this.icon) {
            this.icon = 'help_outline';
        }


        const confirmField = this.fb.control('');
        this.myForm = this.fb.group({
            confirmField: confirmField
        });


        if (data.confirmFieldValue) {
            this.disableConfirmField = false;
            this.confirmFieldValue = data.confirmFieldValue;
        }
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.confirmFieldError = '';
        if (!this.disableConfirmField) {
            const confirmFieldValue: string = this.myForm.get('confirmField').value;
            if (this.confirmFieldValue === confirmFieldValue) {
                this.dialogRef.close(true);
            } else {
                this.confirmFieldError = 'Confirmação errada';
            }
        } else {
            this.dialogRef.close(true);
        }


    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

}

export function createErrorModal(
    matDialog: MatDialog,
    message: string
): Observable<boolean> {
    return matDialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
            message: message,
            disableCancel: true,
            icon: 'error_outline',
            confirmButtonValue: 'Ok'
        }
    }).afterClosed();
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {ImageFileDaoService} from './image-file-dao-service';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
    ],
    declarations: [],
    providers: [ImageFileDaoService]
})
export class ImageFileModule {
}

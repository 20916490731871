import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-sub-project-dialog',
    templateUrl: './sub-project-dialog.component.html',
    styleUrls: ['./sub-project-dialog.component.scss']
})
export class SubProjectDialogComponent {

    constructor(private dialogRef: MatDialogRef<SubProjectDialogComponent>) {
    }



    onPrivate(): void {
        this.dialogRef.close('private');
    }

    onShared(): void {
        this.dialogRef.close('shared');
    }

    onNewShared(): void {
        this.dialogRef.close('new-shared');
    }

    close(): void {
        this.dialogRef.close();
    }

}

import {Component} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Model} from '../../../../models-ai';
import {MatDialogRef} from '@angular/material/dialog';
import {ModelDaoService} from '../model.dao.service';
import {convertToFormGroup} from '../../../../helpers/kluh';

@Component({
    selector: 'app-model-create',
    templateUrl: './model-create.component.html',
    styleUrls: ['./model-create.component.scss']
})
export class ModelCreateComponent extends ComponentCleaner {
    myForm: FormGroup;
    model: Model;

    constructor(
        public dialogRef: MatDialogRef<ModelCreateComponent>,
        private modelDaoService: ModelDaoService,
        private fb: FormBuilder,
    ) {
        super();
        this.model = this.modelDaoService.init();
        this.myForm = this.fb.group(convertToFormGroup(this.model));
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.model = this.myForm.value;
        this.modelDaoService.create(this.model).subscribe(modelFromDao => {
            this.dialogRef.close({
                data: modelFromDao,
                operation: 'CREATE'
            });
        });
    }
}

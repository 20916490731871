<mat-card class="card" appDragDropFile (fileDropped)="fileDrop($event)">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                Versões de Agente
            </div>
            <div>
                <button type="button" mat-raised-button (click)="updateAllServerAgent()">Atualizar em todos</button>
            </div>
            <div style="height: 36px;">
                <input (change)="onFileChange($event)" name="file"
                       id="serverAgentToUpload" accept=".exe" type="file" style="display:none;">
                <button type="button" mat-raised-button color="accent" onclick="document.getElementById('serverAgentToUpload').click()">
                    <mat-icon>cloud_upload</mat-icon>
                </button>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let serverAgentFile of serverPluginService.serverAgentFiles; let i = index"
             class="row-item" [ngClass]="{'mt-8': i > 0, 'even': i % 2 === 0, 'odd': i % 2 != 0}">
            <div>
                {{serverAgentFile.version}}
            </div>
            <div>
                <button type="button" mat-mini-fab color="accent" (click)="onDownload(serverAgentFile)">
                    <mat-icon>
                        cloud_download
                    </mat-icon>
                </button>
                <button type="button" class="ml-10" mat-mini-fab color="warn" (click)="onDelete(serverAgentFile)">
                    <mat-icon>
                        delete
                    </mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
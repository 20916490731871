<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div *ngIf="baseServer">
                    <fa-icon class="s-20 mr-8  windows-icon" [icon]="faWindows"
                             *ngIf="serverType === ServerType.WINDOWS"
                             style="color: #2E8DEF"></fa-icon>
                    <fa-icon class="s-20 mr-8  windows-icon" [icon]="faLinux"
                             *ngIf="serverType === ServerType.LINUX"
                             style="color: #1E2129"></fa-icon>
                    <b>{{baseServer.hostname}}</b>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <div *ngIf="baseServer">
                <p><small>connectionFQDN: </small><br>
                    <span class="accent-A700-fg">{{baseServer.connectionFQDN}}</span></p>

<!--                <p><small>gatewayFQDN: </small><br>-->
<!--                    <span class="accent-A700-fg">{{baseServer.gatewayFQDN}}</span></p>-->

                <p><small>connectionPort: </small><br>
                    <span class="accent-A700-fg">{{baseServer.connectionPort}}</span></p>

                <p><small>publicIp: </small><br>
                    <span class="accent-A700-fg">{{baseServer.publicIp}}</span></p>

                <p><small>privateIp: </small><br>
                    <span class="accent-A700-fg">{{baseServer.privateIp}}</span></p>

                <hr *ngIf="baseServer.comment && baseServer.comment.length > 3">
                <p>{{baseServer.comment}}</p>
            </div>
        </div>
    </mat-expansion-panel>

    <app-server-cpu-men-hd
        [connectionStatus$]="webStompService.connectionStatus$"
        [baseServer]="baseServer"
        [serverType]="serverType">
    </app-server-cpu-men-hd>

    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div *ngIf="serverUptime">
                    <div class="pt-3" *ngIf="checksIfTheServerIsOnline(serverUptime);else offline">
                        <span class="primary-200-fg">Ativo : </span>
                        <span class="accent-A400-fg">
                            <mat-icon class="arrow-inner arrow-down-up" title="Online">
                                arrow_upward
                            </mat-icon>
                            <span>
                                 {{serverUptime.value | secondsToStringInfoTime}}
                            </span>
                        </span>
                    </div>
                    <ng-template #offline>
                        <div>
                            <span class="primary-200-fg">Inativo: </span>
                            <span class="warn-A700-fg">
                                <mat-icon class="arrow-inner arrow-down-up" title="Offline">
                                    arrow_downward
                                </mat-icon>
                                <span>
                                  {{serverUptime.createdAt | timeDiff}}
                                </span>
                            </span>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="!serverUptime" class="primary-100-fg">
                    {{loadingOverViewText}}
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <!--
            <div><b>Data da última coleta de dados:</b></div>
            <div>{{serverUptime?.createdAt | date: 'dd/MM/yyyy HH:mm' }}</div>

            <hr/>

            <h2>Agendamento:</h2>
            <table class="width-100-percent">
                <thead>
                <tr>
                    <th class="day-of-week" style="width: 40%"></th>
                    <th class="tit col-start">Liga</th>
                    <th class="tit col-stop">Desliga</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>SEG</td>
                    <td>6:00</td>
                    <td>22:00</td>
                </tr>
                <tr>
                    <td>TER</td>
                    <td>6:00</td>
                    <td>22:00</td>
                </tr>
                <tr>
                    <td>QUA</td>
                    <td>6:00</td>
                    <td>22:00</td>
                </tr>
                <tr>
                    <td>QUI</td>
                    <td>6:00</td>
                    <td>22:00</td>
                </tr>
                <tr>
                    <td>SEX</td>
                    <td>6:00</td>
                    <td>22:00</td>
                </tr>
                <tr>
                    <td>SAB</td>
                    <td>6:00</td>
                    <td>20:00</td>
                </tr>
                <tr>
                    <td>DOM</td>
                    <td>6:00</td>
                    <td>18:00</td>
                </tr>
                </tbody>
            </table>
            -->
        </div>
    </mat-expansion-panel>
</mat-accordion>

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {MarketplaceProduct} from '../../../models';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable()
export class MarketplaceProductDaoService extends BaseDao<MarketplaceProduct> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'marketplace-product');
    }

    findAllByIdList(marketplaceProductIds: number[]): Observable<MarketplaceProduct[]> {
        return this.http.get<MarketplaceProduct[]>(this.url + '/find-all-by-id-list?ids=' + marketplaceProductIds);
    }
}

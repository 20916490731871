export class ClipTipHelper {
    constructor(private a: any, private b: any) {
        
    }

    showCliphelpTip(c: any, d: any, e: any): void {
        try {
            this.a.getSuppressClipHelpForConnection(d) || !c.isChrome && !c.isIE || e && e(d), this.a.setSuppressClipHelpForConnection(d, !0);
        } catch (a) {
            this.b.error('[CliptipHelper] Unable to show the helptip template ' + a.message);
        }
    }
    
    setDontShowHelpTipValue(b: any): void {
        this.a.setSuppressClipHelpGlobally(b);
    }
}

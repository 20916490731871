import {RdpConnection} from './rdp-connection';

declare const Module;

export class ClipboardHandler {
    private d: any;
    clipboardReady = !1;
    clipboardOwner = Module.ClipboardOwner.Unknown;

    constructor(private a: RdpConnection, private b: any, private c: any) {

    }

    setClipboardController(a: any): void {
        this.d = a;
    }

    clipboardProtocolReady(a: any): void {
        this.clipboardReady = a;
    }

    clipboardOwnerChanged(a: any): void {
        this.clipboardOwner = a;
    }

    onClipboardContentRequest(b: any): void {
        this.a.delegate.clipboardContentRequest(b);
    }

    setRemoteClipboardFormats(a: any = null): void {
        if (a) {
            this.d.SetRemoteClipboardFormats(a);
        } else {
            this.a.telemetry.clipboardUsed = !0;
            const b = new Module.ClipboardFormatList;
            b.push_back(new Module.ClipboardFormat(Module.ClipboardFormatType.Text)), b.push_back(new Module.ClipboardFormat(Module.ClipboardFormatType.UnicodeText)),
                this.d.SetRemoteClipboardFormats(b), b.delete();
        }
    }

    getRemoteClipboardContent(b: any): void {
        this.a.telemetry.clipboardUsed = !0, this.d.GetRemoteClipboardContent(b);
    }

    getRemoteClipboardContentComplete(b: any, d: any, e: any): void {
        if (b.value === Module.ClipboardResponse.ResponseOk.value) {
            switch (d.value) {
                case Module.ClipboardFormatType.Text.value:
                    break;
                case Module.ClipboardFormatType.UnicodeText.value:
                    this.a.delegate.getRemoteClipboardContent(d, e);
                    break;
                default:
                    this.c.debug('[Connection] Unknown clipboard format received!');
            }
        } else {
            this.c.debug('[Connection] Clipboard getClipboardContent result invalid.');
        }
    }

    remoteClipboardChanged(a: any): void {
        for (let b = 0; b < a.size(); b++) {
            const c = a.get(b);
            switch (c.Type && c.Type.value) {
                case Module.ClipboardFormatType.UnicodeText.value:
                    this.getRemoteClipboardContent(Module.ClipboardFormatType.UnicodeText);
                    break;
                case Module.ClipboardFormatType.Text.value:
                    this.getRemoteClipboardContent(Module.ClipboardFormatType.Text);
            }
        }
        a.delete();
    }

    setRemoteClipboardFormatsComplete(b: any): void {
        this.a.delegate.setRemoteClipboardFormatsComplete(b);
    }

}

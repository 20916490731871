import {Injectable} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {detectBrowserVersion, osDetectByUserAgent} from '../../utils/utils-kluh';

@Injectable({
    providedIn: 'root'
})
export class StatisticsService extends ComponentCleaner {
    constructor() {
        super();
    }

    osDetectByUserAgent(agent: string): string {
        return osDetectByUserAgent(agent);
    }

    detectBrowserVersion(agent: string): string {
        return detectBrowserVersion(agent);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {ManagerUserAccessPeriodService} from '../manager-user-access-period/manager-user-access-period.service';
import {MyPrintsComponent} from './my-prints.component';
import {PdfPrintDaoService} from './pdf-print-dao.service';
import {PdfPrintService} from './pdf-print.service';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';

const routes = [
    {
        path: 'my-prints',
        component: MyPrintsComponent,
        canActivate: [AuthService]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        KluhSharedModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSelectModule,
        MatGridListModule
    ],
    providers: [
        ManagerUserAccessPeriodService, PdfPrintDaoService, PdfPrintService
    ],
    entryComponents: [],
    declarations: [MyPrintsComponent],

})
export class MyPrintsModule {
}

import {Injectable} from '@angular/core';
import {ProcessorUsageDaoService} from './processor-usage-dao.service';
import {ChartDataService} from '../chart-data.service';
import {MonitoringStompService} from '../../websocket-stomp/monitoring-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class ProcessorUsageService extends ChartDataService<ProcessorUsageDaoService> {

    constructor(
                webStompService: MonitoringStompService,
                service: ProcessorUsageDaoService,
               ) {
        super(
    'ProcessorUsage',
            service,
            webStompService
        );
    }
}

import {Injectable} from '@angular/core';
import {LoggerService} from './logger.service';
import {HttpClient} from '@angular/common/http';
import {PrintHandler} from './ts/printHandler';


@Injectable({
    providedIn: 'root'
})
export class PrintingService extends PrintHandler {

    constructor(logger: LoggerService, http: HttpClient) {
        super(logger, http);
    }

}

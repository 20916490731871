import {Injectable, Injector, OnDestroy} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase';
import {User} from 'firebase';
import {from, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {clientVersion} from '../../client-version';
import {HttpClient} from '@angular/common/http';
import {v4 as uuid} from 'uuid';
import {UserAuthorityDaoService} from './main/user-authority/user-authority-dao.service';
import {FirebaseUserDetails} from './models';
import UserCredential = firebase.auth.UserCredential;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import IdTokenResult = firebase.auth.IdTokenResult;

@Injectable({
    providedIn: 'root'
})
export class FirebaseService implements OnDestroy {
    private tokenRemainingMillis = 0;
    private readonly idToken$: ReplaySubject<string>;
    private isLoggedIn$ = new ReplaySubject<boolean>(1);
    private idTokenResult: IdTokenResult;
    private user: User;
    private onDestroy$ = new Subject();
    private interval: NodeJS.Timer;
    private _userAuthority: UserAuthorityDaoService;

    private get userAuthority(): UserAuthorityDaoService {
        if (!this._userAuthority) {
            this._userAuthority = this.injector.get<UserAuthorityDaoService>(UserAuthorityDaoService);
        }
        return this._userAuthority;
    }

    constructor(
        private afAuth: AngularFireAuth,
        private httpAngular: HttpClient,
        private injector: Injector,
    ) {
        this.idToken$ = new ReplaySubject<string>(1);
        afAuth.app.then((app) => app.auth().useDeviceLanguage());
        this.isLoggedIn().pipe(switchMap(() => {
            return this.refreshToken();
        })).subscribe();
        this.afAuth.authState.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe((value: User | null) => {
            // console.trace('authState called', value);
            if (value) {
                this.user = value;
                this.isLoggedIn$.next(true);
            } else {
                this.user = null;
                this.isLoggedIn$.next(false);
            }
        });
        this.interval = setInterval(() => {
            this.refreshToken().subscribe();
        }, 10000);
    }


    public loginApi(me: FirebaseUserDetails | null): Observable<User> {
        if (!this.user || !this.user.email) {
            // console.trace('this.user is invalid');
            return of(null);
        }
        if (!me || me.email !== this.user.email) {
            // console.trace('me is invalid');
            return from(this.user.getIdToken(true)).pipe(switchMap((token) => {
                let webClientId: string = localStorage.getItem('_webClientId');
                if (!webClientId) {
                    webClientId = uuid().replace(/-/g, '');
                    localStorage.setItem('_webClientId', webClientId);
                }
                const body = {
                    token: token,
                    clientId: webClientId,
                    clientVersion: clientVersion
                };
                return this.httpAngular.post('/api/firebase/login', body);
            }), map(() => this.user));
        }
        // console.trace('user is already logged');
        return of(this.user);
    }

    public invalidateAndRefreshToken(): Observable<void> {
        return this.getIdTokenFromUser(true);
    }

    private refreshToken(): Observable<void> {
        if (this.user) {
            if (this.idTokenResult) {
                const expirationTimeStr = this.idTokenResult.expirationTime;
                const expirationTime = moment(expirationTimeStr);
                const now = moment();
                if (now.add(5, 'minutes').isAfter(expirationTime)) {
                    return this.getIdTokenFromUser(true);
                }
            } else {
                return this.getIdTokenFromUser(false);
            }
        } else if (this.idTokenResult) {
            this.idTokenResult = null;
            this.idToken$.next(null);
        }
        return of(null);
    }

    private getIdTokenFromUser(refresh: boolean): Observable<void> {
        return from(this.user.getIdTokenResult(refresh)).pipe(take(1), switchMap((idTokenResult) => {
            this.idTokenResult = idTokenResult;
            this.idToken$.next(this.idTokenResult.token);
            return of(null);
        }));
    }

    isLoggedIn(): Observable<boolean> {
        return this.isLoggedIn$.asObservable();
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.idToken$.complete();
        this.isLoggedIn$.complete();
    }

    signInWithPopup(provider: GoogleAuthProvider): Observable<UserCredential> {
        return from(this.afAuth.signInWithPopup(provider));
    }

    setPersistence(persistence: firebase.auth.Auth.Persistence): Observable<void> {
        return from(this.afAuth.setPersistence(persistence));
    }

    signInWithEmailAndPassword(email: string, password: string): Observable<UserCredential> {
        return from(this.afAuth.signInWithEmailAndPassword(email, password));
    }

    auth(): Observable<firebase.auth.Auth> {
        return from(this.afAuth.app).pipe(map((app) => app.auth()));
    }

    signOut(): Observable<void> {
        // console.trace('signOut called');
        return from(this.afAuth.signOut());
    }

    private getTokenRemainingMillis(): number {
        return this.tokenRemainingMillis;

    }

    getToken(): Observable<string> {
        return this.idToken$.asObservable().pipe(filter(o => !!o));
    }

    updateProfile(name: string, picture: string): void {
        this.afAuth.currentUser.then((user) => user.updateProfile({
            displayName: name,
            photoURL: picture
        }).then(() => {
            console.debug('DisplayName updated: ' + name);
        }).catch(err => console.debug(err)));
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RemoteAppListComponent} from './remote-app-list/remote-app-list.component';
import {AuthService} from '../../auth.service';
import {RemoteAppDaoService} from './remote-app-dao.service';
import {RouterModule} from '@angular/router';
import {RemoteAppCreateEditComponent} from './remote-app-create-edit/remote-app-create-edit.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {JaversModule} from '../javers/javers.module';
import {RemoteAppFileBrowserComponent} from './remote-app-file-browser/remote-app-file-browser.component';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RemoteAppServiceService} from './RemoteAppServiceService';

const routes = [
    {
        path: 'remote-app',
        component: RemoteAppListComponent,
        canActivate: [AuthService],
        data: {permissions: ['RemoteApp.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        JaversModule,
        MatTableModule,
        NgBytesPipeModule,
        FontAwesomeModule,
        MatMenuModule,
        MatTooltipModule
    ],
    declarations: [RemoteAppListComponent, RemoteAppCreateEditComponent, RemoteAppFileBrowserComponent],
    providers: [
        RemoteAppDaoService, RemoteAppServiceService
    ],
    entryComponents: [
        RemoteAppCreateEditComponent, RemoteAppFileBrowserComponent
    ]
})
export class RemoteAppModule {
}

import {Injectable} from '@angular/core';

import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {BaseMonitoringDao} from '../../../../../base-monitoring-dao';
import {Observable} from 'rxjs/internal/Observable';
import {HdMonitoringConfiguration, HdUsage} from '../../../../../models-monitoring';
import {ServerType} from '../../../../../models';
import {R2CloudHttpMonitoringService} from '../../../../../r2-cloud-http-monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class HdUsageDaoService extends BaseMonitoringDao<HdUsage> {
    constructor(http: R2CloudHttpMonitoringService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'hd-usage');
    }

    findAllByServerIdAndServerType(serverId: number, serverType: ServerType): Observable<HdMonitoringConfiguration[]> {
        return this.http.get<HdMonitoringConfiguration[]>(this.url + '/find-all-by-server-id-and-server-type/' + serverType + '/' + serverId);
    }
}

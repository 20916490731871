import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UploadFileDaoService} from '../upload-file-dao.service';
import {FileStatus, LocalUploadFile} from '../../digital-certificate/digital-certificate-dao.service';

@Component({
    selector: 'app-send-files',
    templateUrl: './send-files.component.html',
    styleUrls: ['./send-files.component.scss']
})
export class SendFilesComponent {

    FileStatus = FileStatus;
    localUploadFiles: LocalUploadFile[] = [];
    sendingFiles = false;
    finished = false;
    subProjectId = 0;
    desktopServerId = 0;

    forbiddenExtensions = [
        '.exe', '.bat', '.vbs', '.js', '.wsf', '.msi', '.jar',
        '.scr', '.reg', '.dll', '.pif', '.com', '.sys', '.cmd',
        '.docm', '.xlsm', '.pptm', '.ade', '.adp', '.asx', '.chm',
        '.lnk', '.msp', '.mst', '.pif', '.reg', '.vbe', '.ws', '.wsc',
        '.wsf', '.wsh'];
    fileSizeLimit = 20 * (1024 * 1024);


    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<SendFilesComponent>,
        private uploadFileDaoService: UploadFileDaoService,
    ) {
        this.desktopServerId = this.data.desktopServerId;
        this.subProjectId = this.data.subProjectId;
        this.localUploadFiles = this.fileListToLocalUploadFileList(this.data.files, this.forbiddenExtensions);
    }

    private fileListToLocalUploadFileList(files: FileList, forbiddenExtensions: string[]): LocalUploadFile[] {
        const localUploadFiles: LocalUploadFile[] = [];
        Array.from(files).forEach((file, i) => {
            const localUploadFile: LocalUploadFile = {
                name: file.name,
                size: file.size,
                position: i,
                blocked: this.hasRejectExtensions(file.name, forbiddenExtensions) || this.fileSizeLimit < file.size,
                status: null
            };
            localUploadFiles.push(localUploadFile);
        });
        return localUploadFiles;
    }

    hasBlockedFile(localUploadFiles: LocalUploadFile[]): boolean {
        return localUploadFiles.findIndex((luf) => luf.blocked === true) > -1;
    }

    private hasRejectExtensions(fileName: string, forbiddenExtensions: string[]): boolean {
        const extension = fileName.toLowerCase().split('.').pop();
        return forbiddenExtensions.includes(`.${extension}`);
    }

    onCancel(finished: boolean): void {
        this.dialogRef.close(finished);
    }

    delete(position: number): void {
        const index = this.localUploadFiles.findIndex((o) => o.position === position);
        if (index > -1) {
            this.localUploadFiles.splice(index, 1);
        }
    }

    onSending(): void {
        const customFileList = new DataTransfer();
        const files: FileList = this.data.files;
        this.sendingFiles = true;
        this.localUploadFiles.forEach((localUploadFile) => {
            localUploadFile.status = FileStatus.TO_SEND;
        });
        const uploadFilesAsync = async () => {
            for (let i = 0; i < this.localUploadFiles.length; i++) {
                const localUploadFile = this.localUploadFiles[i];
                if (!localUploadFile.blocked) {
                    localUploadFile.status = FileStatus.SENDING;
                    const currentFile = files.item(localUploadFile.position);
                    await this.uploadFileDaoService.uploadFile(currentFile, this.subProjectId, this.desktopServerId).toPromise();
                    customFileList.items.add(currentFile);
                    localUploadFile.status = FileStatus.SENT;
                }
            }
        };
        uploadFilesAsync().then(() => {
            this.finished = true;
            this.dialogRef.close(this.finished);
        });
    }

}


<form *ngIf="myForm" [formGroup]="myForm"
      id="role-customer-group-manager-user-edit-form"
      (submit)="myForm.valid && myForm.dirty && (!currentRoleId || currentRoleId !== myForm.get('roleIdForm').value) && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Papel do usuário</span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">

            <ng-container>
                <div>
                    <div fxLayout="column" fxLayoutAlign="none stretch">
                        <mat-form-field *ngIf="!currentRoleId && !managerUser">
                            <input matInput formControlName="emailForm" placeholder="E-mail" required>
                            <mat-error>
                                Email inválido.
                            </mat-error>
                        </mat-form-field>

                        <h2 *ngIf="managerUser && managerUser.email" style="margin-bottom: 25px">
                            {{managerUser.email}}
                        </h2>

                        <!--<mat-form-field [hidden]="myForm.get('managerUserForm').disabled" formGroupName="managerUserForm">-->
                            <!--<mat-select placeholder="Autorização" formControlName="authorityIds" multiple required>-->
                                <!--<mat-option *ngFor="let authority of allAuthorities" [value]="authority.id">-->
                                    <!--{{authority.authority.replace("ROLE_", "")}}-->
                                <!--</mat-option>-->
                            <!--</mat-select>-->
                            <!--<mat-error>-->
                                <!--{{myForm.get('managerUserForm').get('authorityIds').errors | error}}-->
                            <!--</mat-error>-->
                        <!--</mat-form-field>-->

                    </div>

                    <div  formGroupName="managerUserForm" fxLayout="row" fxLayoutAlign="space-between end" [hidden]="myForm.get('managerUserForm').disabled">
                        <div>
                            <mat-form-field *ngIf="adminService.customers && adminService.customers.length > 0" style="width: 290px;">
                                <mat-select placeholder="Filiais" formControlName="customerId" required >
                                    <mat-option *ngFor="let customer of adminService.customers" [value]="customer.id">
                                        {{customer.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{myForm.get('managerUserForm').get('customerId').errors | error}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div matTooltip="Criar Customer" style="margin-bottom: 16px;">
                            <button type="button" mat-raised-button color="accent" aria-label="Criar Filial"
                                    (click)="onCreateCustomer()">Criar Filial
                            </button>

                        </div>

                    </div>
                </div>

                <mat-form-field>
                    <mat-select placeholder="Papel"
                                id="role-customer-group-manager-user-edit-select-role"
                                formControlName="roleIdForm" required>
                        <mat-option *ngFor="let role of adminService.roles" [value]="role.id" id="{{role.name}}" >
                            {{role.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{myForm.errors | error}}
                    </mat-error>
                </mat-form-field>


            </ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <div>
                <button type="submit" mat-raised-button color="accent" aria-label="Salvar"  [disabled]="myForm.pristine || !myForm.valid || currentRoleId === myForm.get('roleIdForm').value">
                    <span *ngIf="currentRoleId;else newRoleSubProjectManagerUser">Salvar</span>
                    <ng-template #newRoleSubProjectManagerUser>Criar</ng-template>
                </button>
            </div>
            <div>
                <button type="button" mat-raised-button color="warn" (click)="onRemove()" *ngIf="currentRoleId"
                >Excluir</button>
            </div>
            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
            </div>
        </div>
    </div>
</form>


import {ClipTipHelper} from './clip-tip-helper';
import {KbHelper} from './kb-helper';
import * as _ from 'lodash';
import {EventProvider} from './event-provider';
import {SessionViewModelDelegate} from './session-view-model-delegate';
import {SessionEvent} from './enum/session-event';
import {ConnectionState} from './enum/connection-state';
import {BookmarkType} from './enum/bookmark-type';
import {SessionState} from './enum/session-state';
import {ConnectionPropertiesRejectedReason} from './enum/connection-properties-rejected-reason';
import {MouseButton} from './enum/mouse-button';
import {KeyCodes} from './enum/key-codes';
import {ASCIICodes} from './enum/ascii-codes';
import {SessionProviderEvent} from './enum/session-provider-event';
import {TouchState} from './enum/touch-state';
import {Logger} from './logger';
import {SessionStore} from './session-store';
import {RdpClientUIPropertiesService} from '../rdp-client-uiproperties.service';
import {PlatformInfo} from './platform-info';
import {UserAuthInfoService} from '../user-auth-info.service';
import {Session} from './session';
import {RdpConnection} from './rdp-connection';
import {SessionStateStoreService} from '../session-state-store.service';
import {SessionProviderService} from '../session-provider.service';
import {CustomKeyboardEvent} from '../session-view/clip-text-box/CustomKeyboardEvent';

declare const Module;

export class SessionViewModel {

    constructor(protected sessionProvider: SessionProviderService,
                private sessionStore: SessionStore,
                private rdpClientUIPropertiesService: RdpClientUIPropertiesService,
                private logger: Logger,
                public platformInfo: PlatformInfo,
                private canvasCreateCallback: () => HTMLCanvasElement,
                private userAuthInfoService: UserAuthInfoService,
                private sessionStateStore: SessionStateStoreService) {
        this.clipTipHelper = new ClipTipHelper(rdpClientUIPropertiesService, logger);
        this.kbHelper = new KbHelper(platformInfo, this.v);
        this.throttle = _.throttle(() => {
            setTimeout(() => {
            });
        }, 100);
        this.events = new EventProvider();
        this.delegate = new SessionViewModelDelegate();
    }

    public session: Session;
    private executeCopyPending = false;
    private n: any = null;
    private copyPending = false;
    private p: any = [100, 250, 500];
    private q: any[] = [];
    private isCopying = false;
    private s: any = {};
    private t: any = {};
    private confirmationDialogPending = false;
    private v: any = {};
    private clipTipHelper: any;
    private kbHelper: any;
    private y: any = {};
    private z: any = null;
    private A: any = '';
    private throttle: any;
    private ja = false;

    sessionList: any = {};
    events: any;
    canvas: any = null;
    canvases: any = {};
    canvasContainer: HTMLDivElement;
    textBox: any = null;
    clipboard: any = {};
    delegate: SessionViewModelDelegate;

    private k = (a: any, b: any): void => {
        b ? (a.events.subscribe(SessionEvent.DisplayDesktopBackground, this.onDisplayDesktopBackground),
                a.events.subscribe(SessionEvent.WillConnect, this.onWillConnect),
                a.events.subscribe(SessionEvent.DidDisconnect, this.onDidDisconnect),
                a.events.subscribe(SessionEvent.DidConnect, this.onDidConnect),
                a.events.subscribe(SessionEvent.ViewSizeChanged, this.onViewSizeChanged),
                a.events.subscribe(SessionEvent.WindowIconChanged, this.onWindowIconChanged),
                a.events.subscribe(SessionEvent.WindowTitleChanged, this.onWindowTitleChanged),
                a.events.subscribe(SessionEvent.WindowLaunched, this.onWindowLaunched),
                a.events.subscribe(SessionEvent.ApplicationIdChanged, this.onApplicationIdChanged),
                a.events.subscribe(SessionEvent.CredentialsNeeded, this.onCredentialsNeeded),
                a.events.subscribe(SessionEvent.TrustChallenge, this.onTrustChallenge),
                a.events.subscribe(SessionEvent.MousePointerChanged, this.onMousePointerChanged),
                a.events.subscribe(SessionEvent.ShowDefaultMousePointer, this.onShowDefaultMousePointer),
                a.events.subscribe(SessionEvent.GetRemoteClipboardContent, this.onGetRemoteClipboardContent),
                a.events.subscribe(SessionEvent.ClipboardContentRequest, this.onClipboardContentRequest),
                a.events.subscribe(SessionEvent.SetRemoteClipboardFormatsComplete, this.onSetRemoteClipboardFormatsComplete),
                a.events.subscribe(SessionEvent.Error, this.onError)) :
            (a.events.unsubscribe(SessionEvent.DisplayDesktopBackground, this.onDisplayDesktopBackground),
                a.events.unsubscribe(SessionEvent.WillConnect, this.onWillConnect),
                a.events.unsubscribe(SessionEvent.DidDisconnect, this.onDidDisconnect),
                a.events.unsubscribe(SessionEvent.DidConnect, this.onDidConnect),
                a.events.unsubscribe(SessionEvent.ViewSizeChanged, this.onViewSizeChanged),
                a.events.unsubscribe(SessionEvent.WindowIconChanged, this.onWindowIconChanged),
                a.events.unsubscribe(SessionEvent.WindowTitleChanged, this.onWindowTitleChanged),
                a.events.unsubscribe(SessionEvent.WindowLaunched, this.onWindowLaunched),
                a.events.unsubscribe(SessionEvent.ApplicationIdChanged, this.onApplicationIdChanged),
                a.events.unsubscribe(SessionEvent.CredentialsNeeded, this.onCredentialsNeeded),
                a.events.unsubscribe(SessionEvent.TrustChallenge, this.onTrustChallenge),
                a.events.unsubscribe(SessionEvent.MousePointerChanged, this.onMousePointerChanged),
                a.events.unsubscribe(SessionEvent.ShowDefaultMousePointer, this.onShowDefaultMousePointer),
                a.events.unsubscribe(SessionEvent.GetRemoteClipboardContent, this.onGetRemoteClipboardContent),
                a.events.unsubscribe(SessionEvent.ClipboardContentRequest, this.onClipboardContentRequest),
                a.events.unsubscribe(SessionEvent.SetRemoteClipboardFormatsComplete, this.onSetRemoteClipboardFormatsComplete),
                a.events.unsubscribe(SessionEvent.Error, this.onError));
    };

    private C = (a: any): any => {
        return this.canvases.hasOwnProperty(a) || (this.canvases[a] = this.canvasCreateCallback()), this.canvases[a];
    };

    private D = (): void => {
        this.canvas.width > this.canvasContainer.offsetWidth || this.canvas.height > this.canvasContainer.offsetHeight ?
            this.canvasContainer.style.overflow = 'auto' : this.canvasContainer.style.overflow = 'visible';
    };

    private E = (a: any): void => {
        const b = this.C(a);
        b && (b !== this.canvas && (this.canvas && this.canvasContainer.removeChild(this.canvas), this.canvas = b,
            this.canvasContainer.appendChild(this.canvas), this.D()),
        this.delegate.sessionTakeFocus && this.delegate.sessionTakeFocus());
    };

    private F = (): void => {
        this.sessionList = this.sessionProvider.sessionListGrouped(this.sessionList), this.throttle();
    };

    private G = (): void => {
        this.sessionProvider.hasActiveConnections() || this.delegate.allSessionsDisconnected();
    };

    private onWillConnect = (a: any, b: any, d: any): void => {
        let e = '';
        switch (b) {
            case ConnectionState.OpeningRemotePort:
                e = 'CONNECTION_OPENING_REMOTE_PORT';
                break;
            case ConnectionState.EstablishingSecureConnection:
                e = 'CONNECTION_ESTABLISHING_SECURE_CONNECTION';
                break;
            case ConnectionState.ConfiguringRemoteConnection:
                e = 'CONNECTION_CONFIGURING_REMOTE_CONNECTION';
                break;
            case ConnectionState.DetectingNetworkQuality:
                e = 'CONNECTION_DETECTING_NETWORK_QUALITY';
                break;
            case ConnectionState.SessionBrokerFindingDestination:
                e = 'CONNECTION_SESSION_BROKER_FINDING_DESTINATION';
                break;
            case ConnectionState.SessionBrokerLoadingDestination:
                e = 'CONNECTION_SESSION_BROKER_LOADING_DESTINATION';
                break;
            case ConnectionState.SessionBrokerBringingSessionOnline:
                e = 'CONNECTION_SESSION_BROKER_BRINGING_SESSION_ONLINE';
                break;
            case ConnectionState.SessionBrokerRedirectingToDestination:
                e = 'CONNECTION_SESSION_BROKER_REDIRECTING_TO_DESTINATION';
                break;
            case ConnectionState.VirtualMachineLoading:
                e = 'CONNECTION_VIRTUAL_MACHINE_LOADING';
                break;
            case ConnectionState.VirtualMachineWaking:
                e = 'CONNECTION_VIRTUAL_MACHINE_WAKING';
                break;
            case ConnectionState.VirtualMachineStarting:
                e = 'CONNECTION_VIRTUAL_MACHINE_STARTING';
                break;
            case ConnectionState.VirtualMachineRetryingSessionMonitoring:
                e = 'CONNECTION_VIRTUAL_MACHINE_RETRYING_SESSION_MONITORING';
                break;
            case ConnectionState.VirtualMachineStartingSessionMonitoring:
                e = 'CONNECTION_VIRTUAL_MACHINE_STARTING_SESSION_MONITORING';
        }
        if (this.s.hasOwnProperty(a) ? this.s[a].state = e : this.s[a] = {
            state: e
        }, 0 !== d) {
            this.t.hasOwnProperty(a) ? this.t[a].retryCount = d : this.t[a] = {
                retryCount: d
            };
            const f = this.sessionStore.find(a);
            this.delegate.openAutoreconDialog && f && this.delegate.openAutoreconDialog(a, f.getConnectionId(), this.t);
        }
        this.sessionStateStore.next(a, {
            state: e,
            retryCount: d
        });
        this.throttle();
    };

    private onDidConnect = (a: any): void => {
        this.logger.debug('[SessionViewModel] Received didConnect event on UI layer for session ' + a);
        const c = this.sessionProvider.find(a);
        c.getBookmark() && c.getBookmark().bookmarkType !== BookmarkType.RemoteApp && this.delegate.closeLoaderModal && this.delegate.closeLoaderModal(a),
        this.delegate.closeAutoreconDialog && this.delegate.closeAutoreconDialog(a), this.F(), this.E(this.session.getConnectionId());
    };

    private onDidDisconnect = (a: any): void => {
        this.logger.debug('[SessionViewModel] Received didDisconnect event on UI layer');
        const b = this.sessionStore.find(a);
        !this.delegate.closeLoaderModal || b && 0 !== b.getSiblingCount() || this.delegate.closeLoaderModal(a),
        !this.delegate.closeAutoreconDialog || b && 0 !== b.getSiblingCount() || this.delegate.closeAutoreconDialog(a), this.F(), this.G();
    };

    private onError = (a: any, b: any): void => {
        this.logger.error('[SessionViewModel] Session ' + a + ' had error: ' + b);
    };

    private canvasNedded = (a: any): any => {
        this.logger.debug('[SessionViewModel] Received canvasNeeded event on UI layer, connectionID=' + a);
        const b = this.C(a);
        return this.logger.debug(b), b;
    };

    private retireCanvas = (a: any): any => {
        this.logger.debug('[SessionViewModel] Received retireCanvas event on UI layer, connectionID=' + a), delete this.canvases[a];
    };

    private monitorBounds = (): any => {
        return this.logger.debug('[SessionViewModel] Received monitorBound event on UI layer'), this.getMonitorBounds();
    };

    private onCredentialsNeeded = (a: any, b: RdpConnection, hostname: string): void => {
        if (true === a.isTokenRequired) {
            this.logger.debug('[SessionViewModel] Credentials requested (token)');
            const c = this.userAuthInfoService.getCachedToken();
            c ? (this.logger.debug('[SessionViewModel] Token found, completing credential request with token'), a.completeWithToken('Bearer ' + c)) : a.cancel();
        } else {
            this.logger.debug('[SessionViewModel] Credentials requested (username/password)'),
            this.delegate.onCredentialsNeeded && this.delegate.onCredentialsNeeded(a, b, hostname);
        }
    };

    private onTrustChallenge = (a: any): void => {
        this.logger.debug('[SessionViewModel] Certificate trust challenge'), this.delegate.onTrustChallenge && this.delegate.onTrustChallenge(a);
    };

    private onMousePointerChanged = (a: any): void => {
        this.logger.debug('[SessionViewModel] Mouse pointer changed to ' + a), this.canvas.style.cursor = 'url("' + a + '"), auto';
    };

    private onShowDefaultMousePointer = (a: any): void => {
        switch (this.logger.debug('[SessionViewModel] Default mouse pointer value changed to ' + a), a) {
            case true:
                this.canvas.style.cursor = 'auto';
                break;
            case false:
                this.platformInfo.isMac && this.platformInfo.isFirefox || (this.canvas.style.cursor = 'none');
        }
    };

    private onGetRemoteClipboardContent = (eventProvider: EventProvider, b: any): void => {
        this.z = this.session.getConnectionId();
        this.setLocalClipboardContent(eventProvider, b);
        this.setRemoteClipboardFormats();
        this.copyPending = true;
        this.A = b;
        this.logger.log('[SessionViewModel] Setting textbox value to ' + b);
        if (this.platformInfo.isMac) {
            navigator.clipboard.writeText(b).then();
        } else {
            this.textBox.value = b;
        }
    };

    private onClipboardContentRequest = (a: any): void => {
        const b = this.clipboard[a.GetFormat()];
        b ? a.complete(b) : a.cancel(), a.delete();
    };

    private onSetRemoteClipboardFormatsComplete = (a: any): void => {
        a.value === Module.ClipboardResponse.ResponseOk.value && this.logger.log('[SessionViewModel] Set remote clipboard formats complete');
    };

    private onWindowIconChanged = (): void => {
        this.F();
    };

    private onWindowTitleChanged = (): void => {
        this.F();
    };

    private onWindowLaunched = (): void => {
        this.F();
    };

    private onApplicationIdChanged = (): void => {
        this.F();
    };

    private onViewSizeChanged = (a: any, b: any, c: any): void => {
        a.getConnectionId() === this.session.getConnectionId() && (this.canvas.width = b, this.canvas.height = c, this.D());
    };

    private onSessionFocused = (a: any): void => {
        this.session = this.sessionStore.find(a), this.logger.log('[SessionViewModel] Session focused event received in view model session id: ' + this.session.id),
            this.F(), this.E(this.session.getConnectionId());
    };

    private onSessionDestroyed = (a: any): void => {
        this.logger.debug('[SessionViewModel] Session destroy event received for: ' + a), this.F(), this.G();
    };

    private onSessionCreated = (a: any): void => {
        this.logger.log('[SessionViewModel] Session created event received for: ' + a);
        this.session = this.sessionStore.find(a);
        this.k(this.session, true);
        if (this.session.state === SessionState.Initialized) {
            this.session.connect();
            if (this.delegate.openLoaderModal && this.session && 0 === this.session.getSiblingCount()) {
                this.delegate.openLoaderModal(a, this.session.getConnectionId(), this.s, this.session.getLabel());
            }
        }
        this.F();
        this.E(this.session.getConnectionId());
        // (this.j = this.c.find(a)) && (this.k(this.j, true),
        // this.j.state === SessionState.Initialized && (this.j.connect(), this.delegate.openLoaderModal && this.j && 0 === this.j.getSiblingCount() &&
        // this.delegate.openLoaderModal(a, this.j.getConnectionId(), this.s, this.j.getLabel())), this.F(), this.E(this.j.getConnectionId()));
    };

    private onSessionCreateFailed = (a: any): void => {
        this.logger.debug('[SessionViewModel] SessionCreateFailed event received in view model'), a &&
        a.propertiesRejectedReason === ConnectionPropertiesRejectedReason.GatewayNotSpecified && this.delegate.showNoGatewayError && this.delegate.showNoGatewayError(a);
    };

    private onSessionOrderChanged = (): void => {
        this.logger.debug('[SessionViewModel] Session order changed event received in view model'), this.F();
    };

    private onSessionsLoaded = (): void => {
        this.logger.debug('[SessionViewModel] Sessions loaded event received in view model'), this.F();
    };

    private fa = (): any => {
        // let connectionId;
        // if (!this.canvas){
        //     if (this.j) {
        //         connectionId = this.j.getConnectionId();
        //     } else {
        //         if (this.canvases){
        //             for (const key in this.canvases){
        //                 if (this.canvases.hasOwnProperty(key)){
        //                     connectionId = key;
        //                 }
        //             }
        //         }
        //     }
        //     if (connectionId && this.canvases){
        //         if (this.canvases.hasOwnProperty(connectionId)){
        //             this.canvas = this.canvases[connectionId];
        //         }
        //     }
        // }
        const a = this.canvas.getBoundingClientRect();
        return {
            x: a.x + window.pageXOffset,
            y: a.y + window.pageYOffset,
            width: a.width,
            height: a.height,
            top: a.top + window.pageYOffset,
            bottom: a.bottom + window.pageYOffset,
            left: a.left + window.pageXOffset,
            right: a.right + window.pageXOffset
        };
    };

    private calculateMousePos = (a: any, b: any): MousePos => {
        let aa = a, bb = b, c, d, e = 0,
            f = 0;
        const g = this.fa();
        return e += g.left, f += g.top, c = this.canvas.offsetWidth / this.canvas.width, d = this.canvas.offsetHeight / this.canvas.height, aa = (aa - e) / c, bb = (bb - f) / d, {
            x: aa,
            y: bb
        };
    };

    private ha = (a: any, b: any): any => {
        const c = this.fa(),
            d = this.canvas.width / (c.right - c.left),
            e = this.canvas.height / (c.bottom - c.top);
        return {
            clientX: (a - c.left) * d,
            clientY: (b - c.top) * e
        };
    };

    private ia = (a: any): any => {
        let b;
        return 1 === a ? b = MouseButton.Left : 2 === a ? b = MouseButton.Middle :
            3 === a ? b = MouseButton.Right : 4 === a ? b = MouseButton.Button4 : 5 === a && (b = MouseButton.Button5), b;
    };

    private processKeySpecial = (a: CustomKeyboardEvent, b: boolean): boolean => {
        if (!this.platformInfo.isMac || a.keyCode !== KeyCodes.Cmd1 && a.keyCode !== KeyCodes.Cmd2 && a.keyCode !== KeyCodes.Cmd3) {
            if ('End' === a.key && a.altKey && a.ctrlKey && !a.metaKey && !a.shiftKey) {
                a.key = 'Delete';
                a.keyCode = KeyCodes.Delete;
                return false;
            } else {
                if (!('Meta' !== a.key && 'Win' !== a.key || a.altKey || a.ctrlKey || a.shiftKey) ||
                    !('F3' !== a.key || !a.altKey || a.ctrlKey || a.metaKey || a.shiftKey)) {
                    this.session.keyUp(KeyCodes.Alt);
                    if (false === this.ja) {
                        this.ja = true;
                        a.key = 'Meta';
                        a.keyCode = KeyCodes.Cmd1;
                    }
                    if (false === b) {
                        this.ja = false;
                        a.key = 'Meta';
                        a.keyCode = KeyCodes.Cmd1;
                    }
                }
                return false;
            }
        } else {
            a.key = 'Ctrl';
            a.keyCode = KeyCodes.Ctrl;
            a.metaKey = false;
            a.ctrlKey = true;
            return true;
        }
    };

    private processKey = (event: CustomKeyboardEvent, keyDown: boolean): boolean => {
        if (this.processKeySpecial(event, keyDown)) {
            return false;
        }
        const keyCode = event.keyCode;
        if (this.kbHelper.isNonCharacterKey(keyCode) || event.altKey || event.metaKey || event.ctrlKey || this.kbHelper.isCmdPressed()) {
            this.v[keyCode] = keyDown;
            const char = String.fromCharCode(keyCode);
            if (!this.kbHelper.isNumericPadKey(keyCode)) {
                if (!this.platformInfo.isMac && !this.kbHelper.isNonCharacterKey(keyCode) && char.toLowerCase() !== event.key && char.toUpperCase() !== event.key) {
                    return false;
                }
                if (this.platformInfo.deviceInfo.isWindows() && event.altKey && event.ctrlKey && !this.kbHelper.isNonCharacterKey(keyCode) &&
                    !this.kbHelper.isCtrlAltCharacterShortCuts(keyCode)) {
                    return false;
                }
            }
            if (keyDown) {
                setTimeout(() => {
                    this.session.keyDown(keyCode);
                }, 25);
                if ((this.kbHelper.isCmdPressed() || event.ctrlKey) && (keyCode === ASCIICodes.C || keyCode === ASCIICodes.V || keyCode === ASCIICodes.X ||
                    keyCode === ASCIICodes.c || keyCode === ASCIICodes.v || keyCode === ASCIICodes.x)) {
                    this.executeCopyPending = true;
                    return false;
                }
            } else {
                setTimeout(() => {
                    this.session.keyUp(keyCode);
                }, 25);
            }
            return !this.platformInfo.isMac && keyCode !== KeyCodes.Ctrl || this.platformInfo.isMac && (keyCode !== KeyCodes.Cmd1 && keyCode !== KeyCodes.Cmd2 ||
                // @ts-ignore
                keyCode !== KeyCodes.Cmd3);
        }
        return false;
    };

    private ma = (): void => {
        for (; this.q.length > 0;) {
            clearTimeout(this.q.shift());
        }
    };

    private processCopy = (): void => {
        if (this.copyPending) {
            this.ma();
            const activeElement = document.activeElement as HTMLElement;
            this.textBox.select();
            try {
                if (document.execCommand('copy')) {
                    this.logger.log('[SessionViewModel] \'' + this.textBox.value + '\' successfully copied to local clipboard');
                    this.copyPending = false;
                    activeElement.focus();
                } else {
                    this.logger.log('[SessionViewModel] Unable to copy to local clipboard because execcommand returned false.');
                    activeElement.focus();
                    if (this.delegate.showCopyConfirmationDialog && !this.confirmationDialogPending) {
                        this.confirmationDialogPending = true;
                        this.isCopying = true;
                        this.delegate.showCopyConfirmationDialog(this.processCopy);
                    }
                }
            } catch (b) {
                this.logger.log('[SessionViewModel] Unable to copy to local clipboard because execcommand failed.');
                activeElement.focus();
                if (this.delegate.showCopyConfirmationDialog && !this.confirmationDialogPending) {
                    this.confirmationDialogPending = true;
                    this.isCopying = true;
                    this.delegate.showCopyConfirmationDialog(this.processCopy);
                }
            }
        }
    };

    private onDisplayDesktopBackground = (a: any, c: any): void => {
        const d = this.sessionProvider.find(c);
        if (d.getBookmark() && d.getBookmark().bookmarkType === BookmarkType.RemoteApp) {
            a && this.delegate.closeLoaderModal && this.delegate.closeLoaderModal(c);
            const f = true === a ? 'block' : 'none';
            const g = this.C(d.getConnectionId());
            g.style.display !== f && (g.style.display = f, this.logger.log('[SessionViewModel] Session ' + c + ' changed canvas display to ' + f));
        }
    };

    isIdTypeOrgId(): any {
        return false;
    }

    setDontShowHelpTipValue(a: any): void {
        this.clipTipHelper.setDontShowHelpTipValue(a);
    }

    runExeccommandOnKey(): void {
        this.isCopying = true;
    }

    sendCutEvent(): void {
        this.clipTipHelper.showCliphelpTip(this.platformInfo, this.session.getConnectionId(), this.delegate.showClipHelptip);
    }

    sendCopyEvent(): void {
        this.clipTipHelper.showCliphelpTip(this.platformInfo, this.session.getConnectionId(), this.delegate.showClipHelptip);
    }

    executeCopy(): void {
        if (this.executeCopyPending) {
            this.executeCopyPending = false;
            this.p.map((a) => {
                setTimeout(this.processCopy, a);
            });
        }
    }

    init(): void {
        this.canvasContainer = document.getElementById('canvas-container') as HTMLDivElement;
        this.sessionProvider.events.subscribe(SessionProviderEvent.SessionCreated, this.onSessionCreated),
            this.sessionProvider.events.subscribe(SessionProviderEvent.SessionCreateFailed, this.onSessionCreateFailed),
            this.sessionProvider.events.subscribe(SessionProviderEvent.SessionFocused, this.onSessionFocused),
            this.sessionProvider.events.subscribe(SessionProviderEvent.SessionDestroyed, this.onSessionDestroyed),
            this.sessionProvider.events.subscribe(SessionProviderEvent.SessionOrderChanged, this.onSessionOrderChanged),
            this.sessionProvider.events.subscribe(SessionProviderEvent.SessionsLoaded, this.onSessionsLoaded),
            this.sessionProvider.delegate.canvasNeeded = this.canvasNedded,
            this.sessionProvider.delegate.retireCanvas = this.retireCanvas,
            this.sessionProvider.delegate.monitorBounds = this.monitorBounds;
    }

    handlePasteEvent(a: any): void {
        let b = a;
        // debugger;
        if (this.platformInfo.isMac) {
            b = this.kbHelper.convertToCRLF(a);
        }
        this.clipTipHelper.showCliphelpTip(this.platformInfo, this.session.getConnectionId(), this.delegate.showClipHelptip);
        if (this.isClipboardReady()) {
            if (a !== this.A) {
                this.z = null;
                this.setLocalClipboardContent(Module.ClipboardFormatType.Text, b);
                this.setLocalClipboardContent(Module.ClipboardFormatType.UnicodeText, b);
                this.setRemoteClipboardFormats();
            }
            this.A = a;
        }
    }

    setLocalClipboardContent(a: any, b: any): void {
        this.clipboard[a] = b;
    }

    setRemoteClipboardFormats(): void {
        let a;
        const c = this.sessionProvider.connectionList(),
            d = new Module.ClipboardFormatList;
        for (d.push_back(new Module.ClipboardFormat(Module.ClipboardFormatType.Text)),
                 d.push_back(new Module.ClipboardFormat(Module.ClipboardFormatType.UnicodeText)), a = 0; a < c.length; a++) {
            const e = c[a];
            e.id !== this.z && e.clipboardHandler.setRemoteClipboardFormats(d);
        }
        d.delete();
    }

    isClipboardReady(): any {
        return !!this.session && this.session.isClipboardReady();
    }

    getMonitorBounds(): any {
        let a, b;
        const rect = this.canvasContainer.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;
        return a = width, b = height, a = Math.max(a, 800), a = Math.min(a, 3840), b = Math.max(b, 208),
            b = Math.min(b, 2160), a = 16 * Math.floor(a / 16), b = 16 * Math.floor(b / 16), {
            width: a,
            height: b
        };
    }

    postCopyDialogCleanup(): void {
        this.copyPending = false;
        this.confirmationDialogPending = false;
        this.isCopying = false;
    }

    disconnectAllSessions(): void {
        this.sessionProvider.disconnect(), this.F(), this.G();
    }

    disconnectSession(a: any, c: any): void {
        const d = this.sessionProvider.find(a);
        d && d.disconnect(c);
    }

    cancelAutoreconnect(): void {
    }

    openPopover(a: any): void {
        let b = null;
        try {
            b = new Event('openTrigger');
        } catch (a) {
            b = document.createEvent('Event'), b.initEvent('openTrigger', true, true);
        }
        document.getElementById(a).dispatchEvent(b);
    }

    closePopover(a: any): void {
        let b = null;
        try {
            b = new Event('closeTrigger');
        } catch (a) {
            b = document.createEvent('Event'), b.initEvent('closeTrigger', true, true);
        }
        document.getElementById(a).dispatchEvent(b);
    }

    getConnectionStateForId(a: any): any {
        return this.s[a];
    }

    getAppListForId(a: any): any {
        return this.sessionList[a];
    }

    closeAppGroup(a: any): any {
        const b = this.getAppListForId(a);
        if (b && b.sessionList) {
            for (let c = 0; c < b.sessionList.length; c++) {
                b.sessionList[c].disconnect();
            }
        }
    }

    hasBookmarks(a: any): any {
        // throw new Error('this.bookmarkList not found');
        return this.bookmarkList(a).length > 0;
    }

    bookmarkList(a: any): any {
        throw new Error('bookmarkList(a) not implemented');
    }

    shown(): void {
        let a, c;
        this.logger.log('[SessionViewModel] Session shown'), this.F();
        for (a in this.sessionList) {
            if (this.sessionList.hasOwnProperty(a)) {
                const d = this.sessionList[a].sessionList;
                // tslint:disable-next-line:forin
                for (a in d) {
                    d.hasOwnProperty(a) && (c = d[a], this.k(c, true));
                }
            }
        }
        this.session = this.sessionProvider.activeSession, setTimeout(() => {
            this.sessionProvider.invalidateCanvasForActiveConnections();
        }, 500);
    }

    dismissed(): void {
        let a, c;
        this.logger.log('[SessionViewModel] Session dissmissed'),
            this.sessionProvider.events.unsubscribe(SessionProviderEvent.SessionCreated, this.onSessionCreated),
            this.sessionProvider.events.unsubscribe(SessionProviderEvent.SessionCreateFailed, this.onSessionCreateFailed),
            this.sessionProvider.events.unsubscribe(SessionProviderEvent.SessionFocused, this.onSessionFocused),
            this.sessionProvider.events.unsubscribe(SessionProviderEvent.SessionDestroyed, this.onSessionDestroyed),
            this.sessionProvider.events.unsubscribe(SessionProviderEvent.SessionOrderChanged, this.onSessionOrderChanged),
            this.sessionProvider.events.unsubscribe(SessionProviderEvent.SessionsLoaded, this.onSessionsLoaded);
        this.sessionProvider.delegate.canvasNeeded = null;
        this.sessionProvider.delegate.retireCanvas = null;
        this.sessionProvider.delegate.monitorBounds = null;
        for (a in this.sessionList) {
            if (this.sessionList.hasOwnProperty(a)) {
                const d = this.sessionList[a].sessionList;
                // tslint:disable-next-line:forin
                for (a in d) {
                    d.hasOwnProperty(a) && (c = d[a], this.k(c, false));
                }
            }
        }
    }

    mouseDown(a: any, b: any, c: any): void {
        let cc;
        const mousePos = this.calculateMousePos(a, b);
        cc = this.ia(c), this.session.mouseDown(mousePos.x, mousePos.y, cc), this.y[cc] = true;
    }

    mouseUp(a: any, b: any, c: any): void {
        let cc;
        const mousePos = this.calculateMousePos(a, b);
        cc = this.ia(c), this.session.mouseUp(mousePos.x, mousePos.y, cc),
        cc === MouseButton.Left && this.n === MouseButton.Right && (this.executeCopyPending = true, this.executeCopy()), this.n = cc, delete this.y[cc];
    }

    mouseMove(a: any, b: any): void {
        const mousePos = this.calculateMousePos(a, b);
        this.session.mouseMove(mousePos.x, mousePos.y);
    }

    mouseWheel(a: any, b: any): void {
        this.session.mouseWheel(a, b);
    }

    mouseEnter(): void {
    }

    mouseLeave(a: any, b: any): void {
        let c;
        const mousePos = this.calculateMousePos(a, b);
        this.logger.debug('[SessionViewModel] Mouse left canvas mouse position: ' + mousePos.x + ':' + mousePos.y),
            mousePos.x < 0 ? mousePos.x = 0 : mousePos.x > this.canvas.width && (mousePos.x = this.canvas.width),
            mousePos.y < 0 ? mousePos.y = 0 : mousePos.y > this.canvas.height && (mousePos.y = this.canvas.height);
        // tslint:disable-next-line:forin
        for (c in this.y) {
            this.y.hasOwnProperty(c) && this.y[c] && (this.logger.debug('[SessionViewModel Resetting key state for mouse button: ' + c),
                this.session.mouseUp(mousePos.x, mousePos.y, parseInt(c, 10)), delete this.y[c]);
        }
    }

    addAdjustedTouchPos(a: any): void {
        for (let b = 0; b < a.length; b++) {
            a[b].adjustedTouchPos = this.ha(a[b].clientX, a[b].clientY);
        }
    }

    touchStart(a: any): void {
        this.addAdjustedTouchPos(a), this.session.sendTouchEvents(TouchState.Start, a);
    }

    touchMove(a: any): void {
        this.addAdjustedTouchPos(a), this.session.sendTouchEvents(TouchState.Move, a);
    }

    touchEnd(a: any): void {
        this.addAdjustedTouchPos(a), this.session.sendTouchEvents(TouchState.End, a);
    }

    touchCancel(a: any): void {
        this.addAdjustedTouchPos(a), this.session.sendTouchEvents(TouchState.Cancel, a);
    }

    keyPress(keyCode: number, event: CustomKeyboardEvent): any {
        if (!this.kbHelper.isCmdPressed() && !event.ctrlKey || !event || 'KeyV' !== event.code && 'KeyC' !== event.code && 'KeyX' !== event.code &&
            (!this.platformInfo.isSafari || keyCode !== ASCIICodes.c && keyCode !== ASCIICodes.v && keyCode !== ASCIICodes.x)) {
            const altKey = event.altKey;
            if (altKey && !this.platformInfo.isMac) {
                this.session.keyUp(KeyCodes.Alt);
            }
            this.session.keyUnicodeDown(keyCode);
            this.session.keyUnicodeUp(keyCode);
            if (altKey && !this.platformInfo.isMac) {
                this.session.keyDown(KeyCodes.Alt);
            }
            return true;
        }
        return false;
    }

    keyDown(a: CustomKeyboardEvent): any {
        if (this.isCopying) {
            document.execCommand('copy');
        }
        return this.processKey(a, true);
    }

    keyUp(a: CustomKeyboardEvent): any {
        return this.processKey(a, false);
    }

    canvasFocus(): void {
        this.logger.debug('[SessionViewModel] Canvas gains focus');
    }

    canvasLostFocus(): void {
        let a;
        this.logger.debug('[SessionViewModel] Canvas lost focus'), setTimeout(() => {
            // tslint:disable-next-line:forin
            for (a in this.v) {
                this.v.hasOwnProperty(a) && this.v[a] && (this.session.keyUp(parseInt(a, 10)), this.v[a] = false);
            }
        }, 26);
    }

    activeSessionId(): any {
        return this.session ? this.session.id : null;
    }

    getAppIdForActiveSession(): any {
        return this.session ? this.session.getApplicationId() : null;
    }

    getSessionListForAppId(a: any): any {
        return this.sessionList[a];
    }

    checkAndSwitchActiveWindow(a: any, b: any): void {
        if (b.is('img')) {
            let c;
            c = this.getSessionListForAppId(a).sessionList[0].id, this.focusToSession(c);
        }
    }

    focusToSession(a: string): void {
        this.logger.debug('[SessionViewModel] Session switched to ' + a), this.sessionProvider.focusToSession(a, true);
    }

    displayDesktopBackground(a1: any, a2: any): void {
        this.onDisplayDesktopBackground(a1, a2);
    }
}

export interface MousePos {
    x: number;
    y: number;
}

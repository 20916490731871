import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {Role} from '../../models';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class RoleDAOService extends BaseDao<Role> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'role');
    }
}

import {Injectable} from '@angular/core';
import {DotNetServerPluginDependencyFile} from '../../../models';
import {BaseDao} from '../../../base-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class DotNetServerPluginDependencyFileDaoService extends BaseDao<DotNetServerPluginDependencyFile> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'server-plugin-dependency-file');
    }

    upload(formData: FormData): Observable<DotNetServerPluginDependencyFile> {
        return this.http.upload(formData, this.url + `/upload`);
    }

    download(id: number): Observable<Blob> {
        return this.http.download(this.url + `/${id}/download`);
    }
}

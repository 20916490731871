export enum SessionProviderEvent {
    SessionCreated = 0,
    SessionDestroyed = 1,
    SessionFocused = 2,
    SessionCreateFailed = 3,
    SessionOrderChanged = 4,
    SessionsLoaded = 5,
    SessionGroupingChanged = 6,
    RedirectionsRequest = 7,
    SessionIconChanged = 8
}

import {Component, Input} from '@angular/core';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {Customer, CustomerGroup, SnapshotType} from '../../../models';
import {StatusService} from '../status-service';
import {FormControl} from '@angular/forms';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-manager-user-changing-or-deleting',
    templateUrl: './manager-user-changing-or-deleting.component.html',
    styleUrls: ['./manager-user-changing-or-deleting.component.scss', '../status.component.scss']
})
export class ManagerUserChangingOrDeletingComponent {

    managerUsersSnapshots: JaversSnapshot<any>[];
    snapshotTypeControl: FormControl = new FormControl();
    sizeListControl: FormControl = new FormControl();
    snapshotType = SnapshotType;
    loading = false;

    @Input()
    customers: Customer[] = [];

    @Input()
    customerGroups: CustomerGroup[] = [];

    constructor(
        private managerUserDao: ManagerUserDaoService,
        protected statusServer: StatusService,
        private dialog: MatDialog,

        ) {
        this.sizeListControl.setValue(5);
        this.snapshotTypeControl.setValue(SnapshotType.ALL);
        // this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    private snapshotsByTypeAndLimit(size: number, snapshotType: SnapshotType = SnapshotType.ALL): void {
        this.loading = true;
        this.managerUsersSnapshots = [];
        this.managerUserDao.snapshotsByTypeAndLimit(size, snapshotType).subscribe((subProjectsSnapshots) => {
            this.managerUsersSnapshots = subProjectsSnapshots;
            this.loading = false;
        });
    }

    refresh(): void {
        this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    onJaversHistory(id: number): void {
        EntityHistoryComponent.openHistory(id, this.managerUserDao, this.dialog);
    }

}

<app-customer-group-tool-bar *ngIf="subProjectIdList.length === 1" [customStyle]="customStyle"></app-customer-group-tool-bar>
<div *ngIf="subProjectIdList.length > 1">
    <mat-form-field class="full-width" style="padding: 7px 10px 0 10px;height: 80px;border-radius: 0 0 5px 5px;" appearance="outline">
        <mat-label>Projetos</mat-label>
        <mat-select placeholder="Projetos"  [formControl]="subProjectFilterControl">
            <mat-option *ngFor="let subProjectId of subProjectIdList" [value]="subProjectId">
                {{ findSubProjectById(subProjectId)?.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div fxLayout="row wrap">
    <ng-container *ngFor="let remoteAppWrapper of remoteAppHtml5List">
        <div *ngIf="remoteAppWrapper.remoteAppId && remoteAppWrapper.status !== 'DELETING' && (!subProjectFilterControl.value || subProjectFilterControl.value == remoteAppWrapper.subProjectId)"
             [title]="remoteAppWrapper?.remoteAppId"
             matTooltip="Servidor desconectado, o aplicativo talvez não abra"
             [matTooltipDisabled]="remoteAppWrapper.status === 'READY' || remoteAppWrapper.status === 'CLIENT_NOT_CONNECTED'"
             appDebounceClick
             (click)="onOpen(remoteAppWrapper)"
             [class.remote-app-error]="remoteAppWrapper.status === 'SERVER_NOT_CONNECTED'"
             [class.remote-app-ok]="remoteAppWrapper.status === 'READY' || remoteAppWrapper.status === 'CLIENT_NOT_CONNECTED'"
             class="m-14 remote-app" fxLayout="column" fxLayoutAlign="center center">
            <img alt="{{remoteAppWrapper.remoteAppId}}" [class.remote-app-image-error]="remoteAppWrapper.status === 'SERVER_NOT_CONNECTED'"
                 [class.remote-app-image-warn]="remoteAppWrapper.status === 'CLIENT_NOT_CONNECTED'  || remoteAppWrapper.status === 'PREPARING'"
                 [src]=imageFileService.getRemoteAppPictureUrl(remoteAppWrapper.imageUUID)
                 width="64" height="64">
            <div class="remote-app-name mt-8">{{remoteAppWrapper.appName}}</div>
        </div>
    </ng-container>
</div>
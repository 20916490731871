import {Component, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {RoleSubProjectManagerUseInBatch} from '../../../../models';
import {ComponentCleaner} from '../../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {RoleSubProjectManagerUserDAOService} from '../../role-sub-project-manager-user-dao.service';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';
import {invalidEmail} from '../../../../utils/utils-kluh';

@Component({
    selector: 'app-manager-user-administrator-in-batch',
    templateUrl: './manager-user-administrator-in-batch.component.html',
    styleUrls: ['./manager-user-administrator-in-batch.component.scss']
})
export class ManagerUserAdministratorInBatchComponent extends ComponentCleaner {

    emailsControl: FormControl = new FormControl();
    roleId: number = null;
    roleControl: FormControl = new FormControl();

    constructor(
        public adminService: R2CloudAdminService,
        public managerUserDaoService: ManagerUserDaoService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ManagerUserAdministratorInBatchComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private roleSubProjectManagerUserDao: RoleSubProjectManagerUserDAOService
    ) {
        super();
        this.emailsControl.setValidators([invalidEmail()]);
    }

    getAmountOfEmails(): number {
        let emailsStr = '';
        if (this.emailsControl.value) {
            emailsStr = this.emailsControl.value;
        }
        const emails = emailsStr?.match(/\n/g);
        return emails ? (emails.length + 1) : 0;
    }


    onCancel(): void {
        this.dialogRef.close(false);
    }

    onCreate(): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja inserir todos esses usuários com essa permissão no projeto (' + this.data.subProject.name + ')?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const emails = this.emailsControl.value?.trim().split(/\n/g);

                const roleSubProjectManagerUseInBatch: RoleSubProjectManagerUseInBatch = {
                    emails: emails,
                    subProjectId: this.data.subProject.id,
                    roleId: +this.roleControl.value
                };
                this.managerUserDaoService.findAllByEmailAndSubProjectIdList(emails, this.data.subProject.id).subscribe((emailAndSubProjectIdList) => {
                    const openAlert = emailAndSubProjectIdList.some(e => e.exists === false || e.hasRole === true);
                    if (openAlert) {
                        const noExists = emailAndSubProjectIdList.filter(e => e.exists === false).map(e => '<div>' + e.email + '</div>').join('');
                        const hasRole = emailAndSubProjectIdList.filter(e => e.hasRole === true).map(e => '<div>' + e.email + '</div>').join('');
                        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                            disableClose: true,
                            data: {
                                message: '<h1>Atenção</h1>' +
                                    '<h3>- E-mails não cadastrados na plataforma, serão ignorados na inserção dos usuários no projeto.</h3>' +
                                    '<h3>- E-mails que já tem um Papel na aba administração, serão substituidos pelo Papel inserido agora.</h3>' +
                                    '<div class="pt-25 pb-40">' +
                                    '<div>' +
                                        '<div  class="w-420 float-left">' +
                                            '<div>' +
                                                '<div><b>Não cadastrados:</b></div>' +
                                                noExists +
                                            '</div>' +
                                        '</div>' +
                                        '<div class="float-left">' +
                                            '<div>' +
                                        '       <div><b>Com Papel:</b></div>' +
                                                hasRole +
                                            '</div>' +
                                        '</div>' +
                                        '<div class="display-block-clear"></div>' +
                                    '</div>' +
                                    '<div class="display-block-clear"></div>' +
                                    '</div>' ,
                                disableCancel: false,
                                cancelButtonValue: 'Cancelar',
                                confirmButtonValue: 'OK',
                                icon: 'error_outline'
                            }
                        }).afterClosed().subscribe((result2) => {
                            if (result2) {
                                this.roleSubProjectManagerUserDao.createInBatch(roleSubProjectManagerUseInBatch).subscribe(_ => {
                                    this.dialogRef.close(true);
                                });
                            }
                        }));
                    } else {
                        this.roleSubProjectManagerUserDao.createInBatch(roleSubProjectManagerUseInBatch).subscribe(_ => {
                            this.dialogRef.close(true);
                        });
                    }
                });
            }
        }));
    }
}

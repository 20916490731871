import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {DesktopServerPool, DesktopServerPoolWithDesktopServerIds} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {BaseDao} from '../../base-dao';
import {Observable} from 'rxjs/internal/Observable';
import {ValueWrapper} from '../../helpers/value-wrapper';

@Injectable()
export class DesktopServerPoolDaoService extends BaseDao<DesktopServerPool> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'desktop-server-pool');
    }

    initDesktopServerPool(): DesktopServerPool {
        return {
            id: null,
            active: true,
            comment: null,
            modified: null,
            optlock: null,
            groupId: null,
            appBaseDesktopServerId: null,
            notOpenAppFromAppBaseDesktopServer: null,
            name: null,
            adDomainId: null,
            gatewayServerId: null,
            gatewayServerNatConfigDestinationPortRangeMin: null,
            gatewayServerNatConfigDestinationPortRangeMax: null,
            gatewayServerNatConfigOpenAccess: null,
            gatewayServerNatConfigServerConnectionType: null,
            gatewayServerNatConfigServerConnectionCustomPort: null,
            gatewayServerNatConfigProtocol: null,
            gatewayFQDN: null,
            maxUsersPerDesktopServer: null
        };
    }

    createADUserPoolRelationshipGroups(desktopServerPoolWithDesktopServerIds: DesktopServerPoolWithDesktopServerIds, subProjectId: number): Observable<DesktopServerPool> {
        return this.http.post<DesktopServerPool>(this.url + '/create-and-save-in-sub-project-list/' + subProjectId, desktopServerPoolWithDesktopServerIds);
    }

    generateNewPrivateKey(groupId: string): Observable<ValueWrapper> {
        return this.http.get<ValueWrapper>(this.url + '/generate-new-private-key/' + groupId);
    }

    saveDesktopServerPoolWithDesktopServerIds(
        desktopServerPoolWithDesktopServerIds: DesktopServerPoolWithDesktopServerIds
    ): Observable<DesktopServerPoolWithDesktopServerIds> {
        return this.http.put<DesktopServerPoolWithDesktopServerIds>(
            this.url + '/save-desktop-server-pool-with-desktop-server-ids/' + desktopServerPoolWithDesktopServerIds.desktopServerPool.id, desktopServerPoolWithDesktopServerIds
        );
    }

    canDelete(desktopServerPoolId: number): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/can-delete/' + desktopServerPoolId);
    }

    findAllByProjectId(projectId: number): Observable<DesktopServerPool[]> {
        return this.http.get<DesktopServerPool[]>(this.url + '/find-all-by-project-id/' + projectId);
    }

    saveDesktopServerPoolRelationshipSubProject(desktopServerPoolId: number, subProjectId: number): Observable<void> {
        return this.http.put<void>(
            this.url + '/save-desktop-server-pool-relationship-sub-project/' + desktopServerPoolId, subProjectId
        );
    }

    deleteFromSubProject(desktopServerPoolId: number, subProjectId: number): Observable<string> {
        return this.http.remove(this.url + '/delete-from-sub-project/?desktopServerPoolId=' + desktopServerPoolId + '&subProjectId=' + subProjectId);
    }
}

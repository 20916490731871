import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MetricType, ServerOverview} from '../../../../../models-monitoring';
import {BehaviorSubject, Subscription} from 'rxjs';
import {getDifferenceBetweenTwoDatesInSeconds} from '../../../../../helpers/kluh';
import {BaseServer, ServerType} from '../../../../../models';
import {StompConnectionStatus} from '../../../../../ws/stomp-connection-status.enum';
import {MonitoringStompService} from '../../../websocket-stomp/monitoring-stomp.service';
import {Observable} from 'rxjs/internal/Observable';

@Component({
    selector: 'app-server-cpu-men-hd',
    templateUrl: './server-cpu-men-hd.component.html',
    styleUrls: ['./server-cpu-men-hd.component.scss']
})
export class ServerCpuMenHdComponent implements OnInit, OnDestroy {


    @Input()
    baseServer: BaseServer;

    @Input()
    connectionStatus$: Observable<StompConnectionStatus>;

    @Input()
    serverType: ServerType;

    private subServerOverView: Subscription;
    serverOverview: ServerOverview;
    statusOverview = false;
    metricType: MetricType = null;
    metricId = 0;
    public panelOpenState = false;

    constructor(public webStompService: MonitoringStompService) {
    }

    ngOnInit(): void {
        this.serverOverview = {
            cpu: -1,
            memory: -1,
            hdList: [],
            createdAt: new Date(Date.now() - 5 * 60000)
        };
        this.metricId = this.baseServer.id;
        if (this.serverType === ServerType.LINUX) {
            this.metricType = MetricType.LINUX_SERVER;
        } else if (this.serverType === ServerType.WINDOWS) {
            this.metricType = MetricType.WINDOWS_SERVER;
        }
        this.getServerOverview();
        this.checkStatusOverview(this.serverOverview);
    }

    getServerOverview(): void {
        this.subServerOverView = this.webStompService.stompSubscribe<ServerOverview[]>('/topic/server-overview.' + this.serverType + '.' + this.metricId)
            .subscribe((serverOverviewList) => {
                if (serverOverviewList) {
                    this.statusOverview = this.checkStatusOverview(serverOverviewList[0]);
                    this.serverOverviewHandler(serverOverviewList);
                }
            });
    }

    checkStatusOverview(serverOverview: ServerOverview): boolean {
        if (serverOverview && serverOverview.createdAt) {
            const date = new Date(serverOverview.createdAt.toString());
            const diffSeconds = getDifferenceBetweenTwoDatesInSeconds(date, new Date(Date.now()));
            // console.log('statusOverview diffSeconds: ' + diffSeconds + ' | ' + (diffSeconds <= (60 * 3)));
            return diffSeconds <= (60 * 3);
        }
        return false;
    }

    serverOverviewHandler(serverOverviewList: ServerOverview[]): void {
        for (const serverOverview of serverOverviewList) {
            if (serverOverview.cpu !== -1) {
                this.serverOverview.cpu = serverOverview.cpu;
                if (this.serverOverview.cpu > 100) {
                    this.serverOverview.cpu = 100;
                }
            } else if (serverOverview.memory !== -1) {
                this.serverOverview.memory = serverOverview.memory;
            } else if (serverOverview.hdList.length > 0) {
                this.serverOverview.hdList = serverOverview.hdList;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.subServerOverView) {
            this.subServerOverView.unsubscribe();
        }
    }
}

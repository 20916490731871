import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SessionViewComponent} from '../session-view.component';
import {fromKeyboardEvent} from './CustomKeyboardEvent';
import {KeyCodes} from '../../ts/enum/key-codes';

@Component({
    selector: 'app-clip-text-box',
    templateUrl: './clip-text-box.component.html',
    styleUrls: ['./clip-text-box.component.scss']
})
export class ClipTextBoxComponent implements OnInit, OnDestroy {
    textBox: HTMLTextAreaElement;

    private sessionViewComponent: SessionViewComponent;
    private h = !0;
    private i = !1;

    constructor() {
    }

    @Input()
    set sessionView(sessionView: SessionViewComponent) {
        this.textBox = document.getElementById('hiddenClipboardBox') as HTMLTextAreaElement;
        sessionView.textBox = this.textBox;
        this.sessionViewComponent = sessionView;
        this.sessionViewComponent.canvasContainer.addEventListener('click', this.k);
        this.textBox.onblur = () => this.sessionViewComponent.canvasLostFocus();

        this.textBox.onkeypress = (a) => {
            const event = fromKeyboardEvent(a);
            if (!this.sessionViewComponent.platformInfo.isMac) {
                if (this.sessionViewComponent.keyPress(event.which, event)) {
                    a.preventDefault();
                }
            }
        };

        this.textBox.onkeydown = (a) => {
            const event = fromKeyboardEvent(a);
            if (!this.sessionViewComponent.platformInfo.isMac) {

                const keyDown = this.sessionViewComponent.keyDown(event);
                if (keyDown) {
                    a.preventDefault();
                }
            } else {
                if (a.key === 'Dead') {
                    return;
                }

                if (a.code === 'KeyV' && (a.metaKey || a.ctrlKey)) {
                    navigator.clipboard.readText().then(clipText => {
                        const d = clipText || '';
                        this.sessionViewComponent.handlePasteEvent(d);
                        setTimeout(() => {
                            this.sessionViewComponent.session.keyDown(86);
                        }, 100);
                    });
                    return;
                }

                if (a.code === 'KeyC' && (a.metaKey || a.ctrlKey)) {
                    this.sessionViewComponent.session.keyDown(67);
                    setTimeout(() => {
                        if (!this.i && this.h) {
                            this.sessionViewComponent.sendCopyEvent();
                            this.h = !1;
                        } else {
                            this.i = !1;
                        }
                        this.j();
                    }, 100);
                    return;
                }
                if (a.code === 'KeyX' && (a.metaKey || a.ctrlKey)) {
                    this.sessionViewComponent.session.keyDown(88);
                    setTimeout(() => {
                        this.sessionViewComponent.sendCutEvent();
                        this.i = !0;
                    }, 100);
                    return;
                }

                if (a.metaKey && (a.key === 'Meta')) {
                    this.sessionViewComponent.session.keyDown(KeyCodes.Ctrl);
                    return;
                }

                const keyDown = this.sessionViewComponent.keyDown(event);
                if (keyDown) {
                    a.preventDefault();
                } else {
                    if (event.key.length === 1) {
                        const keyPress = this.sessionViewComponent.keyPress(event.key.codePointAt(0), event);
                        if (keyPress) {
                            a.preventDefault();
                        }
                    } else {
                        const keyPress = this.sessionViewComponent.keyPress(event.which, event);
                        if (keyPress) {
                            a.preventDefault();
                        }
                    }
                }
            }
        };
        this.textBox.onkeyup = (a) => {
            if (!this.sessionViewComponent.platformInfo.isMac) {
                const event = fromKeyboardEvent(a);
                this.sessionViewComponent.executeCopy();
                const keyUp = this.sessionViewComponent.keyUp(event);
                if (keyUp) {
                    a.preventDefault();
                }
            } else {
                if (a.key === 'Dead') {
                    return;
                }

                if (a.code === 'KeyV') {
                    this.sessionViewComponent.session.keyUp(86);
                    return;
                }
                if (a.code === 'KeyC') {
                    this.sessionViewComponent.session.keyUp(67);
                    return;
                }
                if (a.code === 'KeyX') {
                    this.sessionViewComponent.session.keyUp(88);
                    return;
                }


                if (a.key === 'Meta') {
                    this.sessionViewComponent.session.keyUp(KeyCodes.Ctrl);
                    return;
                }
                const event = fromKeyboardEvent(a);
                this.sessionViewComponent.executeCopy();
                const keyUp = this.sessionViewComponent.keyUp(event);
                if (keyUp) {
                    a.preventDefault();
                }
            }
        };
        this.textBox.oncut = () => {
            if (this.sessionViewComponent.platformInfo.isMac) {
                return;
            }
            this.sessionViewComponent.sendCutEvent();
            this.i = !0;
        };
        this.textBox.oncopy = () => {
            if (this.sessionViewComponent.platformInfo.isMac) {
                return;
            }
            !this.i && this.h ? (this.sessionViewComponent.sendCopyEvent(), this.h = !1) : this.i = !1, this.j();
        };
        this.textBox.onpaste = (a) => {
            if (this.sessionViewComponent.platformInfo.isMac) {
                return;
            }
            const c = a.clipboardData;
            const d = c.getData('text') || '';
            this.sessionViewComponent.handlePasteEvent(d);
            a.preventDefault();
        };
    }

    ngOnInit(): void {

    }

    private k = () => {
        this.textBox.focus();
    };

    private j = () => {
        return setTimeout(() => {
            this.h = !0;
        }, 550);
    };

    ngOnDestroy(): void {
        if (this.sessionViewComponent) {
            this.sessionViewComponent.canvasContainer.removeEventListener('click', this.k);
        }
    }


}

import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionStateStoreService implements OnDestroy {
    private subjects = {};

    constructor() {

    }

    next(sessionId: string, stateObject: StateObject): void {
        let subject: Subject<StateObject>;
        if (this.subjects.hasOwnProperty(sessionId)) {
            subject = this.subjects[sessionId];
        } else {
            console.warn('[SessionStateStoreService] no subject found for sessionId: ' + sessionId);
            return;
        }
        console.debug('[SessionStateStoreService] emitting state: ' + stateObject.state + ' sessionId: ' + sessionId);
        subject.next(stateObject);
    }

    subscribe(sessionId: string, callback: (state: StateObject) => void): Subscription {
        let subject: Subject<StateObject>;
        if (this.subjects.hasOwnProperty(sessionId)){
            subject = this.subjects[sessionId];
        } else {
            subject = new Subject<StateObject>();
            this.subjects[sessionId] = subject;
        }
        return subject.subscribe(callback);
    }

    ngOnDestroy(): void {
        for (const key in this.subjects){
            if (this.subjects.hasOwnProperty(key)){
                const subject: Subject<StateObject> = this.subjects[key];
                subject.complete();
            }
        }
    }
}

export interface StateObject {
    retryCount: number;
    state: string;
}

<div class="dialog-content-wrapper" *ngIf="marketplaceProduct">
    <form fxFlex [formGroup]="myForm"
          id="marketplace-product-contact-form"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Contato</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg mat-elevation-z2 " fxLayout="column" fxLayoutAlign="space-between stretch"
             matDialogContent>

            <div class="p-16">
                <div class="h1">{{marketplaceProduct.name}}</div>
                <div class="h4 secondary-text">{{marketplaceProduct.company}}</div>
            </div>

            <mat-form-field class="full-width" appearance="outline">
                <input matInput placeholder="E-mail"
                       id="marketplace-product-email"
                       name="email" formControlName="email">
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
                <input matInput placeholder="Celular" name="phone"
                       id="marketplace-product-phone"
                       formControlName="phone" mask="(00)-00000000000"  [validation]="false">
            </mat-form-field>

            <div style="padding: 20px 20px 20px 0px;">
                <mat-form-field class="width-100-percent" appearance="outline">
                    <mat-label>Detalhes e necessidades</mat-label>
                    <textarea rows="4" matInput formControlName="description"
                              id="marketplace-product-description"
                              name="description" ></textarea>
                </mat-form-field>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent"
                    id="marketplace-product-send"
                    [disabled]="(!myForm.valid || myForm.pristine)" >Enviar</button>
            <button type="button" mat-raised-button color="primary"
                    id="marketplace-product-cancel"
                    (click)="onCancel()">Cancelar</button>
        </div>
    </form>
</div>


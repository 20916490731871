import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseServer, ServerType} from '../../../../../models';
import {VpnMonitoringConfigurationComponent} from '../vpn-monitoring-configuration/vpn-monitoring-configuration.component';
import {filter} from 'rxjs/operators';
import {VpnMonitoringConfigurationDaoService} from '../vpn-monitoring-configuration/vpn-monitoring-configuration-dao.service';
import {VpnMonitoringStatusDaoService} from '../vpn-monitoring-status/vpn-monitoring-configuration-dao.service';
import {MatDialog} from '@angular/material/dialog';
import {VpnMonitoringConfiguration, VpnMonitoringStatus} from '../../../../../models-monitoring';
import {PointOptionsType} from 'highcharts';
import {Subject} from 'rxjs/internal/Subject';
import {Subscription} from 'rxjs';
import {getDifferenceBetweenTwoDatesInSeconds, sortListByName} from '../../../../../helpers/kluh';
import {Router} from '@angular/router';
import {CustomerGroupService} from '../../../../customer-group/customer-group.service';
import {MonitoringStompService} from '../../../websocket-stomp/monitoring-stomp.service';

@Component({
    selector: 'app-vpn-overview-accordion',
    templateUrl: './vpn-overview-accordion.component.html',
    styleUrls: ['./vpn-overview-accordion.component.scss']
})
export class VpnOverviewAccordionComponent implements OnInit, OnDestroy {

    // @Input()
    vpnMonitoringConfigurationList: VpnMonitoringConfiguration[];

    vpnMonitoringStatusList: VpnMonitoringStatus[];

    // @Input()
    // desktopServer$: Observable<DesktopServer>;
    //
    // desktopServer: DesktopServer;
    //
    // @Input()
    // linuxServer$: Observable<LinuxServer>;
    //
    // linuxServer: LinuxServer;


    @Input()
    serverType: ServerType;

    @Input()
    baseServer: BaseServer;

    timerUpdate = null;

    statusVpnMonitoring = true;

    private subscriptionData: Subscription;

    dataSubject = new Subject<PointOptionsType[]>();

    companyId: number;

    constructor(private dialog: MatDialog,
                private vpnDaoService: VpnMonitoringConfigurationDaoService,
                private webStompService: MonitoringStompService,
                private router: Router,
                public customerGroupService: CustomerGroupService,
                private vpnMonitoringStatusDaoService: VpnMonitoringStatusDaoService) {
    }

    ngOnInit(): void {
        if (this.baseServer) {
            this.loadAllData();
        }
        // this.desktopServer$?.subscribe((desktopServerFromDao) => {
        //     // console.log('1. vpnMonitoringStatusList');
        //     this.desktopServer = desktopServerFromDao;
        //     this.serverType = ServerType.WINDOWS;
        //     this.serverId = this.desktopServer.id;
        //     this.baseServer = this.desktopServer;
        //     this.loadAllData();
        // });
        // this.linuxServer$?.subscribe((linuxServerFromDao) => {
        //     // console.log('1. vpnMonitoringStatusList');
        //     this.linuxServer = linuxServerFromDao;
        //     this.serverType = ServerType.LINUX;
        //     this.serverId = this.linuxServer.id;
        //     this.baseServer = this.linuxServer;
        //     this.loadAllData();
        // });

    }

    loadAllData(): void {
        this.findAllVpnMonitoringConfigurationOfServerId();
        this.findAllVpnMonitoringStatusOfServerId();
        this.loadDataFromWebsocket();
    }

    loadDataFromWebsocket(): void {
        this.subscriptionData = this.webStompService.stompSubscribe<VpnMonitoringStatus[]>(
            '/topic/vpn-monitoring-status.' + this.serverType + '.' + this.baseServer.id)
            .subscribe((vpnMonitoringStatusList) => {
                if (vpnMonitoringStatusList) {
                    this.vpnMonitoringStatusList = vpnMonitoringStatusList;
                }
            });
    }


    findAllVpnMonitoringStatusOfServerId(): void {
        this.vpnMonitoringStatusDaoService.findAllByServerIdAndServerType(this.baseServer.id, this.serverType).subscribe(vpnMonitoringStatusList => {
            if (vpnMonitoringStatusList) {
                this.vpnMonitoringStatusList = vpnMonitoringStatusList;
            }
        });
    }

    getVpnMonitoringStatusOfVpnMonitoringConfiguration(vpnMonitoringConfigurationId: number): VpnMonitoringStatus {
        if (this.vpnMonitoringStatusList) {
            const vpnMonitoringStatus = this.vpnMonitoringStatusList.find(
                (x) => x?.vpnMonitoringConfigurationId === vpnMonitoringConfigurationId
            );
            if (vpnMonitoringStatus) {
                const diffSeconds = getDifferenceBetweenTwoDatesInSeconds(new Date(vpnMonitoringStatus.updatedAt.toString()), new Date(Date.now()));
                this.statusVpnMonitoring = diffSeconds <= (60 * 3);
            }
            return vpnMonitoringStatus;
        }
        return;
    }


    findAllVpnMonitoringConfigurationOfServerId(): void {
        this.vpnDaoService.findAllByServerIdAndServerType(this.baseServer.id, this.serverType).subscribe(vpnList => {
            if (vpnList) {
                this.vpnMonitoringConfigurationList = sortListByName(vpnList);
            }
        });
    }

    onEditVPN(baseServer: BaseServer, serverType: ServerType): void {
        const dialogRef = this.dialog.open(VpnMonitoringConfigurationComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                baseServer: baseServer,
                serverType: serverType
            }
        });
        dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((vpnList: VpnMonitoringConfiguration[]) => {
            if (vpnList) {
                this.vpnMonitoringConfigurationList = vpnList;
            }
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.timerUpdate);
        if (this.subscriptionData) {
            this.subscriptionData.unsubscribe();
        }
    }

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'todayDiff'
})
export class TodayDiffPipe implements PipeTransform {

    public static getDifferenceBetweenTwoDatesInDays(begin: Date, end: Date): number {
        const diffTime = Math.abs(end.getTime() - begin.getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    transform(value: any, ...args: any[]): any {
        return this.getDifferenceBetweenTodayAndADayInDays(value);
    }

    getDifferenceBetweenTodayAndADayInDays(begin: string): number {
        return TodayDiffPipe.getDifferenceBetweenTwoDatesInDays(new Date(begin), new Date(Date.now()));
    }


}

import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {SessionViewModel} from '../ts/session-view-model';
import {SessionProviderService} from '../session-provider.service';
import {SessionStoreService} from '../session-store.service';
import {RdpClientUIPropertiesService} from '../rdp-client-uiproperties.service';
import {LoggerService} from '../logger.service';
import {PlatformInfoService} from '../platform-info.service';
import {UserAuthInfoService} from '../user-auth-info.service';
import {Bookmark} from '../ts/bookmark';
import {BookmarkType} from '../ts/enum/bookmark-type';
import {SessionProviderEvent} from '../ts/enum/session-provider-event';
import {Redirections} from '../ts/redirections';
import {TelemetryService} from '../telemetry.service';
import {FeedData} from '../ts/feed-data';
import {Session} from '../ts/session';
import {RdpConnection} from '../ts/rdp-connection';
import {ObjectHelper} from '../ts/object-helper';
import {ADDomain, ADUserHtml5, DesktopServer, RemoteAppHtml5} from '../../models';
import {StompConnectionStatus} from '../../ws/stomp-connection-status.enum';
import {RemoteAppDaoService} from '../../main/remote-app/remote-app-dao.service';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {filter, first, switchMap, takeUntil, tap} from 'rxjs/operators';
import {AdUserDaoService} from '../../main/ad-user/ad-user-dao.service';
import {DesktopServerDaoService} from '../../main/desktop-server/desktop-server-dao.service';
import {AdDomainDaoService} from '../../main/ad-domain/ad-domain-dao.service';
import {CredentialRequest} from '../ts/credential-request';
import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {AppDrawerComponent} from './app-drawer/app-drawer.component';
import {SessionStateModalComponent} from './session-state-modal/session-state-modal.component';
import {SessionStateStoreService} from '../session-state-store.service';
import {locale as ptBr} from '../i18n/pt-br';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {R2CloudClientService} from '../../ws/r2-cloud-client.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import * as screenfull from 'screenfull';
import {Screenfull} from 'screenfull';
import {AESEncryptor} from '../ts/decrypt';
import {Subject} from 'rxjs/internal/Subject';
import {RemoteAppHtml5Service} from '../remote-app-html5.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {DownloadFileService} from '../../main/my-docs/download-file.service';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {DOCUMENT} from '@angular/common';
import {AdUserPoolDaoService} from '../../main/ad-user-pool/ad-user-pool-dao.service';


@Component({
    selector: 'app-session-view',
    templateUrl: './session-view.component.html',
    styleUrls: ['./session-view.component.scss']
})
export class SessionViewComponent extends SessionViewModel implements OnInit, OnDestroy {

    constructor(private router: Router,
                @Inject(DOCUMENT) private document: Document,
                elementRef: ElementRef,
                renderer: Renderer2,
                sessionProvider: SessionProviderService,
                sessionStore: SessionStoreService,
                rdpClientUIProperties: RdpClientUIPropertiesService,
                logger: LoggerService,
                platformInfo: PlatformInfoService,
                userAuthInfo: UserAuthInfoService,
                telemetry: TelemetryService,
                sessionStateStore: SessionStateStoreService,
                private stomp: R2CloudStompService,
                private remoteAppDao: RemoteAppDaoService,
                private adUserDao: AdUserDaoService,
                private adUserPoolDaoService: AdUserPoolDaoService,
                private desktopServerDao: DesktopServerDaoService,
                private adDomainDao: AdDomainDaoService,
                private fuseConfig: FuseConfigService,
                private dialog: MatDialog,
                private fuseTranslationLoaderService: FuseTranslationLoaderService,
                private clientService: R2CloudClientService,
                private remoteAppHtml5Service: RemoteAppHtml5Service,
                private downloadFileService: DownloadFileService) {
        super(sessionProvider, sessionStore, rdpClientUIProperties, logger, platformInfo, () => {
            return this.createCanvas();
        }, userAuthInfo, sessionStateStore);
        this.showR2CloudRobot = this.document.location.href.indexOf('r2cloud') > -1 || this.document.location.href.indexOf('kluh') > -1;

        window.addEventListener('click', this.resumeAudioContext);
        this.clientService.disable();
        this.fuseTranslationLoaderService.loadTranslations(ptBr);
        fuseConfig.setConfig({
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                }
            }
        });
        this.stompConnectionSubscription = this.stomp.connectionStatus$.pipe(takeUntil(this.destroy$)).subscribe((status: StompConnectionStatus) => {
            console.debug('desktop component onConnectionChange: ' + status);
            this.stompConnectionStatus = status;

            // coloca um timer no botão reconetar que pode aparecer na home
            if (status === StompConnectionStatus.DISCONNECTED && !this.showReconnectButton) {
                if (this.waitingTimerForButton === 10) {
                    console.debug('entrou reconnect onConnectionChange');
                    clearInterval(this.timerForButton);
                    this.timerForButton = setInterval(() => {
                        this.waitingTimerForButton--;
                        if (this.waitingTimerForButton < 1) {
                            clearInterval(this.timerForButton);
                            if (status === StompConnectionStatus.DISCONNECTED && !this.showReconnectButton) {
                                this.showReconnectButton = true;
                                console.debug('show button reconnect onConnectionChange ' + status);
                            }
                        }
                    }, 1000);
                }
            }
            if (status === StompConnectionStatus.OK) {
                this.encryptor = new AESEncryptor(this.stomp.webClientId);
                clearInterval(this.timerForButton);
                this.showReconnectButton = false;
                this.waitingTimerForButton = 10;
                console.debug('hide button reconnect onConnectionChange');
            }
        });


        this.remoteAppsHtml5Subscription = this.remoteAppHtml5Service.remoteAppChanges().subscribe((remoteAppHtml5) => {
            const index = this.remoteAppHtml5List.findIndex((value) => value.remoteAppId === remoteAppHtml5.remoteAppId);
            if (index > -1) {
                this.remoteAppHtml5List[index] = remoteAppHtml5;
            } else {
                this.remoteAppHtml5List.push(remoteAppHtml5);
            }
        });

        this.sessionProvider = sessionProvider;
        this.elementRef = elementRef;
        this.telemetry = telemetry;
        this.sessionView = this;
        this.renderer = renderer;
        this.initDelegation();

    }

    private renderer: Renderer2;
    private stompConnectionStatus: StompConnectionStatus;
    private showReconnectButton: boolean;
    private waitingTimerForButton = 10;
    private timerForButton: any;
    private encryptor: AESEncryptor;
    private remoteAppsHtml5Subscription: Subscription;
    private stompConnectionSubscription: Subscription;
    private remoteAppHtml5List: RemoteAppHtml5[] = [];
    private adUserList: ADUserHtml5[] = [];
    private destroy$ = new Subject();
    private desktopServerList: DesktopServer[] = [];
    private adDomainList: ADDomain[] = [];
    private sessionStateModalDict = {};
    showR2CloudRobot = false;
    private elementRef: ElementRef;
    private telemetry: TelemetryService;
    sessionView: any;
    objectHelper = new ObjectHelper();
    loading = true;
    @Output()
    displayImageBackground = new BehaviorSubject(true);

    private i = {
        width: null,
        height: null
    };

    openAppDrawerDebounced = _.debounce(() => {
        this.loading = false;
        this.openAppDrawer();
    }, 2000);

    onResized = _.debounce((document: Document) => {
        const canvasContainer = document.getElementById('canvas-container');
        const width = canvasContainer.offsetWidth;
        const height = canvasContainer.offsetHeight;
        if (this.i.width !== width || this.i.height !== height) {
            const connections = this.sessionProvider.connectionList(); // Atualize sua lógica aqui
            connections.forEach(conn => conn.updateMonitorLayout());
        }

        this.i.width = width;
        this.i.height = height;
    }, 300);

    resumeAudioContext = () => {
        console.debug('resumeAudioContext');
        if (this.sessionProvider.audioContext) {
            if (this.sessionProvider.audioContext.state === 'suspended') {
                this.sessionProvider.audioContext.resume().then(() => {
                    console.debug('audioContext resume');
                    window.removeEventListener('click', this.resumeAudioContext);
                });
            } else {
                window.removeEventListener('click', this.resumeAudioContext);
            }
        }
    };

    onRedirectionsRequest = (a) => {
        if (a) {
            const g = new Redirections();
            g.clipboard = true;
            g.printer = true;
            g.rememberCollection = false;
            a.complete(g);
        }
    };

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.onResized(document);
    }

    private updateDimensions(): void {
        this.i.width = this.elementRef.nativeElement.offsetWidth;
        this.i.height = this.elementRef.nativeElement.offsetHeight;
    }

    private initDelegation(): void {
        this.delegate.onCredentialsNeeded = (a: CredentialRequest, b: RdpConnection): void => {
            if (a) {
                const remoteAppId = b.remoteAppId;
                const remoteApp = this.remoteAppHtml5List.find((x) => {
                    return x.remoteAppId === remoteAppId;
                });
                let desktopServer = this.desktopServerList.find((x) => {
                    return x.id === remoteApp.desktopServerId;
                });
                if (desktopServer == null) {
                    desktopServer = this.desktopServerList[0];
                }
                const adDomain = this.adDomainList.find((x) => {
                    return desktopServer.adDomainId === x.id;
                });
                const adUser = this.adUserList.find((x) => {
                    return x.adDomainId === adDomain.id;
                });
                if (adUser && adDomain) {
                    this.encryptor.decrypt(adUser.login, adDomain.domainName, adUser.password, a.getOriginal());
                }
            }
        };
        this.delegate.onTrustChallenge = (a): void => {
            if (a) {
                a.complete(1);
            }
        };
        this.sessionProvider.events.subscribe(SessionProviderEvent.RedirectionsRequest, this.onRedirectionsRequest);
        this.delegate.openLoaderModal = (sessionId: string, connectionId: string, stateDict: any, sessionLabel: string) => {
            this.sessionStateModalDict[sessionId] = this.dialog.open(SessionStateModalComponent, {
                disableClose: true,
                panelClass: 'session-state-modal',
                data: {
                    sessionId: sessionId,
                    connectionId: connectionId,
                }
            });
        };
        this.delegate.closeLoaderModal = (sessionId: string) => {
            this.displayImageBackground.next(false);
            const modal: MatDialogRef<SessionStateModalComponent> = this.sessionStateModalDict[sessionId];
            if (modal) {
                modal.close();
            }
        };
        this.delegate.allSessionsDisconnected = () => {
            console.debug('allSessionsDisconnected');
            const canvasContainer = document.getElementById('canvas-container') as HTMLDivElement;
            if (canvasContainer) {
                canvasContainer.childNodes.forEach((node: HTMLElement) => {
                    node.style.display = 'none';
                });
                this.openAppDrawerDebounced();
            }
            this.displayImageBackground.next(true);
        };
        const feedData = new FeedData();
        feedData.loading = false;
        this.telemetry.onFeedsLoaded(feedData);
    }

    createCanvas(): HTMLCanvasElement {
        const canvasContainer = document.getElementById('canvas-container') as HTMLDivElement;
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.id = 'shown-canvas-' + new ObjectHelper().createGuid();
        this.renderer.appendChild(canvasContainer, canvas);
        canvas.onmousedown = (b) => {
            this.mouseDown(b.pageX, b.pageY, b.which), b.preventDefault();
        };
        canvas.onmouseup = (b) => {
            this.mouseUp(b.pageX, b.pageY, b.which), b.preventDefault();
        };
        canvas.onmousemove = (b) => {
            this.mouseMove(b.pageX, b.pageY), b.preventDefault();
        };
        canvas.onmouseleave = (b) => this.mouseLeave(b.pageX, b.pageY);
        canvas.onmouseenter = () => this.mouseEnter();
        canvas.onwheel = (b: WheelEvent) => {
            this.mouseWheel(-b.deltaX, -b.deltaY), b.preventDefault();
        };
        canvas.oncontextmenu = (a) => a.preventDefault();
        canvas.ontouchstart = (b: TouchEvent) => this.touchStart(b.changedTouches);
        canvas.ontouchmove = (b) => this.touchMove(b.changedTouches);
        canvas.ontouchend = (b) => this.touchEnd(b.changedTouches);
        canvas.ontouchcancel = (b) => this.touchCancel(b.changedTouches);
        return canvas;
    }

    ngOnInit(): void {
        this.init();
        this.openAppDrawerDebounced();
        this.updateDimensions();
    }

    ngOnDestroy(): void {
        this.downloadFileService.stompUnsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.sessionProvider.events.unsubscribe(SessionProviderEvent.RedirectionsRequest, this.onRedirectionsRequest);
        window.removeEventListener('click', this.resumeAudioContext);
        this.clientService.enable();
        this.fuseConfig.setConfig({
            layout: {
                navbar: {
                    hidden: false
                },
                toolbar: {
                    hidden: false
                }
            }
        });
        this.stompConnectionSubscription.unsubscribe();
        this.remoteAppsHtml5Subscription.unsubscribe();
        this.disconnectAllSessions();
        this.openAppDrawerDebounced.cancel();
    }

    onRemoteAppHtml5Open(remoteAppHtml5: RemoteAppHtml5): void {
        const adUserHtml5 = this.adUserList.find((x) => {
            return x.adUserId === remoteAppHtml5.adUserId;
        });
        const foundDesktopServer = this.desktopServerList.find((x) => {
            return x.id === remoteAppHtml5.desktopServerId;
        });
        let desktopServer$: Observable<DesktopServer>;
        let adUserHtml5$: Observable<ADUserHtml5>;
        if (adUserHtml5) {
            adUserHtml5$ = of(adUserHtml5);
        } else {
            if (remoteAppHtml5.poolUser) {
                adUserHtml5$ = this.adUserPoolDaoService.getOneHtml5(remoteAppHtml5.adUserId).pipe(tap((result) => {
                    if (result) {
                        this.adUserList.push(result);
                    }
                }));
            } else {
                adUserHtml5$ = this.adUserDao.getOneHtml5(remoteAppHtml5.adUserId).pipe(tap((result) => {
                    if (result) {
                        this.adUserList.push(result);
                    }
                }));
            }
        }
        if (foundDesktopServer && !foundDesktopServer.desktopServerPoolId) {
            desktopServer$ = of(foundDesktopServer);
        } else {
            desktopServer$ = this.desktopServerDao.getRandomDesktopServerByDesktopServerId(remoteAppHtml5.desktopServerId, remoteAppHtml5.remoteAppId).pipe(tap((result) => {
                this.desktopServerList.push(result);
            }));
        }
        forkJoin([adUserHtml5$, desktopServer$]).pipe(switchMap((result) => {
            const adDomainId = result[1].adDomainId;
            const adDomain = this.adDomainList.find((x) => {
                return x.id === adDomainId;
            });
            let adDomain$: Observable<ADDomain>;
            if (adDomain) {
                adDomain$ = of(adDomain);
            } else {
                adDomain$ = this.adDomainDao.getOne(adDomainId).pipe(tap((result2) => {
                    this.adDomainList.push(result2);
                }));
            }
            return forkJoin([of(result[0]), of(result[1]), adDomain$]);
        }), takeUntil(this.destroy$)).subscribe((result: [ADUserHtml5, DesktopServer, ADDomain]) => {
            // const adUser: ADUserHtml5 = result[0];
            const desktopServer: DesktopServer = result[1];
            const adDomain: ADDomain = result[2];
            this.processClick(remoteAppHtml5, desktopServer, adDomain);
            this.remoteAppDao.markAsOpenInHTML5(remoteAppHtml5).subscribe(() => {
            });
        });
    }

    onSessionClick(session: Session): void {
        this.focusToSession(session.id);
    }

    private processClick(remoteAppHtml5: RemoteAppHtml5, desktopServer: DesktopServer, adDomain: ADDomain): void {
        if (!desktopServer.gatewayFQDN) {
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Esse servidor não tem <b>Gateway FQDN</b> cadastrado.<br />Entre em contato com o suporte.',
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe(() => {
                this.openAppDrawer();
            });

        } else {
            let fullDesktopServerAddress = desktopServer.hostname.toLowerCase() + '.' + adDomain.domainName.toLowerCase();
            if (desktopServer.desktopServerPoolId && desktopServer.disposable && desktopServer.adIp) {
                fullDesktopServerAddress = desktopServer.adIp;
            }
            const bookmark = new Bookmark();
            bookmark.id = this.objectHelper.createGuid();
            bookmark.remoteAppId = remoteAppHtml5.remoteAppId;
            bookmark.adminMode = false;
            bookmark.bookmarkType = BookmarkType.RemoteApp;
            bookmark.port = desktopServer.internalPort || 3389;
            bookmark.friendlyName = remoteAppHtml5.remoteAppId;
            bookmark.imageFileId = remoteAppHtml5.imageUUID;
            bookmark.workspaceName = fullDesktopServerAddress;
            bookmark.rdpFileString = `redirectclipboard:i:1
redirectprinters:i:1
devicestoredirect:s:*
session bpp:i:32
remoteapplicationmode:i:1
allow font smoothing:i:1
promptcredentialonce:i:1
gatewayusagemethod:i:1
gatewayprofileusagemethod:i:1
gatewaycredentialssource:i:0
full address:s:${fullDesktopServerAddress}
server port:i:${bookmark.port}
remoteapplicationprogram:s:${remoteAppHtml5.app}
gatewayhostname:s:${desktopServer.gatewayFQDN}
remoteapplicationname:s:${remoteAppHtml5.remoteAppId}
remoteapplicationcmdline:s:${remoteAppHtml5.appArgs || ''}
workspace id:s:${fullDesktopServerAddress}
use redirection server name:i:1`;
            this.sessionProvider.createSession(bookmark, null, remoteAppHtml5.remoteAppId);
        }


    }

    openAppDrawer(): void {
        const dialogRef: MatDialogRef<AppDrawerComponent> = this.dialog.open(AppDrawerComponent, {
            disableClose: false,
            panelClass: 'generic-transparent-dialog-large',
        });
        dialogRef.afterClosed().pipe(first(), filter((x) => !!(x)), takeUntil(this.destroy$)).subscribe((remoteAppHtml5: RemoteAppHtml5) => {
            this.onRemoteAppHtml5Open(remoteAppHtml5);
        });
    }

    getSessionsStore(): any {
        return this.sessionList;
    }

    goToHome(): void {
        if (this.isFullscreen()) {
            this.exitFullscreen();
        }
        window.location.href = '/desktop';
    }

    isFullscreen(): boolean {
        return (<Screenfull>screenfull).isFullscreen;
    }

    goFullscreen(): void {
        if ((<Screenfull>screenfull).isEnabled) {
            (<Screenfull>screenfull).request();
        }
    }

    exitFullscreen(): void {
        (<Screenfull>screenfull).exit();
    }
}

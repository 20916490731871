import {CanvasGraphicsSink} from './canvas-graphics-sink';
import {Logger} from './logger';

export class CanvasGraphicsSinkFactory {
    constructor(private logger: Logger){

    }

    createGraphicsSink(graphicsSinkDescriptor: any, focus: boolean): CanvasGraphicsSink {
        return new CanvasGraphicsSink(graphicsSinkDescriptor, focus, this.logger);
    }
}

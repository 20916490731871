import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {DesktopServer, FirewallFQDN, LinuxServer} from '../../models';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {FirewallFqdnDaoService} from './firewall-fqdn-dao.service';
import {findDuplicateValueInArray, isValidIpOrFQDNInArray} from '../../utils/utils-kluh';

@Component({
    selector: 'app-firewall-fqdn',
    templateUrl: './firewall-fqdn.component.html',
    styleUrls: ['./firewall-fqdn.component.scss'],
    queries: {
        'firewallFQDNContentRef': new ViewChild('firewallFQDNContentRef', {static: false})
    }
})
export class FirewallFqdnComponent extends ComponentCleaner implements AfterViewChecked {
    desktopServer: DesktopServer;
    linuxServer: LinuxServer;
    firewallFQDNList: FirewallFQDN[] = [];
    myForm: FormGroup;
    myFormArray: FormArray;
    firewallFQDNContentRef!: ElementRef;
    myFormControls: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<FirewallFqdnComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private firewallFqdnDaoService: FirewallFqdnDaoService
    ) {
        super();

        this.desktopServer = data.desktopServer;
        this.linuxServer = data.linuxServer;
        this.firewallFQDNList = data.firewallFQDNList;

        this.myFormArray = this.fb.array([]);
        this.myForm = this.fb.group({myFormArray: this.myFormArray});

        this.myForm.setValidators([
            uniqueFQDNValueOfFormArrayOfFormGroupValidator({fqdn: 'IP ou FQDN Duplicado'}, 'myFormArray'),
            validatorIpOrFQDN({fqdn: 'IP ou FQDN Inválido'}, 'myFormArray')
        ]);

        this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;

        this.firewallFQDNList.forEach(firewallFQDN => {
            if (firewallFQDN) {
                this.onAddFirewallFQDN(firewallFQDN);
            }
        });

    }


    buildVpn(firewallFQDN?: FirewallFQDN): FormGroup {
        let firewallFQDNObj = {
            id: null, fqdn: '', active: true, comment: null, modified: null, optlock: null, desktopServerId: this.desktopServer?.id, linuxServerId: this.linuxServer?.id
        };
        if (firewallFQDN) {
            firewallFQDNObj = firewallFQDN;
        }
        return this.fb.group(firewallFQDNObj);
    }

    onCancel(): void {
        this.dialogRef.close(this.data.firewallFQDNList);
    }

    onAddFirewallFQDN(firewallFQDN?: FirewallFQDN): void {
        this.myFormArray.push(this.buildVpn(firewallFQDN));
        setTimeout(() => {
            this.firewallFQDNContentRef.nativeElement.scrollTo(0, this.firewallFQDNContentRef.nativeElement.scrollHeight);
        }, 50);
    }

    onDelete(firewallFQDN: FirewallFQDN, indexFormArray: number): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Tem certeza que deseja remover esse IP/FQDN da lista? <br>' +
                    '<h2 class="warn-A700-fg">' + firewallFQDN.fqdn + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                if (firewallFQDN?.id) {
                    this.firewallFqdnDaoService.remove(firewallFQDN.id).subscribe((ip) => {
                        this.myFormArray.removeAt(indexFormArray);
                        this.dialogRef.close(this.myForm.value.myFormArray);
                    });
                } else {
                    this.myFormArray.removeAt(indexFormArray);
                }
            }
        });
        this.addSubscription(subscription);

    }

    onSubmit(): void {
        const firewallFQDNList = this.myForm.value.myFormArray;
        const firewallFQDNCreateList = [];
        const firewallFQDNSaveList = [];


        for (const firewallFQDN of firewallFQDNList) {
            if (firewallFQDN.id) {
                firewallFQDNSaveList.push(firewallFQDN);
            } else {
                firewallFQDNCreateList.push(firewallFQDN);
            }
        }
        let firewallFQDNSave$: Observable<FirewallFQDN[]>;
        if (!firewallFQDNSaveList || firewallFQDNSaveList.length < 1) {
            firewallFQDNSave$ = of([]);
        } else {
            firewallFQDNSave$ = this.firewallFqdnDaoService.saveAll(firewallFQDNSaveList);
        }
        let firewallFQDNCreate$: Observable<FirewallFQDN[]>;
        if (!firewallFQDNCreateList || firewallFQDNCreateList.length < 1) {
            firewallFQDNCreate$ = of([]);
        } else {
            firewallFQDNCreate$ = this.firewallFqdnDaoService.createAll(firewallFQDNCreateList);
        }
        forkJoin([firewallFQDNCreate$, firewallFQDNSave$]).subscribe((values) => {
            const allFirewallFQDN = [];
            const firewallFQDNCreatedList = values[0];
            const firewallFQDNSavedList = values[1];
            if (firewallFQDNCreatedList) {
                for (const firewallFQDN of firewallFQDNCreatedList) {
                    allFirewallFQDN.push(firewallFQDN);
                }
            }
            if (firewallFQDNSavedList) {
                for (const firewallFQDN of firewallFQDNSavedList) {
                    allFirewallFQDN.push(firewallFQDN);
                }
            }
            this.dialogRef.close(allFirewallFQDN);
        });
    }


    getControlsOfFormGroup(firewallFQDN: AbstractControl): FormGroup {
        return firewallFQDN as FormGroup;
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }
}


export function uniqueFQDNValueOfFormArrayOfFormGroupValidator(fieldError: any, formArrayName: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const myFormArray: FormArray = group.controls[formArrayName] as FormArray;
        const allIPs: string[] = myFormArray.controls.map(data => data.value).map(data => data.fqdn);

        const formControlsDuplicate: FormGroup[] = (myFormArray.controls.filter(data => data.value)
            .filter(data => findDuplicateValueInArray(allIPs)
                .includes(data.value.fqdn))) as FormGroup[];

        if (formControlsDuplicate.length > 0) {
            const lastFormGroup: FormGroup = formControlsDuplicate[formControlsDuplicate.length - 1];
            lastFormGroup.controls.fqdn.setErrors(fieldError);
        }
        return;
    };
}

export function validatorIpOrFQDN(fieldError: any, formArrayName: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const myFormArray: FormArray = group.controls[formArrayName] as FormArray;
        const allIPs: string[] = myFormArray.controls.map(data => data.value).map(data => data.fqdn);

        const formControlsInvalidInput: FormGroup[] = (myFormArray.controls.filter(data => data.value)
            .filter(data => isValidIpOrFQDNInArray(allIPs)
                .includes(data.value.fqdn))) as FormGroup[];

        if (formControlsInvalidInput.length > 0) {
            const lastFormGroup: FormGroup = formControlsInvalidInput[formControlsInvalidInput.length - 1];
            lastFormGroup.controls.fqdn.setErrors(fieldError);
        }
        return;
    };
}




<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>AdUsers (Changing ou Deleting)</div>
            <button *ngIf="!loading" mat-raised-button type="button" color="primary" class="md-small" (click)="getData()">
                <span *ngIf="!alreadyRead">Show</span>
                <span *ngIf="alreadyRead">Update</span>
            </button>
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th></th>
                <th class="w-115"></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let adUser of adUsersPendingOrDeleting">
                <td>
                    <div *ngIf="statusServer.getAdUser(adUser.id, adUsers) as adUser">
                        <div><b>{{adUser.login}}</b></div>
                        <div class="font-size-12">
                            domínio: {{statusServer.getAdDomain(adUser?.adDomainId, adDomains)?.name}}</div>
                        <div class="font-size-12">{{adUser.updatedAt}}</div>
                        <div>
                            <div
                                *ngIf="statusServer.getAdUserByLogin(adUser.login,adUsers) && adUser.subProjectIds">
                                <div *ngFor="let subProjectId of adUser.subProjectIds">
                                    <ul *ngIf="statusServer.getSubProject(subProjectId, subProjects) as subProject">
                                        <li>
                                            ID:<b> {{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.id}} </b>
                                        </li>
                                        <li>Empresa:
                                            <a [routerLink]="['/admin/']" class="mouse-pointer"
                                               [queryParams]="{id: statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.id }">
                                                {{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.name}}
                                            </a>
                                        </li>
                                        <li>
                                            subProject:<b> {{subProject?.name}} </b>
                                        </li>
                                        <li>ManagerUser:
                                            <b> {{statusServer.getManagerUser(adUser?.managerUserId, managerUsers)?.email}} </b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    {{adUser.updatedAt | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
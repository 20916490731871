import {Injectable} from '@angular/core';
import {SessionProvider} from './ts/session-provider';
import {ConnectionFactory} from './ts/connection-factory';
import {ConnectionFactoryType} from './ts/enum/connection-factory-type';
import {SessionFactory} from './ts/session-factory';
import {TrustedCollectionsService} from './trusted-collections.service';
import {LoggerService} from './logger.service';
import {PrintingService} from './printing.service';
import {PlatformInfoService} from './platform-info.service';

import {SessionStoreService} from './session-store.service';
import {TelemetryService} from './telemetry.service';
// @ts-ignore
import * as strings from './i18n/pt-BR/strings.json';

declare const Module;

@Injectable({
    providedIn: 'root'
})
export class SessionProviderService extends SessionProvider {
    public audioContext: AudioContext;

    constructor(sessionStore: SessionStoreService,
                trustedCollections: TrustedCollectionsService,
                logger: LoggerService,
                printing: PrintingService,
                platformInfo: PlatformInfoService,
                telemetry: TelemetryService) {
        try {
            Module.Tracing.Register((a, b, e, f) => {
                const g = this.getConnection(f);
                g && telemetry.onRdCoreTelemetryEvent(g, a, b);
            }, (a) => {
                logger.logWithoutTimestamp(a);
            }, (a) => {
                logger.warnWithoutTimestamp(a);
            }, (a) => {
                logger.errorWithoutTimestamp(a);
            });
        } catch (a) {
            logger.log('Tried to register telemetry collect trace function with RdCore but failed');
        }

        const rdcorelib = document.getElementById('rdcorelib') as HTMLScriptElement;
        rdcorelib && (Module.SetWebWorkerBasePath(''), Module.SetWebWorkerLibName(rdcorelib.src));
        // @ts-ignore
        Module.UIStrings.RegisterTranslationTable(strings.default);
        const k = null;
        // const logger = new Logger();
        const rdDiagnostics = null;
        let audioContext;
        try {
            // @ts-ignore
            audioContext = new(window.AudioContext || window.webkitAudioContext);
        } catch (a) {
            logger.warn('Failed to create audio context');
        }
        const l = new ConnectionFactory(ConnectionFactoryType.RdpConnection, logger, audioContext, telemetry, printing, platformInfo, rdDiagnostics, k),
            m = new SessionFactory(l,
                logger,
                // rdFeedTracker,
                trustedCollections);
        super(m, l, sessionStore, null, null, logger);
        this.audioContext = audioContext;
    }
}

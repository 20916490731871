import {Injectable} from '@angular/core';
import {ADDomain, ADGroup, ADUser, Customer, CustomerGroup, DesktopServerWithSubProject, ManagerUser, RemoteApp, SubProject} from '../../models';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    constructor(private router: Router) {
    }

    redirectToR2cloud(subProject: SubProject): void {
        this.router.navigate(['/admin'], {
            queryParams: {
                id: subProject.customerGroupId,
                subProjectId: subProject.id,
                projectId: subProject.projectId
            }
        });
    }

    getSubProject(subProjectId: number, subProjects: SubProject[]): SubProject {
        if (subProjects) {
            return subProjects.find((subProject) => {
                return subProject.id === subProjectId;
            });
        }
        return null;
    }

    getAdDomain(adDomainId: number, adDomains: ADDomain[]): ADDomain {
        if (adDomains) {
            return adDomains.find((adDomain) => {
                return adDomain.id === adDomainId;
            });
        }
        return null;
    }


    getAdUser(adUserId: number, adUsers: ADUser[]): ADUser {
        let adUser: ADUser;
        if (adUsers) {
            const index = adUsers.findIndex((o) => o.id === adUserId);
            if (index > -1) {
                adUser = adUsers[index];
            }
        }
        return adUser;
    }


    getAdUserByLogin(adUserLogin: string, adUsers: ADUser[]): ADUser {
        let adUser: ADUser;
        if (adUsers) {
            const index = adUsers.findIndex((o) => o.login === adUserLogin);
            if (index > -1) {
                adUser = adUsers[index];
            }
        }
        return adUser;
    }


    getCustomerGroup(customerGroupId: number, customerGroups: CustomerGroup[]): CustomerGroup {
        let customerGroup: CustomerGroup;
        if (customerGroups) {
            const index = customerGroups.findIndex((o) => o.id === customerGroupId);
            if (index > -1) {
                customerGroup = customerGroups[index];
            }
        }
        return customerGroup;
    }


    getManagerUser(managerUserId: number, managerUsers: ManagerUser[]): ManagerUser {
        let managerUser: ManagerUser;
        if (managerUsers) {
            const index = managerUsers.findIndex((o) => o.id === managerUserId);
            if (index > -1) {
                managerUser = managerUsers[index];
            }
        }
        return managerUser;
    }


    getAdGroup(adGroupId: number, adGroups: ADGroup[]): ADGroup {
        let adGroup: ADGroup;
        if (adGroups) {
            const index = adGroups.findIndex((o) => o.id === adGroupId);
            if (index > -1) {
                adGroup = adGroups[index];
            }
        }
        return adGroup;
    }


    getRemoteApp(remoteAppId: number, remoteApps: RemoteApp[]): RemoteApp {
        let remoteApp: RemoteApp;
        if (remoteApps) {
            const index = remoteApps.findIndex((o) => o.id === remoteAppId);
            if (index > -1) {
                remoteApp = remoteApps[index];
            }
        }
        return remoteApp;
    }

    getCustomer(customerId: number, customers: Customer[]): Customer {
        let customer: Customer;
        if (customers) {
            const index = customers.findIndex((o) => o.id === customerId);
            if (index > -1) {
                customer = customers[index];
            }
        }
        return customer;
    }

    getDesktopServer(desktopServerId: number, desktopServerWithSubProjectIdsList: DesktopServerWithSubProject[]): DesktopServerWithSubProject {
        let desktopServer: DesktopServerWithSubProject;
        if (desktopServerWithSubProjectIdsList) {
            const index = desktopServerWithSubProjectIdsList.findIndex((o) => o.desktopServerDTO.id === desktopServerId);
            if (index > -1) {
                desktopServer = desktopServerWithSubProjectIdsList[index];
            }
        }
        return desktopServer;
    }

}

<ng-container *ngIf="customerGroup?.id">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Projetos</div>
                <div>
                    <button
                        *appIfPermission="appCanCreateList"
                        type="button" mat-raised-button color="accent"
                        (click)="onCreateSubProject()" id="r2-cloud-admin-sub-project-create-project">Criar
                    </button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div *ngIf="subProjects && subProjects.length > 1">
                <mat-form-field class="projects" appearance="outline">
                    <mat-label>Projeto</mat-label>
                    <mat-select placeholder="Projetos" [formControl]="subProjectFilterControl">
                        <mat-option *ngFor="let subProject of subProjects" [value]="subProject">
                            {{subProject.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div *ngIf="subProjectFilterControl.value.id">
                    <button *appIfPermission="'WRITE'; data: subProjectFilterControl.value; type: 'SubProject'"
                            type="button"
                            mat-raised-button color="accent"
                            id="r2-cloud-sub-project-edit"
                            (click)="onEditSubProject(subProjectFilterControl.value)">Edit
                    </button>
                </div>

                <div *ngIf="sharedProjects?.length > 0">
                    <h3>
                        Projeto Compartilhado
                    </h3>
                    <div *ngIf="!projectSharedInfo" class="w-300">
                        <button type="button" *ngIf="!projectSharedInfoShow"
                                mat-raised-button
                                (click)="findProjectForProjectSharedInfoInProjectList()">Mostrar
                        </button>
                        <mat-spinner *ngIf="projectSharedInfoShow" [diameter]="24" color="primary"></mat-spinner>
                    </div>
                    <div *ngIf="projectSharedInfo">
                        <table>
                            <tr *ngIf="projectSharedInfo.projectName">
                                <td>Compartilhamento</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.projectName}}</b></td>
                            </tr>
                            <tr *ngIf="projectSharedInfo.subProjectCount">
                                <td>
                                    Nº de projetos
                                </td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.subProjectCount}}</b></td>
                            </tr>
                            <tr *ngIf="projectSharedInfo.adUserCount">
                                <td>Total de usuários</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.adUserCount + projectSharedInfo.adUserPoolCount}}</b></td>
                            </tr>
                            <tr *ngIf="projectSharedInfo.adUserCountUnique">
                                <td>Total de usuários únicos</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.adUserCountUnique + projectSharedInfo.adUserPoolCountUnique}}</b></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>


        </mat-card-content>
    </mat-card>
</ng-container>
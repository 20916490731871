<div fusePerfectScrollbar class="monitoring-details page-layout simple fullwidth">
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row" *ngIf="!hasMonitoringPlugin && !loading &&!linuxServer">
                <h1>Servidor sem o plugin de monitoramento</h1>
            </div>
            <div class="row" *ngIf="hasMonitoringPlugin || linuxServer">
                <div class="width-100-percent">
                    <div class="monitoring-card mb-20 float-left pr-5">
                        <mat-card class="width-100-percent">
                            <mat-card-header>
                                <mat-card-title>
                                    Server
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="start center"
                                     style="margin-left: 20px;margin-bottom: 25px;">
                                    <div>
                                        <small class="mr-10">
                                            <fa-icon
                                                [ngClass]="{
                                                     'client-connected': connectionStatus === StompConnectionStatus.OK,
                                                     'client-not-connected': connectionStatus === (
                                                            StompConnectionStatus.DISCONNECTED ||
                                                            StompConnectionStatus.CONNECTION_ENDED ||
                                                            StompConnectionStatus.DESTROYED ||
                                                            StompConnectionStatus.DUPLICATE_LOGIN
                                                     )
                                                     }"
                                                [icon]="faCircle"></fa-icon>
                                        </small>
                                        Monitoramento
                                    </div>
                                    <div style="margin-left: 80px;">
                                        <small class="mr-10">
                                            <fa-icon
                                                [ngClass]="{
                                                     'client-connected': agentServerConnectionStatus === ServerStatusType.CONNECTED,
                                                     'client-not-connected': agentServerConnectionStatus !== ServerStatusType.CONNECTED
                                                     }"
                                                [icon]="faCircle"></fa-icon>
                                        </small>
                                        Agente servidor
                                    </div>
                                </div>
                                <div *ngIf="baseServer">
                                    <app-server-overview-accordion
                                        [baseServer]="baseServer"
                                        [serverType]="serverType"></app-server-overview-accordion>

                                    <div *ngIf="serverType === ServerType.LINUX && linuxServer">
                                        <button type="button" mat-raised-button color="primary" class="mt-25 mb-0 w-100"
                                                (click)="openWebSSH(linuxServer)">Abrir SSH</button>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="monitoring-card mb-20 float-right pl-10">
                        <mat-card class="width-100-percent">
                            <mat-card-header>
                                <mat-card-title>VPNs</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="padding-none">
                                <app-vpn-overview-accordion
                                    [baseServer]="baseServer"
                                    [serverType]="serverType"
                                ></app-vpn-overview-accordion>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div style="clear: both;"></div>
                <mat-card style="width: 100%;">
                    <mat-card-header>
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>Monitoramento detalhes</div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <button mat-button [ngClass]="{'primary-200-bg': rangeInMinSelected < 50}"
                                        (click)="setPeriod(15)">15 min
                                </button>
                                <button mat-button [ngClass]="{'primary-200-bg': rangeInMinSelected == 65}"
                                        (click)="setPeriod(60)">1 hora
                                </button>
                                <button mat-button [ngClass]="{'primary-200-bg': rangeInMinSelected == (6 * 60) + 5}"
                                        (click)="setPeriod(6 * 60)">6 horas
                                </button>
                                <button mat-button [ngClass]="{'primary-200-bg': rangeInMinSelected == (12 * 60) + 5}"
                                        (click)="setPeriod(12 * 60)">12 horas
                                </button>
                                <button mat-button [ngClass]="{'primary-200-bg': rangeInMinSelected == (24 * 60) + 5}"
                                        (click)="setPeriod(24 * 60)">1 dia
                                </button>
                                <button mat-button
                                        [ngClass]="{'primary-200-bg': rangeInMinSelected == (24 * 4 * 60) + 5}"
                                        (click)="setPeriod(24 * 4 * 60)">4 dias
                                </button>
                                <button mat-button
                                        [ngClass]="{'primary-200-bg': rangeInMinSelected == (24 * 15 * 60) + 5}"
                                        (click)="setPeriod(24 * 15 * 60)">15 dias
                                </button>
                                <button mat-button
                                        [ngClass]="{'primary-200-bg': rangeInMinSelected == (24 * 30 * 60) + 5}"
                                        (click)="setPeriod(24 * 30 * 60)">30 dias
                                </button>
                                <!--                                <mat-form-field>-->
                                <!--                                    <input matInput-->
                                <!--                                           placeholder="Escolha o período"-->
                                <!--                                           [satDatepicker]="picker"-->
                                <!--                                           [formControl]="periodDateFilterControl">-->
                                <!--                                    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>-->
                                <!--                                    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>-->
                                <!--                                </mat-form-field>-->
                                <!--                                -->
                                <!--                                <mat-form-field class="ml-8" style="min-width: 80px;">-->
                                <!--                                    <mat-label>Resolução</mat-label>-->
                                <!--                                    <mat-select [formControl]="resolutionControl">-->
                                <!--                                        <mat-option *ngFor="let resolution of resolutions" [value]="resolution.value">-->
                                <!--                                            {{resolution.name}}-->
                                <!--                                        </mat-option>-->
                                <!--                                    </mat-select>-->
                                <!--                                </mat-form-field>-->
                                <!--                                -->
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-tab-group (selectedTabChange)="onLinkClick($event)" class="mt-25"
                                       [(selectedIndex)]="selectedIndex">
                            <mat-tab *ngIf="selectedIndex && selectedIndex === -1"></mat-tab>
                            <mat-tab label="PROCESSOR_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">computer</mat-icon>
                                    <span class="ml-15">Processamento</span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12">
                                            <div style="display: block;height: 300px;">
                                                <app-processor-usage
                                                    *ngIf="baseServer.id"
                                                    [serverId]="baseServer.id"
                                                    [metricType]="metricType"
                                                    [begin]="begin"
                                                    [end]="end"
                                                    [resolution]="resolutions[2].value"
                                                ></app-processor-usage>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="MEMORY_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">memory</mat-icon>
                                    <span class="ml-15">Memória</span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12">
                                            <div style="display: block;height: 300px;">
                                                <app-memory-usage *ngIf="baseServer.id"
                                                                  [serverId]="baseServer.id"
                                                                  [metricType]="metricType"
                                                                  [begin]="begin"
                                                                  [end]="end"
                                                                  [resolution]="getResolution()">
                                                </app-memory-usage>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="HD_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">hd</mat-icon>
                                    <span class="ml-15">
                                    HD
                                </span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12" *ngIf="lineChartData">
                                            <div style="display: block;height: 320px;margin-top: 40px"
                                                 *ngFor="let hd of hdList">
                                                <h2 class="mb-0 text-align-center"
                                                    [innerHTML]="replaceSeparator(hd.driveName+':', '|') | safeHtml: 'html'"></h2>
                                                <app-hd-usage *ngIf="baseServer.id"
                                                              [metricId]="hd.id"
                                                              [serverId]="baseServer.id"
                                                              [metricType]="MetricType.HD"
                                                              [begin]="begin"
                                                              [end]="end"
                                                              [resolution]="getResolution()">
                                                </app-hd-usage>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="VPN_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">vpn_lock</mat-icon>
                                    <span class="ml-15">VPN</span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12" *ngIf="lineChartData">
                                            <div style="display: block;height: 320px;margin-top: 40px"
                                                 *ngFor="let vpn of vpnList">
                                                <h2 class="mb-0 text-align-center">{{vpn.name}}</h2>
                                                <div class="text-align-center">{{vpn.clientVpnIp}}</div>
                                                <app-vpn-usage *ngIf="baseServer.id"
                                                               [serverId]="baseServer.id"
                                                               [metricId]="vpn.id"
                                                               [metricType]="MetricType.VPN"
                                                               [begin]="begin"
                                                               [end]="end"
                                                               [resolution]="getResolution()">
                                                </app-vpn-usage>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="PROCESS_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">apps</mat-icon>
                                    <span class="ml-15">Processos</span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12">
                                            <div style="display: block;">
                                                <app-process-details-table
                                                    [baseServer]="baseServer"
                                                    [serverType]="serverType"
                                                ></app-process-details-table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="USER_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">people</mat-icon>
                                    <span class="ml-15">
                                    Usuários
                                </span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12">
                                            <div style="display: block;"
                                                 *ngIf="baseServer.id && serverType && baseServer.clientId">

                                                <app-user-details-table
                                                    [serverType]=serverType
                                                    [serverId]=baseServer.id
                                                    [clientId]=baseServer.clientId
                                                ></app-user-details-table>

                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="SERVICE_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">room_service</mat-icon>
                                    <span class="ml-15">
                                    Serviços
                                </span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12">
                                            <div style="display: block;">
                                                <app-services-details-table
                                                    [baseServer]="baseServer"
                                                    [serverType]="serverType"
                                                ></app-services-details-table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="NETWORK_USAGE">
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">link</mat-icon>
                                    <span class="ml-15">
                                    Rede
                                </span>
                                </ng-template>
                                <ng-template matTabContent>
                                    <div class="content-tab mt-30  pt-14">
                                        <div class="col-md-12">
                                            <div style="display: block;" *ngIf="networks.length > 0">
                                                <app-network-configuration
                                                    [networks]="networks"></app-network-configuration>
                                                <div *ngIf="lineChartData">
                                                    <div style="display: block;height: 700px;margin-top: 40px"
                                                         *ngFor="let network of networks">
                                                        <h2 class="mb-0 text-align-center">{{network.name}}
                                                            (Saida):</h2>
                                                        <app-network-usage *ngIf="baseServer.id"
                                                                           [baseServerId]="baseServer.id"
                                                                           [serverId]="network.id"
                                                                           [metricType]="MetricType.NETWORK_SENT"
                                                                           [begin]="begin"
                                                                           [end]="end"
                                                                           [resolution]="getResolution()">
                                                        </app-network-usage>

                                                        <h2 class="mb-0 text-align-center">{{network.name}}
                                                            (Recebimento):</h2>
                                                        <app-network-usage *ngIf="baseServer.id"
                                                                           [baseServerId]="baseServer.id"
                                                                           [serverId]="network.id"
                                                                           [metricType]="MetricType.NETWORK_RECEIVED"
                                                                           [begin]="begin"
                                                                           [end]="end"
                                                                           [resolution]="getResolution()">
                                                        </app-network-usage>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removeChars'
})
export class RemoveCharsPipe implements PipeTransform {
    transform(text: any): any {
        if (text != null && typeof (text) !== 'undefined') {
            return text.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');
        }
        return '-';
    }
}

import {Injectable, Injector} from '@angular/core';
import {R2CloudHttpBase} from './r2-cloud-http.base';
import {HttpClient} from '@angular/common/http';
import {FuseProgressBarService} from '../../@fuse/components/progress-bar/progress-bar.service';
import {DomainPathService} from './domain-path/domain-path.service';
import {AuthService} from './auth.service';
import {EMPTY, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {FirebaseService} from './firebase.service';

@Injectable({
    providedIn: 'root'
})
export class R2CloudHttpApiService extends R2CloudHttpBase {

    constructor(private httpAngular: HttpClient,
                private fuseProgressBarService: FuseProgressBarService,
                private domainPathService: DomainPathService,
                private injector: Injector
    ) {
        super(httpAngular,
            () => this.fuseProgressBarService.addAsync(),
            (id: string) => fuseProgressBarService.removeAsync(id),
            () => of(null),
            domainPathService.apiURL$,
            (caught) => {
                return this.firebaseService.loginApi(null)
                    .pipe(
                        switchMap((user) => {
                            if (!user) {
                                // console.trace('User not authenticated on firebase');
                                return throwError('User not authenticated on firebase');
                            }
                            return of(user);
                        }),
                        switchMap(() => caught),
                        catchError(() => {
                            this.authService.logout().subscribe();
                            // console.log('(auth) 10 - R2CloudHttpApiService');
                            if (!this.authService.getBlockedUser()) {
                                // console.log('(auth) 11 - reload');
                                window.location.reload();
                            }
                            return EMPTY;
                        }),
                    );
            }
        );
    }

    private _authService: AuthService = null;

    private get authService(): AuthService {
        if (!this._authService) {
            this._authService = this.injector.get<AuthService>(AuthService);
        }
        return this._authService;
    }

    private _firebaseService: FirebaseService = null;

    private get firebaseService(): FirebaseService {
        if (!this._firebaseService) {
            this._firebaseService = this.injector.get<FirebaseService>(FirebaseService);
        }
        return this._firebaseService;
    }
}

import {Component, OnInit} from '@angular/core';
import {ManagerUserPermissionsDaoService} from './manager-user-permissions-dao.service';
import {ManagerUserDaoService} from '../manager-user-dao.service';
import {ManagerUser, ManagerUserPermissions} from '../../../models';
import {forkJoin, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {ImageFileService} from '../../image-file/image-file-service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-manager-user-permissions',
    templateUrl: './manager-user-permissions.component.html',
    styleUrls: ['./manager-user-permissions.component.scss']
})
export class ManagerUserPermissionsComponent extends ComponentCleaner implements OnInit {
    managerUsers: ManagerUser[] = [];
    adminManagerUserPermissions: ManagerUserPermissions[] = [];
    customerGroupCreatorManagerUserPermissions: ManagerUserPermissions[] = [];
    filteredManagerUsers: Observable<ManagerUser[]>;
    filterControl = new FormControl('');
    radioControl = new FormControl('');

    constructor(private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService,
                private managerUserDaoService: ManagerUserDaoService,
                private dialog: MatDialog,
                public imageFileService: ImageFileService) {
        super();
    }

    ngOnInit(): void {
        const managerUserPermissionsAdmin$ = this.managerUserPermissionsDaoService.filter({admin: true});
        const managerUserPermissionsCreator$ = this.managerUserPermissionsDaoService.filter({customerGroupCreator: true});
        forkJoin([
            managerUserPermissionsAdmin$,
            managerUserPermissionsCreator$
        ]).subscribe((result) => {

            if (result[0]) {
                this.adminManagerUserPermissions = result[0];
            }
            if (result[1]) {
                this.customerGroupCreatorManagerUserPermissions = result[1];
            }

            const managerUserIdList: number[] = [];
            this.adminManagerUserPermissions.forEach((adminManagerUser) => {
                managerUserIdList.push(adminManagerUser.managerUserId);
            });
            this.customerGroupCreatorManagerUserPermissions.forEach((creatorManagerUser) => {
                managerUserIdList.push(creatorManagerUser.managerUserId);
            });

            this.managerUserDaoService.findAllByIdList(managerUserIdList).subscribe((managerUserList) => {
                if (managerUserList) {
                    this.setManagerUserList(managerUserList);
                }
            });

            this.filteredManagerUsers = this.filterControl.valueChanges
                .pipe(
                    tap(x => {
                        console.debug('manager: x.length: ' + x.length);
                    }),
                    debounceTime(1000),
                    distinctUntilChanged(),
                    filter(x => x.length > 3),
                    map(value => typeof value === 'string' ? value : value.email),
                    switchMap(email => this._filter(email))
                );

        });
    }

    private _filter(email: string): Observable<ManagerUser[]> {
        return this.managerUserDaoService.findUnprivilegedByEmailLike(email.toLowerCase());
    }

    getManagerUser(managerUserId: number): ManagerUser {
        if (this.managerUsers) {
            return this.managerUsers.find((result) => {
                return result.id === managerUserId;
            });
        } else {
            return null;
        }
    }

    private setManagerUser(managerUser: ManagerUser): void {
        const manager = this.getManagerUser(managerUser.id);
        if (!manager) {
            this.managerUsers.push(managerUser);
        }
    }

    private setManagerUserList(managerUserList: ManagerUser[]): void {
        managerUserList.forEach((managerUser) => {
            this.setManagerUser(managerUser);
        });
    }

    displayFn(managerUser?: ManagerUser): string | undefined {
        return managerUser ? managerUser.email : undefined;
    }

    validateForm(): boolean {
        return this.radioControl.dirty &&
            this.radioControl.valid &&
            this.filterControl.dirty &&
            this.filterControl.valid &&
            this.filterControl.value &&
            this.filterControl.value.id;
    }

    onSubmit(): void {
        this.setManagerUser(this.filterControl.value);
        const managerUserId = this.filterControl.value.id;
        const admin = this.radioControl.value === 'admin';
        const customerGroupCreator = this.radioControl.value === 'customerGroupCreator';

        this.managerUserPermissionsDaoService.findByManagerUserId(managerUserId).subscribe((managerUserPermissionsDAO) => {
            let managerUserPermission$;
            if (managerUserPermissionsDAO) {
                managerUserPermissionsDAO.admin = admin;
                managerUserPermissionsDAO.customerGroupCreator = customerGroupCreator;
                managerUserPermission$ = this.managerUserPermissionsDaoService.save(managerUserPermissionsDAO);
            } else {
                const managerUserPermissions: ManagerUserPermissions = {
                    managerUserId: managerUserId,
                    id: null,
                    comment: null,
                    active: true,
                    admin: admin,
                    customerGroupCreator: customerGroupCreator,
                    modified: null,
                    optlock: null
                };
                managerUserPermission$ = this.managerUserPermissionsDaoService.create(managerUserPermissions);
            }
            managerUserPermission$.subscribe((result) => {
                if (result.admin) {
                    this.adminManagerUserPermissions.push(result);
                } else if (result.customerGroupCreator) {
                    this.customerGroupCreatorManagerUserPermissions.push(result);
                }
                this.filterControl.setValue('');
                this.filterControl.updateValueAndValidity();
                this.radioControl.setValue('');
                this.radioControl.updateValueAndValidity();
            });
        });
    }

    onRemoveManagerUserPermission(managerUserPermissions: ManagerUserPermissions): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse papel do usuário?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((remove) => {
            if (remove) {
                managerUserPermissions.admin = false;
                managerUserPermissions.customerGroupCreator = false;
                this.managerUserPermissionsDaoService.save(managerUserPermissions).subscribe((result) => {

                        const indexAdmin = this.adminManagerUserPermissions.findIndex((value) => {
                            return value.id === managerUserPermissions.id;
                        });
                        if (indexAdmin > -1) {
                            this.adminManagerUserPermissions.splice(indexAdmin, 1);
                        }

                        const indexCreator = this.customerGroupCreatorManagerUserPermissions.findIndex((value) => {
                            return value.id === managerUserPermissions.id;
                        });
                        if (indexCreator > -1) {
                            this.customerGroupCreatorManagerUserPermissions.splice(indexCreator, 1);
                        }

                });
            }
        });
        this.addSubscription(subscription);

    }
}

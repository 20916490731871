<form (submit)="onSubmit()" [formGroup]="myForm">

    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Mensagem enviada antes da atualização:</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent >

        <div style="padding: 20px 20px 20px 0px;">
            <mat-form-field class="width-100-percent">
                <mat-label>Mensagem</mat-label>
                <textarea rows="4" matInput formControlName="message" name="message" ></textarea>
            </mat-form-field>
        </div>

        <div style="padding: 20px 20px 20px 0px;">
            <div>
            <b>Tempo em minutos</b> que a mensagem será enviada antes da atualização:
            </div>
            <mat-form-field class="w-70">
                <input matInput placeholder="" min="30" style="text-align: left" type="number"
                       formControlName="timeInMinutes" name="timeInMinutes">
            </mat-form-field>
        </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="submit" mat-raised-button color="accent" aria-label="Salvar">
            Salvar
        </button>
        <button type="button" mat-raised-button color="warn" aria-label="Cancelar" (click)="onCancel()">
            Cancelar
        </button>
    </div>
</form>


import {RdpConnection} from './rdp-connection';
import {ConnectionEvent} from './enum/connection-event';
import {ConnectionState} from './enum/connection-state';
import {ConnectionFactoryType} from './enum/connection-factory-type';
import {PlatformInfo} from './platform-info';
import {PrintingService} from '../printing.service';
import {Logger} from './logger';
import {TelemetryService} from '../telemetry.service';
import {ConnectionProperties} from './connection-properties';
import {ConnectionWrapper} from './ConnectionWrapper';

declare const Module;

export class ConnectionFactory {
    connections: ConnectionWrapper[] = [];

    constructor(private a: any,
                private logger: Logger,
                private audioContext: AudioContext,
                private telemetry: TelemetryService,
                private printing: PrintingService,
                private platformInfo: PlatformInfo,
                private g: any,
                private h: any) {

    }

    private onConnectionConnectFailed = (a: any): void => {
        this.removeConnection(a);
    };

    private onConnectionDestroyed = (a: any): void => {
        a.disconnectReason && a.disconnectReason.code !== Module.DisconnectCode.UserInitiated || this.removeConnection(a);
    };

    private onWindowsLoaded = (a: any): void => {
        let c;
        for (this.logger.log('[SessionProvider] All windows loaded for connection: ' + a), c = 0; c < this.connections.length; c++) {
            if (this.connections[c].connection.id === a) {
                this.connections[c].windowsLoaded = !0;
                break;
            }
        }
    };

    private k = (connectionProperties: any): any => {
        let properties, index;
        for (index = 0; index < this.connections.length; index++) {
            properties = this.connections[index].properties;
            if (properties.isEqual(connectionProperties)) {
                return this.connections[index].connection;
            }
        }
        return null;
    };

    findConnection(a: any): any {
        return this.k(a);
    }

    findConnectionById(id: any): any {
        let index;
        for (index = 0; index < this.connections.length; index++) {
            if (this.connections[index].connection.id === id) {
                return this.connections[index].connection;
            }
        }
        return null;
    }

    removeConnection(a: any): void {
        let b;
        for (b = 0; b < this.connections.length; b++) {
            if (this.connections[b].connection === a) {
                this.connections.splice(b, 1);
                break;
            }
        }
        a.dismiss();
    }

    createConnection(o: ConnectionProperties, p: (rdpConnection: RdpConnection) => void, remoteAppId: number): any {
        let q, r, s, t = null;
        const u = (a: any, h: (rdpConnection: RdpConnection) => void): void => {
            o.connectionSettings.ADFSToken = a, s = Module.CorrelationID.CreateRandomCorrelationID(), r = Module.IConnection.Create(o.connectionSettings, s);
            const i = new RdpConnection(r, this.logger, this.audioContext, o.remoteApplicationMode, this.telemetry, this.printing, this.platformInfo, this.g, remoteAppId);
            i.id = s.ToString().slice(1, -1), i.correlationId = s, i.tenantId = o.tenantId, o.connectionSettings &&
            (i.eventLogUploadAddress = o.connectionSettings.EventLogUploadAddress), this.connections.push({
                connection: i,
                properties: o,
                windowsLoaded: !1
            }), i.events.subscribe(ConnectionEvent.ConnectionDestroyed, this.onConnectionDestroyed),
                i.events.subscribe(ConnectionEvent.ConnectionConnectFailed, this.onConnectionConnectFailed),
                i.events.subscribe(ConnectionEvent.WindowsLoaded, this.onWindowsLoaded), i.isExistingConnection = !1, h(i);
        };
        if (this.logger.debug('[ConnectionFactory] Creating connection to: '), this.logger.debug(o), q = this.k(o),
        q && q.getState() === ConnectionState.Disconnected && (this.removeConnection(q), q = null), null === q) {
            if (this.a === ConnectionFactoryType.RdpConnection) {
                if (o.connectionSettings.GatewayCertLogonAuthority && this.h) {
                    const v = this.h.getADFSToken(o);
                    v.then((a: any): void => {
                        u(a, p);
                    }, () => {
                        u('', p);
                    });
                } else {
                    u('', p);
                }
            }
        } else {
            this.logger.log('[ConnectionFactory] Existing connection found '), t = q, t.isExistingConnection = !0, p(t);
        }
    }

    disconnectAllConnections(): void {
        let a;
        for (a = this.connections.shift(); a && a.connection;) {
            a.connection.disconnect(!0), a = this.connections.shift();
        }
    }

    invalidateCanvasForActiveConnections(): void {
        let a;
        for (a = 0; a < this.connections.length; a++) {
            this.connections[a].connection.invalidateCanvas();
        }
    }

    hasActiveConnections(): any {
        return 0 !== this.connections.length;
    }

    listActiveConnections(): RdpConnection[] {
        let a;
        const b = [];
        for (a = 0; a < this.connections.length; a++) {
            b.push(this.connections[a].connection);
        }
        return b;
    }

    windowsLoaded(): any {
        let a;
        for (a = 0; a < this.connections.length; a++) {
            if (!1 === this.connections[a].windowsLoaded) {
                return !1;
            }
        }
        return !0;
    }
}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {Model} from '../../../models-ai';

@Injectable({
    providedIn: 'root'
})
export class ModelDaoService extends BaseDao<any> {
    private aiURL = '';

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    init(): Model {
        return {
            id: null,
            name: null,
            provider: null,
            createdAt: null
        };
    }

    findAll(): Observable<Model[]> {
        return this.http.get<Model[]>(this.aiURL + 'model/', null, null, true);
    }

    save(model: Model): Observable<Model> {
        return this.http.put<Model>(this.aiURL + 'model/', model, true);
    }

    create(model: Model): Observable<Model> {
        return this.http.post<Model>(this.aiURL + 'model/', model, true);
    }

    delete(model: Model): Observable<void> {
        return this.http.delete(this.aiURL + 'model/', model, true);
    }
}


export class RdpCredential {
    id: any = null;
    userName: any = null;
    password: any = null;

    label(): any {
        return this.userName;
    }

    validate(): any {
        return !0;
    }
}

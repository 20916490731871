import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {IfErrorArgs} from './if-error-args';
import {NgIfContext} from '@angular/common';

@Directive({
  selector: '[appIfError]'
})
export class IfErrorDirective {

  private hasView = false;
  private context = new NgIfContext();

  @Input('appIfError')
  set appIfError(errorArgs: IfErrorArgs) {
    let condition = false;
    console.debug(errorArgs);
    if (errorArgs && errorArgs.errors) {
      for (const key in errorArgs.errors) {
        if (errorArgs.errors.hasOwnProperty(key)) {
          if (errorArgs.errorFields) {
            for (const field of errorArgs.errorFields) {
              if (key === field.key) {
                if (field.value) {
                  if (field.value === errorArgs.errors[key]) {
                    condition = true;
                  }
                } else {
                  condition = true;
                }
              }
            }
          }
        }
      }
    }
    // console.debug('error directive show: ' + condition);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

}

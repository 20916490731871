import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {convertToFormGroup, CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {CustomerGroup, DesktopServer, DesktopServerPool, DesktopServerPoolWithDesktopServerIds, GatewayServer, ServerConnectionType} from '../../../models';
import {DesktopServerPoolDaoService} from '../desktop-server-pool-dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import * as download from 'download.js';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatSelect} from '@angular/material/select';
import {ComponentCleaner} from '../../../component-cleaner';
import {GatewayServerDaoService} from '../../gateway-server/gateway-server-dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-desktop-server-pool-edit',
    templateUrl: './desktop-server-pool-edit.component.html',
    styleUrls: ['./desktop-server-pool-edit.component.scss']
})
export class DesktopServerPoolEditComponent extends ComponentCleaner implements OnInit {

    myForm: FormGroup;
    desktopServerPool: DesktopServerPool;
    commonDesktopServers: DesktopServer[] = [];
    filteredCommonDesktopServers: DesktopServer[] = [];
    allDesktopServers: DesktopServer[] = [];
    desktopServersIdsCtrl: FormControl = new FormControl();
    filteredDesktopServersMulti: DesktopServer[] = [];
    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;

    readonly ServerConnectionType = ServerConnectionType;


    // gatewayServerForm: FormControl = new FormControl();
    customerGroupForm: FormControl = new FormControl();
    activateR2SecurityControl: FormControl = new FormControl();
    gatewayServers: GatewayServer[] = [];
    gatewayServer: GatewayServer;
    customerGroups: CustomerGroup[] = [];
    activateCustomPortControl: FormControl = new FormControl();
    customerGroupFormHasChange = false;


    constructor(
        public dialogRef: MatDialogRef<DesktopServerPoolEditComponent>,
        private fb: FormBuilder,
        private desktopServerPoolDaoService: DesktopServerPoolDaoService,
        public adminService: R2CloudAdminService,
        private gatewayServerDaoService: GatewayServerDaoService,
        private customerGroupService: CustomerGroupService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();
        this.desktopServerPool = data.desktopServerPool;
        this.commonDesktopServers = data.commonDesktopServers;
        if (!this.desktopServerPool) {
            this.desktopServerPool = this.desktopServerPoolDaoService.initDesktopServerPool();
        }
        this.filteredCommonDesktopServers = this.removeAppBaseDesktopServerFromCommonDesktopServers(this.desktopServerPool.appBaseDesktopServerId, this.commonDesktopServers);
        this.myForm = this.fb.group(convertToFormGroup(this.desktopServerPool), {asyncValidator: this.desktopServerPoolDaoService.validator});
        this.myForm.get('appBaseDesktopServerId').setValidators([Validators.required]);

        this.myForm.get('name').setValidators([Validators.required]);

        this.desktopServersIdsCtrl.setValue(this.getFilteredCommonDesktopServerIds());
        this.filteredDesktopServersMulti = this.desktopServerListBySelectedADDomainAndRemoveAppBaseDesktopServerId();

        this.addSubscription(this.myForm.get('appBaseDesktopServerId').valueChanges.subscribe((appBaseDesktopServerId) => {
            if (appBaseDesktopServerId) {
                this.filteredCommonDesktopServers = this.removeAppBaseDesktopServerFromCommonDesktopServers(appBaseDesktopServerId, this.commonDesktopServers);
                this.desktopServersIdsCtrl.setValue(this.getFilteredCommonDesktopServerIds());
                this.filteredDesktopServersMulti = this.desktopServerListBySelectedADDomainAndRemoveAppBaseDesktopServerId();
            }
        }));

        this.addSubscription(this.desktopServersIdsCtrl.valueChanges.subscribe((desktopServerIds) => {
            if (desktopServerIds) {
                this.myForm.markAsDirty();
                this.myForm.updateValueAndValidity();
            }
        }));


        this.addSubscription(this.myForm.get('gatewayServerNatConfigDestinationPortRangeMin').valueChanges.subscribe(() => {
            this.validationRangePort();
            this.myForm.markAsDirty();
            this.myForm.updateValueAndValidity();
        }));

        this.addSubscription(this.myForm.get('gatewayServerNatConfigDestinationPortRangeMax').valueChanges.subscribe(() => {
            this.validationRangePort();
            this.myForm.markAsDirty();
            this.myForm.updateValueAndValidity();
        }));


        this.customerGroups = this.customerGroupService.customerGroups;
        const gatewayServerId = this.myForm.get('gatewayServerId').value;
        if (gatewayServerId) {
            this.activateR2SecurityControl.setValue(true);
            const serverConnectionCustomPort = this.myForm.get('gatewayServerNatConfigServerConnectionCustomPort').value;
            if (serverConnectionCustomPort && serverConnectionCustomPort > 0) {
                this.activateCustomPortControl.setValue(true);
            }
            this.gatewayServerDaoService.getOne(gatewayServerId).subscribe((gatewayServer) => {
                if (gatewayServer) {
                    this.gatewayServer = gatewayServer;
                    this.customerGroupForm.setValue(gatewayServer?.customerGroupId);
                    this.findGatewayServerByCustomerGroupId(gatewayServer.customerGroupId);
                }
            });
        } else {
            this.customerGroupForm.setValue(this.customerGroupService?.customerGroup?.id);
            this.customerGroupFormHasChange = true;
            if (this.customerGroupService?.customerGroup?.id) {
                this.findGatewayServerByCustomerGroupId(this.customerGroupService?.customerGroup?.id);
            }
        }

        this.allDesktopServers = this.adminService.desktopServersOfPool.filter(d => d.id !== this.desktopServerPool.appBaseDesktopServerId);

    }


    ngOnInit(): void {
        this.addSubscription(this.addSubscription(this.customerGroupForm.valueChanges.subscribe(customerGroupId => {
            if (this.customerGroupFormHasChange) {
                this.findGatewayServerByCustomerGroupId(customerGroupId);
                this.myForm.get('gatewayServerId').setValue(null);
            }
            this.customerGroupFormHasChange = true;
        })));
        this.addSubscription(this.activateCustomPortControl.valueChanges.subscribe(_ => {
            markAsTouched(this.myForm);
        }));
        this.addSubscription(this.activateR2SecurityControl.valueChanges.subscribe(_ => {
            markAsTouched(this.myForm);
        }));
    }

    private validationRangePort(): void {
        const minControl = this.myForm.get('gatewayServerNatConfigDestinationPortRangeMin');
        const maxControl = this.myForm.get('gatewayServerNatConfigDestinationPortRangeMax');
        const minStr = minControl.value;
        const maxStr = maxControl.value;
        const min = +minStr;
        const max = +maxStr;
        if (!minStr && !maxStr) {
            return;
        }
        if (isNaN(minStr) || isNaN(maxStr)) {
            const errors: ValidationErrors = {minGreaterThanMax: 'Por favor, insira apenas números.'};
            maxControl.setErrors(errors);
            minControl.setErrors(errors);
            return;
        }

        if (min >= max) {
            const errors: ValidationErrors = {minGreaterThanMax: 'O valor máximo do range precisa ser maior que o valor mínimo do range'};
            maxControl.setErrors(errors);
            minControl.setErrors(errors);
            return;
        }

        if (min < 4000 || min > 6000 || max < 4000 || max > 6000) {
            const errors: ValidationErrors = {minGreaterThanMax: 'O Range deve estar entre 4000-6000'};
            maxControl.setErrors(errors);
            minControl.setErrors(errors);
            return;
        }

        maxControl.setErrors(null);
        minControl.setErrors(null);
        return;
    }


    private getFilteredCommonDesktopServerIds(): number[] {
        return this.filteredCommonDesktopServers.map(ds => ds.id);
    }

    private removeAppBaseDesktopServerFromCommonDesktopServers(appBaseDesktopServerId: number, desktopServers: DesktopServer[]): DesktopServer[] {
        if (!desktopServers || !appBaseDesktopServerId) {
            return [];
        }
        return desktopServers?.filter((d) => d.id !== appBaseDesktopServerId);
    }

    desktopServerListBySelectedADDomain(): DesktopServer[] {
        const adDomainId = +this.myForm.get('adDomainId').value;
        return this.adminService.desktopServers.filter((desk) => {
            return desk.adDomainId === adDomainId && (!desk.desktopServerPoolId || desk.desktopServerPoolId === this.desktopServerPool.id);
        });
    }

    private desktopServerListBySelectedADDomainAndRemoveAppBaseDesktopServerId(): DesktopServer[] {
        const desktopServersByDomainId = this.desktopServerListBySelectedADDomain();
        const appBaseDesktopServerId = +this.myForm.get('appBaseDesktopServerId').value;
        return desktopServersByDomainId.filter((desk) => {
            return desk.id !== appBaseDesktopServerId && (!desk.desktopServerPoolId || desk.desktopServerPoolId === this.desktopServerPool.id);
        });
    }

    onSubmit(): void {
        const commonDesktopServerIds: number[] = this.desktopServersIdsCtrl.value;
        const desktopServerPool: DesktopServerPool = this.myForm.value;
        if (!this.activateR2SecurityControl.value) {
            desktopServerPool.gatewayServerId = null;
            desktopServerPool.gatewayServerNatConfigDestinationPortRangeMin = null;
            desktopServerPool.gatewayServerNatConfigDestinationPortRangeMax = null;
            desktopServerPool.gatewayServerNatConfigOpenAccess = null;
            desktopServerPool.gatewayServerNatConfigServerConnectionType = null;
            desktopServerPool.gatewayServerNatConfigServerConnectionCustomPort = null;
            desktopServerPool.gatewayServerNatConfigProtocol = null;
        }
        const desktopServerPoolWithDesktopServerIds: DesktopServerPoolWithDesktopServerIds = {
            desktopServerPool: desktopServerPool,
            desktopServerIds: commonDesktopServerIds
        };


        if (desktopServerPool.id) {
            this.addSubscription(this.desktopServerPoolDaoService.saveDesktopServerPoolWithDesktopServerIds(
                desktopServerPoolWithDesktopServerIds
            ).subscribe((result) => {
                this.dialogRef.close({
                    data: result.desktopServerPool,
                    operation: 'SAVE'
                });
            }));
        } else {
            this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: '<h1>Salve o arquivo com a KEY do grupo do R2 Scaling</h1>' +
                        'Esse arquivo será usado no arquivo de <b>configuração</b> dos <b>servidores</b>' +
                        '<br>em <b>auto scaling.</b>',
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((output) => {
                if (output) {
                    this.desktopServerPoolDaoService.createADUserPoolRelationshipGroups(desktopServerPoolWithDesktopServerIds, this.adminService.subProject.id)
                        .subscribe((desktopServerFromDao) => {
                            this.desktopServerPoolDaoService.generateNewPrivateKey(desktopServerFromDao.groupId).subscribe((privateKey) => {
                                download.downloadText(this.toFileName(desktopServerFromDao.name) + '-' + desktopServerFromDao.groupId + '.txt',
                                    privateKey.value);
                                this.dialogRef.close({
                                    data: desktopServerFromDao,
                                    operation: 'CREATE'
                                });
                            });
                        });
                }
            }));
        }
    }

    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<div>Tem certeza que deseja deletar esse do R2 Scaling?<br>' +
                    'Todos os servidores criados de forma automatica serão excluidos. ' +
                    '<br><br> Para remover digite <u>DELETE</u> no campo abaixo</div>',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Deletar',
                icon: 'error_outline',
                confirmFieldValue: 'DELETE'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.desktopServerPoolDaoService.canDelete(this.desktopServerPool.id).subscribe((canDelete) => {
                    if (canDelete) {
                        this.desktopServerPoolDaoService.remove(this.desktopServerPool.id).subscribe(() => {
                            const crudOperation: CrudOperationWrapper = {
                                data: this.desktopServerPool,
                                operation: 'DELETE'
                            };
                            this.dialogRef.close(crudOperation);
                        });
                    } else {
                        this.alertMessage('<h1>R2 Scaling não pode ser deletado</h1><div class="mb-60"><b>Não pode ser deletado</b> ' +
                            'um R2 Scaling que está sendo usado em vários projetos, <br>' +
                            'precisará remover de todos os projetos antes de deletar.</div>');
                    }
                });

            }
        });
        this.addSubscription(subscription);
    }

    private alertMessage(message: string): void {
        this.addSubscription(
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe(() => {
            })
        );
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    private toFileName(name: string): string {
        return name.toLowerCase().replace(/ /g, '-');
    }

    onGeneratePrivateKey(): void {

        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<h1>Importante!</h1>' +
                    'Todos os servidores que estavam usando a chave antiga precisarão ser substituídos pela nova.<br><br>' +
                    '<h2>Salve o arquivo com a <b>KEY</b> do grupo do R2 Scaling.</h2>' +
                    'Esse arquivo será usado no arquivo de <b>configuração</b> dos <b>servidores</b><br>' +
                    'em <b>auto scaling.</b><br><br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                cancelButtonValue: 'Cancelar',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((output) => {
            if (output) {
                this.addSubscription(this.desktopServerPoolDaoService.generateNewPrivateKey(this.desktopServerPool.groupId).subscribe((privateKey) => {
                    download.downloadText(this.toFileName(this.desktopServerPool.name) + '-' + this.desktopServerPool.groupId + '.txt',
                        privateKey.value);
                    this.dialogRef.close();
                }));
            }
        });
    }


    private findGatewayServerByCustomerGroupId(customerGroupId: number): void {
        const customerGroup = this.customerGroups.find(x => x.id === customerGroupId);
        const gatewayServerId = this.myForm.get('gatewayServerId').value;
        if (customerGroup) {
            this.findGatewayServerByCustomerGroup(customerGroup);
        }
        if (gatewayServerId) {
            const index = this.gatewayServers.findIndex(x => x.id === gatewayServerId);
            if (index < 0) {
                this.gatewayServers.push(this.gatewayServer);
            }
        }
    }

    private findGatewayServerByCustomerGroup(customerGroup: CustomerGroup): void {
        this.gatewayServerDaoService.findAllBy(customerGroup.id, customerGroup.name).subscribe((gatewayServers) => {
            if (gatewayServers) {
                this.gatewayServers = gatewayServers;
            }
        });
    }

    onRemoveFromProject(): void {

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<div>Tem certeza que deseja remover esse do R2 Scaling desse projeto?<br>' +
                    '<br><br> Para remover digite <u>DELETE</u> no campo abaixo</div>',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Deletar',
                icon: 'error_outline',
                confirmFieldValue: 'DELETE'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.desktopServerPoolDaoService.deleteFromSubProject(this.desktopServerPool.id, this.adminService.subProject.id).subscribe(() => {
                    const crudOperation: CrudOperationWrapper = {
                        data: this.desktopServerPool,
                        operation: 'DELETE'
                    };
                    this.dialogRef.close(crudOperation);
                });

            }
        });
        this.addSubscription(subscription);

    }


    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.desktopServerPool.id, this.desktopServerPoolDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.desktopServerPool.id, this.desktopServerPoolDaoService, this.dialog);
    }

    numberOfDoorsInRange(): number {
        return 1 + (+this.myForm.get('gatewayServerNatConfigDestinationPortRangeMax').value) - (+this.myForm.get('gatewayServerNatConfigDestinationPortRangeMin').value);
    }

    serverLimitExceeded(): boolean {
        return this.numberOfDoorsInRange() <= this.allDesktopServers.length;
    }
}

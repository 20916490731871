import {Injectable} from '@angular/core';
import {RdpCredential} from './ts/rdp-credential';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class CredentialStoreService {
    private a: any;

    constructor() {
    }

    storeCreds(b: any, c: any): void {
        this.a = new RdpCredential(), this.a.userName = b, this.a.password = c;
    }

    getSavedCreds(): any {
        return this.a && _.cloneDeep(this.a);
    }
}

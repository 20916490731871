import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MarketplaceService} from '../../marketplace.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {convertToFormGroup} from '../../../../helpers/kluh';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Customer, CustomerGroup, FirebaseUserDetails, ManagerUser, MarketplaceOrder, MarketplaceOrderComment, MarketplaceProduct} from '../../../../models';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {ImageFileService} from '../../../image-file/image-file-service';
import {CustomerGroupDaoService} from '../../../customer-group/customer-group-dao.service';
import {CustomerDaoService} from '../../../customer/customer-dao.service';
import {MarketplaceProductCommentService} from '../../marketplace-product/marketplace-product-comment/marketplace-product-comment.service';
import {MarketplaceOrderDaoService} from '../marketplace-order.dao.service';
import {MarketplaceProductDaoService} from '../../marketplace-product/marketplace-product.dao.service';
import {MarketplaceOrderCommentDaoService} from '../marketplace-order-comment/marketplace-order-comment.dao.service';
import {MarketplaceOrderCommentService} from '../marketplace-order-comment/marketplace-order-comment.service';
import {USER_TOPIC} from '../../../../../../main';
import {ComponentCleaner} from '../../../../component-cleaner';
import {R2CloudStompService} from '../../../../ws/r2-cloud-stomp.service';

@Component({
    selector: 'app-marketplace-order-detail',
    templateUrl: './marketplace-order-detail.component.html',
    styleUrls: ['./marketplace-order-detail.component.scss', '../../marketplace-product/marketplace-product.component.scss'],
    queries: {
        'contentRef': new ViewChild('contentRef', {static: false})
    }
})
export class MarketplaceOrderDetailComponent extends ComponentCleaner implements OnInit {
    contentRef!: ElementRef;
    private marketplaceOrderParam = 'marketplaceOrderId';
    marketplaceOrder: MarketplaceOrder;
    marketplaceProduct: MarketplaceProduct;
    currentUserCustomerGroup: CustomerGroup;
    myForm: FormGroup;
    managerUserOfMarketplaceOrder: ManagerUser;
    customerGroupOfManagerUserOfMarketplaceOrder: CustomerGroup;
    customerOfManagerUserOfMarketplaceOrder: Customer;
    marketplaceRequestCommentList: MarketplaceOrderComment[] = [];
    user: FirebaseUserDetails = null;

    constructor(private route: ActivatedRoute,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private userAuthorityDao: UserAuthorityDaoService,
                private managerUserDaoService: ManagerUserDaoService,
                private marketplaceProductCommentService: MarketplaceProductCommentService,
                private customerGroupDaoService: CustomerGroupDaoService,
                private customerDaoService: CustomerDaoService,
                public imageFileService: ImageFileService,
                public adminService: R2CloudAdminService,
                private router: Router,
                private marketplaceService: MarketplaceService,
                private marketplaceOrderDaoService: MarketplaceOrderDaoService,
                private marketplaceOrderCommentService: MarketplaceOrderCommentService,
                private marketplaceProductDaoService: MarketplaceProductDaoService,
                private stomp: R2CloudStompService,
                private marketplaceOrderCommentDaoService: MarketplaceOrderCommentDaoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((queryParamMap) => {
            if (queryParamMap && queryParamMap.has(this.marketplaceOrderParam)) {
                const marketplaceOrderId: number = Number(queryParamMap.get(this.marketplaceOrderParam));
                this.userAuthorityDao.getMe().subscribe((user) => {
                    this.user = user;
                    const subscription1 = this.customerGroupDaoService.getByAuthenticatedManagerUser().subscribe(currentUserCustomerGroup => {
                        if (currentUserCustomerGroup) {
                            this.currentUserCustomerGroup = currentUserCustomerGroup;
                            this.getMarketplaceOrderByIdAndInitPageVariables(marketplaceOrderId);
                            this.getNewMarketplaceOrderComment();
                        }
                    });
                    this.addSubscription(subscription1);
                });
            }
        });
    }

    getNewMarketplaceOrderComment(): void {
        const subscriptionOrderComment = this.stomp.stompTopic<MarketplaceOrderComment>(`${USER_TOPIC}/marketplace-order-comment`).subscribe((marketplaceOrderComment) => {
            if (marketplaceOrderComment) {
                this.putOrderCommentToListIfDontExists(marketplaceOrderComment);
                if (marketplaceOrderComment.id) {
                    this.putMarketplaceOrderCommentManagerUserInAdminServiceManagerUserList(marketplaceOrderComment);
                    this.markHasRead(marketplaceOrderComment.marketplaceOrderId);
                    this.sendScrollToBottom();
                }
            }
        });
        this.addSubscription(subscriptionOrderComment);
    }

    putOrderCommentToListIfDontExists(marketplaceOrderComment: MarketplaceOrderComment): void {
        if (!this.marketplaceOrderCommentService.findById(marketplaceOrderComment.id, this.marketplaceRequestCommentList)) {
            this.marketplaceRequestCommentList.push(marketplaceOrderComment);
        }
    }

    putMarketplaceOrderCommentListManagerUserInAdminServiceManagerUserList(): void {
        for (const orderComment of this.marketplaceRequestCommentList) {
            this.putMarketplaceOrderCommentManagerUserInAdminServiceManagerUserList(orderComment);
        }
    }

    putMarketplaceOrderCommentManagerUserInAdminServiceManagerUserList(orderComment: MarketplaceOrderComment): void {
        if (!this.adminService.getManagerUser(orderComment.managerUserId)) {
            const subscription2 = this.managerUserDaoService.getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(orderComment.marketplaceOrderId)
                .subscribe(commentManagerUser => {
                    this.addManagerUserInAdminService(commentManagerUser);
                });
            this.addSubscription(subscription2);
        }
    }

    transformsManagerUserIdListInManagerUserList(): void {
        const managerUserIds: number[] = [];
        for (const comment of this.marketplaceRequestCommentList) {
            managerUserIds.push(comment.managerUserId);
        }
        if (managerUserIds && managerUserIds.length > 0) {
            const subscription3 = this.managerUserDaoService.findAllByIdList(managerUserIds).subscribe(managerUserList => {
                if (managerUserList) {
                    managerUserList.map(managerUserMap => {
                        this.addManagerUserInAdminService(managerUserMap);
                    });
                }
                this.sendScrollToBottom();
            });
            this.addSubscription(subscription3);
        }
    }

    addManagerUserInAdminService(managerUser: ManagerUser): void {
        if (!this.adminService.getManagerUser(managerUser.id)) {
            this.adminService.addManagerUser(managerUser);
        }
    }

    getManagerUserByIdAndAddOnAdminService(managerUserId: number): void {
        const subscription4 = this.managerUserDaoService.getOne(managerUserId).subscribe(managerUser => {
            if (managerUser) {
                this.addManagerUserInAdminService(managerUser);
            }
        });
        this.addSubscription(subscription4);

    }

    findMarketplaceOrderCommentByMarketplaceOrderId(marketplaceOrderId: number): void {
        const subscription5 = this.marketplaceOrderCommentDaoService.findAllByMarketplaceOrderId(marketplaceOrderId).subscribe(marketplaceRequestCommentList => {
            if (marketplaceRequestCommentList) {
                this.marketplaceRequestCommentList = marketplaceRequestCommentList;
            }
        });
        this.addSubscription(subscription5);
    }

    findMarketplaceProductById(marketplaceProductId: number): void {
        const subscription6 = this.marketplaceProductDaoService.getOne(marketplaceProductId).subscribe(marketplaceProduct => {
            if (marketplaceProduct) {
                this.marketplaceProduct = marketplaceProduct;
            }
        });
        this.addSubscription(subscription6);
    }

    getMarketplaceOrderByIdAndInitPageVariables(marketplaceOrderId: number): void {
        const subscription7 = this.marketplaceOrderDaoService.getOne(marketplaceOrderId).subscribe(marketplaceOrder => {
            if (marketplaceOrder) {
                this.marketplaceOrder = marketplaceOrder;
                this.findMarketplaceProductById(this.marketplaceOrder.marketplaceProductId);
                this.findMarketplaceOrderCommentByMarketplaceOrderId(this.marketplaceOrder.id);
                this.myForm = this.fb.group(convertToFormGroup(this.marketplaceOrderCommentService.initMarketplaceOrderComment()));
                this.getManagerUserOfMarketplaceOrder(this.marketplaceOrder.id);
                this.markHasRead(this.marketplaceOrder.id);
            }
        });
        this.addSubscription(subscription7);
    }

    getCustomerGroupIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(customerGroupId: number, marketplaceOrderId: number): void {
        const subscription8 = this.customerGroupDaoService.getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(
            customerGroupId, marketplaceOrderId).subscribe(customerGroup => {
            this.customerGroupOfManagerUserOfMarketplaceOrder = customerGroup;
            this.putMarketplaceOrderCommentListManagerUserInAdminServiceManagerUserList();
            this.transformsManagerUserIdListInManagerUserList();
        });
        this.addSubscription(subscription8);
    }

    getCustomerIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(customerId: number, marketplaceOrderId: number): void {
        const subscription9 = this.customerDaoService.getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(
            customerId, marketplaceOrderId).subscribe(customer => {
            this.customerOfManagerUserOfMarketplaceOrder = customer;
            this.getCustomerGroupIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(customer.customerGroupId, this.marketplaceOrder.id);
        });
        this.addSubscription(subscription9);
    }

    getManagerUserOfMarketplaceOrder(marketplaceOrderId: number): void {
        const subscription10 = this.managerUserDaoService.getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(marketplaceOrderId).subscribe(managerUser => {
            this.managerUserOfMarketplaceOrder = managerUser;
            if (managerUser.customerId) {
                this.adminService.addManagerUser(this.managerUserOfMarketplaceOrder);
                this.getCustomerIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(
                    this.managerUserOfMarketplaceOrder.customerId, this.marketplaceOrder.id);
            }
        });
        this.addSubscription(subscription10);
    }

    markHasRead(marketplaceOrderId: number): void {
       const subscription11 = this.marketplaceOrderDaoService.markAsRead(marketplaceOrderId).subscribe(read => {
            this.marketplaceOrder.readOrder = true;
        });
        this.addSubscription(subscription11);
    }

    onSubmit(): void {
        const comment = this.marketplaceOrderCommentService.initMarketplaceOrderComment();
        comment.marketplaceOrderId = this.marketplaceOrder.id;
        comment.managerUserId = this.user.managerUser.id;
        comment.email = this.user.managerUser?.email;
        comment.comment = this.myForm.value.comment;
        this.myForm.setValue(this.marketplaceOrderCommentService.initMarketplaceOrderComment());
        const subscription12 = this.marketplaceOrderCommentDaoService.create(comment).subscribe(commentFromDAO => {
            if (commentFromDAO) {
                this.putOrderCommentToListIfDontExists(commentFromDAO);
                this.sendScrollToBottom();
            }
        });
        this.addSubscription(subscription12);
    }

    sendScrollToBottom(): void {
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 100);
    }

    onCancel(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja cancelar esse pedido?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
               const subscription13 =  this.marketplaceOrderDaoService.markAsCanceled(this.marketplaceOrder.id).subscribe(read => {
                    this.marketplaceOrder.cancel = true;
                    this.router.navigate(['/marketplace-order']);
                });
                this.addSubscription(subscription13);
            }
        });
    }

    onComplete(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja concluir e fechar esse pedido?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
               const subscription14 = this.marketplaceOrderDaoService.markAsConcluded(this.marketplaceOrder.id).subscribe(read => {
                    this.marketplaceOrder.concluded = true;
                    this.router.navigate(['/marketplace-order']);
                });
                this.addSubscription(subscription14);
            }
        });
    }

    onReopenOrder(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja reabrir esse pedido?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
               const subscription15 = this.marketplaceOrderDaoService.markAsOpenOrder(this.marketplaceOrder.id).subscribe(read => {
                    this.marketplaceOrder.cancel = false;
                });
                this.addSubscription(subscription15);
            }
        });
    }

    onMarkAsUnread(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja esse pedido como não lido?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const subscription16 = this.marketplaceOrderDaoService.markAsUnread(this.marketplaceOrder.id).subscribe(read => {
                    this.marketplaceOrder.readOrder = false;
                    this.router.navigate(['/marketplace-order']);
                });
                this.addSubscription(subscription16);
            }
        });
    }

    isProducerProduct(): boolean {
        return this.currentUserCustomerGroup.id !== this.marketplaceOrder.customerGroupId;
    }
}

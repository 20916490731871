import {FormArray, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {validIpv4OrIpv6} from '../utils/utils-kluh';

export function validIpv4OrIpv6OfFormArrayOfFormGroupValidator(fieldError: any, formArrayName: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const myFormArray: FormArray = group.controls[formArrayName] as FormArray;
        for (let i = 0; i < myFormArray.controls.length; i++) {
            const formControl = myFormArray.controls[i] as FormGroup;
            if (!validIpv4OrIpv6(formControl.controls.clientVpnIp.value)) {
                formControl.controls.clientVpnIp.setErrors(fieldError);
            }
        }
        return;
    };
}

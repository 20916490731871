import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LinuxServerDaoService} from '../linux-server-dao.service';
import {FirewallFQDN, LinuxServer, Project, SubProject} from '../../../models';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import * as download from 'download.js';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {ClientDownloadService} from '../../desktop/client-download.service';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FirewallFqdnDaoService} from '../../firewall-fqdn/firewall-fqdn-dao.service';
import {FirewallFqdnComponent} from '../../firewall-fqdn/firewall-fqdn.component';

@Component({
    selector: 'app-linux-server-create',
    templateUrl: './linux-server-create.component.html',
    styleUrls: ['./linux-server-create.component.scss'],
    queries: {
        'firewallFQDNContentRef': new ViewChild('firewallFQDNContentRef', {static: false})
    },
})
export class LinuxServerCreateComponent extends ComponentCleaner implements OnInit {
    myForm: FormGroup;
    linuxServer: LinuxServer;
    linuxServers: LinuxServer[];
    subProject: SubProject;
    project: Project;

    firewallFQDNList: FirewallFQDN[] = [];

    // copyInAllSharedSubProjectsFilterControl: FormControl = new FormControl();

    constructor(private dialogRef: MatDialogRef<LinuxServerCreateComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private linuxServerDao: LinuxServerDaoService,
                private subProjectDao: SubProjectDaoService,
                private firewallFqdnDaoService: FirewallFqdnDaoService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                public clientDownloadService: ClientDownloadService) {
        super();
    }

    ngOnInit(): void {
        const newLinuxServer: LinuxServer = {
            clientId: '',
            publicIp: '',
            privateIp: '',
            hostname: '',
            connectionFQDN: '',
            connectionPort: null,
            id: null,
            comment: '',
            active: true,
            firewallFQDNIds: [],
            modified: null,
            gatewayServerNatConfigActive: null,
            optlock: null,
            lastLoginAt: null
        };
        this.subProject = this.data.subProject;
        this.linuxServers = this.data.linuxServers;
        this.linuxServer = this.data.linuxServer;
        this.project = this.data.project;

        if (this.linuxServer) {
            // ativando o validator o hostname fica sempre invalido
            this.myForm = this.fb.group(convertToFormGroup(this.linuxServer), {asyncValidator: this.linuxServerDao.validator});
            // this.myForm = this.fb.group(this.desktopServer, {});
        } else {
            this.myForm = this.fb.group(convertToFormGroup(newLinuxServer), {asyncValidator: this.linuxServerDao.validator});
        }

        this.firewallFqdnDaoService.findAllByLinuxServerId(this.linuxServer.id).subscribe((firewallFQDNList) => {
            if (firewallFQDNList) {
                this.firewallFQDNList = firewallFQDNList;
            }
        });


    }

    onSubmitConfirm(): void {
        let linuxServer$: Observable<LinuxServer>;
        let operation: CrudOperation;
        let linuxServerPrivateKey: string;
        const crudOperations: CrudOperationWrapper[] = [];
        const linuxServer: LinuxServer = this.myForm.value;

        const copyInAllSharedSubProjectsFilterControl = false;
        // if (this.copyInAllSharedSubProjectsFilterControl && this.copyInAllSharedSubProjectsFilterControl.value) {
        //     copyInAllSharedSubProjectsFilterControl = true;
        // }


        if (linuxServer.id) {
            operation = 'SAVE';
            linuxServer$ = this.linuxServerDao.save(linuxServer);
        } else {
            operation = 'CREATE';
            linuxServer$ = this.linuxServerDao.createLinuxServerInAllSubProjectsOfProjectAndGetPrivateKey(
                linuxServer, this.subProject.id, copyInAllSharedSubProjectsFilterControl).pipe(switchMap((linuxServerWithPrivateKey) => {
                if (linuxServerWithPrivateKey) {
                    linuxServerPrivateKey = linuxServerWithPrivateKey.privateKey;
                    const linuxServerObservable = this.linuxServerDao.getOne(linuxServerWithPrivateKey.serverId);
                    this.subProject.linuxServerIds.push(linuxServerWithPrivateKey.serverId);
                    return linuxServerObservable;
                } else {
                    return of(null);
                }
            }));
        }
        linuxServer$.subscribe((result) => {
            if (linuxServerPrivateKey) {
                download.downloadText(result.hostname + '-' + result.clientId + '.txt', linuxServerPrivateKey);
            }
            crudOperations.push({
                data: result,
                operation: operation
            });
            if (!this.subProject.linuxServerIds) {
                this.subProject.linuxServerIds = [];
            }
            let subProject$: Observable<SubProject>;
            let subProjectOperation: CrudOperation;
            const index = this.subProject.linuxServerIds.findIndex(value => {
                return value === result.id;
            });
            if (index === -1) {
                this.subProject.linuxServerIds.push(result.id);
                subProjectOperation = 'SAVE';
                subProject$ = this.subProjectDao.save(this.subProject);
            } else {
                subProjectOperation = 'CANCEL';
                subProject$ = of(this.subProject);
            }
            subProject$.subscribe((result2) => {
                crudOperations.push({
                    data: result2,
                    operation: subProjectOperation
                });
                this.dialogRef.close(crudOperations);
            });
        });

    }


    onSubmit(): void {
        if (!this.myForm.value.id) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Assim que salvar as configurações desse servidor, <br />' +
                        'seu navegador fará o <u>download de um arquivo .txt</u> contendo a chave criptografada <br /> ' +
                        'que será usada para instalar o Client nesse mesmo servidor.',
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe(() => {
                this.onSubmitConfirm();
            });
            this.addSubscription(subscription);
        } else {
            this.onSubmitConfirm();
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }


    onDelete(): void {
        const crudOperations: CrudOperationWrapper[] = [];
        const linuxServer: LinuxServer = this.myForm.value;
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<b>Confirme</b> a exclusão digitando o nome do servidor: <b>' + linuxServer.hostname +
                    '</b> no campo abaixo <br> Esse procedimento não tem volta e deletará tudo que for relacionado a esse servidor.',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline',
                confirmFieldValue: linuxServer.hostname
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.linuxServerDao.remove(linuxServer.id).subscribe(() => {
                    crudOperations.push({
                        data: linuxServer,
                        operation: 'DELETE'
                    });
                    const index = this.subProject.linuxServerIds.findIndex(value => {
                        return value === linuxServer.id;
                    });
                    if (index !== -1) {
                        let subProject$: Observable<SubProject>;
                        this.subProject.linuxServerIds.splice(index, 1);
                        subProject$ = of(this.subProject);
                        subProject$.subscribe((result3) => {
                            crudOperations.push({
                                data: result3,
                                operation: 'DELETE'
                            });
                            this.dialogRef.close(crudOperations);
                        });
                    } else {
                        this.dialogRef.close();
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.linuxServer.id, this.linuxServerDao, this.dialog);
    }

    onGenerateNewPrivateKey(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Ao gerar uma nova chave privada, será necessário reinstalar o agente no servidor.<br />' +
                    'Deseja continuar?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const linuxServer1: LinuxServer = this.myForm.value;
                this.linuxServerDao.generateNewPrivateKey(linuxServer1.id).subscribe((linuxServerPrivateKey) => {
                    if (linuxServerPrivateKey) {
                        download.downloadText(linuxServer1.hostname + '-' + linuxServer1.clientId + '.txt',
                            linuxServerPrivateKey.privateKey);
                        this.dialogRef.close();
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }



    openFirewallFQDNList(): void {
        this.dialog.open(FirewallFqdnComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                linuxServer: this.linuxServer,
                firewallFQDNList: this.firewallFQDNList
            }
        }).afterClosed().subscribe((result: FirewallFQDN[]) => {
            if (result) {
                this.firewallFQDNList = result;
            }
        });
    }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {NginxFile} from './nginx-file';
import {map, shareReplay} from 'rxjs/operators';
import {ImageFileService} from '../main/image-file/image-file-service';
import {DomainPathService} from '../domain-path/domain-path.service';

@Injectable()
export class BackgroundImageService {
    private static url;
    private backgroundUrl$: Observable<string>;

    constructor(
        private imageFileService: ImageFileService,
        private http: HttpClient,
        private domainPathService: DomainPathService
    ) {
        BackgroundImageService.url = domainPathService.staticURL + 'background/';
    }

    clearCache(): void {
        this.backgroundUrl$ = null;
    }

    backgroundIsLoaded(): boolean{
        return !!this.backgroundUrl$;
    }

    getRandomBackgroundUrl(imageUrls: number[] | null): Observable<string> {
        if (this.backgroundUrl$ == null) {
            if (imageUrls && imageUrls.length > 0) {
                this.backgroundUrl$ = of(this.imageFileService.getPictureUrlFromImageFileTemplateId(imageUrls[Math.floor(Math.random() * imageUrls.length)]));
            } else {
                this.backgroundUrl$ = this.http.get<NginxFile[]>(BackgroundImageService.url).pipe(
                    map((response) => {
                        const index = Math.floor(Math.random() * response.length);
                        return BackgroundImageService.url + response[index].name;
                    }), shareReplay(1));
            }
        }
        return this.backgroundUrl$;
    }

    setRandomBackground(imageUrls: number[]): void {
        const url = this.imageFileService.getPictureUrlFromImageFileTemplateId(imageUrls[Math.floor(Math.random() * imageUrls.length)]);
        this.backgroundUrl$ = of(url);
        const image = document.getElementById('img-background') as HTMLImageElement;
        if (image) {
            image.src = url;
        }
        const divBackground = document.getElementById('div-background') as HTMLDivElement;
        if (divBackground) {
            divBackground.style.backgroundImage = 'url("' + url + '")';
        }
    }

}

import {Injectable} from '@angular/core';
import {DotNetServerPluginTemplate} from '../../../models';
import {BaseDao} from '../../../base-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class DotNetServerPluginTemplateDaoService extends BaseDao<DotNetServerPluginTemplate> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'server-plugin-template');
    }
}

import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {isInteger, isNumberFinite, isPositive, toDecimal} from '../utils/utils';

export type ByteUnit = 'B' | 'kB' | 'KB' | 'MB' | 'GB' | 'TB';

@Pipe({
    name: 'bytes',
})
export class BytesPipe implements PipeTransform {
    formats: { [key: string]: { max: number; prev?: ByteUnit } } = {
        B: {max: 1024},
        kB: {max: Math.pow(1024, 2), prev: 'B'},
        KB: {max: Math.pow(1024, 2), prev: 'B'}, // Backward compatible
        MB: {max: Math.pow(1024, 3), prev: 'kB'},
        GB: {max: Math.pow(1024, 4), prev: 'MB'},
        TB: {max: Number.MAX_SAFE_INTEGER, prev: 'GB'},
    };

    transform(input: any, decimal: number = 0, from: ByteUnit = 'B', to?: ByteUnit): any {
        if (!(isNumberFinite(input) && isNumberFinite(decimal) && isInteger(decimal) && isPositive(decimal))) {
            return input;
        }

        let bytes = input;
        let unit = from;
        while (unit !== 'B') {
            bytes *= 1024;
            // tslint:disable-next-line:no-non-null-assertion
            unit = this.formats[unit].prev!;
        }

        if (to) {
            const format = this.formats[to];

            const result = toDecimal(this.calculateResult(format, bytes), decimal);

            return this.formatResult(result, to);
        }

        for (const key in this.formats) {
            if (this.formats.hasOwnProperty(key)) {
                const format = this.formats[key];
                if (bytes < format.max) {
                    const result = toDecimal(this.calculateResult(format, bytes), decimal);

                    return this.formatResult(result, key);
                }
            }
        }
    }

    formatResult(result: number, unit: string): string {
        return `${result} ${unit}`;
    }

    calculateResult(format: { max: number; prev?: ByteUnit }, bytes: number): number {
        const prev = format.prev ? this.formats[format.prev] : undefined;
        return prev ? bytes / prev.max : bytes;
    }
}

@NgModule({
    declarations: [BytesPipe],
    exports: [BytesPipe],
})
export class NgBytesPipeModule {
}

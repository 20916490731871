import {Injectable} from '@angular/core';
import {BaseSearchDao} from '../../base-search-dao';
import {ServicesStatus, SessionNumbers} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';


@Injectable()
export class StatusDaoService extends BaseSearchDao<ServicesStatus> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'status');
    }

    getServicesStatus(): Observable<ServicesStatus> {
        return this.http.get<ServicesStatus>(this.url + '/k8s');
    }

    getSessionNumbers(): Observable<SessionNumbers> {
        return this.http.get<SessionNumbers>(this.url + '/session-numbers');
    }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../auth.service';
import {switchMap} from 'rxjs/operators';
import {isWindowsOS} from '../helpers/kluh';

@Injectable()
export class HomeService implements CanActivate {
    private history = [];

    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.auth.isLoggedIn().pipe(switchMap((result) => {
            if (result) {
                return this.auth.getMyPermissions().pipe(switchMap((permissions) => {
                    this.redirectDesktop();
                    return of(false);
                }));
            } else {
                this.auth.redirectToLogin(null);
            }
        }));
    }

    private redirectDesktop(): void {
        if (!isWindowsOS()) {
            this.router.navigate(['webclient-html5']);
        } else {
            this.router.navigate(['desktop']);
        }
    }
}

<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row">
                <mat-card>
                    <mat-card-header style="height: 90px;">
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>Meus impressos</div>
                        </mat-card-title>
                        <mat-card-subtitle>
                            <small>* Atenção, os arquivos ficarão armazenados por 15 dias.</small>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <mat-icon>print</mat-icon>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="jobId">
                                <th mat-header-cell *matHeaderCellDef><div class="pl-15">Nome</div></th>
                                <td mat-cell *matCellDef="let element">
                                    Arquivo {{element.id}}
                                    <div *ngIf="element.jobTitle"> - {{element.jobTitle}}</div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef>Data de criação</th>
                                <td mat-cell *matCellDef="let element" class="w-190 no-padding">
                                    <div *ngIf="element.createdAt">
                                        {{element.createdAt | date: 'dd/MM/yyyy HH:mm - EEE'}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="printId">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" class="text-align-center" style="width: 170px;">
                                    <button type="button" mat-raised-button color="primary"
                                            (click)="openPDF(element.id)">Baixar
                                    </button>
                                    <button mat-mini-fab color="warn" class="ml-15" aria-label="delete"
                                            (click)="delete(element.id)">
                                        <mat-icon>delete_forever</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="totalPdfPrints"></mat-paginator>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
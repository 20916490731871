import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';

import {SearchComponent} from './search.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {KluhSharedModule} from '../../../kluh-manager/kluh-shared.module';

// mat-spinner

@NgModule({
    declarations: [
        SearchComponent
    ],
    imports: [
        RouterModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        FontAwesomeModule,
        FuseSharedModule,
        KluhSharedModule
    ],
    exports: [
        SearchComponent
    ]
})
export class SearchModule {
}

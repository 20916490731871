import {Component, OnInit} from '@angular/core';
import {CustomerGroupCreateEditComponent} from '../../customer-group/customer-group-create-edit/customer-group-create-edit.component';
import {CrudOperationWrapper} from '../../../helpers/kluh';

import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-register-customer-group',
    templateUrl: './register-customer-group.component.html',
    styleUrls: ['./register-customer-group.component.scss', '../register.component.scss']
})
export class RegisterCustomerGroupComponent extends ComponentCleaner implements OnInit {


    constructor(private dialog: MatDialog,
                public customerGroupService: CustomerGroupService,
                public managerUserDao: ManagerUserDaoService,
                public adminService: R2CloudAdminService,
                public imageFileService: ImageFileService) {
        super();

    }


    onEditCustomerGroup(): void {
        const dialogRef = this.dialog.open(CustomerGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                customerGroup: this.customerGroupService.customerGroup
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'SAVE') {
            this.customerGroupService.customerGroup = result.data;
            if (this.customerGroupService.customerGroups) {
                const index = this.customerGroupService.customerGroups.findIndex(value => value.id === result.data.id);
                this.customerGroupService.customerGroups[index] = result.data;
            }
        }
    }

    ngOnInit(): void {
        const customerGroups = this.customerGroupService.customerGroups;
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.managerUserDao.filter({managerUsersInRoleCustomerGroupId: customerGroup.id}).subscribe((managerUsersCustomerGroup) => {
                    if (managerUsersCustomerGroup) {
                        this.adminService.managerUsersCustomerGroup = managerUsersCustomerGroup;
                    }
                });
            }
        });
    }
}

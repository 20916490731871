import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {PermissionWrapper} from '../../../../../directives/if-permission.directive';
import {HipcomBulkUpdateCurrentSoftwareVersion, HipcomBulkUpdateLatestSoftwareVersion, HipcomBulkUpdateMessage, StatusType} from '../../../../../models';
import {HipcomBulkUpdateLatestSoftwareVersionDaoService} from './hipcom-bulk-update-latest-software-version-dao.service';
import {HipcomBulkUpdateCreateEditComponent} from '../hipcom-bulk-update-create-edit/hipcom-bulk-update-create-edit.component';
import {CrudOperationWrapper} from '../../../../../helpers/kluh';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {HipcomBulkUpdatePathCreateEditComponent} from '../hipcom-bulk-update-path-create-edit/hipcom-bulk-update-path-create-edit.component';
import {HipcomBulkUpdateMessageEditComponent} from '../hipcom-bulk-update-message-edit/hipcom-bulk-update-message-edit.component';
import {HipcomBulkUpdateCloseSoftwareCreateEditComponent} from '../hipcom-bulk-update-close-software-create-edit/hipcom-bulk-update-close-software-create-edit.component';
import {HipcomBulkUpdateMessageDaoService} from '../hipcom-bulk-update-message-edit/hipcom-bulk-update-message-dao.service';

@Component({
    selector: 'app-hipcom-bulk-update-latest-software-version',
    templateUrl: './hipcom-bulk-update-latest-software-version.component.html',
    styleUrls: ['../hipcom-bulk-update.component.scss', './hipcom-bulk-update-latest-software-version.component.scss']
})
export class HipcomBulkUpdateLatestSoftwareVersionComponent extends ComponentCleaner implements OnDestroy {

    appCanCreateList: PermissionWrapper[];

    @Output()
    eventHipcomBulkUpdateLatestSoftwareVersions = new EventEmitter<HipcomBulkUpdateLatestSoftwareVersion[]>();

    hipcomBulkUpdateLatestSoftwareVersions: HipcomBulkUpdateLatestSoftwareVersion[];

    @Input()
    hipcomBulkUpdateCurrentSoftwareVersions: HipcomBulkUpdateCurrentSoftwareVersion[];

    @Output()
    eventHipcomBulkUpdateDetails = new EventEmitter();

    hipcomBulkUpdateMessage: HipcomBulkUpdateMessage;

    timerForGetLastSoftware: any;

    constructor(
        private hipcomBulkUpdateLatestSoftwareVersionDaoService: HipcomBulkUpdateLatestSoftwareVersionDaoService,
        private hipcomBulkUpdateMessageDaoService: HipcomBulkUpdateMessageDaoService,
        private dialog: MatDialog) {
        super();

        this.timerForGetLastSoftware = setInterval(() => {
            this.getLastSoftware();
        }, 60000);


        this.hipcomBulkUpdateLatestSoftwareVersionDaoService.get().subscribe((hipcomBulkUpdateLatestList) => {
            if (hipcomBulkUpdateLatestList) {
                this.hipcomBulkUpdateLatestSoftwareVersions = hipcomBulkUpdateLatestList;
                this.eventHipcomBulkUpdateLatestSoftwareVersions.emit(this.hipcomBulkUpdateLatestSoftwareVersions);
                this.eventHipcomBulkUpdateDetails.emit();
            }
        });
        this.hipcomBulkUpdateMessageDaoService.get().subscribe(hipcomBulkUpdateMessage => {
            if (hipcomBulkUpdateMessage) {
                this.hipcomBulkUpdateMessage = hipcomBulkUpdateMessage[0];
            }
        });
    }


    openUpdateBulkCreateModal(): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdateCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {
            if (bulkUpdateOperation && bulkUpdateOperation.operation === 'SAVE') {
                this.hipcomBulkUpdateLatestSoftwareVersions = bulkUpdateOperation.data;
            } else if (bulkUpdateOperation && bulkUpdateOperation.operation === 'DELETE') {
                const index = this.hipcomBulkUpdateLatestSoftwareVersions.findIndex(value => value.id === bulkUpdateOperation.data.id);
                this.hipcomBulkUpdateLatestSoftwareVersions.splice(index, 1);
            }
        });
        this.addSubscription(subscription);
    }

    onCheckLastUpdateSoftwareFromHttp(): void {
        this.hipcomBulkUpdateLatestSoftwareVersionDaoService.readLastSoftware().subscribe(v => {
            this.getLastSoftware();
        });
    }

    getLastSoftware(): void {
        this.hipcomBulkUpdateLatestSoftwareVersionDaoService.get().subscribe((hipcomBulkUpdateLatestList) => {
            if (hipcomBulkUpdateLatestList) {
                this.hipcomBulkUpdateLatestSoftwareVersions = hipcomBulkUpdateLatestList;
                this.eventHipcomBulkUpdateLatestSoftwareVersions.emit(this.hipcomBulkUpdateLatestSoftwareVersions);
                this.eventHipcomBulkUpdateDetails.emit();
            }
        });
    }


    openUpdateBulkPathCreateModal(): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdatePathCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {

        });
        this.addSubscription(subscription);
    }

    getClass(element: HipcomBulkUpdateLatestSoftwareVersion): any {
        if (element && element.status && element.status === StatusType.ERROR) {
            return {'red-900-bg red-900': true};
        } else if (element && !element.active) {
            return {'primary-100 primary-100-bg': true};
        }
    }

    openEditDefaultMessageModal(): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdateMessageEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                hipcomBulkUpdateMessage: this.hipcomBulkUpdateMessage
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {
            if (bulkUpdateOperation && (bulkUpdateOperation.operation === 'SAVE' || bulkUpdateOperation.operation === 'CREATE')) {
                this.hipcomBulkUpdateMessage = bulkUpdateOperation.data;
            }
        });
        this.addSubscription(subscription);
    }

    openEditCloseSoftwareModal(): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdateCloseSoftwareCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {

        });
        this.addSubscription(subscription);
    }

    ngOnDestroy(): void {
        if (this.timerForGetLastSoftware) {
            clearInterval(this.timerForGetLastSoftware);
        }
    }

    toDate(dateStr: string): Date {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) {
            return null;
        }
        return date;
    }
}

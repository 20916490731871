import {Component} from '@angular/core';
import {Subject} from 'rxjs';

import {ComponentCleaner} from '../../component-cleaner';
import {ProjectCreateComponent} from './project-create/project-create.component';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {filter} from 'rxjs/operators';
import {CustomerGroup} from '../../models';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends ComponentCleaner {
    onRefresh = new Subject<void>();
    customerGroups: CustomerGroup[];

    constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private customerGroupDao: CustomerGroupDaoService) {
        super();
        this.customerGroupDao.getAllCache().subscribe((result) => {
        // this.customerGroupDao.get().subscribe((result) => {
            this.customerGroups = result;
        });
    }

    onCreateProject(): void {
        const dialogRef = this.dialog.open(ProjectCreateComponent, {
            disableClose: true,
            panelClass: 'ad-domain-edit-dialog',
            data: {
                customerGroups: this.customerGroups
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((project) => {
            // TODO implementar historico de notificação
            this.snackBar.open(project.name + ' Criado com Sucesso', null, {duration: 5000});
            this.onRefresh.next();
        });
        this.addSubscription(subscription);
    }


}


import {Component, Inject, OnDestroy} from '@angular/core';
import {SessionStateStoreService} from '../../session-state-store.service';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-session-state-modal',
    templateUrl: './session-state-modal.component.html',
    styleUrls: ['./session-state-modal.component.scss']
})
export class SessionStateModalComponent implements OnDestroy {
    private readonly connectionId: string;
    private readonly sessionId: string;
    state = 'CONNECTION_INITIALIZED';
    private readonly subscription: Subscription;
    stateCount = 0;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private sessionStateStore: SessionStateStoreService) {
        this.sessionId = data.sessionId;
        this.connectionId = data.connectionId;
        this.subscription = this.sessionStateStore.subscribe(this.sessionId, (stateObject) => {
            this.state = stateObject.state;
            if (this.stateCount < 100){
                this.stateCount = this.stateCount + 20;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription){
            this.subscription.unsubscribe();
        }
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {R2CloudAdminService} from '../../r2-cloud-admin.service';
import {MatDialog} from '@angular/material/dialog';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {CrudOperationWrapper} from '../../../../helpers/kluh';
import {FirebaseUserDetails, LinuxServer, ServerStatus, ServerStatusType} from '../../../../models';
import {LinuxServerCreateComponent} from '../../../linux-server/linux-server-create/linux-server-create.component';
import {filter, take} from 'rxjs/operators';
import {TOPIC} from '../../../../../../main';
import {faLinux} from '@fortawesome/free-brands-svg-icons';
import {ManagerUserPermissionsDaoService} from '../../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {AuthInfoService} from '../../../auth-info/auth-info.service';
import {TerminalService} from '../../../terminal/terminal.service';
import {R2CloudStompService} from '../../../../ws/r2-cloud-stomp.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-r2-cloud-admin-linux-server-item]',
    templateUrl: './r2-cloud-admin-linux-server-item.component.html',
    styleUrls: ['./r2-cloud-admin-linux-server-item.component.scss']
})
export class R2CloudAdminLinuxServerItemComponent extends ComponentCleaner implements OnInit {
    faLinux = faLinux;
    faCircle = faCircle;
    online = false;
    version = '-';
    countLoading = 0;

    @Input('linuxServerCallback')
    private linuxServerCallback: (result: CrudOperationWrapper[]) => void;

    @Input('app-r2-cloud-admin-linux-server-item')
    public linuxServer: LinuxServer;

    public user: FirebaseUserDetails;

    public isAdmin = false;

    constructor(
        public adminService: R2CloudAdminService,
        private dialog: MatDialog,
        public terminalService: TerminalService,
        private stomp: R2CloudStompService,
        private authInfoService: AuthInfoService,
        private userAuthorityDao: UserAuthorityDaoService,
        public customerGroupService: CustomerGroupService,
        private managerUserPermissionsDao: ManagerUserPermissionsDaoService
    ) {
        super();
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
        });

        this.managerUserPermissionsDao.getMyUserPermissions().pipe(take(1)).subscribe((result) => {
            if (result.admin) {
                this.isAdmin = true;
            }
        });
    }

    ngOnInit(): void {
        this.addSubscription(
            this.stomp.stompTopic<ServerStatus>(`${TOPIC}/LinuxServer.${this.linuxServer.id}.status`).subscribe((result) => {
                this.version = result.clientVersion;
                this.online = result.status === ServerStatusType.CONNECTED;
            })
        );
    }

    onEditLinuxServer(linuxServerInput: LinuxServer): void {
        const dialogRef = this.dialog.open(LinuxServerCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomains: this.adminService.adDomains,
                linuxServer: linuxServerInput,
                linuxServers: this.adminService.linuxServers,
                subProject: this.adminService.subProject,
                project: this.adminService.getProject(this.adminService.subProject.projectId)
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
            this.linuxServerCallback(result);
        });
        this.addSubscription(subscription);
    }
/*

    public openLinuxServer(linuxServer: LinuxServer): void {
        linuxServer.comment = 'LOADING';
        this.stomp.publish('/stomp/open-linux-local-admin', {id: linuxServer.id});
        setTimeout(() => {
            linuxServer.comment = '';
        }, 5000);
    }
*/

    openWebSSH(linuxServer: LinuxServer): void {
        linuxServer.comment = 'LOADING';
        this.terminalService.openWebSSH(linuxServer);
        setTimeout(() => {
            linuxServer.comment = '';
        }, 8000);
    }
}

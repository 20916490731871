import {Component} from '@angular/core';
import {StatusDaoService} from '../status-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {SessionNumbers} from '../../../models';

@Component({
    selector: 'app-session-status',
    templateUrl: './session-status.component.html',
    styleUrls: ['./session-status.component.scss', '../status.component.scss']
})
export class SessionStatusComponent extends ComponentCleaner {
    sessionNumbers: SessionNumbers = null;
    loading = false;

    constructor(private statusDaoService: StatusDaoService) {
        super();
        this.getSessionNumbers();
    }

    getSessionNumbers(): void {
        this.sessionNumbers = null;
        this.loading = true;
        this.statusDaoService.getSessionNumbers().subscribe((sessions) => {
            if (sessions) {
                this.sessionNumbers = sessions;
            }
            this.loading = false;
        });
    }


}

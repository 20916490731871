import {Injectable} from '@angular/core';
import {MarketplaceCategory} from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceCategoryService {
    constructor() {
    }

    initMarketplaceCategory(): MarketplaceCategory {
        return {
            id: null,
            name: null,
            active: true,
            comment: null,
            modified: null,
            optlock: null
        };
    }

    save(marketplaceCategory: MarketplaceCategory, marketplaceCategoryList: MarketplaceCategory[]): MarketplaceCategory[] {
        const index = marketplaceCategoryList.findIndex((o) => o.id === marketplaceCategory.id);
        if (index > -1) {
            marketplaceCategoryList[index] = marketplaceCategory;
        }
        return marketplaceCategoryList;
    }

    delete(marketplaceCategory: MarketplaceCategory, marketplaceCategoryList: MarketplaceCategory[]): MarketplaceCategory[] {
        const index = marketplaceCategoryList.findIndex((o) => o.id === marketplaceCategory.id);
        if (index > -1) {
            marketplaceCategoryList.splice(index, 1);
        }
        return marketplaceCategoryList;
    }
}

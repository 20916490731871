
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Login no Servidor ({{desktopServer.hostname}})</span>
        </mat-toolbar-row>
    </mat-toolbar>


    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
            <p>Deseja fazer login como um usuário <b>Administrador Local</b> ou
                 com o seu usuário do <b>Active directory?</b></p>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

            <button type="button" mat-raised-button
                    (mousedown)="openDesktopServer(desktopServer)"
                    class="mat-green-600-bg">
                <span>Abrir com Administrador Local</span>

            </button>
            <button type="button" mat-raised-button
                    (mousedown)="openDesktopServerADAdmin(desktopServer)"
                    class="mat-green-600-bg">
                <span>Abrir com Usuário do AD</span>
            </button>

            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseDao} from '../../base-dao';
import {Customer, SearchCriteria} from '../../models';
import {Page} from '../../helpers/Page';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class CustomerDaoService extends BaseDao<Customer> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'customers');
    }

    getActiveByProjectId(projectId: number): Observable<Customer[]> {
        return this.getActive({projectId: projectId});
    }

    search(searchCriteria: SearchCriteria): Observable<Page<Customer>> {
        return this.http.put<Page<Customer>>(this.url + '/search', searchCriteria);
    }

    getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(customerId: number, marketplaceOrderId: number): Observable<Customer> {
        return this.http.get<Customer>(this.url + '/get-one-if-it-belongs-to-my-company-or-if-have-an-order-for-my-company/' + customerId + '/' + marketplaceOrderId);
    }

    getOneExternalByManagerUserId(managerUserId: number): Observable<Customer> {
        return this.http.get<Customer>(this.url + '/get-one-external-by-manager-user-id/' + managerUserId);
    }
}

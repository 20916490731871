import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {MarketplaceCategory} from '../../../models';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable()
export class MarketplaceCategoryDaoService extends BaseDao<MarketplaceCategory> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'marketplace-category');
    }
}

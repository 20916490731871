import {Component, Inject} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';

import {DesktopServerCreateComponent} from '../../desktop-server/desktop-server-create/desktop-server-create.component';
import {AdDomainDaoService} from '../ad-domain-dao.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {ADDomain, CustomerGroup, SubProject} from '../../../models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-ad-domain-wizard',
    templateUrl: './ad-domain-wizard.component.html',
    styleUrls: ['./ad-domain-wizard.component.scss']
})
export class AdDomainWizardComponent extends ComponentCleaner {

    public myForm: FormGroup;
    public adDomainForm: FormGroup;
    public useExistingAdDomainForm: FormGroup;
    public adDomains: ADDomain[];
    private subProject: SubProject;
    private customerGroup: CustomerGroup;

    constructor(public dialogRef: MatDialogRef<DesktopServerCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private daoAdDomainService: AdDomainDaoService) {
        super();
        this.subProject = data.subProject;
        this.customerGroup = data.customerGroup;
        this.adDomains = data.adDomains;
        this.useExistingAdDomainForm = this.fb.group({
            useExistingAdDomain: false,
            adDomainId: null
        });
        let adDomain: ADDomain;
        if (data.adDomain) {
            adDomain = data.adDomain;
        } else {
            adDomain = {
                id: null,
                active: true,
                comment: null,
                subProjectIds: [this.subProject.id],
                adServerId: null,
                domainName: null,
                name: 'Dominio ' + this.subProject.name,
                customerGroupId: this.customerGroup.id,
                modified: null,
                optlock: null
            };
        }
        this.adDomainForm = this.fb.group(convertToFormGroup(adDomain), {asyncValidator: this.daoAdDomainService.validator});
        this.myForm = this.fb.group({
            adDomainForm: this.adDomainForm,
            useExistingAdDomainForm: this.useExistingAdDomainForm
        });

        const subscription = this.useExistingAdDomainForm.get('useExistingAdDomain').valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe(result => setTimeout(() => {
            if (result) {
                this.adDomainForm.disable();
                this.useExistingAdDomainForm.get('adDomainId').setValidators(Validators.required);
            } else {
                this.adDomainForm.enable();
                this.useExistingAdDomainForm.get('adDomainId').setValidators(null);
            }
        }, 0));
        this.addSubscription(subscription);
    }

    onSubmit(): void {
        let adDomain$: Observable<ADDomain>;
        let operation: CrudOperation;
        if (this.useExistingAdDomainForm.get('useExistingAdDomain').value) {
            const adDomainId: number = this.useExistingAdDomainForm.get('adDomainId').value;
            const adDomain: ADDomain = this.adDomains.find(value => {
                return adDomainId === value.id;
            });
            adDomain.subProjectIds.push(this.subProject.id);
            adDomain.name = adDomain.name.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');
            adDomain$ = this.daoAdDomainService.save(adDomain);
            operation = 'SAVE';
        } else {
            const adDomain: ADDomain = this.adDomainForm.value;
            adDomain.name = adDomain.name.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');
            if (adDomain.id) {
                adDomain$ = this.daoAdDomainService.save(adDomain);
                operation = 'SAVE';
            } else {
                adDomain$ = this.daoAdDomainService.create(adDomain);
                operation = 'CREATE';
            }
        }
        adDomain$.subscribe((result) => {
            const crudOperation: CrudOperationWrapper = {
                data: result,
                operation: operation
            };
            this.dialogRef.close(crudOperation);
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../../auth.service';
import {switchMap} from 'rxjs/operators';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';

@Injectable()
export class LoginGuardService implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.auth.isLoggedIn().pipe(switchMap((result) => {
            if (result){
                return this.userAuthorityDao.getMe().pipe(switchMap((user) => {
                    if (user) {
                        this.router.navigate(['home']);
                        return of(false);
                    } else {
                        return of(true);
                    }
                }));
            } else {
                return of(true);
            }
        }));

    }

    constructor(private userAuthorityDao: UserAuthorityDaoService,
                private auth: AuthService,
                private router: Router) {

    }

}

import {Component, Inject} from '@angular/core';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AiUserGroup, Assistant, Model} from '../../../../../models-ai';
import {ManagerUser} from '../../../../../models';
import {FormControl, Validators} from '@angular/forms';
import {AiUserGroupDaoService} from '../../ai-user-group.dao.service';
import {CustomerGroupService} from '../../../../customer-group/customer-group.service';
import {AssistantDaoService} from '../../../assistant/assistant.dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-ai-user-modal',
    templateUrl: './ai-user-group-modal.component.html',
    styleUrls: ['./ai-user-group-modal.component.scss']
})
export class AiUserGroupModalComponent extends ComponentCleaner {
    aiUserGroup: AiUserGroup;
    assistant: Assistant;
    managerUsers: ManagerUser[] = [];
    models: Model[] = [];
    managerUsersOfAiUserGroup: ManagerUser[] = [];
    managerUserIdsIdsControl: FormControl = new FormControl();
    aiUserGroupNameControl: FormControl = new FormControl();
    instructionsControl: FormControl = new FormControl();
    modelControl: FormControl = new FormControl();
    loading = false;

    constructor(
        private aiUserGroupDaoService: AiUserGroupDaoService,
        private customerGroupService: CustomerGroupService,
        private assistantDaoService: AssistantDaoService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<AiUserGroupModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();
        this.aiUserGroup = data.aiUserGroup;
        this.models = data.models;
        if (this.aiUserGroup === null) {
            this.aiUserGroup = aiUserGroupDaoService.init();
        }
        this.managerUsers = data.managerUsers;

        this.managerUsersOfAiUserGroup = this.managerUsers.filter(g => this.aiUserGroup.managerUserIds?.some(id => id === g.id));
        this.managerUserIdsIdsControl.setValue(this.managerUsersOfAiUserGroup.map(g => g.id));
        this.managerUserIdsIdsControl.setValidators([Validators.required]);

        this.aiUserGroupNameControl.setValue(this.aiUserGroup.name);
        this.aiUserGroupNameControl.setValidators([Validators.required]);

        if (this.aiUserGroup?.id) {
            this.assistantDaoService.findByVectorStoreId(this.aiUserGroup.vectorStoreId).subscribe((assistant) => {
                this.assistant = assistant;
                this.instructionsControl.setValue(this.assistant.instructions);
                this.instructionsControl.setValidators([Validators.required]);

                this.modelControl.setValue(this.assistant.model);
                this.modelControl.setValidators([Validators.required]);
            });

        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        this.loading = true;
        this.aiUserGroup.managerUserIds = this.managerUserIdsIdsControl.value;
        if (this.aiUserGroup.id) {
            this.assistant.model = this.modelControl.value;
            this.assistant.instructions = this.instructionsControl.value;
            this.save(this.aiUserGroup, this.assistant);
        } else {
            this.aiUserGroup.customerGroupId = this.customerGroupService.customerGroup.id;
            this.aiUserGroup.name = this.aiUserGroupNameControl.value;
            this.create(this.aiUserGroup);
        }
    }

    onDelete(): void {

        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<div>Tem certeza que deseja remover esse Grupo?<br>' +
                    '<br><br> Para remover digite <u>DELETE</u> no campo abaixo</div>',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Deletar',
                icon: 'error_outline',
                confirmFieldValue: 'DELETE'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.loading = true;
                this.aiUserGroupDaoService.delete(this.aiUserGroup).subscribe(_ => {
                    this.dialogRef.close({
                        data: this.aiUserGroup,
                        operation: 'DELETE'
                    });
                });
            }
        }));
    }

    private save(aiUserGroup: AiUserGroup, assistant: Assistant): void {
        combineLatest([
            this.aiUserGroupDaoService.save(aiUserGroup),
            this.assistantDaoService.save(assistant)
        ]).subscribe(values => {
            this.aiUserGroup = values[0];
            this.assistant = values[1];
            this.dialogRef.close({
                data: this.aiUserGroup,
                operation: 'SAVE'
            });
        });
    }

    private create(aiUserGroup: AiUserGroup): void {
        this.aiUserGroupDaoService.create(aiUserGroup).subscribe(aiUserGroupFromDTO => {
            this.aiUserGroup = aiUserGroupFromDTO;
            this.dialogRef.close({
                data: aiUserGroupFromDTO,
                operation: 'CREATE'
            });
        });
    }

    isValidForm(): boolean {
        const managerUsers: number[] = this.managerUserIdsIdsControl.value;
        return !!(managerUsers?.length > 0 && this.aiUserGroupNameControl.value);
    }
}

import {EventProvider} from './event-provider';
import {StoreType} from './enum/store-type';
import {StoreEvent} from './enum/store-event';
import {ObjectHelper} from './object-helper';
import {StoreTransactionMode} from './enum/store-transaction-mode';
import {Session} from './session';

export class Store {
    id: any;
    events = new EventProvider();
    private c: any;
    private d: any;
    private h: any[] = [];
    private i: any[] = [];
    private j: any = {};

    constructor(private a: any, private b: any, private cc: any, private dd: any, private e: any, private f: any) {
        this.c = cc;
        this.d = dd;
        // tslint:disable-next-line:triple-equals
        'function' == typeof this.c && this.c || (this.c = (): any => {
            return '';
            // tslint:disable-next-line:triple-equals
        }), 'function' == typeof this.d && this.c || (this.d = (): any => {
            return null;
        }), this.k();
    }

    private k = (): void => {
        let a, c;
        if (this.b === StoreType.Persistent) {
            if (!('sessionStorage' in window) || null === window.sessionStorage) {
                throw new Error('Session storage is not supported');
            }
            this.j = {};
            for (a in sessionStorage) {
                if (sessionStorage.hasOwnProperty(a)) {
                    sessionStorage.hasOwnProperty(a) && 0 === a.indexOf(this.e) && (c = this.d(sessionStorage[a])) && (this.i[c.id] = c, this.j[c.id] = c);
                }
            }
        }
    };

    private l = (a: any): any => {
        return this.j.hasOwnProperty(a);
    };

    private m = (a: any): void => {
        this.b === StoreType.Persistent && delete sessionStorage[this.e + a], delete this.j[a], this.events.fireEventCallback(StoreEvent.RecordRemoved, a);
    };

    private n = (a: any): void => {
        let d = !1;
        void 0 !== a.id && !0 === this.l(a.id) && (d = !0), this.b === StoreType.Persistent && (sessionStorage[this.e + a.id] = this.c(a)), this.j[a.id] = a,
            d ? this.events.fireEventCallback(StoreEvent.RecordUpdated, a.id) : this.events.fireEventCallback(StoreEvent.RecordAdded, a.id);
    };

    private o = (a: any): void => {
        let b;
        for (b = this.h.length - 1; b >= 0; b--) {
            this.h[b].obj.id === a && this.h.splice(b, 1);
        }
    };


    findExcludingCommit(a: any): any {
        return this.l(a) ? (new ObjectHelper()).cloneObject(this.j[a]) : null;
    }

    find(b: any): Session {
        let c;
        this.k();
        c = this.j;
        if (this.a === StoreTransactionMode.Transactional) {
            c = this.i;
            return (new ObjectHelper()).cloneObject(c[b]);
        } else {
            if (c.hasOwnProperty(b)) {
                return (new ObjectHelper()).cloneObject(c[b]);
            } else {
                return null;
            }
        }
        // return this.k(), c = this.j, this.a === StoreTransactionMode.Transactional ? (c = this.i, (new ObjectHelper).cloneObject(c[b])) : c.hasOwnProperty(b) ?
        //     (new ObjectHelper()).cloneObject(c[b]) : null;
    }

    list(b: any): Session[] {
        let c, d;
        const e = this.j, f = [],
            g = (a: any, bb: any): Session[] => {
                // tslint:disable-next-line:forin
                for (c in bb) {
                    bb.hasOwnProperty(c) && (d = bb[c].id, f.hasOwnProperty(d) ? (a.push(f[d]), delete f[d]) : a.push((new ObjectHelper).cloneObject(bb[c])));
                }
                return a;
            };
        if (this.k(), void 0 === b || null === b) {
            // tslint:disable-next-line:no-parameter-reassignment
            b = [];
        } else {
            for (c in b) {
                if (b.hasOwnProperty(c)) {
                    f[b[c].id] = b[c];
                }
            }
            b.splice(0, b.length);
        }
        return this.a === StoreTransactionMode.Transactional ? g(b, this.i) : g(b, e);
    }

    remove(b: any): void {
        this.a === StoreTransactionMode.Transactional ? (this.o(b), this.l(b) && (this.h.push({
            obj: {
                id: b
            },
            type: 'Delete'
        }), delete this.i[b])) : this.m(b);
    }

    removeAll(): void {
        let a;
        // tslint:disable-next-line:forin
        for (a in this.j) {
            this.j.hasOwnProperty(a) && this.remove(this.j[a].id);
        }
    }

    findAllKeys(): any {
        return Object.keys(this.j);
    }

    save(b: any): void {
        let c;
        c = this.f ? (new ObjectHelper).cloneObject(b) : b, this.a === StoreTransactionMode.Transactional ? (this.o(b.id), this.h.push({
            obj: c,
            type: 'Add'
        }),
        void 0 !== b.id && (this.i[c.id] = c)) : this.n(c);
    }

    commit(): void {
        let b;
        if (this.a === StoreTransactionMode.Transactional) {
            for (b in this.h) {
                if (this.h.hasOwnProperty(b)) {
                    const c = this.h[b];
                    'Add' === c.type ? this.n(c.obj) : this.m(c.obj.id);
                }
            }
        }
        this.h = [], this.events.fireEventCallback(StoreEvent.CommitComplete);
    }

    rollback(): void {
        let a;
        this.i = [];
        // tslint:disable-next-line:forin
        for (a in this.j) {
            this.i[this.j[a].id] = this.j[a];
        }
        this.h = [], this.events.fireEventCallback(StoreEvent.RollbackComplete);
    }

}

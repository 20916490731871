import {Injectable, OnDestroy} from '@angular/core';
import {QueryParam} from './query-param';
import {filter} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class QueryParamsService implements OnDestroy {
    private queryParams: QueryParam[] = [];
    private subscription: Subscription;
    constructor(private router: Router, private route: ActivatedRoute, private location: Location) {
        this.subscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((x) => {
            // console.debug(x);
            this.queryParams = this.queryParams.filter((o) => o.preserve);
            this.setUrlQueryParams();
        });
    }
    public setCustomUrlQueryParam(url: string, queryParams: NavigationExtras): void {
        this.router.navigate([url], queryParams);
    }

    public setUrlQueryParam(queryParam: QueryParam): void {
        if (queryParam){
            const index = this.queryParams.findIndex((o) => {
                return o.id === queryParam.id;
            });
            if (queryParam.value){
                if (index > -1){
                    this.queryParams[index] = queryParam;
                } else {
                    this.queryParams.push(queryParam);
                }
            } else {
                if (index > -1){
                    this.queryParams.splice(index, 1);
                }
            }
        }
        this.setUrlQueryParams();
    }

    private setUrlQueryParams(): void {
        const queryParams = Object.assign({}, this.route.snapshot.queryParams);
        for (const queryParam of this.queryParams) {
            if (queryParam.value){
                queryParams[queryParam.id] = queryParam.value;
            }
        }
        const urlTree = this.router.createUrlTree([], {relativeTo: this.route, queryParams: queryParams});
        this.location.go(urlTree.toString());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}

import {Observer} from 'rxjs';
import {v4} from 'uuid';

export class R2CloudClientSubscriber<T> {
    public id: string;

    constructor(public observer: Observer<T>, public queue?: string){
        this.id = v4().replace(/-/g, '');
    }
}

import {Component, ViewChild} from '@angular/core';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {ComponentCleaner} from '../../../../component-cleaner';
import {CustomerGroup, HipcomBulkUpdateCurrentSoftwareVersion, HipcomBulkUpdateDetail, HipcomBulkUpdateLatestSoftwareVersion, SubProject} from '../../../../models';
import {HipcomBulkUpdateDetailComponent} from './hipcom-bulk-update-detail/hipcom-bulk-update-detail.component';

@Component({
    selector: 'app-hipcom-bulk-update',
    templateUrl: './hipcom-bulk-update.component.html',
    styleUrls: ['./hipcom-bulk-update.component.scss']
})
export class HipcomBulkUpdateComponent extends ComponentCleaner {

    appCanCreateList: PermissionWrapper[];
    subProjects: SubProject[] = [];
    hipcomBulkUpdateLatestSoftwareVersions: HipcomBulkUpdateLatestSoftwareVersion[];
    hipcomBulkUpdateCurrentSoftwareVersions: HipcomBulkUpdateCurrentSoftwareVersion[] = [];
    hipcomBulkUpdateDetails: HipcomBulkUpdateDetail[];

    @ViewChild(HipcomBulkUpdateDetailComponent) appHipcomBulkUpdateDetail: HipcomBulkUpdateDetailComponent;

    constructor(public customerGroupService: CustomerGroupService) {
        super();
        this.appCanCreateList = [
            {type: 'ManagerUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
    }

    eventHipcomBulkUpdateDetails(): void {
        this.appHipcomBulkUpdateDetail.loadHipcomBulkUpdateDetails();
    }

    eventHipcomBulkUpdateLatestSoftwareVersions(event: HipcomBulkUpdateLatestSoftwareVersion[]): void {
        this.hipcomBulkUpdateLatestSoftwareVersions = event;
    }

    eventHipcomBulkUpdateDetailDataList(event: HipcomBulkUpdateDetail[]): void {
        this.hipcomBulkUpdateDetails = event;
    }

    eventHipcomBulkUpdateCurrentSoftwareVersions(event: HipcomBulkUpdateCurrentSoftwareVersion[]): void {
        this.hipcomBulkUpdateCurrentSoftwareVersions = event;
    }
}

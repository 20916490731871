<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
            <mat-icon class="nav-link-icon" *ngIf="icon.startsWith('fa'); else notFa"
                      fontSet="fa"
                      [fontIcon]="icon"></mat-icon>
            <ng-template #notFa>
                <mat-icon class="icon">{{icon}}</mat-icon>
            </ng-template>
        </mat-toolbar>
        <div class="p-16 message" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <div class="mat-body-2" [innerHTML]="message"></div>
            <div style="min-height: 100px;" [hidden]="disableConfirmField">
                <mat-form-field appearance="outline" class="full-width"
                                style="max-width: 520px;max-height: 70px;margin: 20px 0 20px 0;">
                    <mat-label>Confirme</mat-label>
                    <input matInput placeholder="Confirme"
                           id="confirm-dialog-confirm"
                           name="confirmField" required
                           formControlName="confirmField">
                </mat-form-field>
                <div *ngIf="confirmFieldError" style="margin-left: 20px;margin-bottom: 50px;">
                    <b class="warn-A700-fg">*{{confirmFieldError}}</b>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
            <div>
                <button type="submit" mat-raised-button color="accent" id="confirm-dialog-ok" >{{confirmButtonValue}}</button>
            </div>
            <div *ngIf="!disableCancel">
                <button mat-raised-button type="button" color="primary" class="md-small" (click)="onCancel()">{{cancelButtonValue}}
                </button>
            </div>
        </div>
</form>

import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {ManagerUser, ManagerUserProvider} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class ManagerUserAndAdUserDaoService extends BaseDao<ManagerUser> {

  constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
    super(http, validatorDAO, 'manager-users');
  }

  getProviders(): Observable<ManagerUserProvider[]> {
    return this.http.get<ManagerUserProvider[]>(this.url + '/providers');
  }
}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {SoftwareCompany} from '../../../models';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable()
export class SoftwareCompanyDaoService extends BaseDao<SoftwareCompany> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'software-company');
    }
}

import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {HipcomBulkUpdateLatestSoftwareVersion, StatusType} from '../../../../../models';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {CrudOperationWrapper} from '../../../../../helpers/kluh';
import {HipcomBulkUpdateLatestSoftwareVersionDaoService} from '../hipcom-bulk-update-latest-software-version/hipcom-bulk-update-latest-software-version-dao.service';

@Component({
    selector: 'app-hipcom-bulk-update-create-edit',
    templateUrl: './hipcom-bulk-update-create-edit.component.html',
    styleUrls: ['./hipcom-bulk-update-create-edit.component.scss'],
    queries: {
        'contentRef': new ViewChild('contentRef', {static: false})
    },
})
export class HipcomBulkUpdateCreateEditComponent extends ComponentCleaner {

    myForm: FormGroup;
    myFormArray: FormArray;
    contentRef!: ElementRef;
    myFormControls: FormGroup;

    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdateCreateEditComponent>,
                private hipcomBulkUpdateLatestSoftwareVersionDaoService: HipcomBulkUpdateLatestSoftwareVersionDaoService,
                private fb: FormBuilder,
                private dialog: MatDialog) {
        super();
        this.myFormArray = this.fb.array([]);
        this.myForm = this.fb.group({myFormArray: this.myFormArray});
        this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;
        this.hipcomBulkUpdateLatestSoftwareVersionDaoService.get().subscribe(hipcomBulkUpdateList => {
            if (hipcomBulkUpdateList) {
                for (const hipcomBulkUpdate of hipcomBulkUpdateList) {
                    this.onAddHipcomBulkUpdate(hipcomBulkUpdate);
                }
            } else {
                this.onAddHipcomBulkUpdate();
            }
        });
    }

    onAddHipcomBulkUpdate(hipcomBulkUpdate?: HipcomBulkUpdateLatestSoftwareVersion): void {
        this.myFormArray.push(this.buildHipcomBulkUpdate(hipcomBulkUpdate));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const hipcomBulkUpdateList: HipcomBulkUpdateLatestSoftwareVersion[] = this.myForm.value.myFormArray;
        const hipcomBulkUpdateCreateList = [];
        const hipcomBulkUpdateSaveList = [];

        for (const hipcomBulkUpdate of hipcomBulkUpdateList) {
            if (hipcomBulkUpdate.id) {
                hipcomBulkUpdateSaveList.push(hipcomBulkUpdate);
            } else {
                hipcomBulkUpdateCreateList.push(hipcomBulkUpdate);
            }
        }
        this.hipcomBulkUpdateLatestSoftwareVersionDaoService.saveAll(hipcomBulkUpdateSaveList).subscribe((hipcomBulkUpdateSavedList) => {
            this.hipcomBulkUpdateLatestSoftwareVersionDaoService.createAll(hipcomBulkUpdateCreateList).subscribe((hipcomBulkUpdateCreatedList) => {
                const allHipcomBulkUpdate: HipcomBulkUpdateLatestSoftwareVersion[] = [];
                if (hipcomBulkUpdateSavedList) {
                    for (const bulkUpdate of hipcomBulkUpdateSavedList) {
                        allHipcomBulkUpdate.push(bulkUpdate);
                    }
                }
                if (hipcomBulkUpdateCreatedList) {
                    for (const bulkUpdate of hipcomBulkUpdateCreatedList) {
                        allHipcomBulkUpdate.push(bulkUpdate);
                    }
                }
                const crudOperation: CrudOperationWrapper = {
                    operation: 'SAVE',
                    data: allHipcomBulkUpdate
                };
                this.dialogRef.close(crudOperation);
            });
        });
    }

    onRemove(bulkUpdate: HipcomBulkUpdateLatestSoftwareVersion): void {
        if (bulkUpdate?.id !== null) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Você tem certeza que deseja remover esse programa da lista de atualização? ' +
                        '<br><div class="warn-A700-fg">' + bulkUpdate.name + '<br>' + bulkUpdate.executableName + '</div>',
                    disableCancel: false,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.hipcomBulkUpdateLatestSoftwareVersionDaoService.remove(bulkUpdate.id).subscribe((removed) => {
                        const crudOperation: CrudOperationWrapper = {
                            operation: 'DELETE',
                            data: bulkUpdate
                        };
                        this.dialogRef.close(crudOperation);
                    });
                }
            });
            this.addSubscription(subscription);
        } else {
            this.removeFromList(bulkUpdate);
        }
    }


    public removeFromList(bulkUpdate: HipcomBulkUpdateLatestSoftwareVersion): void {
        for (let i = 0; i < this.myFormArray.value.length; i++) {
            if (this.myFormArray.value[i] === bulkUpdate) {
                this.myFormArray.removeAt(i);
            }
        }
    }


    buildHipcomBulkUpdate(hipcomBulkUpdate?: HipcomBulkUpdateLatestSoftwareVersion): FormGroup {
        let hipcomBulkUpdateObj: HipcomBulkUpdateLatestSoftwareVersion = {
            id: null, active: true, comment: null,
            name: null,
            folderName: null,
            executableName: null,
            version: null,
            link: null,
            status: StatusType.WAITING,
            startAt: null,
            checkedAt: null,
            finishedAt: null,
            modified: null,
            optlock: null
        };
        if (hipcomBulkUpdate) {
            hipcomBulkUpdateObj = hipcomBulkUpdate;
        }
        return this.fb.group(hipcomBulkUpdateObj);
    }

    onHipcomBulkUpdate(hipcomBulkUpdate?: HipcomBulkUpdateLatestSoftwareVersion): void {
        this.myFormArray.push(this.buildHipcomBulkUpdate(hipcomBulkUpdate));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }

}

<form [formGroup]="adUserForm"
      (submit)="!((adUserPasswordForm.pristine && adUserForm.pristine) || (!adUserForm.value.id && !adUserForm.valid)) && !passwordError && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        {{adUserForm.get('id').value ? 'Edição' : 'Criação'}} de Usuário de acesso {{defaultCustomPortalTemplate?.title}} Serviço
          {{adUserForm.get('id').value ? adUserForm.get('login').value : ''}}
      </span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">


            <mat-form-field  appearance="outline" *ngIf="adDomains && adDomains.length > 1" [hidden]="adUserForm.get('id').value">
                <mat-label>Domínio</mat-label>
                <mat-select placeholder="Domínio" formControlName="adDomainId">
                    <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                        {{adDomain.name}} | {{adDomain.domainName}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{adUserForm.get('adDomainId').errors | error}}
                </mat-error>
            </mat-form-field>


            <mat-form-field appearance="outline" class="mb-10">
                <mat-label>Usuários permitidos</mat-label>
                <mat-select placeholder="Usuários que terão acesso" (click)="markAsTouchedAndDirty()" formControlName="managerUserServiceIds" multiple>
                    <mat-option *ngFor="let managerUser of adminService.getOrderedManagerUsersByEmail(managerUsers)" [value]="managerUser.id">
                        {{managerUser.email}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div fxLayout="column" fxLayoutAlign="none stretch">
                <mat-form-field *ngIf="!adUserExternalLoad && !adUserForm.get('id').value"  appearance="outline">
                    <mat-label>Usuário serviço</mat-label>
                    <input matInput formControlName="login" placeholder="login do usuário de serviço">
                    <mat-error>
                        {{adUserForm.get('login').errors | error}}
                    </mat-error>
                </mat-form-field>
                <div *ngIf="adUserExternalLoad || adUserForm.get('id').value" class=" mb-5">
                    <div class="float-left">Login do usuário de serviço:</div>
                    <div style="clear: both;"></div>
                    <div class="float-left mr-10 pt-8 mb-5"><b
                        style="font-size: 16px">{{adUserForm.get('login').value}}</b></div>
                </div>
                <div [hidden]="!customAdUserForm.value" class="full-width pr-40">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Senha do usuário</mat-label>
                        <input matInput type="password" [formControl]="adUserPasswordForm" placeholder="Senha do usuário de serviço">
                        <mat-error *ngIf="adUserPasswordForm.errors">
                            Algum caracter não permitido ( aspas simples ou espaço )
                        </mat-error>
                    </mat-form-field>
                    <div style="clear: both"></div>
                    <mat-form-field appearance="outline" class="full-width pr-40">
                        <mat-label>Repita a senha</mat-label>
                        <input matInput type="password" [formControl]="adUserRepeatPasswordForm"
                               placeholder="Repita a senha">
                        <mat-error *ngIf="adUserRepeatPasswordForm.errors">
                            Senhas não são iguais
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-checkbox *ngIf="!adUserExternalLoad" aria-label="Cadastrar usuário de RDP existente"
                              [formControl]="customAdUserForm"
                              class="mt-15 mb-20 font-size-18 text-bold">
                    {{adUserForm.get('id').value ? 'Alterar senha' : 'Cadastrar usuário de RDP existente'}}
                </mat-checkbox>
            </div>

            <div *appIfPermission="appCanCreateList" class="mb-30">
                <div class="mt-15 mb-15" *ngIf="adUserForm.get('id').value">
                    <div class="font-weight-900" *ngIf="adUserPw !=''">Senha:</div>
                    <div *ngIf="adUserPw ==''">
                        <button type="button" mat-raised-button
                                (click)="findByLoginPw(adUserForm.get('login').value, adUserForm.get('adDomainId').value)">
                            Mostrar senha
                        </button>
                    </div>
                    <div *ngIf="adUserPw !=''">
                        {{adUserPw}}
                    </div>

                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

            <div matTooltip="Salvar">
                <button type="submit" mat-raised-button color="accent"
                        [disabled]="(
                        adUserPasswordForm.pristine && adUserForm.pristine) ||
                        (!adUserForm.value.id && !adUserForm.valid) ||
                        (adUserPasswordForm.errors && customAdUserForm.value === true) ||
                        passwordError

"
                        aria-label="Salvar">
                    {{adUserForm.get('id').value ? 'Salvar' : 'Criar'}}
                </button>
            </div>

            <div *ngIf="adUserForm.get('id').value">
                <button type="button" mat-raised-button color="warn" (click)="onRemove()">Excluir</button>
            </div>
            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

            </div>

            <div *ngIf="adUser">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px" >
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: adUser; type: 'ADUser'"
                            (click)="onJaversHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: adUser; type: 'ADUser'"
                            (click)="onJaversAllHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico completo</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</form>

<mat-expansion-panel (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false">
<mat-expansion-panel-header>
    <mat-panel-title>
        <div class="pt-3">
                    <span [ngClass]="{
                    'primary-100-fg': !statusOverview,
                    'warn-A700-fg blink_me': ((serverOverview.cpu > 85 || serverOverview.cpu < 0) && statusOverview),
                    'accent-A700-fg': (serverOverview.cpu <= 85 && statusOverview)
                    }" >
                        <span *ngIf="serverOverview.cpu > -1 && statusOverview">{{serverOverview.cpu}}%</span>
                        <span *ngIf="serverOverview.cpu < 0">- %</span>
                         CPU
                    </span>
            <span class="primary-100-fg"> | </span>
            <span [ngClass]="{
                    'warn-A700-fg blink_me': (serverOverview.memory > 90 && statusOverview),
                    'accent-A700-fg': ((serverOverview.memory <= 90 || serverOverview.memory < 0) && statusOverview),
                    'primary-100-fg': !statusOverview
                    }">
                        <span *ngIf="serverOverview.memory > -1 && statusOverview">{{serverOverview.memory}}%</span>
                        <span *ngIf="serverOverview.memory < 0">- %</span>
                      RAM
                    </span>

            <span class="primary-100-fg"> | </span>
            <span [ngClass]="{
                        'primary-100-fg': !statusOverview
                        }" >
                        HD
                    </span>
                <span class="text-max-250 float-right">
                    <span *ngFor="let hd of  serverOverview.hdList;let i = index">
                            <span [ngClass]="{
                            'warn-A700-fg blink_me': (hd.value > 85 && statusOverview),
                            'accent-A700-fg': ((hd.value <= 85 || hd.value < 0) && statusOverview),
                            'primary-100-fg': !statusOverview
                            }" >
                                <span class="pl-10 pr-5">{{hd.driveName}}</span>
                                <span *ngIf="hd.value > -1 && statusOverview" >{{hd.value}}%</span>
                                <span class="warn-A700-fg" *ngIf="hd.value < 0">- %</span>
                            </span>
                    </span>
                </span>


        </div>
    </mat-panel-title>
</mat-expansion-panel-header>
<!--
<div>
    <p>Google</p>
    <p>custom </p>
    <p>4 vCPU</p>
    <p>16GB RAM</p>
    <hr>
    <p>Algum detalhe especifico no host. Algum detalhe especifico no host.
        Algum detalhe especifico no host. Algum detalhe especifico no host.
        Algum detalhe especifico no host. Algum detalhe especifico no host. </p>

    <div *ngFor="let hd of  this.hdList;let i = index">
                <span class="pl-10 pr-5">{{hd.driveName}}:</span>
                <span class="pl-10 pr-5">{{hd.id}}:</span>
            </div>

</div>
-->
</mat-expansion-panel>

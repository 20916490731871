<mat-card class="ml-8">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Aplicações</div>
            <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px">
                    <button mat-menu-item (click)="openUpdateBulkCreateModal()">
                        <mat-icon>assignment</mat-icon>
                        <span>Configuração modulos</span>
                    </button>
                    <button mat-menu-item (click)="openUpdateBulkPathCreateModal()">
                        <mat-icon>assignment</mat-icon>
                        <span>Caminhos padrões de instalação</span>
                    </button>
                    <button mat-menu-item (click)="openEditCloseSoftwareModal()">
                        <mat-icon>account_balance_wallet</mat-icon>
                        <span>Fechar programas antes de atualizar</span>
                    </button>
                    <button mat-menu-item (click)="openEditDefaultMessageModal()">
                        <mat-icon>mode_comment</mat-icon>
                        <span>Mensagem padrão de aviso antes de atualizar</span>
                    </button>
                    <button mat-menu-item (click)="onCheckLastUpdateSoftwareFromHttp()">
                        <mat-icon>play_circle_outline</mat-icon>
                        <span>Buscar atualizações no repositório</span>
                    </button>
                </mat-menu>
            </div>


        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table class="width-100-percent bulk-update-table">
            <thead class="no-hover">
            <tr>
                <th>Software</th>
                <th>Versão</th>
                <th>Verificação</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of hipcomBulkUpdateLatestSoftwareVersions" [ngClass]="getClass(item)">
                <td>{{item.name}}</td>
                <td>
                    <div *ngIf="toDate(item.version) as date; else bElse">
                        {{date | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </div>
                    <ng-template #bElse>
                        <div>
                            {{item.version}}
                        </div>
                    </ng-template>
                </td>
                <td>{{item.checkedAt | timeDiff: 'medium'}}</td>
                <td>{{item.status}}</td>
            </tr>
            </tbody>
        </table>

    </mat-card-content>
</mat-card>

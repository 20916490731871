import {R2CloudHttpBase} from './r2-cloud-http.base';
import {KluhManagerValidator} from './validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {AsyncValidatorFn} from '@angular/forms';
import {ChartPoint} from './models';


export abstract class BaseMonitoringDao<T extends BaseIdLong> {

    protected url: string;
    validator: AsyncValidatorFn;

    protected constructor(protected http: R2CloudHttpBase, protected validatorDAO: KluhManagerValidator, url: string) {
        this.url = url;
        this.validator = this.validatorDAO.validator(this.url, this.http);
    }


    get(): Observable<T[]> {
        return this.http.get<T[]>(this.url);
    }

    getOne(id: number): Observable<T> {
        return this.http.get<T>(this.url + '/' + id);
    }

    create(entity: T): Observable<T> {
        return this.http.post<T>(this.url + '/', entity);
    }

    save(entity: T): Observable<T> {
        return this.http.put<T>(this.url + '/', entity);
    }

    createAll(entityList: T[]): Observable<T[]> {
        return this.http.post<T[]>(this.url + '/batch-create', entityList);
    }

    delete(entityId: number): Observable<string> {
        return this.http.remove(this.url + '/' + entityId);
    }

    deleteByServerIdAndMetricType(desktopServerId: number, metricType: string): Observable<string> {
        return this.http.remove(this.url + '/delete-by-server-id-and-server-type/' + metricType + '/' + desktopServerId);
    }

    averageLastMinutes(serverId: number, metricType: string, lastMinutes: number): Observable<string> {
        return this.http.get<string>(this.url + '/average-last-minutes/' + metricType + '/' + serverId + '/' + lastMinutes);
    }

    findByServerIdAndMetricTypeAndRangeOfCreatedAt(serverId: number, metricType: string, from: string, to: string, resolution: number): Observable<ChartPoint[]> {
        return this.http.get<ChartPoint[]>(
            this.url + '/find-by-server-id-and-server-type-and-range-of-created-at/' + metricType + '/' + serverId,
            {
                from: from,
                to: to,
                resolution: resolution
            }
        );
    }

    dataGeneratorOfTheLastDays(entity: T, days: number): Observable<string> {
        return this.http.post<string>(this.url + '/data-generator-of-the-last-days/' + days, entity);
    }

    findAllByClientId(clientId: string): Observable<T[]> {
        return this.http.get<T[]>(this.url + '/find-all-by-client-id/' + clientId);
    }

}

export interface BaseIdLong {
    createdAt: Date | null;
}

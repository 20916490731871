import {EventWrapper} from './EventWrapper';

export class EventProvider {
    private b: EventWrapper[] = [];

    constructor() {

    }


    filter(a: any): any {
        return this.b.filter(function (b: any): any {
            return b.event === a;
        });
    }

    fireEventCallback(b: any, ...args: any): void {
        let c, e;
        const d = this.filter(b);
        e = args;
        if (d.length > 0) {
            for (c = 0; c < d.length; c++) {
                e = e.concat(d[c].arg);
                d[c].callback.apply(this, e);
            }
        }
    }

    subscribe(a: any, c: any, ...args: any[]): void {
        let d;
        const f = [];
        for (d in this.b) {
            if (this.b.hasOwnProperty(d) && this.b[d].event === a && this.b[d].callback === c) {
                return;
            }
        }
        for (const arg of args) {
            f.push(arg);
        }
        this.b.push({
            event: a,
            callback: c,
            arg: f
        });
    }

    unsubscribe(a: any, c: any): void {
        let d;
        for (d = 0; d < this.b.length; d++) {
            if (this.b[d].event === a && this.b[d].callback === c) {
                this.b.splice(d, 1);
                break;
            }
        }
    }
}

<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header class="pt-0">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Últimos AdGroups</div>
            <div fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" class="h-70 mr-5">
                    <mat-select placeholder="Quantidade" [formControl]="sizeListControl">
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="50">50</mat-option>
                        <mat-option [value]="100">100</mat-option>
                        <mat-option [value]="200">200</mat-option>
                        <mat-option [value]="300">300</mat-option>
                        <mat-option [value]="500">500</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="h-70 mr-5">
                    <mat-select placeholder="tipo" [formControl]="snapshotTypeControl">
                        <mat-option [value]="snapshotType.ALL">Todos</mat-option>
                        <mat-option [value]="snapshotType.INITIAL">Criado</mat-option>
                        <mat-option [value]="snapshotType.UPDATE">Edição</mat-option>
                        <mat-option [value]="snapshotType.TERMINAL">Delete</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button type="button" color="primary" class="md-small" (click)="refresh()">Enviar
                </button>
            </div>
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th style="width: 300px"></th>
                <th></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let obj of adGroupsSnapshots">
                <td>
                    <div>
                        <b *ngIf="obj.state.name; else existsEmail">{{ obj.state.name }} - {{ obj.state.prefix }}</b>
                        <ng-template #existsEmail>
                            <b>cdoId: {{ obj.globalId.cdoId | json }}</b>
                        </ng-template>
                    </div>
                    <div class="font-size-12"><b>por:</b> {{ obj.commitMetadata?.author }}</div>
                    <div class="font-size-12">{{ obj.commitMetadata?.commitDate }}</div>
                    <button mat-raised-button type="button" color="primary" class="md-small mt-5"
                            (click)="onJaversHistory(obj.globalId.cdoId)">Histórico
                    </button>
                </td>
                <td>
                    {{ obj.commitMetadata.commitDate | timeDiff: 'large' }}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
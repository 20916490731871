<mat-card >
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Empresa</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="column mt-4" *ngIf="customerGroupService.customerGroup">
            <div fxLayout="row" fxLayoutAlign="start start" class="model1">
                <div class="avatar">
                    <img src="{{imageFileService.getCustomerGroupPictureUrl(customerGroupService.customerGroup.imageUUID, 150)}}" width="150" height="150">
                </div>
                <div class="fields">
                    <div class="font-size-26 mb-8">{{customerGroupService.customerGroup.name}}</div>
                    <div class="font-size-14 mb-15" style="clear: both;display: block;">
                        {{customerGroupService.customerGroup.contractedUsers}} usuários simultâneos
                    </div>
                    <div>
                        <button *appIfPermission="'WRITE'; type: 'CustomerGroup'; data: customerGroupService.customerGroup"
                                id="register-customer-group-edit"
                                (click)="onEditCustomerGroup()" mat-raised-button>Editar</button>
                    </div>
                    <div *ngIf="customerGroupService.customerGroup.blockAllAccess"
                        class="blocked-user mt-15 font-size-16">Bloqueado</div>
                </div>

            </div>
        </div>
    </mat-card-content>
</mat-card>
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, finishedLoading, isLoading, setLoading, validateEmail} from '../../../helpers/kluh';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ADAdmin, ADDomain, ADUser, ADUserWithPassword, Customer, DesktopServer, FilterByEmailAndAdDomainIdAndSubProjectId, ManagerUser, Project, ServerType} from '../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ScriptExecutionDaoService} from '../../script-execution/script-execution-dao.service';
import {AdAdminDaoService} from '../../ad-admin/ad-admin.dao.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ManagerUserPermissionsDaoService} from '../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';
import {DesktopServerService} from '../../desktop-server/desktop-server.service';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {CustomLayoutService, initCustomPortalTemplate} from '../../custom-layout/custom-layout.service';
import {EditAdUserPasswordComponent} from '../edit-ad-user-password/edit-ad-user-password.component';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';


@Component({
    selector: 'app-manager-user-and-ad-user-create-edit',
    templateUrl: './manager-user-and-ad-user-create-edit.component.html',
    styleUrls: ['./manager-user-and-ad-user-create-edit.component.scss']
})

export class ManagerUserAndAdUserCreateEditComponent extends ComponentCleaner {
    public myForm: FormGroup;
    public project: Project;
    public adDomains: ADDomain[];
    public managerUsers: ManagerUser[];
    public managerUser: ManagerUser;
    public currentEditManagerUserIsSuperAdmin = true;
    currentEditManagerUserIsTheSameUser = false;
    public alertError = '';
    public adUserPw = '';
    public adAdmin: ADAdmin;
    public hasNewEmail = false;
    public isToRegisterANewManagerUser = false;
    public isEditForm = false;
    public subProjectId: number;
    public desktopServerFilterControl: FormControl = new FormControl();
    public adUserAlreadyExists: FormControl = new FormControl();
    public askThePassword: FormControl = new FormControl();
    public adUserPassword: FormControl = new FormControl();
    public adUser: ADUser;
    public customer: Customer;
    public adUserWithPassword: ADUserWithPassword = {
        adUser: null,
        askThePassword: false,
        adUserPassword: '',
        adUserAlreadyExists: false,
        subProjectId: null
    };
    appCanCreateList: PermissionWrapper[];

    adGroupIds: FormControl = new FormControl();
    defaultCustomPortalTemplate: CustomPortalTemplate = initCustomPortalTemplate();


    constructor(public dialogRef: MatDialogRef<ManagerUserAndAdUserCreateEditComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private daoManagerUser: ManagerUserDaoService,
                private adAdminDaoService: AdAdminDaoService,
                private adUserDaoService: AdUserDaoService,
                private userAuthorityDao: UserAuthorityDaoService,
                private customerGroupService: CustomerGroupService,
                private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService,
                private scriptExecutionDaoService: ScriptExecutionDaoService,
                private stomp: R2CloudStompService,
                public desktopServerService: DesktopServerService,
                public adminService: R2CloudAdminService,
                private customLayoutService: CustomLayoutService,
    ) {
        super();

        this.appCanCreateList = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];

        this.adUserPw = '';
        this.isEditForm = false;
        this.askThePassword.setValue(true);
        this.adUserAlreadyExists.setValue(false);

        this.adDomains = data.adDomains;
        if (data.subProject) {
            this.subProjectId = data.subProject.id;
        }
        this.project = data.project;
        if (this.adDomains) {
            this.adDomains = this.adDomains.filter(x => x.active === true);
        }

        this.daoManagerUser.filter({'subProjectId': this.subProjectId}).subscribe((managerUsers) => {
            this.managerUsers = managerUsers;
        });


        let adUser: ADUser = data.adUser;
        this.managerUser = data.managerUser;
        this.customer = this.adminService.getCustomer(this.managerUser.customerId);
        if (!adUser) {
            adUser = {
                id: null,
                comment: null,
                active: true,
                login: null,
                managerUserId: null,
                adDomainId: null,
                adGroupIds: [],
                serviceUser: data.isServiceUser,
                managerUserServiceIds: [],
                subProjectIds: [this.subProjectId],
                changing: false,
                deleting: false,
                createdAt: null,
                updatedAt: null,
                modified: null,
                optlock: null
            };
        }
        this.adUser = adUser;

        if (!this.managerUser) {
            this.managerUser = {
                id: null,
                active: true,
                comment: null,
                authorityIds: [],
                customerId: null,
                displayName: null,
                email: null,
                individualClientConfig: false,
                twoFactorAuth: false,
                picture: null,
                imageFileId: null,
                province: null,
                city: null,
                neighborhood: null,
                addressAddOn: null,
                address: null,
                addressNumber: null,
                zipCode: null,
                phone: null,
                managerUserAccessPeriodProfileId: null,
                modified: null,
                optlock: null,
                imageUUID: null,
            };
        } else {
            combineLatest([this.managerUserPermissionsDaoService.isAdminUser(this.managerUser.id), this.userAuthorityDao.getMe()]).subscribe(values => {
                const isAdmin = values[0];
                const getMe = values[1];
                this.currentEditManagerUserIsSuperAdmin = isAdmin;
                this.currentEditManagerUserIsTheSameUser = getMe.managerUser.id === this.managerUser.id;
            });
        }


        if (adUser.id) {
            this.isEditForm = true;
        }

        if (this.adDomains.length === 1) {
            adUser.adDomainId = this.adDomains[0].id;
        }


        const formGroups = {
            selectOperationForm: this.fb.group({operation: [Validators.required]}),
            managerUserForm: this.fb.group(convertToFormGroup(this.managerUser)),
            adUserForm: this.fb.group(convertToFormGroup(adUser))
        };

        this.myForm = this.fb.group(formGroups);

        const emailControl = this.myForm.get('managerUserForm').get('email');
        const loginControl = this.myForm.get('adUserForm').get('login');

        emailControl.setValidators(Validators.required);

        const subscription = this.adUserAlreadyExists.valueChanges.subscribe((result) => {
            if (result) {
                loginControl.setValidators(Validators.required);
            } else {
                loginControl.setValidators(null);
            }
        });
        this.addSubscription(subscription);

        const subscription1 = this.askThePassword.valueChanges.subscribe((result) => {
            if (result && this.adUserAlreadyExists.value) {
                this.adUserPassword.setValidators(Validators.required);
            } else {
                this.adUserPassword.setValidators(null);
            }
        });
        this.addSubscription(subscription1);

        const managerUserEmail$ = emailControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                filter(value => {
                    if (value) {
                        return validateEmail(value);
                    }
                    return false;
                }));
        const adUserAdDomainId$ = this.myForm.get('adUserForm').get('adDomainId').valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            filter(value => !!value));

        const subscription2 = combineLatest([managerUserEmail$, adUserAdDomainId$]).subscribe((result) => {
            const email = result[0];
            const adDomainId = result[1];
            const findFilter: any = {
                managerUserEmail: email,
                adDomainId: adDomainId,
                projectId: this.project.id,
                subProjectId: this.subProjectId
            };
            this.adUserDaoService.filterOne({byEmailAndAdDomainIdAndProjectId: findFilter}).subscribe((adUserFromDAO) => {
                if (adUserFromDAO) {
                    const error = {email: 'E-mail já cadastrado no domínio.'};
                    emailControl.setErrors(error);
                } else {
                    emailControl.setErrors(null);
                    this.daoManagerUser.findByEmail(email).subscribe((managerUserFromDAO) => {
                        if (managerUserFromDAO && managerUserFromDAO.id) {
                            const managerUserIdControl = this.myForm.get('adUserForm').get('managerUserId');
                            managerUserIdControl.setValue(managerUserFromDAO.id);
                            managerUserIdControl.markAsDirty();
                            managerUserIdControl.updateValueAndValidity();
                        }
                    });
                }
            });

        });
        this.addSubscription(subscription2);


        this.adAdmin = {
            id: null,
            adDomainId: this.adUser.adDomainId,
            adUserId: this.adUser.id,
            comment: null,
            active: true,
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
        this.adAdminDaoService.findByAdDomainIdAndAdUserId(this.adUser.adDomainId, this.adUser.id).subscribe((adAdmin) => {
            if (adAdmin) {
                this.adAdmin = adAdmin;
                // console.debug('adAdmin: ' + adAdmin);
            }
        });
        this.adGroupIds.setValue(this.adUser.adGroupIds);

        this.addSubscription(this.customLayoutService.getDefaultCustomerGroupTemplate$().subscribe(defaultCustomerGroupTemplate => {
            this.defaultCustomPortalTemplate = defaultCustomerGroupTemplate;
        }));
    }

    onSubmit(): void {
        this.hasNewEmail = false;
        this.alertError = '';
        const adUser: ADUser = this.myForm.get('adUserForm').value;
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        adUser.subProjectIds = [this.subProjectId];

        // se não for serviceUser
        if (typeof adUser.serviceUser !== 'undefined' && !adUser.serviceUser) {

            if (this.isEditForm) {
                this.saveOrCreateAdUser(adUser);
                this.isToRegisterANewManagerUser = false;
            } else {
                // checar se já existe o userManager nesse dominio e não é service
                const findFilter: FilterByEmailAndAdDomainIdAndSubProjectId = {
                    managerUserEmail: managerUser.email,
                    adDomainId: adUser.adDomainId,
                    subProjectId: this.subProjectId
                };
                this.adUserDaoService.filterOne({byEmailAndAdDomainIdAndProjectId: findFilter}).subscribe((checkManagerUserFromDomain) => {
                    if (checkManagerUserFromDomain && checkManagerUserFromDomain.id) {
                        this.alertError = '*E-mail já cadastrado no domínio, por favor insira outro.';
                    } else {
                        this.daoManagerUser.filterOne({email: managerUser.email}).subscribe((managerUserFromDAOCheckIsNew) => {
                            if (!managerUserFromDAOCheckIsNew && !this.isToRegisterANewManagerUser) {
                                this.hasNewEmail = true;
                            } else {
                                this.adUserDaoService.findByLogin(adUser.login, adUser.adDomainId).subscribe((adUserDAO) => {
                                    if (adUserDAO && adUserDAO.login) {
                                        this.alertError = '*Login AD já cadastrado no domínio, por favor insira outro.';
                                    } else {
                                        let managerUser$: Observable<ManagerUser>;
                                        if (managerUser.id) {
                                            managerUser$ = this.daoManagerUser.getOne(managerUser.id);
                                        } else {
                                            managerUser$ = this.daoManagerUser.create(managerUser);
                                        }
                                        managerUser$.subscribe((managerUserFromDAO) => {
                                            adUser.managerUserId = managerUserFromDAO.id;
                                            this.isToRegisterANewManagerUser = false;
                                            this.saveOrCreateAdUser(adUser);
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
            // ou se for serviceUser
        } else {
            this.adUserDaoService.findByLogin(adUser.login, adUser.adDomainId).subscribe((adUserDAO) => {
                if (adUserDAO && adUserDAO.login != null && adUserDAO.id !== adUser.id) {
                    this.alertError = '*Login AD já cadastrado no domínio. Dica: Use prefixo do projeto e subprojeto no login';
                } else {
                    adUser.serviceUser = true;
                    adUser.managerUserId = null;
                    this.isToRegisterANewManagerUser = false;
                    this.saveOrCreateAdUser(adUser);
                }
            });
        }
    }

    private saveOrCreateAdUser(adUser: ADUser): void {
        if (adUser.id) {
            this.adUserDaoService.save(adUser).subscribe((aDUserFromDAO) => {
                this.dialogRef.close(aDUserFromDAO);
            });
        } else {
            this.adUserWithPassword.adUser = adUser;
            this.adUserWithPassword.adUserAlreadyExists = this.adUserAlreadyExists.value;
            this.adUserWithPassword.adUserPassword = this.adUserPassword.value;
            this.adUserWithPassword.askThePassword = this.askThePassword.value;

            this.adUserDaoService.createAdUserWithCheck(this.adUserWithPassword).subscribe((aDUserFromDAO) => {
                this.dialogRef.close(aDUserFromDAO);
            });
        }
    }

    onRegisterNewUser(): void {
        this.isToRegisterANewManagerUser = true;
        this.onSubmit();
    }

    onCloseNewUser(): void {
        this.isToRegisterANewManagerUser = false;
        this.hasNewEmail = false;
        this.myForm.get('managerUserForm').get('email').setValue('');
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onUserLogOff(aDLogin: string): void {
        setLoading('onUserLogOff');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            this.scriptExecutionDaoService.logoffUserOnDesktopServer(desktopServer.id, aDLogin).subscribe((response) => {
                finishedLoading('onUserLogOff' , 3000);
                if (response) {
                    console.debug('onUserLogOff: ' + response);
                    this.dialogRef.close();
                }
            });
            this.desktopServerService.onUserLogOffLocalAdmin(aDLogin, desktopServer.id);
        }
    }

    onUserReset(aDLogin: string): void {
        setLoading('onUserReset');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            this.scriptExecutionDaoService.resetUserOnDesktopServer(desktopServer.id, aDLogin).subscribe((response) => {
                finishedLoading('onUserReset' , 3000);
                if (response) {
                    console.debug('onUserReset: ' + response);
                    this.dialogRef.close();
                }
            });
            this.desktopServerService.onUserResetLocalAdmin(aDLogin, desktopServer.id);
        }
    }

    onUnlockUserADAccount(aDLogin: string): void {

        setLoading('unlockUserADAccount');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            const serverId = this.adminService.getAdDomain(desktopServer.adDomainId).adServerId;
            this.scriptExecutionDaoService.unlockUserADAccount(serverId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
                if (response) {
                    finishedLoading('unlockUserADAccount' , 3000);
                    console.debug('onUnlockUserADAccount: ' + response);
                    this.dialogRef.close();
                }
            });
        }
    }

    onEnableUserADAccount(aDLogin: string): void {
        setLoading('enableUserADAccount');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            const serverId = this.adminService.getAdDomain(desktopServer.adDomainId).adServerId;
            this.scriptExecutionDaoService.enableUserADAccount(serverId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
                if (response) {
                    finishedLoading('enableUserADAccount' , 3000);
                    console.debug('onEnableUserADAccount: ' + response);
                    this.dialogRef.close();
                }
            });
        }
    }


    onCheckEnableUserADAccount(aDLogin: string): void {
        setLoading('checkEnableUserADAccount');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            const serverId = this.adminService.getAdDomain(desktopServer.adDomainId).adServerId;
            this.scriptExecutionDaoService.checkEnableUserADAccount(serverId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
                finishedLoading('checkEnableUserADAccount' , 3000);
                if (response) {
                    console.debug('onCheckEnableUserADAccount: ' + response, {serverId, aDLogin});
                    this.dialogRef.close();
                }
            });
        }
    }


    onResetPassword(): void {
        const adUser = this.myForm.get('adUserForm').value;

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo resetar a senha desse usuário no dominio?<br><br>' +
                    'O Usuário terá que digitar uma nova senha para esse ADUser assim que logar no R2.',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adUserDaoService.resetPasswordAdUser(adUser.id).subscribe(() => {
                    this.dialogRef.close(adUser);
                });
            }
        });
        this.addSubscription(subscription);
    }


    onSetRandomPassword(): void {
        const adUser = this.myForm.get('adUserForm').value;

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo gerar uma senha aleatória para esse usuário no dominio?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adUserDaoService.setRandomPassword(adUser.id).subscribe(() => {
                    this.dialogRef.close(adUser);
                });
            }
        });
        this.addSubscription(subscription);
    }


    onSetLegacyRandomPassword(): void {
        const adUser = this.myForm.get('adUserForm').value;

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo gerar uma senha <b><u>LEGADA</u></b> aleatória para esse usuário no dominio?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adUserDaoService.setLegacyRandomPassword(adUser.id).subscribe(() => {
                    this.dialogRef.close(adUser);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onRemove(): void {
        const adUser = this.myForm.get('adUserForm').value;

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo remover esse usuário?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            console.debug('result: ' + result);
            if (result) {
                this.adUserDaoService.removeAdUserReferences(adUser.id, this.subProjectId, false).subscribe(() => {
                    this.dialogRef.close(adUser);
                    // todo remover da lista de usuários de serviço tambem
                });
            }
        });
        this.addSubscription(subscription);

    }

    onRemoveWithoutConfirmation(): void {
        const adUser = this.myForm.get('adUserForm').value;

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo remover esse usuário sem confirmar se foi deletado? (Para usuário local essa é a opção correta)',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            console.debug('result: ' + result);
            if (result) {
                this.adUserDaoService.removeAdUserReferences(adUser.id, this.subProjectId, true).subscribe(() => {
                    this.dialogRef.close(adUser);
                    // todo remover da lista de usuários de serviço tambem
                });
            }
        });
        this.addSubscription(subscription);

    }


    findByLoginPw(login: string, adDomainId: number): void {
        this.adUserDaoService.findByLoginPw(login, adDomainId).subscribe((adUserPw) => {
            this.adUserPw = adUserPw.adUserPassword;
        });
    }

    onPutAdUserAsAdmin(): void {
        const adUser: ADUser = this.myForm.get('adUserForm').value;
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                message: '<b>Confirme</b> digitando o login: <b>' + adUser.login +
                    '</b> no campo abaixo <br> Esse procedimento irá <b>INSERIR</b> esse usuário como <b><u>administrador do domínio</u></b>.',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline',
                confirmFieldValue: adUser.login
            }
        }).afterClosed().subscribe((result) => {
            console.debug('result: ' + result);
            if (result) {
                this.adAdminDaoService.create(this.adAdmin).subscribe((adAdmin) => {
                    this.adAdmin = adAdmin;
                    this.adminService.addADAdmin(adAdmin);
                    this.dialogRef.close();
                    console.debug('ADMIN do dominio: ' + adAdmin.adUserId);
                });
            }
        });
        this.addSubscription(subscription);
    }


    onRemoveAdUserAsAdmin(): void {
        const adUser: ADUser = this.myForm.get('adUserForm').value;
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                message: '<b>Confirme</b> digitando o login: <b>' + adUser.login +
                    '</b> no campo abaixo <br> Esse procedimento irá <b>REMOVER</b> esse usuário como <b><u>administrador do domínio</u></b>.',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline',
                confirmFieldValue: adUser.login
            }
        }).afterClosed().subscribe((result) => {
            console.debug('result: ' + result);
            if (result) {
                this.adAdminDaoService.remove(this.adAdmin.id).subscribe(() => {
                    this.adAdmin.deleting = true;
                    this.adminService.replaceADAdmin(this.adAdmin);
                    this.dialogRef.close();
                    console.debug('REMOVEU ADMIN do dominio: ' + this.adAdmin.adUserId);
                });
            }
        });
        this.addSubscription(subscription);
    }

    openDesktopServerCustomUser(): void {
        const adUser = this.myForm.get('adUserForm').value;
        const desktopServerId = this.desktopServerFilterControl?.value?.id;
        this.desktopServerService.openDesktopServerADAdminCustomUser(desktopServerId, adUser.id, false, this.adminService.subProject.id);
    }

    openDesktopServerCustomUserLocalAdmin(): void {
        const adUser = this.myForm.get('adUserForm').value;
        const desktopServerId = this.desktopServerFilterControl?.value?.id;
        this.desktopServerService.openDesktopServerADAdminCustomUserLocalAdmin(desktopServerId, adUser.id);
    }

    getDesktopServerListByADDomainId(adDomainId: number): DesktopServer[] {
        return this.adminService.desktopServers.filter((desktopServer) => {
            return desktopServer.adDomainId === adDomainId;
        });
    }

    adGroupListHasChange(): boolean {
        const array1 = this.adUser.adGroupIds;
        const array2 = this.adGroupIds.value;
        if (array1.length === array2.length) {
            return array1.every(element => {
                return !!array2.includes(element);
            });
        }
        return false;
    }

    savingGroupsInUser(): void {
        const adUserToSaveGroups = this.adUser;
        adUserToSaveGroups.adGroupIds = this.adGroupIds.value;
        this.adUserDaoService.saveADUserGroups(adUserToSaveGroups, this.subProjectId).subscribe(adUserSaved => {
            if (adUserSaved) {
                this.adUser = adUserSaved;
                this.adminService.replaceADUser(adUserSaved);
                this.dialogRef.close();
            }
        });
    }

    onEditPasswordWithoutConfirmation(): void {
        const adUser = this.myForm.get('adUserForm').value;
        this.addSubscription(this.dialog.open(EditAdUserPasswordComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adUser: adUser
            }
        }).afterClosed().subscribe(() => {
            this.dialogRef.close(adUser);
        }));
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.adUser.id, this.adUserDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.adUser.id, this.adUserDaoService, this.dialog);
    }

    protected readonly isLoading = isLoading;
}

import {Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {getArrayDateFromPeriod, Period} from '../../../helpers/kluh';
import {CustomerGroup, R2cloudStompSession} from '../../../models';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {filter, startWith} from 'rxjs/operators';
import {ComponentCleaner} from '../../../component-cleaner';
import {StatisticsService} from '../statistics.service';

@Component({
    selector: 'app-session-log',
    templateUrl: './session-log.component.html',
    styleUrls: ['./session-log.component.scss']
})
export class SessionLogComponent extends ComponentCleaner implements OnChanges, OnDestroy {

    @Input()
    managerUserIdSearch: number;

    @Input()
    period: Period;

    private customerGroup: CustomerGroup;
    currentPeriod = [];
    dataSource = new MatTableDataSource<R2cloudStompSession>();
    displayedColumns: string[] = [
        'avatar', 'managerUserId', 'connectAt', 'disconnectAt', 'connectedTime', 'ip', 'userAgent', 'browser'
    ];

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    totalDocs = 0;
    pageSize = 50;
    pageIndex = 0;


    constructor(
        private customerGroupService: CustomerGroupService,
        private managerUserDao: ManagerUserDaoService,
        public imageFileService: ImageFileService,
        public statisticsService: StatisticsService,
        public adminService: R2CloudAdminService,
    ) {
        super();
        this.currentPeriod = getArrayDateFromPeriod(Period.today);

        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (customerGroup && customerGroup.id) {
                this.customerGroup = customerGroup;
                this.unloadProject();
                this.loadData();
            } else {
                this.unloadProject();
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.pageIndex = 0;
        this.paginator.pageIndex = this.pageIndex;
        this.currentPeriod = getArrayDateFromPeriod(this.period);
        this.loadData();
    }

    loadData(): void {
        this.paginator?.page.pipe(startWith({
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize,
        }), filter(value => !!value)).subscribe((page: PageEvent) => {
            if (page && this.customerGroup.id) {
                this.pageIndex = page.pageIndex;
                this.getStompSession(
                    this.customerGroup.id,
                    this.currentPeriod[0],
                    this.currentPeriod[1],
                );
            }
        });
    }


    unloadProject(): void {
        this.dataSource.data = null;
        this.totalDocs = 0;
        this.pageIndex = 0;
        this.managerUserIdSearch = 0;
    }

    private getStompSession(customerGroupId: number, from: string, to: string): void {
        this.managerUserDao.findStompSessionListByCustomerGroupId(
            customerGroupId,
            this.managerUserIdSearch,
            from,
            to,
            this.pageIndex,
            this.pageSize,
        ).subscribe((stompSessionList) => {
            if (stompSessionList) {
                this.totalDocs = stompSessionList.totalElements;
                this.dataSource.data = stompSessionList.content;
            }
        });
    }

    ngOnDestroy(): void {
        // console.log('ngOnDestroy session');
    }

}

<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Procurar programa</span>
    </mat-toolbar-row>
</mat-toolbar>


<div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="no-padding grey-50-bg border-bottom"
     matDialogActions>
    <div class="m-20 mb-0">
        <div class="float-left">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Disco" [formControl]="driveControl">
                    <mat-option *ngFor="let disc of driveElements" [value]="disc">
                        {{disc.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="float-left ml-25">
            <mat-form-field appearance="outline" class="min-width-640">
                <input matInput placeholder="Path" [formControl]="pathControl">
            </mat-form-field>
        </div>
    </div>
</div>

<div class="mat-white-bg mat-elevation-z2 no-padding" fxLayout="column" fxLayoutAlign="space-between stretch"
     matDialogContent>


    <div class="file-browser-table-wrapper">
        <table mat-table [dataSource]="fileDataSource" class="mat-elevation-z8 width-100-percent file-browser-table">

            <!-- Name Column -->
            <ng-container matColumnDef="icon" class="width-100-percent">
                <th mat-header-cell *matHeaderCellDef>
                    <button [ngClass]="{'yellow-800-fg': (parentPath), 'gray-200-fg': !(parentPath)}"
                            class="back-button" type="button"
                            mat-stroked-button [disabled]="!parentPath" (click)="parentPath && onBack()">
                        <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
                    </button>
                </th>

                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)">
                    <mat-icon class="icon yellow-800-fg" *ngIf="element.type === FileTypes.Directory">folder</mat-icon>
                    <mat-icon class="icon accent-fg" *ngIf="element.type === FileTypes.File">laptop</mat-icon>
                    <mat-icon class="icon" *ngIf="element.type === FileTypes.Document">sd_storage</mat-icon>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name" class="width-100-percent">
                <th mat-header-cell *matHeaderCellDef > Nome</th>
                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)">
                    <div class="text-max-150" title="{{element.name}}">{{element.name}}</div>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="path">
                <th mat-header-cell *matHeaderCellDef> Caminho</th>
                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)" title="{{element.path}}">
                    <div class="text-max-400 filter-left">{{element.path}}</div>
                </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Tipo</th>
                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)"> {{element.type}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef> Dono</th>
                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)"> {{element.owner}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef> Tamanho</th>
                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)"
                    title="{{element.size}} byte"> {{element.size | bytes}}</td>
            </ng-container>


            <!-- Name Column -->
            <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef> Modificado em</th>
                <td mat-cell *matCellDef="let element" (click)="onSelectElement(element)"
                    title="{{element.updated | date: 'dd/MM/yyyy HH:mm:ss'}}">
                    {{element.updated | date: 'dd/MM/yyyy HH:mm'}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="fileTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: fileTable;" class="mat-tr"
                [ngClass]="{'selected': pathControl.value == row.path}"
                [@listEnterAnimationQuick]></tr>
        </table>
    </div>


</div>

<div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
    <div>
        <button type="button" mat-raised-button color="accent" [disabled]="!validExe()"
                (click)="validExe() && onSave()">Ok
        </button>
    </div>
    <div>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>


import {Injectable} from '@angular/core';
import {R2CloudHttpBase} from './r2-cloud-http.base';
import {HttpClient} from '@angular/common/http';
import {FuseProgressBarService} from '../../@fuse/components/progress-bar/progress-bar.service';
import {DomainPathService} from './domain-path/domain-path.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class R2CloudHttpMonitoringService extends R2CloudHttpBase {

    constructor(private httpAngular: HttpClient,
                private fuseProgressBarService: FuseProgressBarService,
                private authService: AuthService,
                private domainPathService: DomainPathService
    ) {
        super(httpAngular,
            () => this.fuseProgressBarService.addAsync(),
            (id: string) => fuseProgressBarService.removeAsync(id),
            () => authService.getFirebaseToken(),
            domainPathService.monitoringURL$);
    }
}

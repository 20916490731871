import {Injectable} from '@angular/core';
import {DotNetServerPluginFile} from '../../../models';
import {BaseDao} from '../../../base-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class DotNetServerPluginFileDaoService extends BaseDao<DotNetServerPluginFile> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'server-plugin-file');
    }

    upload(formData: FormData): Observable<DotNetServerPluginFile> {
        return this.http.upload(formData, this.url + `/upload`);
    }

    download(id: number): Observable<Blob> {
        return this.http.download(this.url + `/${id}/download`);
    }

    delete(serverPluginFile: DotNetServerPluginFile): Observable<void> {
        return this.http.delete(this.url + '/' + serverPluginFile.id, serverPluginFile);
    }
}

<div class="dialog-content-wrapper">
    <form [formGroup]="myForm"
          id="marketplace-category-create-and-edit-form"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de categoria</span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <div class="form-full-size mt-20">
                <mat-form-field appearance="outline" class="w-300">
                    <mat-label>Nome da categoria</mat-label>
                    <input matInput formControlName="name" autocomplete="off" id="marketplace-category-create-and-edit-name" >
                    <mat-error>
                        {{myForm.get('name').errors | error}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button
                    id="marketplace-category-create-and-edit-create"
                    color="accent" [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="marketplaceCategory.id;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <button type="button" mat-raised-button color="primary"
                    id="marketplace-category-create-and-edit-cancel"
                    (click)="onCancel()">Cancelar</button>
        </div>
    </form>
</div>

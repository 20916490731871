import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoggerService} from './logger.service';
import {PlatformInfoService} from './platform-info.service';
import {PrintingService} from './printing.service';
import {SessionProviderService} from './session-provider.service';
import {TrustedCollectionsService} from './trusted-collections.service';
import {SessionViewComponent} from './session-view/session-view.component';
import {CredentialStoreService} from './credential-store.service';
import {RdpClientUIPropertiesService} from './rdp-client-uiproperties.service';
import {SessionStoreService} from './session-store.service';
import {UserAuthInfoService} from './user-auth-info.service';
import {AppSettingsStoreService} from './app-settings-store.service';
import {TelemetryService} from './telemetry.service';
import {ClipTextBoxComponent} from './session-view/clip-text-box/clip-text-box.component';
import {AuthService} from '../auth.service';
import {RouterModule} from '@angular/router';
import {KluhSharedModule} from '../kluh-shared.module';
import {FuseSharedModule} from '../../../@fuse/shared.module';

import {FeedParserService} from './feed-parser.service';
import {FeedRetrieverService} from './feed-retriever.service';
import {FeedTrackerService} from './feed-tracker.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppDrawerComponent} from './session-view/app-drawer/app-drawer.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SessionStateModalComponent} from './session-view/session-state-modal/session-state-modal.component';
import {SessionStateStoreService} from './session-state-store.service';
import {TranslateModule} from '@ngx-translate/core';
import {SessionSingleComponent} from './session-view/session-single/session-single.component';
import {SessionGroupedComponent} from './session-view/session-grouped/session-grouped.component';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Html5ScriptLoaderComponent} from './script-loader/html5-script-loader/html5-script-loader.component';
import {Html5ScriptLoaderModalComponent} from './script-loader/html5-script-loader/html5-script-loader-modal/html5-script-loader-modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';

const routes = [
    {
        path: 'webclient-html5',
        component: Html5ScriptLoaderComponent,
        canActivate: [AuthService],
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        TranslateModule,
        MatTooltipModule,
        MatIconModule,
        FontAwesomeModule,
        MatDialogModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
    ],
    declarations: [
        SessionViewComponent,
        ClipTextBoxComponent,
        AppDrawerComponent,
        SessionStateModalComponent,
        SessionSingleComponent,
        SessionGroupedComponent,
        Html5ScriptLoaderComponent,
        Html5ScriptLoaderModalComponent
    ],
    exports: [
        SessionViewComponent
    ],
    providers: [
        AppSettingsStoreService,
        CredentialStoreService,
        FeedParserService,
        FeedRetrieverService,
        FeedTrackerService,
        LoggerService,
        PlatformInfoService,
        PrintingService,
        RdpClientUIPropertiesService,
        SessionProviderService,
        SessionStoreService,
        TelemetryService,
        TrustedCollectionsService,
        UserAuthInfoService,
        SessionStateStoreService,
        MatProgressSpinnerModule
    ],
    entryComponents: [
        AppDrawerComponent,
        SessionStateModalComponent
    ]
})
export class Html5Module {
}

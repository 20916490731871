import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MonitoringDetailsService {
    constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute) {
    }
    changePath(rootPath: string, tapPath: string): void {
        const queryParams = Object.assign({}, this.route.snapshot.queryParams);
        const urlTree = this.router.createUrlTree(['/' + rootPath + '/' + tapPath], {relativeTo: this.route, queryParams: queryParams});
        this.location.go(urlTree.toString());
    }



}

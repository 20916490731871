import {Component, Inject} from '@angular/core';

import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ProjectDaoService} from '../project-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {CustomerGroupCreateEditComponent} from '../../customer-group/customer-group-create-edit/customer-group-create-edit.component';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CustomerGroup, Project, SubProject} from '../../../models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-project-create',
    templateUrl: './project-create.component.html',
    styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent extends ComponentCleaner {
    public myForm: FormGroup;
    public project: Project;
    public subProjects: SubProject[];
    public customerGroups: CustomerGroup[];
    public subProjectsForm: FormArray;


    constructor(public dialogRef: MatDialogRef<ProjectCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private projectDAO: ProjectDaoService,
                private subProjectDao: SubProjectDaoService,
                private fb: FormBuilder) {


        super();
        if (data) {
            let projectName: string = null;
            let projectId: number = null;
            if (data.project) {
                this.project = data.project;
                projectName = this.project.name;
                projectId = this.project.id;
            } else {
                this.project = {
                    id: null,
                    name: '',
                    active: true,
                    comment: null,
                    hasSubProjects: false,
                    modified: null,
                    optlock: null
                };
            }
            if (data.subProjects) {
                this.subProjects = data.subProjects;
            } else {
                this.subProjects = [];
            }
            if (this.subProjects && this.subProjects.length === 0) {
                const subProject: SubProject = this.initSubProject(projectId, projectName);
                this.subProjects.push(subProject);
            }

            this.customerGroups = data.customerGroups;
        }

        const projectCreateForm: FormGroup = this.fb.group(convertToFormGroup(this.project), {asyncValidator: this.projectDAO.validator});

        const name$ = projectCreateForm.get('name').valueChanges;
        const hasSubProjects$ = projectCreateForm.get('hasSubProjects').valueChanges;
        const active$ = projectCreateForm.get('active').valueChanges;

        // merge(name$, hasSubProjects$, active$).pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
        //     markAsTouched(this.myForm);
        // });

        const subscription = name$.pipe(distinctUntilChanged()).subscribe(() => {
            markAsTouched(this.myForm);
        });
        this.addSubscription(subscription);
        const subscription1 = hasSubProjects$.pipe(distinctUntilChanged()).subscribe(() => {
            markAsTouched(this.myForm);
        });
        this.addSubscription(subscription1);
        const subscription2 = active$.pipe(distinctUntilChanged()).subscribe(() => {
            markAsTouched(this.myForm);
        });
        this.addSubscription(subscription2);


        const subProjectsFormArray = [];
        for (const subProject of this.subProjects) {
            let subProjectForm;
            if (subProject.projectId !== null) {
                subProjectForm = this.fb.group(convertToFormGroup(subProject), {asyncValidator: this.subProjectDao.validator});
            } else {
                subProjectForm = this.fb.group(convertToFormGroup(subProject));
            }
            const subscription3 = subProjectForm.get('customerGroupId').valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
                markAsTouched(this.myForm);
            });
            this.addSubscription(subscription3);
            subProjectsFormArray.push(subProjectForm);

        }
        this.subProjectsForm = this.fb.array(subProjectsFormArray);
        this.myForm = this.fb.group({projectCreateForm: projectCreateForm, subProjectsForm: this.subProjectsForm});
    }

    private initSubProject(projectId: number, subProjectName: string): SubProject {
        return {
            id: null,
            projectId: projectId,
            adDomainIds: [],
            customerGroupId: null,
            active: true,
            comment: null,
            name: subProjectName,
            desktopServerIds: [],
            linuxServerIds: [],
            desktopServerPoolIds: [],
            modified: null,
            optlock: null
        };
    }

    onSubmit(): void {
        const crudOperations: CrudOperationWrapper[] = [];
        const project: Project = this.myForm.get('projectCreateForm').value;
        let project$: Observable<Project>;
        let projectOperation: CrudOperation;
        if (project.id) {
            projectOperation = 'SAVE';
            project$ = this.projectDAO.save(project);
        } else {
            projectOperation = 'CREATE';
            project$ = this.projectDAO.create(project);
        }
        project$.subscribe((result) => {
            const controls = this.subProjectsForm.controls;
            crudOperations.push({data: result, operation: projectOperation});

            if (controls.length === 1 && !result.hasSubProjects) {
                const subProject: SubProject = controls[0].value;
                subProject.projectId = result.id;
                subProject.name = result.name;
                let subProject$: Observable<SubProject>;
                let subProjectOperation: CrudOperation;
                if (subProject.id) {
                    subProjectOperation = 'SAVE';
                    subProject$ = this.subProjectDao.save(subProject);
                } else {
                    subProjectOperation = 'CREATE';
                    subProject$ = this.subProjectDao.create(subProject);
                }
                subProject$.subscribe((result2) => {
                    crudOperations.push({data: result2, operation: subProjectOperation});
                    this.dialogRef.close(crudOperations);
                });
            } else {
                this.dialogRef.close(crudOperations);
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }


    onAddCustomerGroup(): void {
        const dialogRef = this.dialog.open(CustomerGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                customerGroup: null
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    onAddSubProject(): void {
        this.subProjectsForm.push(this.fb.group(convertToFormGroup(this.initSubProject(null, null)), {asyncValidator: this.subProjectDao.validator}));
    }


    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.customerGroups.push(result.data);
            if (this.subProjectsForm && this.subProjectsForm.controls && this.subProjectsForm.controls.length === 1) {
                const subProjectForm = (<FormGroup> this.subProjectsForm.controls[0]);
                subProjectForm.controls['customerGroupId'].setValue(result.data.id);
                markAsTouched(this.myForm);
            }
        } else if (result.operation === 'SAVE') {
            const index = this.customerGroups.findIndex(value => value.id === result.data.id);
            this.customerGroups[index] = result.data;
        } else if (result.operation === 'DELETE') {
            this.customerGroups = this.customerGroups.filter(value => value.id === result.data);
        }
    }


}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {PluginMessage} from '../../models';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class PluginMessageDaoService extends BaseDao<PluginMessage> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'plugin-message');
    }
    sendMessageToAgent(pluginMessage: PluginMessage): Observable<boolean> {
        return this.http.post<boolean>(this.url + '/send-message-to-agent', pluginMessage);
    }
}

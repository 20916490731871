import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {clientVersion} from '../../../client-version';
import {AuthService} from '../auth.service';
import {of} from 'rxjs';
import {StompConnectionStatus} from './stomp-connection-status.enum';
import {UserAuthorityDaoService} from '../main/user-authority/user-authority-dao.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {DomainPathService} from '../domain-path/domain-path.service';
import {StompService} from '../stomp/stomp.service';

@Injectable({
    providedIn: 'root',
})
export class R2CloudStompService extends StompService implements OnDestroy {
    private _webClientId: string;
    private authSubscription: Subscription;
    private connectionStatusSubscription: Subscription;

    constructor(private auth: AuthService,
                private userAuthorityDao: UserAuthorityDaoService,
                private domainPathService: DomainPathService,
    ) {
        super();
        this.authSubscription = this.auth.isLoggedIn().pipe(switchMap((result) => {
            if (result) {
                return this.userAuthorityDao.getMe();
            } else {
                return of(null);
            }
        })).subscribe((result) => {
            if (result) {
                this.connect();
            } else {
                this.send('/stomp/logout', {});
                this._connectionStatus$.next(StompConnectionStatus.DISCONNECTED);
                this._username$.next(null);
                this.disconnect();
            }
        });
        this.connectionStatusSubscription = this.connectionStatus$.pipe(filter(x => x === StompConnectionStatus.DUPLICATE_LOGIN)).subscribe(() => {
            this.auth.duplicatedLogin();
        });
    }

    protected url(): Observable<string> {
        return this.domainPathService.apiWebSocketURL$.pipe(map((apiWebSocketURL) => {
            return `${apiWebSocketURL}stomp/web?X-Client-Id=${this.webClientId}&X-Client-Version=${clientVersion}`;
        }));
    }

    get webClientId(): string {
        if (!this._webClientId) {
            let item: string = localStorage.getItem('_webClientId');
            if (!item) {
                item = uuid().replace(/-/g, '');
                localStorage.setItem('_webClientId', item);
            }
            this._webClientId = item;
        }
        return this._webClientId;
    }

    ngOnDestroy(): void {
        this.authSubscription?.unsubscribe();
        this.connectionStatusSubscription?.unsubscribe();
        super.ngOnDestroy();
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {CustomerGroup, RemoteAppHtml5, RemoteAppReadyStatus, SubProject} from '../../../models';
import {guessImageMime} from '../../../helpers/kluh';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map, scan} from 'rxjs/operators';
import {CustomerGroupService} from '../../../main/customer-group/customer-group.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {RemoteAppHtml5Service} from '../../remote-app-html5.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../../main/image-file/image-file-service';
import {FormControl} from '@angular/forms';
import {SubProjectDaoService} from '../../../main/r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';

@Component({
    selector: 'app-app-drawer',
    templateUrl: './app-drawer.component.html',
    styleUrls: ['./app-drawer.component.scss'],
    animations: fuseAnimations
})
export class AppDrawerComponent extends ComponentCleaner implements OnInit {
    remoteAppHtml5List: RemoteAppHtml5[];
    customStyle = {
        color: '#000000',
        padding: '15px 5px 5px 5px'
    };

    private subProjects: SubProject[] = [];
    subProjectFilterControl: FormControl = new FormControl();
    subProjectIdList: number[] = [];

    constructor(public dialogRef: MatDialogRef<AppDrawerComponent>,
                private customerGroupService: CustomerGroupService,
                private subProjectDaoService: SubProjectDaoService,
                private remoteAppHtml5Service: RemoteAppHtml5Service,
                public imageFileService: ImageFileService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super();

        const observable = this.remoteAppHtml5Service.remoteAppChanges().pipe(scan((acc: RemoteAppHtml5[], value: RemoteAppHtml5) => {
            const index = acc.findIndex((item) => item.remoteAppId === value.remoteAppId);
            if (index > -1) {
                acc[index] = value;
            } else {
                acc.push(value);
            }
            return acc;
        }, []));

        this.addSubscription(combineLatest([
            observable, this.customerGroupService.get()]).pipe(map((value: [RemoteAppHtml5[], CustomerGroup]) => {
            const remoteAppHtml5List = value[0];
            const customerGroup = value[1];
            this.loadSubProjectsAndReloadRemoteApps(customerGroup.id);
            return remoteAppHtml5List.filter(value2 => {
                return value2.customerGroupId === customerGroup?.id;
            });
        })).subscribe(remoteAppHtml5FilteredList => {
            this.remoteAppHtml5List = remoteAppHtml5FilteredList.sort((a, b) => {
                const aStatus = this.translateStatusToNumber(a);
                const bStatus = this.translateStatusToNumber(b);
                if (aStatus === bStatus) {
                    return (a.remoteAppId > b.remoteAppId) ? 1 : -1;
                } else {
                    return (aStatus > bStatus) ? -1 : 1;
                }
            });
            this.populateSubprojectIdList(this.remoteAppHtml5List);
            this.selectFirstSubProjectInSelectFieldIfThereIsMoreThanOne();
        }));
    }

    private populateSubprojectIdList(remoteApps: RemoteAppHtml5[]): number[] {
        remoteApps?.forEach(remoteApp => {
            if (this.subProjectIdList.findIndex(subProject => subProject === remoteApp.subProjectId) < 0) {
                this.subProjectIdList?.push(remoteApp.subProjectId);
            }
        });
        return this.subProjectIdList;
    }

    private selectFirstSubProjectInSelectFieldIfThereIsMoreThanOne(): void {
        if (this.subProjectIdList.length > 1) {
            this.subProjectFilterControl.patchValue(this.subProjectIdList[0]);
        }
    }

    findSubProjectById(subProjectId: number): SubProject {
        return this.subProjects.find(subProject => {
            return subProject.id === subProjectId;
        });
    }

    private loadSubProjectsAndReloadRemoteApps(customerGroupId: number): void {
        this.subProjectDaoService.getSubProjectsByCustomerGroupId(customerGroupId).subscribe((subProjects) => {
            if (subProjects) {
                this.subProjects = subProjects;
            }
        });
    }

    private translateStatusToNumber(remoteAppHtml5: RemoteAppHtml5): number {
        if (remoteAppHtml5.status === RemoteAppReadyStatus.READY) {
            return 1;
        } else {
            return -1;
        }
    }

    ngOnInit(): void {
    }

    public guessImageMime(base64: string): string {
        return guessImageMime(base64);
    }

    onOpen(remoteAppHtml5: RemoteAppHtml5): void {
        this.dialogRef.close(remoteAppHtml5);
    }

}

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ADDomain, ADUser, ADUserPool, ADUserPoolRelationship, ADUserServiceExternal, ManagerUser, NumberADUsersPoolADDomain, SubProject} from '../../../models';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {debounceTime, distinctUntilChanged, filter, startWith} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {AdUserPoolRelationshipDaoService} from '../ad-user-pool-relationship-dao.service';
import {AdUserPoolDaoService} from '../../ad-user-pool/ad-user-pool-dao.service';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {CustomLayoutService, initCustomPortalTemplate} from '../../custom-layout/custom-layout.service';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-ad-user-pool-relationship-user-service-create',
    templateUrl: './ad-user-pool-relationship-user-service-create.component.html',
    styleUrls: ['./ad-user-pool-relationship-user-service-create.component.scss']
})
export class AdUserPoolRelationshipUserServiceCreateComponent extends ComponentCleaner {
    readonly managerUsers: ManagerUser[];
    currentADDomain: ADDomain;
    adDomainForm: FormControl = new FormControl();
    managerUserServiceIds: FormControl = new FormControl();
    adUserServiceExternal: ADUserServiceExternal;
    myForm: FormGroup;
    adUserPoolRelationship: ADUserPoolRelationship;
    tabIndex = 0;
    numberADUsersPoolADDomainList: NumberADUsersPoolADDomain[] = [];
    private adDomainId;
    adUserPw = '';
    appCanCreateList: PermissionWrapper[];
    defaultCustomPortalTemplate: CustomPortalTemplate = initCustomPortalTemplate();

    constructor(public dialogRef: MatDialogRef<AdUserPoolRelationshipUserServiceCreateComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private customerGroupService: CustomerGroupService,
                public adminService: R2CloudAdminService,
                private snackBar: MatSnackBar,
                private adUserPoolDaoService: AdUserPoolDaoService,
                private adUserDao: AdUserDaoService,
                private adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
                private customLayoutService: CustomLayoutService,
    ) {
        super();
        this.appCanCreateList = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];

        this.adUserPoolRelationship = data.adUserPoolRelationship;
        this.managerUsers = data.managerUsers;

        if (this.getADDomainsWithADUserPool().length === 1 || this.adUserPoolRelationship?.id) {
            this.adDomainId = this.getADDomainsWithADUserPool()[0].id;
            this.currentADDomain = this.adminService.getAdDomain(this.adDomainId);
        }
        this.getNumberADUsersPoolADDomainsForCurrentSubProject();

        this.adDomainForm = this.fb.control(this.adDomainId, [Validators.required]);
        this.managerUserServiceIds = this.fb.control(this.adUserPoolRelationship?.managerUserServiceIds, [Validators.required]);

        this.adDomainForm.valueChanges.subscribe((value: number) => {
            this.adDomainId = value;
        });
        this.myForm = this.fb.group({
            adDomainForm: this.adDomainForm,
            managerUserServiceIds: this.managerUserServiceIds,
        });
        this.myForm.get('managerUserServiceIds').valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged()).subscribe(() => {
            markAsTouched(this.myForm);
        });

        this.adDomainForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(<number>this.adDomainId),
            filter(value => {
                return value && value > 0;
            })
        ).subscribe(((adDomainId) => {
            if (adDomainId) {
                this.adDomainId = adDomainId;
                this.currentADDomain = this.adminService.getAdDomain(this.adDomainId);
                this.myForm.get('managerUserServiceIds').setValue(null);
                this.myForm.get('adDomainId').setValue(this.adDomainId);
            }
        }));
        this.managerUserServiceIds.setValue(this.adUserPoolRelationship?.managerUserServiceIds);
        this.adDomainForm.setValue(this.adDomainId);

        this.addSubscription(this.customLayoutService.getDefaultCustomerGroupTemplate$().subscribe(defaultCustomerGroupTemplate => {
            this.defaultCustomPortalTemplate = defaultCustomerGroupTemplate;
        }));
    }

    private getNumberADUsersPoolADDomainsForCurrentSubProject(): void {
        this.adUserPoolRelationshipDaoService.listOfNumberOfUsersPoolUsedPerADDomainOfThisSubProjectIdByUserType(this.adminService.subProject.id, true)
            .subscribe((numberADUsersPoolADDomainList) => {
                this.numberADUsersPoolADDomainList = numberADUsersPoolADDomainList;
            });
    }

    private getADUserPoolListByADDomainId(adDomainId: number): ADUserPool[] {
        const adUsersPoolADDomain: ADUserPool[] = [];
        this.adminService.adUsersPoolService?.forEach(adUserPool => {
            if (adUserPool.adDomainId === adDomainId && adUserPool.changing === false && adUserPool.deleting === false && adUserPool.serviceUser) {
                adUsersPoolADDomain.push(adUserPool);
            }
        });
        return adUsersPoolADDomain;
    }

    getNumberOfADUsersPoolUsedForThisADDomain(aDDomainId: number): number {
        const foundNumberADUsersPoolADDomain = this.numberADUsersPoolADDomainList.find((numberADUsersPoolADDomain) => {
            return numberADUsersPoolADDomain.adDomainId === aDDomainId;
        });
        if (foundNumberADUsersPoolADDomain) {
            return foundNumberADUsersPoolADDomain.amount;
        }
        return 0;
    }

    canCreateANewADUserPoolOnCurrentADDomain(): boolean {
        if (!this.currentADDomain?.id) {
            return false;
        }
        const totalOfADUserPool = this.getTotalADUserPoolByADDomainId(this.currentADDomain?.id);
        return this.getNumberOfADUsersPoolUsedForThisADDomain(this.currentADDomain?.id) < totalOfADUserPool;
    }

    getTotalADUserPoolByADDomainId(adDomainId: number): number {
        return this.getADUserPoolListByADDomainId(adDomainId).length;
    }

    getADDomainsWithADUserPool(): ADDomain[] {
        const adDomains: ADDomain[] = [];
        this.adminService.adDomains.forEach(adDomain => {
            if (this.getTotalADUserPoolByADDomainId(adDomain.id) > 0) {
                adDomains.push(adDomain);
            }
        });
        return adDomains;
    }

    onSubmit(): void {
        const subProject = this.adminService.subProject;
        const adDomainId = this.currentADDomain.id;
        let adUserPoolRelationship$;
        if (this.adUserPoolRelationship?.id) {
            adUserPoolRelationship$ = this.adUserPoolRelationshipDaoService.saveADUserPoolRelationshipServiceUser(
                this.managerUserServiceIds.value, this.adUserPoolRelationship
            );
        } else {
            adUserPoolRelationship$ = this.adUserPoolRelationshipDaoService.createADUserPoolRelationshipServiceUser(
                this.managerUserServiceIds.value, subProject.id, +adDomainId
            );
        }
        adUserPoolRelationship$.subscribe((adUserPoolRelationship) => {
            if (adUserPoolRelationship) {
                if (this.adUserPoolRelationship?.id) {
                    this.adminService.replaceADUserPoolRelationship(adUserPoolRelationship);
                } else {
                    this.adminService.addADUserPoolRelationship(adUserPoolRelationship);
                }
            } else {
                console.log('ERROR: addADUserPoolRelationship user service');
            }
            this.dialogRef.close();
        });
    }

    onCancel(): void {
        const crudOperation: CrudOperationWrapper = {
            operation: 'CANCEL',
            data: this.adUserPoolRelationship
        };
        this.dialogRef.close(crudOperation);
    }

    onRemove(): void {
        if (this.adUserPoolRelationship?.id) {
            this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Quer mesmo remover esse usuário de serviço?',
                    disableCancel: false,
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.adUserPoolRelationshipDaoService.remove(this.adUserPoolRelationship?.id).subscribe(() => {
                        this.adminService.removeADUserPoolRelationship(this.adUserPoolRelationship?.id);
                    });
                    this.dialogRef.close();
                }
            }));
        }
    }

    findByLoginPw(adUserPool: ADUserPool): void {
        this.adUserPoolDaoService.findByLoginPw(adUserPool.login, adUserPool.adDomainId).subscribe((adUserPw) => {
            this.adUserPw = adUserPw.adUserPassword;
        });
    }

    markAsTouchedAndDirty(): void {
        this.myForm.markAsTouched();
        this.myForm.markAsDirty();
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.adUserPoolRelationship.id, this.adUserPoolRelationshipDaoService, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.adUserPoolRelationship.id, this.adUserPoolRelationshipDaoService, this.dialog);
    }
}


import {Component} from '@angular/core';
import {AdDomainDaoService} from '../ad-domain-dao.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {AdDomainCreateComponent} from '../ad-domain-create/ad-domain-create.component';

import {ComponentCleaner} from '../../../component-cleaner';
import {filter} from 'rxjs/operators';
import {ADDomain} from '../../../models';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-ad-domain-list',
  templateUrl: './ad-domain-list.component.html',
  styleUrls: ['./ad-domain-list.component.scss'],
  animations: fuseAnimations
})
export class AdDomainListComponent extends ComponentCleaner {
  public adDomains: ADDomain[];

  constructor(private dialog: MatDialog,
              private dao: AdDomainDaoService) {
    super();
    this.dao.get().subscribe((adDomains) => {
      this.adDomains = adDomains;
    });
  }

  onCreateAdDomain(): void {
    const dialogRef = this.dialog.open(AdDomainCreateComponent, {
      disableClose: true,
      panelClass: 'ad-domain-edit-dialog-large',
      data: {
        projects: null,
      }
    });
    const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((adDomain) => {
      this.adDomains.push(adDomain);
    });
    this.addSubscription(subscription);
  }

}

/* tslint:disable:no-console */
import {LogLevel} from './enum/log-level';

export class Logger {
    logLevel: LogLevel = LogLevel.All;
    private e: any = !1;
    private f: any[] = [];

    private c = (a: any): any => {
        const b = (new Date).toISOString() + ' ';
        return 1 === a.length ? b + a[0] : b + Array.prototype.slice.call(a).join(' ');
    };

    private d = (a: any): any => {
        return 1 === a.length ? a[0] : Array.prototype.slice.call(a).join(' ');
    };

    private g = (a: any): void => {
        this.f.push(a + '\r\n');
    };

    getConsoleRecordingStatus(): any {
        return this.e;
    }
    
    getListOfLogs(): any {
        return this.f;
    }
    
    startRecording(): void {
        console.debug((new Date).toISOString() + ' Started console recording'), this.f = [], this.e = !0;
    }
    
    stopRecording(): void {
        console.debug((new Date).toISOString() + ' Stopped console recording'), this.e = !1;
    }
    
    debugWithoutTimestamp(...args: any): void {
        let a;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug || (a = this.d(args), !0 === this.e && this.g(a), console.debug(a));
    }

    debug(...args: any): void {
        let a;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug || (a = this.c(args), !0 === this.e && this.g(a), console.debug(a));
    }

    logWithoutTimestamp(...args: any): void {
        let a;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug && this.logLevel !== LogLevel.Info || (a = this.d(args), !0 === this.e && this.g(a), console.debug(a));
    }

    log(...args: any): void {
        let a;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug && this.logLevel !== LogLevel.Info || (a = this.c(args), !0 === this.e && this.g(a), console.debug(a));
    }

    warnWithoutTimestamp(...args: any): void {
        let a;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug && this.logLevel !== LogLevel.Info && this.logLevel !== LogLevel.Warn || (a = this.d(args),
        !0 === this.e && this.g(a), console.warn(a));
    }

    warn(...args: any): void {
        let a;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug && this.logLevel !== LogLevel.Info && this.logLevel !== LogLevel.Warn ||
        (a = this.c(args), !0 === this.e && this.g(a),
            console.warn(a));
    }

    errorWithoutTimestamp(...args: any): void {
        let c, f;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug && this.logLevel !== LogLevel.Info && this.logLevel !== LogLevel.Warn &&
        this.logLevel !== LogLevel.Error || (c = this.d(args),
            f = this.stackTrace(), !0 === this.e && (this.g(c), this.g(f)), console.error(c), console.error(f));
    }

    error(...args: any): void {
        let d, f;
        this.logLevel !== LogLevel.All && this.logLevel !== LogLevel.Debug && this.logLevel !== LogLevel.Info && this.logLevel !== LogLevel.Warn &&
        this.logLevel !== LogLevel.Error || (d = this.c(arguments),
            f = this.stackTrace(), !0 === this.e && (this.g(d), this.g(f)), console.error(d), console.error(f));
    }

    stackTrace(): any {
        let a;
        try {
            throw new Error('');
        } catch (b) {
            a = b.stack || '';
        }
        return a = a.split('\n').map((aa: any): any => {
            return aa.trim();
        }), a.splice('Error' === a[0] ? 2 : 1);
    }

    setLogLevel(a: any): void {
        this.logLevel = a;
    }
}

<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title dialog-title">Informações:  {{adUserPool.login}}</div>
        <div>Quantidade: {{adUserPoolInfoList.length}}</div>
    </mat-toolbar-row>
</mat-toolbar>
<div class="p-16 message" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div class="card-pool-info">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *appIfPermission="appCanViewPasswordList" class="mb-30">
                    <div *ngIf="adUserPw ==''">
                        <button type="button" mat-raised-button
                                (click)="findByLoginPw(adUserPool)">
                            Mostrar senha
                        </button>
                    </div>
                    <div *ngIf="adUserPw !=''">
                        <div class="font-weight-900" style="margin-top: 15px;">Senha:</div>
                        {{adUserPw}}
                    </div>
                </div>
            </div>
            <table class="width-100-percent">
                <thead>
                <tr class="bg-white">
                    <th style="width: 20%;">Projeto</th>
                    <th style="width: 20%;">Empresa</th>
                    <th style="width: 8%;">Serviço</th>
                    <th style="width: 52%;">Usuário</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let adUserPoolInfo of adUserPoolInfoList">
                    <td>{{adUserPoolInfo.subProjectName}}</td>
                    <td>{{adUserPoolInfo.customerGroupName}}</td>
                    <td class="text-align-center">
                        <mat-icon *ngIf="adUserPoolInfo.serviceUser" class="success-icon">check_circle</mat-icon>
                    </td>
                    <td class="no-padding">
                        <table class="width-100-percent border-none" >
                            <tr *ngFor="let emailRelationship of adUserPoolInfo.emailsRelationship">
                                <td>{{emailRelationship.email}}</td>
                                <td style="width: 86px">{{emailRelationship.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                            </tr>
                        </table>
                    </td>

                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
    <button type="button" mat-raised-button color="accent" (click)="onSetRandomPassword()">
        Gerar senha aleatória
    </button>
    <button type="button" mat-raised-button (click)="onSetLegacyRandomPassword()">
        Gerar senha aleatória (Legada)
    </button>
    <button mat-raised-button type="button" color="primary" class="md-small" (click)="onCancel()">Fechar
    </button>

    <div *ngIf="adUserPool?.id">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" style="max-width: 372px" >
            <button mat-menu-item *appIfPermission="'AUDIT'; data: adUserPool; type: 'ADUserPool'"
                    (click)="onJaversHistory()">
                <mat-icon>history</mat-icon>
                <span>Histórico</span>
            </button>
            <button mat-menu-item *appIfPermission="'AUDIT'; data: adUserPool; type: 'ADUserPool'"
                    (click)="onJaversAllHistory()">
                <mat-icon>history</mat-icon>
                <span>Histórico completo</span>
            </button>
        </mat-menu>
    </div>
</div>


import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {PdfPrint} from '../../models';
import {BaseSearchDao} from '../../base-search-dao';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {PageEvent} from '@angular/material/paginator';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';


@Injectable()
export class PdfPrintDaoService extends BaseSearchDao<PdfPrint> implements DataSource<PdfPrint> {
    private dict = {};
    private subject = new BehaviorSubject<PdfPrint[]>([]);
    private totalSubject = new BehaviorSubject<number>(0);

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'pdf-print');
    }

    filter(params?: any): Observable<PdfPrint[]> {
        return this.http.get<PdfPrint[]>(this.url + '/filter', params);
    }

    downloadPdfById(id: number): Observable<Blob> {
        return this.http.get(this.url + '/download/' + id, null, 'blob');
    }

    getMyPrints(): Observable<PdfPrint[]> {
        return this.http.get<PdfPrint[]>(this.url + '/my-prints');
    }

    connect(collectionViewer: CollectionViewer): Observable<PdfPrint[] | ReadonlyArray<PdfPrint>> {
        this.subject = new BehaviorSubject<PdfPrint[]>([]);
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.totalSubject.complete();
        this.subject = null;
        this.totalSubject = null;
    }

    load(page: PageEvent): void {
        if (this.subject && this.totalSubject) {
            this.search({field: undefined, value: undefined, limit: page.pageSize, page: page.pageIndex, fieldOrder: 'id', order: 'desc'}).subscribe(value => {
                this.subject.next(value.content);
                this.totalSubject.next(value.totalElements);
            });
        }
    }

    get total$(): Observable<number> {
        if (!this.totalSubject) {
            this.totalSubject = new BehaviorSubject<number>(0);
        }
        return this.totalSubject.asObservable();
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'enumToArray'})
export class EnumToArrayPipe implements PipeTransform {
    transform(value: any): EnumWrapper[] {
        const enumWrappers = Object.keys(value).map((o) => {
            if (isNaN(+o)) {
                return ({name: value[o], enumType: 'string'} as EnumWrapper);
            } else {
                return ({index: +o, name: value[o], enumType: 'number'} as EnumWrapper);
            }
        });
        return enumWrappers;
    }
}

export interface EnumWrapper {
    index?: number;
    enumType: 'number' | 'string';
    name: string;
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {AuthService} from '../../auth.service';
import {CustomerGroupsDashboardComponent} from './customer-groups-dashboard.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';

const routes = [
    {
        path: 'customer-groups',
        component: CustomerGroupsDashboardComponent,
        canActivate: [AuthService],
        data: {permissions: ['HipcomBulkUpdateDetail.AUDIT']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule
    ],
    entryComponents: [],
    declarations: [CustomerGroupsDashboardComponent],
    providers: []
})
export class CustomerGroupsDashboardModule {
}

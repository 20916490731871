import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {validateEmail} from '../helpers/kluh';

export function validIpv4OrIpv6(ip: string): boolean {
    // tslint:disable-next-line:max-line-length
    const regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
    const regexDNS = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
    return ip && ip.indexOf(' ') < 0 && (regex.test(ip) || regexDNS.test(ip));

}

export function findDuplicateValueInArray(strArray: any[]): any[] {
    return strArray.filter((item, index) => strArray.indexOf(item) !== index);
}

export function intMinutesToStringHourAndMinutes(t: number): string {
    const hours = t / 60;
    let hoursStr: string = hours.toString();
    if (hours < 10) {
        hoursStr = '0' + hoursStr;
    }
    const minutes = t % 60;
    let minutesStr: string = minutes.toString();
    if (minutes < 10) {
        minutesStr = '0' + minutesStr;
    }
    return hoursStr + ':' + minutesStr;
}

export function twoZerosInHourOrMinute(t: number): string {
    let numStr: string = t.toString();
    if (t < 10) {
        numStr = '0' + numStr;
    }
    return numStr;
}

export function convertYouTubeURLToEmbedVideo(html: string): string {
    const re = /(?:https:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    const vid = '<iframe width="420" height="345" src="https://www.youtube.com/embed/$1" allowfullscreen></iframe>';
    return html.replace(re, vid);
}

export function isValidIpOrFQDNInArray(strArray: any[]): any[] {
    return strArray.filter((item) =>
        !isValidIpOrFQDN(item)
    );
}

export function isValidIpOrFQDN(value: string): boolean {
    if (!value) {
        return false;
    }
    const hasValidIp = validIpv4OrIpv6(value);
    return hasValidIp || isValidFQDN(value);
}

export function isValidFQDN(value: string): boolean {
    if (!value) {
        return false;
    }
    return /^(?!.*?_.*?)(?!(?:[\w]+?\.)?\-[\w\.\-]*?)(?![\w]+?\-\.(?:[\w\.\-]+?))(?=[\w])(?=[\w\.\-]*?\.+[\w\.\-]*?)(?![\w\.\-]{254})(?!(?:\.?[\w\-\.]*?[\w\-]{64,}\.)+?)[\w\.\-]+?(?<![\w\-\.]*?\.[\d]+?)(?<=[\w\-]{2,})(?<![\w\-]{25})$/.test(value);
}

export function repeatedCharsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value) {
            const regex = /(.)\1{5,}/;
            if (regex.test(control.value)) {
                return {repeatedChars: true};
            }
        }
        return;
    };
}

export function sequentialNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const sequences = ['0123', '1234', '2345', '3456', '4567', '5678', '6789', '9876', '8765', '7654', '6543', '5432', '4321', '3210'];
        if (control?.value) {
            const hasSequentialNumber = sequences.some(sequence => control.value.includes(sequence));
            if (hasSequentialNumber) {
                return {sequentialNumber: true};
            }
        }
        return;
    };
}


export function minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value) {
            if (control.value.length < minLength) {
                return {minLength: true};
            }
        }
        return;
    };
}

export function detectBrowserName(agentIn: string): string {
    const agent = agentIn.toLowerCase();
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
}

export function detectBrowserVersion(userAgent: string): string {
    if (!userAgent) {
        return '';
    }
    let tem;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
        tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
            return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
        matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join(' ');
}

export function osDetectByUserAgent(agent: string): string {
    if (!agent) {
        return '';
    }
    if (agent.indexOf('Win') !== -1) {
        return 'Windows';
    }
    if (agent.indexOf('Mac') !== -1) {
        return 'MacOS';
    }
    if (agent.indexOf('X11') !== -1) {
        return 'UNIX';
    }
    if (agent.indexOf('Linux') !== -1) {
        return 'Linux';
    }
}

export function invalidChar(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control?.value) {
            if (regex.test(control.value)) {
                return {invalidChars: true};
            }
        }
        return;
    };
}

export function invalidIpv4OrIpv6OrFqdn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control?.value) {
            return null;
        }
        const lines = control.value.split('\n').map((line: string) => line.trim());
        if (!lines.length) {
            return null;
        }
        const hasInvalid = lines.some((line: string) => !isValidIpOrFQDN(line));
        return hasInvalid ? {invalidIpv4OrIpv6OrFqdn: true} : null;
    };
}

export function invalidIpv4OrIpv6OrFqdnIgnoreHttpHttps(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control?.value) {
            return null;
        }
        const valueUrl = control.value.replace('https://', '').replace('http://', '');
        const valuePath = valueUrl.split('/')[0];
        const lines = valuePath.split('\n').map((line: string) => line.trim());
        if (!lines.length) {
            return null;
        }
        const hasInvalid = lines.some((line: string) => !isValidIpOrFQDN(line));
        return hasInvalid ? {invalidIpv4OrIpv6OrFqdn: true} : null;
    };
}


export function invalidEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control?.value) {
            return null;
        }
        const lines = control.value.trim().split('\n').map((line: string) => line.trim());
        if (!lines.length) {
            return null;
        }
        const invalidEmails = lines.filter((line: string) => !validateEmail(line));
        if (invalidEmails.length > 0) {
            return {
                invalidEmail: {
                    message: 'Invalid email(s): ' + invalidEmails.join(', ')
                }
            };
        }
        return null;
    };
}

export function invalidLastCharacterIsNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control?.value) {
            return null;
        }
        const lines = control.value.split('\n').map((line: string) => line.trim());
        if (!lines.length) {
            return null;
        }
        const hasInvalidLastChar = lines.some((line: string) => ((/[0-9]/.test(line.charAt(line.length - 1)))) && !validIpv4OrIpv6(line));
        return hasInvalidLastChar ? {invalidLastCharacterIsNumber: true} : null;
    };
}

export function onCopyScript(value: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = 'Copiado';
    setTimeout(() => {
        this.dialogRef.close();
    }, 1000);
}

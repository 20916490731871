import {AfterViewInit, Component} from '@angular/core';
import {PermissionWrapper} from '../../directives/if-permission.directive';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {GatewayServerEditComponent} from './gateway-server-edit/gateway-server-edit.component';
import {filter} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../component-cleaner';
import {CustomerGroup} from '../../models';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {onCopyScript} from '../../utils/utils-kluh';
import {GatewayServerShowAllComponent} from './gateway-server-show-all/gateway-server-show-all.component';
import {GatewayServerBaseServerListComponent} from './gateway-server-base-server-list/gateway-server-base-server-list.component';
import {GatewayServerScriptInstallComponent} from './gateway-server-script-install/gateway-server-script-install.component';

@Component({
    selector: 'app-gateway-server',
    templateUrl: './gateway-server.component.html',
    styleUrls: ['./gateway-server.component.scss']
})
export class GatewayServerComponent extends ComponentCleaner implements AfterViewInit {
    canEditList: PermissionWrapper[];
    customerGroup: CustomerGroup;
    reloadChild = true;

    constructor(
        private dialogRef: MatDialog,
        private customerGroupService: CustomerGroupService,
        private managerUserDaoService: ManagerUserDaoService,
        private dialog: MatDialog,
    ) {
        super();
        this.canEditList =
            [
                {type: 'GatewayServer', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup}
            ];
    }

    ngAfterViewInit(): void {
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.customerGroup = customerGroup;
            }
        });
    }

    create(): void {
        const dialogRef = this.dialog.open(GatewayServerEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                gatewayServer: null,
                customerGroupId: this.customerGroup.id,
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe(() => {
            this.reloadChildComponent();
            this.dialogRef.closeAll();
        }));
    }

    scriptInstall(): void {
        const dialogRef = this.dialog.open(GatewayServerScriptInstallComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                gatewayServer: null,
                customerGroupId: this.customerGroup.id,
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe(() => {
            this.dialogRef.closeAll();
        }));
    }


    allLoggedUsersIps(): void {
        this.managerUserDaoService.allLoggedUsersIps().subscribe((loggedUsers) => {
            const ipsBreak = loggedUsers.map(user => user.ip).join('\n');
            const usersHtml = loggedUsers.map(user =>
                `<tr><td>${user.ip}</td><td>${user.email}</td></tr>`
            ).join('');
            this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: '<h1>IPs de usuários logados (' + loggedUsers.length + ')</h1><br><br><table class="min-width-450">' +
                        '<thead><tr><th class="min-width-150">IP</th><th>E-mail</th></tr></thead>' + usersHtml + '</table>',
                    disableCancel: false,
                    cancelButtonValue: 'Fechar',
                    confirmButtonValue: 'Copiar lista de IPs',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    onCopyScript(ipsBreak);
                }

            }));
        });
    }

    showAllGatewayServers(): void {
        const dialogRef = this.dialog.open(GatewayServerShowAllComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {
                showAllGatewayServers: true
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe(() => {
            this.reloadChildComponent();
            this.dialogRef.closeAll();
        }));
    }


    serverWithoutR2Security(): void {
        const dialogRef = this.dialog.open(GatewayServerBaseServerListComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe(() => {
            this.dialogRef.closeAll();
        }));
    }

    reloadChildComponent(): void {
        this.reloadChild = false;
        setTimeout(() => this.reloadChild = true);
    }

    onCopyScript(): void {
        onCopyScript('');
    }

}


import {ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpInterceptor} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';
import {AppComponent} from './app.component';
import {KluhManagerModule} from './kluh-manager/kluh-manager.module';
import {ShowAlwaysErrorStateMatcher} from './kluh-manager/show-always-error-state-matcher';
import {FuseSplashScreenService} from '../@fuse/services/splash-screen.service';
import {FuseConfigService} from '../@fuse/services/config.service';
import {FuseNavigationService} from '../@fuse/components/navigation/navigation.service';
import {FuseSharedModule} from '../@fuse/shared.module';
import {FuseModule} from '../@fuse/fuse.module';
import {fuseConfig} from './fuse-config';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '../@fuse/components';
import {LayoutModule} from './layout/layout.module';
import {SentryErrorHandlerService} from './kluh-manager/sentry-error-handler.service';
import {ManagerHttpInterceptorService} from './kluh-manager/error/manager-http-interceptor.service';
import {MatPaginatorTranslator} from './kluh-manager/i18n/mat-paginator-translator';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import {registerLocaleData} from '@angular/common';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorIntl} from '@angular/material/paginator';

registerLocaleData(localePt, 'pt-BR', localePtExtra);

export function errorInterceptorFactory(injector: Injector): HttpInterceptor {
    return new ManagerHttpInterceptorService(injector);
}
export function sentryErrorFactory(injector: Injector): ErrorHandler {
    return new SentryErrorHandlerService(injector);
}

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            enableTracing: false, // <- debug the routes
            initialNavigation: true // <- turn off the initial redirect, used for redirect or hash routing strategy
        }),
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        TranslateModule.forRoot(),
        FuseProgressBarModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        LayoutModule,
        MatIconModule,
        KluhManagerModule
    ],
    providers: [
        FuseSplashScreenService,
        FuseConfigService,
        FuseNavigationService,
        {provide: ErrorStateMatcher, useClass: ShowAlwaysErrorStateMatcher},
        // {
        //     provide: ErrorHandler,
        //     useFactory: sentryErrorFactory,
        //     deps: [Injector]
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: errorInterceptorFactory,
            multi: true,
            deps: [Injector]
        },
        { provide: MatPaginatorIntl, useFactory: matPaginatorTranslator },
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}


export function matPaginatorTranslator(): MatPaginatorIntl {
    return new MatPaginatorTranslator();
}

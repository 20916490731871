import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {ImageFileService} from '../../image-file/image-file-service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {CustomerGroup, FirebaseUserDetails, ManagerUser, SearchCriteria} from '../../../models';
import {MatMenuTrigger} from '@angular/material/menu';
import {SafeHtml} from '@angular/platform-browser';
import {AiUserGroup, Message, Thread} from '../../../models-ai';
import {FormControl} from '@angular/forms';
import {ThreadService} from '../thread/thread.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, take} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {AiUserGroupDaoService} from '../ai-user-group/ai-user-group.dao.service';
import {Observable} from 'rxjs/internal/Observable';
import {ThreadDaoService} from '../thread/thread.dao.service';
import {MessageContent, MessageContentTextAnnotation, MessageDaoService, MessageFlux} from '../message/message.dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {CrudOperationWrapper, updateQueryString} from '../../../helpers/kluh';
import {AiUserGroupEditComponent} from '../ai-user-group/ai-user-group-edit/ai-user-group-edit.component';
import {AiConfigComponent} from '../ai-config/ai-config.component';
import {AiUserGroupFileEditComponent} from '../ai-user-group-file/ai-user-group-file-edit/ai-user-group-file-edit.component';
import {AiSoftwareCompanyFileEditComponent} from '../ai-software-company-file/ai-software-company-file-edit/ai-software-company-file-edit.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerGroupAiConfigDaoService} from '../ai-config/customer-group-ai-config.dao.service';
import * as showdown from 'showdown';
import {of} from 'rxjs/internal/observable/of';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {KeyValueWrapper} from '../../../helpers/key-value-wrapper';
import {AiUserGroupFileDaoService} from '../ai-user-group-file/ai-user-group-file.dao.service';

@Component({
    selector: 'app-chat-ai',
    templateUrl: './chat-ai.component.html',
    styleUrls: ['./chat-ai.component.scss']
})
export class ChatAiComponent extends ComponentCleaner {
    user: FirebaseUserDetails;
    message: SafeHtml = '';
    fileCitations: string[] = [];
    threads: Thread[] = [];
    aiUserGroups: AiUserGroup[] = [];
    currentThread: Thread;
    messages: Message[] = [];
    messageCtrl: FormControl = new FormControl();
    loadingConfig = false;
    loading = false;
    loadingMessage = false;
    aiGroupControl: FormControl = new FormControl();
    searchControl: FormControl = new FormControl();
    privateThreadControl: FormControl = new FormControl();
    customerGroup: CustomerGroup;
    sameCompanyUser = false;
    hasReadPermission = false;
    hasWritePermission: PermissionWrapper[];
    hasAuditPermission: PermissionWrapper[];
    isAdmin = false;
    hasConfiguration = true;
    threadPage = 0;
    threadPageLoading = false;
    startThreadBase64Id = '';
    limitThreads = 30;
    hasMoreThreads = true;
    showAnnotations = false;

    constructor(
        private dialog: MatDialog,
        public imageFileService: ImageFileService,
        private userAuthorityDaoService: UserAuthorityDaoService,
        private threadService: ThreadService,
        private aiUserGroupDaoService: AiUserGroupDaoService,
        private threadDaoService: ThreadDaoService,
        private messageDaoService: MessageDaoService,
        private adminService: R2CloudAdminService,
        private managerUserDaoService: ManagerUserDaoService,
        private customerGroupService: CustomerGroupService,
        private customerGroupAiConfigDaoService: CustomerGroupAiConfigDaoService,
        private aiUserGroupFileDaoService: AiUserGroupFileDaoService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super();
        this.message = '';
        this.fileCitations = [];
        this.initValues();
        this.addSubscription(this.aiGroupControl.valueChanges.subscribe((value) => {
            if (value && this.currentThread) {
                this.currentThread.aiUserGroupId = value;
            }
        }));
        this.addSubscription(this.privateThreadControl.valueChanges.subscribe((value) => {
            this.currentThread.private = !!value;
        }));

        this.addSubscription(this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.threadPage = 0;
            this.loadAllThreads();
        }));

        this.addSubscription(this.route.queryParams.pipe(take(1)).subscribe(params => {
            this.startThreadBase64Id = params['thread'];
        }));

        this.hasWritePermission =
            [
                {type: 'ChatAi', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            ];
        this.hasAuditPermission =
            [
                {type: 'ChatAi', permission: 'AUDIT', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            ];
    }

    @ViewChildren(MatMenuTrigger, {read: ElementRef}) triggers: QueryList<ElementRef>;

    onKeydown(event: KeyboardEvent): void {
        if (event.key === 'Enter' && !event.ctrlKey && !event.metaKey && !event.altKey) {
            event.preventDefault();
            if (this.canSend()) {
                this.sendMessage();
            }
        } else if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            event.preventDefault();
            this.insertNewLine();
        } else if (event.altKey && event.key === 'Enter') {
            event.preventDefault();
            this.insertNewLine();
        }
    }

    private insertNewLine(): void {
        const currentValue = this.messageCtrl.value || '';
        const newValue = `${currentValue}\n`;
        this.messageCtrl.setValue(newValue);
    }

    canSend(): boolean {
        return !(this.loadingMessage || (!this.aiGroupControl.value && !this.currentThread?.id) || !this.messageCtrl.value);
    }

    newThreadClickButton(): void {
        this.searchControl.setValue('');
        this.newThread();
    }

    newThread(setSelectedThreadScroll: boolean = true): void {
        let selectedThread = this.threads?.find(t => t?.title === '');
        if (!selectedThread) {
            const thread = this.threadService.initThread();
            thread.title = '';
            thread.managerUserId = this.user.managerUser.id;
            this.threads?.unshift(thread);
            selectedThread = thread;
        }
        if (setSelectedThreadScroll) {
            this.scrollToThread();
        }
        this.openThread(selectedThread);
    }

    openThread(thread: Thread): void {
        this.setSelectedThread(thread);
        this.messages = [];
        if (thread.id) {
            updateQueryString(this.router, this.route, 'thread', thread.id.toString(), true);
            this.populateMessages(thread.id);
        } else {
            updateQueryString(this.router, this.route, 'thread', '', true);
        }
    }

    private populateMessages(threadId: number, lastX: number = 0): void {
        if (lastX > 0) {
            this.addSubscription(this.messageDaoService.findLastXMessages(threadId, lastX).subscribe((messages) => {
                messages.forEach(message => {
                    const index = this.messages.findIndex(m => m.id === message.id);
                    if (index > -1) {
                        this.messages[index] = message;
                    }
                });
                this.setScrollDown();
            }));
        } else {
            this.addSubscription(this.messageDaoService.getMessages(threadId).subscribe((messages) => {
                this.messages = messages;
                this.setScrollDown();
            }));
        }
    }

    private scrollToBottom(timeout: number = 300): void {
        const scrollableDiv = document.getElementById('threads');
        setTimeout(() => {
            if (scrollableDiv) {
                scrollableDiv.scrollTop = scrollableDiv.scrollHeight + 90;
            }
        }, timeout);
    }

    private initValues(): void {
        this.addSubscription(this.loadUserAndCustomerGroup().subscribe(values => {
            this.loadingConfig = true;
            this.processUserAndCustomerGroup(values);
        }));
    }

    private loadUserAndCustomerGroup(): Observable<[FirebaseUserDetails, CustomerGroup, boolean]> {
        return combineLatest([
            this.userAuthorityDaoService.getMe(),
            this.customerGroupService.get(),
            this.userAuthorityDaoService.isAdminUser(),
        ]);
    }

    private processUserAndCustomerGroup(values: [FirebaseUserDetails, CustomerGroup, boolean]): void {
        this.hasConfiguration = false;
        const [user, customerGroup, isAdmin] = values;
        this.isAdmin = isAdmin;
        this.customerGroup = customerGroup;
        this.hasReadPermission = this.checksIfUserBelongsCompany(user, customerGroup);

        if (this.hasReadPermission || this.isAdmin) {
            this.user = user;
            setTimeout(() => {
                if (customerGroup?.id) {
                    this.loadConfiguration(customerGroup.id);
                    this.checkSameCompanyUser(this.customerGroup.customerIds, this.user.managerUser.customerId);
                }
            }, 2000);
        }
    }

    private checkSameCompanyUser(customerGroupCustomerIds: number[], customerId: number): void {
        if (customerGroupCustomerIds.indexOf(customerId) > -1 || this.isAdmin) {
            this.sameCompanyUser = true;
        }
    }

    private checksIfUserBelongsCompany(user: FirebaseUserDetails, customerGroup: CustomerGroup): boolean {
        return customerGroup?.customerIds.some(id => id === user?.managerUser.customerId) || false;
    }

    private loadConfiguration(customerGroupId: number): void {
        this.addSubscription(
            this.customerGroupAiConfigDaoService.getConfig(customerGroupId).subscribe(config => {
                this.loadingConfig = false;
                if (config?.customerGroupId) {
                    this.hasConfiguration = config.active;
                    this.loadManagerUsers(customerGroupId);
                }
            })
        );
    }

    private loadManagerUsers(customerGroupId: number): void {
        this.addSubscription(
            this.managerUserDaoService.findAllByCustomerGroupId(customerGroupId).subscribe(managerUsers => {
                if (managerUsers) {
                    this.adminService.managerUsers = managerUsers;
                    this.loadAllThreads(2000);
                }
            })
        );
    }

    private loadAllThreads(timeout: number = 1, setSelectedThread: boolean = true): void {
        this.threadPageLoading = true;
        setTimeout(() => {
            this.getAllThreads(setSelectedThread);
        }, timeout);
    }

    private changeToArray(obj: any | any[]): any[] {
        if (!obj) {
            return [];
        }
        if (!Array.isArray(obj)) {
            return [obj];
        }
        return obj;
    }

    private getAllThreads(setSelectedThreadScroll: boolean = true): void {
        const searchValue = this.searchControl.value;
        const searchCriteria: SearchCriteria = {
            field: 'search',
            value: searchValue,
            fieldOrder: null,
            order: null,
            limit: this.limitThreads,
            page: this.threadPage,
        };

        this.threadPageLoading = true;

        this.addSubscription(combineLatest([this.threadDaoService.getThreads(this.customerGroup.id, searchCriteria), this.aiUserGroupDaoService.getAll()])
            .pipe(map((values) => {
                    const threads = this.changeToArray(values[0]);
                    const aiUserGroups = this.changeToArray(values[1]);
                    if (searchCriteria.value) {
                        return threads.filter(t => t.title !== '');
                    }
                    return [threads, aiUserGroups];
                }),
                catchError((error) => {
                    console.error(error);
                    return of([]);
                }),
                finalize(() => {
                    this.threadPageLoading = false;
                })
            ).subscribe((values2) => {
                const threads = this.changeToArray(values2[0]);
                const aiUserGroups = this.changeToArray(values2[1]);
                this.getAllAiUserGroups(aiUserGroups);
                if (searchCriteria.page === 0) {
                    this.threads = threads;
                } else {
                    this.addUniqueThreads(threads);
                    if (threads.length <= 0) {
                        this.hasMoreThreads = false;
                    }
                    this.scrollToBottom(0);
                }
                if (!this.currentThread?.id) {
                    this.newThread(setSelectedThreadScroll);
                }
                if (searchCriteria.value && this.threads?.length > 0) {
                    this.openThread(this.threads[0]);
                }
                if (setSelectedThreadScroll) {
                    this.selectedThreadByStartThreadId();
                }
                this.removeThreadsWithoutAiGroupPermission();
            }));
    }

    private removeThreadsWithoutAiGroupPermission(): void {
        this.threads = this.threads?.filter(thread => {
            const aiUserGroup = this.findAiUserGroupById(thread?.aiUserGroupId);
            return (thread?.aiUserGroupId > 0 && thread?.private) || (aiUserGroup?.default) || !thread?.id;
        });
    }

    private addUniqueThreads(newThreads: Thread[]): void {
        newThreads.forEach((newThread) => {
            const exists = this.threads?.some(thread => thread.id === newThread.id);
            if (!exists) {
                this.threads?.push(newThread);
            }
        });
    }


    private selectedThreadByStartThreadId(): void {
        if (this.startThreadBase64Id) {
            const threadId = +window.atob(this.startThreadBase64Id);
            const thread = this.threads?.find(t => t.id === threadId);
            this.startThreadBase64Id = null;
            if (thread) {
                this.openThread(thread);
                this.scrollToThread(thread.id);
            } else {
                this.threadDaoService.findById(threadId).subscribe(threadFromDao => {
                    if (threadFromDao) {
                        this.threads?.push(threadFromDao);
                        this.openThread(threadFromDao);
                        setTimeout(() => {
                            this.scrollToThread(threadFromDao.id);
                        }, 200);
                    }
                });
            }
        }
    }

    findAiUserGroupById(id: number): AiUserGroup | undefined {
        return this.aiUserGroups.find(aiUserGroup => aiUserGroup?.id === id);
    }

    private getAllAiUserGroups(aiUserGroups: AiUserGroup[]): void {
        this.aiUserGroups = aiUserGroups;
        const aiUserGroupDefault = this.aiUserGroups?.find(g => g?.default);
        if (aiUserGroupDefault && this.aiGroupControl) {
            this.aiGroupControl.setValue(aiUserGroupDefault.id);
        }
    }

    private getThreadByProviderThreadId(providerThreadId: string, messageIndex: number = 0): void {
        if (providerThreadId) {
            this.addSubscription(this.threadDaoService.getThreadByProviderThreadId(providerThreadId).subscribe((thread) => {
                if (thread) {
                    const index = this.threads?.findIndex((value) => value === this.currentThread);
                    if (index > -1) {
                        this.currentThread = thread;
                        this.threads[index] = thread;
                        this.loadingMessage = false;
                    }
                    if (messageIndex > 0) {
                        this.populateMessages(thread.id);
                    }
                }
            }));
        }
    }

    deleteByThreadId(thread: Thread): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa pergunta?<br><br>"' + thread.title + '"',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.threads = this.removeThreadFromList(this.threads, thread.id);
                this.addSubscription(this.threadDaoService.deleteThread(thread).subscribe((_) => {
                    this.messages = [];
                }));
            }
        }));
    }

    private removeThreadFromList(thread: Thread[], threadId: number): Thread[] {
        return thread.filter(thr => thr.id !== threadId);
    }

    getClass(message: Message): string {
        return message.providerRole;
    }

    trackByFn(index: number, item: MessageContent): string {
        return item.type;
    }

    private setSelectedThread(thread: Thread): void {
        this.message = '';
        this.fileCitations = [];
        this.currentThread = thread;
    }

    getMessageText(message: Message): MessageContent[] {
        const cleanedJsonString = message.providerContent.replace(/\n/g, ' ');
        return this.getMessageProviderText(cleanedJsonString);
    }

    getMessageFlux(json: string): MessageFlux {
        if (this.isJsonString(json)) {
            const parse = JSON.parse(json);
            return parse as MessageFlux;
        } else {
            return {
                id: null,
                object: null,
                delta: null,
            } as MessageFlux;
        }
    }

    getMessageProviderText(json: string): MessageContent[] {
        if (this.isJsonString(json)) {
            const parse = JSON.parse(json);
            if (Array.isArray(parse)) {
                return parse as MessageContent[];
            } else {
                return [parse] as MessageContent[];
            }
        } else {
            return [{
                type: 'text',
                text: {
                    value: json,
                    annotations: [],
                },
            }] as MessageContent[];
        }
    }

    isJsonString(str: string): boolean {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    getManagerUser(managerUserId: number): ManagerUser {
        return this.adminService.managerUsers.find(user => user.id === managerUserId);
    }

    openMenu(managerUserId: number): void {
        const trigger = this.triggers.toArray().find((_, index) => {
            return this.adminService.managerUsers[index]?.id === managerUserId;
        });
        if (trigger) {
            const matMenuTrigger = trigger.nativeElement.querySelector('button[mat-menu-trigger-for]');
            matMenuTrigger?.click();
        }
    }

    sendMessage(): void {
        let messageInner = '';
        const fileCitations: MessageContentTextAnnotation[] = [];
        const messageText = this.messageCtrl.value;
        if (!this.currentThread.title) {
            this.currentThread.title = messageText;
        }
        this.messageCtrl.setValue('');
        const message = this.messageDaoService.createMessageUser(this.currentThread, this.user.managerUser.id, messageText);
        const assistantMessage = this.messageDaoService.createMessageAssistant(this.currentThread);
        this.messages.push(message, assistantMessage);
        this.setScrollDown();
        this.setLoadingState(true);
        const aiUserGroupId = this.currentThread.aiUserGroupId ?? this.aiUserGroups.find(g => g?.default)?.id;
        const isPrivate = this.currentThread.private;
        let lastThreadId = '';
        this.addSubscription(this.messageDaoService.sendMessages(message, aiUserGroupId, isPrivate).pipe(
            finalize(() => {
                this.setScrollDown();
                this.getThreadByProviderThreadId(lastThreadId, this.messages.length - 1);
            })
        ).subscribe(
            data => {
                this.setLoadingState(false);
                if (!lastThreadId) {
                    lastThreadId = this.extractThreadId(data);
                }
                const messageFlux = this.getMessageFlux(data);
                if (messageFlux?.delta?.content?.length > 0) {
                    const content = messageFlux.delta.content[0];
                    messageInner += content?.text?.value ?? '';
                    if (content?.text?.annotations?.length > 0) {
                        fileCitations.push(...content.text.annotations);
                    }
                    this.updateMessageContent(messageInner, fileCitations);
                }
            },
            error => {
                console.error(error);
                this.setLoadingState(false);
            }
        ));
    }

    private setLoadingState(state: boolean): void {
        this.loading = state;
        this.loadingMessage = state;
    }

    private updateMessageContent(messageInner: string, fileCitations: MessageContentTextAnnotation[]): void {
        const index = this.messages.length - 1;
        const annotationsString = fileCitations.length > 0 ? `, "annotations": ${JSON.stringify(fileCitations)}` : ', "annotations":[]';
        const htmlContent = this.breakLineToHtml(messageInner);
        const jsonMessage = `[{"type":"text","text":{"value":"${htmlContent}"${annotationsString}}}]`;
        const fullMessage = this.getMessageProviderText(jsonMessage);

        this.messages[index].providerContent = JSON.stringify(fullMessage);
        this.setScrollDown();
    }

    breakLineToHtml(text: string): string {
        return text
            .replace(/(\r\n|\r|\n|\\n)/g, '\\n')
            .replace(/"/g, '\\"');
    }

    extractThreadId(input: string): string | null {
        if (input.indexOf('thread_id') === -1) {
            return '';
        }
        const regex = /"thread_id":"(thread_[^"]+)"/;
        const match = regex.exec(input);
        return match ? match[1] : null;
    }

    private setScrollDown(): void {
        setTimeout(() => {
            const scrollableDiv = document.getElementById('chat');
            scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        }, 200);
    }

    markdownToHtml(text: string): string {
        let text2 = text.replace(/```/gim, '');
        if (!this.showAnnotations) {
            text2 = text2.replace(/【.*?】/gim, '');
        }
        const converter = new showdown.Converter();
        return converter.makeHtml(text2);
    }

    aiUserGroupEdit(): void {
        const dialogRef = this.dialog.open(AiUserGroupEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'SAVE' || result.operation === 'CREATE') {
                this.loadAllThreads();
            }
        }));
    }

    aiConfigEdit(): void {
        const dialogRef = this.dialog.open(AiConfigComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result) {
                this.initValues();
            }
        }));
    }

    aiUserGroupFiles(): void {
        const dialogRef = this.dialog.open(AiUserGroupFileEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((_: CrudOperationWrapper) => {
        }));
    }

    aiSoftwareCompaniesFiles(): void {
        const dialogRef = this.dialog.open(AiSoftwareCompanyFileEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((_: CrudOperationWrapper) => {
        }));
    }

    deleteMessage(message: Message): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa mensagem?<br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.addSubscription(this.messageDaoService.delete(message).subscribe((_) => {
                    this.messages = this.messages.filter(value => value.id !== message.id);
                }));
            }
        }));
    }

    hasMoreThreadsThanLimit(): boolean {
        return this.threads?.length >= this.limitThreads && this.hasMoreThreads;
    }

    getMoreThreads(): void {
        this.threadPage++;
        this.loadAllThreads(1, false);
    }

    scrollToThread(threadId: number = null): void {
        let divThreadId = 'thread_';
        if (threadId) {
            divThreadId = 'thread_' + threadId;
        }
        new Promise<void>((resolve) => {
            const checkVariable = () => {
                if (!this.threadPageLoading) {
                    const element = document.getElementById(divThreadId);
                    if (element) {
                        element.scrollIntoView();
                        resolve();
                    }
                } else {
                    setTimeout(checkVariable, 100);
                }
            };
            checkVariable();
        }).then();
    }

    showPrimaryConfiguration(): boolean {
        return !!(!this.hasConfiguration && !this.loadingConfig && this.hasAuditPermission);
    }


    aiFilesStatistics(): void {
        this.addSubscription(this.aiUserGroupFileDaoService.statistics(this.customerGroup.id).subscribe((statistics) => {
            if (statistics) {
                const keyValueList: KeyValueWrapper[] = [];
                statistics.forEach(statistic => {
                    keyValueList.push(statistic);
                });
                let tr = '';
                keyValueList.forEach(kv => {
                    tr = tr + '<tr><td>' + kv.key + '</td><td>' + kv.value + '</td></tr>';
                });
                this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: '<table class="w-400">' + tr + '</table>',
                        disableCancel: true,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline'
                    }
                }).afterClosed().subscribe(_ => {
                }));
            }
        }));
    }

    hasThreads(): boolean {
        if (this.searchControl?.value) {
            if (this.threads?.length < 1) {
                return false;
            }
            if (this.threads?.length === 1 && !this.threads[0].id) {
                return false;
            }
        }
        return true;
    }


}

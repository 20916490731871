export const locale = {
    lang: 'pt-br',
    data: {
        'RDP_HTML5': {
            'CONNECTION_INITIALIZED': 'Conexão inicializada',
            'CONNECTION_OPENING_REMOTE_PORT': 'Abrindo porta remota',
            'CONNECTION_ESTABLISHING_SECURE_CONNECTION': 'Estabelecendo conexão segura',
            'CONNECTION_CONFIGURING_REMOTE_CONNECTION': 'Configurando a conexão remota',
            'CONNECTION_DETECTING_NETWORK_QUALITY': 'Detectando a qualidade da rede',
            'CONNECTION_SESSION_BROKER_FINDING_DESTINATION': 'Agente de sessão localizando a sessão',
            'CONNECTION_SESSION_BROKER_LOADING_DESTINATION': 'Agente de sessão carregando o destino',
            'CONNECTION_SESSION_BROKER_BRINGING_SESSION_ONLINE': 'Agente de sessão colocando a sessão online',
            'CONNECTION_SESSION_BROKER_REDIRECTING_TO_DESTINATION': 'Agente de sessão redirecionando para destino',
            'CONNECTION_VIRTUAL_MACHINE_LOADING': 'Máquina virtual carregando',
            'CONNECTION_VIRTUAL_MACHINE_WAKING': 'Máquina virtual acordando',
            'CONNECTION_VIRTUAL_MACHINE_STARTING': 'Máquina virtual iniciando',
            'CONNECTION_VIRTUAL_MACHINE_RETRYING_SESSION_MONITORING': 'Máquina virtual repetindo o monitoramento de sessão',
            'CONNECTION_VIRTUAL_MACHINE_STARTING_SESSION_MONITORING': 'Máquina virtual iniciando o monitoramento de sessão'
        }
    }
};

<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header class="pt-0">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Últimos RemoteApps</div>
            <div fxLayoutAlign="space-between center">
                <mat-form-field  appearance="outline" class="h-70 mr-5">
                    <mat-select placeholder="Quantidade"  [formControl]="sizeListControl">
                        <mat-option [value]="5">5</mat-option>
                        <mat-option [value]="10">10</mat-option>
                        <mat-option [value]="50">50</mat-option>
                        <mat-option [value]="100">100</mat-option>
                        <mat-option [value]="200">200</mat-option>
                        <mat-option [value]="300">300</mat-option>
                        <mat-option [value]="500">500</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field  appearance="outline"  class="h-70 mr-5">
                    <mat-select placeholder="tipo"  [formControl]="snapshotTypeControl">
                        <mat-option [value]="snapshotType.ALL">Todos</mat-option>
                        <mat-option [value]="snapshotType.INITIAL">Criado</mat-option>
                        <mat-option [value]="snapshotType.UPDATE">Edição</mat-option>
                        <mat-option [value]="snapshotType.TERMINAL">Delete</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button type="button" color="primary" class="md-small" (click)="refresh()">Enviar</button>
            </div>
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th style="width: 50px"></th>
                <th style="width: 250px"></th>
                <th></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let obj of remoteAppsSnapshots">
                <td>
                    <img *ngIf="obj.state.imageBase64" [src]="'data:image/png;base64,' + obj.state.imageBase64" width="32" height="32">
                </td>
                <td>
                    <div>
                        <div>
                            <b *ngIf="obj.state.name; else existsEmail">{{obj.state.name}}</b>
                            <ng-template #existsEmail>
                                <b>cdoId: {{obj.globalId.cdoId | json}}</b>
                                <button mat-raised-button type="button" color="primary" class="md-small ml-25" (click)="onJaversHistory(obj.globalId.cdoId)">Histórico</button>
                            </ng-template>
                        </div>
                        <div class="font-size-12"
                             style="margin-bottom: 10px">{{obj.state.app}}</div>
                        <div class="font-size-12"><b>por:</b> {{obj.commitMetadata?.author}}</div>
                        <div class="font-size-12">{{obj.commitMetadata?.commitDate}}</div>
                        <div *ngIf="statusServer.getRemoteApp(obj.state.id, remoteApps) as remoteApp">
                            <ul *ngIf="statusServer.getSubProject(remoteApp?.subProjectId, subProjects) as subProject">
                                <li>
                                    ID:<b> {{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.id}} </b>
                                </li>
                                <li>Empresa:
                                    <b>{{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.name}} </b>
                                </li>
                                <li>subProject:<b> {{subProject?.name}} </b></li>
                            </ul>
                        </div>
                    </div>
                </td>
                <td>
                    {{obj.commitMetadata.commitDate | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
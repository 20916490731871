import {Component, Inject} from '@angular/core';

import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {minLengthValidator, repeatedCharsValidator, sequentialNumberValidator} from '../../../utils/utils-kluh';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
    myForm: FormGroup;

    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder) {


        this.myForm = this.fb.group({
            oldPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, minLengthValidator(6), repeatedCharsValidator(), sequentialNumberValidator()]],
            reNewPassword: ['', [Validators.required, minLengthValidator(6), repeatedCharsValidator(), sequentialNumberValidator()]]
        }, {validator: this.checkPasswords});


    }

    checkPasswords(group: FormGroup): ValidationErrors {
        const pass = group.controls.newPassword.value;
        const confirmPass = group.controls.reNewPassword.value;
        return pass === confirmPass ? null : {notSame: true};
    }

    onSubmit(): void {
        const result: ChangePasswordModalResult = {
            newPassword: this.myForm.value.newPassword,
            oldPassword: this.myForm.value.oldPassword
        };
        this.dialogRef.close(result);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}

export interface ChangePasswordModalResult {
    oldPassword: string;
    newPassword: string;
}

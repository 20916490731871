import {ConnectionProperties} from './connection-properties';

export class RedirectionsRequest {
    properties: ConnectionProperties;

    constructor(private a: any, b: ConnectionProperties){
        this.properties = b;
    }

    complete(b: any): void {
        const d = this.a;
        this.properties.connectionSettings.EnableClipboard = true;
        this.properties.connectionSettings.EnablePrinting = true;
        d.complete(this.properties, true);
        this.a = null;
    }

    cancel(): void {
        const b = this.a;
        this.a = null, b && b.cancel();
    }

}

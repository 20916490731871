import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {MarketplaceComponent} from './marketplace.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MarketplaceProductComponent} from './marketplace-product/marketplace-product.component';
import {MarketplaceProductCreateAndEditComponent} from './marketplace-product-create-and-edit/marketplace-product-create-and-edit.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MarketplaceProductDescriptionComponent} from './marketplace-product/marketplace-product-description/marketplace-product-description.component';
import {MarketplaceProductFAQComponent} from './marketplace-product/marketplace-product-faq/marketplace-product-faq.component';
import {MarketplaceProductScreenComponent} from './marketplace-product/marketplace-product-screen/marketplace-product-screen.component';
import {MarketplaceProductCommentComponent} from './marketplace-product/marketplace-product-comment/marketplace-product-comment.component';
import {MarketplaceProductContactComponent} from './marketplace-product/marketplace-product-contact/marketplace-product-contact.component';
import {MarketplaceProductFAQEditComponent} from './marketplace-product/marketplace-product-faq/marketplace-product-faq-edit/marketplace-product-faq-edit.component';
import {MarketplaceProductDetailScreenEditComponent} from './marketplace-product/marketplace-product-screen/marketplace-product-screen-edit/marketplace-product-detail-screen-edit.component';
import {MarketplaceOrderComponent} from './marketplace-order/marketplace-order.component';
import {MarketplaceOrderDetailComponent} from './marketplace-order/marketplace-order-detail/marketplace-order-detail.component';
import {MarketplaceProductCommentEditComponent} from './marketplace-product/marketplace-product-comment/marketplace-product-comment-edit/marketplace-product-comment-edit.component';
import {MarketplaceCategoryCreateAndEditComponent} from './marketplace-category-create-and-edit/marketplace-category-create-and-edit.component';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MarketplaceCategoryDaoService} from './marketplace-category-create-and-edit/marketplace-category.dao.service';
import {MarketplaceProductDaoService} from './marketplace-product/marketplace-product.dao.service';
import {MarketplaceProductFAQDaoService} from './marketplace-product/marketplace-product-faq/marketplace-product-faq.dao.service';
import {MarketplaceProductCommentDaoService} from './marketplace-product/marketplace-product-comment/marketplace-product-comment.dao.service';
import {MarketplaceProductScreenDaoService} from './marketplace-product/marketplace-product-screen/marketplace-product-screen.dao.service';
import {MarketplaceOrderDaoService} from './marketplace-order/marketplace-order.dao.service';
import {MarketplaceOrderCommentDaoService} from './marketplace-order/marketplace-order-comment/marketplace-order-comment.dao.service';
import {SoftwareCompanyCreateAndEditComponent} from './software-company-create-and-edit/software-company-create-and-edit.component';
import {SoftwareCompanyDaoService} from './software-company-create-and-edit/software-company.dao.service';
import {MarketplaceOrderTableComponent} from './marketplace-order/marketplace-order-table/marketplace-order-table.component';
import {MarketplaceOrderCommentListComponent} from './marketplace-order/marketplace-order-detail/marketplace-order-comment-list/marketplace-order-comment-list.component';
import {MarketplaceProductListComponent} from './marketplace-product-list/marketplace-product-list.component';
import {FooterModule} from '../../../layout/components/footer/footer.module';

const routes = [
    {
        path: 'marketplace',
        component: MarketplaceComponent,
        canActivate: [AuthService]
    },
    {
        path: 'marketplace-order',
        component: MarketplaceOrderComponent,
        canActivate: [AuthService]
    },
    {
        path: 'marketplace-order/detail',
        component: MarketplaceOrderDetailComponent,
        canActivate: [AuthService]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        KluhSharedModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSelectModule,
        MatGridListModule,
        NgBytesPipeModule,
        MatTabsModule,
        MatExpansionModule,
        NgxMaskModule,
        NgxMatSelectSearchModule,
        FooterModule
    ],
    providers: [
        {provide: MatDialogRef, useValue: {}},
        MarketplaceCategoryDaoService,
        MarketplaceProductDaoService,
        MarketplaceProductFAQDaoService,
        MarketplaceProductCommentDaoService,
        MarketplaceProductScreenDaoService,
        MarketplaceOrderDaoService,
        SoftwareCompanyDaoService,
        MarketplaceOrderCommentDaoService
    ],
    entryComponents: [MarketplaceProductComponent, MarketplaceProductCreateAndEditComponent],
    declarations: [
        MarketplaceComponent,
        MarketplaceProductComponent,
        MarketplaceProductCreateAndEditComponent,
        MarketplaceProductDescriptionComponent,
        MarketplaceProductFAQComponent,
        MarketplaceProductScreenComponent,
        MarketplaceProductCommentComponent,
        MarketplaceProductContactComponent,
        MarketplaceProductFAQEditComponent,
        MarketplaceProductDetailScreenEditComponent,
        MarketplaceOrderComponent,
        MarketplaceOrderDetailComponent,
        MarketplaceProductCommentEditComponent,
        MarketplaceCategoryCreateAndEditComponent,
        SoftwareCompanyCreateAndEditComponent,
        MarketplaceOrderTableComponent,
        MarketplaceOrderCommentListComponent,
        MarketplaceProductListComponent],

})
export class MarketplaceModule {
}

import {Injectable} from '@angular/core';
import * as X2JS from 'x2js';
import {Bookmark} from './ts/bookmark';
import {BookmarkType} from './ts/enum/bookmark-type';
import {LoggerService} from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class FeedParserService {

    constructor(private a: LoggerService) {

    }

    private c = (a, b) => {
        const c = a._Title.toUpperCase(),
            d = b._Title.toUpperCase(),
            e = a._Type,
            f = b._Type;
        let g = 0;
        return e === f ? c > d ? g = 1 : d > c && (g = -1) : g = 'RemoteApp' === f ? 1 : -1, g;
    };

    parseWorkspaceFeed(b: any): WebFeed {
        const d = new X2JS(),
            e: any = d.xml2js(b),
            f: WebFeed = {
                publisher: e.ResourceCollection.Publisher._Name,
                publisherId: e.ResourceCollection.Publisher._ID,
                bookmarks: [],
                folders: [],
                url: null,
                description: e.ResourceCollection.Publisher._Description,
                supportInfoPresent: !1,
                supportObj: {
                    logoURL: null,
                    supportURL: null,
                    supportPhone: null,
                    privacyURL: null
                }
            };
        let g = f.description;
        const h = g.indexOf('{');
        if (h >= 0 && (f.description = g.substr(0, h), g = g.substr(h), g = g.trim(), g.search('{*}'))) {
            try {
                f.supportObj = JSON.parse(g), f.supportInfoPresent = !0;
            } catch (b) {
                f.supportObj = null, this.a.log('Error in reading the following support information JSON : ' + g);
            }
        }
        let i;
        const j = d.asArray(e.ResourceCollection.Publisher.Resources.Resource);
        j.sort(this.c);
        let k, l, m, n;
        for (k = 0; k < j.length; k++) {
            if (i = new Bookmark, i.id = j[k]._ID, i.friendlyName = j[k]._Title, i.thumbnail = j[k].Icons.Icon32._FileURL,
                i.rdpFileURL = d.asArray(j[k].HostingTerminalServers.HostingTerminalServer)[0].ResourceFile._URL, i.workspaceName = f.publisher,
                i.bookmarkType = 'RemoteApp' === j[k]._Type ? BookmarkType.RemoteApp : BookmarkType.Desktop, j[k].Folders) {
                for (m = d.asArray(j[k].Folders.Folder), l = 0; l < m.length; l++) {
                    n = m[l]._Name, '/' === n[0] && n.length > 1 && (n = n.substr(1)),
                    f.folders.indexOf(n) < 0 && '/' !== n && f.folders.push(n), i.folderNames.push(n);
                }
            }
            f.bookmarks.push(i);
        }
        return f.folders.sort((a, bb) => {
            return a.localeCompare(bb);
        }), f;
    }
}

export class WebFeed {
    publisher: any;
    publisherId: any;
    bookmarks: Bookmark[];
    folders: any[];
    url: any;
    description: any;
    supportInfoPresent: boolean;
    supportObj: WebFeedSupportObj;
}

export class WebFeedSupportObj {
    logoURL: any;
    supportURL: any;
    supportPhone: any;
    privacyURL: any;
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {ADGroup} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class AdGroupDaoService extends BaseDao<ADGroup>{

  constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
    super(http, validatorDAO, 'ad-group');
  }


    getAllAdGroupsPendingOrDeleting(): Observable<ADGroup[]> {
        return this.http.get<ADGroup[]>(this.url + '/get-all-ad-groups-pending-or-deleting');
    }

    forceDelete(remoteAppId: number): Observable<string> {
        return this.http.remove(this.url + '/force-delete/' + remoteAppId);
    }
}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {ADUserPoolRelationship, ADUserPoolRelationshipLogoff, AmountOfADUserPoolRelationshipByADUserPool, NumberADUsersPoolADDomain} from '../../models';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class AdUserPoolRelationshipDaoService extends BaseDao<ADUserPoolRelationship> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'ad-user-pool-relationship');
    }

    createADUserPoolRelationship(managerUserId: number, subProjectId: number, adDomainId: number): Observable<ADUserPoolRelationship> {
        return this.http.post<ADUserPoolRelationship>(this.url +
            '/create-aduser-pool-relationship?managerUserId=' + managerUserId + '&subProjectId=' + subProjectId + '&adDomainId=' + adDomainId
            , {value: 'true'});
    }

    createADUserPoolRelationshipServiceUser(managerUserIds: number[], subProjectId: number, adDomainId: number): Observable<ADUserPoolRelationship> {
        return this.http.post<ADUserPoolRelationship>(this.url +
            '/create-aduser-pool-relationship-service-user?subProjectId=' + subProjectId + '&adDomainId=' + adDomainId
            , managerUserIds);
    }

    saveADUserPoolRelationshipServiceUser(managerUserIds: number[], adUserPoolRelationship: ADUserPoolRelationship): Observable<ADUserPoolRelationship> {
        return this.http.post<ADUserPoolRelationship>(this.url +
            '/save-aduser-pool-relationship-service-user?managerUserIds=' + managerUserIds
            , adUserPoolRelationship);
    }


    existsADUserPoolRelationship(managerUserId: number, adDomainId: number, subProjectId: number): Observable<boolean> {
        return this.http.get<boolean>(this.url +
            '/exists-aduser-pool-relationship?managerUserId=' + managerUserId + '&subProjectId=' + subProjectId + '&adDomainId=' + adDomainId);
    }

    amountOfAdUserPoolRelationship(adUserPoolId: number): Observable<number> {
        return this.http.get<number>(this.url +
            '/amount-of-ad-user-pool-relationship/' + adUserPoolId);
    }

    listOfNumberOfUsersPoolUsedPerADDomainOfThisSubProjectIdByUserType(subProjectId: number, serviceUser: boolean): Observable<NumberADUsersPoolADDomain[]> {
        return this.http.get<NumberADUsersPoolADDomain[]>(this.url +
            '/list-of-number-of-users-pool-used-per-ad-domain-of-this-sub-project-id-by-user-type?subProjectId=' + subProjectId + '&serviceUser=' + serviceUser);
    }

    saveADUserPoolRelationshipGroups(adUserPoolRelationship: ADUserPoolRelationship): Observable<ADUserPoolRelationship> {
        return this.http.post<ADUserPoolRelationship>(this.url + '/save-aduser-pool-relationship-groups', adUserPoolRelationship);
    }

    findAllMyADUserPoolRelationshipLogoff(): Observable<ADUserPoolRelationshipLogoff[]> {
        return this.http.get<ADUserPoolRelationshipLogoff[]>(this.url + '/find-all-my-aduser-pool-relationship-logoff');
    }

    findAmountOfADUserPoolRelationshipForEachADUserPoolByADDomainId(adDomainId: number): Observable<AmountOfADUserPoolRelationshipByADUserPool[]> {
        return this.http.get<AmountOfADUserPoolRelationshipByADUserPool[]>(
            this.url + '/find-amount-of-ad-user-pool-relationship-for-each-ad-user-pool-by-ad-domain-id?adDomainId=' + adDomainId);
    }

    findAllBySubProjectId(subProjectId: number): Observable<ADUserPoolRelationship[]> {
        return this.http.get<ADUserPoolRelationship[]>(this.url + '/find-all-by-sub-project-id/' + subProjectId);
    }


}

<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Detalhes do Usuário (STOMP)</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>

        <table>
            <tr>
                <td style="width: 160px;">

                    <img src="{{imageFileService.getManagerUserPicture(managerUserOnline)}}?w=150&square=true"
                         width="150"
                         height="150">
                </td>
                <td>

                    <h2>{{managerUserOnline.displayName}}</h2>
                    <h3>{{managerUserOnline.email}}</h3>

                </td>
            </tr>
        </table>




            <div *ngFor="let clientSession of managerUserOnline.clientSessions">
                <hr>
                <div><b>clientId</b></div>
                <div>{{clientSession.clientId}}</div>

                <div class="mt-10"><b>clientType</b></div>
                <div>{{clientSession.clientType}}</div>

                <div class="mt-10"><b>sessionId</b></div>
                <div>{{clientSession.sessionId}}</div>

                <div class="mt-10"><b>startTime</b></div>
                <div>{{clientSession.startTime | date}}</div>

                <div class="mt-10"><b>userName</b></div>
                <div>{{clientSession.userName}}</div>

                <div class="mt-10" style="clear: both" ></div>

<!--                <div *ngFor="let subscription of clientSession.subscriptions">-->
<!--                    <hr>-->
<!--                    <div class="mt-10"><b>subscription</b></div>-->
<!--                    <div>{{subscription.destination}}</div>-->

<!--                    <div class="mt-10"><b>subscriptionId</b></div>-->
<!--                    <div>{{subscription.subscriptionId}}</div>-->

<!--                    <div class="mt-10">-->
<!--                        <button type="button" mat-raised-button (click)="openMessageModal(clientSession.userName, subscription.destination)"><mat-icon>email</mat-icon></button>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

        <hr>

        <div *ngFor="let webSession of managerUserOnline.webSessions">
            <hr>
            <div><b>clientId</b></div>
            <div>{{webSession.clientId}}</div>

            <div class="mt-10"><b>clientType</b></div>
            <div>{{webSession.clientType}}</div>

            <div class="mt-10"><b>sessionId</b></div>
            <div>{{webSession.sessionId}}</div>

            <div class="mt-10"><b>startTime</b></div>
            <div>{{webSession.startTime | date}}</div>

            <div class="mt-10"><b>userName</b></div>
            <div>{{webSession.userName}}</div>

            <div class="mt-10" style="clear: both" ></div>
<!--            <div *ngFor="let subscription of webSession.subscriptions">-->
<!--                <hr>-->
<!--                <div class="mt-10" ><b>subscription</b></div>-->
<!--                <div>{{subscription.destination}}</div>-->

<!--                <div class="mt-10"><b>subscriptionId</b></div>-->
<!--                <div>{{subscription.subscriptionId}}</div>-->

<!--                <div class="mt-10">-->
<!--                    <button type="button" mat-raised-button (click)="openMessageModal(webSession.userName, subscription.destination)"><mat-icon>email</mat-icon></button>-->
<!--                </div>-->
<!--            </div>-->
        </div>

    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <div class="mb-20">
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</div>

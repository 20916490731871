<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column" style="max-width: 950px;">
            <div class="row" *appIfPermission="canEditList; else cannotAccess">
                <!--<div class="row" *appIfPermission="canEditList; and: true ; else cannotAccess">-->
            <!--<div class="row" *appIfPermission="'CREATE'; type: 'ManagerUserAccessPeriodProfile'; parentType: 'CustomerGroup'; parent: customerGroupService.get(); else cannotAccess">-->
            <!--<div class="row" *appIfPermission="'WRITE'; type: 'CustomerGroup'; data: customerGroupService.get(); else cannotAccess">-->
                <app-register-customer-group></app-register-customer-group>
                <app-register-customer></app-register-customer>
                <app-register-manager-user *ngIf="adminService.customers && adminService.customers.length > 0"></app-register-manager-user>
                <app-register-manager-user-access-period></app-register-manager-user-access-period>
            </div>
            <ng-template #cannotAccess>
                <div class="row">
                    <h2>
                        Sem permissão
                    </h2>
                </div>
            </ng-template>
        </div>
    </div>
</div>
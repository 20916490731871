import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomerGroup, DesktopServer, HipcomBulkUpdateDetail, HipcomBulkUpdateDetailLog} from '../../../../../models';
import {HipcomBulkUpdateDetailLogDaoService} from './hipcom-bulk-update-detail-log-dao.service';
import {ImageFileService} from '../../../../image-file/image-file-service';
import {DesktopServerDaoService} from '../../../../desktop-server/desktop-server-dao.service';

@Component({
    selector: 'app-hipcom-bulk-update-detail-log',
    templateUrl: './hipcom-bulk-update-detail-log.component.html',
    styleUrls: ['./hipcom-bulk-update-detail-log.component.scss']
})
export class HipcomBulkUpdateDetailLogComponent {

    hipcomBulkUpdateDetail: HipcomBulkUpdateDetail;
    customerGroups: CustomerGroup[];
    logs:  HipcomBulkUpdateDetailLog[];
    desktopServer:  DesktopServer;

    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdateDetailLogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private imageFileService: ImageFileService,
                private hipcomBulkUpdateDetailLogDaoService: HipcomBulkUpdateDetailLogDaoService,
                private desktopServerDaoService: DesktopServerDaoService
                ) {
        this.hipcomBulkUpdateDetail = this.data.hipcomBulkUpdateDetail;
        this.customerGroups = this.data.customerGroups;
        if (!this.hipcomBulkUpdateDetail || !this.customerGroups) {
            this.dialogRef.close();
        }


        this.hipcomBulkUpdateDetailLogDaoService.findByDesktopServerId(this.hipcomBulkUpdateDetail.desktopServerId).subscribe((logs) => {
            this.logs = logs;
        });

        this.desktopServerDaoService.getOne(this.hipcomBulkUpdateDetail.desktopServerId).subscribe((desktopServer) => {
            this.desktopServer = desktopServer;
        });

    }
    onCancel(): void {
        this.dialogRef.close();
    }
}

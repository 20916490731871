import {AsyncValidatorFn} from '@angular/forms';
import {R2CloudHttpBase} from './r2-cloud-http.base';
import {KluhManagerValidator} from './validators/kluh-manager-validator';
import {Observable} from 'rxjs';

export abstract class BaseIAMDao<T> {

    protected url: string;
    validator: AsyncValidatorFn;
    protected constructor(protected http: R2CloudHttpBase, protected validatorDAO: KluhManagerValidator, url: string){
        this.url = url;
        this.validator = this.validatorDAO.validator(this.url, this.http);
    }

    create(entity: T): Observable<T> {
        return this.http.post<T>(this.url, entity);
    }

    delete(entity: T): Observable<void> {
        return this.http.delete(this.url, entity);
    }

    getByParentId(id: number): Observable<T[]> {
        return this.http.get<T[]>(this.url + '/parent/' + id);
    }

    getByMemberId(id: number): Observable<T[]> {
        return this.http.get<T[]>(this.url + '/member/' + id);
    }


    getByRoleId(id: number): Observable<T[]> {
        return this.http.get<T[]>(this.url + '/role/' + id);
    }

    validate(value: T): Observable<any[]> {
        return this.http.put<any[]>(this.url + '/validate', value);
    }


    findAllByParentIdAndMemberId(parentId: number, memberId: number): Observable<T[]> {
        return this.http.get<T[]>
        (this.url + '/find-all-by-parent-id-and-member-id?parentId=' + parentId + '&memberId=' + memberId);
    }




}

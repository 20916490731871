import {FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';

@Injectable()
export class ShowAlwaysErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | FormGroup | any, form: FormGroupDirective | NgForm | any): boolean {
    if (control.touched) {
      if (control.invalid) {
        return true;
      }
      if (control.parent) {
        const formGroup = <FormGroup> control.parent;
        if (formGroup.status === 'INVALID') {
          if (formGroup.errors) {
            let controlKey = null;
            for (const key in formGroup.errors) {
              if (formGroup.errors.hasOwnProperty(key)) {
                const myControl = formGroup.get(key);
                if (myControl === control) {
                  controlKey = key;
                  break;
                }
              }
            }
            if (controlKey) {
              if (control.status !== 'INVALID') {
                const errors: ValidationErrors = {};
                errors[controlKey] = formGroup.errors[controlKey];
                control.setErrors(errors);
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }
}

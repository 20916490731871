<div class="dialog-content-wrapper">
    <form [formGroup]="myForm"
          id="marketplace-product-comment-edit-form"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Editar comentário</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>

            <div fxLayout="row" fxLayoutAlign="start start" class="reply  mt-50 width-100-percent mb-60" >
                <img class="avatar" src="{{imageFileService.getManagerUserPictureUrl(user.managerUser.imageUUID)}}?w=40&square=true" />
                <div fxLayout="column" fxLayoutAlign="start start" class="width-100-percent">
                    <textarea placeholder="coloque seu comentário..."
                              id="marketplace-product-comment-edit-comment"
                              formControlName="comment" rows="6"></textarea>
                    <div>
                        <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="mb-10 mt-10">
                            <div class="product-detail-rating rating-select">
                                <mat-icon class="star1"
                                          id="marketplace-product-comment-edit-star1"
                                          (mouseover)="marketplaceService.ratingSelect(1,5, elRef)"
                                          (mouseout)="marketplaceService.ratingUnSelect(1,5, elRef)"
                                          (click)="myForm.markAsDirty();onRatingSelected(1,5)">
                                    star
                                </mat-icon>
                                <mat-icon class="star2"
                                          id="marketplace-product-comment-edit-star2"
                                          (mouseover)="marketplaceService.ratingSelect(2,5, elRef)"
                                          (mouseout)="marketplaceService.ratingUnSelect(2,5, elRef)"
                                          (click)="myForm.markAsDirty();myForm.markAsDirty();onRatingSelected(2,5)">
                                    star
                                </mat-icon>
                                <mat-icon class="star3"
                                          id="marketplace-product-comment-edit-star3"
                                          (mouseover)="marketplaceService.ratingSelect(3,5, elRef)"
                                          (mouseout)="marketplaceService.ratingUnSelect(3,5, elRef)"
                                          (click)="myForm.markAsDirty();onRatingSelected(3,5)">
                                    star
                                </mat-icon>
                                <mat-icon class="star4"
                                          id="marketplace-product-comment-edit-star4"
                                          (mouseover)="marketplaceService.ratingSelect(4,5, elRef)"
                                          (mouseout)="marketplaceService.ratingUnSelect(4,5, elRef)"
                                          (click)="myForm.markAsDirty();onRatingSelected(4,5)">
                                    star
                                </mat-icon>
                                <mat-icon class="star5"
                                          id="marketplace-product-comment-edit-star5"
                                          (mouseover)="marketplaceService.ratingSelect(5,5, elRef)"
                                          (mouseout)="marketplaceService.ratingUnSelect(5,5, elRef)"
                                          (click)="myForm.markAsDirty();onRatingSelected(5,5)">
                                    star
                                </mat-icon>
                            </div>
                            <div class="font-size-12 mr-5"> Nota</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>

            <button type="submit" mat-raised-button color="accent"
                    id="marketplace-product-comment-edit-save"
                    [disabled]="(!myForm.valid || myForm.pristine) || (commentRating < 1)"
                    aria-label="Salvar">
                <span>Salvar</span>
            </button>


            <button type="button" mat-raised-button color="primary"
                    id="marketplace-product-comment-edit-cancel"
                    (click)="onCancel()">Cancelar</button>

        </div>
    </form>
</div>

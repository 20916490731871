import {AfterViewInit, Component, Input} from '@angular/core';
import {NetworkConfiguration} from '../../../../../models-monitoring';
import {MatTableDataSource} from '@angular/material/table';
import {MonitoringDetailsService} from '../../monitoring-details.service';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-network-configuration',
    templateUrl: './network-configuration.component.html',
    styleUrls: ['./network-configuration.component.scss']
})
export class NetworkConfigurationComponent implements AfterViewInit {

    @Input()
    networks: NetworkConfiguration[];

    networkConfigurationTable: string[] = ['name', 'speed', 'ipV4', 'macAddress', 'description', 'status', 'actions'];
    networkConfigurationDataSource = new MatTableDataSource<NetworkConfiguration>();

    constructor(
        private monitoringDetailsService: MonitoringDetailsService,
        private dialog: MatDialog
    ) {
        this.monitoringDetailsService.changePath('monitoring-details', 'network');
    }

    ngAfterViewInit(): void {
        this.networkConfigurationDataSource = new MatTableDataSource(this.networks);
    }

    openDetails(element: NetworkConfiguration): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<table class="w-500">' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">Nome</td>' +
                    '<td>' + element.name + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">Velocidade</td>' +
                    '<td>' + element.speed + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">MAC Address</td>' +
                    '<td>' + element.macAddress + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">Descrição</td>' +
                    '<td>' + element.description + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">status</td>' +
                    '<td>' + element.status + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">ipv6</td>' +
                    '<td>' + element.ipv6 + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">ipV4</td>' +
                    '<td>' + element.ipV4 + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">ipV4Mask</td>' +
                    '<td>' + element.ipV4Mask + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">DHCP</td>' +
                    '<td>' + element.dhcp + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">DHCP Server</td>' +
                    '<td>' + element.dhcpServer + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">Gateway</td>' +
                    '<td>' + element.gateway + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">DNS Primário</td>' +
                    '<td>' + element.primaryDns + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">DNS Secundário</td>' +
                    '<td>' + element.secondaryDns + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">interface Type</td>' +
                    '<td>' + element.interfaceType + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="w-210 secondary-text">Apareceu em</td>' +
                    '<td>' + element.createdAt + '</td>' +
                    '</tr>' +
                    '</table>' +
                    '<div class="mt-50"><hr/><h3>Outros detalhes</h3>' + element.comment +
                    '</div>',
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
        });
    }
}

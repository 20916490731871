<div class="dialog-content-wrapper">
    <form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de fornecedor de Software</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch"
             style="padding: 10px;">
            <table>
                <tr>
                    <td class="vertical-align-top w-150" >
                        <div>
                            <img src="{{imageFileService.getSoftwareCompanyPictureUrl(softwareCompany.imageUUID, 150)}}"
                                 width="150"
                                 height="150"
                                 *ngIf="!imageFileElement">
                            <img [src]="'data:image/jpg;base64,' + imageFileElement" width="150" height="150"
                                 *ngIf="imageFileElement">
                        </div>
                        <button style="width: 150px;margin-top: 5px;" type="button" mat-raised-button
                                class="green-800-bg green-800"
                                onclick="document.getElementById('fileToUploadSoftwareCompany').click()">
                            Alterar Foto
                        </button>
                        <input (change)="onImageFileChange($event)" name="file"
                               id="fileToUploadSoftwareCompany" accept="image/*" type="file" style="display:none;">

                    </td>
                    <td class="pl-20">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Nome da empresa ou software" name="name" required formControlName="name">
                            <mat-error>
                                {{myForm.get('name').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Telefone" mask="(00)-00000000000" [validation]="false" name="phone" formControlName="phone">
                            <mat-error>
                                {{myForm.get('phone').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="CNPJ" name="cnpj" mask="00.000.000/0000-00" [validation]="false"  formControlName="cnpj">
                            <mat-error>
                                {{myForm.get('cnpj').errors | error}}
                            </mat-error>

                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <mat-select placeholder="Estado" formControlName="province">
                                <mat-option *ngFor="let province of provinceList" [value]="province.key">
                                    {{province.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{myForm.get('province').errors | error}}
                            </mat-error>

                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Cidade" name="city" formControlName="city">
                            <mat-error>
                                {{myForm.get('city').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Bairro" name="neighborhood" formControlName="neighborhood">
                            <mat-error>
                                {{myForm.get('neighborhood').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="Endereço" name="address" formControlName="address">
                            <mat-error>
                                {{myForm.get('address').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Número" name="addressNumber" mask="00000000000" [validation]="false"  formControlName="addressNumber">
                            <mat-error>
                                {{myForm.get('addressNumber').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="Complemento" name="addressAddOn" formControlName="addressAddOn">
                            <mat-error>
                                {{myForm.get('addressAddOn').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="CEP"  mask="00000-000"  name="zipCode" formControlName="zipCode">
                            <mat-error>
                                {{myForm.get('zipCode').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-slide-toggle formControlName="active" name="active">
                            Ativo
                        </mat-slide-toggle>

                        <div *ngIf="errorMessage" class="mt-10 font-size-16 warn-A700-fg">
                            {{errorMessage}}
                        </div>
                    </td>
                </tr>
            </table>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>

            <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="softwareCompany.id;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>


            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

        </div>
    </form>
</div>

<div class="dialog-content-wrapper">
    <form [formGroup]="myForm" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Editar credeinciais do administrador local do servidor</span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <mat-form-field appearance="outline" class="mt-25">
                <mat-label>Login</mat-label>
                <input matInput formControlName="login" autocomplete="off">
                <mat-error>
                    {{myForm.get('login').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" autocomplete="off">
                <mat-error>
                    {{myForm.get('password').errors | error}}
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>

            <button type="button" mat-raised-button color="accent" (click)="onSave()">Salvar</button>

            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </form>
</div>

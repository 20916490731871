<mat-card class="mr-8">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Dashboard</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="padding-none">

        <div>
            <mat-card class="dash-card">
                <div
                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{getNumberOfUpdatesScheduled(-1, false)}}</div>
                <div class="h5 secondary-text ng-tns-c206-146">Quantidade de agendamentos de ontem</div>
            </mat-card>

            <mat-card class="dash-card">
                <div
                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{getNumberOfUpdatesScheduled(0, true)}}</div>
                <div class="h5 secondary-text ng-tns-c206-146">Agendados para hoje</div>
            </mat-card>

            <mat-card class="dash-card" [ngClass]="{'error':(getNumberOfError() > 0)}" >
                <div
                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{getNumberOfError()}}</div>
                <div class="h5 secondary-text ng-tns-c206-146" [ngClass]="{'error':(getNumberOfError() > 0)}" >Execuções com erro</div>
            </mat-card>

            <mat-card class="dash-card">
                <div
                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{getNumberOfServers()}}</div>
                <div class="h5 secondary-text ng-tns-c206-146">Total de servidores</div>
            </mat-card>

            <mat-card class="dash-card">
                <div class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">
                    <div
                        *ngIf="hipcomBulkUpdateLatestSoftwareVersions">{{hipcomBulkUpdateLatestSoftwareVersions.length}}</div>
                </div>
                <div class="h5 secondary-text ng-tns-c206-146">Módulos cadastrados</div>
            </mat-card>

            <mat-card class="dash-card">
                <div class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{getTotalNumberOfModules()}}</div>
                <div class="h5 secondary-text ng-tns-c206-146">Total de módulos monitorados</div>
            </mat-card>
        </div>
        <div style="clear: both">

        </div>
    </mat-card-content>
</mat-card>

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../base-dao';
import {MarketplaceProductScreen} from '../../../../models';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';

@Injectable()
export class MarketplaceProductScreenDaoService extends BaseDao<MarketplaceProductScreen> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'marketplace-product-screen');
    }

    findAllByMarketplaceProductId(marketplaceProductId: number): Observable<MarketplaceProductScreen[]> {
        return this.http.get<MarketplaceProductScreen[]>(this.url + '/find-all-by-marketplace-product-id/' + marketplaceProductId);
    }
}

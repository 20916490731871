import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ServerLog} from '../../../models';
import {BaseIdStringDao} from '../../../base-id-string-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {Page} from '../../../helpers/Page';

@Injectable()
export class ServerLogDaoService extends BaseIdStringDao<ServerLog> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'server-log');
    }


    findServerLogByCustomerGroupId(
        customerGroupId: number,
        managerUserId: number,
        from: string,
        to: string,
        currentPage: number,
        pageSize: number
    ): Observable<Page<ServerLog>> {
        return this.http.get<Page<ServerLog>>(
            this.url + '/list?customerGroupId=' + customerGroupId + '&managerUserId=' + managerUserId + '&from=' + from + '&to=' + to + '&currentPage=' +
            currentPage + '&pageSize=' + pageSize
        );
    }

}

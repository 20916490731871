/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.19.577 on 2021-04-09 12:57:04.

export interface Metric {
    value: number;
    createdAt: Date;
}

export interface HdMonitoringConfiguration {
    id: number | null;
    comment: string | null;
    active: boolean | null;
    driveName: string | null;
    serverId: number | null;
    serverType: ServerType | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface HdUsage {
    value: number;
    metricId: number;
    metricType: MetricType;
    createdAt: Date | null;
}

export interface MemoryUsage {
    value: number;
    metricId: number;
    metricType: MetricType;
    createdAt: Date | null;
}

export interface NetworkConfiguration {
    id: number | null;
    name: string | null;
    comment: string | null;
    active: boolean | null;
    serverId: number | null;
    serverType: ServerType | null;
    speed: number | null;
    macAddress: string | null;
    description: string | null;
    status: string | null;
    ipv6: string | null;
    ipV4: string | null;
    ipV4Mask: string | null;
    dhcp: boolean | null;
    dhcpServer: string | null;
    gateway: string | null;
    primaryDns: string | null;
    secondaryDns: string | null;
    interfaceType: string | null;
    createdAt: Date | null;
    modified: number | null,
    optlock: number | null
}

export interface NetworkUsage {
    value: number;
    metricId: number;
    metricType: MetricType;
    createdAt: Date | null;
}

export interface ProcessDetails extends BaseDataTable {
    processId: number | null;
    path: string | null;
    username: string | null;
    responding: string | null;
    processorUsage: number | null;
    memoryUsage: number | null;
    memoryUsagePercentage: number | null;
    totalMemory: number | null;
}

export interface ProcessorUsage {
    value: number;
    metricId: number;
    metricType: MetricType;
    createdAt: Date | null;
}

export interface ServerOverview {
    cpu: number | null;
    memory: number | null;
    hdList: HdUsageMon[] | null;
    createdAt: Date | null;
}

export interface ServerUptime {
    value: number;
    metricId: number;
    metricType: MetricType;
    createdAt: Date | null;
}

export interface UserUsage {
    id: number | null;
    adUser: string | null;
    adDomain: string | null;
    clientId: string | null;
    processorUsage: number | null;
    memoryUsage: number | null;
    memoryUsagePercentage: number | null;
    totalMemory: number | null;
    toRemove: boolean | null;
    createdAt: Date | null;
    firstSeenAt: Date | null;
}

export interface VpnMonitoringConfiguration {
    id: number | null;
    comment: string | null;
    active: boolean | null;
    serverId: number | null;
    serverType: ServerType | null;
    name: string | null;
    clientVpnIp: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    modified: number | null;
    optlock: number | null;
}

export interface VpnMonitoringStatus {
    id: number | null;
    comment: string | null;
    active: boolean | null;
    onlineSince: Date | null;
    offlineSince: Date | null;
    vpnMonitoringConfigurationId: number | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    modified: number | null;
    optlock: number | null;
}

export interface VpnUsage {
    value: number;
    metricId: number;
    metricType: MetricType;
    createdAt: Date | null;
}

export interface WindowsService extends BaseDataTable {
    displayName: string | null;
    pathName: string | null;
    startName: string | null;
    description: string | null;
    startMode: string | null;
    state: ServiceState | null;
}

export interface BaseDataTable {
    id: number | null;
    name: string | null;
    unique: string | null;
    toRemove: boolean | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    firstSeenAt: Date | null;
}

export interface Page {
    index: number;
    size: number;
}

export interface TableCriteria {
    fields: TableFieldSearch[];
    page: Page;
    order: TableOrder | null;
}

export interface TableFieldSearch {
    field: string;
    value: string;
    fieldType: TableFieldType;
    searchType: TableFieldSearchType;
    criteria: TableFieldSearchCriteria;
}

export interface TableOrder {
    field: string;
    order: string;
}

export interface TableResults<T> {
    content: T[];
    total: number;
}

export interface HdUsageMon {
    driveName: string | null;
    value: number | null;
}

export enum TableFieldSearchCriteria {
    MUST = 'MUST',
    FILTER = 'FILTER',
    SHOULD = 'SHOULD',
    MUST_NOT = 'MUST_NOT',
}

export enum TableFieldSearchType {
    WILDCARD = 'WILDCARD',
    TERM = 'TERM',
    MATCH = 'MATCH',
}

export enum TableFieldType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
}

export enum ServerType {
    WINDOWS = 'WINDOWS',
    LINUX = 'LINUX',
}

export enum MetricType {
    WINDOWS_SERVER = 'WINDOWS_SERVER',
    LINUX_SERVER = 'LINUX_SERVER',
    VPN = 'VPN',
    HD = 'HD',
    NETWORK_SENT = 'NETWORK_SENT',
    NETWORK_RECEIVED = 'NETWORK_RECEIVED',
}

export enum ServiceState {
    STOPPED = 'STOPPED',
    START_PENDING = 'START_PENDING',
    STOP_PENDING = 'STOP_PENDING',
    RUNNING = 'RUNNING',
    CONTINUE_PENDING = 'CONTINUE_PENDING',
    PAUSE_PENDING = 'PAUSE_PENDING',
    PAUSED = 'PAUSED',
    UNKNOWN = 'UNKNOWN',
    EXITED = 'EXITED',
}

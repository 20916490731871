import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {CustomerGroup, FirebaseUserDetails, ImageFile, MarketplaceCategory, MarketplaceProduct, SoftwareCompany} from '../../../models';
import {of} from 'rxjs/internal/observable/of';
import {ImageFileService} from '../../image-file/image-file-service';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {MarketplaceService} from '../marketplace.service';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {MatSelect} from '@angular/material/select';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {MarketplaceCategoryDaoService} from '../marketplace-category-create-and-edit/marketplace-category.dao.service';
import {SoftwareCompanyDaoService} from '../software-company-create-and-edit/software-company.dao.service';
import {MarketplaceProductDaoService} from '../marketplace-product/marketplace-product.dao.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ValueWrapper} from '../../../helpers/value-wrapper';

@Component({
    selector: 'app-marketplace-product-create-and-edit',
    templateUrl: './marketplace-product-create-and-edit.component.html',
    styleUrls: ['./marketplace-product-create-and-edit.component.scss']
})
export class MarketplaceProductCreateAndEditComponent implements OnInit {

    myForm: FormGroup;
    marketplaceProduct: MarketplaceProduct;
    marketplaceCategoryList: MarketplaceCategory[] = [];
    imageFileElement: any[] = [null, null];
    private avatarImageFile: ImageFile;
    private bannerImageFile: ImageFile;
    customerGroups: CustomerGroup[];
    softwareCompanyList: SoftwareCompany[] = [];
    user: FirebaseUserDetails = null;

    public softwareCompanyMultiFilterCtrl: FormControl = new FormControl();
    public filteredSoftwareCompanyMulti: ReplaySubject<SoftwareCompany[]> = new ReplaySubject<SoftwareCompany[]>(1);
    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;
    protected _onDestroy = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<MarketplaceProductCreateAndEditComponent>,
                private fb: FormBuilder,
                private customerGroupService: CustomerGroupService,
                private marketplaceService: MarketplaceService,
                public imageFileService: ImageFileService,
                private marketplaceCategoryDaoService: MarketplaceCategoryDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private softwareCompanyDaoService: SoftwareCompanyDaoService,
                private marketplaceProductDaoService: MarketplaceProductDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.marketplaceProduct = data?.marketplaceProduct;
        if (!this.marketplaceProduct) {
            this.marketplaceProduct = this.marketplaceService.initMarketplaceProduct();
        }
        this.myForm = this.fb.group(convertToFormGroup(this.marketplaceProduct));
    }

    ngOnInit(): void {
        this.userAuthorityDaoService.getMe().subscribe((user) => {
            this.user = user;
        });
        this.customerGroups = this.customerGroupService.customerGroups;


        this.softwareCompanyDaoService.get().subscribe(softwareCompanies => {
            if (softwareCompanies) {
                this.softwareCompanyList = softwareCompanies;
                this.filteredSoftwareCompanyMulti.next(this.softwareCompanyList.slice());
                this.softwareCompanyMultiFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterBanksMulti();
                    });
            }
        });


        this.marketplaceCategoryDaoService.get().subscribe(marketplaceCategoryList => {
            if (marketplaceCategoryList) {
                this.marketplaceCategoryList = marketplaceCategoryList;
            }
        });

    }

    protected filterBanksMulti(): void {
        if (!this.customerGroups) {
            return;
        }
        let search = this.softwareCompanyMultiFilterCtrl.value;
        if (!search) {
            this.filteredSoftwareCompanyMulti.next(this.softwareCompanyList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSoftwareCompanyMulti.next(
            this.softwareCompanyList.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
        );
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }


    onImageFileChange(object: Event, imagePos: number): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement[imagePos] = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.myForm);
        }
    }

    onSubmit(): void {
        const marketPlaceProduct: MarketplaceProduct = this.myForm.value;
        marketPlaceProduct.managerUserId = this.user.managerUser.id;
        let marketPlaceProduct$: Observable<MarketplaceProduct>;
        let operation: CrudOperation = 'CREATE';
        if (marketPlaceProduct.id) {
            operation = 'SAVE';
            marketPlaceProduct$ = this.marketplaceProductDaoService.save(marketPlaceProduct);
        } else {
            marketPlaceProduct$ = this.marketplaceProductDaoService.create(marketPlaceProduct);
        }

        marketPlaceProduct$.pipe(switchMap((marketplaceProductFromDAO) => {
            const imageObservables: Observable<any>[] = [];

            const avatarImageFile = this.imageFileElement[0];
            let avatarImageFile$: Observable<ValueWrapper>;
            if (avatarImageFile) {
                avatarImageFile$ = this.imageFileService.saveMarketPlaceProductAvatarPicture(marketplaceProductFromDAO.id, avatarImageFile);
            } else {
                avatarImageFile$ = of(null);
            }


            const bannerImageFile = this.imageFileElement[1];
            let bannerImageFile$: Observable<ValueWrapper>;
            if (bannerImageFile) {
                bannerImageFile$ = this.imageFileService.saveMarketPlaceProductBannerPicture(marketplaceProductFromDAO.id, bannerImageFile);
            } else {
                bannerImageFile$ = of(null);
            }


            return forkJoin([avatarImageFile$, bannerImageFile$, of(marketplaceProductFromDAO)]);
        })).subscribe(result => {
            const marketplaceProductFromDAO: MarketplaceProduct = result[2];
            if (this.imageFileElement[0]) {
                marketplaceProductFromDAO.avatarUUID = result[0].value;
            }
            if (this.imageFileElement[1]) {
                marketplaceProductFromDAO.bannerUUID = result[1].value;
            }
            const crudOperation: CrudOperationWrapper = {
                data: marketplaceProductFromDAO,
                operation: operation
            };
            this.dialogRef.close(crudOperation);
        });

    }

}
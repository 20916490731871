import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomerDaoService} from '../customer-dao.service';

import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {Observable, of} from 'rxjs';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComponentCleaner} from '../../../component-cleaner';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched, Province} from '../../../helpers/kluh';
import {Customer, CustomerGroup, ImageFile} from '../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {ProvinceService} from '../../../helpers/province.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {switchMap} from 'rxjs/operators';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ValueWrapper} from '../../../helpers/value-wrapper';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-customer-create-edit',
    templateUrl: './customer-create-edit.component.html',
    styleUrls: ['./customer-create-edit.component.scss'],
    animations: fuseAnimations
})
export class CustomerCreateEditComponent extends ComponentCleaner {

    customerForm: FormGroup;
    customer: Customer;
    customerGroups: CustomerGroup[];
    imageFile: ImageFile;
    imageFileElement: any = null;
    errorMessage: string = null;
    provinceList: Province[];

    constructor(public dialogRef: MatDialogRef<CustomerCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                public adminService: R2CloudAdminService,
                public imageFileService: ImageFileService,
                private customerGroupService: CustomerGroupService,
                private managerUserDaoService: ManagerUserDaoService,
                private fb: FormBuilder,
                private provinceService: ProvinceService,
                private customerDaoService: CustomerDaoService) {
        super();
        this.provinceList = this.provinceService.provinceList;
        this.customer = data.customer;
        if (!this.customer) {
            this.customer = {
                id: null,
                active: true,
                comment: null,
                customerGroupId: null,
                name: null,
                imageFileId: null,
                cnpj: null,
                province: null,
                city: null,
                neighborhood: null,
                address: null,
                addressAddOn: null,
                addressNumber: null,
                zipCode: null,
                phone: null,
                uuid: null,
                modified: null,
                optlock: null,
                imageUUID: null,
                blockAllAccess: null,
                contractedUsers: null
            };
        }
        this.customer.customerGroupId = this.customerGroupService.customerGroup.id;
        this.setCustomerForm(this.customer);
    }

    private setCustomerForm(customer: Customer): void {
        this.customerForm = this.fb.group(convertToFormGroup(customer), {asyncValidator: this.customerDaoService.validator});
        this.customerForm.get('name').setValidators([Validators.required]);
        // this.customerForm.get('contractedUsers').setValidators([Validators.min(0)]);
    }

    onSubmit(): void {
        const customer: Customer = this.customerForm.value;
        let customer$: Observable<Customer>;
        let operation: CrudOperation;
        if (customer.contractedUsers && customer.contractedUsers === 0) {
            customer.contractedUsers = null;
        }
        if (customer.id) {
            customer$ = this.customerDaoService.save(customer);
            operation = 'SAVE';
        } else {
            customer$ = this.customerDaoService.create(customer);
            operation = 'CREATE';
        }
        customer$.pipe(switchMap((result) => {
            let imageFile$: Observable<ValueWrapper>;
            const image = this.imageFileElement;
            if (image) {
                imageFile$ = this.imageFileService.saveCustomerPicture(result.id, image);
            } else {
                imageFile$ = of(null);
            }

            return forkJoin([imageFile$, of(result)]);
        })).subscribe((result) => {
            const customerDAO = result[1];
            if (this.imageFileElement) {
                customerDAO.imageUUID = result[0].value;
            }
            const crudOperation: CrudOperationWrapper = {
                data: customerDAO,
                operation: operation
            };
            this.dialogRef.close(crudOperation);
        });
    }

    onCancel(): void {
        const crudOperation: CrudOperationWrapper = {
            operation: 'CANCEL',
            data: null
        };
        this.dialogRef.close(crudOperation);
    }

    onRemove(): void {
        this.errorMessage = '';
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Deseja remover a filial <b>' + this.customerForm.get('name').value + '</b>?'
            }
        }).afterClosed().subscribe(result => {
            if (result) {
                this.managerUserDaoService.filter({customerId: this.customer.id}).subscribe((managerUsersInCustomer) => {
                    if (managerUsersInCustomer && managerUsersInCustomer.length > 0) {
                        this.errorMessage = 'Existem usuários cadastrado nessa Filial, remova todos os usuários antes de excluir a filial.';
                    } else {
                        this.customerDaoService.remove(this.customer.id).subscribe(() => {
                            const crudOperation: CrudOperationWrapper = {
                                operation: 'DELETE',
                                data: this.customer.id
                            };
                            this.dialogRef.close(crudOperation);
                        });
                    }
                });
            }
        });

        this.addSubscription(subscription);
    }


    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.customerForm);
        }
    }

    onRemoveImage(): void {
        this.imageFileElement = null;
        this.customer.imageUUID = null;
        this.customerForm.get('imageUUID').setValue(null);
        markAsTouched(this.customerForm);
    }


    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.customer.id, this.customerDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.customer.id, this.customerDaoService, this.dialog);
    }

}

<!--<div>-->
    <!--<div *ngFor="let role of roles">-->
        <!--{{role.name}}-->
    <!--</div>-->
<!--</div>-->

<div fusePerfectScrollbar class="page-layout simple fullwidth">
    <div class="content p-24">
        <div fxLayout="column">
            <mat-card>
                <mat-card-header>
                    <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                        <div> Permissões de grupos e usuários</div>
                        <div>
                            <button type="button" mat-raised-button color="accent">Criar</button>
                        </div>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <table cellspacing="0" cellpadding="0" class="border_top">
                        <thead>
                            <tr class="no-border">
                                <th class="min-width-250">Nome</th>
                                <th class="min-width-250" style="padding-left: 24px;">Permissões</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>
                                <div class="name">
                                    Desktop
                                </div>
                                <button type="button" mat-raised-button color="accent" >Edit</button>
                            </td>
                            <td>
                                <ul class="permissions">

                                    <li>BaseServerService.WRITE</li>
                                    <li>BaseServerService.READ</li>
                                    <li>ADDomain.WRITE</li>
                                    <li>ADDomain.READ</li>
                                    <li>Project.WRITE</li>
                                    <li>Project.READ</li>
                                    <li>SubProject.WRITE</li>
                                    <li>SubProject.READ</li>
                                </ul>
                                <a class="show-more">mostrar mais (15)</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="name">
                                    Desktop
                                </div>
                                <button type="button" mat-raised-button color="accent" >Edit</button>
                                <button type="button" mat-raised-button color="accent" >Edit</button>
                            </td>
                            <td>
                                <ul class="permissions">

                                    <li>BaseServerService.WRITE</li>
                                    <li>BaseServerService.READ</li>
                                    <li>ADDomain.WRITE</li>
                                    <li>ADDomain.READ</li>
                                    <li>Project.WRITE</li>
                                    <li>Project.READ</li>
                                    <li>SubProject.WRITE</li>
                                    <li>SubProject.READ</li>
                                </ul>
                                <a class="show-more">mostrar mais (15)</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="name">
                                    Desktop
                                </div>
                                <button type="button" mat-raised-button color="accent" >Edit</button>
                            </td>
                            <td>
                                <ul class="permissions">

                                    <li>BaseServerService.WRITE</li>
                                    <li>BaseServerService.READ</li>
                                    <li>ADDomain.WRITE</li>
                                    <li>ADDomain.READ</li>
                                    <li>Project.WRITE</li>
                                    <li>Project.READ</li>
                                    <li>SubProject.WRITE</li>
                                    <li>SubProject.READ</li>
                                </ul>
                                <a class="show-more">mostrar mais (15)</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="name">
                                    Desktop
                                </div>
                                <button type="button" mat-raised-button color="accent" >Edit</button>
                            </td>
                            <td>
                                <ul class="permissions">

                                    <li>BaseServerService.WRITE</li>
                                    <li>BaseServerService.READ</li>
                                    <li>ADDomain.WRITE</li>
                                    <li>ADDomain.READ</li>
                                    <li>Project.WRITE</li>
                                    <li>Project.READ</li>
                                    <li>SubProject.WRITE</li>
                                    <li>SubProject.READ</li>
                                </ul>
                                <a class="show-more">mostrar mais (15)</a>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>



        </div>
    </div>
</div>

<div>
    <div *ngFor="let permission of permissions">
        {{permission.name}}
    </div>
</div>
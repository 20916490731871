<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row">
                <mat-card>
                    <mat-card-header style="height: 110px;">
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>Meus arquivos</div>
                        </mat-card-title>
                        <mat-card-subtitle>
                            Para aparecer seus arquivos na lista, basta salvar na pasta (Documents\R2Cloud-Download) no
                            servidor em nuvem.<br>
                            <small>* Atenção, os arquivos ficarão armazenados por 15 dias.</small>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            <!-- Position Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                                <td mat-cell *matCellDef="let element" class="icon">
                                    <img *ngIf="element.name" src="{{imageFileService.getIconOfExtension(element.name)}}" width="60" class="p-5">
                                </td>
                            </ng-container>

                            <!-- Position Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef><div class="pl-15">Nome</div></th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="text-max-400" title="{{element.name}}">{{element.name}}</div>
                                </td>
                            </ng-container>


                            <!-- Name Column -->
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef>Data de criação</th>
                                <td mat-cell *matCellDef="let element" class="w-190 padding-none">
                                    <div *ngIf="element.createdAt">
                                        {{element.createdAt | date: 'dd/MM/yyyy HH:mm - EEE'}}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" class="text-align-center w-150 no-padding">
                                    <div>
                                        <button type="button" class="mr-10" mat-raised-button color="primary"
                                                (click)="openDoc(element)">Baixar
                                        </button>
                                        <button mat-mini-fab color="warn" aria-label="delete"
                                                (click)="deleteDoc(element)">
                                            <mat-icon>delete_forever</mat-icon>
                                        </button>
                                    </div>

                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="totalDocs"></mat-paginator>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>

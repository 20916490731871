import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../../../image-file/image-file-service';
import {MarketplaceService} from '../../../marketplace.service';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {markAsTouched} from '../../../../../helpers/kluh';
import {FirebaseUserDetails, MarketplaceProduct, MarketplaceProductScreen} from '../../../../../models';
import {MarketplaceProductScreenDaoService} from '../marketplace-product-screen.dao.service';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {UserAuthorityDaoService} from 'app/kluh-manager/main/user-authority/user-authority-dao.service';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-marketplace-product-detail-screen-edit',
    templateUrl: './marketplace-product-detail-screen-edit.component.html',
    styleUrls: ['./marketplace-product-detail-screen-edit.component.scss'],
    queries: {
        'contentRef': new ViewChild('contentRef', {static: false})
    }
})
export class MarketplaceProductDetailScreenEditComponent extends ComponentCleaner implements OnInit {

    myForm: FormGroup;
    myFormArray: FormArray;
    myFormControls: FormGroup;
    contentRef!: ElementRef;
    marketplaceProduct: MarketplaceProduct;
    imageFileElement: any[] = [];
    user: FirebaseUserDetails = null;

    constructor(public dialogRef: MatDialogRef<MarketplaceProductDetailScreenEditComponent>,
                private fb: FormBuilder,
                public imageFileService: ImageFileService,
                private marketplaceProductScreenDaoService: MarketplaceProductScreenDaoService,
                private marketplaceService: MarketplaceService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                // private imageFileDaoService: ImageFileDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog) {
        super();
        this.userAuthorityDaoService.getMe().subscribe((user) => {
            this.user = user;
            this.myFormArray = this.fb.array([]);
            this.myForm = this.fb.group({myFormArray: this.myFormArray});
            this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;

            this.marketplaceProduct = data?.marketplaceProduct;
            if (!this.marketplaceProduct) {
                this.marketplaceProduct = this.marketplaceService.initMarketplaceProduct();
            }

            this.marketplaceProductScreenDaoService.findAllByMarketplaceProductId(this.marketplaceProduct.id).subscribe(marketplaceProductScreenList => {
                if (marketplaceProductScreenList) {
                    for (let i = 0; i < marketplaceProductScreenList.length; i++) {
                        this.onAddProductScreen(marketplaceProductScreenList[i]);
                    }
                    if (this.myFormArray.length < 1) {
                        this.onAddProductScreen();
                    }
                }
            });
        });
    }

    onAddScreen(marketPlaceProductScreen?: MarketplaceProductScreen): void {
        this.myFormArray.push(this.buildScreen(marketPlaceProductScreen));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }

    buildScreen(marketPlaceProductScreen?: MarketplaceProductScreen): FormGroup {
        let productScreen: MarketplaceProductScreen = {
            id: null,
            active: true,
            comment: null,
            managerUserId: this.user.managerUser.id,
            marketplaceProductId: this.marketplaceProduct.id,
            imageFileId: null,
            description: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null,
            imageUUID: null
        };
        if (marketPlaceProductScreen) {
            productScreen = marketPlaceProductScreen;
        }
        return this.fb.group(productScreen);
    }

    onAddProductScreen(productScreen?: MarketplaceProductScreen): void {
        this.myFormArray.push(this.buildScreen(productScreen));
        this.imageFileElement.push(null);
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }

    onImageFileChange(object: Event, imagePos: number): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                this.imageFileElement[imagePos] = window.btoa(binaryString);
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.myForm);
        }
    }

    onRemove(productScreen: MarketplaceProductScreen): void {
        if (productScreen?.id !== null) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Você tem certeza que deseja remover essa imagem da lista? ' +
                        '<br><div class="warn-A700-fg">' + productScreen.description + '</div>',
                    disableCancel: false,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.removeFromList(productScreen);
                    this.marketplaceProductScreenDaoService.remove(productScreen.id).subscribe((removed) => {

                    });
                }
            });
            this.addSubscription(subscription);
        } else {
            this.removeFromList(productScreen);
        }
    }


    public removeFromList(productScreen: MarketplaceProductScreen): void {
        for (let i = 0; i < this.myFormArray.value.length; i++) {
            if (this.myFormArray.value[i] === productScreen) {
                this.myFormArray.removeAt(i);
                this.myForm.markAsDirty();
            }
        }
    }


    ngOnInit(): void {
    }

    onSubmit(): void {
        const productScreens: MarketplaceProductScreen[] = this.myForm.value.myFormArray;
        const mapped = productScreens.map((item, index) => {
            return {productScreen: item, image: this.imageFileElement[index]};
        });
        const productScreenSaveList = mapped.filter((item) => !!item.productScreen.id);
        const productScreenCreateList = mapped.filter((item) => !item.productScreen.id);

        const saveCreateObservables: Observable<any>[] = [];

        productScreenSaveList.forEach(productImagePair => {
            const $ob = this.marketplaceProductScreenDaoService.save(productImagePair.productScreen).pipe(switchMap((result) => {
                return this.imageFileService.saveMarketPlaceProductScreenPicture(result.id, productImagePair.image);
            }));
            saveCreateObservables.push($ob);
        });

        productScreenCreateList.forEach(productImagePair => {
            const $ob = this.marketplaceProductScreenDaoService.create(productImagePair.productScreen).pipe(switchMap((result) => {
                return this.imageFileService.saveMarketPlaceProductScreenPicture(result.id, productImagePair.image);
            }));
            saveCreateObservables.push($ob);
        });

        forkJoin(saveCreateObservables).subscribe((resultSaveCreate) => {
            this.dialogRef.close(false);
        });
    }


    onCancel(): void {
        this.dialogRef.close(false);
    }

}

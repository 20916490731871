import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {ManagerUserAccessPeriodService} from '../manager-user-access-period/manager-user-access-period.service';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {DigitalCertificateComponent} from './digital-certificate.component';
import {DigitalCertificateDaoService} from './digital-certificate-dao.service';
import {SendDigitalCertificateComponent} from './send-digital-certificate/send-digital-certificate.component';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxMaskModule} from 'ngx-mask';
import {MatMenuModule} from '@angular/material/menu';
import {DigitalCertificateInstallDaoService} from './send-digital-certificate/digital-certificate-install-dao.service';
import {MatTooltipModule} from '@angular/material/tooltip';

const routes = [
    {
        path: 'digital-certificate',
        component: DigitalCertificateComponent,
        canActivate: [AuthService],
        data: {permissions: ['DigitalCertificate.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        KluhSharedModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSelectModule,
        MatGridListModule,
        NgBytesPipeModule,
        MatProgressSpinnerModule,
        NgxMaskModule,
        MatMenuModule,
        MatTooltipModule
    ],
    providers: [
        ManagerUserAccessPeriodService, DigitalCertificateDaoService, DigitalCertificateInstallDaoService
    ],
    entryComponents: [],
    declarations: [DigitalCertificateComponent, SendDigitalCertificateComponent],

})
export class DigitalCertificateModule {
}

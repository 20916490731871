import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ADDomain, ADUserPool, ADUserPoolRelationship, Customer, CustomerGroup, DesktopServer, ManagerUser, ServerType, SubProject} from '../../../models';
import {ComponentCleaner} from '../../../component-cleaner';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {AdUserPoolDaoService} from '../../ad-user-pool/ad-user-pool-dao.service';
import {combineLatest} from 'rxjs';
import {AdAdminDaoService} from '../../ad-admin/ad-admin.dao.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {AdUserPoolRelationshipDaoService} from '../ad-user-pool-relationship-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {FormControl} from '@angular/forms';
import {ScriptExecutionDaoService} from '../../script-execution/script-execution-dao.service';
import {DesktopServerService} from '../../desktop-server/desktop-server.service';
import {CustomerDaoService} from '../../customer/customer-dao.service';
import {CustomLayoutService, initCustomPortalTemplate} from '../../custom-layout/custom-layout.service';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {finishedLoading, isLoading, setLoading} from '../../../helpers/kluh';

@Component({
    selector: 'app-ad-user-pool-relationship-edit',
    templateUrl: './ad-user-pool-relationship-edit.component.html',
    styleUrls: ['./ad-user-pool-relationship-edit.component.scss']
})
export class AdUserPoolRelationshipEditComponent extends ComponentCleaner {
    adDomains: ADDomain[] = [];
    adDomain: ADDomain;
    adUserPoolRelationship: ADUserPoolRelationship;
    managerUser: ManagerUser;
    subProject: SubProject;
    customer: Customer;
    customerGroup: CustomerGroup;
    adUserPool: ADUserPool;
    adUserPw = '';
    loggedManagerUserIsAdmin = false;
    loggedManagerUserIsADDomainAdmin = false;
    currentEditManagerUserIsTheSameUser = false;
    appCanViewPasswordList: PermissionWrapper[];
    appCanOpenDesktopServerWithCustomADUserList: PermissionWrapper[];
    desktopServerFilterControl: FormControl = new FormControl();
    adGroupIds: FormControl = new FormControl();
    defaultCustomPortalTemplate: CustomPortalTemplate = initCustomPortalTemplate();


    constructor(
        public dialogRef: MatDialogRef<AdUserPoolRelationshipEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        public adminService: R2CloudAdminService,
        public adAdminDaoService: AdAdminDaoService,
        private customerGroupService: CustomerGroupService,
        private customerDaoService: CustomerDaoService,
        private userAuthorityDaoService: UserAuthorityDaoService,
        public adUserPoolDaoService: AdUserPoolDaoService,
        public adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
        public scriptExecutionDaoService: ScriptExecutionDaoService,
        public desktopServerService: DesktopServerService,
        public customLayoutService: CustomLayoutService,
    ) {
        super();


        this.adDomains = data.adDomains;
        this.adUserPoolRelationship = data.adUserPoolRelationship;
        this.managerUser = data.managerUser;
        this.subProject = data.subProject;

        this.customer = this.adminService.getCustomer(this.managerUser.customerId);
        if (!this.customer) {
            this.customerDaoService.getOneExternalByManagerUserId(this.managerUser.id).subscribe((customer) => {
                this.customer = customer;
            });
        }
        this.adDomain = this.adminService.getAdDomain(this.adminService.getAdUserPool(this.adUserPoolRelationship.adUserPoolId).adDomainId);
        this.adUserPool = this.adminService.getAdUserPool(this.adUserPoolRelationship.adUserPoolId);

        this.adGroupIds.setValue(this.adUserPoolRelationship.adGroupIds);

        combineLatest([
            this.adAdminDaoService.isAdminUser(this.adDomain.id),
            this.userAuthorityDaoService.getMe(),
            this.userAuthorityDaoService.isAdminUser()]).subscribe(values => {
            this.loggedManagerUserIsADDomainAdmin = values[0];
            const getMe = values[1];
            this.loggedManagerUserIsAdmin = values[2];
            this.currentEditManagerUserIsTheSameUser = getMe.managerUser.id === this.managerUser.id;
        });
//
        const customerGroupOfADDomain = this.customerGroupService.getCustomerGroup(this.adDomain.customerGroupId);
        if (customerGroupOfADDomain) {
            this.appCanViewPasswordList = [
                {type: 'ADUserPool', permission: 'CREATE', parentType: 'CustomerGroup', parent: customerGroupOfADDomain},
            ];
            this.appCanOpenDesktopServerWithCustomADUserList = [
                {type: 'ADUserPool', permission: 'CREATE', parentType: 'CustomerGroup', parent: customerGroupOfADDomain},
            ];
        }
        this.addSubscription(this.customLayoutService.getDefaultCustomerGroupTemplate$().subscribe(defaultCustomerGroupTemplate => {
            this.defaultCustomPortalTemplate = defaultCustomerGroupTemplate;
        }));
    }

    findByLoginPw(adUserPool: ADUserPool): void {
        this.adUserPoolDaoService.findByLoginPw(adUserPool.login, adUserPool.adDomainId).subscribe((adUserPw) => {
            this.adUserPw = adUserPw.adUserPassword;
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo remover esse usuário do projeto?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adUserPoolRelationshipDaoService.remove(this.adUserPoolRelationship.id).subscribe(() => {
                    this.dialogRef.close(this.adUserPool);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onUserLogOff(aDLogin: string): void {
        setLoading('onUserLogOff');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            this.scriptExecutionDaoService.logoffUserOnDesktopServer(desktopServer.id, aDLogin).subscribe((response) => {
                finishedLoading('onUserLogOff', 3000);
                if (response) {
                    console.debug('onUserLogOff: ' + response);
                    this.dialogRef.close();
                }
            });
        }
    }

    onUserReset(aDLogin: string): void {
        setLoading('onUserReset');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            this.scriptExecutionDaoService.resetUserOnDesktopServer(desktopServer.id, aDLogin).subscribe((response) => {
                finishedLoading('onUserReset', 3000);
                if (response) {
                    console.debug('onUserReset: ' + response);
                    this.dialogRef.close();
                }
            });
        }
    }


    onUnlockUserADAccount(aDLogin: string): void {
        setLoading('onUnlockUserADAccount');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            const serverId = this.adminService.getAdDomain(desktopServer.adDomainId).adServerId;
            this.scriptExecutionDaoService.unlockUserADAccount(serverId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
                finishedLoading('onUnlockUserADAccount', 3000);
                if (response) {
                    console.log('onUnlockUserADAccount: ' + response, {serverId, aDLogin});
                    this.dialogRef.close();
                }
            });
        }
    }

    onEnableUserADAccount(aDLogin: string): void {
        setLoading('onEnableUserADAccount');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            const serverId = this.adminService.getAdDomain(desktopServer.adDomainId).adServerId;
            this.scriptExecutionDaoService.enableUserADAccount(serverId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
                finishedLoading('onEnableUserADAccount', 3000);
                if (response) {
                    console.debug('onEnableUserADAccount: ' + response, {serverId, aDLogin});
                    this.dialogRef.close();
                }
            });
        }
    }

    onCheckEnableUserADAccount(aDLogin: string): void {
        setLoading('onCheckEnableUserADAccount');
        const desktopServer: DesktopServer = this.desktopServerFilterControl.value;
        if (desktopServer && desktopServer.id) {
            const serverId = this.adminService.getAdDomain(desktopServer.adDomainId).adServerId;
            this.scriptExecutionDaoService.checkEnableUserADAccount(serverId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
                finishedLoading('onCheckEnableUserADAccount', 3000);
                if (response) {
                    console.debug('onCheckEnableUserADAccount: ' + response, {serverId, aDLogin});
                    this.dialogRef.close();
                }
            });
        }
    }

    openDesktopServerCustomADUserPool(): void {
        const desktopServerId = this.desktopServerFilterControl?.value?.id;
        this.desktopServerService.openDesktopServerADAdminCustomUser(desktopServerId, this.adUserPool.id, true, this.adminService.subProject.id);
    }

    getDesktopServerListByADDomainId(adDomainId: number): DesktopServer[] {
        return this.adminService.desktopServers.filter((desktopServer) => {
            return desktopServer.adDomainId === adDomainId;
        });
    }

    savingGroupsInUser(): void {
        const adUserPoolRelationshipToSaveGroups = this.adUserPoolRelationship;
        adUserPoolRelationshipToSaveGroups.adGroupIds = this.adGroupIds.value;
        this.adUserPoolRelationshipDaoService.saveADUserPoolRelationshipGroups(adUserPoolRelationshipToSaveGroups).subscribe(adUserPoolRelationshipSaved => {
            if (adUserPoolRelationshipSaved) {
                this.adUserPoolRelationship = adUserPoolRelationshipSaved;
                this.adminService.replaceADUserPoolRelationship(adUserPoolRelationshipSaved);
                this.dialogRef.close();
            }
        });
    }

    adGroupListHasChange(): boolean {
        const array1 = this.adUserPoolRelationship.adGroupIds;
        const array2 = this.adGroupIds.value;
        if (array1.length === array2.length) {
            return array1.every(element => {
                return !!array2.includes(element);
            });
        }
        return false;
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.adUserPool.id, this.adUserPoolDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.adUserPool.id, this.adUserPoolDaoService, this.dialog);
    }

    onJaversHistoryRelationship(): void {
        EntityHistoryComponent.openHistory(this.adUserPoolRelationship.id, this.adUserPoolRelationshipDaoService, this.dialog);
    }

    onJaversAllHistoryRelationship(): void {
        EntityHistoryComponent.openAllHistory(this.adUserPoolRelationship.id, this.adUserPoolRelationshipDaoService, this.dialog);
    }

    protected readonly isLoading = isLoading;
}

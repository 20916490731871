<div class="page-layout carded fullwidth" style="margin: 20px">

    <div class="content" *ngIf="user && form" style="max-width:800px;">


        <form class="mat-card mat-elevation-z4 p-24 mr-24" style="padding: 0 !important;padding-bottom: 50px;width: 100%;" fxLayout="column"
              id="profile-form"
              fxLayoutAlign="start"
              (submit)="form.valid && form.dirty && onSubmit()"
              fxFlex="1 0 auto" name="form" [formGroup]="form">

            <div class="h2 mb-24" style="padding: 15px 30px 0 20px;">
                Perfil
            </div>

            <table cellspacing="0" cellpadding="0" style="padding: 0px 24px 24px 0px;">
                <tr>
                    <td style="text-align: right;vertical-align: top;max-width: 194px;">
                        <div style="border: #eeeeee solid 1px; padding: 5px;margin: 5px 0 5px 15px">
                            <img src="{{imageFileService.getManagerUserPicture(user.managerUser)}}?w=150&square=true" width="150"
                                 height="150" *ngIf="!imageFileElement">
                            <img [src]="'data:image/jpg;base64,' + imageFileElement" width="150" height="150"
                                 *ngIf="imageFileElement">
                        </div>

                        <button style="width: 163px;margin-top: 5px;" type="button" mat-raised-button
                                class="green-800-bg green-800"
                                onclick="document.getElementById('fileToUpload').click()">
                            Alterar Foto
                        </button>
                        <input (change)="onImageFileChange($event)" name="file"
                               id="fileToUpload" accept="image/*" type="file"  style="display:none;">



                        <button type="button" mat-raised-button color="primary" class="mt-20"
                                id="profile-change-password"
                                (click)="onChangePassword()">Alterar Senha
                        </button>


                        <!--<input (change)="onImageFileChange($event)"-->
                        <!--[formControl]="imageFileControl" type="file" name="file" accept="image/*" />-->
                        <!--<button style="width: 150px;margin-top: 5px;" type="button" mat-raised-button class="green-800-bg green-800" (click)="onChangePassword()">Alterar Foto-->
                        <!--</button>-->
                    </td>
                    <td style="padding: 0 0 0 20px;vertical-align: top;">
                        <div style="width: 100%;">
                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>E-mail (não pode alterar)</mat-label>
                                <input matInput formControlName="emailForm">
                                <mat-icon matSuffix class="disabled-text">email</mat-icon>
                            </mat-form-field>
                        </div>

                        <div formGroupName="managerUserForm" style="width: 100%">


                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Nome</mat-label>
                                <input matInput placeholder="Nome" name="displayName" required
                                       id="profile-name"
                                       formControlName="displayName">
                                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            </mat-form-field>


                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Número do Celular</mat-label>
                                <input matInput formControlName="phone"
                                       id="profile-phone"
                                       mask="(00)-00000000000" [validation]="false">
                                <mat-icon matSuffix class="secondary-text">smartphone</mat-icon>

                            </mat-form-field>


                            <mat-form-field appearance="outline" class="pr-4" id="profile-province"  style="width: 100%">
                                <mat-label>Estado</mat-label>
                                <!--<input matInput formControlName="province"-->
                                       <!--formControlName="province">-->
                                <mat-select placeholder="Estado" formControlName="province"
                                            id="profile-province-inner" >
                                    <mat-option *ngFor="let province of provinceList"
                                                id="{{province.value}}"
                                                [value]="province.key">
                                        {{province.value}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>


                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Cidade</mat-label>
                                <input matInput formControlName="city" id="profile-city" >

                            </mat-form-field>


                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Bairro</mat-label>
                                <input matInput formControlName="neighborhood" id="profile-neighborhood" >

                            </mat-form-field>



                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Endereço</mat-label>
                                <input matInput formControlName="address" id="profile-address" >

                            </mat-form-field>


                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Número</mat-label>
                                <input matInput formControlName="addressNumber" id="profile-address-number" mask="00000000000" [validation]="false">

                            </mat-form-field>



                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>Complemento</mat-label>
                                <input matInput formControlName="addressAddOn" id="profile-address-add-on" >

                            </mat-form-field>


                            <mat-form-field appearance="outline" class="pr-4" style="width: 100%">
                                <mat-label>CEP</mat-label>
                                <input matInput mask="00000-000" id="profile-address-zip-code"  formControlName="zipCode">

                            </mat-form-field>

                            <div>
                                <mat-slide-toggle formControlName="twoFactorAuth" name="twoFactorAuth" (change)="changeTwoFacture()">
                                    Autenticação em dois fatores
                                </mat-slide-toggle>
                            </div>

                        </div>
                    </td>
                </tr>

            </table>

            <div fxLayout="row" fxLayoutAlign="space-between end" class="p-16 grey-50-bg border-top"
                 style="min-height: 69px !important;height: 69px !important;"
                 matDialogActions>
                <button type="submit" mat-raised-button color="accent" [disabled]="form.pristine || !form.valid"
                        aria-label="Salvar">
                    <span>Salvar</span>

                </button>

            </div>

        </form>

    </div>
</div>




import {Injectable} from '@angular/core';
import {FirewallFQDN} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseDao} from '../../base-dao';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class FirewallFqdnDaoService extends BaseDao<FirewallFQDN> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'firewall-fqdn');
    }


    findAllByIds(ids: number[]): Observable<FirewallFQDN[]> {
        return this.http.post<FirewallFQDN[]>(this.url + '/find-all-by-ids', ids);
    }

    findAllByDesktopServerId(desktopServerId: number): Observable<FirewallFQDN[]> {
        return this.http.get<FirewallFQDN[]>(this.url + '/find-all-by-desktop-server-id/' + desktopServerId);
    }

    findAllByLinuxServerId(linuxServerId: number): Observable<FirewallFQDN[]> {
        return this.http.get<FirewallFQDN[]>(this.url + '/find-all-by-linux-server-id/' + linuxServerId);
    }
}

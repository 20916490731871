import {Injectable} from '@angular/core';
import {FeedParserService} from './feed-parser.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeedRetrieverService {

    constructor(private c: FeedParserService, private http: HttpClient) {
    }

    getWorkspace(d: any, e: any): Observable<any> {
        return this.http.get<void>(d, {
            headers: {
                Accept: 'application/x-msts-radc+xml;radc_schema_version=2.0,text/xml'
            },
            params: {
                'x-ms-correlation-id': e
            },
            responseType: 'text' as 'json',
            withCredentials: true,
        }).pipe(map((a) => {
            return this.c.parseWorkspaceFeed(a);
        }));
    }
}

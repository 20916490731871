import {Component, Inject} from '@angular/core';

import {ComponentCleaner} from '../../../component-cleaner';
import {FormControl} from '@angular/forms';
import {ImageFileService} from '../../image-file/image-file-service';
import {CustomerGroup} from '../../../models';
import {CustomerGroupService} from '../customer-group.service';
import {CustomerGroupCreateEditComponent} from '../customer-group-create-edit/customer-group-create-edit.component';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {SoftwareCompanyDaoService} from '../../marketplace/software-company-create-and-edit/software-company.dao.service';

@Component({
    selector: 'app-customer-group-search',
    templateUrl: './customer-group-search.component.html',
    styleUrls: ['./customer-group-search.component.scss']
})
export class CustomerGroupSearchComponent extends ComponentCleaner {
    search = new FormControl('');
    customerGroups: CustomerGroup[] = [];
    customerGroupsFiltered: CustomerGroup[] = [];

    searchCustomerGroupType = SearchCustomerGroupType;

    selectSearchCustomerGroupTypeFormControl: FormControl = new FormControl(this.searchCustomerGroupType.ALL);

    constructor(private dialogRef: MatDialogRef<CustomerGroupSearchComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public imageFileService: ImageFileService,
                public adminService: R2CloudAdminService,
                private dialog: MatDialog,
                public customerGroupService: CustomerGroupService,
                private softwareCompanyDaoService: SoftwareCompanyDaoService,
    ) {
        super();

        if (data.customerGroups) {
            this.customerGroups = data.customerGroups;
            this.customerGroupsFiltered = this.customerGroups;
        }
        if (this.adminService.softwareCompanies.length === 0) {
            this.softwareCompanyDaoService.get().subscribe((softwareCompanies) => {
                this.adminService.softwareCompanies = softwareCompanies;
            });
        }

        const subscriptionSelectSearch = this.selectSearchCustomerGroupTypeFormControl.valueChanges.subscribe((value) => {
            this.onSearch(this.search.value);
        });
        this.addSubscription(subscriptionSelectSearch);

        const subscription = this.search.valueChanges.subscribe((value) => {
            this.onSearch(value);
        });
        this.addSubscription(subscription);
    }

    private onSearch(search: string): void {
        if (search) {
            const searchCustomerGroupType: SearchCustomerGroupType = this.selectSearchCustomerGroupTypeFormControl.value;
            this.customerGroupsFiltered = this.customerGroups.filter((customerGroup) => {
                let customerGroupName = false;
                if (searchCustomerGroupType === SearchCustomerGroupType.ALL || searchCustomerGroupType === SearchCustomerGroupType.COMPANY_NAME) {
                    customerGroupName = customerGroup.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
                }
                let softwareCompanyName = false;
                if (searchCustomerGroupType === SearchCustomerGroupType.ALL || searchCustomerGroupType === SearchCustomerGroupType.SOFTWARE_COMPANY) {
                    customerGroup.softwareCompanyIds.forEach((softwareCompanyId) => {
                        if (this.adminService.getSoftwareCompany(softwareCompanyId)?.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) {
                            softwareCompanyName = true;
                        }
                    });
                }
                let partnerName = false;
                if (searchCustomerGroupType === SearchCustomerGroupType.ALL || searchCustomerGroupType === SearchCustomerGroupType.PARTNER) {
                    partnerName = this.customerGroupService.getCustomerGroup(
                        customerGroup.partnerCustomerGroupId
                    )?.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
                }
                let local = false;
                if (searchCustomerGroupType === SearchCustomerGroupType.ALL) {
                    local = customerGroup.localAdminUsersOnly && 'local'.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
                }
                return customerGroupName || softwareCompanyName || partnerName || local;
            });
        } else {
            this.customerGroupsFiltered = this.customerGroups;
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onAddCustomerGroup(): void {
        const dialogRef = this.dialog.open(CustomerGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                customerGroup: null
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRef.close(result);
        });
        this.addSubscription(subscription);
    }

    setCustomerGroupOnChange(customerGroup: CustomerGroup): void {
        if (customerGroup && this.customerGroupService.customerGroup !== customerGroup) {
            this.customerGroupService.set(customerGroup);
            this.dialogRef.close();
        }
    }

}

export enum SearchCustomerGroupType {
    ALL = 'ALL',
    COMPANY_NAME = 'COMPANY_NAME',
    PARTNER = 'PARTNER',
    SOFTWARE_COMPANY = 'SOFTWARE_COMPANY'
}



import {Component, Input} from '@angular/core';
import {DesktopServerVersionCount} from '../../../helpers/kluh';
import {CustomerGroup, DesktopServerIdWithVersion, DesktopServerWithSubProject, SubProject} from '../../../models';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {StatusService} from '../status-service';

@Component({
    selector: 'app-desktop-server-version',
    templateUrl: './desktop-server-version.component.html',
    styleUrls: ['./desktop-server-version.component.scss', '../status.component.scss']
})
export class DesktopServerVersionComponent {


    public desktopServerVersionCountList: DesktopServerVersionCount[] = [];
    public desktopServerIdWithVersionList: DesktopServerIdWithVersion[];
    public desktopServerWithSubProjectIdsList: DesktopServerWithSubProject[];


    @Input()
    customerGroups: CustomerGroup[];

    @Input()
    subProjects: SubProject[];

    loading = false;
    alreadyRead = false;

    subscript;


    constructor(private desktopServerDaoService: DesktopServerDaoService, private statusService: StatusService) {

    }

    private getData(): void {
        this.loading = true;
        this.alreadyRead = true;
        this.desktopServerDaoService.getAllDesktopServersWithSubProjectIds().subscribe((desktopServers) => {
            this.desktopServerWithSubProjectIdsList = desktopServers;
            const desktopServerIds: number[] = [];
            for (const desktopServer of desktopServers) {
                desktopServerIds.push(desktopServer.desktopServerDTO.id);
            }
            if (desktopServerIds.length > 0) {
                this.desktopServerDaoService.getDesktopServerIdWithVersion(desktopServerIds).subscribe((desktopServerIdWithVersionList) => {
                    this.desktopServerIdWithVersionList = desktopServerIdWithVersionList;
                    this.loading = false;
                    for (const desktopServerIdWithVersion of desktopServerIdWithVersionList) {
                        this.setDesktopServerVersionCount(desktopServerIdWithVersion.version);
                    }
                });
            }
        });
    }

    setDesktopServerVersionCount(version: string): void {
        let innerVersion = version;
        if (!innerVersion) {
            innerVersion = 'null';
        }
        let desktopServerVersionCount: DesktopServerVersionCount = {version: innerVersion, amount: 1};
        if (this.desktopServerVersionCountList) {
            const index = this.desktopServerVersionCountList.findIndex((o) => o.version === innerVersion);
            if (index > -1) {
                desktopServerVersionCount = this.desktopServerVersionCountList[index];
                desktopServerVersionCount.amount = desktopServerVersionCount.amount + 1;
                this.desktopServerVersionCountList[index] = desktopServerVersionCount;
            } else {
                this.desktopServerVersionCountList.push(desktopServerVersionCount);
            }
        }
    }

}

import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Html5ScriptLoaderModalComponent} from './html5-script-loader-modal/html5-script-loader-modal.component';

@Component({
    selector: 'app-html5-script-loader',
    templateUrl: './html5-script-loader.component.html',
    styleUrls: ['./html5-script-loader.component.scss']
})
export class Html5ScriptLoaderComponent implements OnInit {
    scriptLoaded = false;
    displayImageBackground = true;
    downloadProgress = new BehaviorSubject(0);
    openedDialog: MatDialogRef<Html5ScriptLoaderModalComponent>;
    private scriptDomId = 'rdcorelib';

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.loadScript();
    }

    onScriptLoaded(): void {
        console.debug('script loaded!!!!');
        this.openedDialog?.close();
        this.scriptLoaded = true;
    }

    loadScript(): void {
        if (document.getElementById(this.scriptDomId)) {
            console.debug('script already loaded');
            this.onScriptLoaded();
            return;
        }
        console.debug('preparing to load...');
        this.openedDialog = this.dialog.open(Html5ScriptLoaderModalComponent, {
            disableClose: true,
            panelClass: 'html5-script-loader-modal',
            data: {
                progress$: this.downloadProgress.asObservable()
            }
        });
        const req = new XMLHttpRequest();
        req.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                this.downloadProgress.next(event.loaded / event.total);
            }
        }, true);
        const _this = this;
        req.addEventListener('load', function (event: ProgressEvent): void {
            const node: HTMLScriptElement = document.createElement('script');
            node.id = _this.scriptDomId;
            // node.innerHTML = this.responseText;
            node.async = true;
            node.src = 'assets/js/core.js';
            document.getElementsByTagName('head')[0].appendChild(node);
            node.onload = () => _this.onScriptLoaded();
        });
        req.open('GET', 'assets/js/core.js');
        req.send();
    }

    onDisplayImageBackground($event: boolean): void {
        this.displayImageBackground = $event;
    }
}

import {Component, OnInit} from '@angular/core';
import {faPlug} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-server-plugin',
    templateUrl: './server-plugin.component.html',
    styleUrls: ['./server-plugin.component.scss']
})
export class DotNetServerPluginComponent implements OnInit {

    public faPlug = faPlug;

    constructor() {
    }

    ngOnInit(): void {
    }

}

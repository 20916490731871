import {Injectable} from '@angular/core';
import {BaseMonitoringDao} from '../../../base-monitoring-dao';
import {CustomerGroupTemplate} from '../../../models-custom-layout';
import {HttpClient} from '@angular/common/http';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpCustomLayoutService} from '../../../r2-cloud-http-custom-layout.service';
import {switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupTemplateDaoService extends BaseMonitoringDao<CustomerGroupTemplate> {
    private currentCustomerGroupTemplate: CustomerGroupTemplate;

    constructor(private httpAngular: HttpClient,
                http: R2CloudHttpCustomLayoutService,
                validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'customer-group-template');
    }

    getCurrentCustomerGroupTemplate(): CustomerGroupTemplate {
        return this.currentCustomerGroupTemplate;
    }

    getHelpLink(): void {
        let helpLink = this.getCurrentCustomerGroupTemplate()?.customPortalTemplate?.helpLink;
        if (!(helpLink && helpLink?.length > 4)) {
            helpLink = 'https://wa.me/551141110868?text=Ol%C3%A1!%20preciso%20de%20ajuda.';
        }
        window.open(helpLink, '_blank');
    }

    setCurrentCustomerGroupTemplate(currentCustomerGroupTemplate: CustomerGroupTemplate): void {
        this.currentCustomerGroupTemplate = currentCustomerGroupTemplate;
    }

    findOneByCustomerGroupUrl(url: string): Observable<CustomerGroupTemplate> {
        return this.http.urlProvider.pipe(switchMap((domainUrl) => {
            return this.httpAngular.get<CustomerGroupTemplate>(domainUrl + this.url + '/public/find-one-by-customer-group-url/' + url);
        }));
    }

    getDefaultCustomerGroupTemplate(): Observable<CustomerGroupTemplate> {
        return this.http.urlProvider.pipe(switchMap((domainUrl) => {
            return this.httpAngular.get<CustomerGroupTemplate>(domainUrl + this.url + '/public/find-one-by-customer-group-url/');
        }));
    }

    findOneByManagerUserId(managerUserId: number): Observable<CustomerGroupTemplate> {
        return this.http.get<CustomerGroupTemplate>(this.url + '/find-one-by-manager-user-id/' + managerUserId);
    }

    clearCacheByWebClientId(): Observable<void> {
        return this.http.get<void>(this.url + '/clear-cache-by-web-client-id');
    }

}

<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>Certificados Digitais</div>
                            <div>
                                <button type="button"
                                        mat-raised-button color="accent" (click)="openFileInput()"
                                        [disabled]="!customerGroup?.id">
                                    Adicionar certificado
                                </button>
                                <input type="file" #fileInput style="display: none;" (change)="onAddFiles($event)"
                                       accept=".pem, .crt, .cer, .key, .der, .pfx, .p12, .p7b, .p7c, .jks">
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">


                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Nome</th>
                                <td mat-cell *matCellDef="let element" class="full-width">
                                    <div class="text-max-400" title="{{element.name}}">{{element.name}}</div>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="size">
                                <th mat-header-cell *matHeaderCellDef>Tamanho</th>
                                <td mat-cell *matCellDef="let element" class="w-100" style="padding-left: 0;">
                                    <div>{{element.size | bytes}}</div>
                                </td>
                            </ng-container>


                            <!-- Name Column -->
                            <ng-container matColumnDef="expirationDate">
                                <th mat-header-cell *matHeaderCellDef>Data de expiração</th>
                                <td mat-cell *matCellDef="let element" style="padding-left: 0;">
                                    <div *ngIf="element.expirationDate" class="w-150">
                                        <div fxLayout="row" fxLayoutAlign="start center"
                                             *ngIf="isDateExpired(element.expirationDate, 30)" style="color: red;">
                                            <mat-icon>error</mat-icon>
                                            <div class="ml-5"><b>{{element.expirationDate | date: 'dd/MM/yyyy'}}</b></div>
                                        </div>
                                        <div *ngIf="!isDateExpired(element.expirationDate, 30)">
                                            <div class="ml-5">{{element.expirationDate | date: 'dd/MM/yyyy'}}</div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef>Data de criação</th>
                                <td mat-cell *matCellDef="let element" style="padding-left: 0;">
                                    <div *ngIf="element.createdAt" class="w-210">
                                        {{element.createdAt | date: 'dd/MM/yyyy HH:mm - EEE'}}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" class="text-align-center pr-15">
                                    <div *ngIf="element.deleting">
                                        <button mat-icon-button matTooltip="Deletando..."
                                                [matTooltipPosition]="'above'"
                                                (click)="openDigitalCertificateDeleteModal(element)">
                                            <mat-icon style="color: red;">error</mat-icon>
                                            <span style="padding-left: 5px;">Deletando...</span>
                                        </button>
                                    </div>
                                    <div class="full-width" fxLayout="row" fxLayoutAlign="end center"
                                         *ngIf="!element.deleting">
                                        <button color="warn" type="button" mat-raised-button
                                                (click)="openDigitalCertificateDeleteModal(element)">
                                            <span>Deletar</span>
                                        </button>
                                        <div *appIfPermission="appCanInstallCertificate">
                                            <button color="accent" type="button" class="ml-15" mat-raised-button
                                                    (click)="showDigitalCertificateDetailsModal(element)">
                                                <span>Instalar/Verificar</span>
                                            </button>
                                        </div>
                                    </div>

                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                [ngClass]="{'deleting':row.deleting}"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="totalDocs"></mat-paginator>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>

<div id="remote-app-list" fxLayout="column" fxLayoutAlign="start stretch">
  <!--<app-remote-app-create (remoteAppCreated)="onRemoteAppCreated($event)" [adDomains]="adDomains" [desktopServers]="desktopServers" class="mb-8"></app-remote-app-create>-->
  <button type="button" mat-raised-button (click)="onCreate()">Criar</button>
  <div fxLayout="column">
    <mat-form-field>
      <input matInput placeholder="Nome do Programa" [formControl]="remoteAppNameFilterControl">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Servidor" [formControl]="serverNameFilterControl">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Domínio" [formControl]="domainNameFilterControl">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let remoteApp of filteredRemoteApps">
    <div>
      {{remoteApp.name}}
    </div>
    <div>
      {{getServerFQDN(remoteApp)}}
    </div>
    <div>
      <button type="button" mat-raised-button (click)="onEdit(remoteApp)">
        Editar
      </button>
    </div>
  </div>
</div>

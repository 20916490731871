import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {SubProject} from '../../models';
import {CustomerDaoService} from '../customer/customer-dao.service';
import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {RoleDAOService} from '../iam/role-dao.service';
import {R2CloudAdminService} from '../r2-cloud-admin/r2-cloud-admin.service';
import {RoleCustomerGroupManagerUserDAOService} from '../iam/role-customer-group-manager-user-dao.service';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {orderArrayAnyBy} from '../../helpers/kluh';
import {ManagerUserAccessPeriodService} from '../manager-user-access-period/manager-user-access-period.service';
import {ManagerUserAccessPeriodProfileDaoService} from '../manager-user-access-period/manager-user-access-period-profile-dao.service';
import {ManagerUserAccessPeriodTimeDaoService} from '../manager-user-access-period/manager-user-access-period-time-dao.service';
import {PermissionWrapper} from '../../directives/if-permission.directive';
import {Subscription} from 'rxjs/internal/Subscription';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit, OnDestroy {

    subProjects: SubProject[] = [];
    canEditList: PermissionWrapper[];
    private subscriptions: Subscription[] = [];
    customerGroupServiceGet$: Subscription;

    constructor(
        public managerUserAccessPeriodService: ManagerUserAccessPeriodService,
        public customerGroupService: CustomerGroupService,
        private customerDaoService: CustomerDaoService,
        private managerUserDaoService: ManagerUserDaoService,
        private roleDaoService: RoleDAOService,
        public adminService: R2CloudAdminService,
        private customerGroupDaoService: CustomerGroupDaoService,
        private roleCustomerGroupManagerUserDao: RoleCustomerGroupManagerUserDAOService,
        private managerUserAccessPeriodProfileDaoService: ManagerUserAccessPeriodProfileDaoService,
        private managerUserAccessPeriodTimeDaoService: ManagerUserAccessPeriodTimeDaoService
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData(): void {
        this.clearData();
        this.customerGroupServiceGet$ = this.customerGroupService.get().subscribe(customerGroup => {
            this.clearSubscriptions();
            if (customerGroup && customerGroup.id) {
                this.clearData();
                this.canEditList =
                    [
                        {object: this.customerGroupService.get(), type: 'CustomerGroup', permission: 'WRITE'},
                        {object: this.adminService.customers$, type: 'Customer', permission: 'READ'},
                        {type: 'ManagerUser', permission: 'WRITE', parentType: 'CustomerGroup', parent: customerGroup},
                    ];
                this.getManagerUserAccessPeriodProfiles(customerGroup.id);
                this.getManagerUserAccessPeriodTimes(customerGroup.id);
                this.getCustomers(customerGroup.id);
                this.getManagerUsers(customerGroup.id);
                this.getRoles(customerGroup.id);
            }
        });
    }

    private getManagerUserAccessPeriodProfiles(customerGroupId: number): void {
        this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles = [];
        this.addSubscription(this.managerUserAccessPeriodProfileDaoService.filter(
            {customerGroupId: customerGroupId}
        ).subscribe(results => {
            if (results && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles = results;
            }
        }));
    }

    private getManagerUserAccessPeriodTimes(customerGroupId: number): void {
        this.managerUserAccessPeriodService.managerUserAccessPeriodTimes = [];
        this.addSubscription(this.managerUserAccessPeriodTimeDaoService.filter(
            {customerGroupId: customerGroupId}
        ).subscribe(results => {
            if (results && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.managerUserAccessPeriodService.managerUserAccessPeriodTimes = results;
            }
        }));
    }

    private getCustomers(customerGroupId: number): void {
        this.adminService.customers = [];
        this.addSubscription(this.customerDaoService.getActive({customerGroupId: customerGroupId}).subscribe(customers => {
            if (customers && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.adminService.customers = customers;
            }
        }));
    }

    private getManagerUsers(customerGroupId: number): void {
        this.adminService.managerUsers = [];
        this.addSubscription(this.managerUserDaoService.filter({managerUsersInCustomerGroupId: customerGroupId}).subscribe(managerUsers => {
            if (managerUsers && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.adminService.managerUsers = managerUsers;
                this.adminService.managerUsers = orderArrayAnyBy(this.adminService.managerUsers, 'id');
            }
        }));
    }


    private getRoles(customerGroupId: number): void {
        this.addSubscription(this.roleDaoService.get().subscribe((roles) => {
            if (roles && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.adminService.roles = roles;
                this.getManagerUsersInRoleCustomerGroupId(customerGroupId);
            }
        }));
    }

    private getManagerUsersInRoleCustomerGroupId(customerGroupId: number): void {
        this.addSubscription(this.managerUserDaoService.filter({managerUsersInRoleCustomerGroupId: customerGroupId}).subscribe((managerUsersCustomerGroup) => {
            if (managerUsersCustomerGroup && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                for (const managerUser of managerUsersCustomerGroup) {
                    this.adminService.addOrReplaceManagerUser(managerUser);
                    this.getExternalCustomersByManagerUserGroupId(managerUser.id, customerGroupId);
                }
                this.roleCustomerGroupManagerUser(customerGroupId);
            }
        }));
    }

    private roleCustomerGroupManagerUser(customerGroupId: number): void {
        this.adminService.roleCustomerGroupManagerUsers = [];
        this.addSubscription(this.roleCustomerGroupManagerUserDao.getByParentId(customerGroupId).subscribe((roleSubProjectManagerUserList) => {
            if (roleSubProjectManagerUserList && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.adminService.roleCustomerGroupManagerUsers = roleSubProjectManagerUserList;
            }
        }));
    }

    private getExternalCustomersByManagerUserGroupId(managerUserId: number, customerGroupId: number): void {
        this.addSubscription(this.customerDaoService.getOneExternalByManagerUserId(managerUserId).subscribe(customer => {
            if (customer && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.adminService.externalCustomers.push(customer);
                this.getCustomerGroupOfCustomerId(customer.id, customerGroupId);
            }
        }));
    }

    private getCustomerGroupOfCustomerId(customerId: number, customerGroupId: number): void {
        this.customerGroupDaoService.getOneExternalByCustomerId(customerId).subscribe(externalCustomerGroup => {
            if (externalCustomerGroup && this.customerGroupIdEqualsCurrentCustomerGroup(customerGroupId)) {
                this.adminService.externalCustomerGroups.push(externalCustomerGroup);
            }
        });
    }

    private customerGroupIdEqualsCurrentCustomerGroup(customerGroupId: number): boolean {
        return customerGroupId === this.customerGroupService.customerGroup.id;
    }

    private clearData(): void {
        this.subProjects = [];
        this.adminService.roles = [];
        this.adminService.roleCustomerGroupManagerUsers = [];
        this.adminService.managerUsersAdministrator = [];
        this.adminService.managerUsers = [];
        this.adminService.customers = [];
        this.adminService.externalCustomerGroups = [];
    }

    private clearSubscriptions(): void {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        this.subscriptions = [];
    }

    protected addSubscription(subscription: Subscription): Subscription {
        this.subscriptions.push(subscription);
        return subscription;
    }

    ngOnDestroy(): void {
        this.customerGroupServiceGet$?.unsubscribe();
        this.clearSubscriptions();
    }
}

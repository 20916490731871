import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HipcomBulkUpdatePathDaoService} from '../hipcom-bulk-update-path/hipcom-bulk-update-path-dao.service';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {HipcomBulkUpdatePath} from '../../../../../models';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {CrudOperationWrapper} from '../../../../../helpers/kluh';

@Component({
    selector: 'app-hipcom-bulk-update-path-create-edit',
    templateUrl: './hipcom-bulk-update-path-create-edit.component.html',
    styleUrls: ['./hipcom-bulk-update-path-create-edit.component.scss'],
    queries: {
        'contentRef': new ViewChild('contentRef', {static: false})
    },
})
export class HipcomBulkUpdatePathCreateEditComponent extends ComponentCleaner {


    myForm: FormGroup;
    myFormArray: FormArray;
    contentRef!: ElementRef;
    myFormControls: FormGroup;


    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdatePathCreateEditComponent>,
                private hipcomBulkUpdatePathDaoService: HipcomBulkUpdatePathDaoService,
                private fb: FormBuilder,
                private dialog: MatDialog) {
        super();
        this.myFormArray = this.fb.array([]);
        this.myForm = this.fb.group({myFormArray: this.myFormArray});
        this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;
        this.hipcomBulkUpdatePathDaoService.get().subscribe(hipcomBulkUpdatePathList => {
            if (hipcomBulkUpdatePathList) {
                for (const hipcomBulkUpdatePath of hipcomBulkUpdatePathList) {
                    this.onAddHipcomBulkUpdatePath(hipcomBulkUpdatePath);
                }
            } else {
                this.onAddHipcomBulkUpdatePath();
            }
        });
    }

    onAddHipcomBulkUpdatePath(hipcomBulkUpdate?: HipcomBulkUpdatePath): void {
        this.myFormArray.push(this.buildHipcomBulkUpdatePath(hipcomBulkUpdate));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }


    buildHipcomBulkUpdatePath(hipcomBulkUpdate?: HipcomBulkUpdatePath): FormGroup {
        let hipcomBulkUpdateObj: HipcomBulkUpdatePath = {
            id: null, active: true, comment: null,
            path: null,
            modified: null,
            optlock: null
        };
        if (hipcomBulkUpdate) {
            hipcomBulkUpdateObj = hipcomBulkUpdate;
        }
        return this.fb.group(hipcomBulkUpdateObj);
    }

    onHipcomBulkUpdatePath(hipcomBulkUpdate?: HipcomBulkUpdatePath): void {
        this.myFormArray.push(this.buildHipcomBulkUpdatePath(hipcomBulkUpdate));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }


    onSubmit(): void {
        const hipcomBulkUpdateList: HipcomBulkUpdatePath[] = this.myForm.value.myFormArray;
        const hipcomBulkUpdateCreateList = [];
        const hipcomBulkUpdateSaveList = [];

        for (const hipcomBulkUpdate of hipcomBulkUpdateList) {
            if (hipcomBulkUpdate.id) {
                hipcomBulkUpdateSaveList.push(hipcomBulkUpdate);
            } else {
                hipcomBulkUpdateCreateList.push(hipcomBulkUpdate);
            }
        }
        this.hipcomBulkUpdatePathDaoService.saveAll(hipcomBulkUpdateSaveList).subscribe((hipcomBulkUpdateSavedList) => {
            this.hipcomBulkUpdatePathDaoService.createAll(hipcomBulkUpdateCreateList).subscribe((hipcomBulkUpdateCreatedList) => {
                const allHipcomBulkUpdate: HipcomBulkUpdatePath[] = [];
                if (hipcomBulkUpdateSavedList) {
                    for (const bulkUpdate of hipcomBulkUpdateSavedList) {
                        allHipcomBulkUpdate.push(bulkUpdate);
                    }
                }
                if (hipcomBulkUpdateCreatedList) {
                    for (const bulkUpdate of hipcomBulkUpdateCreatedList) {
                        allHipcomBulkUpdate.push(bulkUpdate);
                    }
                }
                const crudOperation: CrudOperationWrapper = {
                    operation: 'SAVE',
                    data: allHipcomBulkUpdate
                };
                this.dialogRef.close(crudOperation);
            });
        });
    }


    onRemove(bulkUpdate: HipcomBulkUpdatePath): void {
        if (bulkUpdate?.id !== null) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Você tem certeza que deseja remover esse caminho da lista? ' +
                        '<br><div class="warn-A700-fg">' + bulkUpdate.path + '</div>',
                    disableCancel: false,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.hipcomBulkUpdatePathDaoService.remove(bulkUpdate.id).subscribe((removed) => {
                        const crudOperation: CrudOperationWrapper = {
                            operation: 'DELETE',
                            data: bulkUpdate
                        };
                        this.dialogRef.close(crudOperation);
                    });
                }
            });
            this.addSubscription(subscription);
        } else {
            this.removeFromList(bulkUpdate);
        }
    }


    public removeFromList(bulkUpdate: HipcomBulkUpdatePath): void {
        for (let i = 0; i < this.myFormArray.value.length; i++) {
            if (this.myFormArray.value[i] === bulkUpdate) {
                this.myFormArray.removeAt(i);
            }
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}

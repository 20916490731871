<div fxLayout="column" class="mt-26">
    <mat-card class="server-list-admin">
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Servidores ({{adminService.desktopServers?.length}})</div>
                <div>
<!--                    <button-->
<!--                        *appIfPermission="appCanCreateList"-->
<!--                        type="button" mat-raised-button color="accent"-->
<!--                        (click)="onCreateServer()">Criar-->
<!--                    </button>-->
                    <button style="width: 105px"
                            *appIfPermission="appCanCreateList"
                            type="button" mat-raised-button color="accent"
                            (click)="onCreateServerToken()">Criar
                    </button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content  *ngIf="(adminService.desktopServers && adminService.desktopServers.length > 0) || (adminService.linuxServers && adminService.linuxServers.length > 0)" >

            <table class="remote-app-table width-100-percent" cellspacing="0"
                   cellpadding="0">
                <thead>
                <tr class="bg-white">
                    <th class="no-padding"></th>
                    <th class="no-padding">Hostname</th>
<!--                    <th>Ip Privado</th>-->
                    <th class="no-padding">URL (FQDN)</th>
                    <th class="no-padding">Versão</th>
                    <th class="text-align-center no-padding ">Status</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tr *ngFor="let desktopServer of adminService.desktopServers"
                    [app-r2-cloud-admin-desktop-server-item]="desktopServer"
                    [desktopServerCallback]="desktopServerCallback"
                    [ngClass]="{'disabled': !desktopServer.active}">
                </tr>

                <tr *ngFor="let linuxServer of adminService.linuxServers"
                    [app-r2-cloud-admin-linux-server-item]="linuxServer"
                    [linuxServerCallback]="linuxServerCallback"
                    [ngClass]="{'disabled': !linuxServer.active}">

                </tr>
            </table>


        </mat-card-content>
    </mat-card>
</div>
import {Component} from '@angular/core';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';

@Component({
  selector: 'app-ad-user-pool-edit',
  templateUrl: './ad-user-pool-edit.component.html',
  styleUrls: ['./ad-user-pool-edit.component.scss']
})
export class AdUserPoolEditComponent {
    canEditList: PermissionWrapper[];
  constructor(
      private customerGroupService: CustomerGroupService
  ) {

      this.canEditList =
          [
              {type: 'ADUserPool', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup}
          ];
  }


}

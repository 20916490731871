<div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
        <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
            Escolha o tipo do Projeto que será criado
        </mat-toolbar>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="grey-50-bg border-top" style="padding: 35px 10px 10px 10px;">
        <div>
            <button mat-raised-button color="accent" (click)="onPrivate()" id="sub-project-dialog-private" >Privado</button>
        </div>
        <div>
            <button mat-raised-button color="accent" (click)="onShared()" id="sub-project-dialog-share" >Em um compartilhamento existente</button>
        </div>
        <div>
            <button mat-raised-button color="accent" (click)="onNewShared()" id="sub-project-dialog-new-share" >Em um novo compartilhamento</button>
        </div>
    </div>

    <div class="grey-50-bg p-16">
        <div style="margin-bottom: 15px;margin-top: 15px;">
            <div><b>Privado:</b></div>
            <span>Criará o projeto isolado em instâncias exclusivas.</span>
        </div>

        <div style="margin-bottom: 15px;">
            <div><b>Em um compartilhamento existente:</b></div>
            <span>Criará o projeto num compartilhamento existente junto com outros projetos.</span>
        </div>

        <div>
            <div><b>Em um novo compartilhamento:</b></div>
            <span>Criará um novo grupo de compartilhamento e colocará o projeto dentro.</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="grey-50-bg p-16" style="margin-bottom: 15px">
        <button mat-raised-button color="primary" (click)="close()" id="sub-project-dialog-cancel" >Cancelar</button>
    </div>
</div>

import {Injectable} from '@angular/core';
import {MarketplaceProduct} from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceProductService {
    constructor() {
    }

    delete(marketplaceProduct: MarketplaceProduct, marketplaceProducts: MarketplaceProduct[]): MarketplaceProduct[] {
        const index = marketplaceProducts.findIndex((o) => o.id === marketplaceProduct.id);
        if (index > -1) {
            marketplaceProducts.splice(index, 1);
        }
        return marketplaceProducts;
    }

    update(marketplaceProduct: MarketplaceProduct, marketplaceProducts: MarketplaceProduct[]): MarketplaceProduct[] {
        const index = marketplaceProducts.findIndex((o) => o.id === marketplaceProduct.id);
        if (index > -1) {
            marketplaceProducts[index] = marketplaceProduct;
        }
        return marketplaceProducts;
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {AuthService} from '../../auth.service';
import {IAMComponent} from './iam/iam.component';
import {PermissionDAOService} from './permission-dao.service';
import {RoleDAOService} from './role-dao.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RolesComponent} from './roles/roles.component';
import {IAMCreateEditComponent} from './iamcreate-edit/iamcreate-edit.component';
import {IAMAddPermissionsModalComponent} from './iamcreate-edit/iamadd-permissions-modal/iamadd-permissions-modal.component';
import {RoleSubProjectManagerUserDAOService} from './role-sub-project-manager-user-dao.service';
import {ManagerUserAdministratorComponent} from './manager-user-administrator/manager-user-administrator.component';
import {RoleCustomerGroupManagerUserComponent} from './role-customer-group-manager-user/role-customer-group-manager-user.component';
import {RoleCustomerGroupManagerUserEditComponent} from './role-customer-group-manager-user/role-customer-group-manager-user-edit/role-customer-group-manager-user-edit.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {ManagerUserAdministratorInBatchComponent} from './manager-user-administrator/manager-user-administrator-in-batch/manager-user-administrator-in-batch.component';


const routes = [
    {
        path: 'iam',
        component: IAMComponent,
        canActivate: [AuthService],
        data: {permissions: ['Permission.READ']}
    },
    {
        path: 'iam/role/:id',
        component: IAMCreateEditComponent,
        canActivate: [AuthService],
        data: {permissions: ['Permission.CREATE', 'Permission.WRITE']}
    },
    {
        path: 'iam-roles',
        component: RolesComponent,
        canActivate: [AuthService],
        data: {permissions: ['Permission.READ']}
    },
    {
        path: 'iam-role-customer-group-manager-user',
        component: RoleCustomerGroupManagerUserComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.WRITE']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatCardModule,
        MatIconModule,
        MatSelectModule,
        MatMenuModule,
        FontAwesomeModule
    ],
    declarations: [IAMComponent, RolesComponent, RoleCustomerGroupManagerUserComponent,
        IAMCreateEditComponent, IAMAddPermissionsModalComponent, ManagerUserAdministratorComponent,
        RoleCustomerGroupManagerUserEditComponent,
        ManagerUserAdministratorInBatchComponent],
    providers: [
        PermissionDAOService,
        RoleDAOService,
        RoleSubProjectManagerUserDAOService
    ],
    entryComponents: [
        IAMAddPermissionsModalComponent, RoleCustomerGroupManagerUserEditComponent
    ]
})
export class IAMModule {
}

import {Injectable, NgZone} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {BaseDao} from '../../../base-dao';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Message, Thread} from '../../../models-ai';
import {DomainPathService} from '../../../domain-path/domain-path.service';

@Injectable({
    providedIn: 'root'
})
export class MessageDaoService extends BaseDao<any> {
    private aiURL = '';

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator,
        private ngZone: NgZone) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    delete(message: Message): Observable<void> {
        return this.http.delete(this.aiURL + 'messages/', message, true);
    }

    createMessageAssistant(thread: Thread): Message {
        return this.createMessageObject(thread, null, 'assistant', '');
    }

    createMessageUser(thread: Thread, managerUserId: number, messageText: string): Message {
        return this.createMessageObject(thread, managerUserId, 'user', messageText);
    }

    private createMessageObject(thread: Thread, managerUserId: number | null, providerRole: string, messageText: string): Message {
        return {
            id: null,
            managerUserId: managerUserId,
            providerMessageId: null,
            providerThreadId: thread.providerThreadId,
            providerRole: providerRole,
            providerAssistantId: null,
            providerRunId: null,
            providerContent: messageText,
            providerAttachments: null,
            providerMetadata: null,
            threadId: thread.id,
            createdAt: new Date(Date.now()),
            updatedAt: null
        };
    }

    getMessages(threadId: number): Observable<Message[]> {
        return this.http.get<Message[]>(this.aiURL + 'messages/thread-id/' + threadId, null, null, true);
    }

    findLastXMessages(threadId: number, lastX: number): Observable<Message[]> {
        return this.http.get<Message[]>(this.aiURL + 'messages/thread-id/' + threadId + '/' + lastX, null, null, true);
    }

    getById(messageId: number): Observable<Message> {
        return this.http.get<Message>(this.aiURL + 'messages/' + messageId, null, null, true);
    }

    sendMessages(message: Message, aiUserGroupId: number, isPrivate: boolean): Observable<string> {
        const _webClientId = localStorage.getItem('_webClientId');
        const postUrl = this.aiURL + 'messages/' + aiUserGroupId + '/' + isPrivate;
        return new Observable<string>(observer => {
            fetch(postUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-web-client-id': '' + _webClientId + ''
                },
                body: JSON.stringify(message)
            }).then(response => {
                if (!response.body) {
                    throw new Error('ReadableStream not supported!');
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                const read = () => {
                    reader.read().then(({done, value}) => {
                        if (done) {
                            observer.complete();
                            return;
                        }
                        const chunk = decoder.decode(value, {stream: true});
                        this.ngZone.run(() => {
                            observer.next(chunk);
                        });
                        read();
                    }).catch(error => {
                        this.ngZone.run(() => {
                            observer.error(error);
                        });
                    });
                };
                read();
            }).catch(error => {
                this.ngZone.run(() => {
                    observer.error(error);
                });
            });
        });
    }
}



export interface MessageContent {
    type: string | null;
    text: MessageContentText | null;
}

export interface MessageContentText {
    value: string | null;
    annotations: MessageContentTextAnnotation[] | null;
}

export interface MessageContentTextAnnotation {
    type: string | null;
    text: string | null;
    start_index: number | null;
    end_index: number | null;
    file_citation: MessageContentTextAnnotationFileCitation | null;
}

export interface MessageContentTextAnnotationFileCitation {
    file_id: string | null;
}

export interface MessageFluxDelta {
    content: MessageContent[] | null;
}

export interface MessageFlux {
    id: string | null;
    object: string | null;
    delta: MessageFluxDelta | null;
}


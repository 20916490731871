<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Usuário de acesso {{defaultCustomPortalTemplate?.title}}.</span>
    </div>
  </mat-toolbar>
  <div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch" *ngIf="myForm">
      <mat-checkbox title="Apenas se o usuário ainda quiser continuar usando o TS"  aria-label="Cadastrar usuário de RDP existente."  formControlName="customAdUser" class="mb-4">
          Cadastrar usuário de RDP existente.
      </mat-checkbox>


      <mat-form-field formGroupName="adUserForm" [hidden]="!myForm.get('customAdUser').value">
        <input matInput formControlName="login" placeholder="Login">
        <mat-error>
          {{myForm.get('adUserForm').get('login').errors | error}}
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!myForm.get('customAdUser').value || myForm.get('askThePasswordForm').value">
        <input matInput type="password" formControlName="adUserPasswordForm" placeholder="Senha">
          <mat-error *ngIf="myForm.get('adUserPasswordForm').errors">
              Algum caracter não permitido ( aspas simples ou espaço )
          </mat-error>
      </mat-form-field>

        <mat-checkbox  [formControl]="removeChangingControl" name="removeChanging" class="mb-20" [hidden]="!myForm.get('customAdUser').value">
            Não checar se o usuário foi criado no servidor. (usado para usuário local)
        </mat-checkbox>

        <mat-checkbox class="mb-30"  [hidden]="!myForm.get('customAdUser').value"
                      aria-label="Pedir senha para o usuário quando logar no {{defaultCustomPortalTemplate?.title}}"  formControlName="askThePasswordForm">
            Pedir senha para o usuário quando logar no {{defaultCustomPortalTemplate?.title}}
        </mat-checkbox>




        <mat-form-field>
            <input matInput formControlName="email" placeholder="E-mail">
            <mat-error>
                {{myForm.get('adUserForm').get('login').errors | error}}
            </mat-error>
        </mat-form-field>




        <mat-form-field *ngIf="adDomains && adDomains.length > 1">
        <mat-select placeholder="Domínio" formControlName="domainForm">
          <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
            {{adDomain.name}} | {{adDomain.domainName}}
          </mat-option>
        </mat-select>
        <mat-error>
          {{myForm.get('domainForm').errors | error}}
        </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between end" >
        <div>
          <mat-form-field formGroupName="managerUserForm" *ngIf="customers && customers.length > 0 && !hasCustomerId" style="width: 290px;" >
            <mat-select placeholder="Filiais" formControlName="customerId">
              <mat-option *ngFor="let customer of customers" [value]="customer.id">
                {{customer.name}}
              </mat-option>
            </mat-select>
            <mat-error>
              {{myForm.get('managerUserForm').get('customerId').errors | error}}
            </mat-error>
          </mat-form-field>
        </div>

         <div *ngIf="!hasCustomerId || customers.length < 1" style="margin-bottom: 16px;" >
            <button type="button" mat-raised-button color="accent" aria-label="Criar Filial" (click)="onCreateCustomer()">Criar Filial</button>

         </div>

      </div>




      <!--<mat-form-field formGroupName="managerUserForm" *ngIf="newManagerUser && allAuthorities">-->
        <!--<mat-select placeholder="Autorização" formControlName="authorityIds" multiple>-->
          <!--<mat-option *ngFor="let authority of allAuthorities" [value]="authority.id">-->
            <!--{{authority.authority.replace("ROLE_", "")}}-->
          <!--</mat-option>-->
        <!--</mat-select>-->
        <!--<mat-error>-->
          <!--{{myForm.get('managerUserForm').get('authorityIds').errors | error}}-->
        <!--</mat-error>-->
      <!--</mat-form-field>-->

      <mat-error *ngIf="!myForm.get('email').errors">
        {{myForm.get('adUserForm').get('login').errors | error}}
      </mat-error>




    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

      <div matTooltip="Salvar">
        <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
                aria-label="Salvar">Criar</button>

      </div>

      <div>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()" >Cancelar</button>

      </div>
    </div>
  </div>
</form>

import {Injectable, Injector, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {auth} from 'firebase/app';
import {UserAuthorityDaoService} from './main/user-authority/user-authority-dao.service';
import {from, Observable, of, onErrorResumeNext, Subject} from 'rxjs';
import {catchError, filter, flatMap, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {CanAccess, FirebaseUserDetails, GrantedAuthority, Permission} from './models';
import {ManagerUserPermissionsDaoService} from './main/manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {ConfirmDialogComponent} from './helpers/confirm-dialog/confirm-dialog.component';
import {FirebaseService} from './firebase.service';
import {CacheControlService} from './cache-control.service';
import {MatDialog} from '@angular/material/dialog';
import {CustomerGroupService} from './main/customer-group/customer-group.service';
import {CustomerGroupTemplateDaoService} from './main/custom-layout/customer-group-template/customer-group-template-dao.service';
import {MonitoringPermissionDaoService} from './main/monitoring/monitoring-details/permission/monitoring-permission-dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {HttpClient} from '@angular/common/http';
import {DomainPathService} from './domain-path/domain-path.service';
import {ValueWrapper} from './helpers/value-wrapper';

@Injectable()
export class AuthService implements CanActivate, OnDestroy {
    canAccess: CanAccess | null = null;
    private blockedUser = false;

    constructor(
        private firebaseService: FirebaseService,
        private router: Router,
        private cacheControl: CacheControlService,
        private dialog: MatDialog,
        private injector: Injector,
        private httpAngular: HttpClient,
        private domainPathService: DomainPathService
    ) {
        // afAuth.auth.app.auth().languageCode = 'br';
        const onLogout = this.isLoggedIn().pipe(filter(x => !x));
        this.cacheControl.setCacheObservable(onLogout);
        onLogout.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            sessionStorage.clear();
        });
    }

    private get managerUserPermissionsDaoService(): ManagerUserPermissionsDaoService {
        if (!this._managerUserPermissionsDaoService) {
            this._managerUserPermissionsDaoService = this.injector.get<ManagerUserPermissionsDaoService>(ManagerUserPermissionsDaoService);
        }
        return this._managerUserPermissionsDaoService;
    }

    private get customerGroupService(): CustomerGroupService {
        if (!this._customerGroupService) {
            this._customerGroupService = this.injector.get<CustomerGroupService>(CustomerGroupService);
        }
        return this._customerGroupService;
    }

    private get userAuthorityDao(): UserAuthorityDaoService {
        if (!this._userAuthorityDao) {
            this._userAuthorityDao = this.injector.get<UserAuthorityDaoService>(UserAuthorityDaoService);
        }
        return this._userAuthorityDao;
    }

    private get monitoringPermissionDaoService(): MonitoringPermissionDaoService {
        if (!this._monitoringPermissionDaoService) {
            this._monitoringPermissionDaoService = this.injector.get<MonitoringPermissionDaoService>(MonitoringPermissionDaoService);
        }
        return this._monitoringPermissionDaoService;
    }

    private get customerGroupTemplateDaoService(): CustomerGroupTemplateDaoService {
        if (!this._customerGroupTemplateDaoService) {
            this._customerGroupTemplateDaoService = this.injector.get<CustomerGroupTemplateDaoService>(CustomerGroupTemplateDaoService);
        }
        return this._customerGroupTemplateDaoService;
    }

    private onDestroy$ = new Subject();

    private _managerUserPermissionsDaoService: ManagerUserPermissionsDaoService = null;
    private _customerGroupService: CustomerGroupService = null;
    private _userAuthorityDao: UserAuthorityDaoService = null;
    private _monitoringPermissionDaoService: MonitoringPermissionDaoService = null;
    private _customerGroupTemplateDaoService: CustomerGroupTemplateDaoService = null;

    static isValidUser(user: FirebaseUserDetails): boolean {
        return !!(user && user.accountNonExpired && user.accountNonLocked && user.enabled && user.email);
    }

    getBlockedUser(): boolean {
        return this.blockedUser;
    }

    private setBlockedUser(blockedUser: boolean): void {
        // console.log('(auth) blockedUser:', blockedUser);
        this.blockedUser = blockedUser;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const url = routerState.url;
        // console.debug(`verificando permissão para rota ${url}`);
        return this.firebaseService.isLoggedIn().pipe(switchMap((result) => {
            // console.debug(`verificando se está logado para rota ${url}, ${result}`);
            if (result) {
                return this.userAuthorityDao.getMe().pipe(switchMap((user) => {
                    // console.debug(`verificando se usuário é valido para rota ${url}, ${user}`);
                    if (AuthService.isValidUser(user)) {
                        // console.debug(`usuário é valido para rota ${url}`);
                        if (user.credentialsExpiring && url !== '/profile') {
                            this.redirectToChangePassword();
                            return of(false);
                        } else {
                            return this.managerUserPermissionsDaoService.isAdminUser(user.managerUser.id).pipe(switchMap((isAdmin) => {
                                if (isAdmin) {
                                    return of(true);
                                } else {
                                    if (route.data.permissions) {
                                        return this.comparePermissions(route.data.permissions, route.data.preCondition).pipe(switchMap((permissionsResult) => {
                                            // console.debug(`acesso autorizado para rota ${url}, ${permissionsResult}`);
                                            if (!permissionsResult) {
                                                //    TODO redirecionar para uma tela com a msg de acesso negado ou para a Home?
                                                if (this.customerGroupService.customerGroup) {
                                                    this.router.navigate(['/desktop'], {queryParams: {id: this.customerGroupService.customerGroup.id}});
                                                } else {
                                                    this.router.navigate(['/desktop']);
                                                }
                                            }
                                            return of(permissionsResult);
                                        }));
                                    } else {
                                        return of(true);
                                    }
                                }
                            }));
                        }
                    } else {
                        // console.debug(`usuário não é valido para rota ${url}, redirecionando para login`);
                        this.redirectToLogin(url);
                        return of(false);
                    }
                }));
            } else {
                // console.debug(`usuário não está logado para rota ${url}, redirecionando para login`);
                this.redirectToLogin(url);
                return of(false);
            }
        }));
    }

    loginWithGoogle(): Observable<boolean> {
        const provider = new auth.GoogleAuthProvider();
        // provider.setCustomParameters({
        //   hd: 'kluh.com.br'
        // });
        return this.firebaseService.signInWithPopup(provider).pipe(map(o => !!o), catchError(() => of(false)));
    }

    loginWithCredentialsV2(email: string, password: string, rememberMe: boolean): Observable<string> {
        return this.domainPathService.apiURL$.pipe(
            switchMap((apiUrl) => {
                return this.httpAngular.post<ValueWrapper>(`${apiUrl}firebase/credentials`, {
                    email: email,
                    password: password,
                    rememberMe: rememberMe
                }).pipe(
                    map((wrapper) => wrapper.value),
                );
            })
        );
    }

    loginWithCredentials(email: string, password: string, rememberMe: boolean): Observable<any> {
        let persistence: auth.Auth.Persistence;
        console.debug('login loginWithCredentials 1');
        if (rememberMe) {
            persistence = auth.Auth.Persistence.LOCAL;
            console.debug('login loginWithCredentials 2');
        } else {
            persistence = auth.Auth.Persistence.SESSION;
            console.debug('login loginWithCredentials 3');
        }
        return this.firebaseService.setPersistence(persistence).pipe(flatMap(() => {
            return this.firebaseService.signInWithEmailAndPassword(email, password);
        }));
    }


    reloginWithPassword(password: string): Observable<any> {
        return this.userAuthorityDao.getMe().pipe(switchMap((user) => {
            const email = user.email;
            const credential: auth.AuthCredential = auth.EmailAuthProvider.credential(email, password);
            return this.firebaseService.auth().pipe(switchMap((authResult) => {
                return from(authResult.currentUser.reauthenticateWithCredential(credential));
            }));
            // return from(this.firebaseService.auth().currentUser.reauthenticateWithCredential(credential));
        }));
    }

    logoutMicroservices$(): Observable<any> {
        return combineLatest([
            this.monitoringPermissionDaoService.clearCacheByWebClientId(),
            this.customerGroupTemplateDaoService.clearCacheByWebClientId()]).pipe(() => {
            return of({});
        });
    }

    logout(): Observable<void> {
        // console.trace('logout called');
        return this.firebaseService.signOut().pipe(switchMap(() => this.userAuthorityDao.getLogout()));
    }

    getMyPermissions(): Observable<Permission[]> {
        return this.userAuthorityDao.getMyPermissions();
    }


    /**
     * @deprecated
     */
    getMyRoles(): Observable<GrantedAuthority[]> {
        console.warn('method getMyRoles is deprecated');
        return this.userAuthorityDao.getMe().pipe(map((user) => {
            if (user) {
                return user.authorities;
            } else {
                return [];
            }
        }));
    }

    comparePermissions(permissions: string[], preCondition: boolean): Observable<boolean> {
        return this.getMyPermissions().pipe(switchMap((myPermissions) => {
            if (myPermissions) {
                for (const permissionName of permissions) {
                    for (const myPermission of myPermissions) {
                        if (permissionName === myPermission.name) {
                            if (preCondition) {
                                const url = new URL(window.location.href);
                                const params = new URLSearchParams(url.search);
                                let customerGroupId = params.get('id');
                                let subProjectIds: number[] = [];
                                if (customerGroupId == null) {
                                    if (this.customerGroupService.customerGroup) {
                                        customerGroupId = '' + this.customerGroupService.customerGroup.id;
                                    }
                                }
                                if (customerGroupId != null) {
                                    subProjectIds = this.customerGroupService.customerGroup.subProjectIds;
                                    const subProjectExist = myPermission.subProjectIds.some(x => subProjectIds.includes(x));
                                    return of(
                                        (myPermission.customerGroupIds.indexOf(+customerGroupId) > -1) ||
                                        subProjectExist
                                    );
                                } else {
                                    return of(false);
                                }
                            } else {
                                return of(true);
                            }
                        }
                    }
                }
            }
            return of(false);
        }));
    }

    returnToOriginalUrl(): void {
        let urlString = sessionStorage.getItem('returnUrl');
        sessionStorage.removeItem('returnUrl');
        if (!(urlString && urlString.length > 0)) {
            urlString = '/';
        }
        this.router.navigateByUrl(urlString);
    }

    redirectToLogin(returnUrl: string | null): void {
        // noinspection JSIgnoredPromiseFromCall
        if (returnUrl) {
            sessionStorage.setItem('returnUrl', returnUrl);
        }
        this.router.navigate(['login']);
    }

    redirectToWithRefresh(returnUrl: string): void {
        if (returnUrl) {
            window.location.href = returnUrl;
        }
    }

    /*
        redirectToLoginWithQuerys(params?: any): void {
            this.router.navigate(['/login'], {queryParams: params});
        }
    */
    forcedLogout(message: string): void {
        this.logoutMicroservices$().subscribe(() => {
                this.logoutWithMessageAndRedirectToLoginPage(message);
            },
            () => {
                this.logoutWithMessageAndRedirectToLoginPage(message);
            });
    }

    logoutAfterChangePassword(): void {
        const message = '<h2>Senha alterada com sucesso.</h2>Por favor faça o login com sua nova senha.';
        this.logoutWithMessage(message);
    }

    logoutWithTitleAndMessage(title: string, message: string): void {
        const titleH2 = '<h2>' + title + '</h2>';
        const topBody = '<div>';
        const bottomBody = '</div><br>';
        let defaultMessage = topBody + 'Por favor entre em contato com o administrador' + bottomBody;
        if (message && message.length > 3) {
            defaultMessage = topBody + message + bottomBody;
        }
        this.logoutWithMessageAndRedirectToLoginPage(titleH2 + defaultMessage);
    }

    processCanAccess(canAccess: CanAccess): void {
        this.canAccess = canAccess;
        if (!canAccess.allowed) {
            this.logoutMicroservices$().subscribe(() => {
                    this.logoutOnCanNotAccess(canAccess.start, canAccess.end);
                },
                () => {
                    this.logoutOnCanNotAccess(canAccess.start, canAccess.end);
                });
        }
    }

    logoutOnCanNotAccess(timeStart: string, timeEnd: string): void {
        let dateMessage = 'horário permitido <b>das ' + timeStart + ' às ' + timeEnd + '</b>';
        if (timeStart === '00:00' && timeEnd === '00:01') {
            dateMessage = '<b>Periodo:</b> O dia inteiro';
        }
        const message = '<h2>Acesso temporariamente bloqueado</h2>' + dateMessage +
            '<br><br>Caso seja urgente, <br>' +
            'entre em contato com o reponsável pelo controle de acesso da sua empresa<br><br>';
        this.logoutWithMessageAndRedirectToLoginPage(message);
    }

    private logoutWithMessageAndRedirectToLoginPage(message: string): void {
        // this.redirectToLogin(null);
        this.logoutWithMessage(message);
    }

    private logoutWithMessage(message: string, redirectToLoginPage: boolean = true): void {
        this.setBlockedUser(true);
        this.logout().subscribe(() => {
            const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: true,
                    icon: 'info_outline',
                    confirmButtonValue: 'OK'
                }
            });
            matDialogRef.afterClosed().subscribe(() => {
                if (redirectToLoginPage) {
                    this.setBlockedUser(false);
                    window.location.href = '/login';
                }
            });
        });
    }

    sendResetPassword(email: string): Observable<any> {
        return onErrorResumeNext(
            this.firebaseService.auth().pipe(switchMap((authResult) => {
                return from(authResult.sendPasswordResetEmail(email));
            })),
            of(null)
        );
    }

    redirectToChangePassword(): void {
        this.router.navigate(['/profile']);
    }

    changePassword(newPassword: string): Observable<any> {
        return this.firebaseService.getToken()
            .pipe(
                take(1),
                switchMap((token) => {
                    console.debug('changePassword get oldToken ok');
                    return this.firebaseService.auth()
                        .pipe(
                            switchMap((authResult) => {
                                return from(authResult.currentUser.updatePassword(newPassword));
                            }),
                            take(1),
                            switchMap(() => of(token))
                        );
                }),
                switchMap((token: string) => {
                    console.debug('changePassword updatePassword ok');
                    this.userAuthorityDao.clearCache();
                    return this.firebaseService.invalidateAndRefreshToken()
                        .pipe(
                            switchMap(() => {
                                try {
                                    return of(token);
                                } catch (error) {
                                    console.error('Erro1:', error);
                                    return of(token);
                                }
                            }),
                            catchError(error => {
                                console.error('Erro2:', error);
                                return of(token);
                            })
                        );
                }),
                switchMap((oldToken: string) => {
                    console.debug('changePassword invalidateAndRefreshToken ok');
                    return this.userAuthorityDao.setCredentialsExpiring(false, oldToken)
                        .pipe(
                            tap(() => {
                                console.debug('changePassword setCredentialsExpiring ok');
                            })
                        );
                }));
    }

    duplicatedLogin(): void {
        this.logoutMicroservices$().subscribe(() => {
                this.logoutOnDuplicate();
            },
            () => {
                this.logoutOnDuplicate();
            });
    }

    logoutOnDuplicate(): void {
        this.logout().subscribe(() => {
            const message = '<h2>Login duplicado:</h2>  Usuário logado em outro navegador.';
            const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: true,
                    icon: 'info_outline',
                    confirmButtonValue: 'OK'
                }
            });
            matDialogRef.afterClosed().subscribe(() => {
                this.redirectToWithRefresh('/login');
            });
        });
    }

    isLoggedIn(): Observable<boolean> {
        return this.firebaseService.isLoggedIn();
    }

    signOut(): Observable<void> {
        return this.firebaseService.signOut();
    }

    getFirebaseToken(): Observable<string> {
        return this.firebaseService.getToken();
    }

    updateProfile(name: string, picture: string): void {
        this.firebaseService.updateProfile(name, picture);
    }

}

export interface LogoutListener extends Function {
    remove?: Function;
}

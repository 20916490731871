import {ManagerUserAccessPeriodProfile, ManagerUserAccessPeriodTime} from '../../models';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ManagerUserAccessPeriodService implements OnDestroy {

    private _managerUserAccessPeriodProfiles$ = new BehaviorSubject<ManagerUserAccessPeriodProfile[]>([]);
    private _managerUserAccessPeriodProfiles: ManagerUserAccessPeriodProfile[] = [];
    private _managerUserAccessPeriodTimes$ = new BehaviorSubject<ManagerUserAccessPeriodTime[]>([]);
    private _managerUserAccessPeriodTimes: ManagerUserAccessPeriodTime[] = [];
    private _unload$ = new Subject<void>();


    constructor() {
    }

    ngOnDestroy(): void {
        this._managerUserAccessPeriodProfiles$.complete();
        this._managerUserAccessPeriodTimes$.complete();

    }


    public unload(): void {
        this._managerUserAccessPeriodProfiles = [];
        this._managerUserAccessPeriodTimes = [];
        this._unload$.next();
    }

    get whenUnload$(): Observable<void> {
        return this._unload$;
    }


    get managerUserAccessPeriodProfiles$(): Observable<ManagerUserAccessPeriodProfile[]> {
        return this._managerUserAccessPeriodProfiles$.pipe(filter((o) => !!o));
    }

    get managerUserAccessPeriodProfiles(): ManagerUserAccessPeriodProfile[] {
        return this._managerUserAccessPeriodProfiles;
    }

    set managerUserAccessPeriodProfiles(managerUsers: ManagerUserAccessPeriodProfile[]) {
        this._managerUserAccessPeriodProfiles = managerUsers;
        this._managerUserAccessPeriodProfiles$.next(managerUsers);
    }

    public addManagerUserAccessPeriodProfiles(managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile): void {
        const managerUserAccessPeriodProfiles = this._managerUserAccessPeriodProfiles$.value;
        managerUserAccessPeriodProfiles.push(managerUserAccessPeriodProfile);
        this.managerUserAccessPeriodProfiles = managerUserAccessPeriodProfiles;
    }

    public addOrReplaceManagerUserAccessPeriodProfile(managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile): void {
        const managerUserAccessPeriodProfiles = this._managerUserAccessPeriodProfiles$.value;
        const index = managerUserAccessPeriodProfiles.findIndex((o) => o.id === managerUserAccessPeriodProfile.id);
        if (index < 0) {
            managerUserAccessPeriodProfiles.push(managerUserAccessPeriodProfile);
        } else {
            managerUserAccessPeriodProfiles[index] = managerUserAccessPeriodProfile;
        }
        this.managerUserAccessPeriodProfiles = managerUserAccessPeriodProfiles;
    }


    public findManagerUserAccessPeriodTimesById(id: number): ManagerUserAccessPeriodProfile {
        const managerUserAccessPeriodProfiles = this.managerUserAccessPeriodProfiles?.filter(x => x.id === id);
        if (managerUserAccessPeriodProfiles?.length > 0) {
            return managerUserAccessPeriodProfiles[0];
        }
        return null;
    }


    get managerUserAccessPeriodTimes$(): Observable<ManagerUserAccessPeriodTime[]> {
        return this._managerUserAccessPeriodTimes$.pipe(filter((o) => !!o));
    }

    get managerUserAccessPeriodTimes(): ManagerUserAccessPeriodTime[] {
        return this._managerUserAccessPeriodTimes;
    }

    set managerUserAccessPeriodTimes(managerUsers: ManagerUserAccessPeriodTime[]) {
        this._managerUserAccessPeriodTimes = managerUsers;
        this._managerUserAccessPeriodTimes$.next(managerUsers);
    }

    public addmanagerUserAccessPeriodTimes(managerUserAccessPeriodTime: ManagerUserAccessPeriodTime): void {
        const managerUserAccessPeriodTimes = this._managerUserAccessPeriodTimes$.value;
        managerUserAccessPeriodTimes.push(managerUserAccessPeriodTime);
        this.managerUserAccessPeriodTimes = managerUserAccessPeriodTimes;
    }

    public addOrReplaceManagerUserAccessPeriodTime(managerUserAccessPeriodTime: ManagerUserAccessPeriodTime): void {
        const managerUserAccessPeriodTimes = this._managerUserAccessPeriodTimes$.value;
        const index = managerUserAccessPeriodTimes.findIndex((o) => o.id === managerUserAccessPeriodTime.id);
        if (index < 0) {
            managerUserAccessPeriodTimes.push(managerUserAccessPeriodTime);
        } else {
            managerUserAccessPeriodTimes[index] = managerUserAccessPeriodTime;
        }
        this.managerUserAccessPeriodTimes = managerUserAccessPeriodTimes;
    }


}

import {NgModule} from '@angular/core';

import {RouterModule} from '@angular/router';

import {FuseLoginComponent} from './login.component';
import {LoginGuardService} from './login-guard.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';

import {FuseForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FooterModule} from '../../../layout/components/footer/footer.module';
import {TwoFactorAuthDaoService} from './two-factor-auth-dao.service';
import {NgxMaskModule} from 'ngx-mask';

const routes = [
    {
        path: 'login',
        component: FuseLoginComponent,
        canActivate: [LoginGuardService]
    },
    {
        path: 'forgot-password',
        component: FuseForgotPasswordComponent
    }
];

@NgModule({
    declarations: [
        FuseLoginComponent,
        FuseForgotPasswordComponent
    ],
    imports: [
        FuseSharedModule,
        RouterModule.forChild(routes),
        KluhSharedModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatButtonModule,
        FooterModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        LoginGuardService,
        TwoFactorAuthDaoService
    ]
})

export class LoginModule {

}

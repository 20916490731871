import * as moment from 'moment';
import {getDifferenceBetweenTwoDatesInHours} from '../../../helpers/kluh';

class DataChart {
    maxValue: number;
    minValue: number;
    maxChartValue: number;
    minChartValue: number;
    labelTitle: string[];
}

export let lineChartData: Array<any> = [];
export let lineChartLabels: Array<any> = [];
export let lineChartLegend = true;
export let lineChartType = 'line';
export let lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                //  steps : 25,
                //  stepValue : 15,
                max: 100,
                min: 0
            }
        }]
    }
};

export let lineChartColors: Array<any> = [
    { // dark grey
        backgroundColor: 'rgba(60,186,84,0.3)',
        borderColor: '#3cba54',
        pointBackgroundColor: 'rgba(77,83,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
        backgroundColor: 'rgba( 72, 133, 237, 0.3)',
        borderColor: '#4885ed ',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // grey
        backgroundColor: 'rgba(244, 194, 13,0.3)',
        borderColor: '#f4c20d',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // grey
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // grey
        backgroundColor: 'rgba(219, 50, 54,0.3)',
        borderColor: '#db3236',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
];


export let dataChartProcessor: DataChart = {
    maxValue: 80,
    minValue: 15,
    maxChartValue: 100,
    minChartValue: 0,
    labelTitle: ['Uso de Processamento %']
};


export let dataChartMemory: DataChart = {
    maxValue: 90,
    minValue: 40,
    maxChartValue: 100,
    minChartValue: 0,
    labelTitle: ['Uso de Memória %']
};

export let dataChartHd: DataChart = {
    maxValue: 75,
    minValue: 50,
    maxChartValue: 100,
    minChartValue: 0,
    labelTitle: ['Uso de HD %']
};

export let dataChartVPNs: DataChart = {
    maxValue: 190,
    minValue: 120,
    maxChartValue: 0,
    minChartValue: 0,
    labelTitle: ['Filial 1', 'Filial 2', 'Filial 3']
};


export function setChartScale(max: number, min: number): void {
    if (min !== max) {
        lineChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {point: {radius: 0}},
            lineThickness: 0.2,
            scales: {
                yAxes: [{
                    ticks: {
                        //  steps : 25,
                        //  stepValue : 15,
                        max: max,
                        min: min,
                    }
                }],
                xAxes: [{
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 40
                    }
                }]
            }
        };
    } else {
        lineChartOptions = {
            elements: {point: {radius: 0}},
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 40
                    }
                }]
            }
        };
    }
}

export function setChartValue(begin: Date, end: Date, tabTextLabel: string): void {
    if (tabTextLabel === 'PROCESSOR_USAGE') {
        generateLabelsAndDataToChart(begin, end,
            dataChartProcessor.maxValue,
            dataChartProcessor.minValue,
            dataChartProcessor.maxChartValue,
            dataChartProcessor.minChartValue,
            dataChartProcessor.labelTitle);
    } else if (tabTextLabel === 'MEMORY_USAGE') {
        generateLabelsAndDataToChart(begin, end,
            dataChartMemory.maxValue,
            dataChartMemory.minValue,
            dataChartMemory.maxChartValue,
            dataChartMemory.minChartValue,
            dataChartMemory.labelTitle);
    } else if (tabTextLabel === 'HD_USAGE') {
        generateLabelsAndDataToChart(begin, end,
            dataChartHd.maxValue,
            dataChartHd.minValue,
            dataChartHd.maxChartValue,
            dataChartHd.minChartValue,
            dataChartHd.labelTitle);
    } else if (tabTextLabel === 'VPN_USAGE') {
        generateLabelsAndDataToChart(begin, end,
            dataChartVPNs.maxValue,
            dataChartVPNs.minValue,
            dataChartVPNs.maxChartValue,
            dataChartVPNs.minChartValue,
            dataChartVPNs.labelTitle);
    }
}


export function generateLabelsAndDataToChart(begin: Date, end: Date, maxValue: number, minValue: number, maxChartValue: number, minChartValue: number, labelTitle: string[]): void {
    const diffHours = getDifferenceBetweenTwoDatesInHours(begin, end);
    lineChartLabels = [];
    let dataInner: number[] = [];
    setChartScale(maxChartValue, minChartValue);
    const data: any[] = [];
    if (diffHours < 48) {
        let x = 10; // minutes interval
        if (diffHours > 10 && diffHours < 13) {
            x = 5;
        } else if (diffHours > 5 && diffHours < 7) {
            x = 2;
        } else if (diffHours < 2) {
            x = 1;
        }
        // console.log('2. diffHours: ' + diffHours);
        // console.log('2. x: ' + x);
        const times = []; // time array
        let tt = 0; // start time
        for (let i = 0; tt < 24 * 60; i++) {
            const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
            const mm = (tt % 60); // getting minutes of the hour in 0-55 format
            times[i] = ('0' + (hh)).slice(-2) + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
            tt = tt + x;
            lineChartLabels.push(times[i]);
        }
        for (let a = 0; a < labelTitle.length; a++) {
            dataInner = [];
            for (let i = 0; i < times.length; i++) {
                let dataValue: number = Math.round(Math.random() * (maxValue - minValue) + minValue);
                if (maxChartValue === minChartValue && i === 0) {
                    dataValue = 0;
                }
                dataInner.push(dataValue);
            }
            data.push(dataInner);
        }
        lineChartData = [];
        for (let i = 0; i < labelTitle.length; i++) {
            lineChartData.push({data: data[i], label: labelTitle[i], borderWidth: 1});
        }
    } else {
        const dateArray = [];
        let currentDate = moment(begin);
        const stopDate = moment(end);
        while (currentDate <= stopDate) {
            const x = 10;
            const times = [];
            let tt = 0;
            for (let i = 0; tt < 24 * 60; i++) {
                const hh = Math.floor(tt / 60);
                const mm = (tt % 60);
                times[i] = ('0' + (hh)).slice(-2) + ':' + ('0' + mm).slice(-2);
                tt = tt + x;
                dateArray.push(moment(currentDate).format('YYYY-MM-DD') + ' ' + times[i]);
                lineChartLabels.push(moment(currentDate).format('YYYY-MM-DD') + ' ' + times[i]);
            }
            currentDate = moment(currentDate).add(1, 'days');
        }
        for (let countLabel = 0; countLabel < labelTitle.length; countLabel++) {
            dataInner = [];
            for (let i = 0; i < dateArray.length; i++) {
                let dataValue: number = Math.round(Math.random() * (maxValue - minValue) + minValue);
                if (maxChartValue === minChartValue && i === 0) {
                    dataValue = 0;
                }
                dataInner.push(dataValue);
            }
            data.push(dataInner);
        }
        lineChartData = [];
        for (let i = 0; i < labelTitle.length; i++) {
            lineChartData.push({data: data[i], label: labelTitle[i], borderWidth: 1});
        }
    }
}

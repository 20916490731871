import {Component} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {SnapshotType, SubProject} from '../../../models';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {FormControl} from '@angular/forms';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-project-changing-or-deleting',
    templateUrl: './project-changing-or-deleting.component.html',
    styleUrls: ['./project-changing-or-deleting.component.scss', '../status.component.scss']
})
export class ProjectChangingOrDeletingComponent {

    snapshots: JaversSnapshot<SubProject>[];
    snapshotTypeControl: FormControl = new FormControl();
    sizeListControl: FormControl = new FormControl();
    snapshotType = SnapshotType;
    loading = false;

    constructor(private subProjectDaoService: SubProjectDaoService, private dialog: MatDialog) {
        this.sizeListControl.setValue(5);
        this.snapshotTypeControl.setValue(SnapshotType.ALL);
        // this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    private snapshotsByTypeAndLimit(size: number, snapshotType: SnapshotType = SnapshotType.ALL): void {
        this.loading = true;
        this.snapshots = [];
        this.subProjectDaoService.snapshotsByTypeAndLimit(size, snapshotType).subscribe((subProjectsSnapshots) => {
            this.snapshots = subProjectsSnapshots;
            this.loading = false;
        });
    }

    refresh(): void {
        this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    onJaversHistory(id: number): void {
        EntityHistoryComponent.openHistory(id, this.subProjectDaoService, this.dialog);
    }
}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../../base-dao';
import {HipcomBulkUpdatePath} from '../../../../../models';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class HipcomBulkUpdatePathDaoService extends BaseDao<HipcomBulkUpdatePath> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'partners-hipcom-bulk-update-path');
    }

    findByIdList(idList: number[]): Observable<HipcomBulkUpdatePath[]> {
        return this.http.post<HipcomBulkUpdatePath[]>(this.url + '/find-by-id-list', idList);
    }
}

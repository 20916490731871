import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'secondsToStringInfoTime'
})
export class SecondsToStringInfoTimePipe implements PipeTransform {

    transform(secondsIn: any, texTemplate: any = ''): any {
        if (secondsIn) {
            const s = 1000;
            const m = s * 60;
            const h = m * 60;
            const day = h * 24;
            if (typeof (secondsIn) !== 'undefined') {

                const d = Math.abs(secondsIn * 1000);

                const totalSeconds = secondsIn;
                const months = Math.floor(totalSeconds / (3600 * 24 * 30));
                const days = Math.floor(totalSeconds / (3600 * 24));
                const hours = Math.floor(totalSeconds % (3600 * 24) / 3600);
                const minutes = Math.floor(totalSeconds % 3600 / 60);
                // const seconds = Math.floor(totalSeconds % 60);
                if (texTemplate === 'large') {
                    if (totalSeconds < 45) {
                        return 'há segundos atrás';
                    } else if (totalSeconds >= 45 && minutes < 59) {
                        return 'há ' + minutes + ' minutos atrás';
                    } else if (hours === 1) {
                        return 'há ' + hours + ' hora atrás';
                    } else if (hours > 1 && hours < 24) {
                        return 'há ' + hours + ' horas atrás';
                    } else if (days === 1) {
                        return 'há ' + days + ' dia atrás';
                    } else if (days >= 1 && days < 32) {
                        return 'há ' + days + ' dias atrás';
                    } else if (months === 1) {
                        return 'há ' + months + ' mês atrás';
                    } else if (months >= 1) {
                        return 'há ' + months + ' meses atrás';
                    } else {
                        return '-';
                    }
                } else {
                    if (d > day) {
                        return (days + ' dias e ' + hours + ':' + minutes);
                    } else if (d > h) {
                        return (hours + 'h ' + minutes + 'm');
                    } else if (d > m) {
                        return (minutes + ' minutos');
                    } else if (d > s) {
                        return 'há ' + Math.floor(d / s) + ' segundos ';
                    }
                    return Math.floor(d) + ' ms ';
                }
            }
        }
        return '-';
    }
}

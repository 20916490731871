import {BaseDao} from './base-dao';
import {Observable} from 'rxjs';
import {Page} from './helpers/Page';
import {BaseDTOIdLong, SearchCriteria} from './models';

export abstract class BaseSearchDao<T extends BaseDTOIdLong> extends BaseDao<T> {

    search(searchCriteria: SearchCriteria): Observable<Page<T>> {
        return this.http.put<Page<T>>(this.url + '/search', searchCriteria);
    }
}

<ng-container *ngIf="(customerGroups && customerGroups.length > 1) || customerGroupCreator">
    <button  mat-button class="user-button pl-10 w-275" id="customer-group-tool-bar-search-modal" (click)="searchModalCustomerGroup()">
        <div fxLayout="row" fxLayoutAlign="start center" [ngStyle]="customStyle" >
            <span *ngIf="customerGroupService?.customerGroup?.id;" >
                <img src="{{imageFileService.getCustomerGroupPictureUrl(customerGroupService.customerGroup.imageUUID, 30)}}" width="30" height="30">
            </span>
            <span class="username mr-12 ml-8 text-max-120" *ngIf="customerGroupService?.customerGroup?.id; else showSelectCustomerGroup">{{ customerGroupService.customerGroup.name }}</span>
            <ng-template #showSelectCustomerGroup>
                <span class="username mr-12"> Selecione uma empresa </span>
            </ng-template>
            <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
        </div>
    </button>
</ng-container>
<ng-container *ngIf="customerGroups && customerGroups.length === 1 && !customerGroupCreator">
    <button  mat-button  class="user-button pl-10 cursor-default" >
        <div fxLayout="row" fxLayoutAlign="start center" [ngStyle]="customStyle" >
            <span *ngIf="customerGroupService?.customerGroup?.id;" >
                <img src="{{imageFileService.getCustomerGroupPictureUrl(customerGroupService.customerGroup.imageUUID, 30)}}" width="30" height="30">
            </span>
            <span class="username mr-12 ml-8 text-max-120" *ngIf="customerGroupService?.customerGroup?.id">{{ customerGroupService.customerGroup.name }}</span>
        </div>
    </button>
</ng-container>

import {Component, OnInit} from '@angular/core';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {DotNetServerPluginTemplate} from '../../../models';
import {MatDialog} from '@angular/material/dialog';
import {DotNetServerPluginTemplateCreateEditComponent} from '../server-plugin-template-create-edit/server-plugin-template-create-edit.component';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {DotNetServerPluginTemplateDaoService} from '../services/server-plugin-template-dao.service';

@Component({
    selector: 'app-server-plugin-template-card',
    templateUrl: './server-plugin-template-card.component.html',
    styleUrls: ['./server-plugin-template-card.component.scss']
})
export class DotNetServerPluginTemplateCardComponent implements OnInit {

    constructor(
        public serverPluginService: DotNetServerPluginService,
        public serverPluginTemplateDao: DotNetServerPluginTemplateDaoService,
        private dialog: MatDialog
    ) {

    }

    ngOnInit(): void {
    }

    onCreate(): void {
        const serverPluginTemplate: DotNetServerPluginTemplate = {
            active: false,
            comment: '',
            id: undefined,
            name: '',
            serverPluginIds: [],
            modified: null,
            optlock: null
        };
        this.openCreateEditModal(serverPluginTemplate);
    }

    onEdit(serverPluginTemplate: DotNetServerPluginTemplate): void {
        this.openCreateEditModal(serverPluginTemplate);
    }

    private openCreateEditModal(serverPluginTemplate: DotNetServerPluginTemplate): void {
        this.dialog.open(DotNetServerPluginTemplateCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                serverPluginTemplate
            }
        });
    }

    onDelete(serverPluginTemplate: DotNetServerPluginTemplate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse Template de Plugin?<br><br>' + serverPluginTemplate.name,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverPluginTemplateDao.remove(serverPluginTemplate.id).subscribe(deleted => {
                    this.serverPluginService.deleteFromList(serverPluginTemplate.id, this.serverPluginService.serverPluginTemplates);
                });
            }
        });
    }
}

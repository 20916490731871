import {AfterViewInit, Component, Input} from '@angular/core';
import {BackgroundImageService} from '../helpers/background-image.service';

@Component({
    selector: 'app-background',
    templateUrl: './background.component.html',
    styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements AfterViewInit {
    backgroundUrl: string;

    @Input()
    type: string;

    @Input()
    imageUrls: number[];

    @Input()
    delayMs: number;

    private imageClass: string;
    private originalImageClass: string;

    constructor(private backgroundImage: BackgroundImageService) {
    }

    ngAfterViewInit(): void {
        if (!this.delayMs || this.backgroundImage.backgroundIsLoaded()) {
            this.delayMs = 0;
        }
        setTimeout(() => {
            this.backgroundImage.getRandomBackgroundUrl(this.imageUrls).subscribe((backgroundUrl) => {
                setTimeout(() => {
                    this.backgroundUrl = backgroundUrl;
                    this.originalImageClass = 'background-image background-image-' + this.type;
                    this.imageClass = this.originalImageClass;
                });
            });
        }, this.delayMs);
    }
    setTransition(): void {
        this.imageClass = this.originalImageClass + ' background-image-loaded';
    }
}

import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../../../image-file/image-file-service';
import {MarketplaceService} from '../../../marketplace.service';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {FirebaseUserDetails, MarketplaceProduct, MarketplaceProductFAQ} from '../../../../../models';
import {MarketplaceProductFAQDaoService} from '../marketplace-product-faq.dao.service';
import {combineLatest} from 'rxjs';
import {CrudOperationWrapper} from '../../../../../helpers/kluh';
import {UserAuthorityDaoService} from 'app/kluh-manager/main/user-authority/user-authority-dao.service';

@Component({
    selector: 'app-marketplace-product-detail-faqedit',
    templateUrl: './marketplace-product-faq-edit.component.html',
    styleUrls: ['./marketplace-product-faq-edit.component.scss'],
    queries: {
        'contentRef': new ViewChild('contentRef', {static: false})
    }
})
export class MarketplaceProductFAQEditComponent extends ComponentCleaner {
    myForm: FormGroup;
    myFormArray: FormArray;
    myFormControls: FormGroup;
    contentRef!: ElementRef;
    marketplaceProduct: MarketplaceProduct;
    user: FirebaseUserDetails = null;

    constructor(public dialogRef: MatDialogRef<MarketplaceProductFAQEditComponent>,
                private fb: FormBuilder,
                private imageFileService: ImageFileService,
                private marketplaceService: MarketplaceService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private marketplaceProductFaqDaoService: MarketplaceProductFAQDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog) {
        super();
        this.userAuthorityDaoService.getMe().subscribe((user) => {
            this.user = user;
            this.marketplaceProduct = data?.marketplaceProduct;
            if (!this.marketplaceProduct) {
                this.marketplaceProduct = this.marketplaceService.initMarketplaceProduct();
            }
            this.myFormArray = this.fb.array([]);
            this.myForm = this.fb.group({myFormArray: this.myFormArray});
            this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;
            this.marketplaceProductFaqDaoService.findAllByMarketplaceProductId(this.marketplaceProduct.id).subscribe(marketplaceProductFaqList => {
                if (marketplaceProductFaqList) {
                    for (let i = 0; i < marketplaceProductFaqList.length; i++) {
                        this.onAddProductFAQ(marketplaceProductFaqList[i]);
                    }
                }
                if (this.myFormArray.length < 1) {
                    this.onAddProductFAQ();
                }
            });
        });
    }

    onAddFAQ(marketPlaceProductFAQ?: MarketplaceProductFAQ): void {
        this.myFormArray.push(this.buildFAQ(marketPlaceProductFAQ));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }

    buildFAQ(marketPlaceProductFAQ?: MarketplaceProductFAQ): FormGroup {
        let productFAQ: MarketplaceProductFAQ = {
            id: null,
            active: true,
            comment: null,
            managerUserId: this.user.managerUser.id,
            marketplaceProductId: this.marketplaceProduct.id,
            question: null,
            answer: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
        if (marketPlaceProductFAQ) {
            productFAQ = marketPlaceProductFAQ;
        }
        return this.fb.group(productFAQ);
    }

    onAddProductFAQ(productFAQ?: MarketplaceProductFAQ): void {
        this.myForm.markAsPristine();
        this.myForm.markAsUntouched();
        this.myForm.updateValueAndValidity();
        this.myFormArray.push(this.buildFAQ(productFAQ));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }

    onRemove(productFAQ: MarketplaceProductFAQ): void {
        if (productFAQ?.id !== null) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Você tem certeza que deseja remover esse pergunta da lista? ' +
                        '<br><div class="warn-A700-fg">' + productFAQ.question + '</div>',
                    disableCancel: false,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.removeFromList(productFAQ);
                    if (productFAQ.id) {
                        this.marketplaceProductFaqDaoService.remove(productFAQ.id).subscribe(removed => {

                        });
                    }
                }
            });
            this.addSubscription(subscription);
        } else {
            this.removeFromList(productFAQ);
        }
    }


    public removeFromList(productFAQ: MarketplaceProductFAQ): void {
        for (let i = 0; i < this.myFormArray.value.length; i++) {
            if (this.myFormArray.value[i] === productFAQ) {
                this.myFormArray.removeAt(i);
                this.myForm.markAsDirty();
            }
        }
    }

    onSubmit(): void {
        const productFAQS: MarketplaceProductFAQ[] = this.myForm.value.myFormArray;
        const productFAQCreateList = [];
        const productFAQSaveList = [];
        for (const productFAQ of productFAQS) {
            if (productFAQ.id) {
                productFAQSaveList.push(productFAQ);
            } else {
                productFAQCreateList.push(productFAQ);
            }
        }
        const marketplaceProductFaqSave$ = this.marketplaceProductFaqDaoService.saveAll(productFAQSaveList);
        const marketplaceProductFaqCreate$ = this.marketplaceProductFaqDaoService.createAll(productFAQCreateList);
        combineLatest([
            marketplaceProductFaqSave$, marketplaceProductFaqCreate$]
        ).subscribe(values => {
            const saveList = values[0];
            const createList = values[1];
            const marketplaceProductFaqList = saveList?.concat(createList);
            const crudOperation: CrudOperationWrapper = {
                data: marketplaceProductFaqList,
                operation: 'SAVE'
            };
            this.dialogRef.close(crudOperation);
        });
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}

import {Injectable} from '@angular/core';
import {DotNetServerPluginDaoService} from './server-plugin-dao.service';
import {DotNetServerPluginTemplateDaoService} from './server-plugin-template-dao.service';
import {DotNetServerPluginFileDaoService} from './server-plugin-file-dao.service';
import {Observable, Subscriber} from 'rxjs';
import {
    BaseDTOIdLong,
    DotNetServerAgentFile,
    DotNetServerAgentUpdaterFile,
    DotNetServerPlugin,
    DotNetServerPluginDependencyFile,
    DotNetServerPluginFile,
    DotNetServerPluginTemplate
} from '../../../models';
import {BaseDao} from '../../../base-dao';
import {tap} from 'rxjs/operators';
import {DotNetServerAgentFileDaoService} from './server-agent-file-dao.service';
import {DotNetServerAgentUpdaterFileDaoService} from './server-agent-updater-file-dao.service';
import {DotNetServerPluginDependencyFileDaoService} from './server-plugin-dependency-file-dao.service';

@Injectable({
    providedIn: 'root'
})
export class DotNetServerPluginService {
    public readonly serverPlugin$: Observable<DotNetServerPlugin[]>;
    public serverPlugins: DotNetServerPlugin[] = [];
    private serverPluginSubs: Subscriber<DotNetServerPlugin[]>[] = [];
    public readonly serverPluginTemplate$: Observable<DotNetServerPluginTemplate[]>;
    public serverPluginTemplates: DotNetServerPluginTemplate[] = [];
    private serverPluginTemplateSubs: Subscriber<DotNetServerPluginTemplate[]>[] = [];
    public readonly serverPluginFile$: Observable<DotNetServerPluginFile[]>;
    private serverPluginFiles: DotNetServerPluginFile[] = [];
    private serverPluginFileSubs: Subscriber<DotNetServerPluginFile[]>[] = [];
    public readonly serverPluginDependencyFile$: Observable<DotNetServerPluginDependencyFile[]>;
    public serverPluginDependencyFiles: DotNetServerPluginDependencyFile[] = [];
    private serverPluginDependencyFileSubs: Subscriber<DotNetServerPluginDependencyFile[]>[] = [];
    public readonly serverAgentFile$: Observable<DotNetServerAgentFile[]>;
    public serverAgentFiles: DotNetServerAgentFile[] = [];
    public serverAgentFileSubs: Subscriber<DotNetServerAgentFile[]>[] = [];
    public readonly serverAgentUpdaterFile$: Observable<DotNetServerAgentUpdaterFile[]>;
    public serverAgentUpdaterFiles: DotNetServerAgentUpdaterFile[] = [];
    private serverAgentUpdaterFileSubs: Subscriber<DotNetServerAgentUpdaterFile[]>[] = [];

    constructor(
        private serverPluginDao: DotNetServerPluginDaoService,
        private serverPluginTemplateDao: DotNetServerPluginTemplateDaoService,
        private serverPluginFileDao: DotNetServerPluginFileDaoService,
        private serverPluginDependencyFileDao: DotNetServerPluginDependencyFileDaoService,
        private serverAgentFileDao: DotNetServerAgentFileDaoService,
        private serverAgentUpdaterFileDao: DotNetServerAgentUpdaterFileDaoService
    ) {
        DotNetServerPluginService.processGet(serverPluginDao, this.serverPlugins, this.serverPluginSubs);
        DotNetServerPluginService.processGet(serverPluginTemplateDao, this.serverPluginTemplates, this.serverPluginTemplateSubs);
        DotNetServerPluginService.processGet(serverPluginFileDao, this.serverPluginFiles, this.serverPluginFileSubs);
        DotNetServerPluginService.processGet(serverPluginDependencyFileDao, this.serverPluginDependencyFiles, this.serverPluginDependencyFileSubs);
        DotNetServerPluginService.processGet(serverAgentFileDao, this.serverAgentFiles, this.serverAgentFileSubs);
        DotNetServerPluginService.processGet(serverAgentUpdaterFileDao, this.serverAgentUpdaterFiles, this.serverAgentUpdaterFileSubs);
        this.serverPlugin$ = DotNetServerPluginService.processSubscriber(this.serverPluginSubs, this.serverPlugins);
        this.serverPluginTemplate$ = DotNetServerPluginService.processSubscriber(this.serverPluginTemplateSubs, this.serverPluginTemplates);
        this.serverPluginFile$ = DotNetServerPluginService.processSubscriber(this.serverPluginFileSubs, this.serverPluginFiles);
        this.serverPluginDependencyFile$ = DotNetServerPluginService.processSubscriber(this.serverPluginDependencyFileSubs, this.serverPluginDependencyFiles);
        this.serverAgentFile$ = DotNetServerPluginService.processSubscriber(this.serverAgentFileSubs, this.serverAgentFiles);
        this.serverAgentUpdaterFile$ = DotNetServerPluginService.processSubscriber(this.serverAgentUpdaterFileSubs, this.serverAgentUpdaterFiles);
    }

    private static processSubscriber<T extends BaseDTOIdLong>(subs: Subscriber<T[]>[], list: T[]): Observable<T[]> {
        return new Observable<T[]>(subscriber => {
            subs.push(subscriber);
            if (list) {
                subscriber.next(list);
            }
            return () => {
                subs.splice(subs.indexOf(subscriber), 1);
            };
        });
    }

    private static processGet<T extends BaseDTOIdLong>(dao: BaseDao<T>, list: T[], subs: Subscriber<T[]>[]): void {
        dao.get().subscribe(resultList => {
            let newList;
            if (!resultList) {
                newList = [];
            } else {
                newList = resultList;
            }
            list.length = 0;
            list.push(...newList);
            for (const sub of subs) {
                sub.next(list);
            }
        });
    }

    private static processSave<T extends BaseDTOIdLong>(entity: T, dao: BaseDao<T>, list: T[], subs: Subscriber<T[]>[]): Observable<T> {
        let ob$: Observable<T>;
        if (entity.id) {
            ob$ = dao.save(entity);
        } else {
            ob$ = dao.create(entity);
        }
        return this.getObservable(ob$, list, subs);
    }

    private static getObservable<T extends BaseDTOIdLong>(ob$: Observable<T>, list: T[], subs: Subscriber<T[]>[]): Observable<T> {
        return ob$.pipe(tap(result => {
                const index = list.findIndex(o => o.id === result.id);
                if (index > -1) {
                    list[index] = result;
                } else {
                    list.push(result);
                }
                for (const sub of subs) {
                    sub.next(list);
                }
            }
        ));
    }

    private static deleteObservable<T extends BaseDTOIdLong>(object: T, ob$: Observable<void>, list: T[], subs: Subscriber<T[]>[]): Observable<void> {
        return ob$.pipe(tap(() => {
            const index = list.findIndex(o => o.id === object.id);
            if (index > -1){
                list.splice(index, 1);
            }
            for (const sub of subs) {
                sub.next(list);
            }
        }));
    }

    onServerPluginFileUploadFinished(ob: Observable<DotNetServerPluginFile>): Observable<any> {
        return DotNetServerPluginService.getObservable(ob, this.serverPluginFiles, this.serverPluginFileSubs);
    }

    onServerPluginFileDeleteFinished(serverPluginFile: DotNetServerPluginFile, ob: Observable<void>): Observable<void> {
        return DotNetServerPluginService.deleteObservable(serverPluginFile, ob, this.serverPluginFiles, this.serverPluginFileSubs);
    }

    onServerPluginDependencyFileUploadFinished(ob: Observable<DotNetServerPluginDependencyFile>): Observable<any> {
        return DotNetServerPluginService.getObservable(ob, this.serverPluginDependencyFiles, this.serverPluginDependencyFileSubs);
    }

    onServerAgentFileUploadFinished(ob: Observable<DotNetServerAgentFile>): Observable<any> {
        return DotNetServerPluginService.getObservable(ob, this.serverAgentFiles, this.serverAgentFileSubs);
    }

    onServerAgentUpdaterFileUploadFinished(ob: Observable<DotNetServerAgentUpdaterFile>): Observable<any> {
        return DotNetServerPluginService.getObservable(ob, this.serverAgentUpdaterFiles, this.serverAgentUpdaterFileSubs);
    }

    getServerPluginFromFile(serverPluginVersion: DotNetServerPluginFile): DotNetServerPlugin | null {
        if (this.serverPlugins) {
            return this.serverPlugins.find(o => o.id === serverPluginVersion.serverPluginId);
        }
        return null;
    }

    saveServerPlugin(serverPlugin: DotNetServerPlugin): Observable<DotNetServerPlugin> {
        return DotNetServerPluginService.processSave(serverPlugin, this.serverPluginDao, this.serverPlugins, this.serverPluginSubs);
    }

    saveServerPluginTemplate(serverPluginTemplate: DotNetServerPluginTemplate): Observable<DotNetServerPluginTemplate> {
        return DotNetServerPluginService.processSave(serverPluginTemplate, this.serverPluginTemplateDao, this.serverPluginTemplates, this.serverPluginTemplateSubs);
    }

    getServerPluginFromId(serverPluginId: number): DotNetServerPlugin {
        return this.serverPlugins.find(o => o.id === serverPluginId);
    }

    saveServerPluginFile(serverPluginFile: DotNetServerPluginFile): Observable<DotNetServerPluginFile> {
        return DotNetServerPluginService.processSave(serverPluginFile, this.serverPluginFileDao, this.serverPluginFiles, this.serverPluginFileSubs);
    }

    deleteFromList(id: number, list: any[]): any[] {
        const index = list.findIndex((o) => o.id === id);
        if (index > -1) {
            list.splice(index, 1);
        }
        return list;
    }
}

import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {GatewayServerDaoService} from '../gateway-server-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerGroup, GatewayServer, GatewayServerInUse} from '../../../models';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
    selector: 'app-gateway-server-show-all',
    templateUrl: './gateway-server-show-all.component.html',
    styleUrls: ['./gateway-server-show-all.component.scss']
})
export class GatewayServerShowAllComponent extends ComponentCleaner implements AfterViewInit {


    @ViewChild('allSelected') private allSelected: MatOption;

    customerGroupList: CustomerGroup[] = [];
    gatewayServerList: GatewayServer[] = [];
    partnerCustomerGroups: CustomerGroup[] = [];
    gatewayServerFilteredList: GatewayServer[] = [];
    gatewayServerInUseList: GatewayServerInUse[] = [];
    partnerCustomerGroupControl: FormControl = new FormControl();
    gatewayServerInUseControl: FormControl = new FormControl();

    constructor(
        protected customerGroupService: CustomerGroupService,
        protected customerGroupDaoService: CustomerGroupDaoService,
        private router: Router,
        private gatewayServerDaoService: GatewayServerDaoService,
        public dialogRef: MatDialogRef<GatewayServerShowAllComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
    }

    protected readonly faCircle = faCircle;

    ngAfterViewInit(): void {
        combineLatest([
            this.customerGroupDaoService.getAllFromDao(),
            this.gatewayServerDaoService.get(),
        ]).subscribe(values => {
            this.customerGroupList = values[0];
            const gatewayServerList = values[1];
            const gatewayServerIds = gatewayServerList.map(gatewayServer => gatewayServer.id);
            this.gatewayServerList = this.sortByBooleanField(gatewayServerList, 'online');
            this.gatewayServerDaoService.findWhereGatewayServerInUseByIds(gatewayServerIds).subscribe(gatewayServerInUse => {
                this.gatewayServerInUseList = gatewayServerInUse;
            });
            this.gatewayServerList.forEach(gatewayServer => {
                const partnerCustomerGroup = this.customerGroupList.find(c => c.id === gatewayServer.customerGroupId);
                if (partnerCustomerGroup?.partnerCustomerGroupId) {
                    const partnerExists = this.partnerCustomerGroups.find(partner => partner.id === partnerCustomerGroup?.partnerCustomerGroupId);
                    if (!partnerExists) {
                        this.partnerCustomerGroups.push(this.customerGroupList.find(g => g.id === partnerCustomerGroup?.partnerCustomerGroupId));
                    }
                }
            });
            this.partnerCustomerGroupControl.setValue(0);
            this.gatewayServerInUseControl.setValue('ALL');
        });
        this.partnerCustomerGroupControl.valueChanges.subscribe((customerGroupIds: number[]) => {
            this.changeGatewayServerFilteredList(customerGroupIds, this.gatewayServerInUseControl.value);
        });
        this.gatewayServerInUseControl.valueChanges.subscribe((inUse: string) => {
            this.changeGatewayServerFilteredList(this.partnerCustomerGroupControl.value, inUse);
        });
    }

    private changeGatewayServerFilteredList(customerGroupIds: number[], gatewayServerInUseValue: string): void {
        this.gatewayServerFilteredList = [];
        if (customerGroupIds) {
            this.gatewayServerList.forEach((gatewayServerIn) => {
                const customerGroup = this.customerGroupList.find(cg => cg.id === gatewayServerIn.customerGroupId);
                if (gatewayServerIn && customerGroupIds.indexOf(customerGroup?.partnerCustomerGroupId) > -1 ||
                    (customerGroupIds.indexOf(-2) > -1 && !customerGroup.partnerCustomerGroupId) ||
                    customerGroupIds.indexOf(0) > -1) {
                    this.gatewayServerFilteredList.push(gatewayServerIn);
                }
            });
        } else {
            this.gatewayServerFilteredList.push(...this.gatewayServerList);
        }
        const gatewayServerFilteredList2: GatewayServer[] = [];
        if (gatewayServerInUseValue === 'WITH_NAT') {
            gatewayServerFilteredList2.push(...this.gatewayServerFilteredList.filter(gatewayServer => this.findGatewayServerInUseByGatewayServerId(gatewayServer.id)?.length > 0));
        } else if (gatewayServerInUseValue === 'WITHOUT_NAT') {
            gatewayServerFilteredList2.push(...this.gatewayServerFilteredList.filter(gatewayServer => this.findGatewayServerInUseByGatewayServerId(gatewayServer.id)?.length < 1));
        } else{
            gatewayServerFilteredList2.push(...this.gatewayServerFilteredList);
        }
        this.gatewayServerFilteredList = gatewayServerFilteredList2;
    }

    private sortByBooleanField(items: GatewayServer[], booleanField: string): GatewayServer[] {
        return items.sort((a, b) => {
            if (a[booleanField] && !b[booleanField]) {
                return -1;
            } else if (!a[booleanField] && b[booleanField]) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    tosslePerOne(): void {
         if (this.allSelected.selected) {
             this.allSelected.deselect();
         }
    }

    toggleAllSelection(): void {
        if (this.allSelected.selected) {
            const partnerCustomerGroups: CustomerGroup[] = [];
            this.partnerCustomerGroups.forEach(val => partnerCustomerGroups.push(Object.assign({}, val)));
            partnerCustomerGroups.push(this.customerGroupService.initCustomerGroups());
            this.partnerCustomerGroupControl
                .patchValue([...partnerCustomerGroups.map(item => item.id), 0]);

        } else {
            this.partnerCustomerGroupControl.patchValue([]);
        }
    }

    findGatewayServerInUseByGatewayServerId(gatewayServerId: number): GatewayServerInUse[] {
        return this.gatewayServerInUseList.filter(x => x.gatewayServerId === gatewayServerId);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    changeCustomerGroupGatewayServerList(customerGroup: CustomerGroup): void {
        if (customerGroup && this.customerGroupService.customerGroup !== customerGroup) {
            this.customerGroupService.set(customerGroup);
            this.router.navigate(['gateway-server']).then(() => {
                this.dialogRef.close();
            });
        }
    }

    countBooleanField(items: GatewayServer[], booleanField: string, value: boolean): number {
        return items.reduce((count, item) => {
            if (item[booleanField] === value) {
                return count + 1;
            } else {
                return count;
            }
        }, 0);
    }
}

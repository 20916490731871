import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {ADDomain, ADUser, CustomerGroup, ManagerUser, SnapshotType, SubProject} from '../../../models';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {StatusService} from '../status-service';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-ad-user-snapshots',
    templateUrl: './ad-user-snapshots.component.html',
    styleUrls: ['./ad-user-snapshots.component.scss', '../status.component.scss']
})
export class AdUserSnapshotsComponent {

    public snapshots: JaversSnapshot<ADUser>[];

    @Input()
    adDomains: ADDomain[] = [];

    @Input()
    managerUsers: ManagerUser[] = [];

    @Input()
    customerGroups: CustomerGroup[] = [];

    @Input()
    subProjects: SubProject[] = [];

    @Input()
    adUsers: ADUser[];


    @Output()
    adUsersRead: EventEmitter<ADUser[]> = new EventEmitter();


    snapshotTypeControl: FormControl = new FormControl();
    sizeListControl: FormControl = new FormControl();
    snapshotType = SnapshotType;
    loading = false;


    constructor(private adUserDaoService: AdUserDaoService, public statusServer: StatusService, private dialog: MatDialog) {
        this.sizeListControl.setValue(5);
        this.snapshotTypeControl.setValue(SnapshotType.ALL);
    }

    private snapshotsByTypeAndLimit(size: number, snapshotType: SnapshotType = SnapshotType.ALL): void {
        this.loading = true;
        this.snapshots = [];
        this.adUserDaoService.snapshotsByTypeAndLimit(size, snapshotType).subscribe((subProjectsSnapshots) => {
            this.snapshots = subProjectsSnapshots;
            this.loading = false;
        });
    }

    refresh(): void {
        this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    onJaversHistory(id: number): void {
        EntityHistoryComponent.openHistory(id, this.adUserDaoService, this.dialog);
    }
}

<div fxLayout="column" class="mt-26 box-admin">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Usuários</div>
                <div *ngIf="tabIndex === 0">
                    <button
                        *appIfPermission="appCanCreateAdUser"
                        type="button"
                        mat-raised-button color="accent" [matMenuTriggerFor]="menu" >Criar Usuário
                    </button>
                    <mat-menu #menu="matMenu" style="max-width: 372px" >
                        <button mat-menu-item (click)="openUserCreateModal()">
                            <mat-icon>person_add</mat-icon>
                            <span>Usuário individual</span>
                        </button>
                        <button mat-menu-item (click)="openBatchUserCreateModal()">
                            <mat-icon>group_add</mat-icon>
                            <span>Usuário individual em lote</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="openUserPoolCreateModal()"  *appIfPermission="appCanCreateADUserPoolRelationship">
                            <mat-icon>person_add</mat-icon>
                            <span>Usuário do Pool</span>
                        </button>
                    </mat-menu>
                </div>
                <div *ngIf="tabIndex === 1 && adminService.subProject.id">
                    <button *appIfPermission="appCanCreateAdministrator"  [matMenuTriggerFor]="menu2"
                            type="button"
                            mat-raised-button color="accent">Criar Administrador
                    </button>
                    <mat-menu #menu2="matMenu" style="max-width: 372px" >
                        <button mat-menu-item (click)="openUserAdministratorCreateModal()">
                            <mat-icon>person_add</mat-icon>
                            <span>Criar Administrador</span>
                        </button>
                        <button mat-menu-item (click)="openBatchUserAdministratorCreateModal()">
                            <mat-icon>group_add</mat-icon>
                            <span>Criar Administrador em lote</span>
                        </button>
                    </mat-menu>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="column mt-4">
                <mat-tab-group (selectedTabChange)="onLinkClick($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon">person</mat-icon>
                            <span class="ml-10">
                                Usuários de Aplicativos
                            <span *ngIf="adminService.adUsers || adminService.findADUsersPoolRelationshipByADUserPoolService(false)">({{(adminService.adUsers.length + adminService.findADUsersPoolRelationshipByADUserPoolService(false).length)}})</span>
                            </span>
                        </ng-template>
                        <div class="content-tab">

                            <table *ngIf="(adminService.adUsers && adminService.adUsers.length > 0) || (adminService.findADUsersPoolRelationshipByADUserPoolService(false) && adminService.findADUsersPoolRelationshipByADUserPoolService(false).length > 0)"
                                   class="remote-app-table" border="0" cellspacing="0">
                                <thead>
                                <tr class="bg-white">
                                    <th class="icons-pool-td"></th>
                                    <th>E-mail</th>
                                    <!--                                <th>Nome no AD</th>-->
                                    <th></th>
                                    <th>Domínio</th>
                                    <th>Grupos</th>
                                    <th class="text-align-center">Status</th>
                                    <th class="text-align-center p-0"></th>
                                </tr>
                                </thead>

                                <tr *ngFor="let adUserPoolRelationship of adminService.findADUsersPoolRelationshipByADUserPoolService(false)  | sort: 'managerUserId'">
                                    <td class="icons-pool-td">
                                        <mat-icon title="Usuário do Pool" class="mat-icon material-icons" role="img"
                                                  aria-hidden="true">supervisor_account
                                        </mat-icon>
                                    </td>
                                    <td title="{{adminService.getManagerUser(adUserPoolRelationship.managerUserId)?.email}}">
                                        <div class="text-max-250 text-email"
                                             *ngIf="adminService.getManagerUser(adUserPoolRelationship.managerUserId)?.email;else cantAccess">
                                            {{adminService.getManagerUser(adUserPoolRelationship.managerUserId)?.email}}
                                        </div>
                                        <ng-template #cantAccess >sem permissão |{{adUserPoolRelationship.id}} - {{adUserPoolRelationship.managerUserId}} |
                                            {{adminService.getManagerUser(adUserPoolRelationship.managerUserId)?.email}}</ng-template>
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        <div
                                            class="mat-caption">{{adminService.getAdDomain(adminService.getAdUserPool(adUserPoolRelationship.adUserPoolId).adDomainId)?.name}}</div>
                                    </td>
                                    <td>
                                        <div *ngFor="let groupId of adUserPoolRelationship?.adGroupIds">
                                            {{adminService.getAdGroup(groupId)?.name}}
                                        </div>
                                    </td>
                                    <td class="text-align-center" style="padding: 0;">

                                    </td>
                                    <td class="text-align-right p-0 pr-2 ">
                                        <button type="button" mat-raised-button class="mat-green-600-bg" *appIfPermission="'WRITE'; data: adUserPoolRelationship; type: 'ADUserPoolRelationship'"
                                                (click)="openUserPoolEditModal(adUserPoolRelationship)" >Edit
                                        </button>
                                    </td>
                                </tr>

                                <tr *ngFor="let adUser of adminService.adUsers  | sort: 'managerUserId'">
                                    <td class="icons-pool-td"></td>
                                    <td title="{{adminService.getManagerUser(adUser.managerUserId)?.email}}">
                                        <div class="text-max-250 text-email"
                                             *ngIf="adminService.getManagerUser(adUser.managerUserId)?.email;else cantAccess">
                                            {{adminService.getManagerUser(adUser.managerUserId)?.email}}
                                        </div>
                                        <ng-template #cantAccess >sem permissão</ng-template>
                                    </td>
                                    <td>
                                        <div *ngIf="adminService.getADAdminIfADUserIdHasADAdmin(adUser.id)">
                                            <b class="accent-A700-fg"
                                               *ngIf="adminService.getADAdminIfADUserIdHasADAdmin(adUser.id).changing">alterando...</b>
                                            <b class="red-A700-fg"
                                               *ngIf="adminService.getADAdminIfADUserIdHasADAdmin(adUser.id).deleting">deletando...</b>
                                            <b *ngIf="(!(adminService.getADAdminIfADUserIdHasADAdmin(adUser.id).deleting ||
                                            adminService.getADAdminIfADUserIdHasADAdmin(adUser.id).changing))"
                                               style="cursor: help"
                                               title="Esse ADUser pode acessar os servidores usando seu usuário do Active Director">AD
                                                Admin</b>
                                        </div>

                                    </td>
                                    <td>
                                        <div
                                            class="mat-caption">{{adminService.getAdDomain(adUser.adDomainId)?.name}}</div>
                                    </td>
                                    <td>
                                        <div *ngFor="let groupId of adUser?.adGroupIds">
                                            {{adminService.getAdGroup(groupId)?.name}}
                                        </div>
                                    </td>
                                    <td class="text-align-center" style="padding: 0;">
                                        <div *ngIf="adUser.changing || adUser.deleting">
                                            <mat-icon *ngIf="!theDateIsGreaterThanNowPlusMinutes(adUser.updatedAt,15)"
                                                      title="Criando... {{adUser.updatedAt}}">add_circle
                                            </mat-icon>
                                            <mat-icon class="red-A700-fg"
                                                      *ngIf="theDateIsGreaterThanNowPlusMinutes(adUser.updatedAt,15)"
                                                      title="Algo errado, está demorando muito para executar a tarefa. {{adUser.updatedAt}}">
                                                error
                                            </mat-icon>
                                        </div>
                                    </td>
                                    <td class="text-align-right p-0 pr-2 ">
                                        <div *ngIf="adminService.getManagerUser(adUser.managerUserId)?.email">
                                            <button *appIfPermission="'WRITE'; data: adUser; type: 'ADUser'"
                                                    type="button"
                                                    mat-raised-button class="mat-green-600-bg"
                                                    (click)="openUserEditModal(adUser)">Edit
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </div>

                    </mat-tab>
                    <ng-container *ngIf="adminService.subProject.id">
                        <mat-tab *appIfPermission="'WRITE'; data: adminService.subProject; type: 'SubProject'">
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon">supervisor_account</mat-icon>
                                <span class="ml-10">
                                Usuários de Administração
                                <span
                                    *ngIf="adminService.roleSubProjectManagerUsers">({{adminService.roleSubProjectManagerUsers.length}}
                                    )</span>
                            </span>
                            </ng-template>
                            <div class="content-tab">
                                <table
                                    *ngIf="adminService.roleSubProjectManagerUsers && adminService.roleSubProjectManagerUsers.length > 0"
                                    class="remote-app-table" border="0" cellspacing="0">
                                    <thead>
                                    <tr class="bg-white">
                                        <th>E-mail</th>
                                        <th>Papel</th>
                                        <th class="p-0 text-align-center" style="width: 90px;"></th>
                                    </tr>
                                    </thead>
                                    <tr *ngFor="let roleSubProjectManagerUser of adminService.roleSubProjectManagerUsers">
                                        <td>
                                            {{adminService.getManagerUserAdministrator(roleSubProjectManagerUser.managerUserId)?.email}}
                                        </td>
                                        <td>
                                            {{adminService.getRole(roleSubProjectManagerUser.roleId)?.name}}
                                        </td>
                                        <td class="text-align-center p-0 pr-2">
                                            <div *ngIf="adminService.subProject.id">
                                                <button
                                                    *appIfPermission="'WRITE'; data: adminService.subProject; type: 'SubProject'"
                                                    type="button"
                                                    mat-raised-button class="mat-green-600-bg"
                                                    (click)="openUserAdministratorEditModal(roleSubProjectManagerUser)">
                                                    Edit
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>
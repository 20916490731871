import {TranslateService} from '@ngx-translate/core';

declare const Module;

export class PlatformInfo {
    isMac: boolean;
    isFirefox: boolean;
    isChrome: boolean;
    isIE: boolean;
    isSafari: boolean;
    clientVersion: string;
    clientTarget: string;
    clientOS: string;
    deviceInfo: any;


    constructor(private a: any, private b: any, private c: TranslateService) {
        const e = a.getResultObject();
        this.isMac = navigator.platform.indexOf('Mac') > -1,
            this.isFirefox = void 0 !== b.mozInnerScreenX,
            this.isChrome = 'Chrome' === e.browser.name,
            this.isIE = 'IE' === e.browser.name,
            this.isSafari = 'Safari' === e.browser.name,
            this.clientVersion = '1.0.18',
            this.clientTarget = 'Live',
            this.clientOS = navigator.platform,
            this.deviceInfo = a;
    }

    isTouchDevice(): any {
        return 'ontouchstart' in this.b || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }

    getWindowWidth(): any {
        return this.b.innerWidth;
    }

    getWindowHeight(): any {
        return this.b.innerHeight;
    }

    getWindowScale(): any {
        return this.b.devicePixelRatio;
    }

    getLocale(): any {
        // return this.c.use();
        return 'pt-BR';
    }

    isHardwareModeEnabled(): any {
        return Module.IsWebGLSupported();
    }


}

import {Component, Input, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {CustomPortalTemplateDaoService} from './custom-portal-template-dao.service';
import {BackgroundImageTemplateEditComponent} from '../background-image-template-list/background-image-template-edit/background-image-template-edit.component';
import {CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {ComponentCleaner} from '../../../component-cleaner';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CustomPortalEditComponent} from './custom-portal-edit/custom-portal-edit.component';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ManagerUserPermissionsDaoService} from '../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';
import {CustomerGroup} from '../../../models';

@Component({
    selector: 'app-custom-portal-list',
    templateUrl: './custom-portal-list.component.html',
    styleUrls: ['./custom-portal-list.component.scss']
})
export class CustomPortalListComponent extends ComponentCleaner {

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private _customPortalTemplateList: CustomPortalTemplate[] = [];

    get customPortalTemplateList(): CustomPortalTemplate[] {
        return this._customPortalTemplateList;
    }

    @Input()
    set customPortalTemplateList(val: CustomPortalTemplate[]) {
        this._customPortalTemplateList = val;
        this.reloadTable();
    }

    @Input()
    isAdmin = false;

    @Input()
    customerGroup: CustomerGroup;


    myForm: FormGroup;
    imageFileElement: any;
    customPortalTemplateDataSource = new MatTableDataSource<CustomPortalTemplate>();
    customPortalTemplateDetailsTable: string[] = ['logoId', 'name', 'action'];


    constructor(
        private customPortalTemplateDaoService: CustomPortalTemplateDaoService,
        private dialogRef: MatDialogRef<BackgroundImageTemplateEditComponent>,
        private fb: FormBuilder,
        private customerGroupDaoService: CustomerGroupDaoService,
        public imageFileService: ImageFileService,
        private userAuthorityDaoService: UserAuthorityDaoService,
        private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService,
        private dialog: MatDialog
    ) {
        super();

    }


    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.myForm);
        }
    }


    reloadTable(): void {
        this.customPortalTemplateDataSource = new MatTableDataSource(this.customPortalTemplateList);
        this.customPortalTemplateDataSource.sort = this.sort;
    }


    onCreate(customPortalTemplate: CustomPortalTemplate | null): void {
        const dialogRef = this.dialog.open(CustomPortalEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                customPortalTemplate: customPortalTemplate,
                customerGroup: this.customerGroup
            }
        });
        this.addSubscription(
            dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
                if (result?.data) {
                    const index = this.customPortalTemplateList.findIndex((y) => y.id === result.data.id);
                    if (result && result.operation === 'CREATE') {
                        this.customPortalTemplateList.push(result.data);
                    } else if (result && result.operation === 'SAVE') {
                        this.customPortalTemplateList[index] = result.data;

                    } else if (result && result.operation === 'DELETE') {
                        this.customPortalTemplateList.splice(index, 1);
                    }
                    this.reloadTable();
                }
            }));
    }

    onDuplicate(customPortalTemplate: CustomPortalTemplate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja duplicar esse tema? ' +
                    '<br><h2>' + customPortalTemplate.name + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'Duplicar',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const customPortalTemplateSend: CustomPortalTemplate = JSON.parse(JSON.stringify(customPortalTemplate));
                customPortalTemplateSend.creatorCustomerGroupId = this.customerGroup.id;
                this.customPortalTemplateDaoService.duplicate(customPortalTemplate).subscribe((duplicated) => {
                    this.customPortalTemplateList.push(duplicated);
                    this.reloadTable();
                });
            }
        });
    }

    onDelete(customPortalTemplate: CustomPortalTemplate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse tema? ' +
                    '<br><h2>' + customPortalTemplate.name + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'Remover',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.customPortalTemplateDaoService.delete(customPortalTemplate.id).subscribe((removed) => {
                    const index = this.customPortalTemplateList.findIndex((y) => y.id === customPortalTemplate.id);
                    if (index >= 0) {
                        this.customPortalTemplateList.splice(index, 1);
                        this.reloadTable();
                    }
                });
            }
        });
    }
}

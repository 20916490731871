<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Chat AI - Grupos de usuários</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div matDialogContent>
        <div fxLayout="row" class="p-15" fxLayoutAlign="start stretch">
            <mat-card class="ai-user">
                <mat-card-header>
                    <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Usuários</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table cellspacing="0" cellpadding="0" class="width-100-percent">
                        <tr *ngFor="let managerUser of managerUsers">
                            <td style="width: 50px;" class="pl-10">
                                <img class="avatar"
                                     src="{{imageFileService.getManagerUserPicture(getManagerUser(managerUser.id))}}?w=150&square=true">
                            </td>
                            <td class="p-10">
                                <div><b>{{ getManagerUser(managerUser.id).displayName }}</b></div>
                                <div class="font-size-12">{{ getManagerUser(managerUser.id).email }}</div>
                                <div class="mt-5">
                                    <span class="ai-group">Todos </span>
                                    <span class="ai-group ml-5"
                                        *ngFor="let aiUserGroup of getAiUserGroupByManagerUserId(managerUser.id)">{{ aiUserGroup.name }}</span>

                                </div>
                            </td>
                            <td class="p-10" style="width: 88px;">
                                <button type="button" mat-raised-button color="primary"
                                        (click)="aiUserEdit(managerUser)"
                                >Editar
                                </button>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>
            <mat-card class="ai-user-group">
                <mat-card-header>
                    <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Grupos</div>
                        <button type="button" mat-raised-button color="accent" (click)="createAiUserGroup()">Criar
                        </button>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table cellspacing="0" cellpadding="0" class="width-100-percent">
                        <tr>
                            <td style="padding: 28px 20px 28px 20px;">Todos</td>
                            <td class="p-20" style="width: 88px">
                                <button type="button" mat-raised-button color="primary" *ngIf="!hasDefaultGroup()"
                                        (click)="createDefaultAiUserGroup()"
                                >Criar</button>
                                <div *appIsAdmin>
                                    <button type="button" mat-raised-button color="warn" *ngIf="hasDefaultGroup()"
                                            (click)="deleteDefaultAiUserGroup()"
                                    >Deletar</button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let aiUserGroup of getAiUserGroupEditable()">
                            <td class="p-20">{{ aiUserGroup.name }}</td>
                            <td style="width: 88px;padding: 20px 10px 20px 20px !important">
                                <button type="button" mat-raised-button color="primary" *ngIf="!aiUserGroup.default"
                                        (click)="aiUserGroupEdit(aiUserGroup)"
                                >Editar
                                </button>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Fechar</button>
    </div>
</div>

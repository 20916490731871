export class ConnectionTelemetry {
    timeLaunched = null;
    timeConnected = null;
    timeDisconnected = null;
    initialWidth = 0;
    initialHeight = 0;
    initialScale = 0;
    arcAttempts = 0;
    arcSuccess = 0;
    totalArcTime = 0;
    resolutionChanged = 0;
    clipboardUsed = !1;
    audioUsed = !1;
    sessionSwitchedCount = 0;
    appSwitchedCount = 0;
}

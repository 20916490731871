<div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
        <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
            Escolha o tipo de edição
        </mat-toolbar>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around start" fxFill class="p-10 grey-50-bg border-top"
         style="padding-top: 50px !important;">
        <div *ngIf="!adminService.isExternalManagerUser(managerUser)" style="min-width: 50px; ">
            <div>
                <img src="{{imageFileService.getManagerUserPicture(managerUser)}}?w=150&square=true"
                     width="150" height="150">
            </div>
            <div>
                <div class="text-max-150 font-size-16 mb-5 mt-10" *ngIf="managerUser?.displayName">
                    <b>{{managerUser?.displayName}}</b></div>
            </div>
            <div>
                <div class="text-max-150 font-size-12" *ngIf="managerUser?.email">{{managerUser?.email}}</div>
            </div>
            <div>
                <button style="width: 150px" class="mt-10" mat-raised-button color="accent"
                        id="role-customer-group-or-manager-user-edit-edit"
                        (click)="onEditManagerUser(managerUser)">Editar contatos
                </button>
            </div>
            <div *appIfPermission="appCanCreateList">
                <button style="width: 150px" class="mt-10" mat-raised-button color="accent"
                        (click)="onChangePassword(managerUser)">Editar Senha
                </button>
            </div>
            <div *appIfPermission="appCanCreateList">
                <button style="width: 150px" class="mt-10" mat-raised-button color="accent"
                        (click)="onForgotPassword(managerUser)">Recuperar senha
                </button>
            </div>
        </div>
        <div style="width: 1px;background-color: #eeeeee;min-height: 250px"
             *ngIf="!adminService.isExternalManagerUser(managerUser)"></div>
        <div style="min-width: 50px;" *ngIf="loggedManagerUser.id !== managerUser.id || isAdmin;else noneUser">
            <div *appIfPermission="appCanEditRoleList">
                <div><b class="font-size-18 mb-10">Papel</b></div>
                <table class="full-width">
                    <tr *ngFor="let rolesOfCustomerGroup of getAllRoleCustomerGroupManagerUsers(managerUser.id);">
                        <td class="padding-none">{{adminService.getRole(rolesOfCustomerGroup.roleId).name}}</td>
                        <td style="width: 74px;" class="padding-none">
                            <button mat-button color="accent"
                                    (click)="onEditRoleCustomerGroupManagerUserWithRole(managerUser, rolesOfCustomerGroup)">
                                Editar
                            </button>
                        </td>
                    </tr>
                </table>
                <div style="margin-top: 40px;margin-bottom: 40px;text-align: center;">
                    <button mat-raised-button color="accent"
                            id="role-customer-group-or-manager-user-edit-add-role"
                            (click)="onAddRoleToTheManagerUser(managerUser)">
                        Adicionar Papel
                    </button>
                </div>
            </div>
        </div>
        <ng-template #noneUser>
            <div style="width: 200px;display: block;height: 10px;"></div>
        </ng-template>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center"
         fxFill class="p-16 grey-50-bg border-top">
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

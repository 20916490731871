import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CustomPortalTemplate} from '../../models-custom-layout';
import {Observable} from 'rxjs';
import {CustomerGroupTemplateDaoService} from './customer-group-template/customer-group-template-dao.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomLayoutService {
    private customPortalTemplate$: Observable<CustomPortalTemplate> = null;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private customerGroupTemplateDaoService: CustomerGroupTemplateDaoService,
    ) {

    }
    getDefaultCustomerGroupTemplate$(): Observable<CustomPortalTemplate> {
        if (this.customPortalTemplate$) {
            return this.customPortalTemplate$;
        }
        this.customPortalTemplate$ = this.customerGroupTemplateDaoService.getDefaultCustomerGroupTemplate().pipe(
            map(customerGroupTemplate => customerGroupTemplate.customPortalTemplate)
        );
        return this.customPortalTemplate$;
    }

    setDynamicStyle(css: string): void {
        if (css) {
            const head = this.document.getElementsByTagName('head')[0];
            const style = this.document.createElement('style');
            style.setAttribute('id', 'custom-layout');
            style.appendChild(document.createTextNode(css));
            head.appendChild(style);
        }
    }

    removeAllCustomStyle(): void {
        const elementCustomLayout = this.document.getElementById('custom-layout');
        if (elementCustomLayout) {
            elementCustomLayout.remove();
        }
    }
}

export function initCustomPortalTemplate(): CustomPortalTemplate {
    return {
        id: null,
        name: null,
        title: 'R2 Cloud',
        helpLink: null,
        creatorCustomerGroupId: null,
        public: null,
        logoId: null,
        hasCustomProtocol: null,
        customProtocol: null,
        installUrl: null,
        customProtocolSoftwareName: null,
        css: null,
        createdAt: null,
        updatedAt: null
    };
}

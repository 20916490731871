import {Component, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {CustomerGroupDaoService} from '../customer-group-dao.service';

import {CustomerCreateEditComponent} from '../../customer/customer-create-edit/customer-create-edit.component';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {Observable, of} from 'rxjs';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {CustomerGroup, ManagerUser, SoftwareCompany, TextFile} from '../../../models';
import {ImageFileService} from '../../image-file/image-file-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {MatSelect} from '@angular/material/select';
import {Subject} from 'rxjs/internal/Subject';
import {SoftwareCompanyDaoService} from '../../marketplace/software-company-create-and-edit/software-company.dao.service';
import {SoftwareCompanyService} from '../../marketplace/software-company-create-and-edit/software-company.service';
import {SoftwareCompanyCreateAndEditComponent} from '../../marketplace/software-company-create-and-edit/software-company-create-and-edit.component';
import {Router} from '@angular/router';
import {BackgroundImageTemplate, CustomPortalTemplate} from '../../../models-custom-layout';
import {CustomPortalTemplateDaoService} from '../../custom-layout/custom-portal-list/custom-portal-template-dao.service';
import {BackgroundImageTemplateDaoService} from '../../custom-layout/background-image-template-list/background-image-template-dao.service';
import {TextFileDaoService} from '../../textFile/text-file-dao-service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ValueWrapper} from '../../../helpers/value-wrapper';
import {CustomerGroupService} from '../customer-group.service';
import {ManagerUserPermissionsDaoService} from '../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {CustomerDaoService} from '../../customer/customer-dao.service';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-customer-group-create-edit',
    templateUrl: './customer-group-create-edit.component.html',
    styleUrls: ['../../../styles/tabs-search-blue-top.scss', './customer-group-create-edit.component.scss']
})
export class CustomerGroupCreateEditComponent extends ComponentCleaner {
    customerGroupForm: FormGroup;
    customerGroup: CustomerGroup;
    htmlFile: TextFile;
    htmlCtrl: FormControl = new FormControl();
    imageFileElement: any = null;
    isAdmin = false;
    managerUser: ManagerUser = null;
    customerGroupOfLoggedUser: CustomerGroup = null;
    softwareCompanyList: SoftwareCompany[] = [];
    customPortalTemplateList: CustomPortalTemplate[] = [];
    backgroundImageTemplateList: BackgroundImageTemplate[] = [];

    softwareCompanyMultiFilterCtrl: FormControl = new FormControl();
    filteredSoftwareCompanyMulti: ReplaySubject<SoftwareCompany[]> = new ReplaySubject<SoftwareCompany[]>(1);
    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;
    protected _onDestroy = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<CustomerCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private router: Router,
                private customerGroupDaoService: CustomerGroupDaoService,
                private customerDaoService: CustomerDaoService,
                public customerGroupService: CustomerGroupService,
                public userAuthorityDaoService: UserAuthorityDaoService,
                private softwareCompanyDaoService: SoftwareCompanyDaoService,
                private customPortalTemplateDaoService: CustomPortalTemplateDaoService,
                private backgroundImageTemplateDaoService: BackgroundImageTemplateDaoService,
                public softwareCompanyService: SoftwareCompanyService,
                public managerUserPermissionsDao: ManagerUserPermissionsDaoService,
                public imageFileService: ImageFileService,
                public textFileDaoService: TextFileDaoService) {
        super();
        this.customerGroup = data.customerGroup;
        if (!this.customerGroup) {
            this.customerGroup = {
                id: null,
                comment: null,
                active: true,
                customerIds: [],
                managerGroupIds: [],
                softwareCompanyIds: [],
                name: null,
                subProjectIds: [],
                contractedUsers: 3,
                imageFileId: null,
                customPortalTemplateId: null,
                backgroundImageTemplateId: null,
                htmlId: null,
                url: null,
                modified: null,
                optlock: null,
                imageUUID: null,
                partnerCustomerGroupId: null,
                exceededMaximumUsersMessage: null,
                blockAllAccess: null,
                blockAllAccessMessage: null,
                localAdminUsersOnly: null,
            };
        }
        let customerGroup$ = of(this.customerGroup);
        if (this.customerGroup.id) {
            customerGroup$ = this.customerGroupDaoService.getOne(this.customerGroup.id);
        }
        combineLatest([
            this.userAuthorityDaoService.getMe(),
            this.managerUserPermissionsDao.getMyUserPermissions(),
            customerGroup$]).subscribe(values => {
            this.managerUser = values[0].managerUser;
            this.isAdmin = values[1].admin;
            this.customerGroup = values[2];
            this.customerDaoService.getOne(this.managerUser.customerId).subscribe((customer) => {
                if (customer) {
                    this.softwareCompanyDaoService.get().subscribe(softwareCompanyList => {
                        if (softwareCompanyList) {
                            this.softwareCompanyList = softwareCompanyList;
                            this.filteredSoftwareCompanyMulti.next(this.softwareCompanyList.slice());
                            this.softwareCompanyMultiFilterCtrl.valueChanges
                                .pipe(takeUntil(this._onDestroy))
                                .subscribe(() => {
                                    this.filterBanksMulti();
                                });

                            this.findAllCustomPortalTemplate();
                            this.findAllBackgroundImageTemplate();
                            this.findCustomerGroupHtml(this.customerGroup.htmlId);
                        }
                    });
                    this.customerGroupOfLoggedUser = this.customerGroupService.customerGroups.find(c => c.id === customer.customerGroupId);
                    this.setForm(this.customerGroup);
                }
            });
        });

        this.htmlCtrl.valueChanges.subscribe(_ => {
            markAsTouched(this.customerGroupForm);
        });
        this.customerGroupForm?.get('localAdminUsersOnly').valueChanges.subscribe(_ => {
            markAsTouched(this.customerGroupForm);
        });
    }

    private findCustomerGroupHtml(customerGroupHtmlId: number): void {
        if (customerGroupHtmlId) {
            this.textFileDaoService.getOne(customerGroupHtmlId).subscribe((textFile) => {
                if (textFile) {
                    this.htmlFile = textFile;
                    this.htmlCtrl.setValue(textFile.text);
                }
            });
        }
    }

    private findAllCustomPortalTemplate(): void {
        // this.customPortalTemplateDaoService.get().subscribe((customPortalTemplateList) => {
        this.customPortalTemplateDaoService.findMyList().subscribe((customPortalTemplateList) => {
            if (customPortalTemplateList) {
                this.customPortalTemplateList = customPortalTemplateList;
                this.customPortalTemplateList.unshift({
                    id: null,
                    name: '-',
                    title: null,
                    helpLink: null,
                    creatorCustomerGroupId: null,
                    public: null,
                    logoId: null,
                    css: null,
                    hasCustomProtocol: null,
                    customProtocol: null,
                    installUrl: null,
                    customProtocolSoftwareName: null,
                    createdAt: null,
                    updatedAt: null
                });
            }
        });
    }

    private findAllBackgroundImageTemplate(): void {
        this.backgroundImageTemplateDaoService.findMyList().subscribe((backgroundImageTemplateList) => {
            if (backgroundImageTemplateList) {
                this.backgroundImageTemplateList = backgroundImageTemplateList;
                this.backgroundImageTemplateList.unshift({
                    id: null,
                    name: '-',
                    creatorCustomerGroupId: null,
                    public: null,
                    imageFileTemplateIds: null,
                    createdAt: null,
                    updatedAt: null
                });
            }
        });
    }

    protected filterBanksMulti(): void {
        let search = this.softwareCompanyMultiFilterCtrl.value;
        if (!search) {
            this.filteredSoftwareCompanyMulti.next(this.softwareCompanyList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSoftwareCompanyMulti.next(
            this.softwareCompanyList.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
        );
    }

    private setForm(customerGroup: CustomerGroup): void {
        this.customerGroupForm = this.fb.group(convertToFormGroup(customerGroup), {asyncValidator: this.customerGroupDaoService.validator});
        this.customerGroupForm.setValidators(this.validContractedUsers());
    }

    public validContractedUsers(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const myForm: FormGroup = group.controls['contractedUsers'] as FormGroup;
            const contractedUsers = myForm.value;
            if (contractedUsers) {
                if (isNaN(contractedUsers) || contractedUsers < 0) {
                    const error = {contractedUsers: 'Mínimo de 0 usuários'};
                    myForm.setErrors(error);
                }
            }
            return;
        };
    }

    onSubmit(): void {
        let textFile$: Observable<TextFile> = of(null);
        const htmlFile: string = this.htmlCtrl.value;

        if (htmlFile) {
            this.createTextFileObjectIfNull();
            this.htmlFile.text = htmlFile;
            if (!this.htmlFile.id) {
                textFile$ = this.textFileDaoService.create(this.htmlFile);
            } else {
                textFile$ = this.textFileDaoService.save(this.htmlFile);
            }
        }

        textFile$.pipe(
            switchMap((value) => {
                this.htmlFile = value;
                const customerGroup: CustomerGroup = this.customerGroupForm.value;
                customerGroup.url = this.generateCustomerGroupUrl(customerGroup);
                customerGroup.name = customerGroup.name.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');

                if (this.htmlFile?.id) {
                    customerGroup.htmlId = this.htmlFile.id;
                }

                let customerGroup$: Observable<CustomerGroup>;
                let operation: CrudOperation;
                if (customerGroup?.id) {
                    customerGroup$ = this.customerGroupDaoService.save(customerGroup);
                    operation = 'SAVE';
                } else {
                    if (!this.isAdmin) {
                        customerGroup.partnerCustomerGroupId = this.customerGroupOfLoggedUser.id;
                    }
                    customerGroup$ = this.customerGroupDaoService.create(customerGroup);
                    operation = 'CREATE';
                }
                return forkJoin([of(operation), customerGroup$]);
            }),
            switchMap((result) => {
                const image = this.imageFileElement;
                let imageFile$: Observable<ValueWrapper>;
                const customerGroupFromDao = result[1];
                if (image) {
                    imageFile$ = this.imageFileService.saveCustomerGroupPicture(customerGroupFromDao.id, image);
                } else {
                    imageFile$ = of(null);
                }
                return forkJoin([of(result[0]), of(customerGroupFromDao), imageFile$]);
            }))
            .subscribe((result) => {
                const operation = result[0];
                const customerGroupFromDao = result[1];

                if (this.imageFileElement) {
                    customerGroupFromDao.imageUUID = result[2].value;
                }
                const crudOperation: CrudOperationWrapper = {
                    data: customerGroupFromDao,
                    operation: operation
                };
                this.dialogRef.close(crudOperation);
            });
    }

    createTextFileObjectIfNull(): void {
        if (!this.htmlFile) {
            this.htmlFile = {
                id: null,
                comment: null,
                active: null,
                text: null,
                modified: null,
                optlock: null
            };
        }
    }

    onCancel(): void {
        const crudOperation
            :
            CrudOperationWrapper = {
            operation: 'CANCEL',
            data: null
        };
        this.dialogRef.close(crudOperation);
    }

    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Deseja remover o grupo de clientes ' + this.customerGroupForm.get('name').value + '?'
            }
        }).afterClosed().pipe(
            switchMap((result) => {
                if (result) {
                    return this.customerGroupDaoService.remove(this.customerGroup.id).pipe(map(() => true));
                } else {
                    return of(false);
                }
            })).subscribe((result) => {
            if (result) {
                const crudOperation: CrudOperationWrapper = {
                    operation: 'DELETE',
                    data: this.customerGroup.id
                };
                this.dialogRef.close(crudOperation);
            }
        });
        this.addSubscription(subscription);
    }

    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.customerGroupForm);
        }
    }

    onRemoveImage(): void {
        this.imageFileElement = null;
        this.customerGroup.imageUUID = null;
        this.customerGroupForm.get('imageUUID').setValue(null);
        markAsTouched(this.customerGroupForm);
    }

    onCreateSoftwareCompany(): void {
        const dialogRef = this.dialog.open(SoftwareCompanyCreateAndEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'CREATE') {
                this.softwareCompanyList.push(result.data);
                this.filterBanksMulti();
            }
        });
        this.addSubscription(subscription);
    }

    onEditSoftwareCompany(softwareCompany: SoftwareCompany): void {
        const dialogRef = this.dialog.open(SoftwareCompanyCreateAndEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {
                softwareCompany: softwareCompany
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'SAVE') {
                this.softwareCompanyList = this.softwareCompanyService.update(result.data, this.softwareCompanyList);
                this.filterBanksMulti();
            }
        });
        this.addSubscription(subscription);
    }

    generateCustomerGroupUrl(customerGroup: CustomerGroup): string {
        return (customerGroup.name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-') + '-' + customerGroup.id).replace('--', '-');
    }

    getCurrentDomain(): string {
        return window.location.hostname;
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.customerGroup.id, this.customerGroupDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.customerGroup.id, this.customerGroupDaoService, this.dialog);
    }
}

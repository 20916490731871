import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {HipcomBulkUpdateDetailLog} from '../../../../../models';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';
import {BaseIdStringDao} from '../../../../../base-id-string-dao';

@Injectable()
export class HipcomBulkUpdateDetailLogDaoService extends BaseIdStringDao<HipcomBulkUpdateDetailLog> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'partners-hipcom-bulk-update-detail-log');
    }


    findByDesktopServerId(desktopServerId: number): Observable<HipcomBulkUpdateDetailLog[]> {
        return this.http.get<HipcomBulkUpdateDetailLog[]>(this.url + '/find-by-desktop-server-id/' + desktopServerId);
    }

}

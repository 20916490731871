import {Injectable} from '@angular/core';
import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {DomainPathService} from '../../../kluh-manager/domain-path/domain-path.service';

@Injectable({
    providedIn: 'root'
})
export class FooterService {
    terminalUrl = '';
    terminalTitle = '';

    constructor(
        private _fuseConfigService: FuseConfigService,
        private domainPathService: DomainPathService
    ) {
    }

    openTerminal(accessToken: string, terminalTitle: string): void {
        this.terminalUrl = `${this.domainPathService.terminalURL}access-token?token=${encodeURIComponent(accessToken)}`;
        this.terminalTitle = terminalTitle;
        this.hiddenFuseConfigFooter(false);
    }

    closeTerminal(): void {
        this.hiddenFuseConfigFooter(true);
        this.terminalUrl = null;
        this.terminalTitle = null;
    }

    hiddenFuseConfigFooter(hiddenFooter: boolean): void {
        this._fuseConfigService.config = {
            colorTheme: 'theme-default',
            customScrollbars: false,
            layout: {
                style: 'vertical-layout-1',
                width: 'fullwidth',
                navbar: {
                    primaryBackground: 'fuse-navy-700',
                    secondaryBackground: 'fuse-navy-900',
                    folded: false,
                    hidden: false,
                    position: 'left',
                    variant: 'vertical-style-1'
                },
                toolbar: {
                    customBackgroundColor: true,
                    background: 'fuse-navy-900',
                    hidden: false,
                    position: 'above'
                },
                footer: {
                    customBackgroundColor: true,
                    background: 'fuse-navy-900',
                    hidden: hiddenFooter,
                    position: 'below-fixed'
                },
                sidepanel: {
                    hidden: true,
                    position: 'right'
                }
            }
        };
    }

}

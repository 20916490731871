<form (submit)="onSubmit()" [formGroup]="myForm">

    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                <span *ngIf="everyone">Enviar mensagem <b><u>PARA TODOS OS SERVIDORES:</u></b></span>
                <span *ngIf="!everyone">Enviar mensagem:</span>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent >
        <div *ngIf="customerGroups">
            <div title="Abrir a página de administração do projeto" *ngFor="let customerGroup of customerGroups"
                 fxLayout="row" fxLayoutAlign="start center"
                 class="mb-30 mt-20">
                <div class="mr-8"><img
                    src="{{imageFileService.getCustomerGroupPictureUrl(customerGroup.imageUUID, 34)}}"
                    width="34"
                    height="34">
                </div>
                <div>
                    {{customerGroup.name}}
                </div>
            </div>
        </div>
        <div *ngIf="everyone" style="padding: 25px 25px 25px 0px;">
            <b>A mensagem será enviada para todos os servidores com o plugin da Hipcom.</b>
        </div>


        <div style="padding: 20px 20px 20px 0px;">
            <mat-form-field class="width-100-percent">
                <mat-label>Mensagem</mat-label>
                <textarea rows="4" matInput formControlName="message" name="message" ></textarea>
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="submit" mat-raised-button color="accent" aria-label="Salvar">
            Enviar
        </button>
        <button type="button" mat-raised-button color="warn" aria-label="Cancelar" (click)="onCancel()">
            Cancelar
        </button>
    </div>
</form>


import {Injectable} from '@angular/core';

import {BaseMonitoringDao} from '../../../../../base-monitoring-dao';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';

import {VpnUsage} from '../../../../../models-monitoring';
import {R2CloudHttpMonitoringService} from '../../../../../r2-cloud-http-monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class VpnUsageDaoService extends BaseMonitoringDao<VpnUsage> {
    constructor(http: R2CloudHttpMonitoringService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'vpn-usage');
    }
}

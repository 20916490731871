import {NgModule} from '@angular/core';
import {CustomPortalTemplateDaoService} from './custom-portal-list/custom-portal-template-dao.service';
import {CustomLayoutComponent} from './custom-layout.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {AuthService} from '../../auth.service';
import {BackgroundImageTemplateListComponent} from './background-image-template-list/background-image-template-list.component';
import {CustomPortalListComponent} from './custom-portal-list/custom-portal-list.component';
import {BackgroundImageTemplateEditComponent} from './background-image-template-list/background-image-template-edit/background-image-template-edit.component';
import {CustomPortalEditComponent} from './custom-portal-list/custom-portal-edit/custom-portal-edit.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


const routes = [
    {
        path: 'custom-layout',
        component: CustomLayoutComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.WRITE']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        MatTooltipModule,
        MatSlideToggleModule
    ],
    declarations: [CustomLayoutComponent, BackgroundImageTemplateListComponent, CustomPortalListComponent, BackgroundImageTemplateEditComponent, CustomPortalEditComponent],
    providers: [
        CustomPortalTemplateDaoService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ],
    entryComponents: []
})
export class CustomLayoutModule {
}

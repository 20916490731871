import {Injectable} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {R2CloudAdminService} from '../r2-cloud-admin/r2-cloud-admin.service';
import {RemoteAppDaoService} from './remote-app-dao.service';
import {RemoteAppRelationshipDaoService} from '../remote-app-relationship/remote-app-relationship-dao.service';

@Injectable({
    providedIn: 'root'
})
export class RemoteAppServiceService extends ComponentCleaner {

    constructor(
        public adminService: R2CloudAdminService,
        public remoteAppDaoService: RemoteAppDaoService,
        public remoteAppRelationshipDaoService: RemoteAppRelationshipDaoService,
    ) {
        super();
    }

    loadRemoteAppAndPopulateAdminService(subProjectId: number): void {
        this.remoteAppDaoService.filter({subProjectId: subProjectId}).subscribe((remoteApps) => {
            if (remoteApps) {
                this.adminService.remoteApps = remoteApps;
                const remoteAppIds = remoteApps.map(app => app.id);
                this.loadRemoteAppRelationships(remoteAppIds);
            } else {
                this.adminService.remoteApps = [];
                this.adminService.remoteAppRelationships = [];
            }
        });
    }

    private loadRemoteAppRelationships(remoteAppIds: number[]): void {
        this.remoteAppRelationshipDaoService.findAllByRemoteAppIds(remoteAppIds).subscribe((remoteAppRelationships) => {
            if (remoteAppRelationships) {
                this.adminService.remoteAppRelationships = remoteAppRelationships;
            } else {
                this.adminService.remoteAppRelationships = [];
            }
        });
    }
}

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {checkInvalidChars, checkQuotes, convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {Observable} from 'rxjs';
import {ADDomain, ADUser, ADUserServiceExternal, ADUserWithPassword, ManagerUser, Project, SubProject} from '../../../models';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AdUserServiceExternalDaoService} from '../../ad-user-service-external/ad-user-service-external-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {CustomLayoutService, initCustomPortalTemplate} from '../../custom-layout/custom-layout.service';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-service-user-wizard',
    templateUrl: './service-user-wizard.component.html',
    styleUrls: ['./service-user-wizard.component.scss']
})
export class ServiceUserWizardComponent extends ComponentCleaner {
    adUserExternalLoad: ADUser;
    readonly managerUsers: ManagerUser[];
    private readonly subProject: SubProject;
    private readonly project: Project;
    readonly adDomains: ADDomain[];
    passwordError = false;
    adUser: ADUser;
    customAdUserForm: FormControl;
    adUserPasswordForm: FormControl = new FormControl();
    adUserRepeatPasswordForm: FormControl = new FormControl();
    adUserExternalList: ADUser[] = [];
    adUserServiceExternal: ADUserServiceExternal;
    adUserForm: FormGroup;
    tabIndex = 0;
    private adDomainId;

    adUserPw = '';
    appCanCreateList: PermissionWrapper[];
    defaultCustomPortalTemplate: CustomPortalTemplate = initCustomPortalTemplate();


    constructor(public dialogRef: MatDialogRef<ServiceUserWizardComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private customerGroupService: CustomerGroupService,
                public adminService: R2CloudAdminService,
                private snackBar: MatSnackBar,
                private adUserDaoService: AdUserDaoService,
                private adUserServiceExternalDaoService: AdUserServiceExternalDaoService,
                private customLayoutService: CustomLayoutService,
    ) {
        super();
        this.appCanCreateList = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];

        this.subProject = data.subProject;
        this.project = data.project;
        this.adDomains = data.adDomains;
        this.managerUsers = data.managerUsers;
        if (this.adDomains.length === 1) {
            this.adDomainId = this.adDomains[0].id;
        }
        this.customAdUserForm = this.fb.control(false);
        this.adUserPasswordForm = this.fb.control('', [Validators.required, checkQuotes]);
        this.adUserRepeatPasswordForm = this.fb.control('', [Validators.required, checkQuotes]);
        if (data.adUser) {
            this.adUser = data.adUser;
        } else {
            this.adUser = this.initAdUser(this.adDomainId);
            this.adUser.login = 'servi.' + Math.floor((Math.random() * 99999) + 1);
        }
        if (data.adUserServiceExternal) {
            this.adUserServiceExternal = data.adUserServiceExternal;
            this.addSubscription(this.adUserServiceExternalDaoService.findAllADUserBySubProjectId(this.subProject.id).subscribe((adUserExternalList) => {
                if (adUserExternalList) {
                    this.adUserExternalList = adUserExternalList;
                    this.adUserExternalLoad = this.adUserExternalList.find(x => x.id === data.adUserServiceExternal.adUserId);
                    this.adUser = this.adUserExternalLoad;
                    this.adUser.managerUserServiceIds = data.adUserServiceExternal.managerUserIds;
                }
            }));
        }
        this.adUserForm = this.fb.group(convertToFormGroup(this.adUser));

        this.adUserForm.get('login').setValidators([Validators.required, checkInvalidChars]);

        this.customAdUserForm.valueChanges.subscribe(v => {
            if (!this.adUserForm.get('id').value) {
                if (v) {
                    this.adUser.login = '';
                } else {
                    this.adUser.login = 'servi.' + Math.floor((Math.random() * 99999) + 1);
                }
            }
        });


        this.adUserForm.get('adDomainId').valueChanges.subscribe((value: number) => {
            this.adDomainId = value;
            this.adUser.adDomainId = this.adDomainId;
        });

        this.adUserForm.get('login').valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (value && !this.adUserForm.get('login').errors) {
                this.adUserDaoService.findByLogin(value, this.adUser.adDomainId).subscribe((user) => {
                    if (user) {
                        this.adUserExternalLoad = user;
                    }
                });

            }
        });
        this.addSubscription(this.adUserPasswordForm.valueChanges.subscribe(() => {
            this.checkPassword();
        }));
        this.addSubscription(this.adUserRepeatPasswordForm.valueChanges.subscribe(() => {
            this.checkPassword();
        }));
        this.addSubscription(this.customLayoutService.getDefaultCustomerGroupTemplate$().subscribe(defaultCustomerGroupTemplate => {
            this.defaultCustomPortalTemplate = defaultCustomerGroupTemplate;
        }));
    }

    private checkPassword(): void {
        const password = this.adUserPasswordForm.value;
        const passwordRepeat = this.adUserRepeatPasswordForm.value;
        if (password !== passwordRepeat) {
            const errors: ValidationErrors = {};
            this.adUserRepeatPasswordForm.setErrors(errors);
            this.passwordError = true;
        } else {
            this.adUserRepeatPasswordForm.setErrors(null);
            this.passwordError = false;
        }
    }

    checkPasswords(control: FormControl): ValidationErrors {
        const pass = this?.adUserPasswordForm?.value;
        const confirmPass = control.value;
        return pass === confirmPass ? null : {notSame: true};
    }


    private initAdUser(adDomainId: number): ADUser {
        let subProjectId = null;
        if (this.subProject) {
            subProjectId = this.subProject.id;
        }
        return {
            id: null,
            active: true,
            comment: null,
            adDomainId: adDomainId,
            managerUserId: null,
            serviceUser: true,
            subProjectIds: [subProjectId],
            managerUserServiceIds: [],
            login: null,
            adGroupIds: [],
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
    }

    onSubmit(): void {


        let adUserDB$: Observable<ADUser>;
        const adUser: ADUser = this.adUserForm.value;
        const customAdUser: boolean = this.customAdUserForm.value;
        let adUserPassword: string = null;
        if (customAdUser) {
            adUserPassword = this.adUserPasswordForm.value;
        }


        adUserDB$ = this.adUserDaoService.findByLogin(adUser.login, adUser.adDomainId);


        adUserDB$.subscribe((adUserFromDAO) => {
            if (adUserFromDAO) {
                adUser.id = adUserFromDAO.id;
            }
            const adUserWithPassword: ADUserWithPassword = {
                askThePassword: false,
                adUserPassword: adUserPassword,
                adUserAlreadyExists: customAdUser,
                adUser: adUser,
                subProjectId: this.subProject.id
            };
            if (this.adUserExternalLoad) {
                this.adUserServiceExternalDaoService.findByADUserIdAndSubProjectId(adUser.id, this.subProject.id).subscribe((adUserServiceExternalDB) => {
                    let operation: CrudOperation = 'CREATE';
                    if (adUserServiceExternalDB.adUserId) {
                        operation = 'SAVE';
                    }
                    this.saveOrCreateADUserService(adUserWithPassword, operation);
                });
            } else {
                this.saveOrCreateADUser(adUser.id, adUserWithPassword);
            }
        });
    }

    private saveOrCreateADUserService(adUserWithPassword: ADUserWithPassword, operation: CrudOperation): void {
        this.adUserServiceExternalDaoService.saveAdUserServiceExternalWithCheck(adUserWithPassword).subscribe((adUserServiceExternalSaved) => {
            const crudOperation: CrudOperationWrapper = {
                operation: operation,
                data: adUserServiceExternalSaved
            };
            this.dialogRef.close(crudOperation);
        });
    }

    private saveOrCreateADUser(adUserId: number, adUserWithPassword: ADUserWithPassword): void {
        let adUser$: Observable<ADUser>;
        let operation: CrudOperation;
        if (adUserId) {
            operation = 'SAVE';
            adUser$ = this.adUserDaoService.saveAdUserWithCheck(adUserWithPassword);
        } else {
            operation = 'CREATE';
            adUser$ = this.adUserDaoService.createAdUserWithCheck(adUserWithPassword);
        }
        adUser$.subscribe((result) => {
            const crudOperation: CrudOperationWrapper = {
                operation: operation,
                data: result
            };
            this.dialogRef.close(crudOperation);
        });
    }

    onCancel(): void {
        const adUser: ADUser = this.adUserForm.value;
        const crudOperation: CrudOperationWrapper = {
            operation: 'CANCEL',
            data: adUser
        };
        this.dialogRef.close(crudOperation);
    }

    onRemove(): void {
        const adUser: ADUser = this.adUserForm.value;
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo remover esse usuário de serviço?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                if (this.adUserExternalLoad) {
                    this.adUserServiceExternalDaoService.remove(this.adUserServiceExternal.id).subscribe(() => {
                        this.dialogRef.close({
                            operation: 'DELETE',
                            data: this.adUserServiceExternal
                        } as CrudOperationWrapper);
                    });
                } else {
                    this.adUserDaoService.remove(adUser.id).subscribe(() => {
                        this.dialogRef.close({
                            operation: 'DELETE',
                            data: adUser.id
                        } as CrudOperationWrapper);
                    });
                }
            }
        }));

    }


    findByLoginPw(login: string, adDomainId: number): void {
        this.adUserDaoService.findByLoginPw(login, adDomainId).subscribe((adUserPw) => {
            this.adUserPw = adUserPw.adUserPassword;
        });
    }
    markAsTouchedAndDirty(): void {
        this.adUserForm.markAsTouched();
        this.adUserForm.markAsDirty();
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.adUser.id, this.adUserDaoService, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.adUser.id, this.adUserDaoService, this.dialog);
    }
}

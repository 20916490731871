declare const Module;

export class SessionDisconnectCode {
    static locKey(a: any): any {
        let b = Module.DisconnectCode.UnknownError;
        switch (a && a.code && (b = a.code), b) {
            case Module.DisconnectCode.AccountDisabled:
                return 'SESSION_DISCONNECT_CODE_ACCOUNT_DISABLED';
            case Module.DisconnectCode.AccountExpired:
                return 'SESSION_DISCONNECT_CODE_ACCOUNT_EXPIRED';
            case Module.DisconnectCode.AccountLockedOut:
                return 'SESSION_DISCONNECT_CODE_ACCOUNT_LOCKED_OUT';
            case Module.DisconnectCode.AccountRestricted:
                return 'SESSION_DISCONNECT_CODE_ACCOUNT_RESTRICTED';
            case Module.DisconnectCode.AutoReconnectFailed:
                return 'SESSION_DISCONNECT_CODE_AUTO_RECONNECT_FAILED';
            case Module.DisconnectCode.CantUpgradeLicense:
                return 'SESSION_DISCONNECT_CODE_CANT_UPGRADE_LICENSE';
            case Module.DisconnectCode.CertExpired:
                return 'SESSION_DISCONNECT_CODE_CERT_EXPIRED';
            case Module.DisconnectCode.CertMismatch:
                return 'SESSION_DISCONNECT_CODE_CERT_MISMATCH';
            case Module.DisconnectCode.CertValidationFailed:
                return 'SESSION_DISCONNECT_CODE_GENERIC_PROTOCOL_ERROR';
            case Module.DisconnectCode.ConnectionBroken:
                return 'SESSION_DISCONNECT_CODE_CONNECTION_BROKEN';
            case Module.DisconnectCode.ConnectionTimeout:
                return 'SESSION_DISCONNECT_CODE_CONNECTION_TIMEOUT';
            case Module.DisconnectCode.DecompressionFailed:
                return 'SESSION_DISCONNECT_CODE_DECOMPRESSION_FAILED';
            case Module.DisconnectCode.EncryptionFailed:
                return 'SESSION_DISCONNECT_CODE_ENCRYPTION_FAILED';
            case Module.DisconnectCode.GenericByServer:
                return 'SESSION_DISCONNECT_CODE_GENERIC_BY_SERVER';
            case Module.DisconnectCode.GenericLicenseError:
                return 'SESSION_DISCONNECT_CODE_GENERIC_LICENSE_ERROR';
            case Module.DisconnectCode.GenericNetworkError:
                return 'SESSION_DISCONNECT_CODE_GENERIC_NETWORK_ERROR';
            case Module.DisconnectCode.GenericProtocolError:
                return 'SESSION_DISCONNECT_CODE_GENERIC_PROTOCOL_ERROR';
            case Module.DisconnectCode.GenericSecurityError:
                return 'SESSION_DISCONNECT_CODE_GENERIC_SECURITY_ERROR';
            case Module.DisconnectCode.CredSSPRequired:
                return 'SESSION_DISCONNECT_HYBRID_REQUIRED';
            case Module.DisconnectCode.IdleTimeout:
                return 'SESSION_DISCONNECT_CODE_IDLE_TIMEOUT';
            case Module.DisconnectCode.InitFipsFailed:
                return 'SESSION_DISCONNECT_CODE_INIT_FIPS_FAILED';
            case Module.DisconnectCode.InvalidLicense:
                return 'SESSION_DISCONNECT_CODE_INVALID_LICENSE';
            case Module.DisconnectCode.InvalidLogonHours:
                return 'SESSION_DISCONNECT_CODE_INVALID_LOGON_HOURS';
            case Module.DisconnectCode.InvalidWorkStation:
                return 'SESSION_DISCONNECT_CODE_INVALID_WORK_STATION';
            case Module.DisconnectCode.KerberosUser2UserRequired:
                return 'SESSION_DISCONNECT_CODE_KERBEROS_USER_2_USER_REQUIRED';
            case Module.DisconnectCode.LogonTimeout:
                return 'SESSION_DISCONNECT_CODE_LOGON_TIMEOUT';
            case Module.DisconnectCode.LogonTypeNotGranted:
                return 'SESSION_DISCONNECT_CODE_LOGON_TYPE_NOT_GRANTED';
            case Module.DisconnectCode.LoopbackUnsupported:
                return 'SESSION_DISCONNECT_CODE_LOOPBACK_UNSUPPORTED';
            case Module.DisconnectCode.NoLicenseAvailable:
                return 'SESSION_DISCONNECT_CODE_NO_LICENSE_AVAILABLE';
            case Module.DisconnectCode.NoLicenseServer:
                return 'SESSION_DISCONNECT_CODE_NO_LICENSE_SERVER';
            case Module.DisconnectCode.NoRemoteConnectionLicense:
                return 'SESSION_DISCONNECT_CODE_NO_REMOTE_CONNECTION_LICENSE';
            case Module.DisconnectCode.NoSuchUser:
                return 'SESSION_DISCONNECT_CODE_NO_SUCH_USER';
            case Module.DisconnectCode.PasswordExpired:
                return 'SESSION_DISCONNECT_CODE_PASSWORD_EXPIRED';
            case Module.DisconnectCode.PasswordMustChange:
                return 'SESSION_DISCONNECT_CODE_PASSWORD_MUST_CHANGE';
            case Module.DisconnectCode.PreAuthLogonFailed:
                return 'SIGNIN_DIALOG_FAILED_TEXT';
            case Module.DisconnectCode.RemotingDisabled:
                return 'SESSION_DISCONNECT_CODE_REMOTING_DISABLED';
            case Module.DisconnectCode.ReplacedByOtherConnection:
                return 'SESSION_DISCONNECT_CODE_REPLACED_BY_OTHER_CONNECTION';
            case Module.DisconnectCode.ServerDeniedConnection:
                return 'SESSION_DISCONNECT_CODE_SERVER_DENIED_CONNECTION';
            case Module.DisconnectCode.ServerInsufficientPrivileges:
                return 'SESSION_DISCONNECT_CODE_SERVER_INSUFFICIENT_PRIVILEGES';
            case Module.DisconnectCode.ServerNameLookupFailed:
                return 'SESSION_DISCONNECT_CODE_SERVER_NAME_LOOPUP_FAILED';
            case Module.DisconnectCode.ServerOutOfMemory:
                return 'SESSION_DISCONNECT_CODE_SERVER_OUT_OF_MEMORY';
            case Module.DisconnectCode.ServerTool:
                return 'SESSION_DISCONNECT_CODE_SERVER_TOOL';
            case Module.DisconnectCode.SSLHandshakeFailed:
                return 'SESSION_DISCONNECT_CODE_SSL_HANDSHAKE_FAILED';
            case Module.DisconnectCode.TimeSkew:
                return 'SESSION_DISCONNECT_CODE_TIME_SKEW';
            case Module.DisconnectCode.UnknownError:
                return 'SESSION_DISCONNECT_CODE_UNKNOWN_ERROR';
            case Module.DisconnectCode.UserInitiated:
                return 'SESSION_DISCONNECT_CODE_USER_INITIATED';
            case Module.DisconnectCode.VersionMismatch:
                return 'SESSION_DISCONNECT_CODE_VERSION_MISMATCH';
            case Module.DisconnectCode.GatewayProtocolError:
            case Module.DisconnectCode.PreOrchestrationFailed:
            case Module.DisconnectCode.PreOrchestrationResponseError:
                return 'SESSION_DISCONNECT_CODE_GATEWAY_PROTOCOL_ERROR';
            case Module.DisconnectCode.GatewayTimeout:
                return 'SESSION_DISCONNECT_CODE_GATEWAY_TIMEOUT';
            case Module.DisconnectCode.OutOfMemory:
                return 'SESSION_DISCONNECT_CODE_OUT_OF_MEMORY';
            case Module.DisconnectCode.PipelineDecodeError:
                return 'SESSION_DISCONNECT_PIPELINE_DECODE_ERROR';
            default:
                return 'SESSION_DISCONNECT_CODE_UNKNOWN_ERROR';
        }
    }
}

import {BaseSearchDao} from '../../base-search-dao';
import {BaseServer, BaseServerPrivateKey, DesktopServer, LinuxServer} from '../../models';
import {Observable} from 'rxjs';
import {ValueWrapper} from '../../helpers/value-wrapper';

export abstract class BaseServerDao<T extends BaseServer, D extends BaseServerPrivateKey> extends BaseSearchDao<T> {
    createAndGetPrivateKey(server: T): Observable<D> {
        return this.http.post<D>(this.url + '/create-and-get-private-key', server);
    }

    createDesktopServerInAllSubProjectsOfProjectAndGetPrivateKey(server: T, subProjectId: number, copyInAll: boolean): Observable<D> {
        return this.http.post<D>(this.url + '/create-desktop-server-in-all-sub-projects-of-project-and-get-private-key?subProjectId=' + subProjectId + '&copyInAll=' + copyInAll, server);
    }

    saveDesktopServerIdInSubProject(desktopServerId: number, subProjectId: number): Observable<DesktopServer> {
        return this.http.put<DesktopServer>(
            this.url + '/save-desktop-server-in-sub-project?subProjectId=' + subProjectId + '&desktopServerId=' + desktopServerId, null
        );
    }

    createLinuxServerInAllSubProjectsOfProjectAndGetPrivateKey(server: T, subProjectId: number, copyInAll: boolean): Observable<D> {
        return this.http.post<D>(this.url + '/create-linux-server-in-all-sub-projects-of-project-and-get-private-key?subProjectId=' + subProjectId + '&copyInAll=' + copyInAll, server);
    }

    saveLinuxServerIdInSubProject(linuxServerId: number, subProjectId: number): Observable<LinuxServer> {
        return this.http.put<LinuxServer>(
            this.url + '/save-linux-server-in-sub-project?subProjectId=' + subProjectId + '&linuxServerId=' + linuxServerId, null
        );
    }

    generateInstallToken(server: T, subProjectId: number): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(this.url + '/generate-install-token/?subProjectId=' + subProjectId, server);
    }

    generateNewPrivateKey(serverId: number): Observable<D> {
        return this.http.put<D>(this.url + '/' + serverId + '/generate-new-private-key', null);
    }

    syncServer(desktopServerId: number): Observable<DesktopServer> {
        return this.http.put<DesktopServer>(this.url + '/sync-server', desktopServerId);
    }


    deleteDesktopServerFromSubProject(desktopServerId: number, subProjectId: number): Observable<string> {
        return this.http.remove(this.url + '/delete-desktop-server-from-sub-project?desktopServerId=' + desktopServerId + '&subProjectId=' + subProjectId);
    }

    deleteLinuxServerFromSubProject(linuxServerId: number, subProjectId: number): Observable<string> {
        return this.http.remove(this.url + '/delete-linux-server-from-sub-project?linuxServerId=' + linuxServerId + '&subProjectId=' + subProjectId);
    }


}

import {Component, Inject} from '@angular/core';
import {ManagerUserOnline} from '../../../models';
import {ImageFileService} from '../../image-file/image-file-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SendStompMessageComponent} from './send-stomp-message/send-stomp-message.component';

@Component({
    selector: 'app-manager-user-online-details',
    templateUrl: './manager-user-online-details.component.html',
    styleUrls: ['./manager-user-online-details.component.scss']
})
export class ManagerUserOnlineDetailsComponent {
    managerUserOnline: ManagerUserOnline;

    constructor(public dialogRef: MatDialogRef<ManagerUserOnlineDetailsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public imageFileService: ImageFileService,
                private dialog: MatDialog) {
        this.managerUserOnline = data.managerUserOnline;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    openMessageModal(username: string, destination: string): void {
        this.dialog.open(SendStompMessageComponent, {
            disableClose: true,
            data: {
                username,
                destination
            }
        });
    }
}

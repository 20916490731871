export class ConnectionPropertiesRejectedError {
    message: any;
    propertiesRejectedReason: any;
    name: any;
    bookmark: any;

    constructor(private a: any, private b: any){
        this.message = 'Connection creation failed due to a problem with the connection properties, error=' + a,
            this.propertiesRejectedReason = a, this.name = 'ConnectionPropertiesRejectedError', this.bookmark = b;
    }

    toString(): any {
        return this.name + ': ' + this.message;
    }
}

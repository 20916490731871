import {Injectable} from '@angular/core';
import {BaseMonitoringDao} from '../../../../base-monitoring-dao';
import {ProcessDetails} from '../../../../models-monitoring';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {R2CloudHttpMonitoringService} from '../../../../r2-cloud-http-monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class ProcessDetailsTableDaoService extends BaseMonitoringDao<ProcessDetails> {
    constructor(http: R2CloudHttpMonitoringService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'process-details');
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAuthorityDaoService} from './user-authority-dao.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    UserAuthorityDaoService
  ]
})
export class UserAuthorityModule { }

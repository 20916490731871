import {Injectable} from '@angular/core';
import {BaseIAMDao} from '../../base-iam-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {RoleIdCustomerGroupIdManagerUserId} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class RoleCustomerGroupManagerUserDAOService extends BaseIAMDao<RoleIdCustomerGroupIdManagerUserId> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'role-customer-group-manager-user');
    }


}

<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">LOG </span>
        <span class="font-size-14 ml-25">{{desktopServer?.hostname}} <span class="p-14">|</span> {{desktopServer?.publicIp}} <span class="p-14">|</span> {{desktopServer?.connectionFQDN}}</span>
    </mat-toolbar-row>
</mat-toolbar>

<div class="mat-white-bg mat-elevation-z2 p-10 overflow-x-hidden" fxLayout="column"
     fxLayoutAlign="space-between stretch" matDialogContent>

    <div title="Abrir a página de administração do projeto" *ngFor="let customerGroup of customerGroups"
         fxLayout="row" fxLayoutAlign="start center"
         class="mb-5 mt-5 cursor-pointer hover-underline ml-10">
        <div class="mr-8"><img
            src="{{imageFileService.getCustomerGroupPictureUrl(customerGroup.imageUUID, 34)}}"
            width="34"
            height="34">
        </div>
        <div>
            {{customerGroup.name}}
        </div>
    </div>

    <hr>
    <hr>

    <div *ngFor="let log of logs" [ngClass]="{'status-error':log.status === 'ERROR'}">
        <div class="log-box">
            <table class="font-size-18">
                <tr>
                    <td><b>Data: </b></td>
                    <td>{{log.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                </tr>
                <tr>
                    <td><b>Status: </b></td>
                    <td>{{log.status}}</td>
                </tr>
            </table>
            <hr class="hr" >
            <div [innerHTML]="log.log"></div>

        </div>

    </div>


</div>
<div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
     matDialogActions>
    <button type="button" mat-raised-button color="primary" (click)="onCancel()">OK</button>
</div>



import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {ServerType} from '../../../models';
import {Observable} from 'rxjs/internal/Observable';
import {SshAccessPermission} from '../../../models-terminal';
import {R2CloudHttpTerminalService} from '../../../r2-cloud-http-terminal.service';
import {BaseCreatedAtNumberDao} from '../../../base-created-at-number-dao';

@Injectable({
    providedIn: 'root'
})
export class SshAccessPermissionDaoService extends BaseCreatedAtNumberDao<SshAccessPermission> {
    constructor(http: R2CloudHttpTerminalService,
                validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'access-permission');
    }

    getSshAccessPermission(serverId: number, serverType: ServerType): Observable<SshAccessPermission> {
        return this.http.get<SshAccessPermission>(this.url + '/' + serverType + '/' + serverId);
    }
}

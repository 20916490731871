<mat-card class="padding-none width-100-percent mt-20 with-search-box">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 7px;">
            <div>Servidores OffLine
                <span
                    *ngIf="serversOffline && serversOffline.length > 0; else noServers"
                    class="nav-link-badge ng-star-inserted red-A700-bg red-A700">
                      {{serversOfflineWrapperListFilter.length}}
                </span>
                <ng-template #noServers>
                    <span class="nav-link-badge ng-star-inserted red-A700-bg red-A700">0</span>
                </ng-template>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="pr-16">
            <input matInput class="search-box" [formControl]="searchFieldControl" autocomplete="off"
                   *ngIf="serversOffline && serversOffline.length > 0"
                   placeholder="Busca">
        </div>
        <div  class="scroll-content">
            <table class="statistic-table width-100-percent" cellspacing="0"
                   cellpadding="0"
                   *ngIf="serversOffline && serversOffline.length > 0">
                <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th class="w-115"></th>
                </tr>
                </thead>
                <tbody class="body-list">
                <tr *ngFor="let serverOffline of serversOfflineWrapperListFilter">
                    <td>
                        <fa-icon class="s-20 mr-8  windows-icon" [icon]="faWindows"
                                 *ngIf="serverOffline.metricType == serverWindows"
                                 style="color: #2E8DEF"></fa-icon>

                        <fa-icon class="s-20 mr-8  windows-icon" [icon]="faLinux"
                                 *ngIf="serverOffline.metricType == serverLinux"
                                 style="color: #333333"></fa-icon>
                    </td>
                    <td>
                        <div>
                            <div><b>{{serverOffline.hostname}}</b></div>
                            <div class="font-size-12">{{serverOffline.connectionFQDN}}
                                <span *ngIf="serverOffline.connectionPort">:
                                    {{serverOffline.connectionPort}}
                            </span>
                            </div>
                            <div class="font-size-12">
                                {{statusService.getAdDomain(serverOffline.adDomainId, adDomains)?.name}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div *ngFor="let id of serverOffline.subProjectIds">
                            <a (click)="statusService.redirectToR2cloud(statusService.getSubProject(id, subProjects))">
                                {{statusService.getSubProject(id, subProjects)?.name}}
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>

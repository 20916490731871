import {ManagerUser} from '../../models';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ManagerUserService {


    public initManagerUser(): ManagerUser {
        return {
            id: null,
            active: true,
            twoFactorAuth: false,
            comment: null,
            authorityIds: [],
            customerId: null,
            displayName: null,
            email: null,
            individualClientConfig: false,
            picture: null,
            imageFileId: null,
            province: null,
            city: null,
            neighborhood: null,
            addressAddOn: null,
            address: null,
            addressNumber: null,
            zipCode: null,
            phone: null,
            managerUserAccessPeriodProfileId: null,
            modified: null,
            optlock: null,
            imageUUID: null,
        };
    }
}

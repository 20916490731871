<ng-container *ngIf="sessionGroup">
    <div [class.remote-app-active]="isSessionActive()"
        class="dropdown remote-app-icon-bg remote-app-hover">
        <div fxLayout="column"
             fxLayoutAlign="center center"
             (click)="onSessionClick(sessionGroup.sessionList[0])">
            <div class="remote-app" fxLayout="column" fxLayoutAlign="center center">
                <img *ngIf="sessionGroup.thumbnail; else noThumbnail" [src]="sessionGroup.thumbnail"
                     width="32"
                     height="32" class="remote-app-img"/>
                <ng-template #noThumbnail>
                    <img src="assets/icons/hourglass.png" width="32" height="32" class="remote-app-img"/>
                </ng-template>
            </div>
            <div class="remote-app-open-indicator-space"
                 [class.remote-app-open-indicator]="true"
                 [class.remote-app-open-indicator-active]="isSessionActive()">
            </div>
            <div class="remote-app-grouped-bg">

            </div>
        </div>
        <div class="dropdown-content fuse-navy-900">
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <app-session-single
                    *ngFor="let session of sessionGroup.sessionList"
                    [activeSession]="activeSession"
                    (sessionClick)="onSessionClick($event)"
                    [grouped]="true"
                    [session]="session">
                </app-session-single>
            </div>
        </div>
    </div>
</ng-container>
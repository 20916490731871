import {Observable, Observer} from 'rxjs';
import {v4} from 'uuid';

export class StompSubscription<T> {
    private readonly _id: string;
    private _subscribers: Observer<T>[] = [];
    private readonly __destination: string;
    private readonly _observable: Observable<T>;
    private data: T;

    constructor(_destination: string, _complete: (stompSubscription: StompSubscription<T>) => void) {
        this._observable = new Observable<T>((subscriber) => {
            this._subscribers.push(subscriber);
            if (this.data) {
                subscriber.next(this.data);
            }
            return () => {
                this._subscribers.splice(this._subscribers.indexOf(subscriber), 1);
                if (this._subscribers.length === 0){
                    _complete(this);
                }
            };
        });
        this.__destination = _destination;
        this._id = v4().replace(/-/g, '');
    }

    get id(): string {
        return this._id;
    }

    get observable(): Observable<T> {
        return this._observable;
    }

    emit(data: any): void {
        let obj;
        if (typeof data === 'string'){
            obj = <T>JSON.parse(data);
        } else {
            obj = data;
        }
        this.data = obj;
        this._subscribers.forEach((subscriber) => subscriber.next(obj));
    }

    get destination(): string {
        return this.__destination;
    }

    complete(): void {
        this._subscribers.forEach((subscriber) => subscriber.complete);
    }
}

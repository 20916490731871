import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {BaseSearchDao} from '../../../base-search-dao';
import {SubProject} from '../../../models';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {ValueWrapper} from '../../../helpers/value-wrapper';

@Injectable()
export class SubProjectDaoService extends BaseSearchDao<SubProject> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'sub-project');
    }

    getSubProjectsByDesktopServerIds(desktopServerIds: number[]): Observable<SubProject[]> {
        return this.http.post<SubProject[]>(this.url + '/get-sub-projects-by-desktop-server-ids', desktopServerIds);
    }

    getSubProjectsByCustomerGroupId(customerGroupId: number): Observable<SubProject[]> {
        return this.http.get<SubProject[]>(this.url + '/customer-group-id/' + customerGroupId);
    }

    getCustomerGroupIdsObfuscatedBySubProjectId(subProjectId: number): Observable<ValueWrapper> {
        return this.http.get<ValueWrapper>(this.url + '/get-customer-group-ids-obfuscated/' + subProjectId);
    }
}


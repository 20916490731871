import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../../base-dao';
import {HipcomBulkUpdateCloseSoftware} from '../../../../../models';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';

@Injectable()
export class HipcomBulkUpdateCloseSoftwareDaoService extends BaseDao<HipcomBulkUpdateCloseSoftware> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'partners-hipcom-bulk-update-close-software');
    }
}

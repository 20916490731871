<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <!-- TOP BACKGROUND -->
  <div class="top-bg mat-accent-bg"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header white-fg p-24" fxLayout="row" fxLayoutAlign="start center">
      <h2>Lista de Clientes</h2>
      <button type="button" mat-mini-fab id="addCustomer" aria-label="Adicionar" matTooltip="Adicionar" (click)="onAdd()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <!-- / CONTENT BODY -->
    <div class="content">
      <mat-card>
        <div class="widget-group" fxLayout="column" fxLayoutAlign="space-evenly" fxFlex="100" fxLayoutWrap *fuseIfOnDom
             [@animateStagger]="{value:'50'}">
          <div *ngFor="let customerGroup of customerGroups" fxLayout="row" fxLayoutAlign="start center">
            <div class="mb-4 mr-4">
              <button type="button" mat-raised-button color="primary" (click)="onEdit(customerGroup)">Editar</button>
            </div>
            <div>
              {{customerGroup.name}}
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

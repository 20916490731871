import {Component, Inject} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AdUserDaoService} from '../ad-user-dao.service';
import {ADDomain, ADUser, ADUserWithPassword} from '../../../models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-ad-user-password-update',
    templateUrl: './ad-user-password-update.component.html',
    styleUrls: ['./ad-user-password-update.component.scss']
})
export class AdUSerPasswordUpdateComponent extends ComponentCleaner {
    public myForm: FormGroup;
    public adUserPassword: FormControl = new FormControl();
    public adUser: ADUser;
    public adDomain: ADDomain;
    public adUserWithPassword: ADUserWithPassword = {
        adUser: null,
        askThePassword: null,
        adUserPassword: null,
        adUserAlreadyExists: null,
        subProjectId: null
    };

    constructor(
        public dialogRef: MatDialogRef<AdUSerPasswordUpdateComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private daoAdUser: AdUserDaoService
    ) {
        super();

        this.adUser = data.adUser;
        this.adDomain = data.adDomain;


        const formGroups = {};

        this.myForm = this.fb.group(formGroups);


        const subscription = this.adUserPassword.valueChanges.subscribe(() => {
            this.myForm.markAsDirty();
        });
        this.adUserPassword.setValidators(Validators.minLength(8));
        this.addSubscription(subscription);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.adUserWithPassword.adUser = this.adUser;
        this.adUserWithPassword.adUserPassword = this.adUserPassword.value;

        this.daoAdUser.saveMyPassword(this.adUserWithPassword).subscribe((aDUserFromDAO) => {
            this.dialogRef.close(aDUserFromDAO);
        });


    }


}

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {CrudOperationWrapper} from '../../../../helpers/kluh';

@Component({
    selector: 'app-manager-user-change-password',
    templateUrl: './manager-user-change-password.component.html',
    styleUrls: ['./manager-user-change-password.component.scss']
})
export class ManagerUserChangePasswordComponent {
    myForm: FormGroup;

    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder) {


        this.myForm = this.fb.group({
            newPassword: ['', Validators.minLength(6)],
            reNewPassword: ['', Validators.minLength(6)],
            forceChangePasswordOnFirstLogin: [false]
        }, {validator: this.checkPasswords});
    }

    checkPasswords(group: FormGroup): ValidationErrors {
        const pass = group.controls.newPassword.value;
        const confirmPass = group.controls.reNewPassword.value;
        return pass === confirmPass ? null : {notSame: true};
    }

    onSubmit(): void {
        const crudOperation: CrudOperationWrapper = {
            data: {password: this.myForm.value.newPassword, forceChangePasswordOnFirstLogin: this.myForm.value.forceChangePasswordOnFirstLogin},
            operation: 'SAVE'
        };
        this.dialogRef.close(crudOperation);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}

import {Component, Inject, OnInit} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ADUser, ManagerUser, RoleIdCustomerGroupIdManagerUserId} from '../../../../models';
import {Observable, of, Subject} from 'rxjs';

import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {AuthService} from '../../../../auth.service';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';
import {convertToFormGroup, CrudOperationWrapper, markAsTouched, validateEmail} from '../../../../helpers/kluh';
import {debounceTime, delay, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {CustomerCreateEditComponent} from '../../../customer/customer-create-edit/customer-create-edit.component';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {RoleCustomerGroupManagerUserDAOService} from '../../role-customer-group-manager-user-dao.service';
import {RoleDAOService} from '../../role-dao.service';
import {ManagerUserService} from '../../../manager-user/manager-user-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-role-customer-group-manager-user-edit',
    templateUrl: './role-customer-group-manager-user-edit.component.html',
    styleUrls: ['./role-customer-group-manager-user-edit.component.scss']
})
export class RoleCustomerGroupManagerUserEditComponent extends ComponentCleaner implements OnInit {


    public myForm: FormGroup;
    public myPriorityRole: string;
    public currentRoleId: number;
    public customerGroupId: number;
    managerUser: ManagerUser;
    // public allAuthorities: UserAuthority[];
    private validation$: Subject<boolean> = new Subject();

    constructor(public dialogRef: MatDialogRef<RoleCustomerGroupManagerUserEditComponent>,
                private fb: FormBuilder,
                private customerGroupService: CustomerGroupService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public adminService: R2CloudAdminService,
                private dialog: MatDialog,
                private authService: AuthService,
                private daoManagerUser: ManagerUserDaoService,
                private managerUserService: ManagerUserService,
                private roleCustomerGroupManagerUserDao: RoleCustomerGroupManagerUserDAOService,
                private roleDao: RoleDAOService) {
        super();
    }

    ngOnInit(): void {
        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (customerGroup && customerGroup.id) {
                this.customerGroupId = customerGroup.id;
            }
        }));
        this.roleDao.get().subscribe((roles) => {
            if (roles) {
                this.adminService.roles = roles;
            } else {
                this.adminService.roles = [];
            }
        });

        // this.userAuthorityDao.get().subscribe((allAuthorities) => {
        //     this.allAuthorities = allAuthorities;
        // });
        let roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId;
        let roleId;
        let email;
        if (this.data && this.data.roleIdCustomerGroupIdManagerUserId && this.data.roleIdCustomerGroupIdManagerUserId.roleId) {
            roleIdCustomerGroupIdManagerUserId = this.data.roleIdCustomerGroupIdManagerUserId;
            this.managerUser = this.adminService.getManagerUserCustomerGroup(roleIdCustomerGroupIdManagerUserId.managerUserId);
            email = this.managerUser.email;
            roleId = this.adminService.getRole(roleIdCustomerGroupIdManagerUserId.roleId).id;
            this.currentRoleId = roleId;
        } else {
            this.managerUser = this.managerUserService.initManagerUser();
            email = '';
            roleId = '';
        }

        if (this.data && this.data.managerUser) {
            this.managerUser = this.data.managerUser;
            email = this.data.managerUser.email;
        }


        const roleIdForm = this.fb.control(roleId);
        const emailForm = this.fb.control(email, [Validators.email]);
        const managerUserForm = this.fb.group(convertToFormGroup(this.managerUser));
        managerUserForm.disable();
        this.myForm = this.fb.group({
            managerUserForm: managerUserForm,
            emailForm: emailForm,
            roleIdForm: roleIdForm
        }, {asyncValidator: this.validate()});
        const emailForm$ = emailForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (value && validateEmail(value)) {
                this.daoManagerUser.findByEmail(value).subscribe(managerUserFromDao => {
                    if (managerUserFromDao) {
                        managerUserForm.setValue(managerUserFromDao);
                        managerUserForm.disable();
                    } else {
                        managerUserForm.setValue(this.managerUserService.initManagerUser());
                        managerUserForm.enable();
                    }
                });
            } else {
                if (managerUserForm.value.id) {
                    managerUserForm.setValue(this.managerUserService.initManagerUser());
                    managerUserForm.disable();
                }
            }
        });
        this.addSubscription(emailForm$);
    }


    validate(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            this.validation$.next(true);
            this.validation$ = new Subject();
            const entity: RoleIdCustomerGroupIdManagerUserId = {
                roleId: control.get('roleIdForm').value,
                managerUserId: control.get('managerUserForm').value.id,
                customerGroupId: this.customerGroupId
            };
            return this.roleCustomerGroupManagerUserDao.validate(entity).pipe(
                takeUntil(this.validation$),
                delay(500),
                map((response) => {
                    const result = {};
                    for (const error of response) {
                        if (error.field && error.field === 'roleId') {
                            result['roleIdForm'] = error.defaultMessage;
                        }
                    }
                    return result;
                }));
        };
    }

    onSubmit(): void {
        const roleId: number = this.myForm.get('roleIdForm').value;
        const email: string = this.myForm.get('emailForm').value;
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        managerUser.email = email;
        let managerUser$: Observable<ManagerUser>;
        if (managerUser.id) {
            managerUser$ = of(managerUser);
        } else {
            managerUser$ = this.daoManagerUser.create(managerUser);
        }

        managerUser$.subscribe((managerUserCustomerGroup) => {
            const roleIdCustomerGroupIdManagerUserIdNew: RoleIdCustomerGroupIdManagerUserId = {
                customerGroupId: this.customerGroupId,
                managerUserId: managerUserCustomerGroup.id,
                roleId: Number(roleId)
            };
            if (this.currentRoleId) {
                const roleIdCustomerGroupIdManagerUserId = this.getRoleIdCustomerGroupIdManagerUserId();
                roleIdCustomerGroupIdManagerUserId.roleId = this.currentRoleId;
                this.roleCustomerGroupManagerUserDao.delete(roleIdCustomerGroupIdManagerUserId).subscribe(v => {
                    this.createRoleCustomerGroupManagerUser(Number(roleId), managerUserCustomerGroup, roleIdCustomerGroupIdManagerUserId);
                });
            } else {
                this.createRoleCustomerGroupManagerUser(Number(roleId), managerUserCustomerGroup, null);
            }
            const crudOperation: CrudOperationWrapper = {
                data: roleIdCustomerGroupIdManagerUserIdNew,
                operation: 'CREATE'
            };
            this.dialogRef.close(crudOperation);
        });

    }

    getRoleIdCustomerGroupIdManagerUserId(): RoleIdCustomerGroupIdManagerUserId {
        const roleId: number = this.myForm.get('roleIdForm').value;
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        return {
            roleId: roleId,
            managerUserId: managerUser.id,
            customerGroupId: this.customerGroupId
        };
    }

    createRoleCustomerGroupManagerUser(roleId: number, managerUser: ManagerUser, oldRoleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId | null): void {
        const roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId = {
            roleId: roleId,
            managerUserId: managerUser.id,
            customerGroupId: this.customerGroupId
        };
        this.adminService.managerUsersCustomerGroup.push(managerUser);
        this.roleCustomerGroupManagerUserDao.create(roleIdCustomerGroupIdManagerUserId).subscribe((roleCustomerGroupManagerUserSaved) => {
            if (oldRoleIdCustomerGroupIdManagerUserId) {
                this.adminService.replaceRoleCustomerGroupManagerUser(oldRoleIdCustomerGroupIdManagerUserId, roleCustomerGroupManagerUserSaved);
            } else {
                this.adminService.addRoleCustomerGroupManagerUser(roleCustomerGroupManagerUserSaved);
            }
        });
    }

    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover ' + this.myForm.get('managerUserForm').value.email + ' da administração ?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const roleIdCustomerGroupIdManagerUserId = this.getRoleIdCustomerGroupIdManagerUserId();
                roleIdCustomerGroupIdManagerUserId.roleId = this.currentRoleId;
                this.roleCustomerGroupManagerUserDao.delete(roleIdCustomerGroupIdManagerUserId).subscribe(v => {
                    this.adminService.removeRoleCustomerGroupManagerUser(roleIdCustomerGroupIdManagerUserId);
                    const crudOperation: CrudOperationWrapper = {
                        data: roleIdCustomerGroupIdManagerUserId,
                        operation: 'DELETE'
                    };
                    this.dialogRef.close(crudOperation);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onCancel(): void {
        const adUser: ADUser = null;
        const crudOperation: CrudOperationWrapper = {
            operation: 'CANCEL',
            data: adUser
        };
        this.dialogRef.close(crudOperation);
    }

    onCreateCustomer(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.adminService.addCustomer(result.data);

            this.myForm.get('managerUserForm').get('customerId').setValue(result.data.id);
            markAsTouched(this.myForm);
        } else if (result.operation === 'SAVE') {

        } else if (result.operation === 'DELETE') {

        }
    }


}

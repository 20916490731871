import {Component, Input} from '@angular/core';
import {FirebaseUserDetails, MarketplaceOrderComment} from '../../../../../models';
import {ImageFileService} from '../../../../image-file/image-file-service';
import {R2CloudAdminService} from '../../../../r2-cloud-admin/r2-cloud-admin.service';

@Component({
    selector: 'app-marketplace-order-comment-list',
    templateUrl: './marketplace-order-comment-list.component.html',
    styleUrls: ['./marketplace-order-comment-list.component.scss']
})
export class MarketplaceOrderCommentListComponent {

    @Input()
    marketplaceRequestCommentList: MarketplaceOrderComment[] = [];

    @Input()
    user: FirebaseUserDetails = null;

    constructor(
        public imageFileService: ImageFileService,
        public adminService: R2CloudAdminService,
                ) {
    }
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {MarketplaceProduct, MarketplaceProductFAQ} from '../../../../models';
import {MarketplaceProductFAQDaoService} from './marketplace-product-faq.dao.service';

@Component({
    selector: 'app-marketplace-product-detail-faq',
    templateUrl: './marketplace-product-faq.component.html',
    styleUrls: ['./marketplace-product-faq.component.scss', '../marketplace-product.component.scss']
})
export class MarketplaceProductFAQComponent implements OnInit, OnChanges {
    panelOpenState = false;
    marketPlaceProductFQAList: MarketplaceProductFAQ[] = [];

    @Input()
    marketplaceProduct: MarketplaceProduct;

    @Input()
    reloadData: number;

    @Output()
    eventSendEdit = new EventEmitter();

    constructor(
        public marketplaceProductFaqDaoService: MarketplaceProductFAQDaoService
    ) {
    }

    ngOnInit(): void {
        this.loadFQAs();
    }

    ngOnChanges(): void {
        this.loadFQAs();
    }

    loadFQAs(): void {
        this.marketplaceProductFaqDaoService.findAllByMarketplaceProductId(this.marketplaceProduct.id).subscribe(marketPlaceProductFQAList => {
            if (marketPlaceProductFQAList) {
                this.marketPlaceProductFQAList = marketPlaceProductFQAList;
            }
        });
    }

    onEdit(): void {
        this.eventSendEdit.emit(this.marketplaceProduct.id);
    }
}

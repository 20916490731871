import {ADUser, DesktopServer, ManagerUser, ServerConnected, ServerDisconnected, ServerType} from '../../models';
import {Injectable} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {AdUserDaoService} from '../ad-user/ad-user-dao.service';
import {AdDomainDaoService} from '../ad-domain/ad-domain-dao.service';

import {AdUSerPasswordUpdateComponent} from '../ad-user/ad-user-password-update/ad-user-password-update.component';
import {AuthService} from '../../auth.service';
import {DesktopServerDaoService} from './desktop-server-dao.service';
import {ScriptExecutionDaoService} from '../script-execution/script-execution-dao.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {USER_TOPIC} from '../../../../main';
import {MatDialog} from '@angular/material/dialog';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';


@Injectable({
    providedIn: 'root'
})
export class DesktopServerService extends ComponentCleaner {

    desktopServerStatusList: ServerConnected[] = [];
    // desktopServerList: DesktopServer[] = [];
    adUserList: ADUser[] = [];
    managerUser: ManagerUser;
    disconnectLoading: boolean[] = [];
    forceDisconnectLoading: boolean[] = [];

    constructor(private adUserDaoService: AdUserDaoService,
                private adDomainDaoService: AdDomainDaoService,
                private stomp: R2CloudStompService,
                private desktopServerDaoService: DesktopServerDaoService,
                private scriptExecutionDaoService: ScriptExecutionDaoService,
                private auth: AuthService,
                private userAuthorityDao: UserAuthorityDaoService,
                private dialog: MatDialog) {
        super();
    }

    initSubscribe(): void {
        this.addSubscription(this.userAuthorityDao.getMe().subscribe(() => {
            this.checkAdUserWithoutPassword();
        }));

        // Pega os servidores online e filtra com os que usuário tem
        this.addSubscription(this.userAuthorityDao.getMe().subscribe((me) => {
            this.managerUser = me.managerUser;
            if (this.managerUser) {
                const subscriptionServerConnected = this.stomp.stompTopic<ServerConnected[]>(`${USER_TOPIC}/desktop-server-connected`).subscribe((serverConnectedList) => {
                    for (const serverConnected of serverConnectedList) {
                        const index = this.desktopServerStatusList.findIndex((item) => {
                            return serverConnected.desktopServerId === item.desktopServerId;
                        });
                        if (index === -1) {
                            this.desktopServerStatusList.push(serverConnected);
                        }
                    }
                });
                this.addSubscription(subscriptionServerConnected);

                const subscriptionServerDisconnected = this.stomp.stompTopic<ServerDisconnected>(`${USER_TOPIC}/desktop-server-disconnected`).subscribe(
                    (serverDisconnected) => {
                        const index = this.desktopServerStatusList.findIndex((serverConnected) => {
                            return serverConnected.desktopServerId === serverDisconnected.desktopServerId;
                        });
                        if (index > -1) {
                            this.desktopServerStatusList.splice(index, 1);
                        }
                    });
                this.addSubscription(subscriptionServerDisconnected);

                const subscriptionAdUser = this.adUserDaoService.filter({managerUserId: me.managerUser.id}).subscribe((adUserList) => {
                    if (adUserList) {
                        this.adUserList = adUserList;
                    }
                });
                this.addSubscription(subscriptionAdUser);
            }


        }));
        // (Fim) Pega os servidores online e que o usuário tem acesso
    }


    checkAdUserWithoutPassword(): void {
        this.adUserDaoService.getMyAdUsersWithoutPassword().subscribe((adUsers: ADUser[]) => {
            if (adUsers && adUsers.length > 0) {
                for (const adUser of adUsers) {
                    if (adUser && adUser.id) {
                        this.adDomainDaoService.getMyDomain(adUser.id).subscribe((adDomain) => {
                            const dialogRef = this.dialog.open(AdUSerPasswordUpdateComponent, {
                                disableClose: true,
                                panelClass: 'generic-edit-dialog',
                                data: {
                                    adUser: adUser,
                                    adDomain: adDomain
                                }
                            });
                            const subscription = dialogRef.afterClosed().subscribe((result) => {
                                if (result) {

                                }
                            });
                            this.addSubscription(subscription);
                        });
                    }
                }
            }
        });
    }


    getAdUser(domainId: number): ADUser {
        for (const adUser of this.adUserList) {
            if (adUser.adDomainId === domainId) {
                return adUser;
            }
        }
        return null;
    }


    desktopServerConnected(desktopServerId: number): boolean {
        for (const serverConnected of this.desktopServerStatusList) {
            if (serverConnected.desktopServerId === desktopServerId) {
                return true;
            }
        }
        return false;
    }


    existsDesktopServerConnectedOfList(desktopServerList: DesktopServer[]): boolean {
        if (this.desktopServerStatusList && this.desktopServerStatusList.length > 0) {
            for (const desktopServer of desktopServerList) {
                for (const serverConnected of this.desktopServerStatusList) {
                    if (serverConnected.desktopServerId === desktopServer.id) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    onUserLogOffLocalAdmin(aDLogin: string, desktopServerId: number): void {
        this.setDisconnectLoading(desktopServerId);
        this.scriptExecutionDaoService.logoffUserLocalAdminOnDesktopServer(desktopServerId, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onUserLogOff: ' + response);
            }
        });
    }

    onUserResetLocalAdmin(aDLogin: string, desktopServerId: number): void {
        this.setForceDisconnectLoading(desktopServerId);
        this.scriptExecutionDaoService.resetUserLocalAdminOnDesktopServer(desktopServerId, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onUserReset: ' + response);
            }
        });
    }

    onUserLogOff(aDLogin: string, desktopServerId: number): void {
        this.setDisconnectLoading(desktopServerId);
        this.scriptExecutionDaoService.logoffUserOnDesktopServer(desktopServerId, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onUserLogOff: ' + response);
                // this.setForm();
            }
        });
    }

    onUserReset(aDLogin: string, desktopServerId: number): void {
        this.setForceDisconnectLoading(desktopServerId);
        this.scriptExecutionDaoService.resetUserOnDesktopServer(desktopServerId, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onUserReset: ' + response);
                // this.setForm();
            }
        });
    }


    onKillProcess(processId: number, desktopServerId: number): void {
        this.scriptExecutionDaoService.killProcess(desktopServerId, processId).subscribe((response) => {
            if (response) {
                console.debug('onKillProcess: ' + response);
                // this.setForm();
            }
        });
    }

    setDisconnectLoading(pos: number): void {
        this.disconnectLoading[pos] = true;
        setTimeout(() => {
            this.disconnectLoading[pos] = false;
        }, 8000);
    }

    setForceDisconnectLoading(pos: number): void {
        this.forceDisconnectLoading[pos] = true;
        setTimeout(() => {
            this.forceDisconnectLoading[pos] = false;
        }, 8000);
    }

    openDesktopServerADAdminCustomUser(desktopServerId: number, userId: number, isPoolUser: boolean, subProjectId: number): void {
        this.stomp.send('/stomp/open-ad-admin-custom-user', {
            desktopServerId: desktopServerId,
            userId: userId,
            isPoolUser: isPoolUser,
            subProjectId: subProjectId,
        });
    }

    openDesktopServerADAdminCustomUserLocalAdmin(desktopServerId: number, userId: number): void {
        this.stomp.send('/stomp/open-ad-admin-custom-user-local-admin', {
            desktopServerId: desktopServerId,
            userId: userId,
            isPoolUser: 0,
            subProjectId: 0,
        });
    }


    onUnlockUserADAccount(aDLogin: string, desktopServerId: number): void {
        this.scriptExecutionDaoService.unlockUserADAccount(desktopServerId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onUnlockUserADAccount: ' + response);
            }
        });
    }

    onEnableUserADAccount(aDLogin: string, desktopServerId: number): void {
        this.scriptExecutionDaoService.enableUserADAccount(desktopServerId, ServerType.WINDOWS, aDLogin).subscribe((response) => {
            if (response) {
                console.debug('onEnableUserADAccount: ' + response);
            }
        });
    }
}


import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RdpClientUIPropertiesService {
    private b: any;
    private c: any;
    private d: any;

    constructor() {
        this.c = window.sessionStorage;
        this.d = {};
        if (!this.c) {
            throw new Error('Local storage is not supported');
        }
        try {
            this.b = !!JSON.parse(this.c.getItem('rdpClientUI.suppressClipHelpGlobally'));
        } catch (a) {
            this.b = !1;
        }
    }

    getSuppressClipHelpForConnection(a: any): any {
        return !(!this.b && !this.d[a]);
    }

    setSuppressClipHelpForConnection(a: any, b: any): void {
        this.d[a] = !!b;
    }

    setSuppressClipHelpGlobally(a: any): void {
        this.b = !!a, this.c.setItem('rdpClientUI.suppressClipHelpGlobally', JSON.stringify(this.b));
    }

}

import {Component, ViewChild} from '@angular/core';
import {ImageFileService} from '../image-file/image-file-service';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CustomerGroupCreateEditComponent} from '../customer-group/customer-group-create-edit/customer-group-create-edit.component';
import {CrudOperationWrapper} from '../../helpers/kluh';
import {MatDialog} from '@angular/material/dialog';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {CustomerGroup, CustomerGroupLicensesDashboard} from '../../models';
import {ComponentCleaner} from '../../component-cleaner';
import {FormControl} from '@angular/forms';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {MatOption} from '@angular/material/core';
import {CustomerGroupAiConfigDaoService} from '../ai/ai-config/customer-group-ai-config.dao.service';
import {concatMap, map} from 'rxjs/operators';

@Component({
    selector: 'app-customer-groups-dashboard',
    templateUrl: './customer-groups-dashboard.component.html',
    styleUrls: ['./customer-groups-dashboard.component.scss']
})
export class CustomerGroupsDashboardComponent extends ComponentCleaner {

    displayedColumns: string[] = [
        'position',
        'image',
        'customerGroupName',
        'customerGroupLocalAdminUsersOnly',
        'customerGroupCreatedAt',
        'amountOfContractedUsers',
        'amountOfManagerUsers',
        'differenceBetweenContractedUsersAndCreatedUsers',
        'hasR2Ai',
        'amountOfCustomerGroupsExternalADUsers',
        'amountOfCustomerGroupsADUsers',
        'amountOfCustomerGroupsADUsersPool',
        'amountOfDesktopServer',
        'amountOfLinuxServer',
        'actions'];
    dataSource: MatTableDataSource<CustomerGroupLicensesDashboard>;
    amountOfManagerUsers = 0;
    amountOfExternalADUsers = 0;
    amountOfLocalAdminUsers = 0;
    amountOfCommonUsers = 0;
    amountOfADUsers = 0;
    amountOfADUsersPool = 0;
    emptyCustomerGroupLicensesDashboard: CustomerGroupLicensesDashboard;
    partnerCustomerGroupControl: FormControl = new FormControl();
    getManagerUserCreatedCountControl: FormControl = new FormControl(false);
    amountOfContractedUsers = 0;
    amountOfContractedCommonUsers = 0;
    amountOfContractedUsersLocalAdminADUsers = 0;

    amountOfDesktopServers = 0;
    amountOfLinuxServers = 0;
    amountOfDesktopServersADUser = 0;
    amountOfLinuxServersADUser = 0;
    amountOfDesktopServersLocalAdmin = 0;
    amountOfLinuxServersLocalAdmin = 0;

    customerGroupLicensesDashboard: CustomerGroupLicensesDashboard[] = [];
    partnerCustomerGroups: CustomerGroupLicensesDashboard[] = [];
    customerGroupAiConfigIds: number[] = [];

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    daysCreatedControl: FormControl = new FormControl();

    @ViewChild('allSelected') private allSelected: MatOption;

    constructor(public imageFileService: ImageFileService,
                private customerGroupDaoService: CustomerGroupDaoService,
                private dialog: MatDialog,
                private customerGroupAiConfigDaoService: CustomerGroupAiConfigDaoService,
                public customerGroupService: CustomerGroupService) {
        super();

        this.emptyCustomerGroupLicensesDashboard = {
            customerGroupId: -2,
            customerGroupName: null,
            customerGroupLocalAdminUsersOnly: null,
            partnerCustomerGroupId: null,
            customerGroupImageUUID: null,
            amountOfContractedUsers: null,
            amountOfManagerUsers: null,
            differenceBetweenContractedUsersAndCreatedUsers: null,
            customerGroupCreatedAt: null,
            amountOfCustomerGroupsADUsers: null,
            amountOfCustomerGroupsADUsersPool: null,
            amountOfCustomerGroupsExternalADUsers: null,
            amountOfDesktopServer: null,
            amountOfLinuxServer: null,
            hasR2Ai: false
        };

        combineLatest([this.getManagerUserCreatedCountControl.valueChanges, this.daysCreatedControl.valueChanges]).subscribe((value) => {
            const checkBox = value[0];
            const days = value[1];
            if (checkBox) {
                this.populateData(checkBox, days);
            }
        });
        this.daysCreatedControl.setValue('30');
        this.populateData(false, 0);


        this.partnerCustomerGroupControl.valueChanges.subscribe((customerGroupIds: any[]) => {
            if (customerGroupIds) {
                const filteredCustomerGroupLicensesDashboard: CustomerGroupLicensesDashboard[] = [];
                this.customerGroupLicensesDashboard.forEach((customerGroupIn) => {
                    if (customerGroupIn && customerGroupIds.indexOf(customerGroupIn.partnerCustomerGroupId) > -1 ||
                        (customerGroupIds.indexOf(-2) > -1 && !customerGroupIn.partnerCustomerGroupId) ||
                        customerGroupIds.indexOf(0) > -1) {
                        filteredCustomerGroupLicensesDashboard.push(customerGroupIn);
                    }
                });
                this.changeData(filteredCustomerGroupLicensesDashboard);
            } else {
                this.changeData(this.customerGroupLicensesDashboard);
            }
        });
    }

    tosslePerOne(): void {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
        } else {
            if (this.partnerCustomerGroupControl.value.length === (this.partnerCustomerGroups.length + 1)) {
                this.allSelected.select();
            }
        }
    }

    toggleAllSelection(): void {
        if (this.allSelected.selected) {
            const partnerCustomerGroups: CustomerGroupLicensesDashboard[] = [];
            this.partnerCustomerGroups.forEach(val => partnerCustomerGroups.push(Object.assign({}, val)));
            partnerCustomerGroups.push(this.emptyCustomerGroupLicensesDashboard);
            this.partnerCustomerGroupControl
                .patchValue([...partnerCustomerGroups.map(item => item.customerGroupId), 0]);

        } else {
            this.partnerCustomerGroupControl.patchValue([]);
        }
    }

    private populateData(showCreated: boolean, days: number): void {
        this.customerGroupDaoService.customerGroupLicensesDashboard(showCreated, days).pipe(
            concatMap(firstResult =>
                this.customerGroupAiConfigDaoService.getAllIds().pipe(
                    map(secondResult => ({firstResult, secondResult}))
                )
            )
        ).subscribe((values) => {
            const customerGroupLicensesDashboards: CustomerGroupLicensesDashboard[] = values.firstResult;
            const customerGroupAiConfigIds: number[] = values.secondResult;
            if (customerGroupLicensesDashboards) {
                this.customerGroupLicensesDashboard = customerGroupLicensesDashboards;
                this.customerGroupAiConfigIds = customerGroupAiConfigIds;
                this.populateR2AiColumn();
                this.customerGroupLicensesDashboard.forEach(partnerCustomerGroup => {
                    if (partnerCustomerGroup && partnerCustomerGroup.partnerCustomerGroupId) {
                        const customerGroup = this.customerGroupLicensesDashboard.find(
                            c => c.customerGroupId === partnerCustomerGroup.partnerCustomerGroupId
                        );
                        if (customerGroup && this.partnerCustomerGroups.findIndex(c => c?.customerGroupId === customerGroup?.customerGroupId) < 0) {
                            this.partnerCustomerGroups.push(customerGroup);
                        }
                    }
                });
                this.changeData(this.customerGroupLicensesDashboard);
            }
        });
    }

    private populateR2AiColumn(): void {
        this.customerGroupAiConfigIds.forEach(id => {
            const index = this.customerGroupLicensesDashboard.findIndex(c => c.customerGroupId === id);
            if (index > -1) {
                this.customerGroupLicensesDashboard[index].hasR2Ai = true;
            }
        });
    }

    changeData(customerGroupLicensesDashboards: CustomerGroupLicensesDashboard[]): void {
        this.amountOfManagerUsers = 0;
        this.amountOfContractedUsers = 0;
        this.amountOfADUsers = 0;
        this.amountOfLocalAdminUsers = 0;
        this.amountOfExternalADUsers = 0;
        this.amountOfADUsersPool = 0;
        this.amountOfCommonUsers = 0;
        this.amountOfContractedCommonUsers = 0;
        this.amountOfContractedUsersLocalAdminADUsers = 0;

        this.amountOfDesktopServers = 0;
        this.amountOfLinuxServers = 0;
        this.amountOfDesktopServersADUser = 0;
        this.amountOfLinuxServersADUser = 0;
        this.amountOfDesktopServersLocalAdmin = 0;
        this.amountOfLinuxServersLocalAdmin = 0;

        this.dataSource = new MatTableDataSource(customerGroupLicensesDashboards);
        for (const managerUserOfCustomerGroup of customerGroupLicensesDashboards) {
            this.amountOfManagerUsers += managerUserOfCustomerGroup.amountOfManagerUsers;
            this.amountOfContractedUsers += managerUserOfCustomerGroup.amountOfContractedUsers;

            this.amountOfDesktopServers += managerUserOfCustomerGroup.amountOfDesktopServer;
            this.amountOfLinuxServers += managerUserOfCustomerGroup.amountOfLinuxServer;

            if (managerUserOfCustomerGroup.customerGroupLocalAdminUsersOnly) {
                this.amountOfLocalAdminUsers += managerUserOfCustomerGroup.amountOfManagerUsers;
                this.amountOfContractedUsersLocalAdminADUsers += managerUserOfCustomerGroup.amountOfContractedUsers;
                this.amountOfDesktopServersLocalAdmin += managerUserOfCustomerGroup.amountOfDesktopServer;
                this.amountOfLinuxServersLocalAdmin += managerUserOfCustomerGroup.amountOfLinuxServer;
            } else {
                this.amountOfCommonUsers += managerUserOfCustomerGroup.amountOfManagerUsers;
                this.amountOfADUsers += managerUserOfCustomerGroup.amountOfCustomerGroupsADUsers;
                this.amountOfContractedCommonUsers += managerUserOfCustomerGroup.amountOfContractedUsers;
                this.amountOfDesktopServersADUser += managerUserOfCustomerGroup.amountOfDesktopServer;
                this.amountOfLinuxServersADUser += managerUserOfCustomerGroup.amountOfLinuxServer;
            }

            this.amountOfExternalADUsers += managerUserOfCustomerGroup.amountOfCustomerGroupsExternalADUsers;
            this.amountOfADUsersPool += managerUserOfCustomerGroup.amountOfCustomerGroupsADUsersPool;

        }
        this.dataSource.sort = this.sort;
    }

    onEditCustomerGroup(customerGroupId: number): void {
        const customerGroupSelected = this.customerGroupService.getCustomerGroup(customerGroupId);
        if (customerGroupSelected) {
            const dialogRef = this.dialog.open(CustomerGroupCreateEditComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog-x-large',
                data: {
                    customerGroup: customerGroupSelected
                }
            });
            const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
                this.dialogRefCallback(result);
            });
            this.addSubscription(subscription);
        }
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'SAVE') {
            if (this.customerGroupService.customerGroups) {
                this.updateCustomerGroupOnCustomerGroupService(result.data);
                this.updateCustomerGroupLicensesDashboard(result.data);
                this.changeData(this.customerGroupLicensesDashboard);
            }
        }
    }

    private updateCustomerGroupOnCustomerGroupService(customerGroup: CustomerGroup): void {
        const index1 = this.customerGroupService.customerGroups.findIndex(value => value.id === customerGroup.id);
        this.customerGroupService.customerGroups[index1] = customerGroup;
        if (customerGroup.id === this.customerGroupService.customerGroup.id) {
            this.customerGroupService.customerGroup = customerGroup;
        }
    }

    private updateCustomerGroupLicensesDashboard(customerGroup: CustomerGroup): void {
        const index = this.customerGroupLicensesDashboard.findIndex(value => value.customerGroupId === customerGroup.id);
        this.customerGroupLicensesDashboard[index].customerGroupName = customerGroup.name;
        this.customerGroupLicensesDashboard[index].partnerCustomerGroupId = customerGroup.partnerCustomerGroupId;
        this.customerGroupLicensesDashboard[index].amountOfContractedUsers = customerGroup.contractedUsers;
        this.customerGroupLicensesDashboard[index].differenceBetweenContractedUsersAndCreatedUsers =
            customerGroup.contractedUsers - this.customerGroupLicensesDashboard[index].amountOfManagerUsers;
    }

    getTotalCustomerGroupsLocalAdminUsersOnly(): number {
        let totalCustomerGroupsLocalAdminUsersOnly = 0;
        this.dataSource?.data?.forEach(row => {
            if (row.customerGroupLocalAdminUsersOnly) {
                totalCustomerGroupsLocalAdminUsersOnly++;
            }
        });
        return totalCustomerGroupsLocalAdminUsersOnly;
    }

    getTotalCustomerGroupsWithoutLocalAdminUsersOnly(): number {
        let totalCustomerGroupsWithoutLocalAdminUsersOnly = 0;
        this.dataSource?.data?.forEach(row => {
            if (!row.customerGroupLocalAdminUsersOnly) {
                totalCustomerGroupsWithoutLocalAdminUsersOnly++;
            }
        });
        return totalCustomerGroupsWithoutLocalAdminUsersOnly;
    }
}

<div class="product-detail-content-tab p-35">
    <mat-accordion class="mt-16">
        <mat-expansion-panel class="accordion-content primary-50-bg" *ngFor="let fqa of marketPlaceProductFQAList">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{fqa.question}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{fqa.answer}}</p>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="marketPlaceProductFQAList.length < 1"  fxLayout="row" fxLayoutAlign="center center" fxFill  class="mt-30">
        <button type="button" mat-raised-button color="accent" (click)="onEdit()">Criar FAQ</button>
    </div>
</div>
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HipcomBulkUpdateDetail} from '../../../../../models';
import {convertToFormGroup, CrudOperationWrapper} from '../../../../../helpers/kluh';
import {HipcomBulkUpdateDetailDaoService} from '../hipcom-bulk-update-detail/hipcom-bulk-update-detail-dao.service';

@Component({
    selector: 'app-partners-hipcom-bulk-update-schedule-create-edit',
    templateUrl: './hipcom-bulk-update-schedule-create-edit.component.html',
    styleUrls: ['./hipcom-bulk-update-schedule-create-edit.component.scss']
})
export class HipcomBulkUpdateScheduleCreateEditComponent implements OnInit {

    myForm: FormGroup;
    hipcomBulkUpdateDetail: HipcomBulkUpdateDetail;

    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdateScheduleCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private hipcomBulkUpdateDetailDaoService: HipcomBulkUpdateDetailDaoService,
                private fb: FormBuilder) {
        this.hipcomBulkUpdateDetail = this.data.hipcomBulkUpdateDetail;
        if (!this.hipcomBulkUpdateDetail) {
            this.dialogRef.close();
        }
        this.myForm = this.fb.group(convertToFormGroup(this.hipcomBulkUpdateDetail));
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        const formValue: HipcomBulkUpdateDetail = this.myForm.value;
        this.hipcomBulkUpdateDetailDaoService.save(formValue).subscribe(hipcomBulkUpdateDetail => {
            const crudOperation: CrudOperationWrapper = {
                data: hipcomBulkUpdateDetail,
                operation: 'SAVE'
            };
            this.dialogRef.close(crudOperation);
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}

import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseDao} from '../../base-dao';
import {CanAccess, ManagerUserAccessPeriodProfile} from '../../models';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class ManagerUserAccessPeriodProfileDaoService extends BaseDao<ManagerUserAccessPeriodProfile> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'manager-user-access-period-profile');
    }



    canAccess(): Observable<CanAccess> {
        return this.http.get<CanAccess>(this.url + '/can-access');
    }
}

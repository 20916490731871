<form [formGroup]="myForm" (submit)="(myForm.valid && myForm.dirty) && onSubmit()" autocomplete="off">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Configuração dos aplicativos</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div #contentRef class="mat-white-bg mat-elevation-z2 p-10 overflow-x-hidden" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>

        <div formArrayName="myFormArray">
            <mat-card *ngFor="let bulkUpdate of myFormControls.controls; let i = index"
                      [formGroupName]="i">
                <mat-card-content>
                    <div>

                        <mat-form-field appearance="outline" class="mr-30 width-100-percent">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="name" required autocomplete="off">
<!--                            <mat-error>-->
<!--                                {{getControlsOfFormGroup(bulkUpdate).controls.name.errors | error}}-->
<!--                            </mat-error>-->
                        </mat-form-field>


                        <mat-form-field appearance="outline" class="mr-30 width-100-percent">
                            <mat-label>Nome da Pasta</mat-label>
                            <input matInput formControlName="folderName" required autocomplete="off">
                            <!--                            <mat-error>-->
                            <!--                                {{getControlsOfFormGroup(bulkUpdate).controls.name.errors | error}}-->
                            <!--                            </mat-error>-->
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="mr-30 width-100-percent" >
                            <mat-label>Nome do arquivo executável</mat-label>
                            <input matInput formControlName="executableName" required autocomplete="off">
<!--                            <mat-error>-->
<!--                                {{getControlsOfFormGroup(bulkUpdate).controls.name.errors | error}}-->
<!--                            </mat-error>-->
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="mr-30 width-100-percent">
                            <mat-label>Link do arquivo .ZIP do pacote</mat-label>
                            <input matInput formControlName="link" required autocomplete="off">
<!--                            <mat-error>-->
<!--                                {{getControlsOfFormGroup(bulkUpdate).controls.name.errors | error}}-->
<!--                            </mat-error>-->
                        </mat-form-field>

                    </div>

                    <button type="button" class="float-right" mat-raised-button color="warn"
                            (click)="onRemove(bulkUpdate.value)">Delete
                    </button>
                    <div style="clear: both"></div>
                </mat-card-content>
            </mat-card>
        </div>

        <button type="button" mat-raised-button color="primary" class="text-align-center mt-20 mb-30"
                (click)="onHipcomBulkUpdate()">Add
        </button>


    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
         matDialogActions>
        <button type="button" mat-raised-button color="accent" (click)="onSubmit()"
                [disabled]="(!myForm.valid || myForm.pristine)"
        >Salvar</button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

    </div>
</form>


import {RdpCredential} from './rdp-credential';

declare const Module;

export class CredentialRequest {
    constructor(private a: any) {
        
    }

    getOriginal(): any {
        return this.a;
    }
    
    credentialHint(): any {
        let b = '', c = '';
        return this.a.getAuthType() === Module.IAuthCompletion_AuthType.UsernameAndPassword && (b = this.a.GetUserNameHint(), c = this.a.GetDomainHint()), b || (b = ''), 
            c ? c += '\\' : c = '', c + b;
    }

    complete(b: RdpCredential): void {
        let c = b.userName,
            d = '',
            f = -1;
        const e = b.password || '';
        (f = b.userName.indexOf('\\')) > -1 && (d = b.userName.substring(0, f), c = b.userName.substring(f + 1));
        try {
            this.a.complete(c, d, e), this.a.delete(), this.a = null;
        } catch (a) {
            this.a = null;
        }
    }

    completeWithToken(b: any): void {
        try {
            this.a.complete(b), this.a.delete(), this.a = null;
        } catch (a) {
            this.a = null;
        }
    }

    isTokenRequired(): any {
        return this.a.getAuthType() === Module.IAuthCompletion_AuthType.ClaimsToken;
    }

    cancel(): void {
        try {
            this.a.cancel(), this.a.delete(), this.a = null;
        } catch (a) {
            this.a = null;
        }
    }
}

<div class="page-layout simple fullwidth" fusePerfectScrollbar>
  <!-- CONTENT -->
  <div class="content p-24 mat-white-bg">
    <button type="button" class="mb-8" mat-raised-button (click)="onCreateProject()" >Criar</button>
    <app-project-list [refresh]="onRefresh" [customerGroups]="customerGroups">

    </app-project-list>


  </div>
  <!-- / CONTENT -->

</div>

<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">
            R2 Security - todos os servers
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-8" matDialogContent>

        <mat-card class="mt-15">
            <mat-card-content class="padding-none">
                    <div *appIsAdmin class="full-width" fxLayout="row" style="padding: 10px 10px 0 10px;"
                         fxLayoutAlign="start center">
                        <div>
                            <mat-form-field class="full-width min-width-450" style="max-width: 450px;"
                                            appearance="outline">
                                <mat-label>Selecionar parceiro</mat-label>
                                <mat-select class="full-width" placeholder="Selecionar parceiro"
                                            [formControl]="partnerCustomerGroupControl" multiple>
                                    <mat-option [value]="0" #allSelected (click)="toggleAllSelection()">Todos
                                    </mat-option>
                                    <mat-option [value]="-2" (click)="tosslePerOne()">Sem parceiro</mat-option>
                                    <mat-option
                                        *ngFor="let partnerCustomerGroup of partnerCustomerGroups; let i = index;"
                                        [value]="partnerCustomerGroup.id" (click)="tosslePerOne()">
                                        {{ partnerCustomerGroup.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="full-width ml-15" appearance="outline">
                                <mat-label>Regras:</mat-label>
                                <mat-select class="full-width" placeholder="Regras"
                                            [formControl]="gatewayServerInUseControl">
                                    <mat-option [value]="'ALL'">Todos</mat-option>
                                    <mat-option [value]="'WITH_NAT'">Com NAT</mat-option>
                                    <mat-option [value]="'WITHOUT_NAT'">Sem NAT</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                <div *appIsAdmin class="full-width" fxLayout="row" style="padding: 10px 10px 0 10px;"
                     fxLayoutAlign="space-between center">
                    <div><b>Total:</b>  {{ gatewayServerFilteredList.length }}</div>
                    <div><b>Online:</b>  {{ countBooleanField(gatewayServerFilteredList, 'online', true) }}</div>
                    <div><b>Offline:</b>  {{ countBooleanField(gatewayServerFilteredList, 'online', false) }}</div>
                </div>
            </mat-card-content>
        </mat-card>

        <table *ngIf="(gatewayServerFilteredList && gatewayServerFilteredList.length > 0)"
               class="app-table" border="0" cellspacing="0">
            <thead>
            <tr class="bg-white">
                <th></th>
                <th class="p-20">Empresa</th>
                <th class="p-20">Nome gateway</th>
                <th class="p-20">Projeto</th>
                <th></th>
            </tr>
            </thead>
            <tr *ngFor="let gatewayServer of gatewayServerFilteredList">
                <td class="w-30 p-20">
                    <div>
                        <div class="client-connected"
                             title="Online {{gatewayServer.lastStatusUpdatedAt | date: 'dd/MM/yyyy HH:mm'}}"
                             *ngIf="gatewayServer.online; else offline">
                            <fa-icon [icon]="faCircle"></fa-icon>
                        </div>
                        <ng-template #offline>
                            <div class="client-not-connected"
                                 title="Offline {{gatewayServer.lastStatusUpdatedAt | date: 'dd/MM/yyyy HH:mm'}}">
                                <fa-icon [icon]="faCircle"></fa-icon>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td class="w-210 p-20">
                    {{ customerGroupService.getCustomerGroup(gatewayServer.customerGroupId).name }}
                </td>
                <td class="w-150 p-20">
                    {{ gatewayServer.name }}
                </td>
                <td class="w-300 p-20">
                    <ul *ngIf="findGatewayServerInUseByGatewayServerId(gatewayServer.id)">
                        <li *ngFor="let gatewayServerInUs of findGatewayServerInUseByGatewayServerId(gatewayServer.id)">
                            {{ gatewayServerInUs.subProjectName }}
                        </li>
                    </ul>
                </td>
                <td class="text-align-right p-0">
                    <div fxLayout="row" fxLayoutAlign="end start">
                        <div class="mr-10">
                            <button type="button" mat-stroked-button color="primary" class="mat-green-600-bg "
                                    (click)="changeCustomerGroupGatewayServerList(customerGroupService.getCustomerGroup(gatewayServer.customerGroupId))"
                            >Abrir
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        </table>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

    </div>
</div>
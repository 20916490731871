import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AuthInfoService} from '../../auth-info/auth-info.service';
import {map, take} from 'rxjs/operators';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {StompService} from '../../../stomp/stomp.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoringStompService extends StompService implements OnDestroy {
    private monitoringUrl;

    constructor(private authInfoService: AuthInfoService,
                private domainPathService: DomainPathService
    ) {
        super();
        this.monitoringUrl = this.domainPathService.monitoringWSS + 'stomp/websocket';
    }

    protected url(): Observable<string> {
        return this.authInfoService.getFirebaseToken().pipe(map((token: string) => {
            return this.monitoringUrl + '?xFirebaseAuth=' + token + '&webClientId=' + this.authInfoService.getWebClientId();
        }));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ADUserWithPassword} from '../../../models';
import {ComponentCleaner} from '../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {convertToFormGroup} from '../../../helpers/kluh';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';

@Component({
    selector: 'app-edit-ad-user-password',
    templateUrl: './edit-ad-user-password.component.html',
    styleUrls: ['./edit-ad-user-password.component.scss']
})
export class EditAdUserPasswordComponent extends ComponentCleaner {
    myForm: FormGroup;
    public adUserWithPassword: ADUserWithPassword = {
        adUser: null,
        askThePassword: null,
        adUserPassword: null,
        adUserAlreadyExists: null,
        subProjectId: null
    };

    constructor(
        public dialogRef: MatDialogRef<EditAdUserPasswordComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private adUserDaoService: AdUserDaoService
    ) {
        super();
        this.adUserWithPassword.adUser = data.adUser;
        this.myForm = this.fb.group(convertToFormGroup(this.adUserWithPassword), {});
    }

    onSave(): void {
        this.adUserWithPassword = this.myForm.value;
        this.adUserDaoService.saveAdUserPasswordWithoutConfirmation(this.adUserWithPassword).subscribe((adUser) => {
            if (adUser) {
                this.dialogRef.close(adUser);
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }


}

<div class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
         style="z-index: 99;margin-top: 25px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="mr-10 font-size-24">shopping_basket</mat-icon>
            <span class="h1 m-0">Pedidos no marketplace </span>
        </div>
        <div>
            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="search" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input placeholder="buscar serviço"
                           id="marketplace-order-search" 
                           [formControl]="searchMultiFilterCtrl" matInput fxFlex autocomplete="off">
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 20px;margin-left: 15px">
        <div>
            <mat-tab-group animationDuration="0ms" disableRipple="true" (selectedTabChange)="onSelectOrderType($event)">
                <mat-tab label="Pedidos">
                    <div>
                        <div class="h1 text-align-center" style="margin: 20px;">Não lidas</div>
                        <app-marketplace-order-table
                            [marketplaceOrderList]="marketplaceOrderUnReadFilterList"
                            [marketplaceProductList]="marketplaceProductList"
                            [unReadList]="true"
                            (marketplaceOrderListEvent)="onUpdateMarketplaceOrderList($event)"
                        ></app-marketplace-order-table>
                        <div class="h1 text-align-center" style="margin: 60px 20px 20px 20px;">Lidas</div>
                            <app-marketplace-order-table
                                [marketplaceOrderList]="marketplaceOrderReadFilterList"
                                [marketplaceProductList]="marketplaceProductList"
                            ></app-marketplace-order-table>
                    </div>
                </mat-tab>
                <mat-tab label="Cancelados">
                        <app-marketplace-order-table
                            [marketplaceOrderList]="marketplaceOrderCancelFilterList"
                            [marketplaceProductList]="marketplaceProductList"
                        ></app-marketplace-order-table>
                </mat-tab>
                <mat-tab label="Concluídos">
                    <app-marketplace-order-table
                        [marketplaceOrderList]="marketplaceOrderConcludedFilterList"
                        [marketplaceProductList]="marketplaceProductList"
                    ></app-marketplace-order-table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DigitalCertificateDaoService, FileStatus, LocalUploadFile} from '../digital-certificate-dao.service';
import {FormControl, ValidationErrors} from '@angular/forms';
import {ComponentCleaner} from '../../../component-cleaner';
import {ADUser, ADUserPool, ADUserPoolRelationship, DesktopServer, DigitalCertificate, DigitalCertificateInstall, ManagerUser, SubProject} from '../../../models';
import {ImageFileService} from '../../image-file/image-file-service';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {DigitalCertificateInstallDaoService} from './digital-certificate-install-dao.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {AdUserPoolRelationshipDaoService} from '../../ad-user-pool-relationship/ad-user-pool-relationship-dao.service';
import {AdUserPoolDaoService} from '../../ad-user-pool/ad-user-pool-dao.service';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';

@Component({
    selector: 'app-send-digital-certificate',
    templateUrl: './send-digital-certificate.component.html',
    styleUrls: ['./send-digital-certificate.component.scss']
})
export class SendDigitalCertificateComponent extends ComponentCleaner implements OnInit {
    PLUGIN_NAME = 'Certificado Digital';
    FileStatus = FileStatus;
    digitalCertificate: DigitalCertificate;
    localUploadFiles: LocalUploadFile[] = [];
    digitalCertificateInstallList: DigitalCertificateInstall[] = [];
    managerUsers: ManagerUser[] = [];
    sendingFiles = false;
    finished = false;
    passwordError = false;
    subProjectId = 0;
    desktopServerId = 0;
    customerGroupId = 0;
    loading = false;
    error = false;
    errorMessage = '';

    allowedExtensions = ['.pem', '.crt', '.cer', '.key', '.der', '.pfx', '.p12', '.p7b', '.p7c', 'jks'];
    fileSizeLimit = 2 * (1024 * 1024);
    passwordControl: FormControl = new FormControl();
    repeatPasswordControl: FormControl = new FormControl();
    subProjectControl: FormControl = new FormControl();
    desktopServerControl: FormControl = new FormControl();
    subProjects: SubProject[] = [];
    desktopServers: DesktopServer[] = [];
    adUsers: ADUser[] = [];
    adUsersPoolRelationshipAll: ADUserPoolRelationship[] = [];
    adUsersPoolList: ADUserPool[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private elementRef: ElementRef,
                public dialogRef: MatDialogRef<SendDigitalCertificateComponent>,
                public imageFileService: ImageFileService,
                private subProjectDaoService: SubProjectDaoService,
                private desktopServerDaoService: DesktopServerDaoService,
                private adDomainDaoService: AdDomainDaoService,
                private adUserDaoService: AdUserDaoService,
                private adUserPoolDaoService: AdUserPoolDaoService,
                private adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
                private managerUserDaoService: ManagerUserDaoService,
                private digitalCertificateInstallDaoService: DigitalCertificateInstallDaoService,
                private digitalCertificateDaoService: DigitalCertificateDaoService) {
        super();
        this.digitalCertificate = this.data.digitalCertificate;
        this.desktopServerId = this.data.desktopServerId;
        this.desktopServerId = this.data.desktopServerId;
        this.subProjectId = this.data.subProjectId;
        this.customerGroupId = this.data.customerGroupId;
        if (this.data.files) {
            this.localUploadFiles = this.fileListToLocalUploadFileList(this.data.files, this.allowedExtensions);
        }
    }

    ngOnInit(): void {
        this.loadSubProjects(this.customerGroupId);

        this.addSubscription(this.subProjectControl.valueChanges.subscribe((subProjectId) => {
            this.loadDesktopServers(subProjectId);
            this.loadUsers(subProjectId);
        }));
        this.addSubscription(this.desktopServerControl.valueChanges.subscribe((desktopServerId) => {
            this.loadDigitalCertificateInstall(this.digitalCertificate?.id, desktopServerId);
        }));
        this.addSubscription(this.addSubscription(this.passwordControl.valueChanges.subscribe(_ => {
            this.checkPassword();
        })));
        this.addSubscription(this.addSubscription(this.repeatPasswordControl.valueChanges.subscribe(_ => {
            this.checkPassword();
        })));

        this.addTimeout(setInterval(() => {
            this.loadDigitalCertificateInstall(this.digitalCertificate?.id, +this.desktopServerControl.value);
        }, 40000));
    }

    private loadSubProjects(customerGroupId: number): void {
        this.unloadSubProjects();
        if (customerGroupId) {
            this.addSubscription(this.subProjectDaoService.filter({'customerGroupId': customerGroupId}).subscribe((subProjects) => {
                if (subProjects) {
                    this.subProjects = subProjects;
                    this.subProjectControl.setValue(this.subProjects[0].id);
                }
            }));
        }
    }

    private unloadSubProjects(): void {
        this.subProjects = [];
        this.subProjectControl.setValue('');
    }

    private unloadAll(): void {
        this.subProjects = [];
        this.desktopServers = [];
        this.digitalCertificateInstallList = [];
        this.adUsers = [];
        this.adUsersPoolRelationshipAll = [];
        this.managerUsers = [];
        this.adUsersPoolList = [];
    }

    private refreshWithTimer(timer: number): void {
        this.loading = true;
        this.unloadAll();
        setTimeout(() => {
            this.loadSubProjects(this.customerGroupId);
            this.loading = false;
        }, timer);
    }

    private loadDesktopServers(subProjectId: number): void {
        this.unloadDesktopServers();
        if (subProjectId) {
            this.addSubscription(this.desktopServerDaoService.findAllWithPlugin(subProjectId, this.PLUGIN_NAME).subscribe((desktopServers) => {
                if (desktopServers) {
                    this.desktopServers = desktopServers;
                    if (desktopServers.length === 1) {
                        this.desktopServerControl.setValue(this.desktopServers[0].id);
                    }
                }
            }));
        }
    }


    private unloadDesktopServers(): void {
        this.desktopServers = [];
        this.desktopServerControl.setValue('');
    }

    private loadDigitalCertificateInstall(digitalCertificateId: number, desktopServerId: number): void {
        if (digitalCertificateId && desktopServerId) {
            this.digitalCertificateInstallList = [];
            this.addSubscription(this.digitalCertificateInstallDaoService.findAllByDigitalCertificateIdAndDesktopServerId(digitalCertificateId, desktopServerId).subscribe(
                (digitalCertificateInstallList) => {
                    this.digitalCertificateInstallList = digitalCertificateInstallList;
                }));
        }
    }

    private loadUsers(subProjectId: number): void {
        this.adUsers = [];
        this.adUsersPoolRelationshipAll = [];
        this.managerUsers = [];
        this.adUsersPoolList = [];
        if (subProjectId) {
            this.populateADUserPoolList(subProjectId);
            const adUsers$ = this.adUserDaoService.findAllBySubProjectId(subProjectId , false);
            const adUsersPoolRelationshipAll$ = this.adUserPoolRelationshipDaoService.filter({subProjectId: subProjectId});
            const managerUsers$ = this.managerUserDaoService.filter({subProjectId: subProjectId});
            const managerUserOfADUserPoolBySubProjectIds$ = this.managerUserDaoService.findAllManagerUserOfADUserPoolBySubProjectId(subProjectId);

            this.addSubscription(forkJoin([adUsers$, adUsersPoolRelationshipAll$, managerUsers$, managerUserOfADUserPoolBySubProjectIds$]).subscribe((
                result: [ADUser[], ADUserPoolRelationship[], ManagerUser[], ManagerUser[]]) => {
                this.adUsers = result[0];
                this.adUsersPoolRelationshipAll = result[1];
                this.managerUsers = result[2]?.concat(result[3]);
            }));
        }
    }

    private populateADUserPoolList(subProjectId: number): void {
        this.addSubscription(this.adDomainDaoService.filter({'subProjectId': subProjectId}).subscribe((adDomains) => {
            if (adDomains && adDomains.length > 0) {
                const adDomainIds = Object.values(adDomains).map(adDomain => {
                    return adDomain.id;
                });
                this.adUserPoolDaoService.findAllByADDomainIds(adDomainIds.toString()).subscribe((adUsersPool) => {
                    if (adUsersPool) {
                        this.adUsersPoolList = adUsersPool;
                    }
                });
            }
        }));
    }

    hasInstallation(): boolean {
        return this.digitalCertificateInstallList.length > 0;
    }

    findManagerUser(id: number): ManagerUser {
        return this.managerUsers.find(managerUser => managerUser.id === id);
    }

    findDigitalCertificateInstall(adUserLogin: string): DigitalCertificateInstall {
        const desktopServerId = +this.desktopServerControl.value;
        return this.digitalCertificateInstallList?.find(dci => dci.adUserLogin === adUserLogin && dci.desktopServerId === desktopServerId);
    }

    private fileListToLocalUploadFileList(files: FileList, allowedExtensions: string[]): LocalUploadFile[] {
        const localUploadFiles: LocalUploadFile[] = [];
        Array.from(files).forEach((file, i) => {
            const localUploadFile: LocalUploadFile = {
                name: file.name,
                size: file.size,
                position: i,
                blocked: this.hasRejectExtensions(file.name, allowedExtensions) || this.fileSizeLimit < file.size,
                status: null
            };
            localUploadFiles.push(localUploadFile);
        });
        return localUploadFiles;
    }

    private hasRejectExtensions(fileName: string, allowedExtensions: string[]): boolean {
        const extension = fileName.toLowerCase().split('.').pop();
        return !allowedExtensions.includes(`.${extension}`);
    }

    hasBlockedFile(localUploadFiles: LocalUploadFile[]): boolean {
        return localUploadFiles.findIndex((luf) => luf.blocked === true) > -1;
    }

    private checkPassword(): void {
        const password = this.passwordControl.value;
        const passwordRepeat = this.repeatPasswordControl.value;
        if (password !== passwordRepeat) {
            const errors: ValidationErrors = {};
            this.repeatPasswordControl.setErrors(errors);
            this.passwordError = true;
        } else {
            this.repeatPasswordControl.setErrors(null);
            this.passwordError = false;
        }
    }


    onFocus(): void {
        const inputElement = this.elementRef.nativeElement.querySelector('input');
        inputElement.type = 'password';
    }

    onSending(): void {
        const customFileList = new DataTransfer();
        const files: FileList = this.data.files;
        this.sendingFiles = true;
        const password = this.passwordControl.value;
        this.localUploadFiles.forEach((localUploadFile) => {
            localUploadFile.status = FileStatus.TO_SEND;
        });
        const uploadFilesAsync = async () => {
            for (let i = 0; i < this.localUploadFiles.length; i++) {
                const localUploadFile = this.localUploadFiles[i];
                if (!localUploadFile.blocked) {
                    localUploadFile.status = FileStatus.SENDING;
                    const currentFile = files.item(localUploadFile.position);
                    customFileList.items.add(currentFile);
                    localUploadFile.status = FileStatus.SENT;
                    return await this.digitalCertificateDaoService.uploadFile(currentFile, password, this.subProjectId, this.desktopServerId, this.customerGroupId).toPromise();
                }
            }
        };
        uploadFilesAsync().then((valueWrapper) => {
            if (valueWrapper.value === 'OK') {
                this.finished = true;
                this.dialogRef.close(this.finished);
            } else {
                this.error = true;
                this.errorMessage = valueWrapper.value;
            }
        });
    }

    onCancel(finished: boolean): void {
        this.error = false;
        this.errorMessage = '';
        this.dialogRef.close(finished);
    }

    isValidPassword(): boolean {
        return !(this.passwordError || this.passwordControl.value?.length < 1 || this.repeatPasswordControl.value?.length < 1);
    }

    onInstallAll(): void {
        const digitalCertificateInstallADUserList = this.generateDigitalCertificateInstallForADUsers();
        const digitalCertificateInstallADUserPoolList = this.generateDigitalCertificateInstallForADUserPoolList();
        const digitalCertificateInstallList = digitalCertificateInstallADUserList.concat(digitalCertificateInstallADUserPoolList);
        if (digitalCertificateInstallList.length > 0) {
            this.addSubscription(this.digitalCertificateInstallDaoService.createAll(digitalCertificateInstallList).subscribe((_) => {
                this.loadDigitalCertificateInstall(this.digitalCertificate.id, +this.desktopServerControl.value);
                this.loadUsers(+this.subProjectControl.value);
            }));
        }
    }

    private generateDigitalCertificateInstallForADUsers(): DigitalCertificateInstall[] {
        const digitalCertificateInstallList: DigitalCertificateInstall[] = [];
        this.adUsers?.forEach((adUser) => {
            if (!this.findDigitalCertificateInstall(adUser.login)) {
                digitalCertificateInstallList.push(
                    this.createDigitalCertificateInstall(
                        adUser?.managerUserId,
                        this.digitalCertificate.id,
                        +this.desktopServerControl.value,
                        adUser?.id,
                        null,
                        adUser?.login
                    )
                );
            }
        });
        return digitalCertificateInstallList;
    }

    private generateDigitalCertificateInstallForADUserPoolList(): DigitalCertificateInstall[] {
        const digitalCertificateInstallList: DigitalCertificateInstall[] = [];
        this.adUsersPoolRelationshipAll?.forEach((adUserPoolRelationship) => {
            const adUserPool = this.findADUserPool(adUserPoolRelationship.adUserPoolId);
            if (adUserPool && !this.findDigitalCertificateInstall(adUserPool.login)) {
                digitalCertificateInstallList.push(
                    this.createDigitalCertificateInstall(
                        adUserPoolRelationship?.managerUserId,
                        this.digitalCertificate.id,
                        +this.desktopServerControl.value,
                        null,
                        adUserPool?.id,
                        adUserPool?.login
                    )
                );
            }
        });
        return digitalCertificateInstallList;
    }

    private createDigitalCertificateInstall(
        managerUserId: number,
        digitalCertificateId: number,
        desktopServerId: number,
        adUserId: number,
        adUserPoolId: number,
        adUserLogin: string,
    ): DigitalCertificateInstall {
        const digitalCertificateInstall = this.initDigitalCertificateInstall();
        digitalCertificateInstall.managerUserId = managerUserId;
        digitalCertificateInstall.digitalCertificateId = digitalCertificateId;
        digitalCertificateInstall.desktopServerId = desktopServerId;
        digitalCertificateInstall.adUserId = adUserId;
        digitalCertificateInstall.adUserPoolId = adUserPoolId;
        digitalCertificateInstall.adUserLogin = adUserLogin;
        return digitalCertificateInstall;
    }

    private initDigitalCertificateInstall(): DigitalCertificateInstall {
        return {
            id: null,
            comment: null,
            modified: null,
            active: null,
            optlock: null,
            managerUserId: null,
            digitalCertificateId: null,
            desktopServerId: null,
            adUserId: null,
            adUserPoolId: null,
            adUserLogin: null,
            error: null,
            changing: null,
            deleting: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    isInstalled(adUserLogin: string): boolean {
        return this.findDigitalCertificateInstall(adUserLogin) &&
            !this.findDigitalCertificateInstall(adUserLogin)?.changing &&
            !this.findDigitalCertificateInstall(adUserLogin)?.error;
    }

    isInstalling(adUserLogin: string): boolean {
        return this.findDigitalCertificateInstall(adUserLogin) &&
            this.findDigitalCertificateInstall(adUserLogin)?.changing &&
            !this.findDigitalCertificateInstall(adUserLogin)?.error;
    }

    isError(adUserLogin: string): boolean {
        return this.findDigitalCertificateInstall(adUserLogin) &&
            !this.findDigitalCertificateInstall(adUserLogin)?.changing &&
            this.findDigitalCertificateInstall(adUserLogin)?.error;
    }

    findADUserPool(id: number): ADUserPool {
        return this.adUsersPoolList.find((adUserPool) => adUserPool.id === id);
    }

    onCheckAll(): void {
        const logins = this.adUsers.map(
            (adUser) => adUser.login).concat(
            this.adUsersPoolList.map((adUser) => adUser.login)
        );
        if (logins.length > 0) {
            const digitalCertificateInstallList: DigitalCertificateInstall[] = [];
            logins.forEach((login) => {
                const digitalCertificateInstall = this.findDigitalCertificateInstall(login);
                if (digitalCertificateInstall) {
                    digitalCertificateInstallList.push(digitalCertificateInstall);
                }
            });

            this.addSubscription(this.digitalCertificateInstallDaoService.checkInstallations(digitalCertificateInstallList).subscribe((_) => {
                this.refreshWithTimer(6000);
            }));
        }
    }

    onCheck(digitalCertificateInstall: DigitalCertificateInstall): void {
        this.addSubscription(this.digitalCertificateInstallDaoService.checkInstallation(digitalCertificateInstall).subscribe((_) => {
            this.refreshWithTimer(4000);
        }));
    }

    checkInvalidFile(errorMessage: string): boolean {
        return (errorMessage.indexOf('unknown') > -1 && errorMessage.indexOf('encountered') > -1) ||
            (errorMessage.indexOf('stream does not represent a PKCS12 key store') > -1) ||
            (errorMessage.indexOf('DEF length') > -1 && errorMessage.indexOf('object truncated by') > -1);
    }

    checkInvalidPassword(errorMessage: string): boolean {
        return errorMessage.indexOf('wrong password') > -1;
    }

    checkCertificateAlreadyExists(errorMessage: string): boolean {
        return errorMessage.indexOf('ConstraintViolationException') > -1;
    }

    hasUnknownError(errorMessage: string): boolean {
        const knownErrors = this.checkInvalidFile(errorMessage) || this.checkInvalidPassword(errorMessage) || this.checkCertificateAlreadyExists(errorMessage);
        return !knownErrors && errorMessage.length > 2;
    }


}

import {Injectable} from '@angular/core';
import {Image, ManagerUser, ManagerUserOnline, ManagerUserSimple, ManagerUserStatistic} from '../../models';
import {DomainPathService} from '../../domain-path/domain-path.service';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {ValueWrapper} from '../../helpers/value-wrapper';


@Injectable({
    providedIn: 'root'
})
export class ImageFileService {


    constructor(private domainPathService: DomainPathService,
                private http: R2CloudHttpApiService) {
    }

    private readonly managerUserPath = 'manager-user';
    private readonly customerPath = 'customer';
    private readonly customerGroupPath = 'customer-group';
    private readonly remoteAppPath = 'remote-app';
    private readonly marketPlaceProductAvatarPath = 'market-place-product-avatar';
    private readonly marketPlaceProductBannerPath = 'market-place-product-banner';
    private readonly marketPlaceProductScreenPath = 'market-place-product-screen';
    private readonly softwareCompanyPath = 'software-company';

    private static resolvePath(id: number | string, path: string): string {
        return `image/${path}/${id}`;
    }

    private resolvePathWithDomain(id: number | string, path: string): string {
        if (!id) {
            return '/assets/images/avatars/profile.jpg';
        }
        const domain = this.domainPathService;
        const imageCacheUrl = domain.imageCacheURL;
        const baseUrl = this.isValidDomain(imageCacheUrl) ? imageCacheUrl : domain.apiURL;
        return `${baseUrl}${ImageFileService.resolvePath(id, path)}`;
    }

    private isValidDomain(url: string): boolean {
        return url && !url.includes('///') && !url.includes('undefined');
    }

    getPictureFromPath(id: string, path: string, size: number = 0): string {
        const sizeQuery = size > 0 ? `?w=${size}&square=true` : '';
        return `${this.resolvePathWithDomain(id, path)}${sizeQuery}`;
    }

    savePicture(id: number, path: string, data: any): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(ImageFileService.resolvePath(id, path), {data: data} as Image);
    }

    getManagerUserPicture(managerUser: ManagerUser | ManagerUserOnline | ManagerUserStatistic | ManagerUserSimple): string {
        let url: string;
        if ((managerUser as ManagerUserOnline)?.imageUUID) {
            url = this.getManagerUserPictureUrl((managerUser as ManagerUserOnline)?.imageUUID);
        } else if (managerUser?.picture) {
            url = managerUser.picture;
        } else {
            url = this.getManagerUserPictureUrl((managerUser as ManagerUser)?.imageUUID);
        }
        return url;
    }

    getManagerUserPictureUrl(imageUUID: string, size: number = 0): string {
        if (!imageUUID) {
            return '/assets/images/avatars/profile.jpg';
        }
        return this.getPictureFromPath(imageUUID, this.managerUserPath, size);
    }

    saveManagerUserPicture(managerUserId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(managerUserId, this.managerUserPath, data);
    }

    getCustomerPictureUrl(imageUUID: string, size: number = 0): string {
        if (!imageUUID) {
            return '/assets/images/avatars/tenant.png';
        }
        return this.getPictureFromPath(imageUUID, this.customerPath, size);
    }

    saveCustomerPicture(customerId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(customerId, this.customerPath, data);
    }

    getCustomerGroupPictureUrl(imageUUID: string, size: number = 0): string {
        if (!imageUUID) {
            return '/assets/images/avatars/tenant.png';
        }
        return this.getPictureFromPath(imageUUID, this.customerGroupPath, size);
    }

    saveCustomerGroupPicture(customerGroupId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(customerGroupId, this.customerGroupPath, data);
    }

    getRemoteAppPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.remoteAppPath, size);
    }

    saveRemoteAppPicture(remoteAppId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(remoteAppId, this.remoteAppPath, data);
    }

    getMarketPlaceProductAvatarPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.marketPlaceProductAvatarPath, size);
    }

    saveMarketPlaceProductAvatarPicture(marketPlaceProductId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(marketPlaceProductId, this.marketPlaceProductAvatarPath, data);
    }

    getMarketPlaceProductBannerPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.marketPlaceProductBannerPath, size);
    }

    saveMarketPlaceProductBannerPicture(marketPlaceProductId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(marketPlaceProductId, this.marketPlaceProductBannerPath, data);
    }

    getMarketPlaceProductScreenPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.marketPlaceProductScreenPath, size);
    }

    saveMarketPlaceProductScreenPicture(marketPlaceProductScreenId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(marketPlaceProductScreenId, this.marketPlaceProductScreenPath, data);
    }

    getSoftwareCompanyPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.softwareCompanyPath, size);
    }

    saveSoftwareCompanyPicture(softwareCompanyId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(softwareCompanyId, this.softwareCompanyPath, data);
    }

    getPictureUrlFromImageFileTemplateId(imageFileTemplateId: number, width?: number | null): string {
        if (!imageFileTemplateId) {
            return '/assets/images/avatars/profile.jpg';
        }
        const baseUrl = this.domainPathService.customLayoutURL;
        let url = `${baseUrl}image-file-template/public/${imageFileTemplateId}`;
        if (width) {
            url += `?w=${width}&square=true`;
        }
        return url;
    }

    getPictureUrlFromPath(path: string, width?: number | null): string {
        let url = '';
        if (path) {
            if (width) {
                url = path + '?w=' + width + '&square=true';
            }
        } else {
            url = '/assets/images/avatars/profile.jpg';
        }
        return url;
    }

    getIconOfExtension(name: string): string {
        const imgIcon = this.domainPathService.staticURL + 'icons2/';
        if (!name) {
            return imgIcon + 'file.png';
        }
        if (name.indexOf('.txt') > -1) {
            return imgIcon + 'txt.png';
        } else if ((name.indexOf('.doc') > -1) || (name.indexOf('.docx') > -1)) {
            return imgIcon + 'doc.png';
        } else if ((name.indexOf('.xls') > -1) || (name.indexOf('.xlsx') > -1)) {
            return imgIcon + 'xls.png';
        } else if (name.indexOf('.pdf') > -1) {
            return imgIcon + 'pdf.png';
        } else if (name.indexOf('.avi') > -1) {
            return imgIcon + 'avi.png';
        } else if (name.indexOf('.css') > -1) {
            return imgIcon + 'css.png';
        } else if (name.indexOf('.csv') > -1) {
            return imgIcon + 'csv.png';
        } else if (name.indexOf('.dbf') > -1) {
            return imgIcon + 'dbf.png';
        } else if (name.indexOf('.dwg') > -1) {
            return imgIcon + 'dwg.png';
        } else if (name.indexOf('.exe') > -1) {
            return imgIcon + 'exe.png';
        } else if (name.indexOf('.iso') > -1) {
            return imgIcon + 'iso.png';
        } else if (name.indexOf('.jpg') > -1) {
            return imgIcon + 'jpg.png';
        } else if (name.indexOf('.js') > -1) {
            return imgIcon + 'js.png';
        } else if (name.indexOf('.json') > -1) {
            return imgIcon + 'json.png';
        } else if (name.indexOf('.mp3') > -1) {
            return imgIcon + 'mp3.png';
        } else if (name.indexOf('.mp4') > -1) {
            return imgIcon + 'mp4.png';
        } else if (name.indexOf('.png') > -1) {
            return imgIcon + 'png.png';
        } else if (name.indexOf('.ppt') > -1) {
            return imgIcon + 'ppt.png';
        } else if (name.indexOf('.psd') > -1) {
            return imgIcon + 'psd.png';
        } else if (name.indexOf('.rtf') > -1) {
            return imgIcon + 'rtf.png';
        } else if (name.indexOf('.svg') > -1) {
            return imgIcon + 'svg.png';
        } else if (name.indexOf('.txt') > -1) {
            return imgIcon + 'txt.png';
        } else if (name.indexOf('.xml') > -1) {
            return imgIcon + 'xml.png';
        } else if (name.indexOf('.zip') > -1) {
            return imgIcon + 'zip.png';
        }
        return imgIcon + 'find.png';
    }
}

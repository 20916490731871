import {Pipe, PipeTransform} from '@angular/core';
import {intMinutesToStringHourAndMinutes} from '../utils/utils-kluh';

@Pipe({
    name: 'intToHourMinutes'
})
export class IntToHourMinutes implements PipeTransform {
    transform(intHoursAndMinutes_in: any): any {
        if (intHoursAndMinutes_in != null && typeof (intHoursAndMinutes_in) !== 'undefined') {
            return intMinutesToStringHourAndMinutes(+intHoursAndMinutes_in);
        }
        return '-';
    }
}

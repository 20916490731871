import {Injectable, OnDestroy} from '@angular/core';
import {RemoteAppHtml5, RemoteAppReady} from '../models';
import {ComponentCleaner} from '../component-cleaner';
import {Observable, Observer} from 'rxjs';
import {R2CloudClientSubscriber} from '../ws/r2-cloud-client-subscriber';
import {USER_TOPIC} from '../../../main';
import {RemoteAppDaoService} from '../main/remote-app/remote-app-dao.service';
import {R2CloudStompService} from '../ws/r2-cloud-stomp.service';
import {CustomerGroupService} from '../main/customer-group/customer-group.service';

@Injectable({
    providedIn: 'root'
})
export class RemoteAppHtml5Service extends ComponentCleaner implements OnDestroy {
    private subscribers: R2CloudClientSubscriber<RemoteAppHtml5>[] = [];
    private status: RemoteAppHtml5[] = [];
    private currentRemoteAppReadyList: RemoteAppReady[] = [];

    constructor(
        private stomp: R2CloudStompService,
        private remoteAppDao: RemoteAppDaoService,
        private customerGroupService: CustomerGroupService
    ) {
        super();

        this.addSubscription(this.stomp.stompTopic<RemoteAppReady[]>(`${USER_TOPIC}/remote-apps-ready`).subscribe((remoteAppReadyList: RemoteAppReady[]) => {
            this.currentRemoteAppReadyList = remoteAppReadyList;
            this.populateRemoteAppHtml5StatusList(remoteAppReadyList, this.customerGroupService.customerGroup.id);
        }));

        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup.id !== this.customerGroupService.getLastSelectedCustomerGroupByCookie().id) {
                this.populateRemoteAppHtml5StatusList(this.currentRemoteAppReadyList, this.customerGroupService.customerGroup.id);
            }
        });
    }

    private populateRemoteAppHtml5StatusList(remoteAppReadyList: RemoteAppReady[], customerGroupId: number): void {
        for (const remoteAppReady of remoteAppReadyList) {
            if (!remoteAppReady.serviceApp) {
                const find = this.findRemoteAppHtml5OnList(remoteAppReady.remoteAppId);
                if (find) {
                    find.status = remoteAppReady.status;
                    this.emit(find);
                } else {
                    if (remoteAppReady.customerGroupId === customerGroupId) {
                        this.remoteAppDao.getOneHtml5(remoteAppReady.remoteAppId).subscribe((result) => {
                            if (result) {
                                result.status = remoteAppReady.status;
                                this.status.push(result);
                                this.emit(result);
                            }
                        });
                    }
                }
            }
        }
    }

    private findRemoteAppHtml5OnList(remoteAppId: number): RemoteAppHtml5 {
        return this.status.find((value) => {
            return value.remoteAppId === remoteAppId;
        });
    }

    remoteAppChanges(): Observable<RemoteAppHtml5> {
        return new Observable<RemoteAppHtml5>((observer: Observer<RemoteAppHtml5>) => {
            const r2CloudClientSubscriber = new R2CloudClientSubscriber<RemoteAppHtml5>(observer);
            this.subscribers.push(r2CloudClientSubscriber);
            this.next(observer);
            return () => {
                this.subscribers = this.subscribers.filter((value) => {
                    return r2CloudClientSubscriber.id !== value.id;
                });
                observer.complete();
            };
        });
    }

    private next(observer: Observer<RemoteAppHtml5>): void {
        for (const status of this.status) {
            observer.next(status);
        }
    }

    private emit(status: RemoteAppHtml5): void {
        for (const subscriber of this.subscribers) {
            subscriber.observer.next(status);
        }
    }

    ngOnDestroy(): void {
        for (const subscriber of this.subscribers) {
            subscriber.observer.complete();
        }
        super.ngOnDestroy();
    }
}

import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {DownloadFile} from '../../models';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {filter, startWith} from 'rxjs/operators';
import {DownloadFileDaoService} from './download-file-dao.service';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ImageFileService} from '../image-file/image-file-service';

@Component({
    selector: 'app-my-docs',
    templateUrl: './my-docs.component.html',
    styleUrls: ['./my-docs.component.scss']
})
export class MyDocsComponent implements AfterViewInit {

    constructor(private downloadFileDaoService: DownloadFileDaoService,
                private dialog: MatDialog,
                public imageFileService: ImageFileService,
    ) {
        this.dataSource = this.downloadFileDaoService;

        this.downloadFileDaoService.total$.subscribe(total => {
            this.totalDocs = total;
        });
    }
    displayedColumns: string[] = ['id', 'name', 'createdAt', 'action'];
    dataSource: DataSource<DownloadFile>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    totalDocs = 0;


    ngAfterViewInit(): void {
        this.loadData();
    }

    loadData(): void {
        this.paginator.page.pipe(startWith({
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
        }), filter(value => !!value)).subscribe((page: PageEvent) => {
            this.downloadFileDaoService.load(page);
        });
    }

    openDoc(downloadFile: DownloadFile): void {
        this.downloadFileDaoService.downloadById(downloadFile.id).subscribe(docFile => {
            if (docFile) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(docFile);
                link.download = downloadFile.name;
                link.click();
            }
        });
    }

    deleteDoc(downloadFile: DownloadFile): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse arquivo?<br><br>' + downloadFile.name,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.downloadFileDaoService.remove(downloadFile.id).subscribe(removed => {
                    this.loadData();
                });
            }
        });
    }
}

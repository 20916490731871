import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {LinuxServerCreateComponent} from '../linux-server/linux-server-create/linux-server-create.component';
import {FirewallFqdnComponent} from './firewall-fqdn.component';
import {FirewallFqdnDaoService} from './firewall-fqdn-dao.service';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        // RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatSelectModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatCardModule
    ],
    declarations: [FirewallFqdnComponent],
    providers: [
        FirewallFqdnDaoService
    ],
    entryComponents: [
        LinuxServerCreateComponent
    ]
})
export class FirewallFqdnModule {
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {ImageFileService} from '../../image-file/image-file-service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {Customer, CustomerGroup, ManagerUser, ManagerUserOnline, RoleIdCustomerGroupIdManagerUserId} from '../../../models';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {ManagerUserCreateComponent} from '../../manager-user/manager-user-create/manager-user-create.component';
import {RoleCustomerGroupOrManagerUserEditComponent} from './role-customer-group-or-manager-user-edit/role-customer-group-or-manager-user-edit.component';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {FormControl} from '@angular/forms';
import {combineLatest, Subscription} from 'rxjs/';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ManagerUserAccessPeriodService} from '../../manager-user-access-period/manager-user-access-period.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';
import {StompConnectionStatus} from '../../../ws/stomp-connection-status.enum';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {onCopyScript} from '../../../utils/utils-kluh';

@Component({
    selector: 'app-register-manager-user',
    templateUrl: './register-manager-user.component.html',
    styleUrls: ['./register-manager-user.component.scss', '../register.component.scss']
})
export class RegisterManagerUserComponent extends ComponentCleaner implements OnInit {

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    appCanCreateList: PermissionWrapper[];
    appCanLogoutList: PermissionWrapper[];
    appCanLogoutUserList: PermissionWrapper[];
    managerUserTable: string[] = ['picture', 'displayName', 'status', 'email', 'lastLogin', 'customerId', 'roleId', 'action'];
    managerUserDataSource = new MatTableDataSource([]);
    managerUserFormControl: FormControl = new FormControl('');
    managerUsersOnlineByCustomerGroup: BehaviorSubject<ManagerUserOnline[]> = new BehaviorSubject<ManagerUserOnline[]>([]);
    managerUserOnlineList: ManagerUserOnline[] = [];
    onlineUsers = false;
    onlineUsersFormControl: FormControl = new FormControl(false);
    subscriptionManagerUsersOnline: Subscription;
    timerCount = 0;
    increaseLoopTime = 0;


    constructor(public adminService: R2CloudAdminService,
                public imageFileService: ImageFileService,
                public userAuthorityDaoService: UserAuthorityDaoService,
                private managerUserDaoService: ManagerUserDaoService,
                private r2CloudStompService: R2CloudStompService,
                public managerUserAccessPeriodService: ManagerUserAccessPeriodService,
                public customerGroupService: CustomerGroupService,
                private dialog: MatDialog) {
        super();
        this.appCanCreateList = [
            {type: 'ManagerUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
        this.appCanLogoutList = [
            {type: 'ManagerUser', permission: 'AUDIT', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
        this.appCanLogoutUserList = [
            {type: 'ManagerUser', permission: 'DELETE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];

        this.r2CloudStompService.connectionStatus$.pipe(
            filter((connectionStatus) => {
                return connectionStatus === StompConnectionStatus.OK;
            }), switchMap(() => {
                return this.managerUserDaoService.getManagerUsersOnlineByCustomerGroupId(this.customerGroupService.customerGroup.id);
            }), takeUntil(this.onDestroy$))
            .subscribe((managerUserOnlineList) => {
                this.managerUserOnlineList = managerUserOnlineList;
                this.managerUsersOnlineByCustomerGroup.next(managerUserOnlineList);
            });

        this.addTimeout(setInterval(() => {
            this.timerCount++;
            if (this.timerCount > 10) {
                this.increaseLoopTime = 30000 * this.timerCount;
            }
            this.getManagerUsers(this.customerGroupService.customerGroup.id);
        }, 90000 + this.increaseLoopTime));
    }

    private findManagerUsersOnlineByCustomerGroupId(customerGroupId: number): void {
        this.addSubscription(this.managerUserDaoService.getManagerUsersOnlineByCustomerGroupId(customerGroupId).subscribe(managerUserOnlineList => {
            this.managerUserOnlineList = managerUserOnlineList;
            this.managerUsersOnlineByCustomerGroup.next(managerUserOnlineList);
        }));
    }

    private managerUsersWithLastLoginInCustomerGroup(customerGroupId: number): void {
        this.addSubscription(this.managerUserDaoService.managerUsersWithLastLoginInCustomerGroup(customerGroupId).subscribe(managerUsersWithLastLogin => {
            this.adminService.managerUserIdsWithLastLogin = managerUsersWithLastLogin;
            this.triggerSort();
        }));
    }

    private getManagerUsers(customerGroupId: number): void {
        this.addSubscription(this.managerUserDaoService.filter({managerUsersInCustomerGroupId: customerGroupId}).subscribe(managerUsers => {
            if (managerUsers) {
                managerUsers.forEach(managerUser => {
                    this.adminService.addOrReplaceManagerUser(managerUser);
                });
                this.triggerSort();
                this.findManagerUsersOnlineByCustomerGroupId(customerGroupId);
                this.managerUsersWithLastLoginInCustomerGroup(customerGroupId);

            }
        }));
    }

    private triggerSort(): void {
        this.sort.sortChange.emit({active: this.sort.active, direction: this.sort.direction});
    }

    managerUserIsOnline$(managerUserId: number): Observable<boolean> {
        return this.managerUsersOnlineByCustomerGroup.pipe(map((managerUserOnlineList) => {
            return managerUserOnlineList?.findIndex(x => x.managerUserId === managerUserId) > -1;
        }));
    }

    managerUserIsOnline(managerUserId: number): boolean {
        return this.managerUserOnlineList?.findIndex(x => x.managerUserId === managerUserId) > -1;
    }

    ngOnInit(): void {

        this.managerUserDataSource.sortingDataAccessor = (element, property) => {
            switch (property) {
                case 'status':
                    return this.managerUserIsOnline(element.id);
                case 'lastLogin':
                    return this.adminService.getManagerUserIdWithLastLoginByManagerUserId(element.id)?.lastLogin;
                case 'roleId':
                    return this.getRoleDescriptionString(element);
                default:
                    return element[property];
            }
        };
        this.managerUserDataSource.sort = this.sort;

        combineLatest(
            [this.managerUserFormControl.valueChanges.pipe(startWith('')),
                this.adminService.managerUsers$,
                this.managerUserDaoService.managerUsersWithLastLoginInCustomerGroup(this.customerGroupService.customerGroup.id),
                this.managerUserDaoService.findAllManagerUserInactiveByCustomerGroupId(this.customerGroupService.customerGroup.id),
            ]
        ).subscribe(values => {
            const fieldValue = values[0].toLowerCase();
            const managerUserList = values[1];
            const managerUsersWithLastLogin = values[2];
            const managerUsersInactive = values[3];
            const managerUsers = [...managerUserList, ...managerUsersInactive];
            if (managerUsersWithLastLogin) {
                this.adminService.managerUserIdsWithLastLogin = managerUsersWithLastLogin;
            }
            if (managerUsers) {
                if (fieldValue) {
                    this.managerUserDataSource.data = managerUsers.filter(managerUser => {
                        let customerName = this.getCustomerOfManagerUser(managerUser).name;
                        if (!customerName) {
                            customerName = '';
                        }
                        const customerGroup = this.getCustomerGroupOfCustomer(this.getCustomerOfManagerUser(managerUser));
                        let customerGroupName = '';
                        if (customerGroup) {
                            customerGroupName = customerGroup.name;
                        }
                        const roleList = this.getAllRoleCustomerGroupManagerUsers(managerUser.id);
                        let roleListStr = '';
                        for (const role of roleList) {
                            roleListStr = roleListStr + ' ' + this.adminService.getRole(role.roleId).name;
                        }
                        if (roleListStr.length < 2) {
                            roleListStr = 'desktop';
                        }
                        if (customerGroup) {
                            customerGroupName = customerGroup.name;
                        }

                        return ((managerUser?.email && managerUser?.email.toLowerCase().indexOf(fieldValue) > -1) ||
                            (managerUser?.displayName && managerUser?.displayName.toLowerCase().indexOf(fieldValue) > -1) ||
                            customerName.toLowerCase().indexOf(fieldValue) > -1 ||
                            customerGroupName.toLowerCase().indexOf(fieldValue) > -1 ||
                            this.managerUserAccessPeriodService.findManagerUserAccessPeriodTimesById(
                                managerUser.managerUserAccessPeriodProfileId
                            )?.name.toLowerCase().indexOf(fieldValue) > -1 ||
                            roleListStr.toLowerCase().indexOf(fieldValue) > -1);
                    });
                } else {
                    this.managerUserDataSource.data = managerUsers;
                }
                this.managerUserDataSource.sort = this.sort;
                if (this.onlineUsers) {
                    this.onlineUsersFormControl.setValue(false);
                }
            }
        });


    }

    onOnlineUsers(): void {
        let click = true;
        this.subscriptionManagerUsersOnline?.unsubscribe();
        this.subscriptionManagerUsersOnline = this.adminService.managerUsers$.subscribe(managerUsers => {
            this.onlineUsersFormControl.setValue(this.onlineUsers);
            if (click) {
                this.onlineUsers = !this.onlineUsers;
                click = false;
            }
            const managerUsersFiltered: ManagerUser[] = [];
            this.managerUserDataSource.data = [];
            if (this.onlineUsers) {
                managerUsers.forEach(managerUser => {
                    const index = this.managerUserOnlineList.findIndex(m => m.managerUserId === managerUser.id);
                    if (index > -1) {
                        managerUsersFiltered.push(managerUser);
                    }
                });
                this.managerUserDataSource.data = managerUsersFiltered;
            } else {
                this.managerUserFormControl.setValue('');
                this.managerUserDataSource.data = managerUsers;
            }
        });
        this.addSubscription(this.subscriptionManagerUsersOnline);
    }

    private getRoleDescriptionString(managerUser: ManagerUser): string {
        let roleDescription = '';
        this.getAllRoleCustomerGroupManagerUsers(managerUser.id).map((rolesOfCustomerGroup) => {
            if (rolesOfCustomerGroup) {
                roleDescription = roleDescription + this.adminService.getRole(rolesOfCustomerGroup.roleId).name;
            }
        });
        if (roleDescription.length < 2) {
            roleDescription = 'DeskTop';
        }
        return roleDescription;
    }


    getAllRoleCustomerGroupManagerUsers(managerUserId: number): RoleIdCustomerGroupIdManagerUserId[] {
        const roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId[] = [];
        for (const roleCustomerGroup of this.adminService.roleCustomerGroupManagerUsers) {
            if (managerUserId === roleCustomerGroup.managerUserId) {
                roleIdCustomerGroupIdManagerUserId.push(roleCustomerGroup);
            }
        }
        return roleIdCustomerGroupIdManagerUserId;
    }

    getCustomerOfManagerUser(managerUser: ManagerUser): Customer {
        if (managerUser) {
            let index = this.adminService.customers.findIndex((y) => y.id === managerUser.customerId);
            if (index > -1) {
                return this.adminService.customers[index];
            } else {
                index = this.adminService.externalCustomers.findIndex((y) => y.id === managerUser.customerId);
                if (index > -1) {
                    return this.adminService.externalCustomers[index];
                }
            }
        }
        return null;
    }

    getCustomerGroupOfCustomer(customer: Customer): CustomerGroup {
        if (!customer) {
            return null;
        }
        return this.customerGroupService.customerGroups.find(cg => cg.id === customer.customerGroupId);
    }

    isBlockAllAccess(managerUser: ManagerUser): boolean {
        const customer = this.getCustomerOfManagerUser(managerUser);
        return customer?.blockAllAccess || !!this.getCustomerGroupOfCustomer(customer)?.blockAllAccess;
    }


    openUserCreateModal(): void {
        const dialogRef = this.dialog.open(ManagerUserCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((managerUserOperation: CrudOperationWrapper) => {
            if (managerUserOperation && managerUserOperation.operation === 'CREATE') {
                this.adminService.addOrReplaceManagerUser(managerUserOperation.data);
            }
        });
        this.addSubscription(subscription);
    }

    editManagerUser(managerUser: ManagerUser): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupOrManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {managerUser: managerUser}
        });
        const subscription = dialogRef.afterClosed().subscribe(_ => {
        });
        this.addSubscription(subscription);
    }

    logoutManagerUser(managerUser: ManagerUser): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja fazer o logout do usuário ( ' + managerUser.email + ' )?',
                disableCancel: false,
                confirmButtonValue: 'Logout',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.managerUserDaoService.logoutByManagerUserId(managerUser.id).subscribe(() => {
                    const index = this.managerUserOnlineList.findIndex(x => x.managerUserId === managerUser.id);
                    if (index > -1) {
                        this.managerUserOnlineList.splice(index, 1);
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }

    copyEmailList(): void {
        const ipsBreak = this.managerUserDataSource.data.map(user => user.email).join('\n');
        const usersHtml = this.managerUserDataSource.data.map(managerUser =>
            `<tr><td>${managerUser.email}</td></tr>`
        ).join('');
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: '<h1>E-mails de usuários da empresa  (' + this.customerGroupService.customerGroup.name + ')</h1><br><table class="min-width-450">' +
                    '<thead><tr><th  class="min-width-150">E-mail</th></tr></thead>' + usersHtml + '</table>',
                disableCancel: false,
                cancelButtonValue: 'Fechar',
                confirmButtonValue: 'Copiar lista de E-mails',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                onCopyScript(ipsBreak);
            }
        }));
    }

}

import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {ProjectDaoService} from '../../../kluh-manager/main/project/project-dao.service';
import {SubProjectDaoService} from '../../../kluh-manager/main/r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {ManagerUserDaoService} from '../../../kluh-manager/main/manager-user/manager-user-dao.service';
import {CustomerDaoService} from '../../../kluh-manager/main/customer/customer-dao.service';
import {CustomerGroupDaoService} from '../../../kluh-manager/main/customer-group/customer-group-dao.service';
import {DesktopServerDaoService} from '../../../kluh-manager/main/desktop-server/desktop-server-dao.service';
import {LinuxServerDaoService} from '../../../kluh-manager/main/linux-server/linux-server-dao.service';
import {
    Customer,
    CustomerGroup,
    DesktopServer,
    DesktopServerWithSubProject,
    LinuxServer,
    LinuxServerWithSubProject,
    ManagerUser,
    ManagerUserWithSubProject,
    RemoteApp,
    SubProject
} from '../../../kluh-manager/models';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import {ComponentCleaner} from '../../../kluh-manager/component-cleaner';
import {FormControl} from '@angular/forms';
import {faLinux, faWindows} from '@fortawesome/free-brands-svg-icons';
import {RemoteAppDaoService} from '../../../kluh-manager/main/remote-app/remote-app-dao.service';
import {guessImageMime} from '../../../kluh-manager/helpers/kluh';
import {R2CloudAdminService} from '../../../kluh-manager/main/r2-cloud-admin/r2-cloud-admin.service';
import {Router} from '@angular/router';
import {Page} from '../../../kluh-manager/helpers/Page';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faBuilding} from '@fortawesome/free-solid-svg-icons/faBuilding';
import {R2CloudStompService} from '../../../kluh-manager/ws/r2-cloud-stomp.service';
import {ImageFileService} from '../../../kluh-manager/main/image-file/image-file-service';
import {PermissionWrapper} from '../../../kluh-manager/directives/if-permission.directive';
import {CustomerGroupService} from '../../../kluh-manager/main/customer-group/customer-group.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent extends ComponentCleaner {

    showSuggestions = false;
    selectedItem = 1;
    amountOfItems = 0;
    searchLoading = false;
    firstClick = false;

    desktopServers: DesktopServer[];
    desktopServersWithSubProject: DesktopServerWithSubProject[];
    linuxServersWithSubProject: LinuxServerWithSubProject[];
    managerUsersWithSubProject: ManagerUserWithSubProject[];
    remoteApps: RemoteApp[];
    linuxServers: LinuxServer[];
    subProjects: SubProject[];
    customers: Customer[];
    subProjectsFromConsultData: SubProject[];
    customerGroups: CustomerGroup[];
    customerGroupsFromConsultData: CustomerGroup[];
    customersFromConsultData: Customer[];
    managerUsers: ManagerUser[];
    faWindows = faWindows;
    faLinux = faLinux;
    faUser = faUser;
    faBuilding = faBuilding;
    search = new FormControl('');


    @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

    userHasPermissionToSearchDesktopServer: PermissionWrapper[];

    constructor(private projectDao: ProjectDaoService,
                private subProjectDao: SubProjectDaoService,
                private managerUserDao: ManagerUserDaoService,
                private customerDao: CustomerDaoService,
                private customerGroupDao: CustomerGroupDaoService,
                private desktopServerDao: DesktopServerDaoService,
                private remoteAppDao: RemoteAppDaoService,
                public imageFileService: ImageFileService,
                private linuxServerDao: LinuxServerDaoService,
                private adminService: R2CloudAdminService,
                private customerGroupService: CustomerGroupService,
                private router: Router,
                private stomp: R2CloudStompService,
                private elem: ElementRef) {
        super();
        this.customerGroupService.get().subscribe((customGroup) => {
            this.userHasPermissionToSearchDesktopServer = [
                {type: 'DesktopServer', permission: 'SEARCH', parentType: 'CustomerGroup', parent: customGroup}
            ];
        });



        const search$ = this.search.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith('')
        ).subscribe((result) => {
            if (result) {
                this.onFocusSearch();
                this.searchAllContent(result);
            } else {
                // this.searchSubProject('');
            }
        });
        this.addSubscription(search$);
    }


    public onFocusSearch(): void {
        if (!this.firstClick) {
            this.firstClick = true;
            this.getAllCustomerGroups();
            this.getAllCustomers();
            this.getAllSubProjects();
            this.getAllDesktopServers();
            this.getAllLinuxServers();
        }
        if (this.search && this.search.value) {
            this.showSuggestions = this.search.value.toString().length > 0;
            // this.showSuggestions = true;
        }
    }

    public onFocusOutSearch(): void {
        this.showSuggestions = false;
    }


    // todo pensar em atalhos que facilite a vida do usuário
    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {
        if (this.showSuggestions) {
            if (event.key === 'Enter') {
                const elements = this.elem.nativeElement.querySelectorAll('.active');
                if (elements && elements.length > 0) {
                    const element = elements[0];
                    let typeOfElementSearch;
                    let idOfElementSearch;
                    if (element.childNodes) {
                        for (let i = 0; i < element.childNodes.length; i++) {
                            if (element.childNodes[i].className === 'type') {
                                typeOfElementSearch = element.childNodes[i].innerText;
                            } else if (element.childNodes[i].className === 'id') {
                                idOfElementSearch = element.childNodes[i].innerText;
                            }
                        }
                    }
                    this.openSelected(this.selectedItem, typeOfElementSearch, idOfElementSearch);

                }
            } else if (event.key === 'ArrowUp' && this.selectedItem > 1) {
                this.selectedItem--;
            } else if (event.key === 'ArrowDown' && this.selectedItem < this.amountOfItems) {
                this.selectedItem++;
            }
        }
    }

    public openSelected(itemSelected: number, typeOfElementSearch: string, idOfElementSearch: number): void {
        // console.debug('item selecionado : ' + itemSelected);
        this.showSuggestions = false;
        this.searchInput.nativeElement.blur();
        this.selectedItem = itemSelected;
        const id = +idOfElementSearch;
        if (typeOfElementSearch === 'subProject') {
            this.openSubProject(id);
        } else if (typeOfElementSearch === 'desktopServer') {
            this.openDesktopServer(id);
        } else if (typeOfElementSearch === 'linuxServer') {
            this.openLinuxServer(id);
        } else if (typeOfElementSearch === 'remoteApp') {
            this.openRemoteApp(id);
        } else if (typeOfElementSearch === 'managerUser') {
            this.openCustomerGroup(id);
        } else if (typeOfElementSearch === 'customer') {
            this.openCustomerGroup(id);
        }
    }

    public openSubProject(subProjectId: number): void {
        const subProject = this.getSubProject(subProjectId);
        if (subProject) {
            this.router.navigate(['/admin'], {
                queryParams: {
                    id: subProject.customerGroupId,
                    subProjectId: subProject.id
                }
            });
            this.clearSearch();
        } else {
            // subProject não encontrado
        }
    }

    public openDesktopServer(desktopServerId: number): void {
        console.debug('desktopServer : ' + desktopServerId);
        this.searchLoading = true;
        this.stomp.send('/stomp/open-local-admin', {id: desktopServerId});
        setTimeout(() => {
            this.searchLoading = false;
        }, 5000);
    }

    public openRemoteApp(remoteAppId: number): void {
        console.debug('remoteAppId : ' + remoteAppId);
        this.searchLoading = true;
        this.stomp.send('/stomp/open',
            {remoteAppId: remoteAppId});
        setTimeout(() => {
            this.searchLoading = false;
        }, 5000);

    }

    public openLinuxServer(linuxServerId: number): void {
        console.debug('linuxServer : ' + linuxServerId);
        this.searchLoading = true;
        this.stomp.send('/stomp/open-linux-local-admin', {id: linuxServerId});
        setTimeout(() => {
            this.searchLoading = false;
        }, 5000);
    }


    public openCustomerGroup(customerGroupId: number): void {
        console.debug('customerGroupId : ' + customerGroupId);
        const customerGroup = this.getCustomerGroup(customerGroupId);
        if (customerGroup) {
            this.router.navigate(['/register'], {
                queryParams: {
                    id: customerGroup.id
                }
            });
            this.clearSearch();
        } else {
            // subProject não encontrado
        }
    }


    private searchAllContent(search: string): void {
        this.amountOfItems = 0;
        this.searchSubProject(search);
        this.searchCustomerGroup(search);
        this.searchCustomerCNPJ(search);
        this.searchManagerUserName(search);
        this.searchManagerUserEmail(search);
        this.searchDesktopServer(search);
        this.searchLinuxServer(search);
        this.searchRemoteApp(search);
    }


    private searchSubProject(search: string): void {
        this.subProjects = [];
        this.subProjectDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'name', value: search, limit: 3}).subscribe((result) => {
            this.onSubProjectRequestFinished(result);
        });
    }

    private onSubProjectRequestFinished(result: Page<SubProject>): void {
        if (result && result.content) {
            this.subProjects = result.content;
            this.amountOfItems = this.amountOfItems + result.content.length;
        }
    }

    private searchCustomerGroup(search: string): void {

    }

    private searchCustomerCNPJ(inSearch: string): void {
        this.customers = [];
        if (inSearch && inSearch.length > 0) {
            const search = inSearch.replace(/\./g, '').replace(/-/g, '').replace('/', '').trim();
            this.customerDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'cnpj', value: search, limit: 3}).subscribe((result) => {
                if (result && result.content) {
                    this.customers = result.content;
                    this.amountOfItems = this.amountOfItems + result.content.length;
                }
            });
        }
    }

    private searchRemoteApp(search: string): void {
        this.remoteApps = [];
        if (search && search.length > 0) {
            this.remoteAppDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'name', value: search, limit: 3}).subscribe((result) => {
                if (result && result.content) {
                    this.remoteApps = result.content;
                    this.amountOfItems = this.amountOfItems + result.content.length;
                }
            });
        }
    }

    private searchManagerUserName(search: string): void {

    }

    private searchManagerUserEmail(search: string): void {
        this.managerUsers = [];
        if (search && search.length > 0) {
            this.managerUserDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'email', value: search, limit: 3}).subscribe((result) => {
                if (result && result.content) {
                    this.managerUsers = result.content;
                    this.amountOfItems = this.amountOfItems + result.content.length;
                }
            });
        }
    }

    private searchDesktopServer(search: string): void {
        this.desktopServers = [];
        if (search && search.length > 0) {
            this.desktopServerDao.search({
                fieldOrder: undefined, order: undefined, page: undefined,
                field: 'hostname,connectionFQDN,publicIp,clientId',
                value: search,
                limit: 3
            }).subscribe((result) => {
                if (result && result.content) {
                    for (const desktopServer of result.content) {
                        this.desktopServers.push(desktopServer);
                    }
                    this.amountOfItems = this.amountOfItems + result.content.length;
                }
            });
            // this.desktopServerDao.search({field: 'connectionFQDN', value: search, limit: 3}).subscribe((result) => {
            //     for (const desktopServer of result.content) {
            //         this.desktopServers.push(desktopServer);
            //     }
            //     this.amountOfItems = this.amountOfItems + result.content.length;
            // });
        }
    }

    private searchLinuxServer(search: string): void {
        this.linuxServers = [];
        if (search && search.length > 0) {
            this.linuxServerDao.search({
                fieldOrder: undefined, order: undefined, page: undefined,
                field: 'hostname,connectionFQDN,publicIp,clientId',
                value: search,
                limit: 3
            }).subscribe((result) => {
                if (result && result.content) {
                    for (const linuxServer of result.content) {
                        this.linuxServers.push(linuxServer);
                    }
                    this.amountOfItems = this.amountOfItems + result.content.length;
                }
            });
            // this.linuxServerDao.search({field: 'connectionFQDN', value: search, limit: 3}).subscribe((result) => {
            //     for (const linuxServer of result.content) {
            //         this.linuxServers.push(linuxServer);
            //     }
            //     this.amountOfItems = this.amountOfItems + result.content.length;
            // });
        }
    }

    public guessImageMime(base64: string): string {
        return guessImageMime(base64);
    }

    private getAllCustomerGroups(): void {
        this.customerGroupDao.getAllCache().subscribe((customerGroups) => {
            // this.customerGroupDao.get().subscribe((customerGroups) => {
            this.customerGroupsFromConsultData = customerGroups;
        });
    }

    private getAllCustomers(): void {
        this.customerDao.get().subscribe((customers) => {
            this.customersFromConsultData = customers;
        });
    }


    private getAllSubProjects(): void {
        this.subProjectDao.get().subscribe((subProjects) => {
            this.subProjectsFromConsultData = subProjects;
        });
    }

    private getAllDesktopServers(): void {
        this.desktopServerDao.getAllDesktopServersWithSubProjectIds().subscribe((desktopServers) => {
            this.desktopServersWithSubProject = desktopServers;
        });
    }

    private getAllLinuxServers(): void {
        this.linuxServerDao.getAllLinuxServersWithSubProjectIds().subscribe((linuxServers) => {
            this.linuxServersWithSubProject = linuxServers;
        });
    }


    getCustomerGroup(customerGroupId: number): CustomerGroup {
        if (this.customerGroupsFromConsultData) {
            return this.customerGroupsFromConsultData.find((customerGroup) => {
                return customerGroup.id === customerGroupId;
            });
        }
        return null;
    }


    getCustomer(customerId: number): Customer {
        if (this.customersFromConsultData) {
            return this.customersFromConsultData.find((customer) => {
                return customer.id === customerId;
            });
        }
        return null;
    }


    getSubProject(subProjectId: number): SubProject {
        if (this.subProjectsFromConsultData) {
            return this.subProjectsFromConsultData.find((subProject) => {
                return subProject.id === subProjectId;
            });
        }
        return null;
    }


    getDesktopServer(desktopServerId: number): DesktopServerWithSubProject {
        if (this.desktopServersWithSubProject) {
            return this.desktopServersWithSubProject.find((desktopServer) => {
                return desktopServer.desktopServerDTO.id === desktopServerId;
            });
        }
        return null;
    }

    getLinuxServer(linuxServerId: number): LinuxServerWithSubProject {
        if (this.linuxServersWithSubProject) {
            return this.linuxServersWithSubProject.find((linuxServer) => {
                return linuxServer.linuxServerDTO.id === linuxServerId;
            });
        }
        return null;
    }

    getManagerUser(managerUserId: number): ManagerUserWithSubProject {
        if (this.managerUsersWithSubProject) {
            return this.managerUsersWithSubProject.find((managerUser) => {
                return managerUser.managerUserDTO.id === managerUserId;
            });
        }
        return null;
    }

    public clearSearch(): void {
        this.search.setValue('');
    }


}

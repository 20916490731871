import {Component, OnInit} from '@angular/core';
import {RoleDAOService} from '../role-dao.service';
import {PermissionDAOService} from '../permission-dao.service';
import {Permission, Role} from '../../../models';

@Component({
    selector: 'app-iam',
    templateUrl: './iam.component.html',
    styleUrls: ['./iam.component.scss']
})
export class IAMComponent implements OnInit {
    roles: Role[];
    permissions: Permission[];



    constructor(private roleDao: RoleDAOService, private permissionDao: PermissionDAOService) {
    }

    ngOnInit(): void {
        this.roleDao.get().subscribe((roles) => {
            this.roles = roles;
        });
        this.permissionDao.get().subscribe((permissions) => {
            this.permissions = permissions;
        });
    }
    //
    // showMore (id: number): boolean {
    //     if ()
    // }

}

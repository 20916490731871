export enum ConnectionEvent {
    ConnectionDestroyed = 0,
    WindowIconChanged = 1,
    WindowDeleted = 2,
    WindowActivated = 3,
    WindowTitleChanged = 4,
    WindowCreated = 5,
    WindowApplicationIdChanged = 6,
    WindowLaunched = 7,
    ConnectionConnectFailed = 8,
    WindowOrderChanged = 9,
    WindowsLoaded = 10,
    Connecting = 11,
    Connected = 12,
    Disconnecting = 13,
    Disconnected = 14,
    LoginCompleted = 15,
    ConnectionHealthStateChanged = 16,
    AuthChallenge = 17,
    TrustChallenge = 18,
    PrintStart = 19,
    PrintUrl = 20,
    MousePointerChanged = 21,
    RemoteClipboardChanged = 22,
    ClipboardContentRequest = 23,
    CanvasNeeded = 24,
    MonitorBoundsNeeded = 25
}

export class SessionViewModelDelegate {
    allSessionsDisconnected = null;
    onCredentialsNeeded = null;
    onTrustChallenge = null;
    openLoaderModal = null;
    closeLoaderModal = null;
    openAutoreconDialog = null;
    closeAutoreconDialog = null;
    sessionTakeFocus = null;
    showToast = null;
    showCopyConfirmationDialog = null;
    showClipHelptip = null;
    showNoGatewayError = null;
}

import {Component, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Customer, CustomerGroup, ManagerUser} from '../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ManagerUserDaoService} from '../manager-user-dao.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ManagerUserService} from '../manager-user-service';
import {invalidEmail} from '../../../utils/utils-kluh';
import {CrudOperationWrapper} from '../../../helpers/kluh';

@Component({
    selector: 'app-manager-user-create-in-batch',
    templateUrl: './manager-user-create-in-batch.component.html',
    styleUrls: ['./manager-user-create-in-batch.component.scss']
})
export class ManagerUserCreateInBatchComponent extends ComponentCleaner {
    emailsControl: FormControl = new FormControl();
    customer: Customer;
    customerGroup: CustomerGroup;

    constructor(
        public adminService: R2CloudAdminService,
        public managerUserDaoService: ManagerUserDaoService,
        public managerUserService: ManagerUserService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ManagerUserCreateInBatchComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
        this.customer = data.customer;
        this.customerGroup = data.customerGroup;
        this.emailsControl.setValidators([invalidEmail()]);
    }


    getAmountOfEmails(): number {
        let emailsStr = '';
        if (this.emailsControl.value) {
            emailsStr = this.emailsControl.value;
        }
        const emails = emailsStr?.match(/\n/g);
        return emails ? (emails.length + 1) : 0;
    }


    onCancel(): void {
        this.dialogRef.close(false);
    }


    onCreate(): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja criar todos esses usuários nessa empresa (' + this.customerGroup.name + ')?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const emails: string[] = this.emailsControl.value?.trim().split(/\n/g);
                const managerUsers: ManagerUser[] = [];
                emails.forEach(email => {
                    const managerUser = this.managerUserService.initManagerUser();
                    managerUser.email = email;
                    managerUser.displayName = this.formatUsername(email);
                    managerUser.customerId = this.customer.id;
                    managerUsers.push(managerUser);
                });
                console.log('managerUsers', managerUsers);
                this.managerUserDaoService.createAllWithNotExists(managerUsers).subscribe(managerUserList => {
                    const crudOperation: CrudOperationWrapper = {
                        data: managerUserList,
                        operation: 'CREATE'
                    };
                    this.dialogRef.close(crudOperation);
                });
            } else {
                this.dialogRef.close();
            }
        }));
    }

    private formatUsername(email: string): string {
        const username = email.split('@')[0].replace(/[\\.-_]/g, ' ');
        return username
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }


}

<mat-card class="padding-none width-100-percent mt-20" >
    <mat-card-header fxLayout="column" fxLayoutAlign="start stretch" >
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 7px;">
            <div>Usuários online
                <span *ngIf="managerUsersOnline" class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">
                    {{managerUsersOnline.length}}
                </span>
            </div>
            <div >
                Usuários online na lista
                <span *ngIf="managerUsersOnlineFilter" class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">
                    {{managerUsersOnlineFilter.length}}
                </span>
            </div>
            <div>
                <button type="button" mat-stroked-button (click)="getManagerUsersOnline()"><mat-icon>update</mat-icon></button>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="!showManagerUserOnline">
            <button mat-raised-button type="button" color="primary" class="md-small" (click)="getManagerUsersOnline()">
                <span>Show</span>
            </button>
        </div>
        <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        <div *ngIf="showManagerUserOnline && !loading">
            <div *ngIf="partnerCustomerGroups">
                <mat-form-field class="full-width min-width-450" style="max-width: 450px;" appearance="outline">
                    <mat-label>Selecionar parceiro</mat-label>
                    <mat-select class="full-width" placeholder="Selecionar parceiro"
                                [formControl]="partnerCustomerGroupControl" multiple>
                        <mat-option [value]="0" #allSelected (click)="toggleAllSelection()" >Todos</mat-option>
                        <mat-option [value]="-2" (click)="tosslePerOne()">Sem parceiro</mat-option>
                        <mat-option
                            *ngFor="let partnerCustomerGroup of partnerCustomerGroups; let i = index;"
                            [value]="partnerCustomerGroup.id" (click)="tosslePerOne()">
                            {{partnerCustomerGroup.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="pr-16">
                <input matInput class="search-box" [formControl]="searchFieldControl" autocomplete="off"
                       *ngIf="managerUsersOnline && managerUsersOnline.length > 0"
                       placeholder="Busca">
            </div>
            <div  class="scroll-content" style="max-height: 1000px;">
            <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th></th>
                <th class="w-115">Hora de entrada Web</th>
                <th class="w-115">Hora de entrada Windows</th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let managerUserOnline of managerUsersOnlineFilter">
                <td>
                    <div>
                        <div class="float-left">
                            <img class="avatar ng-tns-c71-189 ng-star-inserted"
                                 src="{{imageFileService.getManagerUserPicture(managerUserOnline)}}?w=40&square=true"
                                 width="40" height="40">
                        </div>
                        <div class="float-left mt-3 mouse-pointer" (click)="managerUserOnlineDetails(managerUserOnline)">
                            <div><b>{{managerUserOnline.displayName}}</b></div>
                            <div class="font-size-12">{{managerUserOnline.email}}</div>
                            <div class="font-size-12">{{managerUserOnline.managerUserId}}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="mouse-pointer"
                         (click)="disconnectSession(managerUserOnline.managerUserId, managerUserOnline.webSessions[0].sessionId)"
                         *ngIf="managerUserOnline?.webSessions && managerUserOnline.webSessions.length > 0 && managerUserOnline.webSessions[0].startTime">
                        {{managerUserOnline.webSessions[0].startTime | date: 'dd/MM/yyyy HH:mm'}}
                    </div>
                </td>
                <td>
                    <div class="mouse-pointer"
                         (click)="disconnectSession(managerUserOnline.managerUserId, managerUserOnline.clientSessions[0].sessionId)"
                         *ngIf="managerUserOnline?.clientSessions && managerUserOnline.clientSessions.length > 0 && managerUserOnline.clientSessions[0].startTime">
                        {{managerUserOnline.clientSessions[0].startTime | date: 'dd/MM/yyyy HH:mm'}}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
        </div>
    </mat-card-content>
</mat-card>

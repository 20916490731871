import {Component, Inject, OnInit} from '@angular/core';

import {BaseDao} from '../../../base-dao';
import {BaseDTOIdLong, ManagerUserSimple} from '../../../models';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {flatMap, map} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {validateEmail} from '../../../helpers/kluh';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/internal/Observable';

@Component({
    selector: 'app-entity-history',
    templateUrl: './entity-history.component.html',
    styleUrls: ['./entity-history.component.scss']
})
export class EntityHistoryComponent implements OnInit {
    private dao: BaseDao<BaseDTOIdLong>;
    private id: number;
    private limit = 10;
    public snapshotList: JaversSnapshotWithManagerUserSimple[];

    public static openHistory(id: number, dao: BaseDao<BaseDTOIdLong>, dialog: MatDialog): MatDialogRef<EntityHistoryComponent> {
        return dialog.open(EntityHistoryComponent, {
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                dao: dao,
                id: id,
            }
        });
    }

    public static openAllHistory(id: number, dao: BaseDao<BaseDTOIdLong>, dialog: MatDialog): MatDialogRef<EntityHistoryComponent> {
        return dialog.open(EntityHistoryComponent, {
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                dao: dao,
                id: id,
                limit: 5000,
            }
        });
    }

    constructor(private dialogRef: MatDialogRef<EntityHistoryComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public imageFileService: ImageFileService,
                private managerUserDao: ManagerUserDaoService) {
        if (!data.dao) {
            throw new Error('EntityHistoryComponent requires dao');
        }
        if (!data.id) {
            throw new Error('EntityHistoryComponent requires id');
        }
        this.dao = data.dao;
        this.id = data.id;
        if (data.limit) {
            this.limit = data.limit;
        }
    }

    ngOnInit(): void {
        this.loadEntitySnapshots();
    }

    loadEntitySnapshots(): void {
        this.dao.entitySnapshots(this.id, this.limit, 0).pipe(flatMap((result) => {
            return forkJoin(result.filter((snapshot) => {
                return this.isValidAuthor(snapshot.commitMetadata.author);
            }).map((snapshot) => {
                return this.getManagerUserSimpleObservable(snapshot.commitMetadata.author).pipe(map((managerUserSimple) => {
                    return {snapshot, managerUserSimple} as JaversSnapshotWithManagerUserSimple;
                }));
            }));
        })).subscribe((result) => {
            this.snapshotList = result;
        });
    }

    private isValidAuthor(author: string): boolean {
        return validateEmail(author) || this.isR2Commit(author);
    }

    private isR2Commit(author: string): boolean {
        return author.toLowerCase() === 'r2';
    }

    private getManagerUserSimpleObservable(author: string): Observable<ManagerUserSimple> {
        if (this.isR2Commit(author)) {
            return of(this.managerUserDao.innitManagerUserSimple(author));
        }
        return this.managerUserDao.getManagerUserSimpleByEmail(author);
    }

    getChangedContent(snapshot: JaversSnapshot<any>): any {
        const result = {};
        for (const changedProperty of snapshot.changedProperties) {
            const stateElement = snapshot.state[changedProperty];
            if (stateElement === undefined || stateElement === null) {
                result[changedProperty] = 'null';
            } else {
                result[changedProperty] = stateElement;
            }
        }
        return result;
    }

    onClose(): void {
        this.dialogRef.close();
    }
}

export class JaversSnapshotWithManagerUserSimple {
    snapshot: JaversSnapshot<any>;
    managerUserSimple: ManagerUserSimple;
}

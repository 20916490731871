import {Component} from '@angular/core';
import {invalidIpv4OrIpv6OrFqdnIgnoreHttpHttps, onCopyScript} from '../../../utils/utils-kluh';
import {MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-gateway-server-script-install',
    templateUrl: './gateway-server-script-install.component.html',
    styleUrls: ['./gateway-server-script-install.component.scss']
})
export class GatewayServerScriptInstallComponent extends ComponentCleaner {
    script: string;
    copied: string;
    customIPsUrl = false;
    r2IpsUrlControl: FormControl = new FormControl();

    protected readonly onCopyScript = onCopyScript;

    constructor(
        public dialogRef: MatDialogRef<GatewayServerScriptInstallComponent>,
        private domainPathService: DomainPathService
    ) {
        super();
        const uiDomain = this.domainPathService.domainPath.uiDomains[0];
        this.r2IpsUrlControl.setValue(`https://ips.${uiDomain}`);
        this.generateGatewayServerScript(this.r2IpsUrlControl.value);
        if (!uiDomain.includes('.kluh.')) {
            this.customIPsUrl = true;
            this.r2IpsUrlControl.setValidators([invalidIpv4OrIpv6OrFqdnIgnoreHttpHttps()]);
            this.r2IpsUrlControl.valueChanges.subscribe(url => {
                this.generateGatewayServerScript(url);
            });
        }
    }

    private generateGatewayServerScript(ipsDomain: string): void {
        const scriptName = `r2cloud-security-pfsense-install.sh`;
        const scriptPath = `${this.domainPathService.staticURL}script/${scriptName}`;
        const waiting = `sleep 5`;
        this.script = (`fetch ${scriptPath} -o ${scriptName} && ${waiting} && sh ${scriptName} ${ipsDomain}`).trim();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}

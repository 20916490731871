<div fxLayout="column" fxLayoutAlign="space-between stretch" fusePerfectScrollbar class="page-layout simple fullwidth">
    <div class="p-24">
        <mat-card class="my-card">
            <mat-card-header>
                <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        Cadastrar super usuário
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field class="full-width">
                    <input matInput placeholder="email" aria-label="email" [matAutocomplete]="auto"
                           [formControl]="filterControl">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let managerUser of filteredManagerUsers | async" [value]="managerUser">

                            <!--<img class="option-img" aria-hidden-->
                                 <!--[src]="managerUser.picture || '/assets/images/avatars/profile.jpg'" height="25"-->
                                 <!--width="25">-->

                            <img class="option-img"  src="{{imageFileService.getManagerUserPicture(managerUser)}}?w=25" width="25" height="25">

                            <span>{{managerUser.email}}</span> |
                            <small>{{managerUser?.displayName || '...'}}</small>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-radio-group [formControl]="radioControl" fxLayout="column">
                    <mat-radio-button value="admin">Admin</mat-radio-button>
                    <mat-radio-button value="customerGroupCreator">Criador de Empresa</mat-radio-button>
                </mat-radio-group>
                <button type="button" mat-raised-button color="accent" [disabled]="!validateForm()"
                        (click)="validateForm() && onSubmit()">Cadastrar
                </button>
            </mat-card-content>
        </mat-card>
        <mat-card class="my-card">
            <mat-card-header>
                <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        Usuários Admin
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutAlign="space-between start">

                <table class="remote-app-table" border="0" cellspacing="0">
                    <tr *ngFor="let admin of adminManagerUserPermissions">
                        <td style="width: 30px">
                            <button mat-icon-button (click)="onRemoveManagerUserPermission(admin)" color="warn">
                                <mat-icon aria-label="Example icon-button with a heart icon">remove_circle</mat-icon>
                            </button>
                        </td>
                        <td>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div>
                                    <!--<img class="option-img" aria-hidden-->
                                         <!--[src]="getManagerUser(admin.managerUserId)?.picture || '/assets/images/avatars/profile.jpg'"-->
                                         <!--height="34"-->
                                         <!--width="34">-->

                                    <img class="option-img"  src="{{imageFileService.getManagerUserPicture(getManagerUser(admin.managerUserId))}}?w=34" width="34" height="34">
                                </div>
                                <div>
                                    {{getManagerUser(admin.managerUserId)?.email}} |
                                </div>
                                <div class="ml-8">
                                    <small>{{getManagerUser(admin.managerUserId)?.displayName || '...'}}</small>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>


        <mat-card class="my-card">
            <mat-card-header>
                <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        Usuários Criadores de Empresas
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutAlign="space-between start">
                <table class="remote-app-table" border="0" cellspacing="0">
                    <tr *ngFor="let customerGroupCreator of customerGroupCreatorManagerUserPermissions">
                        <td style="width: 30px">
                            <button mat-icon-button (click)="onRemoveManagerUserPermission(customerGroupCreator)" color="warn">
                                <mat-icon aria-label="Example icon-button with a heart icon">remove_circle</mat-icon>
                            </button>
                        </td>
                        <td>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div>
                                    <!--<img class="option-img" aria-hidden-->
                                         <!--[src]="getManagerUser(customerGroupCreator.managerUserId)?.picture || '/assets/images/avatars/profile.jpg'"-->
                                         <!--height="34"-->
                                         <!--width="34">-->
                                    <img class="option-img" src="{{imageFileService.getManagerUserPicture(getManagerUser(customerGroupCreator.managerUserId))}}?w=34" width="34" height="34">
                                </div>
                                <div>
                                    {{getManagerUser(customerGroupCreator.managerUserId)?.email}} |
                                </div>
                                <div class="ml-8">
                                    <small>{{getManagerUser(customerGroupCreator.managerUserId)?.displayName || '...'}}</small>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>

import {DotNetServerPluginService} from '../services/server-plugin.service';
import {DotNetServerPluginDependencyFile} from '../../../models';
import {MatDialog} from '@angular/material/dialog';
import {Component, OnInit} from '@angular/core';
import {DotNetServerPluginDependencyFileDaoService} from '../services/server-plugin-dependency-file-dao.service';
import {DotNetServerPluginDependencyFileCreateEditComponent} from '../server-plugin-dependency-file-create-edit/server-plugin-dependency-file-create-edit.component';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-server-plugin-dependency-file-card',
    templateUrl: './server-plugin-dependency-file-card.component.html',
    styleUrls: ['./server-plugin-dependency-file-card.component.scss']
})
export class DotNetServerPluginDependencyFileCardComponent implements OnInit {

    constructor(
        public serverPluginService: DotNetServerPluginService,
        public serverPluginDependencyFileDao: DotNetServerPluginDependencyFileDaoService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    onEdit(serverPluginDependencyFile: DotNetServerPluginDependencyFile): void {
        this.openCreateEditModal(serverPluginDependencyFile);
    }

    private openCreateEditModal(serverPluginDependencyFile: DotNetServerPluginDependencyFile): void {
        this.dialog.open(DotNetServerPluginDependencyFileCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                serverPluginDependencyFile
            }
        });
    }

    onDownload(serverPluginDependencyFile: DotNetServerPluginDependencyFile): void {
        this.serverPluginDependencyFileDao.download(serverPluginDependencyFile.id).subscribe((data) => {
            if (data) {
                const blob = new Blob([data], {type: 'application/octet-stream'});
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, serverPluginDependencyFile.name);
                } else {
                    const a = document.createElement('a') as HTMLAnchorElement;
                    a.style['display'] = 'none';
                    a.href = URL.createObjectURL(blob);
                    a.download = serverPluginDependencyFile.name;
                    a.click();
                }
            }
        });
    }

    onCreate(): void {
        this.openCreateEditModal({
            active: true,
            comment: undefined,
            id: undefined,
            md5: undefined,
            name: undefined,
            version: undefined,
            modified: null,
            optlock: null,
            fileUUID: null
        });
    }

    onDelete(serverPluginDependencyFile: DotNetServerPluginDependencyFile): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse Template de Plugin?<br><br>' + serverPluginDependencyFile.name,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverPluginDependencyFileDao.remove(serverPluginDependencyFile.id).subscribe(deleted => {
                    this.serverPluginService.deleteFromList(serverPluginDependencyFile.id, this.serverPluginService.serverPluginDependencyFiles);
                });
            }
        });
    }
}

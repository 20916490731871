import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {MarketplaceOrder} from '../../../models';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable()
export class MarketplaceOrderDaoService extends BaseDao<MarketplaceOrder> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'marketplace-order');
    }

    markAsRead(marketplaceOrderId: number): Observable<void> {
        return this.http.get<void>(this.url + '/mark-as-read/' + marketplaceOrderId);
    }

    markAsUnread(marketplaceOrderId: number): Observable<void> {
        return this.http.get<void>(this.url + '/mark-as-unread/' + marketplaceOrderId);
    }

    markAsCanceled(marketplaceOrderId: number): Observable<void> {
        return this.http.get<void>(this.url + '/mark-as-canceled/' + marketplaceOrderId);
    }

    markAsConcluded(marketplaceOrderId: number): Observable<void> {
        return this.http.get<void>(this.url + '/mark-as-concluded/' + marketplaceOrderId);
    }

    markAsOpenOrder(marketplaceOrderId: number): Observable<void> {
        return this.http.get<void>(this.url + '/mark-as-open-order/' + marketplaceOrderId);
    }

    findAllByAuthenticatedManagerUser(): Observable<MarketplaceOrder[]> {
        return this.http.get<MarketplaceOrder[]>(this.url + '/find-all-by-authenticated-manager-user');
    }

    findAllConcludedByAuthenticatedManagerUser(): Observable<MarketplaceOrder[]> {
        return this.http.get<MarketplaceOrder[]>(this.url + '/find-all-concluded-by-authenticated-manager-user');
    }

    findAllCanceledByAuthenticatedManagerUser(): Observable<MarketplaceOrder[]> {
        return this.http.get<MarketplaceOrder[]>(this.url + '/find-all-cancel-by-authenticated-manager-user');
    }

    getAmountOfUnreadOrders(): Observable<number> {
        return this.http.get<number>(this.url + '/get-amount-of-unread-orders/?id=0&load=0');
    }

    getAmountOfOrderByMarketplaceProductId(marketplaceProductId: number): Observable<number> {
        return this.http.get<number>(this.url + '/get-amount-of-order-by-marketplace-product-id/' + marketplaceProductId);
    }

}

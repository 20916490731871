import {Component} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {CustomerGroup, SnapshotType} from '../../../models';
import {FormControl} from '@angular/forms';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {MatDialog} from '@angular/material/dialog';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';

@Component({
  selector: 'app-customer-group-snapshots',
  templateUrl: './customer-group-snapshots.component.html',
  styleUrls: ['./customer-group-snapshots.component.scss', '../status.component.scss']
})
export class CustomerGroupSnapshotsComponent {

    snapshots: JaversSnapshot<CustomerGroup>[];
    snapshotTypeControl: FormControl = new FormControl();
    sizeListControl: FormControl = new FormControl();
    snapshotType = SnapshotType;
    loading = false;
  constructor(private customerGroupDaoService: CustomerGroupDaoService, private dialog: MatDialog) {
      this.sizeListControl.setValue(5);
      this.snapshotTypeControl.setValue(SnapshotType.ALL);
      // this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
  }


    private snapshotsByTypeAndLimit(size: number, snapshotType: SnapshotType = SnapshotType.ALL): void {
        this.loading = true;
        this.snapshots = [];
        this.customerGroupDaoService.snapshotsByTypeAndLimit(size, snapshotType).subscribe((subProjectsSnapshots) => {
            this.snapshots = subProjectsSnapshots;
            this.loading = false;
        });
    }

    refresh(): void {
        this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    onJaversHistory(id: number): void {
        EntityHistoryComponent.openHistory(id, this.customerGroupDaoService, this.dialog);
    }

}

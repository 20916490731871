<table mat-table matSort [dataSource]="userDataSource" class="mat-elevation-z8 width-100-percent mt-25">

    <!-- Name Column -->
    <ng-container matColumnDef="adUser" class="width-100-percent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User</th>
        <td mat-cell *matCellDef="let element">
            <table *ngIf="findManagerUserCustomerGroupData(element.adUser) as ManagerUserCustomerGroupData; let data">
                <tr>
                    <td class="label-ad-user-list">email:</td>
                    <td>{{data.managerUserEmail}}</td>
                </tr>
                <tr>
                    <td class="label-ad-user-list">filial:</td>
                    <td>{{data.customerName}}</td>
                </tr>
                <tr>
                    <td class="label-ad-user-list">empresa:</td>
                    <td>{{data.customerGroupName}}</td>
                </tr>
                <tr>
                    <td class="label-ad-user-list">ad user:</td>
                    <td>{{element.adUser}}</td>
                </tr>
            </table>
            <div *ngIf="!findManagerUserCustomerGroupData(element.adUser)">{{element.adUser}}</div>
        </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="processorUsage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CPU</th>
        <td mat-cell *matCellDef="let element"> {{element.processorUsage}}%</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="memoryUsage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Memory</th>
        <td mat-cell *matCellDef="let element">{{element.memoryUsage | bytes : 0 : 'B': 'MB' }} </td>

    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 400px;"></th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
            <button type="button" style="width: auto" class="mr-8" color="accent" mat-raised-button
                    (click)="onUserLogOff(element)" *ngIf="serverType === ServerType.WINDOWS">Desconectar
            </button>
            <button type="button" color="warn" mat-raised-button (click)="onUserReset(element)">Forçar Desconexão
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="userTable"></tr>
    <tr mat-row *matRowDef="let row; columns: userTable;"></tr>
</table>


export class ConnectionDelegate {
    canvasNeeded = null;
    viewSizeChanged = null;
    credentialsNeeded = null;
    trustChallenge = null;
    willConnect = null;
    didConnect = null;
    willDisconnect = null;
    didDisconnect = null;
    loginCompleted = null;
    connectionHealthStateChanged = null;
    mousePointerChanged = null;
    showDefaultMousePointer = null;
    mousePositionChanged = null;
    multiTouchEnabled = null;
    error = null;
    getRemoteClipboardContent = null;
    clipboardContentRequest = null;
    monitorBounds = null;
    displayDesktopBackground = null;
    setRemoteClipboardFormatsComplete: any;

    // temporario
    getGatewayHostname: any = null;
}

import {Injectable} from '@angular/core';
import {GatewayServerNatConfig, GatewayServerNatConfigCheck} from '../../../models';
import {BaseDao} from '../../../base-dao';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class GatewayServerNatConfigDaoService extends BaseDao<GatewayServerNatConfig> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'gateway-server-nat-config');
    }

    initGatewayServerNatConfig(): GatewayServerNatConfig {
        return {
            id: null,
            comment: null,
            active: true,
            modified: null,
            optlock: null,
            gatewayServerId: null,
            destinationPort: null,
            desktopServerId: null,
            protocol: null,
            linuxServerId: null,
            openAccess: null,
            serverConnectionType: null,
            serverConnectionCustomPort: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    suspendCreate(gatewayServerNatConfig: GatewayServerNatConfig): Observable<GatewayServerNatConfig> {
        return this.http.post<GatewayServerNatConfig>(this.url + '/suspend-create', gatewayServerNatConfig);
    }

    suspendSave(gatewayServerNatConfig: GatewayServerNatConfig): Observable<GatewayServerNatConfig> {
        return this.http.put<GatewayServerNatConfig>(this.url + '/suspend-save', gatewayServerNatConfig);
    }

    suspendCheck(gatewayServerNatConfig: GatewayServerNatConfig): Observable<GatewayServerNatConfigCheck[]> {
        return this.http.post<GatewayServerNatConfigCheck[]>(this.url + '/suspend-check', gatewayServerNatConfig);
    }

    findByDesktopServerId(desktopServerId: number): Observable<GatewayServerNatConfig> {
        return this.http.get<GatewayServerNatConfig>(this.url + '/find-by-desktop-server-id/' + desktopServerId);
    }
    findByLinuxServerId(linuxServerId: number): Observable<GatewayServerNatConfig> {
        return this.http.get<GatewayServerNatConfig>(this.url + '/find-by-linux-server-id/' + linuxServerId);
    }

    suspendDelete(gatewayServerNatConfig: GatewayServerNatConfig): Observable<void> {
        return this.http.delete(this.url + '/suspend-delete', gatewayServerNatConfig);
    }

    canDelete(gatewayServerId: number): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/can-delete/' + gatewayServerId);
    }


}

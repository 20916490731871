<div id="forgot-password" *fuseIfOnDom fxLayout="row" fxLayoutAlign="start">
    <app-background type="full" *ngIf="!loadingCustomLayout || displayOriginalPortal"
                    [imageUrls]="customerGroupTemplate?.backgroundImageTemplate?.imageFileTemplateIds"></app-background>
  <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>

        <div [innerHTML]="customerGroupTemplate?.html | safeHtml: 'html'"
             *ngIf="customerGroupTemplate?.html && !displayOriginalPortal">
        </div>

        <div *ngIf="(!loadingCustomLayout && !customerGroupTemplate?.html) || displayOriginalPortal">
           <!-- <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
                <img src="assets/images/logos/r2/large/r2-cloud-logo-large-google.png" class=" shadowed r2-logo-alone">

            </div>
            <div class="title shadowed" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                <span *ngIf="(!loadingCustomLayout && !customerGroupTemplate?.html) || displayOriginalPortal">
            A nuvem privada perfeita para sua empresa.<br><br>
            Segurança e alta performance na mesma rede dos <br>
            aplicativos da <b class="g-blue">G</b><b class="g-red">o</b><b class="g-yellow">o</b><b class="g-blue">g</b><b
                    class="g-green">l</b><b class="g-red">e</b>.
         </span>

            </div>-->
        </div>

    </div>

  <div id="forgot-password-form-wrapper" fusePerfectScrollbar *fuseIfOnDom>

    <div id="forgot-password-form">

        <div class="logo mb-60" style="margin-top: 0"
             *ngIf="!loadingCustomLayout && customerGroupTemplate?.customPortalTemplate?.creatorCustomerGroupId && customerGroupImageUUID">
            <img src="{{imageFileService.getCustomerGroupPictureUrl(customerGroupImageUUID, 128)}}"
                 width="128" alt="">
        </div>
        <div class="logo"
             *ngIf="!(!loadingCustomLayout && customerGroupTemplate?.customPortalTemplate?.creatorCustomerGroupId && customerGroupImageUUID)">
            <img width="128" src="assets/images/logos/icone-simples.png" alt="">
        </div>

      <div class="title">RECUPERAR SENHA</div>


      <form [formGroup]="forgotPasswordForm" (submit)="!forgotPasswordSuccess && forgotPasswordForm.dirty && forgotPasswordForm.valid && onSubmit()">

        <mat-form-field>
          <input matInput placeholder="Email" formControlName="email">
          <mat-error *ngIf="forgotPasswordFormErrors.email.required">
            Preencha o e-mail
          </mat-error>
          <mat-error *ngIf="!forgotPasswordFormErrors.email.required && forgotPasswordFormErrors.email.email">
            E-mail inválido
          </mat-error>
        </mat-form-field>

        <button type="submit" mat-raised-button class="submit-button" color="accent"
                aria-label="ENVIAR E-MAIL" [disabled]="forgotPasswordSuccess || forgotPasswordSending || forgotPasswordForm.pristine">
          ENVIAR E-MAIL
        </button>


        <div *ngIf="forgotPasswordSending">
          Enviando...
        </div>


        <div *ngIf="forgotPasswordSuccess">
           E-mail enviado com sucesso, em caso de dúvidas entrar em contato com o suporte.
        </div>

          <div *ngIf="forgotPasswordError">
              E-mail enviado com sucesso, em caso de dúvidas entrar em contato com o suporte
          </div>



      </form>

      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a class="link" (click)="onGoToLogin()" [routerLink]="">Voltar ao login</a>
      </div>

    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {ManagerUserAndAdUserCreateEditComponent} from './manager-user-and-ad-user-create-edit/manager-user-and-ad-user-create-edit.component';
import {ManagerUserAndAdUserDaoService} from './manager-user-and-ad-user-dao.service';
import {NewUserWizardComponent} from './new-user-wizard/new-user-wizard.component';
import {ServiceUserWizardComponent} from './service-user-wizard/service-user-wizard.component';
import {ManagerUserAdministratorComponent} from '../iam/manager-user-administrator/manager-user-administrator.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {EditAdUserPasswordComponent} from './edit-ad-user-password/edit-ad-user-password.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatIconModule,
        MatCheckboxModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatRadioModule,
        MatMenuModule,
        MatTooltipModule,
        MatDialogModule,
        MatProgressSpinnerModule
    ],
    declarations: [ManagerUserAndAdUserCreateEditComponent, NewUserWizardComponent, ServiceUserWizardComponent, EditAdUserPasswordComponent],
    providers: [ManagerUserAndAdUserDaoService],
    entryComponents: [ManagerUserAndAdUserCreateEditComponent, NewUserWizardComponent, ManagerUserAdministratorComponent, ServiceUserWizardComponent]
})
export class ManagerUserAndAdUserModule {
}

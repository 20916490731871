import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {checkQuotes, convertToFormGroup, CrudOperationWrapper, markAsTouched, triggerFormValidation, validateEmail} from '../../../helpers/kluh';
import {debounceTime, distinctUntilChanged, filter, startWith} from 'rxjs/operators';
import {ComponentCleaner} from '../../../component-cleaner';
import {combineLatest, Observable, of} from 'rxjs';
import {CustomerCreateEditComponent} from '../../customer/customer-create-edit/customer-create-edit.component';
import {AuthService} from '../../../auth.service';
import {ADDomain, ADUser, ADUserWithPassword, Customer, CustomerGroup, FilterByEmailAndAdDomainIdAndSubProjectId, ManagerUser, Project, SubProject} from '../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ManagerUserService} from '../../manager-user/manager-user-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {CustomLayoutService, initCustomPortalTemplate} from '../../custom-layout/custom-layout.service';
import {invalidEmail} from '../../../utils/utils-kluh';

@Component({
    selector: 'app-new-user-wizard',
    templateUrl: './new-user-wizard.component.html',
    styleUrls: ['./new-user-wizard.component.scss']
})
export class NewUserWizardComponent extends ComponentCleaner {
    myForm: FormGroup;
    adDomains: ADDomain[];
    private project: Project;
    private subProject: SubProject;
    customers: Customer[];
    newManagerUser: boolean;
    private subProjectId: number;
    hasCustomerId: boolean;
    customerGroups: CustomerGroup[];
    defaultCustomPortalTemplate: CustomPortalTemplate = initCustomPortalTemplate();
    removeChangingControl: FormControl = new FormControl(false);

    constructor(public dialogRef: MatDialogRef<NewUserWizardComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private authService: AuthService,
                private snackBar: MatSnackBar,
                private daoManagerUser: ManagerUserDaoService,
                private daoAdUser: AdUserDaoService,
                private managerUserService: ManagerUserService,
                public adminService: R2CloudAdminService,
                public customLayoutService: CustomLayoutService,
    ) {
        super();

        this.adDomains = data.adDomains;
        let singleAdDomainId = null;
        if (this.adDomains.length === 1) {
            singleAdDomainId = this.adDomains[0].id;
        }
        this.project = data.project;
        this.subProject = data.subProject;
        if (data.customers) {
            this.customers = data.customers;
        } else {
            this.customers = [];
        }
        this.customerGroups = data.customerGroups;
        const managerUser: ManagerUser = this.managerUserService.initManagerUser();
        if (this.subProject) {
            this.subProjectId = this.subProject.id;
        }


        const adUser = this.initAdUser(null, singleAdDomainId);
        const emailForm = this.fb.control('');
        const domainForm = this.fb.control(singleAdDomainId);
        const customAdUserForm = this.fb.control(false);
        const managerUserForm = this.fb.group(convertToFormGroup(managerUser), {asyncValidator: this.daoManagerUser.validator});
        const adUserForm = this.fb.group(convertToFormGroup(adUser));
        const adUserPasswordForm = this.fb.control('');
        const askThePasswordForm = this.fb.control(false);
        emailForm.setValidators([invalidEmail()]);
        this.myForm = this.fb.group({
            email: emailForm,
            domainForm: domainForm,
            customAdUser: customAdUserForm,
            adUserPasswordForm: adUserPasswordForm,
            askThePasswordForm: askThePasswordForm,
            managerUserForm: managerUserForm,
            adUserForm: adUserForm
        });

        adUserPasswordForm.valueChanges.subscribe(() => {
            adUserPasswordForm.setErrors(checkQuotes(adUserPasswordForm));
        });

        const domainForm$ = domainForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(<number>singleAdDomainId),
            filter(value => {
                return value && value > 0;
            })
        );
        const emailForm$ = emailForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            filter(value => {
                if (value) {
                    return validateEmail(value);
                }
                return false;
            })
        );
        const customAdUserForm$ = customAdUserForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(false)
        );
        const askThePasswordForm$ = askThePasswordForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(false));
        const subscription = combineLatest([domainForm$, emailForm$, customAdUserForm$, askThePasswordForm$]).subscribe((results) => {
            const adDomainId = results[0];
            const email = results[1];
            const customAdUser = results[2];
            const askThePassword = results[3];
            const login = this.generateLoginADUserFromEmail(email);
            if (customAdUser && !askThePassword) {
                adUserPasswordForm.setErrors(checkQuotes(adUserPasswordForm));
            } else {
                adUserPasswordForm.setErrors(null);
            }
            this.daoManagerUser.findByEmail(email).subscribe((result) => {
                this.hasCustomerId = !!(result && result.customerId);
                if (result) {
                    this.newManagerUser = false;
                    managerUserForm.setValue(result as any);
                    triggerFormValidation(managerUserForm);
                    if (!customAdUser) {
                        this.daoAdUser.getByEmailAndAdDomainIdAndSubProjectId(email, adDomainId, this.subProjectId).subscribe((result2) => {
                            if (result2) {
                                emailForm.setErrors({invalid: true});
                                adUserForm.setValue(result2 as any);
                                triggerFormValidation(adUserForm);
                            } else {
                                emailForm.setErrors(null);
                                adUserForm.setValue(this.initAdUser(login, adDomainId) as any);
                                triggerFormValidation(adUserForm);
                            }
                        });
                    } else {
                        const adDomainIdForm = adUserForm.get('adDomainId');
                        adDomainIdForm.setValue(adDomainId);
                        adDomainIdForm.markAsDirty();
                        adDomainIdForm.updateValueAndValidity();
                    }
                } else {
                    this.newManagerUser = true;
                    const managerUserInput = this.managerUserService.initManagerUser();
                    managerUserInput.email = email;
                    managerUserForm.setValue(managerUserInput as any);
                    triggerFormValidation(managerUserForm);

                    if (!customAdUser) {
                        this.daoAdUser.findByLogin(login, adDomainId).subscribe((result2) => {
                            if (result2) {
                                adUserForm.setValue(this.initAdUser(this.putARandomValueOnLogin(login), adDomainId) as any);
                            } else {
                                adUserForm.setValue(this.initAdUser(login, adDomainId) as any);
                            }
                            triggerFormValidation(adUserForm);
                        });
                    } else {
                        const adDomainIdForm = adUserForm.get('adDomainId');
                        adDomainIdForm.setValue(adDomainId);
                        adDomainIdForm.markAsDirty();
                        adDomainIdForm.updateValueAndValidity();
                    }
                }
            });
        });
        this.addSubscription(subscription);

        this.addSubscription(this.customLayoutService.getDefaultCustomerGroupTemplate$().subscribe(defaultCustomerGroupTemplate => {
            this.defaultCustomPortalTemplate = defaultCustomerGroupTemplate;
        }));
    }

    generateLoginADUserFromEmail(emailInput: string): string {
        let login = emailInput.split('@')[0];
        if (login.length > 9) {
            login = login.substring(0, 9);
        }
        return login + Math.floor((Math.random() * 9999) + 1);
    }

    putARandomValueOnLogin(loginInput: string): string {
        return loginInput + Math.floor((Math.random() * 9999) + 1);
    }

    private initAdUser(login: string, adDomainId: number): ADUser {
        return {
            id: null,
            comment: null,
            active: true,
            adDomainId: adDomainId,
            adGroupIds: [],
            login: login,
            managerUserId: null,
            managerUserServiceIds: [],
            serviceUser: false,
            subProjectIds: [this.subProjectId],
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
    }

    onSubmit(): void {
        const remoteChanging: boolean = this.removeChangingControl.value;
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        const adUser: ADUser = this.myForm.get('adUserForm').value;
        const customAdUser: boolean = this.myForm.get('customAdUser').value;
        const adUserPassword: string = this.myForm.get('adUserPasswordForm').value;
        const askThePassword: boolean = this.myForm.get('askThePasswordForm').value;
        let managerUser$: Observable<ManagerUser>;
        managerUser.email = managerUser.email?.trim();
        if (managerUser.id) {
            managerUser$ = of(managerUser);
        } else {
            managerUser$ = this.daoManagerUser.create(managerUser);
        }
        managerUser$.subscribe((result) => {
            adUser.managerUserId = result.id;
            let adUser$: Observable<ADUser>;
            this.daoAdUser.getByManagerUserIdAndAdDomainId(adUser.managerUserId, adUser.adDomainId).subscribe((result3) => {
                if (result3 && !customAdUser) {
                    const emailAdDomainSubProject: FilterByEmailAndAdDomainIdAndSubProjectId = {
                        adDomainId: adUser.adDomainId,
                        managerUserEmail: result.email,
                        subProjectId: this.subProjectId
                    };
                    adUser$ = this.daoAdUser.insertAdUserInSubProjectAdUserList(emailAdDomainSubProject);
                    adUser$.subscribe((result2) => {
                        this.dialogRef.close({
                            operation: 'CREATE',
                            data: result2
                        });
                    });
                } else {
                    const adUserWithPassword: ADUserWithPassword = {
                        adUser: adUser,
                        adUserAlreadyExists: customAdUser,
                        adUserPassword: adUserPassword,
                        askThePassword: askThePassword,
                        subProjectId: null
                    };
                    if (remoteChanging) {
                        adUser$ = this.daoAdUser.createAdUserWithCheckRemoveChanging(adUserWithPassword);
                    } else {
                        adUser$ = this.daoAdUser.createAdUserWithCheck(adUserWithPassword);
                    }

                    adUser$.subscribe((result2) => {
                        this.dialogRef.close({
                            operation: 'CREATE',
                            data: result2
                        });
                    });
                }
            });
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onCreateCustomer(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.adminService.addCustomer(result.data);
            this.myForm.get('managerUserForm').get('customerId').setValue(result.data.id);
            markAsTouched(this.myForm);
        } else if (result.operation === 'SAVE') {
        } else if (result.operation === 'DELETE') {
        }
    }
}

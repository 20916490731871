<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header fxLayout="column" fxLayoutAlign="start stretch" style="padding: 17px 0px 0px 10px;">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Session Status</div>
            <button *ngIf="!loading" mat-raised-button type="button" color="primary" style="margin-right: 30px;" class="md-small" (click)="getSessionNumbers()">
                <span>Update</span>
            </button>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="scroll-content">
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
            <table class="session-status" *ngIf="!loading && sessionNumbers">
                <tr>
                    <td class="pr-10">
                        <span
                            class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">{{sessionNumbers.stompUser}}</span>
                    </td>
                    <td>Stomp Users</td>
                </tr>
                <tr>
                    <td class="pr-10">
                        <span
                            class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">{{sessionNumbers.stompSession}}</span>
                    </td>
                    <td>Stomp Sessions</td>
                </tr>
                <tr>
                    <td class="pr-10">
                        <span
                            class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">{{sessionNumbers.stompSubscription}}</span>
                    </td>
                    <td>Stomp Subscription</td>
                </tr>
                <tr>
                    <td class="pr-10">
                        <span
                            class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">{{sessionNumbers.session}}</span>
                    </td>
                    <td>Sessions</td>
                </tr>
                <tr>
                    <td class="pr-10">
                        <span
                            class="nav-link-badge ng-star-inserted accent-A700-bg accent-A700">{{sessionNumbers.sessionAttribute}}</span>
                    </td>
                    <td>Session Attributes</td>
                </tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>

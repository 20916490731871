/* tslint:disable:no-bitwise triple-equals */
export class ObjectHelper {
    private a: any;

    constructor() {
        // @ts-ignore
        this.a = window.crypto || window.msCrypto;
        if (!this.a) { throw new Error('Strong random numbers are not available.'); }        
    }


    cloneObject(a: any): any {
        if (null === a) { return null; }
        let b;
        const c = Object.create(a);
        // const c = _.cloneDeep(a);
        // tslint:disable-next-line:forin
        for (b in a) { void 0 !== a[b] && (c[b] = a[b]); }
        return c;
        // return _.cloneDeep(a);
    }
    
    createGuid(): string {
        const b = new Uint8Array(16);
        this.a.getRandomValues(b), b[6] = 79 & (64 | b[6]), b[8] = 191 & (128 | b[8]);
        let c, d = '';
        for (c = 0; c < 16; c++) {
            4 != c && 6 != c && 8 != c && 10 != c || (d += '-');
            let e = b[c].toString(16);
            1 == e.length && (e = '0' + e), d += e;
        }
        return d;
    }
}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SearchCriteria} from '../../../models';
import {PageEvent} from '@angular/material/paginator';
import {Page} from '../../../helpers/Page';
import {AiFile} from '../../../models-ai';
import {KeyValueWrapper} from '../../../helpers/key-value-wrapper';

@Injectable({
    providedIn: 'root'
})
export class AiUserGroupFileDaoService extends BaseDao<any> implements DataSource<AiFile> {
    private aiURL = '';

    private subject = new BehaviorSubject<AiFile[]>([]);
    private totalSubject = new BehaviorSubject<number>(0);

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    connect(collectionViewer: CollectionViewer): Observable<AiFile[] | ReadonlyArray<AiFile>> {
        this.subject = new BehaviorSubject<AiFile[]>([]);
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.totalSubject.complete();
    }


    clearAllData(): void {
        this.subject.next(null);
        this.totalSubject.next(null);
    }

    load(field: string, value: string, page: PageEvent, isDefault: boolean): void {
        this.clearAllData();
        if (this.subject && this.totalSubject) {
            this.search(
                {field: field, value: value, limit: page.pageSize, page: page.pageIndex, fieldOrder: 'id', order: 'desc'}, isDefault
            ).subscribe((result) => {
                const totalElements = result?.totalElements;
                this.totalSubject.next(totalElements);
                this.subject.next(result?.content);
            });
        }
    }

    get total$(): Observable<number> {
        if (!this.totalSubject) {
            this.totalSubject = new BehaviorSubject<number>(0);
        }
        return this.totalSubject.asObservable();
    }

    addFile(file: AiFile): void {
        const AiFiles = this.subject.value;
        AiFiles.unshift(file);
        this.subject.next(AiFiles);
    }

    updateFile(file: AiFile): void {
        const AiFiles = this.subject.value;
        const index = AiFiles.findIndex((f) => (f.id === null || f.id < 0) && f.name === file.name && f.size === file.size);
        if (index !== -1) {
            AiFiles[index] = file;
            this.subject.next(AiFiles);
        }
    }

    delete(file: AiFile): Observable<void> {
        this.removeFileFromListById(file.id);
        return this.http.delete(this.aiURL + 'ai-file/', file, true);
    }

    private removeFileFromListById(aiFileId: number): void {
        this.subject.next(this.subject.value.filter(item => item.id !== aiFileId));
    }

    removeFileFromListByName(file: AiFile): void {
        this.subject.next(this.subject.value.filter(item => item.name !== file.name));
    }

    getAll(): AiFile[] {
        return this.subject.getValue();
    }

    statistics(customerGroupId: number): Observable<KeyValueWrapper[]> {
        return this.http.get<KeyValueWrapper[]>(this.aiURL + 'ai-file/statistics/' + customerGroupId, null, null, true);
    }

    private search(searchCriteria: SearchCriteria, isDefault: boolean): Observable<Page<AiFile>> {
        let defaultParam = '';
        if (isDefault) {
            defaultParam = '?isDefault=true';
        }
        return this.http.put<Page<AiFile>>(this.aiURL + 'ai-file/search' + defaultParam, searchCriteria, true);
    }

    uploadFile(file: File, aiFile: AiFile, aiUserGroupId: number): Observable<AiFile> {
        aiFile.id = null;
        const formData: FormData = new FormData();
        formData.append('aiFile', JSON.stringify(aiFile));
        formData.append('aiUserGroupId', aiUserGroupId.toString());
        formData.append('file', file, file.name);
        return this.http.upload<AiFile>(formData, this.aiURL + 'ai-file/', true);
    }
}

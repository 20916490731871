import {Injectable} from '@angular/core';
import {PlatformInfo} from './ts/platform-info';
import {DeviceInfo} from './ts/device-info';

@Injectable({
    providedIn: 'root'
})
export class PlatformInfoService extends PlatformInfo {

    constructor() {
        super(new DeviceInfo(null), window, null);
    }
}

<table mat-table [dataSource]="networkConfigurationDataSource" class="mat-elevation-z8 width-100-percent mt-25 ">

    <!-- Name Column -->
    <ng-container matColumnDef="name" class="width-100-percent">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element" >{{element.name}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="speed">
        <th mat-header-cell *matHeaderCellDef> velocidade </th>
        <td mat-cell *matCellDef="let element" > {{element.speed}} Mbps </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="ipV4">
        <th mat-header-cell *matHeaderCellDef> ipV4 </th>
        <td mat-cell *matCellDef="let element" > <small>{{element.ipV4}}</small> </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="macAddress">
        <th mat-header-cell *matHeaderCellDef> MAC Address </th>
        <td mat-cell *matCellDef="let element" > {{element.macAddress}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Descrição </th>
        <td mat-cell *matCellDef="let element" > <small>{{element.description}} </small></td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element" > {{element.status}}</td>
    </ng-container>


    <ng-container matColumnDef="firstSeenIn">
        <th mat-header-cell *matHeaderCellDef> Primeira vez em </th>
        <td mat-cell *matCellDef="let element" > {{element.createdAt | date: 'dd/MM/yyyy HH:mm' }} </td>
    </ng-container>


    <!-- Name Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 100px;">  </th>
        <td mat-cell *matCellDef="let element">
            <button type="button" color="accent" mat-raised-button (click)="openDetails(element)"  >Detalhes</button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="networkConfigurationTable"></tr>
    <tr mat-row *matRowDef="let row; columns: networkConfigurationTable;"></tr>


</table>


import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function atLeastOne(fieldError: any, myForm: FormGroup, formNames: string[]): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        let error = true;
        formNames.forEach((formName) => {
            const formControl: FormGroup = myForm.controls[formName] as FormGroup;
            if (error && formControl.value && !formControl.pristine && formControl.value?.length > 0) {
                error = false;
            }
        });
        if (error) {
            formNames.forEach((formName) => {
                const formControl: FormGroup = myForm.controls[formName] as FormGroup;
                if (formControl) {
                    formControl.setErrors(fieldError);
                }
            });
        }
        return;
    };
}





import {Component, Inject} from '@angular/core';
import {DesktopServerDaoService} from '../desktop-server-dao.service';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as download from 'download.js';

import {ClientDownloadService} from '../../desktop/client-download.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {
    ADDomain,
    DesktopServer,
    DesktopServerPrivateKey,
    DotNetServerPluginTemplate,
    FirewallFQDN,
    GatewayServer,
    GatewayServerNatConfig,
    Project,
    SubProject
} from '../../../models';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {DotNetServerPluginService} from '../../server-plugin/services/server-plugin.service';
import {DotNetServerAgentFileDaoService} from '../../server-plugin/services/server-agent-file-dao.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {FirewallFqdnComponent} from '../../firewall-fqdn/firewall-fqdn.component';
import {FirewallFqdnDaoService} from '../../firewall-fqdn/firewall-fqdn-dao.service';
import {isValidFQDN} from '../../../utils/utils-kluh';
import {DesktopServerLocalAdminComponent} from '../desktop-server-local-admin/desktop-server-local-admin.component';
import {GatewayServerNatConfigModalComponent} from '../../gateway-server/gateway-server-nat-config/gateway-server-nat-config-modal/gateway-server-nat-config-modal.component';
import {GatewayServerNatConfigDaoService} from '../../gateway-server/gateway-server-nat-config/gateway-server-nat-config-dao.service';
import {GatewayServerDaoService} from '../../gateway-server/gateway-server-dao.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';


@Component({
    selector: 'app-desktop-server-create',
    templateUrl: './desktop-server-create.component.html',
    styleUrls: ['./desktop-server-create.component.scss']
})
export class DesktopServerCreateComponent extends ComponentCleaner {
    myForm: FormGroup;
    adDomains: ADDomain[];
    desktopServer: DesktopServer;
    firewallFQDNList: FirewallFQDN[] = [];
    desktopServers: DesktopServer[];
    subProject: SubProject;
    project: Project;
    serverPluginTemplates$: Observable<DotNetServerPluginTemplate[]>;
    gatewayServerNatConfig: GatewayServerNatConfig;
    gatewayServers: GatewayServer[] = [];
    userHasPermission: boolean;


    constructor(public dialogRef: MatDialogRef<DesktopServerCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private desktopServerDaoService: DesktopServerDaoService,
                private customerGroupService: CustomerGroupService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private subProjectDaoService: SubProjectDaoService,
                private gatewayServerNatConfigDaoService: GatewayServerNatConfigDaoService,
                private gatewayServerDaoService: GatewayServerDaoService,
                private fb: FormBuilder,
                private adminService: R2CloudAdminService,
                private dialog: MatDialog,
                public clientDownloadService: ClientDownloadService,
                private firewallFqdnDaoService: FirewallFqdnDaoService,
                public serverPluginService: DotNetServerPluginService,
                public serverAgentFileDao: DotNetServerAgentFileDaoService,
                public domainPathService: DomainPathService,
    ) {
        super();
        const newDesktopServer: DesktopServer = {
            disableUpdate: false,
            adDomainId: null,
            clientId: '',
            publicIp: '',
            privateIp: '',
            hostname: '',
            connectionFQDN: '',
            firewallFQDNIds: [],
            connectionPort: null,
            id: null,
            comment: '',
            active: true,
            remoteAppIds: [],
            gatewayFQDN: null,
            internalPort: 3389,
            serverPluginTemplateIds: [],
            modified: null,
            gatewayServerNatConfigActive: null,
            desktopServerPoolId: null,
            optlock: null,
            disposable: null,
            lastLoginAt: null,
            adIp: null
        };
        this.adDomains = data.adDomains;
        this.subProject = data.subProject;
        this.desktopServers = data.desktopServers;
        this.project = data.project;
        this.desktopServer = data.desktopServer;
        if (this.adDomains) {
            this.adDomains = this.adDomains.filter(x => x.active === true);
        }
        // tendo um só dominio já deixa selecionado
        if (this.adDomains && this.adDomains.length === 1) {
            newDesktopServer.adDomainId = this.adDomains[0].id;
            if (this.desktopServer) {
                this.desktopServer.adDomainId = this.adDomains[0].id;
            }
        }

        if (this.desktopServer) {
            this.myForm = this.fb.group(convertToFormGroup(this.desktopServer), {asyncValidator: this.desktopServerDaoService.validator});
        } else {
            this.myForm = this.fb.group(convertToFormGroup(newDesktopServer), {asyncValidator: this.desktopServerDaoService.validator});
        }
        this.myForm.setValidators([this.isValidFQDNValidator({fqdn: 'FQDN Inválido'}, 'gatewayFQDN')]);

        this.firewallFqdnDaoService.findAllByDesktopServerId(this.desktopServer.id).subscribe((firewallFQDNList) => {
            if (firewallFQDNList) {
                this.firewallFQDNList = firewallFQDNList;
            }
        });


        this.userAuthorityDaoService.hasParentPermission(
            'CustomerGroup',
            this.customerGroupService.customerGroup.id,
            'GatewayServerNatConfig',
            'WRITE').subscribe(userHasPermissionADUser => {
            if (userHasPermissionADUser && userHasPermissionADUser.value) {
                this.userHasPermission = true;
                this.findGatewayServerAndGatewayServerNatConfigByDesktopServerId();
            } else {
                this.userAuthorityDaoService.hasParentPermission(
                    'SubProject',
                    this.adminService.subProject.id,
                    'GatewayServerNatConfig',
                    'WRITE').subscribe(userHasPermission => {
                    if (userHasPermission && userHasPermission.value) {
                        this.userHasPermission = true;
                        this.findGatewayServerAndGatewayServerNatConfigByDesktopServerId();
                    }
                });
            }
        });
    }

    private findGatewayServerAndGatewayServerNatConfigByDesktopServerId(): void {
        this.gatewayServerNatConfigDaoService.findByDesktopServerId(this.desktopServer.id).subscribe((gatewayServerNatConfig) => {
            if (gatewayServerNatConfig?.gatewayServerId) {
                this.gatewayServerNatConfig = gatewayServerNatConfig;
                this.gatewayServerDaoService.getOne(this.gatewayServerNatConfig.gatewayServerId).subscribe((gatewayServer) => {
                    if (gatewayServer) {
                        this.gatewayServers.push(gatewayServer);
                    }
                });
            }
        });
    }

    findGatewaySaverById(gatewayServerId: number): GatewayServer {
        return this.gatewayServers.find(gatewayServer => gatewayServer.id === gatewayServerId);
    }

    private isValidFQDNValidator(fieldError: any, fieldName: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const controller: FormGroup = group.controls[fieldName] as FormGroup;
            if (!controller.value) {
                return;
            }
            if (!isValidFQDN(controller.value)) {
                controller.setErrors(fieldError);
            }
            return;
        };
    }

    onSubmitConfirm(): void {
        let desktopServer$: Observable<DesktopServer>;
        let operation: CrudOperation;
        let desktopServerPrivateKey: string;
        const crudOperations: CrudOperationWrapper[] = [];
        const desktopServer: DesktopServer = this.myForm.value;

        const copyInAllSharedSubProjectsFilterControl = false;

        if (desktopServer.id) {
            operation = 'SAVE';
            desktopServer$ = this.desktopServerDaoService.save(desktopServer);
        } else {
            operation = 'CREATE';
            desktopServer$ = this.desktopServerDaoService.createDesktopServerInAllSubProjectsOfProjectAndGetPrivateKey(
                desktopServer, this.subProject.id, copyInAllSharedSubProjectsFilterControl)
                .pipe(switchMap(
                    (desktopServerWithPrivateKey: DesktopServerPrivateKey) => {
                        if (desktopServerWithPrivateKey) {
                            desktopServerPrivateKey = desktopServerWithPrivateKey.privateKey;
                            const desktopServerObservable = this.desktopServerDaoService.getOne(desktopServerWithPrivateKey.serverId);
                            this.subProject.desktopServerIds.push(desktopServerWithPrivateKey.serverId);
                            return desktopServerObservable;
                        } else {
                            return of(null);
                        }
                    }));
        }
        desktopServer$.subscribe((desktopServerSaved: DesktopServer) => {
            if (desktopServerPrivateKey) {
                download.downloadText(desktopServerSaved.hostname + '-' + desktopServerSaved.clientId + '.txt', desktopServerPrivateKey);
            }
            crudOperations.push({
                data: desktopServerSaved,
                operation: operation
            });
            if (!this.subProject.desktopServerIds) {
                this.subProject.desktopServerIds = [];
            }
            let subProject$: Observable<SubProject>;
            let subProjectOperation: CrudOperation;
            const index = this.subProject.desktopServerIds.findIndex(value => {
                return value === desktopServerSaved.id;
            });
            if (index === -1 && !desktopServerSaved.disposable) {
                this.subProject.desktopServerIds.push(desktopServerSaved.id);
                subProjectOperation = 'SAVE';
                subProject$ = this.subProjectDaoService.save(this.subProject);
            } else {
                subProjectOperation = 'CANCEL';
                subProject$ = of(this.subProject);
            }
            subProject$.subscribe((result2) => {
                crudOperations.push({
                    data: result2,
                    operation: subProjectOperation
                });
                if (this.gatewayServerNatConfig?.id &&
                    desktopServer.gatewayServerNatConfigActive && (
                        this.data.desktopServer.connectionPort !== desktopServer.connectionPort || this.data.desktopServer.connectionFQDN !== desktopServer.connectionFQDN)) {
                    const subscription = this.dialog.open(ConfirmDialogComponent, {
                        disableClose: true,
                        data: {
                            message: '<h1 class="text-align-center mb-20">R2 Security</h1><div class="mb-40">O <b>R2 Security</b> não está com as informações atualizadas deste servidor.<br>' +
                                ' Por favor atualize o R2 Security para manter o acesso ao servidor.</div>',
                            disableCancel: true,
                            confirmButtonValue: 'OK',
                            icon: 'error_outline'
                        }
                    }).afterClosed().subscribe(() => {
                        this.dialogRef.close(crudOperations);
                    });
                    this.addSubscription(subscription);
                } else {
                    this.dialogRef.close(crudOperations);
                }
            });
        });

    }

    onSubmit(): void {
        if (!this.myForm.value.id) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Assim que salvar as configurações desse servidor, <br />' +
                        'seu navegador fará o <u>download de um arquivo .txt</u> ' +
                        'contendo a chave criptografada <br /> ' +
                        'que será usada para instalar o Client nesse mesmo servidor.',
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe(() => {
                this.onSubmitConfirm();
            });
            this.addSubscription(subscription);
        } else {
            this.onSubmitConfirm();
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onReallyDelete(): void {
        const desktopServer: DesktopServer = this.myForm.value;
        this.desktopServerDaoService.findAllSubProjectNames(desktopServer.id).subscribe(subProjectNames => {
            if (subProjectNames && subProjectNames.length === 1 || desktopServer.disposable) {
                const crudOperations: CrudOperationWrapper[] = [];
                this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: '<b>Confirme</b> a exclusão digitando o nome do servidor: <b>' + desktopServer.hostname +
                            '</b> no campo abaixo <br> Esse procedimento não tem volta e deletará tudo que for relacionado a esse servidor.',
                        disableCancel: false,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline',
                        confirmFieldValue: desktopServer.hostname
                    }
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.desktopServerDaoService.remove(desktopServer.id).subscribe(() => {
                            const index = this.subProject.desktopServerIds.findIndex(value => {
                                return value === desktopServer.id;
                            });
                            crudOperations.push({
                                data: desktopServer,
                                operation: 'DELETE'
                            });
                            this.adminService.removeRemoteAppByDesktopServerId(desktopServer.id);
                            if (index !== -1) {
                                let subProject$: Observable<SubProject>;
                                this.subProject.desktopServerIds.splice(index, 1);
                                subProject$ = of(this.subProject);
                                subProject$.subscribe((result3) => {
                                    crudOperations.push({
                                        data: result3,
                                        operation: 'DELETE'
                                    });
                                    this.dialogRef.close(crudOperations);
                                });
                            } else {
                                this.dialogRef.close();
                            }

                        });
                    }
                }));
            } else {
                const subProjectNamesHtml = '<ul>' + subProjectNames.map(value => `<li>${value}</li>`).join('') + '</ul>';
                this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: 'Não é permitido deletar um servidor compartilhado antes que o mesmo<br>seja removido dos outros projetos.<br><br>' +
                            'Servidor usado em:<br> <b>' + subProjectNames.length + '</b> projetos<br>' +
                            subProjectNamesHtml,
                        disableCancel: true,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline'
                    }
                });
            }
        });

    }

    onDelete(): void {
        const crudOperations: CrudOperationWrapper[] = [];
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover o servidor desse projeto?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const desktopServer: DesktopServer = this.myForm.value;
                this.desktopServerDaoService.deleteDesktopServerFromSubProject(desktopServer.id, this.subProject.id).subscribe(() => {
                    const index = this.subProject.desktopServerIds.findIndex(value => {
                        return value === desktopServer.id;
                    });
                    crudOperations.push({
                        data: desktopServer,
                        operation: 'DELETE'
                    });
                    this.adminService.removeRemoteAppByDesktopServerId(desktopServer.id);
                    if (index !== -1) {
                        let subProject$: Observable<SubProject>;
                        this.subProject.desktopServerIds.splice(index, 1);
                        subProject$ = of(this.subProject);
                        subProject$.subscribe((result3) => {
                            crudOperations.push({
                                data: result3,
                                operation: 'DELETE'
                            });
                            this.dialogRef.close(crudOperations);
                        });
                    } else {
                        this.dialogRef.close();
                    }

                });
            }
        });
        this.addSubscription(subscription);
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.desktopServer.id, this.desktopServerDaoService, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.desktopServer.id, this.desktopServerDaoService, this.dialog);
    }

    onSyncServer(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Ao sincronizar o servidor, será enviado comandos para recriar todos os ' +
                    'Aplicativos do servidor, <br />caso seja controlador de domínio será enviado também para Grupos e Usuários.<br /><br />' +
                    '<b>Deseja continuar?</b>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const desktopServer: DesktopServer = this.myForm.value;
                this.desktopServerDaoService.syncServer(desktopServer.id).subscribe((result2) => {
                    if (result2) {
                        this.dialogRef.close();
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }

    onGenerateNewPrivateKey(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Ao gerar uma nova chave privada, será necessário reinstalar o agente no servidor.<br />' +
                    'Deseja continuar?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const desktopServer: DesktopServer = this.myForm.value;
                this.desktopServerDaoService.generateNewPrivateKey(desktopServer.id).subscribe((result2) => {
                    if (result2) {
                        download.downloadText(desktopServer.hostname + '-' + desktopServer.clientId + '.txt',
                            result2.privateKey);
                        this.dialogRef.close();
                    } else {
                        //  TODO Erro
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }

    onAgentUpdate(desktopServerId: number): void {

        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja atualizar o agent do Windows Server desse servidor?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverAgentFileDao.sendUpdate(desktopServerId).subscribe(() => {
                    this.dialogRef.close();
                });
            }
        });
    }

    onGetLocalAdmin(desktopServerId: number): void {
        this.desktopServerDaoService.getLocalAdmin(desktopServerId).subscribe((localAdmin) => {
            if (localAdmin) {
                this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: '<h2>Credenciais do servidor:</h2><br>' + localAdmin.localAdmin + '<br>' + localAdmin.localAdminPassword,
                        disableCancel: true,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline'
                    }
                }).afterClosed().subscribe(() => {
                });
            }
        });
    }

    openFirewallFQDNList(): void {
        this.dialog.open(FirewallFqdnComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                desktopServer: this.desktopServer,
                firewallFQDNList: this.firewallFQDNList
            }
        }).afterClosed().subscribe((result: FirewallFQDN[]) => {
            if (result) {
                this.firewallFQDNList = result;
            }
        });
    }

    openDesktopServerLocalAdminCredentials(): void {
        this.dialog.open(DesktopServerLocalAdminComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                desktopServer: this.desktopServer
            }
        }).afterClosed().subscribe(() => {
        });
    }


    openGatewayServerNatConfigModal(): void {
        this.gatewayServerNatConfig = this.addDesktopServerIdInGatewayServerNatConfig(this.gatewayServerNatConfig, this.desktopServer.id);
        const dialogRef = this.dialog.open(GatewayServerNatConfigModalComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                gatewayServerNatConfig: this.gatewayServerNatConfig
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'SAVE') {
                this.gatewayServerNatConfig = result.data;
            } else if (result.operation === 'DELETE') {
                this.gatewayServerNatConfig = null;
            }
            dialogRef.close();
        }));
    }

    gatewayServerNatConfigCheck(): void {
        this.gatewayServerNatConfig = this.addDesktopServerIdInGatewayServerNatConfig(this.gatewayServerNatConfig, this.desktopServer.id);
        this.gatewayServerNatConfigDaoService.suspendCheck(this.gatewayServerNatConfig).subscribe((results) => {
            if (results) {
                let result = '<table class="mb-60">';
                let error = false;
                results.forEach(configCheck => {
                    if (!configCheck.status) {
                        error = true;
                        result = result + '<tr><td class="pt-14">name:</td> <td class="pt-14">' + configCheck.name + '</td></tr>';
                        result = result + '<tr><td>functionName:</td> <td>' + configCheck.functionName + '</td></tr>';
                        result = result + '<tr><td>status:</td> <td><b class="red p-5">ERROR</b></td></tr>';
                        result = result + '<tr><td>databaseValue:</td> <td>' + configCheck.databaseValue + '</td></tr>';
                        result = result + '<tr><td class="border-bottom pb-20">gatewayValue:</td> <td class="border-bottom pb-20">' + configCheck.gatewayValue + '</td></tr>';

                    }
                });
                result = result + '</table>';
                if (!error) {
                    result = '<br><div class="w-400"><h2 class="text-align-center">OK</h2></div>' + result;
                } else {
                    result = '<h2 class="mt-0 text-align-center width-100-percent">ERROR</h2>' + result;
                }
                const message = result;
                const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: message,
                        disableCancel: true,
                        icon: 'info_outline',
                        confirmButtonValue: 'OK'
                    }
                });
                matDialogRef.afterClosed().subscribe(() => {
                    //
                });
            }
        });
    }

    addDesktopServerIdInGatewayServerNatConfig(gatewayServerNatConfig: GatewayServerNatConfig, desktopServerId: number): GatewayServerNatConfig {
        if (gatewayServerNatConfig) {
            gatewayServerNatConfig.desktopServerId = desktopServerId;
            return gatewayServerNatConfig;
        }
        const gatewayServerNatConfigInner = this.gatewayServerNatConfigDaoService.initGatewayServerNatConfig();
        gatewayServerNatConfigInner.desktopServerId = desktopServerId;
        return gatewayServerNatConfigInner;
    }
}

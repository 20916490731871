<form *ngIf="myForm" [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Criar Ai Model</span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-15" fxLayoutAlign="start start">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name" placeholder="Nome">
                <mat-error>
                    {{ myForm.get('name').errors | error }}
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
                    aria-label="Salvar">Salvar
            </button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</form>
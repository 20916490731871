import {KluhManagerValidator} from './validators/kluh-manager-validator';
import {Observable, of, throwError} from 'rxjs';
import {AsyncValidatorFn} from '@angular/forms';
import {BaseDTOIdLong, SnapshotType} from './models';
import {JaversSnapshot} from './javers/javers-snapshot';
import {JaversShadow} from './javers/javers-shadow';
import {JaversChanges} from './javers/javers-changes';
import {ObservableCache} from './helpers/CacheControl';
import {catchError, shareReplay} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {R2CloudHttpBase} from './r2-cloud-http.base';

export abstract class BaseDao<T extends BaseDTOIdLong> {

    protected url: string;
    validator: AsyncValidatorFn;
    private observableCachesList: ObservableCache[] = [];

    protected constructor(protected http: R2CloudHttpBase, protected validatorDAO: KluhManagerValidator, url: string) {
        this.url = url;
        this.validator = this.validatorDAO.validator(this.url, this.http);
    }

    clearCache(): void{
        this.observableCachesList = [];
    }

    getAllCache(): Observable<T[]> {
        let cache: ObservableCache = this.observableCachesList.find(value => {
            return value.params === null && value.path === this.url;
        });
        if (!cache) {
            const getAll$ = this.http.get<T[]>(this.url).pipe(catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    return of([]);
                }
                return throwError(error);
            }), shareReplay(1));
            cache = {
                params: null,
                path: this.url,
                observable: getAll$
            };
            this.observableCachesList.push(cache);
        }
        return cache.observable;
    }

    get(): Observable<T[]> {
        return this.http.get<T[]>(this.url);
    }

    getOne(id: number): Observable<T> {
        return this.http.get<T>(this.url + '/' + id);
    }

    getActive(params?: any): Observable<T[]> {
        let myParams: any;
        if (!params) {
            myParams = {};
        } else {
            myParams = params;
        }
        myParams['active'] = 'true';
        return this.filter(myParams);
    }

    filterCache(params?: any): Observable<T[]> {
        let cache: ObservableCache = this.observableCachesList.find(value => {
            return value.params === params && value.path === this.url;
        });
        if (!cache) {
            const filter$ = this.http.get<T[]>(this.url + '/filter', params).pipe(catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    return of([]);
                }
                return throwError(error);
            }), shareReplay(1));
            cache = {
                params: params,
                path: this.url,
                observable: filter$
            };
            this.observableCachesList.push(cache);
        }
        return cache.observable;
    }

    filter(params?: any): Observable<T[]> {
        return this.http.get<T[]>(this.url + '/filter', params);
    }

    filterOne(params?: any): Observable<T> {
        return this.http.get<T>(this.url + '/filter-one', params);
    }

    save(entity: T): Observable<T> {
        return this.http.put<T>(this.url + '/' + entity.id, entity);
    }

    saveAll(entityList: T[]): Observable<T[]> {
        return this.http.patch<T[]>(this.url + '/batch-save', entityList);
    }

    createAll(entityList: T[]): Observable<T[]> {
        return this.http.post<T[]>(this.url + '/batch-create', entityList);
    }

    create(entity: T): Observable<T> {
        return this.http.post<T>(this.url, entity);
    }

    remove(entityId: number): Observable<string> {
        return this.http.remove(this.url + '/' + entityId);
    }

    snapshotsByTypeAndLimit(limit: number, snapshotType: SnapshotType): Observable<JaversSnapshot<T>[]> {
        return this.http.get<JaversSnapshot<T>[]>(this.url + '/snapshots-by-type-and-limit', {'limit': limit , 'snapshotType' : snapshotType});
    }
    snapshotsLimit(limit: number): Observable<JaversSnapshot<T>[]> {
        return this.http.get<JaversSnapshot<T>[]>(this.url + '/snapshots-limit', {'limit': limit});
    }

    snapshots(): Observable<JaversSnapshot<T>[]> {
        return this.http.get<JaversSnapshot<T>[]>(this.url + '/snapshots');
    }

    shadows(): Observable<JaversShadow<T>[]> {
        return this.http.get<JaversShadow<T>[]>(this.url + '/shadows');
    }

    changes(): Observable<JaversChanges[]> {
        return this.http.get<JaversChanges[]>(this.url + '/changes');
    }

    entitySnapshots(id: number, limit: number = 10, page: number = 0): Observable<JaversSnapshot<T>[]> {
        return this.http.get<JaversSnapshot<T>[]>(`${this.url}/snapshots/${id}?limit=${limit}&page=${page}`);
    }

    entityShadows(id: number, limit: number = 10, page: number = 0): Observable<JaversShadow<T>[]> {
        return this.http.get<JaversShadow<T>[]>(`${this.url}/shadows/${id}?limit=${limit}&page=${page}`);
    }

    entityChanges(id: number, limit: number = 10, page: number = 0): Observable<JaversChanges[]> {
        return this.http.get<JaversChanges[]>(`${this.url}/changes/${id}?limit=${limit}&page=${page}`);
    }
}

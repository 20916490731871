import {Component, Input} from '@angular/core';
import {HipcomBulkUpdateCurrentSoftwareVersion, HipcomBulkUpdateDetail, HipcomBulkUpdateLatestSoftwareVersion, StatusType} from '../../../../../models';
import {HipcomBulkUpdateService} from '../hipcom-bulk-update.service';

@Component({
    selector: 'app-hipcom-bulk-update-dashboard',
    templateUrl: './hipcom-bulk-update-dashboard.component.html',
    styleUrls: ['./hipcom-bulk-update-dashboard.component.scss', '../hipcom-bulk-update.component.scss']
})
export class HipcomBulkUpdateDashboardComponent {

    @Input()
    hipcomBulkUpdateLatestSoftwareVersions: HipcomBulkUpdateLatestSoftwareVersion[];

    @Input()
    hipcomBulkUpdateCurrentSoftwareVersions: HipcomBulkUpdateCurrentSoftwareVersion[];

    @Input()
    hipcomBulkUpdateDetails: HipcomBulkUpdateDetail[];


    constructor(
        private hipcomBulkUpdateService: HipcomBulkUpdateService) {
    }

    getNumberOfServers(): number {
        if (this.hipcomBulkUpdateDetails) {
            return this.hipcomBulkUpdateDetails.length;
        }
        return 0;
    }

    getNumberOfError(): number {
        let error = 0;
        if (this.hipcomBulkUpdateDetails) {
            for (let i = 0; i < this.hipcomBulkUpdateDetails.length; i++) {
                if (this.hipcomBulkUpdateDetails[i].status === StatusType.ERROR) {
                    error++;
                }
            }
        }
        return error;
    }


    getNumberOfUpdatesScheduled(numDay: number, checkDifferent: boolean): number {
        let schedule = 0;
        if (this.hipcomBulkUpdateDetails) {
            const today = new Date();
            today.setDate(today.getDate() + numDay);
            const weekDay = today.getDay();
            for (const detail of this.hipcomBulkUpdateDetails) {
                if (detail.active) {
                    let isDifferent = false;
                    for (const id of detail.hipcomBulkUpdateCurrentSoftwareVersionIds) {
                        const current = this.findHipcomBulkUpdateCurrentSoftwareVersionById(id);
                        const last = this.findHipcomBulkUpdateLatestSoftwareVersionById(current?.hipcomBulkUpdateLatestSoftwareVersionId);
                        if (current?.version !== last?.version) {
                            isDifferent = true;
                        }
                    }
                    if (isDifferent || !checkDifferent) {
                        if (weekDay === 0) {
                            if (detail.sundayStartHours) {
                                schedule++;
                            }
                        } else if (weekDay === 1) {
                            if (detail.mondayStartHours) {
                                schedule++;
                            }
                        } else if (weekDay === 2) {
                            if (detail.tuesdayStartHours) {
                                schedule++;
                            }
                        } else if (weekDay === 3) {
                            if (detail.wednesdayStartHours) {
                                schedule++;
                            }
                        } else if (weekDay === 4) {
                            if (detail.thursdayStartHours) {
                                schedule++;
                            }
                        } else if (weekDay === 5) {
                            if (detail.fridayStartHours) {
                                schedule++;
                            }
                        } else if (weekDay === 6) {
                            if (detail.saturdayStartHours) {
                                schedule++;
                            }
                        }
                    }
                }
            }
        }
        return schedule;
    }


    findHipcomBulkUpdateLatestSoftwareVersionById(id: number): HipcomBulkUpdateLatestSoftwareVersion {
        return this.hipcomBulkUpdateService.findHipcomBulkUpdateLatestSoftwareVersionById(id, this.hipcomBulkUpdateLatestSoftwareVersions);
    }

    findHipcomBulkUpdateCurrentSoftwareVersionById(id: number): HipcomBulkUpdateCurrentSoftwareVersion {
        return this.hipcomBulkUpdateService.findHipcomBulkUpdateCurrentSoftwareVersionById(id, this.hipcomBulkUpdateCurrentSoftwareVersions);
    }


    getTotalNumberOfModules(): number {
        let modules = 0;
        if (this.hipcomBulkUpdateDetails) {
            for (const detail of this.hipcomBulkUpdateDetails) {
                if (detail) {
                    for (const currentId of detail.hipcomBulkUpdateCurrentSoftwareVersionIds) {
                        modules++;
                    }
                }
            }
        }
        return modules;
    }
}

import {Store} from './store';
import {StoreType} from './enum/store-type';
import {StoreTransactionMode} from './enum/store-transaction-mode';
import {ObjectHelper} from './object-helper';

export class SessionStore extends Store {
    constructor() {
        super(StoreTransactionMode.Immediate, StoreType.Memory, null, null, null, !1);
    }

    save(b: any): void {
        null === this.id && (this.id = (new ObjectHelper()).createGuid()), super.save(b);
    }
}

import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {PdfPrint} from '../../models';

import {PdfPrintDaoService} from './pdf-print-dao.service';
import {filter, startWith} from 'rxjs/operators';
import {DataSource} from '@angular/cdk/collections';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-my-prints',
    templateUrl: './my-prints.component.html',
    styleUrls: ['./my-prints.component.scss']
})


export class MyPrintsComponent implements AfterViewInit {
    displayedColumns: string[] = ['id', 'jobId', 'createdAt', 'printId'];
    dataSource: DataSource<PdfPrint>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    totalPdfPrints = 0;

    constructor(
        private pdfPrintDaoService: PdfPrintDaoService,
        private dialog: MatDialog) {
        this.dataSource = this.pdfPrintDaoService;

        this.pdfPrintDaoService.total$.subscribe(total => {
            this.totalPdfPrints = total;
        });
    }

    ngAfterViewInit(): void {
        this.loadPage();
    }

    private loadPage(): void {
        this.paginator.page.pipe(startWith({pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize}), filter(value => !!value)).subscribe((page: PageEvent) => {
            this.pdfPrintDaoService.load(page);
        });
    }

    openPDF(id: number): void {
        this.pdfPrintDaoService.downloadPdfById(id).subscribe(pdfPrint => {
            if (pdfPrint) {
                const objectUrl = URL.createObjectURL(pdfPrint);
                window.open(objectUrl);
            }
        });
    }

    delete(id: number): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse arquivo?<br><br>' + id,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.pdfPrintDaoService.remove(id).subscribe(pdfPrint => {
                    this.loadPage();
                });
            }
        });
    }
}

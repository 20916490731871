import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {AdUserPoolRelationshipDaoService} from './ad-user-pool-relationship-dao.service';
import {NgxMaskModule} from 'ngx-mask';
import {AdUserPoolRelationshipEditComponent} from './ad-user-pool-relationship-edit/ad-user-pool-relationship-edit.component';
import {AdUserPoolRelationshipCreateComponent} from './ad-user-pool-relationship-create/ad-user-pool-relationship-create.component';
import {AdUserPoolRelationshipUserServiceCreateComponent} from './ad-user-pool-relationship-user-service-create/ad-user-pool-relationship-user-service-create.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
    imports: [
        CommonModule,
        NgxMaskModule.forRoot(),
        FuseSharedModule,
        KluhSharedModule,
        MatIconModule,
        MatCheckboxModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatRadioModule,
        MatTooltipModule,
        MatDialogModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    providers: [
        AdUserPoolRelationshipDaoService
    ],
    declarations: [AdUserPoolRelationshipEditComponent, AdUserPoolRelationshipCreateComponent, AdUserPoolRelationshipUserServiceCreateComponent]
})
export class AdUserPoolRelationshipModule {
}

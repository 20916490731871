<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Tema de imagens de fundo</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent>
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
            <mat-form-field appearance="outline">
                <mat-label>Nome do tema</mat-label>
                <input matInput formControlName="name">
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>

            <div class="full-width">
                <button mat-raised-button type="button" class="green-bg" (click)="fileInput.click()"
                        style="min-height: 36px;">
                    <mat-icon>library_add</mat-icon>
                    <span class="ml-5">Adicionar imagens</span>
                    <input #fileInput type="file" (change)="onImageFileChange($event)" style="display:none;"
                           accept="image/*" multiple/>
                </button>
                <div class="message-box error mt-10" *ngIf="sizeImageError" style="font-weight: bold;">
                    <div>{{sizeImageError}}</div>
                    <div class="pl-20">largura: {{maxWidth}}px</div>
                    <div class="pl-20">altura: {{maxHeight}}px</div>
                </div>
            </div>

            <div
                *ngIf="backgroundImageTemplate.imageFileTemplateIds || (imageFileElement && imageFileElement.length > 0)"
                class="mt-20 mb-20">
                <h2>Imagens do Tema</h2>
                <span *ngFor="let image of imageFileElement; let i = index;" class="p-14">
                     <img [src]="'data:image/jpg;base64,' + image" width="80" height="80"
                          *ngIf="imageFileElement">
                    <mat-icon class="bt-icon warn-A700-fg mouse-pointer" (click)="onRemoveImageFromFileElement(i)">remove_circle</mat-icon>
                </span>
                <span *ngFor="let imageFileTemplateId of backgroundImageTemplate.imageFileTemplateIds" class="p-14">
                    <img
                        src="{{imageFileService.getPictureUrlFromImageFileTemplateId(imageFileTemplateId, 80)}}"
                        width="80">
                    <mat-icon class="bt-icon warn-A700-fg mouse-pointer" (click)="onRemoveImage(imageFileTemplateId)">remove_circle</mat-icon>
                </span>
            </div>

            <div *ngIf="!backgroundImageTemplate?.id">
                <div class="full-width min-width-230 mt-50 mb-60" *appIsAdmin>
                    <div>Tema público, todos podem usar e copiar</div>
                    <mat-slide-toggle formControlName="public">
                        público
                    </mat-slide-toggle>
                </div>
            </div>


        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <div matTooltip="Salvar">

            <button type="submit" color="accent" mat-raised-button class="mat-green-600-bg"
                    [disabled]="(!myForm.valid || myForm.pristine)">
        <span class="mat-button-wrapper">
            Salvar
        </span>
            </button>
        </div>
        <div *ngIf="!backgroundImageTemplate?.public || isAdmin">
            <button type="button" mat-raised-button color="warn" (click)="onDelete(backgroundImageTemplate)">Excluir
            </button>
        </div>
        <div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>

    </div>
</form>

import {Component, Inject, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs/internal/Observable';

@Component({
    selector: 'app-html5-script-loader-modal',
    templateUrl: './html5-script-loader-modal.component.html',
    styleUrls: ['./html5-script-loader-modal.component.scss']
})
export class Html5ScriptLoaderModalComponent implements OnDestroy {
    private readonly subscription: Subscription;
    progress = 0;


    constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
        const ob$: Observable<number> = this.data.progress$;
        this.subscription = ob$.subscribe((progress) => this.progress = progress * 100);
    }


    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

}

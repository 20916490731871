import {UAParser} from 'ua-parser-js';

export class DeviceInfo {
    b: any;
    c: any;
    
    constructor(a: any) {
        this.b = void 0 === a ? new UAParser : new UAParser(a), this.c = this.b.getResult();
    }


    getResultObject(): any {
        return this.c;
    }
    
    getBrowserInfo(): any {
        return this.c.browser;
    }
    
    getDeviceInfo(): any {
        return this.c.device;
    }
    
    getOSInfo(): any {
        return this.c.os;
    }
    
    getEngineInfo(): any {
        return this.c.engine;
    }
    
    getUAString(): any {
        return this.c.ua;
    }
    
    isSupportedBrowser(): any {
        const a = parseInt(this.c.browser.major, 10);
        return 'Chrome' === this.c.browser.name && a >= BrowserMinimumVersion.CHROME || 'Safari' === this.c.browser.name && a >= BrowserMinimumVersion.SAFARI
            || 'IE' === this.c.browser.name && a >= BrowserMinimumVersion.IE ||
            'Firefox' === this.c.browser.name && a >= BrowserMinimumVersion.FIREFOX || 'Edge' === this.c.browser.name;
    }
    
    isWindows(): any {
        return 'Windows' === this.c.os.name;
    }
    
    osVersion(): any {
        return parseInt(this.c.os.version, 10);
    }
    
    isMac(): any {
        return 'Mac OS' === this.c.os.name;
    }
    
    isMobile(): any {
        return 'tablet' === this.c.device.type || 'mobile' === this.c.device.type;
    }
    
    isChrome(): any {
        return 'Chrome' === this.c.browser.name;
    }
    
    isFirefox(): any {
        return 'Firefox' === this.c.browser.name;
    }
    
    isSafari(): any {
        return 'Safari' === this.c.browser.name;
    }
    
    isIE(): any {
        return 'IE' === this.c.browser.name;
    }
    
    isEdge(): any {
        return 'Edge' === this.c.browser.name;
    }

}

export enum BrowserMinimumVersion {
    CHROME = 45,
    FIREFOX = 55,
    SAFARI = 9,
    IE = 11
}

import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DotNetServerPluginFileDaoService} from '../services/server-plugin-file-dao.service';
import {ClientVersionExpression, DotNetServerPluginFile} from '../../../models';
import {ComponentCleaner} from '../../../component-cleaner';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {convertToFormGroup, markAsTouched} from '../../../helpers/kluh';
import {createErrorModal} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';
import {startWith} from 'rxjs/operators';

@Component({
    selector: 'app-server-plugin-file-create-edit',
    templateUrl: './server-plugin-file-create-edit.component.html',
    styleUrls: ['./server-plugin-file-create-edit.component.scss']
})
export class DotNetServerPluginFileCreateEditComponent extends ComponentCleaner implements OnInit {
    myForm: FormGroup;
    minExpressions = [
        ClientVersionExpression.ANY,
        ClientVersionExpression.EQUAL,
        ClientVersionExpression.NOT,
        ClientVersionExpression.GREATER_THAN,
        ClientVersionExpression.GREATER_THAN_OR_EQUAL
    ];
    maxExpressions = [
        ClientVersionExpression.ANY,
        ClientVersionExpression.LESS_THAN,
        ClientVersionExpression.LESS_THAN_OR_EQUAL
    ];
    pluginName: string;
    private formData: FormData;

    constructor(
        public dialogRef: MatDialogRef<DotNetServerPluginFileCreateEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private serverPluginFileDao: DotNetServerPluginFileDaoService,
        private serverPluginService: DotNetServerPluginService,
        private dialog: MatDialog
    ) {
        super();
        this.pluginName = data.pluginName;
        this.myForm = fb.group(convertToFormGroup(data.serverPluginFile), {asyncValidator: this.serverPluginFileDao.validator});
        this.addSubscription(
            this.getMaxClientVersionExpressionController().valueChanges
                .pipe(startWith(<string>this.getMaxClientVersionExpressionController().value))
                .subscribe((value: ClientVersionExpression) => {
                    this.setMaxClientControllerState(value);
                }));
        const minClientVersionController = this.getMinClientVersionControl();
        this.addSubscription(
            this.getMinClientVersionExpressionController().valueChanges
                .pipe(startWith(<string>this.getMinClientVersionExpressionController().value))
                .subscribe((value: ClientVersionExpression) => {
                    if (value === ClientVersionExpression.ANY) {
                        minClientVersionController.disable();
                    } else {
                        minClientVersionController.enable();
                    }
                }));
    }

    private getMinClientVersionExpressionController(): FormControl {
        return this.myForm.get('minClientVersionExpression') as FormControl;
    }

    private getMinClientVersionControl(): FormControl {
        return this.myForm.get('minClientVersion') as FormControl;
    }

    private getMaxClientVersionExpressionController(): FormControl {
        return this.myForm.get('maxClientVersionExpression') as FormControl;
    }

    private getMaxClientController(): FormControl {
        return this.myForm.get('maxClientVersion') as FormControl;
    }

    private setMaxClientControllerState(value: ClientVersionExpression): void {
        if (value === ClientVersionExpression.ANY) {
            this.getMaxClientController().disable();
        } else {
            this.getMaxClientController().enable();
        }
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        const serverPluginFile: DotNetServerPluginFile = this.myForm.value;
        const json = JSON.stringify(serverPluginFile);
        this.formData.append('json', json);
        this.serverPluginService.onServerPluginFileUploadFinished(this.serverPluginFileDao.upload(this.formData))
            .subscribe(() => {
                this.dialogRef.close();
            });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget && currentTarget.files && currentTarget.files.length === 1) {
            this.setFileFormData(currentTarget.files);
        }
    }

    private setFileFormData(files: File[] | FileList): void {
        const file = files[0];
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        this.formData = formData;
        markAsTouched(this.myForm);
    }

    getFileName(): string {
        if (this.formData) {
            const uploadFile: File = this.formData.get('file') as File;
            if (uploadFile) {
                return uploadFile.name;
            }
        }
        return '';
    }


    fileDrop(files: FileList): void {
        if (files.length > 1){
            createErrorModal(this.dialog, 'Apenas um arquivo é suportado.');
            return;
        }
        this.setFileFormData(files);
    }
}

<div class="dialog-content-wrapper">
    <form [formGroup]="myForm">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de Servidor Windows usando token</span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>

            <div *ngIf="adminService.adDomains && adminService.adDomains.length > 1">
                <h2 style="margin-bottom: 0px;margin-left: 2px;">Selecione o domínio que o servidor faz parte:</h2>
            </div>

            <mat-form-field *ngIf="adminService.adDomains && adminService.adDomains.length > 1" appearance="outline"
                            class="mt-10 mb-10">
                <mat-label>AD Domain</mat-label>
                <mat-select formControlName="adDomainId">
                    <mat-option *ngFor="let adDomain of adminService.adDomains" [value]="adDomain.id">
                        {{adDomain.domainName}} | {{adDomain.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{myForm.get('adDomainId').errors | error}}
                </mat-error>
            </mat-form-field>

            <div *ngIf="token" class="mb-30 mt-10">
                <p>Instrução de como instalar o agente do Windows Server.</p>
                <code>
                    {{script}}
                </code>
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <div *ngIf="script">
                <button *ngIf="!copied" type="button" mat-raised-button color="accent" (click)="onCopyScript(script)">Copiar código
                </button>
                <b class="blue-700-fg pl-10">{{copied}}</b>
            </div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </form>
</div>

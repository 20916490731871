import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AuthService} from '../../auth.service';
import {ManagerUserAccessPeriodCreateEditComponent} from './manager-user-access-period-create-edit/manager-user-access-period-create-edit.component';
import {ManagerUserAccessPeriodTimeDaoService} from './manager-user-access-period-time-dao.service';
import {ManagerUserAccessPeriodProfileDaoService} from './manager-user-access-period-profile-dao.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';

const routes = [
    {
        path: 'manager-user-access-period-created-edit',
        component: ManagerUserAccessPeriodCreateEditComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatCardModule,
        MatIconModule,
        MatSelectModule,
        MatMenuModule,
        FontAwesomeModule
    ],
    declarations: [ManagerUserAccessPeriodCreateEditComponent],
    providers: [ManagerUserAccessPeriodTimeDaoService, ManagerUserAccessPeriodProfileDaoService],
    entryComponents: []
})
export class ManagerUserAccessPeriodModule {
}

export function sizeOfUTF8(s: string): number {
    if (!s) {
        return 0;
    }
    return encodeURIComponent(s).match(/%..|./g).length;
}

export const BYTES = {
    // LINEFEED byte (octet 10)
    LF: '\x0A',
    // NULL byte (octet 0)
    NULL: '\x00'
};

export const trim = (str: string) => str.replace(/^\s+|\s+$/g, '');

export function marshallStompFrame(frame: StompFrame): string {
    if (frame.heartbeat || !frame.command){
        return BYTES.LF;
    }
    if (!frame.headers) {
        frame.headers = {};
    }
    const lines: string[] = [frame.command],
        skipContentLength: boolean = (!frame.headers['content-length'])
    ;
    if (skipContentLength) {
        delete frame.headers['content-length'];
    }

    Object.keys(frame.headers).forEach(name => {
        const value: any = frame.headers[name];
        lines.push(`${name}:${value}`);
    });

    if (frame.body) {
        if (!skipContentLength) {
            lines.push(`content-length:${sizeOfUTF8(frame.body)}`);
        }
        lines.push(BYTES.LF + frame.body);
    } else {
        lines.push(BYTES.LF);
    }

    return lines.join(BYTES.LF) + BYTES.NULL;
}

export function unmarshallSingleStompFrame(data: string): StompFrame {
    // search for 2 consecutives LF byte to split the command
    // and headers from the body
    const divider: number = data.search(new RegExp(BYTES.LF + BYTES.LF)),
        headerLines: string[] = data.substring(0, divider).split(BYTES.LF),
        command: string = headerLines.shift(),
        headers: any = {},
        // skip the 2 LF bytes that divides the headers from the body
        bodyIndex = divider + 2;
    let body = '';

    // Parse headers in reverse order so that for repeated headers, the 1st
    // value is used
    for (const line of headerLines.reverse()) {
        const idx: number = line.indexOf(':');
        headers[trim(line.substring(0, idx))] = trim(line.substring(idx + 1));
    }
    // Parse body
    // check for content-length or topping at the first NULL byte found.
    if (headers['content-length']) {
        const len: number = parseInt(headers['content-length'], 10);
        body = ('' + data).substring(bodyIndex, bodyIndex + len);
    } else {
        let chr: string = null;
        for (let i: number = bodyIndex; i < data.length; i++) {
            chr = data.charAt(i);
            if (chr === BYTES.NULL) {
                break;
            }
            body += chr;
        }
    }

    return {
        command: command, headers: headers, body: body, heartbeat: !command
    };
}

export interface StompFrame {
    command?: string;
    headers?: any;
    body?: string;
    heartbeat?: boolean;
}

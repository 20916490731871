<div id="ad-group-list" fxLayout="column" fxLayoutAlign="start stretch">
  <button type="button" mat-raised-button (click)="onCreate()">Criar</button>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let adGroup of adGroups">
    <div>
      {{getAdDomain(adGroup.adDomainId)?.domainName}}
    </div>
    <div>
      {{adGroup.name}}
    </div>
    <div>
      <button type="button" mat-raised-button (click)="onEdit(adGroup)">
        Editar
      </button>
    </div>
  </div>
</div>

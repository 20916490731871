<div fxLayout="row" fxLayoutAlign="center start" class="box-search">
    <div class="search">
        <input type="text" class="searchTerm" placeholder="Buscar processos" [formControl]="searchMultiFilterCtrl" matInput autocomplete="off">
        <button type="submit" class="searchButton">
            <mat-icon class="bt-icon">search</mat-icon>
        </button>
    </div>
</div>
<div fxLayoutAlign="end start">
    <div>Total: <b>{{processDataSource.data.length}}</b></div>
</div>
<table mat-table matSort [dataSource]="processDataSource" matSortActive="memory" matSortDirection="asc"
       class="width-100-percent mt-25">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-100"> Nome</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="processId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-80"> PID</th>
        <td mat-cell *matCellDef="let element" class="pl-10"> {{element.processId}} </td>
    </ng-container>

    <ng-container matColumnDef="firstSeenAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apareceu em / Atualizado</th>
        <td mat-cell *matCellDef="let element">
            <div class="mb-5"><small>{{element.firstSeenAt | date: 'dd/MM/yyyy HH:mm' }}</small></div>
            <div><small>{{element.updatedAt | date: 'dd/MM/yyyy HH:mm' }}</small></div>
        </td>
    </ng-container>

    <ng-container matColumnDef="processorUsage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CPU</th>
        <td mat-cell *matCellDef="let element"> {{element.processorUsage}}%</td>
    </ng-container>

    <ng-container matColumnDef="memoryUsage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Memory</th>
        <td mat-cell *matCellDef="let element"> {{element.memoryUsage | bytes : 0 : 'B': 'MB' }} </td>
    </ng-container>

    <ng-container matColumnDef="path" style="width: 400px">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          <span *ngIf="serverType === ServerType.LINUX">Comando</span>
          <span *ngIf="serverType === ServerType.WINDOWS">Caminho</span>
        </th>
        <td mat-cell *matCellDef="let element"><small>{{element.path}} </small> </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 100px;"></th>
        <td mat-cell *matCellDef="let element">
            <button type="button" color="warn" mat-raised-button (click)="onKillProcess(element)">Kill</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="processDetailsTable"></tr>
    <tr mat-row *matRowDef="let row; columns: processDetailsTable;"></tr>
</table>
import {Injectable} from '@angular/core';
import {ChartDataService} from '../../chart-data.service';
import {VpnUsageDaoService} from './vpn-usage-dao.service';
import {MonitoringStompService} from '../../../websocket-stomp/monitoring-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class VpnUsageService extends ChartDataService<VpnUsageDaoService> {
    constructor(
        webStompService: MonitoringStompService,
        service: VpnUsageDaoService,
    ) {
        super(
            'VpnUsage',
            service,
            webStompService
        );
    }
}

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {MarketplaceCategoryService} from './marketplace-category.service';
import {MarketplaceCategory, MarketplaceProduct} from '../../../models';

@Component({
    selector: 'app-marketplace-category-create-and-edit',
    templateUrl: './marketplace-category-create-and-edit.component.html',
    styleUrls: ['./marketplace-category-create-and-edit.component.scss']
})
export class MarketplaceCategoryCreateAndEditComponent {
    myForm: FormGroup;
    marketplaceCategory: MarketplaceCategory;

    constructor(public dialogRef: MatDialogRef<MarketplaceCategoryCreateAndEditComponent>,
                private fb: FormBuilder,
                private marketplaceCategoryService: MarketplaceCategoryService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.marketplaceCategory = data?.marketplaceCategory;
        if (!this.marketplaceCategory) {
            this.marketplaceCategory = this.marketplaceCategoryService.initMarketplaceCategory();
        }
        this.myForm = this.fb.group(convertToFormGroup(this.marketplaceCategory));
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onSubmit(): void {
        const marketplaceCategory: MarketplaceProduct = this.myForm.value;
        let operation: CrudOperation = 'CREATE';
        if (marketplaceCategory.id) {
            operation = 'SAVE';
        }
        const crudOperation: CrudOperationWrapper = {
            data: marketplaceCategory,
            operation: operation
        };
        this.dialogRef.close(crudOperation);
    }
}

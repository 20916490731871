import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {RegisterComponent} from './register.component';
import {RegisterCustomerGroupComponent} from './register-customer-group/register-customer-group.component';
import {RegisterCustomerComponent} from './register-customer/register-customer.component';
import {RegisterManagerUserComponent} from './register-manager-user/register-manager-user.component';
import {RoleCustomerGroupOrManagerUserEditComponent} from './register-manager-user/role-customer-group-or-manager-user-edit/role-customer-group-or-manager-user-edit.component';
import {RegisterManagerUserAccessPeriodComponent} from './register-manager-user-access-period/register-manager-user-access-period.component';
import {ManagerUserAccessPeriodService} from '../manager-user-access-period/manager-user-access-period.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {ManagerUserChangePasswordComponent} from './register-manager-user/manager-user-change-password/manager-user-change-password.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

const routes = [
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AuthService],
        data: {
            permissions: ['Customer.AUDIT'],
            preCondition: true
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        KluhSharedModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSelectModule,
        MatGridListModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule
    ],
    providers: [
        ManagerUserAccessPeriodService
    ],
    entryComponents: [RoleCustomerGroupOrManagerUserEditComponent],
    declarations: [RegisterComponent, RegisterCustomerGroupComponent, RegisterCustomerComponent,
        RegisterManagerUserComponent, RoleCustomerGroupOrManagerUserEditComponent,
        RegisterManagerUserAccessPeriodComponent,
        ManagerUserChangePasswordComponent],

})
export class RegisterModule {
}

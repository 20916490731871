<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Tema do portal</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent style="padding-left: 0;padding-right: 0;">
        <div fxLayout="column" fxLayoutAlign="none stretch">
            <table>
                <tr>
                    <td class="vertical-align-top w-150 pl-20 pt-20">
                        <div *ngIf="customPortalTemplate.logoId || (imageFileElement)">
                            <span *ngIf="imageFileElement">
                                 <img [src]="'data:image/jpg;base64,' + imageFileElement"
                                      alt="Logo"
                                      width="150"
                                      height="150"
                                      *ngIf="imageFileElement">
                            </span>
                                        <span *ngIf="customPortalTemplate.logoId && !imageFileElement">
                                <img
                                    src="{{imageFileService.getPictureUrlFromImageFileTemplateId(customPortalTemplate?.logoId, 150)}}"
                                    alt="Logo"
                                    width="150"
                                    height="150">
                            </span>
                        </div>
                        <div class="full-width">
                            <button style="width: 150px;margin-top: 5px;" type="button" mat-raised-button
                                    class="green-800-bg green-800"
                                    (click)="fileInput.click()">
                                    <span class="ml-5">Adicionar logo</span>
                            </button>
                            <input #fileInput type="file" (change)="onImageFileChange($event)" style="display:none;"
                                   accept="image/*"/>
                        </div>
                    </td>
                    <td class="vertical-align-top pl-15 w-680 pt-20" style="padding-right: 15px;">

                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Nome do tema</mat-label>
                            <input matInput formControlName="name">
                            <mat-error>
                                {{myForm.get('name').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Titúlo da página</mat-label>
                            <input matInput formControlName="title">
                            <mat-error>
                                {{myForm.get('title').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Link de ajuda</mat-label>
                            <input matInput formControlName="helpLink">
                            <mat-error>
                                {{myForm.get('helpLink').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <div class="full-width mt-10 mb-10 p-15 border">
                            <mat-slide-toggle formControlName="hasCustomProtocol">
                                (beta) Habilitar abertura de programa ao logar
                            </mat-slide-toggle>
                            <div *ngIf="myForm.get('hasCustomProtocol')?.value" class="full-width mt-10">
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>Nome ou descrição do software</mat-label>
                                    <input matInput formControlName="customProtocolSoftwareName">
                                    <mat-error>
                                        {{myForm.get('customProtocolSoftwareName').errors | error}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>Protocolo customizado de abertura do software</mat-label>
                                    <input matInput formControlName="customProtocol">
                                    <mat-error>
                                        {{myForm.get('customProtocol').errors | error}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>Link do instalador</mat-label>
                                    <input matInput formControlName="installUrl">
                                    <mat-error>
                                        {{myForm.get('installUrl').errors | error}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <mat-form-field class="full-width min-width-230">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>CSS customizado do portal</mat-label>
                                <textarea rows="15" matInput  formControlName="css" class="full-width" ></textarea>
                            </mat-form-field>
                        </mat-form-field>

                        <div *ngIf="!customPortalTemplate?.id">
                            <div class="full-width min-width-230 mt-50 mb-60" *appIsAdmin>
                                <mat-slide-toggle formControlName="public">
                                    Público (Tema público, todos podem usar e copiar)
                                </mat-slide-toggle>
                            </div>
                        </div>


                    </td>
                </tr>
            </table>



        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <div matTooltip="Salvar">

            <button type="submit" color="accent" mat-raised-button class="mat-green-600-bg" [disabled]="(!myForm.valid || myForm.pristine)">
        <span class="mat-button-wrapper">
            Salvar
        </span>
            </button>
        </div>
        <div *ngIf="!customPortalTemplate?.public || isAdmin">
            <button type="button" mat-raised-button color="warn" (click)="onDelete(customPortalTemplate)">Excluir</button>
        </div>
        <div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</form>

import {Component} from '@angular/core';
import {DesktopServerDaoService} from '../desktop-server-dao.service';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {fuseAnimations} from '../../../../../@fuse/animations';

import {DesktopServerCreateComponent} from '../desktop-server-create/desktop-server-create.component';
import {filter} from 'rxjs/operators';
import {ADDomain, DesktopServer, ServerConnected, ServerDisconnected} from '../../../models';
import {USER_TOPIC} from '../../../../../main';
import {MatDialog} from '@angular/material/dialog';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';

@Component({
    selector: 'app-desktop-server-list',
    templateUrl: './desktop-server-list.component.html',
    styleUrls: ['./desktop-server-list.component.scss'],
    animations: fuseAnimations
})
export class DesktopServerListComponent extends ComponentCleaner {
    public desktopServers: DesktopServer[];
    public adDomains: ADDomain[];
    public desktopServerStatusList: ServerConnected[] = [];

    constructor(private dialog: MatDialog,
                private dao: DesktopServerDaoService,
                private adDomainDao: AdDomainDaoService,
                private stomp: R2CloudStompService) {
        super();
        this.dao.get().subscribe((desktopServers) => {
            if (desktopServers) {
                this.desktopServers = desktopServers;
            } else {
                this.desktopServers = [];
            }
        });
        this.adDomainDao.get().subscribe((adDomains) => {
            this.adDomains = adDomains;
        });
        const subscription1 = this.stomp.stompTopic<ServerConnected[]>(`${USER_TOPIC}/desktop-server-connected`).subscribe((serverConnectedList) => {
            for (const serverConnected of serverConnectedList) {
                const index = this.desktopServerStatusList.findIndex((item) => {
                    return serverConnected.desktopServerId === item.desktopServerId;
                });
                if (index === -1) {
                    this.desktopServerStatusList.push(serverConnected);
                }
            }
        });
        this.addSubscription(subscription1);
        const subscription2 = this.stomp.stompTopic<ServerDisconnected>(`${USER_TOPIC}/desktop-server-disconnected`).subscribe((serverDisconnected) => {
            const index = this.desktopServerStatusList.findIndex((serverConnected) => {
                return serverConnected.desktopServerId === serverDisconnected.desktopServerId;
            });
            if (index > -1) {
                this.desktopServerStatusList.splice(index, 1);
            }
        });
        this.addSubscription(subscription2);
    }

    onDesktopServerCreate(): void {
        const dialogRef = this.dialog.open(DesktopServerCreateComponent, {
            disableClose: true,
            panelClass: 'desktop-server-edit-dialog',
            data: {
                adDomains: this.adDomains,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((desktopServer) => {
            this.desktopServers.push(desktopServer);
        });
        this.addSubscription(subscription);
    }

    getAdDomain(adDomainId: number): ADDomain {
        if (this.adDomains) {
            return this.adDomains.find((adDomain) => {
                return adDomain.id === adDomainId;
            });
        }
        return null;
    }

    getDesktopServerAgentStatus(desktopServerId: number): boolean {
        return !!(this.desktopServerStatusList.find((serverConnected) => {
            return serverConnected.desktopServerId === desktopServerId;
        }));
    }
}

<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column" style="max-width: 810px;">
            <div class="row">
                <mat-card style="width: 100%;">
                    <mat-card-header>
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                                        style="margin-top: 2px;">
                            <div> <b class="accent-A700-fg">{{customerGroup.name}}</b> | Papel dos funcionários </div>
                            <div>
                                <button *appIfPermission="'WRITE'; data: customerGroup; type: 'CustomerGroup'" type="button"
                                        mat-raised-button color="accent" (click)="openManagerUserCustomerGroupCreateModal()">Criar
                                </button>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column mt-4">
                            <div class="content-tab">
                                <table class="remote-app-table" border="0" cellspacing="0">
                                    <thead>
                                    <tr class="bg-white">
                                        <th class="min-width-300" >E-mail</th>
                                        <th class="min-width-300" >Papel</th>
                                        <th class="text-align-center p-0" style="width: 90px;"></th>
                                    </tr>
                                    </thead>
                                    <tr *ngFor="let roleCustomerGroupManagerUser of adminService.roleCustomerGroupManagerUsers | sort: 'managerUserId'">
                                        <td>
                                            {{adminService.getManagerUserCustomerGroup(roleCustomerGroupManagerUser.managerUserId)?.email}}

                                        </td>
                                        <td>
                                            {{adminService.getRole(roleCustomerGroupManagerUser.roleId)?.name}}
                                        </td>
                                        <td class="text-align-center p-0 ">
                                            <button *appIfPermission="'WRITE'; data: customerGroup; type: 'CustomerGroup'"
                                                    type="button"
                                                    mat-raised-button class="mat-green-600-bg" (click)="openManagerUserCustomerEditModal(roleCustomerGroupManagerUser)"> Edit </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>


                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Session} from '../../ts/session';

@Component({
    selector: 'app-session-grouped',
    templateUrl: './session-grouped.component.html',
    styleUrls: ['../session-view.component.scss']
})
export class SessionGroupedComponent {
    private _sessionGroup: SessionGroup;
    private _activeSession: string;

    constructor() {
    }

    @Input()
    set sessionGroup(sessionGroup: SessionGroup){
        this._sessionGroup = sessionGroup;

    }

    get sessionGroup(): SessionGroup {
        return this._sessionGroup;
    }

    @Input()
    set activeSession(activeSession: string){
        this._activeSession = activeSession;
    }

    get activeSession(): string {
        return this._activeSession;
    }

    @Output()
    sessionClick = new EventEmitter<Session>();

    isSessionActive(): boolean {
        if (this._sessionGroup && this._sessionGroup.sessionList){
            for (const session of this._sessionGroup.sessionList) {
                if (session.id && session.id === this._activeSession){
                    return true;
                }
            }
        }
        return false;
    }

    onSessionClick(session: Session): void {
        this.sessionClick.emit(session);
    }
}


export interface SessionGroup {
    sessionList: Session[];
    thumbnail: string;
}

<div class="dialog-content-wrapper">
    <div fxLayout="column" id="changePassword" fxLayoutAlign="space-between stretch">
        <div>
            <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
                <mat-icon class="icon">lock_outline</mat-icon>
            </mat-toolbar>
        </div>
        <form (submit)="myForm.dirty && myForm.valid && onSubmit()"
              id="change-password-form"
              [formGroup]="myForm">
            <div class="p-16 message" fxLayout="column" fxLayoutAlign="space-between center">
                <mat-form-field>
                    <input type="password" matInput placeholder="Nova senha"
                           id="change-password-new-password"
                           formControlName="newPassword"
                           name="newPassword">
                    <mat-error *ngIf="myForm.get('newPassword').errors">
                        Comprimento mínimo: 6
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input type="password" matInput placeholder="Repita a nova senha"
                           id="change-password-re-new-password"
                           formControlName="reNewPassword"
                           name="reNewPassword">
                    <mat-error *ngIf="myForm.get('reNewPassword').errors">
                        Comprimento mínimo: 6
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="myForm.hasError('notSame')">
                    Sua nova senha não está igual ao campo repita.
                </mat-error>
                <mat-checkbox class="mt-24" formControlName="forceChangePasswordOnFirstLogin">
                    Trocar a senha no próximo login
                </mat-checkbox>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top">
                <div>
                    <button type="submit" mat-raised-button
                            id="change-password-save"
                            color="accent" [disabled]="!myForm.valid || myForm.pristine"
                            aria-label="Salvar">Salvar
                    </button>
                </div>
                <div>
                    <button type="button" mat-raised-button color="primary"
                            id="change-password-cancel"
                            (click)="onCancel()">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
</div>

import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {PointOptionsType} from 'highcharts';
import {Subject} from 'rxjs/internal/Subject';
import {MonitoringDetailsService} from '../monitoring-details.service';
import {MetricType} from '../../../../models-monitoring';
import {ChartPoint} from '../../../../models';
import {MemoryUsageService} from './memory-usage.service';

@Component({
    selector: 'app-memory-usage',
    templateUrl: './memory-usage.component.html',
    styleUrls: ['./memory-usage.component.scss']
})
export class MemoryUsageComponent implements OnChanges, OnDestroy {

    @Input()
    serverId: number;

    @Input()
    metricType: MetricType;

    @Input()
    begin: Date;

    @Input()
    end: Date;

    @Input()
    resolution: number;

    dataSubject = new Subject<PointOptionsType[]>();

    chartPointList: ChartPoint[] = [];

    constructor(private service: MemoryUsageService,
                private monitoringDetailsService: MonitoringDetailsService) {
        this.monitoringDetailsService.changePath('monitoring-details', 'memory');
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.chartPointList = [];
        this.service.loadFromDbAndWebSocket(this.begin, this.end, this.metricType, this.serverId, this.serverId, this.resolution, this.chartPointList, this.dataSubject);
    }

    ngOnDestroy(): void {
        this.chartPointList = [];
        this.dataSubject = new Subject<PointOptionsType[]>();
        this.service.unSubscribe();
    }
}

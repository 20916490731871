<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Escolha do tipo de usuário serviço de acesso.</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-20">
        <div class="mt-30 mb-30">
            <button type="button" mat-raised-button color="accent" (click)="adUserServiceService.openServiceUserModal(null, null)">Usuário serviço individual</button>
        </div>
        <div class="mt-30 mb-30" *appIfPermission="appCanCreateADUserPoolRelationship">
            <button type="button" mat-raised-button color="accent" (click)="adUserServiceService.openUserPoolCreateModal(null, null)">Usuário Pool de serviço</button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
        <div>

        </div>
        <div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</div>

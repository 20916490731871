import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched, Province} from '../../../helpers/kluh';
import {ImageFile, SoftwareCompany} from '../../../models';
import {SoftwareCompanyService} from './software-company.service';
import {SoftwareCompanyDaoService} from './software-company.dao.service';
import {Observable} from 'rxjs/internal/Observable';
import {ProvinceService} from '../../../helpers/province.service';
import {of} from 'rxjs/internal/observable/of';
import {ImageFileService} from '../../image-file/image-file-service';
import {ImageFileDaoService} from '../../image-file/image-file-dao-service';
import {switchMap} from 'rxjs/operators';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

@Component({
    selector: 'app-software-company',
    templateUrl: './software-company-create-and-edit.component.html',
    styleUrls: ['./software-company-create-and-edit.component.scss']
})
export class SoftwareCompanyCreateAndEditComponent implements OnInit {

    myForm: FormGroup;
    softwareCompany: SoftwareCompany;

    imageFile: ImageFile;
    imageFileElement: any = null;
    errorMessage: string = null;
    provinceList: Province[];

    constructor(public dialogRef: MatDialogRef<SoftwareCompanyCreateAndEditComponent>,
                private fb: FormBuilder,
                private softwareCompanyService: SoftwareCompanyService,
                private softwareCompanyDaoService: SoftwareCompanyDaoService,
                public imageFileService: ImageFileService,
                private provinceService: ProvinceService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.softwareCompany = data?.softwareCompany;
        if (!this.softwareCompany) {
            this.softwareCompany = this.softwareCompanyService.initSoftwareCompany();
        }
        this.provinceList = this.provinceService.provinceList;

        this.myForm = this.fb.group(convertToFormGroup(this.softwareCompany));
    }

    ngOnInit(): void {
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.myForm);
        }
    }

    onSubmit(): void {
        const softwareCompany: SoftwareCompany = this.myForm.value;
        let softwareCompany$: Observable<SoftwareCompany>;
        let operation: CrudOperation;
        if (softwareCompany.id) {
            operation = 'SAVE';
            softwareCompany$ = this.softwareCompanyDaoService.save(softwareCompany);
        } else {
            operation = 'CREATE';
            softwareCompany$ = this.softwareCompanyDaoService.create(softwareCompany);
        }
        softwareCompany$.pipe(switchMap((softwareCompanyFromDAO) => {
            let imageFile$: Observable<any>;
            const image = this.imageFileElement;
            if (image) {
                imageFile$ = this.imageFileService.saveSoftwareCompanyPicture(softwareCompanyFromDAO.id, image);
            } else {
                imageFile$ = of(null);
            }
            return forkJoin([imageFile$, of(softwareCompanyFromDAO)]);
        })).subscribe(result => {
            const softwareCompanyFromDAO = result[1];
            if (softwareCompanyFromDAO) {
                const crudOperation: CrudOperationWrapper = {
                    data: softwareCompanyFromDAO,
                    operation: operation
                };
                this.dialogRef.close(crudOperation);
            }
        });
    }
}

import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../logger.service';

export class PrintHandler {
    private g = 'Remote Print Job.pdf';

    constructor(protected logger: LoggerService, protected http: HttpClient) {
    }

    onPrintStart(): void {
        setTimeout(() => {
            // e.$broadcast("rdPrinting:printStart")
        });
    }

    onPrintUrl(d: any): void {
        setTimeout(() => {
            if (this.logger.log('Saving printed document as a file'), window.navigator.msSaveBlob) {
                this.http.get(d, {
                    responseType: 'blob'
                }).subscribe((a: any) => {
                    window.navigator.msSaveBlob(a.data, this.g);
                }, (a: any) => {
                    this.logger.error('Extracting the PDF print job failed unexpectedly, details=' + JSON.stringify(a));
                });
            } else {
                const c: HTMLAnchorElement = window.document.getElementById('printDownloadLink') as HTMLAnchorElement;
                c ? (c.href = d, c.click()) : this.logger.error('The link for downloading printed documents could not be found.');
            }
        });
    }
}

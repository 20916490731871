import {Component, Inject, OnInit} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ManagerUserAccessPeriodProfile, ManagerUserAccessPeriodTime} from '../../../models';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ManagerUserAccessPeriodProfileDaoService} from '../manager-user-access-period-profile-dao.service';
import {ManagerUserAccessPeriodTimeDaoService} from '../manager-user-access-period-time-dao.service';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, formatFormControlWithLeadingZero, markAsTouched} from '../../../helpers/kluh';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-manager-user-access-period-create-edit',
    templateUrl: './manager-user-access-period-create-edit.component.html',
    styleUrls: ['./manager-user-access-period-create-edit.component.scss']
})
export class ManagerUserAccessPeriodCreateEditComponent extends ComponentCleaner implements OnInit {

    myForm: FormGroup;
    managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile;
    managerUserAccessPeriodTimes: ManagerUserAccessPeriodTime[];


    constructor(public dialogRef: MatDialogRef<ManagerUserAccessPeriodCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private managerUserAccessPeriodProfileDaoService: ManagerUserAccessPeriodProfileDaoService,
                private managerUserAccessPeriodTimeDaoService: ManagerUserAccessPeriodTimeDaoService,
                private customerGroupService: CustomerGroupService,
                public adminService: R2CloudAdminService) {
        super();

        this.managerUserAccessPeriodProfile = data.managerUserAccessPeriodProfile;
        if (!this.managerUserAccessPeriodProfile) {
            this.managerUserAccessPeriodProfile = {
                id: null,
                active: true,
                comment: null,
                customerGroupId: customerGroupService.customerGroup.id,
                name: null,
                modified: null,
                optlock: null
            };
        }
        this.managerUserAccessPeriodTimes = data.managerUserAccessPeriodTimes;
        if (!this.managerUserAccessPeriodTimes) {
            this.managerUserAccessPeriodTimes = [];
            for (let i = 1; i < 8; i++) {
                const managerUserAccessPeriodTime: ManagerUserAccessPeriodTime = {
                    active: true,
                    comment: null,
                    id: null,
                    dayOfWeek: i,
                    managerUserAccessPeriodProfileId: 8888888,
                    startHours: 6,
                    startMinutes: 10,
                    stopHours: 6,
                    stopMinutes: 11,
                    modified: null,
                    optlock: null
                };
                this.managerUserAccessPeriodTimes.push(managerUserAccessPeriodTime);
            }
        }
        this.managerUserAccessPeriodProfile.customerGroupId = this.customerGroupService.customerGroup.id;


        this.myForm = this.fb.group({
            managerUserAccessPeriodProfileForm: this.fb.group(convertToFormGroup(this.managerUserAccessPeriodProfile)),
            managerUserAccessPeriodTimesForm: this.fb.array([]),
            listBlockDay: this.fb.array([]),
        });


        const list: FormArray = this.myForm.get('managerUserAccessPeriodTimesForm') as FormArray;
        const listBlockDay: FormArray = this.myForm.get('listBlockDay') as FormArray;
        this.managerUserAccessPeriodTimes.forEach((managerUserAccessPeriodTime) => {
            const formGroup = this.fb.group(convertToFormGroup(managerUserAccessPeriodTime));
            list.push(formGroup);

            const formGroupBlockDay = this.fb.group(convertToFormGroup(false));
            listBlockDay.push(formGroupBlockDay);


        });


        (this.myForm.get('managerUserAccessPeriodTimesForm') as FormArray).controls.forEach((formGroup: FormGroup) => {
            const fields: string[] = ['startHours', 'startMinutes', 'stopHours', 'stopMinutes'];
            fields.forEach((field) => {
                const control = formGroup.controls[field];
                if (field.indexOf('Hours') !== -1) {
                    control.setValidators([Validators.min(0), Validators.max(23)]);
                }
                if (field.indexOf('Minutes') !== -1) {
                    control.setValidators([Validators.min(0), Validators.max(59)]);
                }
                formatFormControlWithLeadingZero(control.value, control);
                const subscription = control.valueChanges.pipe(
                    debounceTime(500),
                    distinctUntilChanged(),
                    filter(x => !isNaN(x))
                ).subscribe((val) => {
                    formatFormControlWithLeadingZero(val, control);
                });


                this.addSubscription(subscription);

            });
        });

    }

    ngOnInit(): void {
        setTimeout(() => {
            markAsTouched(this.myForm);
        }, 1000);
    }

    onSubmit(): void {

        const managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile = this.myForm.get('managerUserAccessPeriodProfileForm').value;

        let managerUserAccessPeriodProfile$: Observable<ManagerUserAccessPeriodProfile>;
        let operation: CrudOperation;

        if (managerUserAccessPeriodProfile.id) {
            managerUserAccessPeriodProfile$ = this.managerUserAccessPeriodProfileDaoService.save(managerUserAccessPeriodProfile);
            operation = 'SAVE';
        } else {
            managerUserAccessPeriodProfile$ = this.managerUserAccessPeriodProfileDaoService.create(managerUserAccessPeriodProfile);
            operation = 'CREATE';
        }

        managerUserAccessPeriodProfile$.subscribe((result) => {
            const managerUserAccessPeriodTimes: ManagerUserAccessPeriodTime[] = this.myForm.get('managerUserAccessPeriodTimesForm').value;
            managerUserAccessPeriodTimes.forEach(managerUserAccessPeriodTime => {
                managerUserAccessPeriodTime.managerUserAccessPeriodProfileId = result.id;
            });

            let managerUserAccessPeriodTimes$: Observable<ManagerUserAccessPeriodTime[]>;

            if (managerUserAccessPeriodProfile.id) {
                managerUserAccessPeriodTimes$ = this.managerUserAccessPeriodTimeDaoService.saveAll(managerUserAccessPeriodTimes);
            } else {
                managerUserAccessPeriodTimes$ = this.managerUserAccessPeriodTimeDaoService.createAll(managerUserAccessPeriodTimes);
            }

            managerUserAccessPeriodTimes$.subscribe(result2 => {
                const crudOperation: CrudOperationWrapper[] = [{
                    data: result,
                    operation: operation
                },
                    {
                        data: result2,
                        operation: operation
                    }];
                this.dialogRef.close(crudOperation);
            });
        });


    }

    onRemove(managerUserAccessPeriodProfile: ManagerUserAccessPeriodProfile): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar o perfil de horáro?<br>' + managerUserAccessPeriodProfile.name,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.managerUserAccessPeriodProfileDaoService.remove(managerUserAccessPeriodProfile.id).subscribe(() => {
                    const operation: CrudOperation = 'DELETE';
                    const crudOperation: CrudOperationWrapper[] = [{
                        data: managerUserAccessPeriodProfile,
                        operation: operation
                    }];
                    this.dialogRef.close(crudOperation);
                });
            }
        });
        this.addSubscription(subscription);


    }

    onCancel(): void {
        this.dialogRef.close();
    }


    // noinspection JSMethodCanBeStatic
    getDayOfWeek(dayOfWeek: number): string {
        switch (dayOfWeek) {
            case 1:
                return 'Domingo';
            case 2:
                return 'Segunda';
            case 3:
                return 'Terça';
            case 4:
                return 'Quarta';
            case 5:
                return 'Quinta';
            case 6:
                return 'Sexta';
            case 7:
                return 'Sábado';
        }
        throw new Error('Invalid Day of Week');
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.managerUserAccessPeriodProfile.id, this.managerUserAccessPeriodProfileDaoService, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.managerUserAccessPeriodProfile.id, this.managerUserAccessPeriodProfileDaoService, this.dialog);
    }


}

<div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">

    <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="!searchLoading">search</mat-icon>
        <mat-spinner *ngIf="searchLoading" [diameter]="24" color="primary"></mat-spinner>
        <input placeholder="busca" matInput (focus)="onFocusSearch()"
               id="search-search"
               [formControl]="search"
               (focusout)="onFocusOutSearch()" #searchInput fxFlex>
    </div>

</div>
<ul class="suggestions" *ngIf="showSuggestions">

    <li *ngFor="let subProject of subProjects; let i = index"
        [ngClass]="[(i+1) === selectedItem ? 'active' : '']" (mousedown)="openSubProject(subProject.id)">
        <mat-icon class="ballot">ballot</mat-icon>
        <div class="info">
            <div class="sugg-title">{{subProject.name}}</div>
            <span class="desc">
        <span class="text-uppercase mr-20">projeto</span>
        <span class="customer-name">{{getCustomerGroup(subProject.customerGroupId)?.name}}</span>
        </span>
        </div>
        <code class="type" [hidden]="true">subProject</code>
        <code class="id" [hidden]="true">{{subProject.id}}</code>
    </li>


    <li *ngFor="let remoteApp of remoteApps; let i = index"
        [ngClass]="[(i+1+subProjects.length) === selectedItem ? ' active' : '']"
        (mousedown)="openRemoteApp(remoteApp.id)">
        <img [src]="imageFileService.getRemoteAppPictureUrl(remoteApp.imageUUID, 64)" width="64"
             height="64">
        <div class="info">
            <div class="sugg-title">{{remoteApp.name}} - {{getSubProject(remoteApp.subProjectId)?.name}}</div>
            <span class="desc">
                <span class="text-uppercase mr-20">Aplicativo</span>
                <span class="customer-name">{{getDesktopServer(remoteApp.desktopServerId).desktopServerDTO.hostname}} | {{getSubProject(remoteApp.subProjectId)?.name}} | {{getCustomerGroup(getSubProject(remoteApp.subProjectId)?.customerGroupId)?.name}}</span>
            </span>
        </div>

        <code class="type" [hidden]="true">remoteApp</code>
        <code class="id" [hidden]="true">{{remoteApp.id}}</code>

    </li>


    <li *ngFor="let desktopServer of desktopServers; let i = index"
        [ngClass]="[(i+1+remoteApps.length+subProjects.length) === selectedItem ? ' active' : '']"
        (mousedown)="openDesktopServer(desktopServer.id)">
        <div *appIfPermission="userHasPermissionToSearchDesktopServer">
            <fa-icon class="s-16 mr-8 windows-icon" [icon]="faWindows"></fa-icon>
            <div class="info">
                <div class="sugg-title">{{desktopServer.hostname}}</div>
                <span class="desc">
                    <span class="text-uppercase mr-20">servidor</span>
                    <span class="customer-name">{{getSubProject(getDesktopServer(desktopServer.id)?.subProjectIDs[0])?.name}} | {{getCustomerGroup(getSubProject(getDesktopServer(desktopServer.id)?.subProjectIDs[0])?.customerGroupId)?.name}}</span>
                </span>
            </div>

            <code class="type" [hidden]="true">desktopServer</code>
            <code class="id" [hidden]="true">{{desktopServer.id}}</code>
        </div>
    </li>


    <li *ngFor="let linuxServer of linuxServers; let i = index"
        [ngClass]="[(i+1+remoteApps.length+subProjects.length+desktopServers.length) === selectedItem ? 'active' : '']"
        (mousedown)="openLinuxServer(linuxServer.id)">
        <fa-icon class="s-16 mr-8 linux-icon" [icon]="faLinux"></fa-icon>
        <div class="info">
            <div class="sugg-title">{{linuxServer.hostname}}</div>
            <span class="desc">
                    <span class="text-uppercase mr-20">servidor</span>
                    <span class="customer-name">{{getSubProject(getLinuxServer(linuxServer.id).subProjectIDs[0])?.name}} | {{getCustomerGroup(getSubProject(getLinuxServer(linuxServer.id).subProjectIDs[0])?.customerGroupId)?.name}}</span>
            </span>
        </div>
        <code class="type" [hidden]="true">linuxServer</code>
        <code class="id" [hidden]="true">{{linuxServer.id}}</code>

    </li>


    <li *ngFor="let managerUser of managerUsers; let i = index"
        id="search-manager-user-{{i}}"
        [ngClass]="[(i+1+remoteApps.length+subProjects.length+desktopServers.length) === selectedItem ? 'active' : '']"
        (mousedown)="openCustomerGroup(getCustomerGroup(getCustomer(managerUser.customerId)?.customerGroupId)?.id)">
        <fa-icon class="s-16 mr-8 linux-icon" [icon]="faUser"></fa-icon>
        <div class="info">
            <div class="sugg-title">{{managerUser?.email}}</div>
            <span class="desc">
                    <span class="text-uppercase mr-20">Usuário</span>
                    <span class="customer-name">{{managerUser?.displayName}} | {{getCustomerGroup(getCustomer(managerUser.customerId)?.customerGroupId)?.name}}</span>
            </span>
        </div>
        <code class="type" [hidden]="true">managerUser</code>
        <code class="id" [hidden]="true">{{getCustomerGroup(getCustomer(managerUser.customerId)?.customerGroupId)?.id}}</code>

    </li>



    <li *ngFor="let customer of customers; let i = index"
        [ngClass]="[(i+1+remoteApps.length+subProjects.length+desktopServers.length) === selectedItem ? 'active' : '']"
        (mousedown)="openCustomerGroup(customer.customerGroupId)">
        <fa-icon class="s-16 mr-8 linux-icon" [icon]="faBuilding"></fa-icon>
        <div class="info">
            <div class="sugg-title">{{customer.name}}</div>
            <span class="desc">
                    <span class="customer-name">{{customer.cnpj}} | {{getCustomerGroup(customer.customerGroupId).name}}</span>
            </span>
        </div>
        <code class="type" [hidden]="true">customer</code>
        <code class="id" [hidden]="true">{{customer.customerGroupId}}</code>

    </li>

</ul>
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseServer, DesktopServer, DotNetServerPluginTemplate, LinuxServer, PluginMessage, ServerStatus, ServerStatusType, ServerType} from '../../../models';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {lineChartColors, lineChartData, lineChartLabels, lineChartLegend, lineChartOptions, lineChartType, setChartValue} from './chart-config';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {resolutions} from './graph-resolution';
import {VpnUsageDaoService} from './vpn-monitoring/vpn-usage/vpn-usage-dao.service';
import {HdUsageDaoService} from './hd-monitoring/hd-usage/hd-usage-dao.service';
import {HdMonitoringConfiguration, MetricType, NetworkConfiguration, VpnMonitoringConfiguration} from '../../../models-monitoring';
import {NetworkConfigurationDaoService} from './network/network-configuration/network-configuration-dao.service';
import {AuthInfoService} from '../../auth-info/auth-info.service';
import {getDifferenceBetweenTwoDatesInMinutes} from '../../../helpers/kluh';
import {StompConnectionStatus} from '../../../ws/stomp-connection-status.enum';
import {DotNetServerPluginService} from '../../server-plugin/services/server-plugin.service';
import {DesktopServerStatusService} from '../../../ws/desktop-server-status.service';
import {Subscription} from 'rxjs';
import {TOPIC} from '../../../../../main';
import {PluginMessageDaoService} from '../../plugin-message/plugin-message-dao.service';
import {VpnMonitoringConfigurationDaoService} from './vpn-monitoring/vpn-monitoring-configuration/vpn-monitoring-configuration-dao.service';
import {TerminalService} from '../../terminal/terminal.service';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';
import {MonitoringStompService} from '../websocket-stomp/monitoring-stomp.service';
import {filter, map, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-monitoring-details',
    templateUrl: './monitoring-details.component.html',
    styleUrls: ['./monitoring-details.component.scss']
})
export class MonitoringDetailsComponent implements OnInit, OnDestroy {
    resolutions = resolutions;
    begin: Date = new Date(Date.now() - 15 * 60000);
    end: Date = new Date(Date.now() + 5 * 60000);
    rangeInMinSelected = 15;
    StompConnectionStatus = StompConnectionStatus;
    ServerStatusType = ServerStatusType;
    connectionStatus: StompConnectionStatus = StompConnectionStatus.DISCONNECTED;
    agentServerConnectionStatus: ServerStatusType = ServerStatusType.DISCONNECTED;
    agentServeVersion = '';
    faCircle = faCircle;
    hasMonitoringPlugin = false;
    loading = true;
    rangeMinutes = 15;

    form: FormGroup;
    tabTextLabel = 'PROCESSOR_USAGE';

    // Grafico
    lineChartData = lineChartData;
    lineChartLabels = lineChartLabels;
    lineChartLegend = lineChartLegend;
    lineChartType = lineChartType;
    lineChartOptions = lineChartOptions;
    lineChartColors = lineChartColors;

    desktopServer: DesktopServer = null;
    linuxServer: LinuxServer = null;

    MetricType = MetricType;
    metricType: MetricType;
    serverType: ServerType;
    baseServer: BaseServer;
    ServerType = ServerType;

    vpnList: VpnMonitoringConfiguration[] = [];
    hdList: HdMonitoringConfiguration[] = [];
    networks: NetworkConfiguration[] = [];
    selectedIndex = null;

    private subscriptions: Subscription[] = [];


    constructor(private desktopServerDao: DesktopServerDaoService,
                private stomp: R2CloudStompService,
                private linuxServerDao: LinuxServerDaoService,
                private dialog: MatDialog,
                private terminalService: TerminalService,
                private vpnUsageDaoService: VpnUsageDaoService,
                private vpnMonitoringConfigurationDaoService: VpnMonitoringConfigurationDaoService,
                private networkConfigurationDaoService: NetworkConfigurationDaoService,
                private hdUsageDaoService: HdUsageDaoService,
                private router: Router,
                public serverPluginService: DotNetServerPluginService,
                private authInfoService: AuthInfoService,
                private desktopServerStatusService: DesktopServerStatusService,
                private webStompService: MonitoringStompService,
                private pluginMessageDaoService: PluginMessageDaoService,
                private route: ActivatedRoute) {
        this.checkConnectionStatus();
        this.subscriptions.push(this.route.queryParamMap.pipe(switchMap(queryParamMap => {
            if (queryParamMap && queryParamMap.has('desktopServerId')) {
                return this.desktopServerDao.getOne(+queryParamMap.get('desktopServerId')).pipe(switchMap(desktopServerFromDao => {
                    return this.serverPluginService.serverPluginTemplate$.pipe(
                        filter((plugins) => this.checkMonitoringPlugin(plugins, desktopServerFromDao)),
                        map(() => ({
                            desktopServer: desktopServerFromDao,
                            linuxServer: null,
                            server: desktopServerFromDao,
                            metricType: MetricType.WINDOWS_SERVER,
                            serverType: ServerType.WINDOWS
                        })));
                }));
            } else if (queryParamMap && queryParamMap.has('linuxServerId')) {
                return this.linuxServerDao.getOne(+queryParamMap.get('linuxServerId')).pipe(
                    map((linuxServerFromDao) => ({
                        desktopServer: null,
                        linuxServer: linuxServerFromDao,
                        server: linuxServerFromDao,
                        metricType: MetricType.LINUX_SERVER,
                        serverType: ServerType.LINUX
                    }))
                );
            } else {
                throw Error('queryParamMap has unknown param');
            }
        })).subscribe((mapped) => {
            this.hasMonitoringPlugin = !!mapped.desktopServer;
            this.metricType = mapped.metricType;
            this.linuxServer = mapped.linuxServer;
            this.desktopServer = mapped.desktopServer;
            this.baseServer = mapped.server;
            this.serverType = mapped.serverType;
            this.loadPathsAndData();
        }));
    }

    private loadPathsAndData(): void {
        this.loading = false;
        this.stompConnect();
        this.loadVPNs();
        this.loadServerHds();
        this.loadDesktopServerNetworkConfiguration();
        if (this.router.url.indexOf('/processor') > -1) {
            this.selectedIndex = 0;
            this.sendWhichModuleIsOpen('PROCESSOR_USAGE');
        } else if (this.router.url.indexOf('/memory') > -1) {
            this.selectedIndex = 1;
            this.sendWhichModuleIsOpen('MEMORY_USAGE');
        } else if (this.router.url.indexOf('/hd') > -1) {
            this.selectedIndex = 2;
            this.sendWhichModuleIsOpen('HD_USAGE');
        } else if (this.router.url.indexOf('/vpn') > -1) {
            this.selectedIndex = 3;
            this.sendWhichModuleIsOpen('VPN_USAGE');
        } else if (this.router.url.indexOf('/processes') > -1) {
            this.selectedIndex = 4;
            this.sendWhichModuleIsOpen('PROCESS_USAGE');
        } else if (this.router.url.indexOf('/users') > -1) {
            this.selectedIndex = 5;
            this.sendWhichModuleIsOpen('USER_USAGE');
        } else if (this.router.url.indexOf('/services') > -1) {
            this.selectedIndex = 6;
            this.sendWhichModuleIsOpen('SERVICE_USAGE');
        } else if (this.router.url.indexOf('/network') > -1) {
            this.selectedIndex = 7;
            this.sendWhichModuleIsOpen('NETWORK_USAGE');
        } else {
            this.selectedIndex = 0;
            this.sendWhichModuleIsOpen('PROCESSOR_USAGE');
        }

    }

    checkMonitoringPlugin(plugins: DotNetServerPluginTemplate[], desktopServer: DesktopServer): boolean {
        if (plugins) {
            for (const plugin of plugins) {
                if (plugin.name.toLowerCase() === 'monitoramento') {
                    const desktopServerPlugin = desktopServer.serverPluginTemplateIds.find(x => x === plugin.id);
                    if (desktopServerPlugin) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    loadVPNs(): void {
        this.subscriptions.push(this.vpnMonitoringConfigurationDaoService.findAllByServerIdAndServerType(this.baseServer.id, this.serverType).subscribe((vpnList) => {
            if (vpnList) {
                this.vpnList = vpnList;
            }
        }));
    }

    loadServerHds(): void {
        this.subscriptions.push(this.hdUsageDaoService.findAllByServerIdAndServerType(this.baseServer.id, this.serverType).subscribe((hdList) => {
            if (hdList) {
                this.hdList = hdList;
            }
        }));
    }

    loadDesktopServerNetworkConfiguration(): void {
        this.subscriptions.push(this.networkConfigurationDaoService.findAllByServerIdAndServerType(this.baseServer.id, this.serverType).subscribe((networks) => {
            if (networks) {
                this.networks = networks;
            }
        }));
    }

    ngOnInit(): void {
        this.lineChartData = lineChartData;
        this.lineChartLabels = lineChartLabels;
        this.lineChartLegend = lineChartLegend;
        this.lineChartType = lineChartType;
        this.lineChartOptions = lineChartOptions;
        this.lineChartColors = lineChartColors;
    }

    onLinkClick(event: MatTabChangeEvent): void {
        this.sendWhichModuleIsOpen(event.tab.textLabel);
        this.tabTextLabel = event.tab.textLabel;
        this.setPeriod(this.rangeMinutes);
        setChartValue(this.begin, this.end, this.tabTextLabel);

    }

    stompConnect(): void {
        if (this.desktopServer || this.linuxServer) {

            if (this.desktopServer) {
                this.checkDesktopServerAgentServerConnectionStatus();
            } else if (this.linuxServer) {
                this.checkLinuxServerAgentServerConnectionStatus();
            }
        }
    }

    checkConnectionStatus(): void {
        this.subscriptions.push(this.webStompService.connectionStatus$.subscribe((connect) => {
            if (connect) {
                this.connectionStatus = connect;
            }
        }));
    }

    checkDesktopServerAgentServerConnectionStatus(): void {
        this.subscriptions.push(this.desktopServerStatusService.onDesktopServerStatus(this.desktopServer.id).subscribe((result) => {
            this.agentServeVersion = result.clientVersion;
            this.agentServerConnectionStatus = result.status;
        }));
    }

    checkLinuxServerAgentServerConnectionStatus(): void {
        this.subscriptions.push(
            this.stomp.stompTopic<ServerStatus>(`${TOPIC}/LinuxServer.${this.linuxServer.id}.status`).subscribe((result) => {
                this.agentServeVersion = result.clientVersion;
                this.agentServerConnectionStatus = result.status;
            })
        );
    }

    ngOnDestroy(): void {
        // this.webStompService.disconnectAll();
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        this.subscriptions = [];
        this.sendWhichModuleIsOpen('');
    }

    setPeriod(minutes: number): void {
        this.rangeMinutes = minutes;
        const beginObj: Date = new Date(Date.now());
        const endObj: Date = new Date(Date.now());
        this.begin = new Date(beginObj.getTime() - minutes * 60000);
        this.end = new Date(endObj.getTime() + 5 * 60000);
        this.rangeInMinSelected = getDifferenceBetweenTwoDatesInMinutes(this.begin, this.end);
        setChartValue(this.begin, this.end, this.tabTextLabel);
    }

    sendWhichModuleIsOpen(selectedModule: string): void {
        if (this.baseServer?.id) {
            const pluginMessage: PluginMessage = {
                id: null,
                comment: null,
                active: true,
                serverId: this.baseServer?.id,
                serverType: this.serverType,
                topic: TOPIC + '/monitoring',
                message: selectedModule,
                modified: null,
                optlock: null
            };
            this.subscriptions.push(this.pluginMessageDaoService.sendMessageToAgent(pluginMessage).subscribe());
        }
    }

    getResolution(): number {
        if (this.rangeMinutes < 50) {
            return resolutions[0].value;
        } else {
            return resolutions[2].value;
        }
    }

    replaceSeparator(driveName: string, separator: string): String {
        return driveName.replace(separator, '<span class="ml-30 mr-30 secondary-text">|</span>');
    }

    openWebSSH(linuxServer: LinuxServer): void {
        this.terminalService.openWebSSH(linuxServer);
        setTimeout(() => {
        }, 8000);
    }
}


<div class="page-layout carded fullwidth">
    <div class="accent"></div>
    <div class="header accent header-medium" fxLayout="row" fxLayoutAlign="start center">
        <h1>Central de atualização em massa</h1>
    </div>
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row">
                <div fxLayout="row wrap" fxLayoutAlign="start none">
                    <app-hipcom-bulk-update-dashboard fxFlex="50"
                                                      [hipcomBulkUpdateLatestSoftwareVersions]=hipcomBulkUpdateLatestSoftwareVersions
                                                      [hipcomBulkUpdateCurrentSoftwareVersions]=hipcomBulkUpdateCurrentSoftwareVersions
                                                      [hipcomBulkUpdateDetails]=hipcomBulkUpdateDetails


                    ></app-hipcom-bulk-update-dashboard>
                    <app-hipcom-bulk-update-latest-software-version fxFlex="auto"
                                                                    (eventHipcomBulkUpdateLatestSoftwareVersions)=eventHipcomBulkUpdateLatestSoftwareVersions($event)
                                                                    [hipcomBulkUpdateCurrentSoftwareVersions]=hipcomBulkUpdateCurrentSoftwareVersions
                                                                    (eventHipcomBulkUpdateDetails)=eventHipcomBulkUpdateDetails()
                    ></app-hipcom-bulk-update-latest-software-version>
                </div>
                <app-hipcom-bulk-update-detail #appHipcomBulkUpdateDetail
                                               [hipcomBulkUpdateLatestSoftwareVersions]=hipcomBulkUpdateLatestSoftwareVersions
                                               (eventHipcomBulkUpdateDetailDataList)=eventHipcomBulkUpdateDetailDataList($event)
                                               (eventHipcomBulkUpdateCurrentSoftwareVersions)=eventHipcomBulkUpdateCurrentSoftwareVersions($event)>
                </app-hipcom-bulk-update-detail>
            </div>
        </div>
    </div>
</div>

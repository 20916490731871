<div class="mat-white-bg mat-elevation-z2 no-padding" fxLayout="column" fxLayoutAlign="space-between stretch"
     matDialogContent>
    <div class="product-detail-header banner" [style.background-image]="'url('+image_url+')' | safeHtml: 'style'">
        <div style="width: 100%; height: 250px" *ngIf="marketplaceProduct != null">
            <div class="product-detail-title">
                <div>
                    {{marketplaceProduct?.name}}
                </div>
                <div class="line-height-24">
                    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <div class="product-detail-rating">
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,1)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 1)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,2)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 2)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,3)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 3)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,4)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 4)}}</mat-icon>
                            <mat-icon
                                class="{{marketplaceService.getStarRating(marketplaceProduct,5)}}">{{marketplaceService.checkIfIsHalfStar(marketplaceProduct, 5)}}</mat-icon>
                        </div>
                        <div class="font-size-12 ml-5">({{marketplaceService.checkTotalVotes(marketplaceProduct)}})
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="product-detail-content">

        <mat-tab-group #tabProductDetail
                       id="marketplace-product-tabs"
                       animationDuration="0ms" disableRipple="true">
            <mat-tab label="Descrição"
                     id="marketplace-product-detail"
                     *ngIf="marketplaceProduct != null">
                <app-marketplace-product-detail-description [marketplaceProduct]="marketplaceProduct"
                                                            [amountOfMarketplaceProductOrders]="amountOfMarketplaceProductOrders"
                                                            [reloadData]="reloadData"></app-marketplace-product-detail-description>
            </mat-tab>
            <mat-tab label="FAQ"
                     id="marketplace-product-faq"
                     *ngIf="marketplaceProduct != null">
                <app-marketplace-product-detail-faq [marketplaceProduct]="marketplaceProduct"
                                                    (eventSendEdit)="eventSendEdit($event)"
                                                    [reloadData]="reloadData"></app-marketplace-product-detail-faq>
            </mat-tab>
            <mat-tab label="Telas"
                     id="marketplace-product-screen"
                     *ngIf="marketplaceProduct != null">
                <app-marketplace-product-detail-screen [marketplaceProduct]="marketplaceProduct"
                                                       (eventSendEdit)="eventSendEdit($event)"
                                                       [reloadData]="reloadData"></app-marketplace-product-detail-screen>
            </mat-tab>
            <mat-tab label="Comentários"
                     id="marketplace-product-comentario"
                     *ngIf="marketplaceProduct != null">
                <app-marketplace-product-detail-comment [marketplaceProduct]="marketplaceProduct"
                                                        [reloadData]="reloadData"></app-marketplace-product-detail-comment>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
    <div>
        <button type="button" mat-raised-button color="accent"
                id="marketplace-product-contact"
                (click)="onContact()">Entrar em contato
        </button>
    </div>

    <div *ngIf="marketplaceProduct != null">
        <div *ngIf="canAccess">
            <button type="button" mat-raised-button color="accent"
                    id="marketplace-product-edit"
                    (click)="onEdit()">Editar</button>
            <button type="button" mat-raised-button color="warn"
                    id="marketplace-product-delete"
                    (click)="onDelete()">Deletar</button>
        </div>
    </div>

    <div>
        <button type="button" mat-raised-button color="primary"
                id="marketplace-product-cancel"
                (click)="onCancel()">Cancelar</button>
    </div>
</div>



import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {ManagerUserAccessPeriodService} from '../manager-user-access-period/manager-user-access-period.service';
import {MyUploadsComponent} from './my-uploads.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {UploadFileDaoService} from './upload-file-dao.service';
import {NgBytesPipeModule} from '../../pipes/bytes-pipe';
import {SendFilesComponent} from './send-files/send-files.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes = [
    {
        path: 'my-uploads',
        component: MyUploadsComponent,
        canActivate: [AuthService],
        data: {permissions: ['UploadFile.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        KluhSharedModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSelectModule,
        MatGridListModule,
        NgBytesPipeModule,
        MatProgressSpinnerModule
    ],
    providers: [
        ManagerUserAccessPeriodService, UploadFileDaoService
    ],
    entryComponents: [],
    declarations: [MyUploadsComponent, SendFilesComponent],

})
export class MyUploadsModule {
}

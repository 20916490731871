<form *ngIf="myForm" [formGroup]="myForm" (ngSubmit)="myForm.valid && onSubmit()" id="ad-domain-wizard-form" >
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Cadastro de Domínio</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2 " fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent>

        <ng-container formGroupName="useExistingAdDomainForm">
            <mat-slide-toggle formControlName="useExistingAdDomain" name="useExistingAdDomain"
                              style="padding: 40px 60px 60px 140px;">
                Usar Domínio existente
            </mat-slide-toggle>

            <mat-form-field style="padding: 10px 20px 20px 20px;" *ngIf="adDomains && useExistingAdDomainForm.get('useExistingAdDomain').value">
                <mat-select placeholder="Domínios existentes" formControlName="adDomainId">
                    <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                        {{adDomain.domainName}} - {{adDomain.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>



        <div class="grey-50-bg border-top p-16" *ngIf="!useExistingAdDomainForm.get('useExistingAdDomain').value"
             fxLayout="column" fxLayoutAlign="space-around start"  >
            <ng-container formGroupName="adDomainForm">
                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="name"
                           id="ad-domain-wizard-name"
                           placeholder="Nome (identificador)" required>
                    <mat-error>
                        {{myForm.get('adDomainForm').get('name').errors | error}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <input matInput formControlName="domainName"
                           id="ad-domain-wizard-domain-name"
                           placeholder="Domínio do Active Directory" required>
                    <mat-error>
                        {{myForm.get('adDomainForm').get('domainName').errors | error}}
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>

        <button type="submit" mat-raised-button color="accent" id="ad-domain-wizard-create-button"
                [disabled]="!myForm.valid || myForm.pristine" aria-label="Salvar">
            <span
                *ngIf="adDomainForm.get('id').value || useExistingAdDomainForm.get('useExistingAdDomain').value;else new">Salvar</span>
            <ng-template #new>Criar</ng-template>
        </button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</form>


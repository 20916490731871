import {Component} from '@angular/core';
import {ManagerUserDaoService} from '../manager-user-dao.service';
import {AuthService} from '../../../auth.service';
import {ManagerUser} from '../../../models';

@Component({
  selector: 'app-manager-user-list',
  templateUrl: './manager-user-list.component.html',
  styleUrls: ['./manager-user-list.component.scss']
})
export class ManagerUserListComponent {
  public managerUsers: ManagerUser[];

  constructor(private dao: ManagerUserDaoService, private auth: AuthService) {
    this.dao.get().subscribe((managerUsers) => {
      this.managerUsers = managerUsers;
    });
  }

  onUserCreated(managerUser: ManagerUser): void {
    if (this.managerUsers && managerUser) {
      // this.auth.sendResetPassword(managerUser.email);
      this.managerUsers.push(managerUser);
    }
  }

}

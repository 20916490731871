import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MarketplaceService} from '../../marketplace.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {convertToFormGroup, CrudOperationWrapper} from '../../../../helpers/kluh';
import {CustomerGroup, FirebaseUserDetails, MarketplaceOrder, MarketplaceProduct} from '../../../../models';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {MarketplaceOrderDaoService} from '../../marketplace-order/marketplace-order.dao.service';
import {MarketplaceOrderService} from '../../marketplace-order/marketplace-order.service';
import {CustomerGroupDaoService} from '../../../customer-group/customer-group-dao.service';


@Component({
    selector: 'app-marketplace-product-contact',
    templateUrl: './marketplace-product-contact.component.html',
    styleUrls: ['./marketplace-product-contact.component.scss']
})
export class MarketplaceProductContactComponent implements OnInit {

    marketplaceProduct: MarketplaceProduct;
    customerGroup: CustomerGroup;
    myForm: FormGroup;
    user: FirebaseUserDetails = null;

    constructor(public dialogRef: MatDialogRef<MarketplaceProductContactComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                public marketplaceService: MarketplaceService,
                public marketplaceOrderDaoService: MarketplaceOrderDaoService,
                public customerGroupDaoService: CustomerGroupDaoService,
                public marketplaceOrderService: MarketplaceOrderService,
                public userAuthorityDao: UserAuthorityDaoService,
    ) {
    }

    ngOnInit(): void {
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
            this.customerGroupDaoService.getByAuthenticatedManagerUser().subscribe(customerGroup => {
                if (customerGroup) {
                    this.customerGroup = customerGroup;
                    if (this.data.marketplaceProduct) {
                        this.marketplaceProduct = this.data.marketplaceProduct;
                        const marketplaceOrder: MarketplaceOrder = this.marketplaceOrderService.initMarketplaceOrder();
                        marketplaceOrder.marketplaceProductId = this.marketplaceProduct.id;
                        marketplaceOrder.managerUserId = this.user.managerUser.id;
                        marketplaceOrder.email = this.user.managerUser?.email;
                        marketplaceOrder.phone = this.user.managerUser?.phone;
                        marketplaceOrder.customerGroupId = this.customerGroup.id;
                        this.myForm = this.fb.group(convertToFormGroup(marketplaceOrder));

                    }
                }
            });
        });
    }
    onCancel(): void {
        this.dialogRef.close(false);
    }
    onSubmit(): void {
        const marketplaceOrder: MarketplaceOrder = this.myForm.value;
        this.marketplaceOrderDaoService.create(marketplaceOrder).subscribe(marketplaceOrderFromDAO => {
            if (marketplaceOrderFromDAO) {
                const crudOperation: CrudOperationWrapper = {
                    data: marketplaceOrderFromDAO,
                    operation: 'CREATE'
                };
                this.dialogRef.close(crudOperation);
            }
        });
    }
}

<div>
  <div>{{project?.name}}</div>
  <div>
    <button type="button" mat-raised-button class="mat-green-600-bg" (click)="editProjectChild(project)">
        <span class="mat-button-wrapper">
            Edit
        </span>
    </button>

  </div>
</div>

import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DotNetServerPluginDependencyFile} from '../../../models';
import {ComponentCleaner} from '../../../component-cleaner';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {markAsTouched} from '../../../helpers/kluh';
import {createErrorModal} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';
import {DotNetServerPluginDependencyFileDaoService} from '../services/server-plugin-dependency-file-dao.service';

@Component({
    selector: 'app-server-plugin-dependency-file-create-edit',
    templateUrl: './server-plugin-dependency-file-create-edit.component.html',
    styleUrls: ['./server-plugin-dependency-file-create-edit.component.scss']
})
export class DotNetServerPluginDependencyFileCreateEditComponent extends ComponentCleaner implements OnInit {
    myForm: FormGroup;
    private formData: FormData;

    constructor(
        public dialogRef: MatDialogRef<DotNetServerPluginDependencyFileCreateEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private serverPluginDependencyFileDao: DotNetServerPluginDependencyFileDaoService,
        private serverPluginService: DotNetServerPluginService,
        private dialog: MatDialog
    ) {
        super();
        this.myForm = fb.group(data.serverPluginDependencyFile, {asyncValidator: this.serverPluginDependencyFileDao.validator});
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        const serverPluginFile: DotNetServerPluginDependencyFile = this.myForm.value;
        const json = JSON.stringify(serverPluginFile);
        this.formData.append('json', json);
        this.serverPluginService.onServerPluginDependencyFileUploadFinished(this.serverPluginDependencyFileDao.upload(this.formData))
            .subscribe(() => {
                this.dialogRef.close();
            });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget && currentTarget.files && currentTarget.files.length === 1) {
            this.setFileFormData(currentTarget.files);
        }
    }

    private setFileFormData(files: File[] | FileList): void {
        const file = files[0];
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        this.formData = formData;
        this.myForm.get('name').setValue(file.name.split('.').slice(0, -1).join('.'));
        markAsTouched(this.myForm);
    }

    getFileName(): string {
        if (this.formData) {
            const uploadFile: File = this.formData.get('file') as File;
            if (uploadFile) {
                return uploadFile.name;
            }
        }
        return '';
    }


    fileDrop(files: FileList): void {
        if (files.length > 1){
            createErrorModal(this.dialog, 'Apenas um arquivo é suportado.');
            return;
        }
        this.setFileFormData(files);
    }
}

<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Editar usuário do grupo</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div matDialogContent>
        <div fxLayout="column" class="p-15" fxLayoutAlign="start stretch">
            <h2 style="margin-bottom: 0;">{{ managerUser.displayName }}</h2>
            <div style="margin-bottom: 30px;">{{ managerUser.email }}</div>

            <div class="full-width mb-10">
                <mat-form-field *ngIf="aiUserGroups" class="full-width" appearance="outline">
                    <mat-select placeholder="Grupos" [formControl]="aiUserGroupIdsControl" multiple>
                        <mat-option *ngFor="let aiUserGroup of removeDefaultAiUserGroup(aiUserGroups)" [value]="aiUserGroup.id">
                            {{aiUserGroup?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="button" mat-raised-button color="accent" [disabled]="!customerGroupService.customerGroup.id" (click)="onSave()">Salvar</button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

<form *ngIf="myForm" [formGroup]="myForm"
      id="sub-project-create-edit-form"
      (submit)="myForm.valid && myForm.dirty && onSubmit()">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Cadastro de Sub Projeto</span>
        </mat-toolbar-row>
    </mat-toolbar>


    <div class="mat-white-bg mat-elevation-z2" fxLayout="column" style="padding: 20px 25px 35px 30px"
         fxLayoutAlign="space-between stretch" matDialogContent>

        <div style="margin-bottom: 20px;">
            <mat-form-field style="width: 100%" *ngIf="projects && projectType === 'shared'">
                <mat-select placeholder="Selecione um Compartilhamento" formControlName="projectId" id="sub-project-create-edit-share-ids">
                    <mat-option *ngFor="let project of projects; let i = index;" [value]="project.id" id="sub-project-create-edit-share-id-{{i}}" >
                        {{project.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{myForm.get('projectId').errors | error}}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="projectType === 'new-shared'" style="margin-bottom: 20px;width: 100%;">
                <input matInput [formControl]="projectNameFilterControl"
                       id="sub-project-create-edit-new-share"  maxlength="33"
                       placeholder="Nome do novo Compartilhamento">
            </mat-form-field>


            <mat-form-field style="width: 100%" *ngIf="projectType !== 'new-shared'">
                <input matInput formControlName="name" placeholder="Nome do Projeto" maxlength="33"
                       id="sub-project-create-edit-name"
                       [disabled]="!!(myForm.value.id)">
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-slide-toggle formControlName="active" name="active">
            Ativo
        </mat-slide-toggle>


    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>

        <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                id="sub-project-create-edit-create-button"
                aria-label="Salvar">
            <span *ngIf="subProject.id;else newUser">Salvar</span>
            <ng-template #newUser>Criar</ng-template>
        </button>
        <button type="button" mat-raised-button color="warn"
                id="sub-project-create-edit-remove"
                (click)="onRemove()">Excluir</button>
        <button type="button" mat-raised-button color="primary"
                id="sub-project-create-edit-cancel"
                (click)="onCancel()">Cancelar</button>

        <div *ngIf="subProject" class="sub-project-modal">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" style="max-width: 472px" >
                <button mat-menu-item *appIsAdmin (click)="onGetLinkIps(subProject)">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Link dos ips de usuários do projeto</span>
                </button>
                <button mat-menu-item *appIfPermission="'AUDIT'; data: subProject; type: 'SubProject'"
                        (click)="onJaversHistory()">
                    <mat-icon>history</mat-icon>
                    <span>Histórico</span>
                </button>
                <button mat-menu-item *appIfPermission="'AUDIT'; data: subProject; type: 'SubProject'"
                        (click)="onJaversAllHistory()">
                    <mat-icon>history</mat-icon>
                    <span>Histórico completo</span>
                </button>
            </mat-menu>
        </div>


    </div>

</form>

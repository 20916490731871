import {Component, Inject} from '@angular/core';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ComponentCleaner} from '../../../component-cleaner';
import {ADDomain, DesktopServer, LinuxServer, SubProject} from '../../../models';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-server-create-dialog',
    templateUrl: './server-create-dialog.component.html',
    styleUrls: ['./server-create-dialog.component.scss']
})
export class ServerCreateDialogComponent extends ComponentCleaner {
    icon = faQuestionCircle;
    useExistingServerForm: FormGroup;
    public myForm: FormGroup;
    public desktopServers: DesktopServer[] = [];
    public linuxServers: LinuxServer[] = [];
    private subProject: SubProject;
    private adDomains: ADDomain[];

    constructor(private dialogRef: MatDialogRef<ServerCreateDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private desktopServerDao: DesktopServerDaoService,
                private linuxServerDao: LinuxServerDaoService,
                public adminService: R2CloudAdminService,
    ) {
        super();
        this.subProject = data.subProject;
        this.adDomains = data.adDomains;
        if (!this.subProject.linuxServerIds){
            this.subProject.linuxServerIds = [];
        }
        if (!this.subProject.desktopServerIds){
            this.subProject.desktopServerIds = [];
        }
        if (this.subProject.projectId) {
            this.desktopServerDao.filter({'projectId': this.subProject.projectId}).subscribe((desktopServers) => {
                if (desktopServers) {
                    for (let i = 0; i < desktopServers.length; i++) {
                        for (let j = 0; j < this.adDomains.length; j++) {
                            if (desktopServers[i].adDomainId === this.adDomains[j].id) {
                                const index = this.subProject.desktopServerIds.findIndex((o) => o === desktopServers[i].id);
                                if (index < 0) {
                                    this.desktopServers.push(desktopServers[i]);
                                }
                            }
                        }
                    }
                }
            });

            this.linuxServerDao.filter({'projectId': this.subProject.projectId}).subscribe((linuxServers) => {
                if (linuxServers) {
                    for (let i = 0; i < linuxServers.length; i++) {
                        const index = this.subProject.linuxServerIds.findIndex((o) => o === linuxServers[i].id);
                        if (index < 0) {
                            this.linuxServers.push(linuxServers[i]);
                        }
                    }
                }
            });
        }


        this.useExistingServerForm = this.fb.group({
            useExistingServer: false,
            desktopServerId: null,
            linuxServerId: null

        });

        this.myForm = this.fb.group({
            useExistingServerForm: this.useExistingServerForm
        });


    }


    onWindows(): void {
        this.dialogRef.close('windows');
    }

    onLinux(): void {
        this.dialogRef.close('linux');
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        const desktopServerId = this.useExistingServerForm.get('desktopServerId').value;
        const linuxServerId = this.useExistingServerForm.get('linuxServerId').value;

        if (desktopServerId || linuxServerId) {
            if (desktopServerId) {
                this.desktopServerDao.saveDesktopServerIdInSubProject(desktopServerId, this.adminService.subProject.id)
                    .subscribe((result2) => {
                        this.adminService.desktopServers.push(result2);
                        this.adminService.subProject.desktopServerIds.push(result2.id);
                        this.dialogRef.close();
                    });
            }
            if (linuxServerId) {
                this.linuxServerDao.saveLinuxServerIdInSubProject(linuxServerId, this.adminService.subProject.id)
                    .subscribe((result2) => {
                        this.adminService.linuxServers.push(result2);
                        this.adminService.subProject.linuxServerIds.push(result2.id);
                        this.dialogRef.close();
                    });
            }
        }

    }

}

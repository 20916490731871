<div class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
         style="z-index: 99;margin-top: 12px;">
        <div fxLayout="row" fxLayoutAlign="start center" style="min-height: 92px;">
            <fa-icon class="mr-10 font-size-24" [icon]="faPlug"></fa-icon>
            <span class="h1 m-0">Administração de Plugins e Agente do R2 </span>
        </div>
    </div>
    <div class="ml-15">
        <div>
            <mat-tab-group animationDuration="0ms" disableRipple="true" id="marketplace-tab-category"
                           class="width-100-percent">
                <mat-tab label="Plugins" id="server-plugin-plugins">
                    <div style="padding-bottom: 500px;"class="mb-60">
                        <app-server-plugin-template-card class="plugin-module"></app-server-plugin-template-card>
                        <app-server-plugin-card class="plugin-module"></app-server-plugin-card>
                        <app-server-plugin-file-card class="plugin-module"></app-server-plugin-file-card>
                        <app-server-plugin-dependency-file-card
                            class="plugin-module"></app-server-plugin-dependency-file-card>
                    </div>
                </mat-tab>
                <mat-tab label="Agente e Updater" id="server-plugin-agent" fxLayout="row" fxLayoutAlign="start start">
                    <div style="padding-bottom: 500px;" class="mb-60">
                        <app-server-agent-file-card class="plugin-module"></app-server-agent-file-card>
                        <app-server-agent-updater-file-card class="plugin-module"></app-server-agent-updater-file-card>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>


import {Component, OnInit} from '@angular/core';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {MatDialog} from '@angular/material/dialog';
import {DotNetServerAgentFileDaoService} from '../services/server-agent-file-dao.service';
import {DotNetServerAgentFile} from '../../../models';
import {ConfirmDialogComponent, createErrorModal} from '../../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-server-agent-file-card',
    templateUrl: './server-agent-file-card.component.html',
    styleUrls: ['./server-agent-file-card.component.scss']
})
export class DotNetServerAgentFileCardComponent implements OnInit {

    constructor(
        public serverPluginService: DotNetServerPluginService,
        public serverAgentFileDao: DotNetServerAgentFileDaoService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    onFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget && currentTarget.files && currentTarget.files.length === 1) {
            this.setFileFormData(currentTarget.files);
        }
    }

    private setFileFormData(files: FileList): void {
        const file = files[0];
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const serverAgentFile: DotNetServerAgentFile = {
            active: true, comment: undefined, id: undefined, md5: undefined, version: undefined,
            modified: null,
            optlock: null,
            fileUUID: null

        };
        const json = JSON.stringify(serverAgentFile);
        formData.append('json', json);
        this.serverPluginService.onServerAgentFileUploadFinished(this.serverAgentFileDao.upload(formData))
            .subscribe();
    }

    onDownload(serverAgentFile: DotNetServerAgentFile): void {
        this.serverAgentFileDao.download(serverAgentFile.id).subscribe((data) => {
            if (data) {
                const blob = new Blob([data], {type: 'application/octet-stream'});
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, this.getFileName(serverAgentFile));
                } else {
                    const a = document.createElement('a') as HTMLAnchorElement;
                    a.style['display'] = 'none';
                    a.href = URL.createObjectURL(blob);
                    a.download = this.getFileName(serverAgentFile);
                    a.click();
                }
            }
        });
    }

    getFileName(serverAgentFile: DotNetServerAgentFile): string {
        return 'R2CloudServerAgent.exe';
    }

    updateAllServerAgent(): void {
        this.serverAgentFileDao.updateAllServerAgent().subscribe();
    }

    fileDrop(files: FileList): void {
        if (files.length > 1) {
            createErrorModal(this.dialog, 'Apenas um arquivo é suportado.');
            return;
        }
        this.setFileFormData(files);
    }

    onDelete(serverAgentFile: DotNetServerAgentFile): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa versão do agente?<br><br>' + serverAgentFile.version,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverAgentFileDao.remove(serverAgentFile.id).subscribe(deleted => {
                    this.serverPluginService.deleteFromList(serverAgentFile.id, this.serverPluginService.serverAgentFiles);
                });
            }
        });

    }
}

import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MarketplaceService} from '../marketplace.service';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {MarketplaceProductContactComponent} from './marketplace-product-contact/marketplace-product-contact.component';
import {MarketplaceProductCreateAndEditComponent} from '../marketplace-product-create-and-edit/marketplace-product-create-and-edit.component';
import {MarketplaceProductFAQEditComponent} from './marketplace-product-faq/marketplace-product-faq-edit/marketplace-product-faq-edit.component';
import {MarketplaceProductDetailScreenEditComponent} from './marketplace-product-screen/marketplace-product-screen-edit/marketplace-product-detail-screen-edit.component';
import {Router} from '@angular/router';
import {MarketplaceProduct} from '../../../models';
import {MarketplaceProductDaoService} from './marketplace-product.dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {MarketplaceOrderDaoService} from '../marketplace-order/marketplace-order.dao.service';
import {MarketplaceOrderService} from '../marketplace-order/marketplace-order.service';

@Component({
    selector: 'app-marketplace-product-detail',
    templateUrl: './marketplace-product.component.html',
    styleUrls: ['./marketplace-product.component.scss']
})
export class MarketplaceProductComponent implements OnInit {

    image_url = '';
    marketplaceProduct: MarketplaceProduct = null;
    amountOfMarketplaceProductOrders = 0;
    reloadData = 0;
    appHasPermissionList: PermissionWrapper[];
    canAccess = false;

    @ViewChild('tabProductDetail') tabProductDetail;

    constructor(public dialogRef: MatDialogRef<MarketplaceProductComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private router: Router,
                public render: Renderer2,
                public elRef: ElementRef,
                private imageFileService: ImageFileService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private customerGroupService: CustomerGroupService,
                private marketplaceProductDaoService: MarketplaceProductDaoService,
                private marketplaceOrderDaoService: MarketplaceOrderDaoService,
                private marketplaceOrderService: MarketplaceOrderService,
                public marketplaceService: MarketplaceService
    ) {
        if (this.data.id != null) {
            this.marketplaceProductDaoService.getOne(this.data.id).subscribe(marketplaceProduct => {
                if (marketplaceProduct) {
                    this.userAuthorityDaoService.hasPermission('MarketplaceProduct', marketplaceProduct.id, 'WRITE').subscribe(userHasPermission => {
                        if (userHasPermission && userHasPermission.value) {
                            this.canAccess = true;
                        }
                        this.marketplaceProduct = marketplaceProduct;
                        this.image_url = this.imageFileService.getMarketPlaceProductBannerPictureUrl(this.marketplaceProduct.bannerUUID);
                        this.marketplaceOrderDaoService.getAmountOfOrderByMarketplaceProductId(this.data.id).subscribe(amountOfMarketplaceProductOrders => {
                            if (amountOfMarketplaceProductOrders) {
                                this.amountOfMarketplaceProductOrders = amountOfMarketplaceProductOrders;
                            }
                        });
                    });
                }
            });
        }
    }

    ngOnInit(): void {
    }

    onCancel(): void {
        const crudOperation: CrudOperationWrapper = {
            operation: 'SAVE',
            data: this.marketplaceProduct
        };
        this.dialogRef.close(crudOperation);
    }


    onContact(): void {
        const dialogRef = this.dialog.open(MarketplaceProductContactComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                marketplaceProduct: this.marketplaceProduct
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'CREATE') {
                this.dialogRef.close(true);
                this.router.navigate(['/marketplace-order/detail'], {queryParams: {marketplaceOrderId: result.data.id}});
            }
        });
    }

    onEdit(): void {
        if (this.tabProductDetail.selectedIndex === 0) {
            this.openProductEdit();
        } else if (this.tabProductDetail.selectedIndex === 1) {
            this.openFAQEdit();
        } else if (this.tabProductDetail.selectedIndex === 2) {
            this.openScreenEdit();
        }
    }


    onDelete(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse serviço?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.marketplaceProductDaoService.remove(this.marketplaceProduct.id).subscribe(product => {
                    const crudOperation: CrudOperationWrapper = {
                        operation: 'DELETE',
                        data: this.marketplaceProduct
                    };
                    this.dialogRef.close(crudOperation);
                });
            }
        });
    }

    openProductEdit(): void {
        const dialogRef = this.dialog.open(MarketplaceProductCreateAndEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                marketplaceProduct: this.marketplaceProduct
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result && result.operation === 'SAVE') {
                this.marketplaceProduct = result.data;
            }
            this.reloadData++;
        });
    }

    openFAQEdit(): void {
        const dialogRef = this.dialog.open(MarketplaceProductFAQEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                marketplaceProduct: this.marketplaceProduct
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            if (result && result.operation === 'SAVE') {
                //
            }
            this.reloadData++;
        });
    }

    openScreenEdit(): void {
        const dialogRef = this.dialog.open(MarketplaceProductDetailScreenEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                marketplaceProduct: this.marketplaceProduct
            }
        });
        dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.reloadData++;
        });
    }


    eventSendEdit(event: number): void {
        this.onEdit();
    }
}

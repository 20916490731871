import {Injectable} from '@angular/core';
import {TableDataService} from '../table-data.service';
import {ProcessDetails} from '../../../../models-monitoring';

@Injectable({
    providedIn: 'root'
})
export class ProcessDetailsTableService extends TableDataService<ProcessDetails> {

    constructor() {
        super();
    }
}

import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MarketplaceOrder, MarketplaceProduct} from '../../../../models';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {ImageFileService} from '../../../image-file/image-file-service';
import {MarketplaceService} from '../../marketplace.service';
import {Router} from '@angular/router';
import {MarketplaceOrderService} from '../marketplace-order.service';

@Component({
    selector: 'app-marketplace-order-table',
    templateUrl: './marketplace-order-table.component.html',
    styleUrls: ['./marketplace-order-table.component.scss']
})
export class MarketplaceOrderTableComponent implements OnChanges {
    dataSource = new MatTableDataSource<MarketplaceOrder>();

    @Input()
    marketplaceOrderList: MarketplaceOrder[];

    @Input()
    marketplaceProductList: MarketplaceProduct[];

    @Input()
    unReadList = false;

    @Output()
    marketplaceOrderListEvent = new EventEmitter<MarketplaceOrder[]>();


    displayedColumns: string[] = [
        'new', 'id', 'managerUserName', 'marketplaceProductAvatarId', 'createdAt'
    ];

    constructor(
        public marketplaceService: MarketplaceService,
        public marketplaceOrderService: MarketplaceOrderService,
        public adminService: R2CloudAdminService,
        private router: Router,
        public imageFileService: ImageFileService,
    ) {
    }

    onOpenRequest(marketplaceOrder: MarketplaceOrder): void {
        this.marketplaceOrderListEvent.next(this.marketplaceOrderService.markAsRead(this.marketplaceOrderList, marketplaceOrder.id));
        this.router.navigate(['/marketplace-order/detail'], {queryParams: {marketplaceOrderId: marketplaceOrder.id}});
    }

    ngOnChanges(): void {
        this.dataSource.data = this.marketplaceOrderList;
    }

}

import {OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

export abstract class ComponentCleaner implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private timeouts: NodeJS.Timeout[] = [];
    onDestroy$ = new Subject();

    ngOnDestroy(): void {
        this.clearSubscriptions();
        this.clearTimeouts();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private clearSubscriptions(): void {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        this.subscriptions = [];
    }

    private clearTimeouts(): void {
        for (const timeout of this.timeouts) {
            if (timeout) {
                clearInterval(timeout);
            }
        }
        this.timeouts = [];
    }
    protected addSubscription(subscription: Subscription): Subscription {
        this.subscriptions.push(subscription);
        return subscription;
    }
    protected addTimeout(timeout: NodeJS.Timeout): NodeJS.Timeout {
        this.timeouts.push(timeout);
        return timeout;
    }
}

import {Component, OnInit} from '@angular/core';
import {CustomerDaoService} from '../customer-dao.service';

import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerCreateEditComponent} from '../customer-create-edit/customer-create-edit.component';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {Customer, CustomerGroup} from '../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss'],
    animations: fuseAnimations,

})
export class CustomerListComponent extends ComponentCleaner implements OnInit {
    public customers: Customer[];
    public customerGroups: CustomerGroup[];

    constructor(private customerDAO: CustomerDaoService, private customerGroupDao: CustomerGroupDaoService, private dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.customerDAO.get().subscribe((result) => {
            this.customers = result;
            if (!this.customers) {
                this.customers = [];
            }
        });
        this.customerGroupDao.get().subscribe((result) => {
            this.customerGroups = result;
        });
    }

    onAdd(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {
                customerGroups: this.customerGroups
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.customers.push(result.data);
        } else if (result.operation === 'SAVE') {
            const index = this.customers.findIndex(value => value.id === result.data.id);
            this.customers[index] = result.data;
        } else if (result.operation === 'DELETE') {
            this.customers = this.customers.filter(value => value.id === result.data);
        }
    }

    onEdit(customer: Customer): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {
                customerGroups: [this.findCustomerGroup(customer.customerGroupId)],
                customer: customer
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    findCustomerGroup(customerGroupId: number): CustomerGroup {
        return this.customerGroups.find(value => value.id === customerGroupId);
    }
}

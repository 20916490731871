import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {RouterModule} from '@angular/router';
import {HomeService} from './home.service';

const routes = [
  {
    path     : 'home',
    component: HomeComponent,
    canActivate: [HomeService],
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [HomeComponent],
  providers: [HomeService]
})
export class HomeModule { }

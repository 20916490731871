import {CtrlAltCharacterShortCuts, KeyCodes, NumericPadKeyCodes} from './enum/key-codes';

export class KbHelper {
    nonCharKeySet: any;
    
    constructor(private a: any, private b: any){
        this.nonCharKeySet = new Set();
        // tslint:disable-next-line:forin
        for (const d in KeyCodes) { KeyCodes.hasOwnProperty(d) && this.nonCharKeySet.add(KeyCodes[d]); }
    }

    isNonCharacterKey(a: any): any {
        return this.nonCharKeySet.has(a);
    }
    
    isCmdPressed(): any {
        return this.a.isMac && (void 0 !== this.b[KeyCodes.Cmd1] && this.b[KeyCodes.Cmd1] || void 0 !== this.b[KeyCodes.Cmd2] && 
            this.b[KeyCodes.Cmd2] || void 0 !== this.b[KeyCodes.Cmd3] && this.b[KeyCodes.Cmd3]);
    }

    /**
    * @deprecated
     */
    checkAndtranslateCmdToCtrl(b: any): any {
        return !this.a.isMac || b !== KeyCodes.Cmd1 && b !== KeyCodes.Cmd2 && b !== KeyCodes.Cmd3 ? b : KeyCodes.Ctrl;
    }
    
    convertToCRLF(a: any): any {
        return a.replace(/\r?\n/g, '\r\n');
    }


    isNumericPadKey(a: any): boolean {
        return a in NumericPadKeyCodes;
    }

    isCtrlAltCharacterShortCuts(a: any): boolean {
        return a in CtrlAltCharacterShortCuts;
    }
    
}

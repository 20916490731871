<div class="dialog-content-wrapper">
    <form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de Servidor Windows</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <mat-form-field *ngIf="adDomains && adDomains.length > 1" appearance="outline">
                <mat-label>AD Domain</mat-label>
                <mat-select formControlName="adDomainId">
                    <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                        {{adDomain.domainName}} | {{adDomain.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{myForm.get('adDomainId').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Hostname</mat-label>
                <input matInput formControlName="hostname">
                <mat-error>
                    {{myForm.get('hostname').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>ID do Client (na instalação do client)</mat-label>
                <input matInput formControlName="clientId">
                <mat-error>
                    {{myForm.get('clientId').errors | error}}
                </mat-error>
            </mat-form-field>
            <div class="client-server-url mb-30" *ngIf="!desktopServer.disposable">
                instale o client no servidor Windows:<br>
                <a href="{{domainPathService.apiURL}}server-agent-file/latest" target="_blank">
                    {{domainPathService.apiURL}}server-agent-file/latest</a>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>IP Externo</mat-label>
                <input matInput formControlName="publicIp">
                <mat-error>
                    {{myForm.get('publicIp').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>IP Interno</mat-label>
                <input matInput formControlName="privateIp">
                <mat-error>
                    {{myForm.get('privateIp').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>FQDN (dynDNS do server)</mat-label>
                <input matInput formControlName="connectionFQDN">
                <mat-error>
                    {{myForm.get('connectionFQDN').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>IP AD (Ip do servidor no AD)</mat-label>
                <input matInput formControlName="adIp">
                <mat-error>
                    {{myForm.get('adIp').errors | error}}
                </mat-error>
            </mat-form-field>

            <div class="mb-40 mt-10 pl-10" *ngIf="!desktopServer.disposable">
                <div class="title-list-fqdn">Lista de IP ou FQDN de possíveis saída do servidor</div>
                <table>
                    <tr>
                        <td *ngIf="firewallFQDNList.length > 0">
                            <div *ngFor="let firewallFQDN of firewallFQDNList" class="p-5 font-size-16">
                                {{firewallFQDN.fqdn}}
                            </div>
                        </td>
                        <td class="width-100-percent pl-10">
                            <button type="button" mat-raised-button
                                    (click)="openFirewallFQDNList()">
                                <span *ngIf="firewallFQDNList.length > 0">Editar Lista</span>
                                <span *ngIf="firewallFQDNList.length == 0">Inserir IP ou FQDN</span>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>


            <div class="mb-60 pl-10" *ngIf="userHasPermission">
                <div class="title-list-fqdn">R2 Security</div>
                <table>
                    <tr>
                        <td>
                            <div class="mb-15 mt-10">
                                <div style="color: red;font-size: 16px;margin-bottom: 10px;" *ngIf="gatewayServerNatConfig?.id && gatewayServerNatConfig.openAccess">Acesso liberado para todos</div>
                                <mat-slide-toggle *ngIf="gatewayServerNatConfig?.id" formControlName="gatewayServerNatConfigActive" name="gatewayServerNatConfigActive" class="mr-20">Ativo</mat-slide-toggle>
                                <span style="padding: 5px;border: solid #ddd 1px;" *ngIf="gatewayServerNatConfig?.id"><span>{{findGatewaySaverById(gatewayServerNatConfig.gatewayServerId)?.fqdn}}</span> : <b>{{gatewayServerNatConfig?.destinationPort}}</b></span>

                            </div>
                            <button *ngIf="gatewayServerNatConfig?.id" type="button" mat-raised-button (click)="openGatewayServerNatConfigModal()">
                                <span>Editar</span>
                            </button>
                            <button *ngIf="gatewayServerNatConfig?.id" type="button" style="margin-left: 26px" mat-raised-button (click)="gatewayServerNatConfigCheck()">
                                <span>Checar configurações</span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="!gatewayServerNatConfig?.id">
                        <td class="width-100-percent pl-10">
                            <button type="button" mat-raised-button
                                    (click)="openGatewayServerNatConfigModal()">
                                <span>Inserir R2 Security</span>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>


            <mat-form-field appearance="outline">
                <mat-label>RDP Port (deixar em branco para a padrão 3389)</mat-label>
                <input matInput formControlName="connectionPort"  pattern="[0-9]*"  max="65535">
                <mat-error *ngIf="myForm.get('connectionPort')?.errors?.pattern">
                    Por favor, insira apenas números.
                </mat-error>
                <mat-error *ngIf="myForm.get('connectionPort')?.errors?.required">
                    Este campo é obrigatório.
                </mat-error>
                <mat-error *ngIf="myForm.get('connectionPort')?.errors?.max">
                    Por favor, insira um número menor ou igual a 65535.
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>FQDN do gateway para HTML5</mat-label>
                <input matInput formControlName="gatewayFQDN">
                <mat-error>
                    {{myForm.get('gatewayFQDN')?.errors | error}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Porta de comunicação interna para HTML5 (padrão 3389)</mat-label>
                <input matInput formControlName="internalPort"  pattern="[0-9]*"  max="65535">
                <mat-error *ngIf="myForm.get('internalPort')?.errors?.pattern">
                    Por favor, insira apenas números.
                </mat-error>
                <mat-error *ngIf="myForm.get('internalPort')?.errors?.required">
                    Este campo é obrigatório.
                </mat-error>
                <mat-error *ngIf="myForm.get('internalPort')?.errors?.max">
                    Por favor, insira um número menor ou igual a 65535.
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Template para Plugins</mat-label>
                <mat-select placeholder="Templates" formControlName="serverPluginTemplateIds" multiple>
                    <mat-option *ngFor="let serverPluginTemplate of serverPluginService.serverPluginTemplate$ | async"
                                [value]="serverPluginTemplate.id">
                        {{serverPluginTemplate.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width min-width-230">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Observações</mat-label>
                    <textarea rows="10" matInput formControlName="comment" class="full-width"></textarea>
                </mat-form-field>
            </mat-form-field>


            <div fxLayout="row" fxLayoutAlign="space-between" fxFill class="p-16 mb-40">
                <mat-slide-toggle formControlName="active" name="active" style="margin-bottom: 40px;">
                    Ativo
                </mat-slide-toggle>
            </div>


        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="desktopServer;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>

            <button *ngIf="desktopServer?.id" type="button" mat-raised-button color="warn" (click)="onDelete()">
                Remover do Projeto
            </button>

            <button type="button" mat-raised-button color="warn"
                    (click)="onReallyDelete()">Deletar
            </button>

            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

            <div *ngIf="desktopServer">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px" >
                    <button mat-menu-item (click)="onAgentUpdate(desktopServer?.id)">
                        <mat-icon>update</mat-icon>
                        <span>Atualizar Agente</span>
                    </button>
                    <button mat-menu-item (click)="onGetLocalAdmin(desktopServer?.id)">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Senha R2LocalAdmin</span>
                    </button>
                    <button mat-menu-item (click)="openDesktopServerLocalAdminCredentials()">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Editar credenciais local admin</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: desktopServer; type: 'DesktopServer'"
                            (click)="onJaversHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: desktopServer; type: 'DesktopServer'"
                            (click)="onJaversAllHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico completo</span>
                    </button>
                    <button mat-menu-item (click)="onSyncServer()">
                        <mat-icon>sync</mat-icon>
                        <span>Sincronizar</span>
                    </button>
                    <button mat-menu-item (click)="onGenerateNewPrivateKey()">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Nova Chave</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </form>
</div>

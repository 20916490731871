<div fxLayout="column" class="mt-26">
    <form *ngIf="myForm" name="iam-form" [formGroup]="myForm"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" id="iamcreate-edit-form" >
        <mat-card>
            <mat-card-header>
                <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                    <div> IAM</div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" id="iamcreate-edit-name" >
                        <mat-error>
                            {{myForm.get('name').errors | error}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput formControlName="comment" id="iamcreate-edit-comment" >
                        <mat-error>
                            {{myForm.get('comment').errors | error}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Filtro</mat-label>
                        <input matInput (keyup)="applyFilter($event.target.value)" id="iamcreate-edit-filter" >
                    </mat-form-field>
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef style="padding-right: 8px ">
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row) : null"
                                              [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
                            <td mat-cell *matCellDef="let row"> {{row?.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição</th>
                            <td mat-cell *matCellDef="let row"> {{row?.comment}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <button type="button" class="width-100-percent mt-16 green-400-bg"
                            id="iamcreate-edit-add-permissions"
                            mat-raised-button color="green-bg" (click)="onAddPermissionsModal()">Adicionar Permissões</button>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>

                <mat-slide-toggle formControlName="active" name="active">
                    Ativo
                </mat-slide-toggle>

            </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

                <button type="submit" color="accent" mat-raised-button
                        id="iamcreate-edit-create"
                        [disabled]="!myForm.valid || myForm.pristine">
                    <ng-container *ngIf="myForm.value.id; else newRole">
                        Salvar
                    </ng-container>
                    <ng-template #newRole>
                        Criar
                    </ng-template>
                </button>
                <button type="button" mat-raised-button
                        id="iamcreate-edit-cancel"
                        (click)="onCancel()" color="primary">Cancelar</button>


                <div *ngIf="role?.id">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" style="max-width: 372px" >
                        <button mat-menu-item *appIfPermission="'AUDIT'; data: role; type: 'Role'"
                                (click)="onJaversHistory()">
                            <mat-icon>history</mat-icon>
                            <span>Histórico</span>
                        </button>
                        <button mat-menu-item *appIfPermission="'AUDIT'; data: role; type: 'Role'"
                                (click)="onJaversAllHistory()">
                            <mat-icon>history</mat-icon>
                            <span>Histórico completo</span>
                        </button>
                    </mat-menu>
                </div>


            </div>
        </mat-card>
    </form>
</div>
import {Injectable, OnDestroy} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {Permission} from '../../models';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable, Subject} from 'rxjs';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {CacheControl} from '../../helpers/CacheControl';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionDAOService extends BaseDao<Permission> implements OnDestroy, CacheControl {
    allPermissions$: Observable<Permission[]>;
    private onDestroy$ = new Subject();

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'permission');
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    setCacheControl(ob: Observable<any>): void {
        ob.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.clearCache();
        });
    }

    clearCache(): void {
        // this.allPermissions$ = of([]);
        this.allPermissions$ = null;
    }

    get(): Observable<Permission[]> {
        if (!this.allPermissions$) {
            this.allPermissions$ = super.get().pipe(shareReplay(1));
        }
        return this.allPermissions$;
    }
}

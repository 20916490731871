import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {DesktopServerPoolDaoService} from './desktop-server-pool-dao.service';
import {DesktopServerPoolEditComponent} from './desktop-server-pool-edit/desktop-server-pool-edit.component';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DesktopServerPoolChoiceComponent} from './desktop-server-pool-choice/desktop-server-pool-choice.component';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatSelectModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatCardModule,
        MatMenuModule,
        MatTooltipModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        NgxMatSelectSearchModule
    ],
    declarations: [DesktopServerPoolEditComponent, DesktopServerPoolChoiceComponent],
    providers: [DesktopServerPoolDaoService],
    entryComponents: []
})
export class DesktopServerPoolModule {
}

import {Component, Inject, OnInit} from '@angular/core';
import {CustomerGroup, GatewayServer, GatewayServerNatConfig, ServerConnectionType} from '../../../../models';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GatewayServerDaoService} from '../../gateway-server-dao.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../../component-cleaner';
import {convertToFormGroup, CrudOperationWrapper, markAsTouched} from '../../../../helpers/kluh';
import {GatewayServerNatConfigDaoService} from '../gateway-server-nat-config-dao.service';
import {Observable} from 'rxjs/internal/Observable';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';

@Component({
    selector: 'app-gateway-server-nat-config-modal',
    templateUrl: './gateway-server-nat-config-modal.component.html',
    styleUrls: ['./gateway-server-nat-config-modal.component.scss']
})
export class GatewayServerNatConfigModalComponent extends ComponentCleaner implements OnInit {

    gatewayServers: GatewayServer[] = [];
    customerGroups: CustomerGroup[] = [];
    gatewayServer: GatewayServer;
    ServerConnectionType = ServerConnectionType;
    gatewayServerNatConfig: GatewayServerNatConfig;
    sending = false;
    myForm: FormGroup;
    gatewayServerForm: FormControl = new FormControl();
    customerGroupForm: FormControl = new FormControl();
    activateCustomPortControl: FormControl = new FormControl();
    changePassword = false;
    canEditList: PermissionWrapper[];
    customerGroupFormHasChange = false;

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private adminService: R2CloudAdminService,
        private gatewayServerDaoService: GatewayServerDaoService,
        private gatewayServerNatConfigDaoService: GatewayServerNatConfigDaoService,
        private customerGroupService: CustomerGroupService,
        public dialogRef: MatDialogRef<GatewayServerNatConfigModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
        this.canEditList =
            [
                {type: 'GatewayServerNatConfig', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
                {type: 'GatewayServerNatConfig', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject}
            ];
        this.gatewayServerNatConfig = data.gatewayServerNatConfig;
        this.gatewayServerForm.setValue(this.gatewayServerNatConfig.gatewayServerId);
        if (!this.gatewayServerNatConfig.protocol) {
            this.gatewayServerNatConfig.protocol = 'tcp';
        }
        this.myForm = this.fb.group(convertToFormGroup(this.gatewayServerNatConfig), {asyncValidator: this.gatewayServerNatConfigDaoService.validator});
        this.myForm.get('destinationPort').setValidators([Validators.required, Validators.min(4000), Validators.max(6000)]);

        this.customerGroups = this.customerGroupService.customerGroups;

        if (this.gatewayServerNatConfig.id) {
            if (this.gatewayServerNatConfig.serverConnectionCustomPort && this.gatewayServerNatConfig.serverConnectionCustomPort > 0) {
                this.activateCustomPortControl.setValue(true);
            }
            this.gatewayServerDaoService.getOne(this.gatewayServerNatConfig.gatewayServerId).subscribe((gatewayServer) => {
                if (gatewayServer) {
                    this.gatewayServer = gatewayServer;
                    this.customerGroupForm.setValue(gatewayServer?.customerGroupId);
                    this.findGatewayServerByCustomerGroupId(gatewayServer.customerGroupId);
                }
            });
        } else {
            this.customerGroupForm.setValue(this.customerGroupService?.customerGroup?.id);
            this.customerGroupFormHasChange = true;
            if (this.customerGroupService?.customerGroup?.id) {
                this.findGatewayServerByCustomerGroupId(this.customerGroupService?.customerGroup?.id);
            }
        }
    }

    ngOnInit(): void {
        this.addSubscription(this.addSubscription(this.gatewayServerForm.valueChanges.subscribe(gatewayServerId => {
            this.myForm.get('gatewayServerId').setValue(gatewayServerId);
            markAsTouched(this.myForm);
        })));
        this.addSubscription(this.addSubscription(this.customerGroupForm.valueChanges.subscribe(customerGroupId => {
            if (this.customerGroupFormHasChange) {
                this.findGatewayServerByCustomerGroupId(customerGroupId);
                this.gatewayServerForm.setValue(null);
            }
            this.customerGroupFormHasChange = true;
        })));
        this.activateCustomPortControl.valueChanges.subscribe(_ => {
            markAsTouched(this.myForm);
        });
    }

    onRemove(): void {

        this.addSubscription(
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: '<div>Tem certeza que deseja remover essa configuração do R2 Security desse servidor? ' +
                        '<br><br> Para remover digite <u>DELETE</u> no campo abaixo</div>',
                    disableCancel: false,
                    cancelButtonValue: 'Cancelar',
                    confirmButtonValue: 'Deletar',
                    icon: 'error_outline',
                    confirmFieldValue: 'DELETE'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.gatewayServerNatConfigDaoService.suspendDelete(this.gatewayServerNatConfig).subscribe(_ => {
                        const crudOperation: CrudOperationWrapper = {
                            data: null,
                            operation: 'DELETE'
                        };
                        this.dialogRef.close(crudOperation);
                    });
                }
            })
        );
    }

    onForceRemove(): void {
        this.addSubscription(
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: '<h1 class="warning">Cuidado</h1><div>Tem certeza que deseja deletar apenas do banco de dados a configuração com o R2 Security desse servidor? ' +
                        '<br><br> Para deletar digite <u>DELETE</u> no campo abaixo</div>',
                    disableCancel: false,
                    cancelButtonValue: 'Cancelar',
                    confirmButtonValue: 'Deletar',
                    icon: 'error_outline',
                    confirmFieldValue: 'DELETE'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.gatewayServerNatConfigDaoService.remove(this.gatewayServerNatConfig.id).subscribe(_ => {
                        const crudOperation: CrudOperationWrapper = {
                            data: null,
                            operation: 'DELETE'
                        };
                        this.dialogRef.close(crudOperation);
                    });
                }
            })
        );

    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onResend(): void {
        const message = '<h2>Deseja reenviar as configurações do Gataway Shield R2 desse servidor?</h2>';
        const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: message,
                disableCancel: false,
                icon: 'info_outline',
                confirmButtonValue: 'Reenviar configuração',
                cancelButtonValue: 'Cancelar'
            }
        });
        matDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onSubmit();
            } else {
                this.dialogRef.close();
            }
        });
    }

    onSubmit(): void {
        this.sending = true;
        this.gatewayServerNatConfig = this.myForm.value;
        if (!this.activateCustomPortControl.value) {
            this.gatewayServerNatConfig.serverConnectionCustomPort = null;
        }
        let service$: Observable<GatewayServerNatConfig>;
        if (this.gatewayServerNatConfig?.id) {
            service$ = this.gatewayServerNatConfigDaoService.suspendSave(this.gatewayServerNatConfig);
        } else {
            service$ = this.gatewayServerNatConfigDaoService.suspendCreate(this.gatewayServerNatConfig);
        }

        service$.subscribe((result) => {
            const crudOperation: CrudOperationWrapper = {
                data: result,
                operation: 'SAVE'
            };
            this.sending = false;
            this.dialogRef.close(crudOperation);
            console.log('gatewayServerNatConfig result', result);
        });

    }

    private findGatewayServerByCustomerGroupId(customerGroupId: number): void {
        const customerGroup = this.customerGroups.find(x => x.id === customerGroupId);
        if (customerGroup) {
            this.findGatewayServerByCustomerGroup(customerGroup);
        }
        if (this.gatewayServer) {
            const index = this.gatewayServers.findIndex(x => x.id === this.gatewayServer.id);
            if (index < 0) {
                this.gatewayServers.push(this.gatewayServer);
            }
        }
    }

    private findGatewayServerByCustomerGroup(customerGroup: CustomerGroup): void {
        this.gatewayServerDaoService.findAllBy(customerGroup.id, customerGroup.name).subscribe((gatewayServers) => {
            if (gatewayServers) {
                this.gatewayServers = gatewayServers;
            }
        });
    }

    isValidCustomPort(): boolean {
        const serverConnectionCustomPort = this.myForm.get('serverConnectionCustomPort')?.value;
        const activateCustomPortControl = this.activateCustomPortControl?.value;
        return !(!serverConnectionCustomPort && activateCustomPortControl);
    }

}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../../base-dao';
import {HipcomBulkUpdateLatestSoftwareVersion} from '../../../../../models';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';

@Injectable()
export class HipcomBulkUpdateLatestSoftwareVersionDaoService extends BaseDao<HipcomBulkUpdateLatestSoftwareVersion> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'partners-hipcom-bulk-update-latest-software-version');
    }

    readLastSoftware(): Observable<void> {
        return this.http.get<void>(this.url + '/read-last-software');
    }

}

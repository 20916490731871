import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {Customer, CustomerGroup, DigitalCertificate} from '../../models';
import {filter} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../component-cleaner';
import {DigitalCertificateDaoService} from './digital-certificate-dao.service';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {SendDigitalCertificateComponent} from './send-digital-certificate/send-digital-certificate.component';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {PermissionWrapper} from '../../directives/if-permission.directive';

@Component({
    selector: 'app-digital-certificate',
    templateUrl: './digital-certificate.component.html',
    styleUrls: ['./digital-certificate.component.scss']
})
export class DigitalCertificateComponent extends ComponentCleaner implements OnInit {
    displayedColumns: string[] = ['name', 'size', 'expirationDate', 'createdAt', 'action'];
    dataSource: DigitalCertificateDaoService;
    appCanInstallCertificate: PermissionWrapper[];
    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    totalDocs = 0;

    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    subProjectControl: FormControl = new FormControl();
    desktopServerControl: FormControl = new FormControl();
    customers: Customer[] = [];
    customerGroup: CustomerGroup;


    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<DigitalCertificateComponent>,
        public customerGroupService: CustomerGroupService,
        public digitalCertificateDaoService: DigitalCertificateDaoService,
    ) {
        super();

        this.appCanInstallCertificate = [
            {type: 'DigitalCertificateInstall', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
    }

    ngOnInit(): void {
        this.dataSource = this.digitalCertificateDaoService;
        this.dataSource.clearAllData();
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.customerGroup = customerGroup;
                this.findUploadFileByCustomerGroupId(this.customerGroup.id);
            }
        });
        this.digitalCertificateDaoService.total$.subscribe(total => {
            this.totalDocs = total;
        });
    }

    openFileInput(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<div class="pl-15 pb-20"><h2>Orientações gerais para adicionar o certificado</h2>' +
                    '<div>- Serão aceitos apenas certificados com a extensão ".pfx".</div>' +
                    '<div>- Serão aceitos apenas certificados com o tamanho máximo de até 50MB.</div>' +
                    '<div><b>- Não colocar a senha no nome do arquivo.</b></div>' +
                    '<div>- Para facilitar na identificação, recomendamos que o nome do arquivo seja no formato a seguir:<br>CNPJ XX.XXX.XXX.XXXX.XX</div></div>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                cancelButtonValue: 'Cancelar',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.fileInput.nativeElement.click();
            }
        });
    }

    onAddFiles(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        this.handleFiles(currentTarget.files);
    }

    private clearInputFile(): void {
        this.fileInput.nativeElement.value = null;
    }

    private handleFiles(files: FileList): void {
        this.openSendingFilesModal(files);
    }

    openSendingFilesModal(files: FileList): void {
        if (files.length > 0) {
            const dialogRef = this.dialog.open(SendDigitalCertificateComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog-x-large',
                data: {
                    files: files,
                    customerGroupId: this.customerGroup.id,
                    desktopServerId: +this.desktopServerControl.value,
                    subProjectId: +this.subProjectControl.value
                }
            });
            const subscription = dialogRef.afterClosed().subscribe((result) => {
                this.clearInputFile();
                if (result) {
                    this.findUploadFileByCustomerGroupId(this.customerGroup.id);
                }
            });
            this.addSubscription(subscription);
        }
    }

    showDigitalCertificateDetailsModal(digitalCertificate: DigitalCertificate): void {
        const dialogRef = this.dialog.open(SendDigitalCertificateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                digitalCertificate: digitalCertificate,
                customerGroupId: digitalCertificate.customerGroupId,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result) => {
        });
        this.addSubscription(subscription);
    }

    private findUploadFileByCustomerGroupId(customerGroupId: number): void {
        const pageEvent = {length: 0, pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize};
        this.dataSource.load('customerGroupId', customerGroupId.toString(), pageEvent);
    }

    isDateExpired(dateString: string, daysToCompare: number): boolean {
        const date = new Date(dateString);
        const today = new Date();
        const expirationDate = new Date(today);
        expirationDate.setDate(today.getDate() + daysToCompare);
        return date <= expirationDate;
    }

    openDigitalCertificateDeleteModal(digitalCertificate: DigitalCertificate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: '' +
                    'Tem certeza que deseja <b><u>DELETAR</u></b> o ' +
                    'Certificado <b><u>"' + digitalCertificate.name + '"</u></b>? ' +
                    '<br><br>Para deletar digite <u>DELETE</u> no campo abaixo',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Deletar',
                icon: 'error_outline',
                confirmFieldValue: 'DELETE'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.digitalCertificateDaoService.onRemove(digitalCertificate).subscribe((removed) => {
                    this.dialog.closeAll();
                    this.digitalCertificateDaoService.removeItem(digitalCertificate.id);
                });
            } else {
                this.dialog.closeAll();
            }
        });
    }

}

import {Injectable} from '@angular/core';

@Injectable()
export class DataShareService {
  private forgotPasswordEmail: string;

  constructor() { }

  setForgotPasswordEmail(email: string): void {
    this.forgotPasswordEmail = email;
  }

  getForgotPasswordEmail(): string {
    if (this.forgotPasswordEmail){
      return this.forgotPasswordEmail;
    } else {
      return '';
    }
  }

}

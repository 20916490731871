import {ChartPoint} from '../../../models';
import {MetricType} from '../../../models-monitoring';
import {Subscription} from 'rxjs';
import {PointOptionsType} from 'highcharts';
import {Subject} from 'rxjs/internal/Subject';
import {getMomentForChart} from './helper/helper';
import {BaseIdLong, BaseMonitoringDao} from '../../../base-monitoring-dao';
import {MonitoringStompService} from '../websocket-stomp/monitoring-stomp.service';

export abstract class ChartDataService<T extends BaseMonitoringDao<BaseIdLong>> {

    private subscriptions: Subscription[] = [];

    protected constructor(
        protected topic: string,
        protected service: T,
        protected webStompService: MonitoringStompService
    ) {
    }

    loadFromDbAndWebSocket(begin: Date, end: Date, metricType: MetricType, metricId: number, serverId: number, resolution: number
        , chartPointList: ChartPoint[], dataSubject: Subject<PointOptionsType[]>): void {
        this.loadDataFromDB(begin, end, metricType, metricId, serverId, resolution, chartPointList, dataSubject);
    }

    private loadDataFromDB(begin: Date, end: Date, metricType: MetricType, metricId: number, serverId: number, resolution: number
        , chartPointList: ChartPoint[], dataSubject: Subject<PointOptionsType[]>): void {
        const momentForChart = getMomentForChart(begin, end);
        this.service
            .findByServerIdAndMetricTypeAndRangeOfCreatedAt(
                metricId,
                metricType,
                momentForChart.begin,
                momentForChart.end,
                resolution
            )
            .subscribe(chartPoints => {
                this.addDataToChart(chartPoints, chartPointList, dataSubject);
                if (momentForChart.diffMinutes < 100) {
                    this.loadDataFromWebsocket(metricType, metricId, serverId, chartPointList, dataSubject);
                } else {
                    this.unSubscribe();
                }
            });
    }

    private loadDataFromWebsocket(metricType: MetricType, metricId: number, serverId: number, chartPointList: ChartPoint[], dataSubject: Subject<PointOptionsType[]>): void {
        if (metricId) {
            this.subscriptions.push(this.webStompService.stompSubscribe<ChartPoint[]>(
                '/topic/' + this.topic + '.' + metricType + '.' + metricId + '.' + serverId)
                .subscribe((chartPoints) => {
                    this.addDataToChart(chartPoints, chartPointList, dataSubject);
                }));
        }
    }

    private addDataToChart(chartPoints: ChartPoint[], chartPointList: ChartPoint[], dataSubject: Subject<PointOptionsType[]>): void {
        if (chartPoints) {
            chartPoints.map((cp) => {
                chartPointList.push(cp);
            });
            dataSubject.next(chartPointList.map((cp) => {
                return {
                    x: cp.x,
                    y: Math.round(cp.y)
                } as PointOptionsType;
            }));
        }
    }

    unSubscribe(): void {
        if (this.subscriptions) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
            this.subscriptions = [];
        }
    }
}

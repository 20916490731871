import {Injectable} from '@angular/core';
import {MarketplaceOrderComment} from '../../../../models';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceOrderCommentService {
    constructor() {
    }
    initMarketplaceOrderComment(): MarketplaceOrderComment {
        return {
            id: null,
            active: true,
            comment: null,
            marketplaceOrderId: null,
            email: null,
            managerUserId: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
    }

    findById(id: number, marketplaceOrderCommentList: MarketplaceOrderComment[]): MarketplaceOrderComment {
        const index = marketplaceOrderCommentList.findIndex((o) => o.id === id);
        if (index > -1) {
            return marketplaceOrderCommentList[index];
        }
        return null;
    }
}

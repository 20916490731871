import {Injectable} from '@angular/core';
import {ChartDataService} from '../../chart-data.service';
import {HdUsageDaoService} from './hd-usage-dao.service';
import {MonitoringStompService} from '../../../websocket-stomp/monitoring-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class HdUsageService extends ChartDataService<HdUsageDaoService> {
    constructor(
        webStompService: MonitoringStompService,
        service: HdUsageDaoService,
    ) {
        super(
            'HdUsage',
            service,
            webStompService
        );
    }
}

import {Component} from '@angular/core';
import {AuthService} from '../../auth.service';
import {of} from 'rxjs';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';

import {ChangePasswordComponent, ChangePasswordModalResult} from './change-password/change-password.component';
import {fuseAnimations} from '../../../../@fuse/animations';
import {ComponentCleaner} from '../../component-cleaner';
import {filter, switchMap} from 'rxjs/operators';
import {FirebaseUserDetails, ManagerUser} from '../../models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, markAsTouched, Province} from '../../helpers/kluh';
import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {ImageFileService} from '../image-file/image-file-service';
import {ProvinceService} from '../../helpers/province.service';
import {BackgroundImageService} from '../../helpers/background-image.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {createWaitModal} from '../../helpers/wait-dialog/wait-dialog.component';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {Observable} from 'rxjs/internal/Observable';
import {ValueWrapper} from '../../helpers/value-wrapper';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: fuseAnimations
})
export class ProfileComponent extends ComponentCleaner {
    user: FirebaseUserDetails;
    changePasswordWrongOldPassword: boolean;
    changePasswordSuccess: boolean;
    form: FormGroup;
    // imageFile: ImageFile;
    imageFileElement: any = null;
    provinceList: Province[];
    // addressNumberMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    // phoneMask = ['(', /\d/, /\d/, ')', '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    // cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

    constructor(private auth: AuthService,
                private userAuthorityDao: UserAuthorityDaoService,
                private dialog: MatDialog,
                private _formBuilder: FormBuilder,
                private managerUserDaoService: ManagerUserDaoService,
                public imageFileService: ImageFileService,
                private backgroundImage: BackgroundImageService,
                private provinceService: ProvinceService,
                private router: Router,
                private authService: AuthService) {
        super();
        this.provinceList = this.provinceService.provinceList;
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
            if (this.user.credentialsExpiring) {
                const subscription = this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: 'Sua senha expirou, por favor escolha uma nova senha.',
                        disableCancel: true,
                        icon: 'error_outline',
                        confirmButtonValue: 'OK'
                    }
                }).afterClosed().subscribe(() => {
                    this.onChangePassword();
                });
                this.addSubscription(subscription);
            }

            const emailForm = this._formBuilder.control(this.user.managerUser?.email, [Validators.email]);
            emailForm.disable();
            const managerUserForm = this._formBuilder.group(convertToFormGroup(this.user.managerUser));
            this.form = this._formBuilder.group({
                managerUserForm: managerUserForm,
                emailForm: emailForm
            });
        });
    }

    onChangePassword(): void {
        console.debug('onChangePassword');
        const subscription = this.dialog.open(ChangePasswordComponent, {
            disableClose: true
        }).afterClosed().pipe(
            filter((x) => !!(x)),
            switchMap((result: ChangePasswordModalResult) => {
                console.debug('ChangePasswordModalResult');
                const ob = this.auth.reloginWithPassword(result.oldPassword).pipe(
                    switchMap(() => of(result.newPassword)),
                    switchMap((newPassword: string) => {
                        console.debug('reloginWithPassword ok');
                        this.changePasswordWrongOldPassword = false;
                        return this.auth.changePassword(newPassword);
                    }),
                );
                return createWaitModal<boolean>(this.dialog, ob,
                    {
                        waitMilliSeconds: 3000,
                        disableCancel: true,
                        message: 'Por favor, aguarde.',
                        icon: null
                    });
            }))
            .subscribe(() => {
                console.debug('changePassword firebase ok');
                this.changePasswordSuccess = true;
                this.authService.logoutAfterChangePassword();
            }, error => {
                console.debug(`reloginWithPassword error: ${error}`);
                if (error) {
                    if (error.code === 'auth/wrong-password') {
                        this.changePasswordWrongOldPassword = true;
                        const subscription1 = this.dialog.open(ConfirmDialogComponent, {
                            disableClose: true,
                            data: {
                                message: 'Sua senha antiga foi recusada.',
                                disableCancel: true,
                                icon: 'warning',
                                confirmButtonValue: 'OK'
                            }
                        }).afterClosed().subscribe(() => {
                            this.onChangePassword();
                        });
                        this.addSubscription(subscription1);
                        return;
                    }
                }
                alert('error: ' + JSON.stringify(error));
            });
        this.addSubscription(subscription);
    }


    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget && currentTarget.files && currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.form);
        }
    }

    onSubmit(): void {
        const managerUser: ManagerUser = this.form.get('managerUserForm').value;
        this.managerUserDaoService.save(managerUser).pipe(switchMap((managerUserFromDAO) => {
            this.form.get('managerUserForm').setValue(managerUserFromDAO);
            this.form.markAsPristine();
            let imageFile$: Observable<ValueWrapper>;
            const image = this.imageFileElement;
            if (image) {
                imageFile$ = this.imageFileService.saveManagerUserPicture(managerUserFromDAO.id, image);
            } else {
                imageFile$ = of(null);
            }
            return forkJoin([imageFile$, of(managerUserFromDAO)]);
        })).subscribe((result) => {
            const managerUserFromDao = result[1];
            if (this.imageFileElement) {
                managerUserFromDao.imageUUID = result[0].value;
                this.imageFileElement = null;
            }
            this.user.managerUser = managerUserFromDao;
            this.updateFirebaseUserProfile(managerUserFromDao.displayName, this.imageFileService.getManagerUserPictureUrl(this.user.managerUser.imageUUID));
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: '<br>Dados alterados com sucesso.<br>',
                    disableCancel: true,
                    icon: 'info_outline',
                    confirmButtonValue: 'OK'
                }
            });
        });
    }

    private updateFirebaseUserProfile(name: string, picture: string): void {
        this.authService.updateProfile(name, picture);
    }

    changeTwoFacture(): void {
        const managerUser: ManagerUser = this.form.get('managerUserForm').value;
        if (managerUser.twoFactorAuth) {
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Se você não tiver acesso ao e-mail ( <b>' + this.user.managerUser.email + '</b> ),<br>você não conseguirá fazer o login. ',
                    disableCancel: false,
                    confirmButtonValue: 'Ativar',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                managerUser.twoFactorAuth = !!result;
                this.form.get('managerUserForm').setValue(managerUser);
            });
        }
    }
}

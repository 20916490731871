import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorSnackBarComponent} from './error-snack-bar/error-snack-bar-component';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {KluhSharedModule} from '../kluh-shared.module';
import {ErrorDetailsComponent} from './error-details/error-details.component';
import {FuseHighlightModule} from '../../../@fuse/components';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        KluhSharedModule,
        MatButtonModule,
        MatSnackBarModule,
        FuseHighlightModule,
        MatDialogModule
    ],
    declarations: [ErrorSnackBarComponent, ErrorDetailsComponent],
    entryComponents: [ErrorSnackBarComponent, ErrorDetailsComponent]
})
export class ErrorModule {
}

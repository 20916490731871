<div class="page-layout carded fullwidth overflow-y-scroll inner-scroll" #contentRef *ngIf="user && marketplaceOrder">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
         style="z-index: 99;margin-top: 25px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="mr-10 font-size-24">shopping_basket</mat-icon>
            <span class="h1 m-0" *ngIf="marketplaceProduct">{{marketplaceProduct.name}} </span>
        </div>
    </div>
    <div style="margin-top: 50px;">
        <div class="content">
            <div fxLayout="row" fxLayoutAlign="start stretch" class="width-100-percent">
                <mat-card class="card m-20" style="width: 50%;" *ngIf="marketplaceOrder">
                    <mat-card-header>
                        <mat-card-title class="mb-10" fxLayoutAlign="space-between stretch">
                            <div>Descrição do pedido</div>
                            <div>id: #{{marketplaceOrder.id}}</div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="mt-10">
                            {{marketplaceOrder.description}}
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card m-20" *ngIf="marketplaceOrder"
                          style="width: 50%;">
                    <mat-card-header class="mb-10">
                        <mat-card-title>
                            <div>Dados do cliente</div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div *ngIf="customerGroupOfManagerUserOfMarketplaceOrder; else notFound">
                            <div fxLayoutAlign="start stretch" class="order-box-user-detail">
                                <div>
                                    <img
                                        src="{{imageFileService.getManagerUserPicture(managerUserOfMarketplaceOrder)}}?w=80&square=true"/>
                                </div>
                                <div class="ml-15">
                                    <div class="mb-15">
                                        <div class="top">Nome:</div>
                                        <div class="desc">{{managerUserOfMarketplaceOrder?.displayName}}</div>
                                    </div>
                                    <div fxLayoutAlign="start stretch">
                                        <div>
                                            <div class="top">Telefone:</div>
                                            <div class="desc">{{managerUserOfMarketplaceOrder?.phone}}</div>
                                        </div>
                                        <div class="ml-25">
                                            <div class="top">E-mail:</div>
                                            <div class="desc">{{managerUserOfMarketplaceOrder?.email}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayoutAlign="start stretch" class="order-box-user-detail mt-30">
                                <div>
                                    <img
                                        src="{{imageFileService.getCustomerGroupPictureUrl(customerGroupOfManagerUserOfMarketplaceOrder?.imageUUID, 80)}}"/>
                                </div>
                                <div class="ml-15">
                                    <div class="mb-15">
                                        <div class="top">Nome da empresa:</div>
                                        <div class="desc">{{customerGroupOfManagerUserOfMarketplaceOrder?.name}}</div>
                                    </div>
                                    <div fxLayoutAlign="start stretch">
                                        <div>
                                            <div class="top">Telefone:</div>
                                            <div class="desc">{{customerOfManagerUserOfMarketplaceOrder?.phone}}</div>
                                        </div>
                                        <div class="ml-25">
                                            <div class="top">Filial:</div>
                                            <div class="desc">{{customerOfManagerUserOfMarketplaceOrder?.name}}</div>
                                            <small>{{customerOfManagerUserOfMarketplaceOrder?.city}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #notFound>
                            <div fxLayoutAlign="start stretch" class="order-box-user-detail">
                                <div>
                                    <img src="{{imageFileService.getManagerUserPictureUrl(null)}}"/>
                                </div>
                                <div class="ml-15">
                                    <div class="mb-15">
                                        <div class="top">Email:</div>
                                        <div class="desc">{{marketplaceOrder?.email}}</div>
                                        <div class="desc warn-A700-fg">Usuário excluído</div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </mat-card-content>
                </mat-card>
            </div>

            <mat-card class="card date-read-box">
                <div fxLayoutAlign="space-between stretch" class="width-100-percent">
                    <div>
                        <div>Pedido realizado em:</div>
                        <div><small>{{marketplaceOrder.createdAt  | date: 'dd/MM/yyyy HH:mm'}}</small></div>
                    </div>
                    <div>
                        <div>Última leitura do cliente:</div>
                        <div><small>{{marketplaceOrder.readOrderLastDate  | date: 'dd/MM/yyyy HH:mm'}}</small></div>
                    </div>
                    <div>
                        <div>Última leitura da empresa:</div>
                        <div><small>{{marketplaceOrder.producerReadOrderLastDate  | date: 'dd/MM/yyyy HH:mm'}}</small>
                        </div>
                    </div>
                </div>
            </mat-card>

            <mat-card class="card m-20">
                <h2 class="mt-50 ml-15">Mensagens</h2>
                <app-marketplace-order-comment-list
                    [marketplaceRequestCommentList]=marketplaceRequestCommentList
                    [user]=user
                ></app-marketplace-order-comment-list>
            </mat-card>
        </div>
        <div class="send-comment">
            <div fxLayout="row" fxLayoutAlign="start start" class="inner-comment">
                <div style="width: 600px;">
                    <div fxLayout="row" fxLayoutAlign="start start"
                         *ngIf="!(marketplaceOrder.concluded || marketplaceOrder.cancel)">
                        <img class="avatar" src="{{imageFileService.getManagerUserPicture(user.managerUser)}}?w=40&square=true"/>
                        <form fxFlex [formGroup]="myForm"
                              id="marketplace-order-detail-comment-form"
                              (submit)="myForm.valid && myForm.dirty && onSubmit()"
                              autocomplete="off">
                                <textarea placeholder="coloque seu comentário..."
                                          id="marketplace-order-detail-comment-comment"
                                          formControlName="comment"
                                          class="width-100-percent"></textarea>
                            <button type="submit" mat-raised-button color="accent"
                                    id="marketplace-order-detail-comment-send"
                                    [disabled]="(!myForm.valid || myForm.pristine)">Enviar comentário
                            </button>
                        </form>
                    </div>
                    <div *ngIf="(marketplaceOrder.concluded || marketplaceOrder.cancel)" style="height: 104px;">
                        <h2 *ngIf="marketplaceOrder.cancel">
                            Pedido cancelado: {{marketplaceOrder.cancelDate | date: 'dd/MM/yyyy HH:mm'}}
                        </h2>
                        <h2 *ngIf="marketplaceOrder.concluded">
                            Pedido concluído: {{marketplaceOrder.concludedDate | date: 'dd/MM/yyyy HH:mm'}}
                        </h2>
                    </div>
                </div>
            </div>
            <div class="action-buttons">
                <div class="inner-action">
                    <div *ngIf="!(marketplaceOrder.concluded || marketplaceOrder.cancel)" fxLayout="row"
                         fxLayoutAlign="center center">
                        <button mat-raised-button (click)="onMarkAsUnread()" class="mr-10">Marcar como não lido
                        </button>
                        <button mat-raised-button (click)="onCancel()" color="warn">Cancelar pedido</button>
                        <button mat-raised-button (click)="onComplete()" class="ml-10" *ngIf="isProducerProduct()">
                            Concluir
                        </button>
                    </div>
                    <div *ngIf="(marketplaceOrder.cancel)" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button (click)="onReopenOrder()">Reabrir pedido</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

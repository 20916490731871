import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-uptime-server',
  templateUrl: './server-uptime.component.html',
  styleUrls: ['./server-uptime.component.scss']
})
export class ServerUptimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

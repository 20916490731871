<app-clip-text-box [sessionView]="sessionView"></app-clip-text-box>
<a target="_blank" href=javascript:void(0) id=printDownloadLink tabindex=-1 aria-hidden=true></a>
<div fxLayout="row" fxLayoutAlign="start stretch" class="main-container" style="background: transparent;">
    <div fxLayout="column" fxLayoutAlign="start stretch" class="fuse-navy-900 app-sidebar">
        <div class="remote-app-hover remote-app-icon-bg" id="session-view-go-home" (click)="goToHome()">
            <mat-icon class="arrow-back-icon">arrow_back</mat-icon>
        </div>
        <mat-progress-spinner *ngIf="loading; else loaded" diameter="27" mode="indeterminate" color="accent" class="progress-spinner"></mat-progress-spinner>
        <ng-template #loaded>
            <div class="apps-drawer" fxLayout="column" fxLayoutAlign="center center" (click)="openAppDrawer()">
                <mat-icon [inline]="true">apps</mat-icon>
            </div>
        </ng-template>
        <ng-container *ngFor="let item of getSessionsStore() | keyvalue">
            <!--{{onDebug(item)}}-->
            <ng-container *ngIf="item.value && item.value.sessionList">
                <!--<ng-container *ngIf="item.value.thumbnail">-->
                <!--{{onDebug(item)}}-->
                <!--</ng-container>-->
                <ng-container *ngIf="item.value.sessionList.length === 1; else grouped">
                    <app-session-single
                        [grouped]="false"
                        [session]="item.value.sessionList[0]"
                        [activeSession]="activeSessionId()"
                        (sessionClick)="onSessionClick($event)"></app-session-single>
                </ng-container>
                <ng-template #grouped>
                    <app-session-grouped
                        (sessionClick)="onSessionClick($event)"
                        [activeSession]="activeSessionId()"
                        [sessionGroup]="item.value">
                    </app-session-grouped>
                </ng-template>
            </ng-container>
        </ng-container>
        <div *ngIf="isFullscreen(); else windowed" class="remote-app-hover remote-app-icon-bg fullscreen-button" (click)="exitFullscreen()">
            <mat-icon class="arrow-back-icon">fullscreen_exit</mat-icon>
        </div>
        <ng-template #windowed>
            <div class="remote-app-hover remote-app-icon-bg fullscreen-button" (click)="goFullscreen()">
                <mat-icon class="arrow-back-icon">fullscreen</mat-icon>
            </div>
        </ng-template>
        <img *ngIf="showR2CloudRobot" class="logo-icon" src="assets/images/logos/r2/medium/r2-cloud-robo-medium.png">
    </div>
    <div class=canvas-container id=canvas-container fxFlex fxLayout="column" fxLayoutAlign="center center">

    </div>
</div>
import {Component, Input, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BackgroundImageTemplateEditComponent} from './background-image-template-edit/background-image-template-edit.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {BackgroundImageTemplate} from '../../../models-custom-layout';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {BackgroundImageTemplateDaoService} from './background-image-template-dao.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ImageFileService} from '../../image-file/image-file-service';
import {CustomerGroup} from '../../../models';

@Component({
    selector: 'app-background-image-template-list',
    templateUrl: './background-image-template-list.component.html',
    styleUrls: ['./background-image-template-list.component.scss']
})
export class BackgroundImageTemplateListComponent extends ComponentCleaner {

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private _backgroundImageTemplateList: BackgroundImageTemplate[] = [];

    get backgroundImageTemplateList(): BackgroundImageTemplate[] {
        return this._backgroundImageTemplateList;
    }

    @Input()
    set backgroundImageTemplateList(val: BackgroundImageTemplate[]) {
        this._backgroundImageTemplateList = val;
        this.reloadTable();
    }

    @Input()
    isAdmin = false;

    @Input()
    customerGroup: CustomerGroup;

    backgroundImageTemplateDataSource = new MatTableDataSource<BackgroundImageTemplate>();
    backgroundImageTemplateDetailsTable: string[] = ['name', 'imageFileTemplateIds', 'action'];


    constructor(public dialogRef: MatDialogRef<BackgroundImageTemplateEditComponent>,
                private backgroundImageTemplateDaoService: BackgroundImageTemplateDaoService,
                public imageFileService: ImageFileService,
                private dialog: MatDialog) {
        super();
    }

    onEdit(backgroundImageTemplate: BackgroundImageTemplate): void {
        this.openModalEdit(backgroundImageTemplate);
    }

    onCreate(): void {
        this.openModalEdit(null);
    }

    openModalEdit(backgroundImageTemplate: BackgroundImageTemplate): void {
        const dialogRef = this.dialog.open(BackgroundImageTemplateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                backgroundImageTemplate: backgroundImageTemplate,
                customerGroup: this.customerGroup
            }
        });
        this.addSubscription(
            dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
                if (result?.data) {
                    const index = this.backgroundImageTemplateList.findIndex((y) => y.id === result.data.id);
                    if (result && result.operation === 'CREATE') {
                        this.backgroundImageTemplateList.push(result.data);
                    } else if (result && result.operation === 'SAVE') {
                        this.backgroundImageTemplateList[index] = result.data;

                    } else if (result && result.operation === 'DELETE') {
                        this.backgroundImageTemplateList.splice(index, 1);
                    }
                    this.reloadTable();
                }
            }));
    }

    reloadTable(): void {
        this.backgroundImageTemplateDataSource = new MatTableDataSource(this.backgroundImageTemplateList);
        this.backgroundImageTemplateDataSource.sort = this.sort;
    }

    onDelete(backgroundImageTemplate: BackgroundImageTemplate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse imagem do tema? ' +
                    '<br><h2>' + backgroundImageTemplate.name + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'Remover',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.backgroundImageTemplateDaoService.delete(backgroundImageTemplate.id).subscribe((removed) => {
                    const index = this.backgroundImageTemplateList.findIndex((y) => y.id === backgroundImageTemplate.id);
                    this.backgroundImageTemplateList.splice(index, 1);
                    this.reloadTable();
                });
            }
        });
    }
}

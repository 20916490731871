import {Injectable, OnDestroy} from '@angular/core';
import {PdfPrintEvent} from '../../models';
import {PdfPrintDaoService} from './pdf-print-dao.service';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {USER_TOPIC} from '../../../../main';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class PdfPrintService implements OnDestroy {
    private onDestroy$ = new Subject();

    constructor(private stomp: R2CloudStompService, private pdfPrintDaoService: PdfPrintDaoService) {
        this.stomp.stompTopic<PdfPrintEvent>(`${USER_TOPIC}/pdf-print`).pipe(takeUntil(this.onDestroy$), filter(e => !!e && e.id > 0)).subscribe((event: PdfPrintEvent) => {
            console.debug('PdfPrintEvent: ' + event.id);
            this.pdfPrintDaoService.downloadPdfById(event.id).subscribe(pdfPrint => {
                if (pdfPrint) {
                    const objectUrl = URL.createObjectURL(pdfPrint);
                    window.open(objectUrl, '_blank');
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}

import {HipcomBulkUpdateCurrentSoftwareVersion, HipcomBulkUpdateLatestSoftwareVersion, ServerStatus} from '../../../../models';
import {Injectable} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {DesktopServerStatusService} from '../../../../ws/desktop-server-status.service';

@Injectable()
export class HipcomBulkUpdateService extends ComponentCleaner {

    constructor(private desktopServerStatusService: DesktopServerStatusService) {
        super();
    }

    findDesktopServersOnline(desktopServerOnlineList: ServerStatus[]): void {
        for (const desktopServerOnline of desktopServerOnlineList) {
            this.addSubscription(
                this.desktopServerStatusService.onDesktopServerStatus(desktopServerOnline.desktopServerId).subscribe((result) => {
                    this.changeServerStatusOfDesktopServerId(desktopServerOnline.desktopServerId, result, desktopServerOnlineList);
                })
            );
        }
    }

    changeServerStatusOfDesktopServerId(desktopServerId: number, serverStatus: ServerStatus, desktopServerOnlineList: ServerStatus[]): void {
        const index = desktopServerOnlineList.findIndex((o) => o.desktopServerId === desktopServerId);
        if (index > -1) {
            desktopServerOnlineList[index] = serverStatus;
        }
    }
    findHipcomBulkUpdateLatestSoftwareVersionById(id: number,
                                                  hipcomBulkUpdateLatestSoftwareVersions: HipcomBulkUpdateLatestSoftwareVersion[]
    ): HipcomBulkUpdateLatestSoftwareVersion {
        if (hipcomBulkUpdateLatestSoftwareVersions) {
            const index = hipcomBulkUpdateLatestSoftwareVersions.findIndex(value => value.id === id);
            if (index > -1) {
                return hipcomBulkUpdateLatestSoftwareVersions[index];
            }
        }
        return null;
    }

    findHipcomBulkUpdateCurrentSoftwareVersionById(
        id: number,
        hipcomBulkUpdateCurrentSoftwareVersions: HipcomBulkUpdateCurrentSoftwareVersion[]
    ): HipcomBulkUpdateCurrentSoftwareVersion {
        if (hipcomBulkUpdateCurrentSoftwareVersions) {
            const index = hipcomBulkUpdateCurrentSoftwareVersions.findIndex(value => value.id === id);
            if (index > -1) {
                return hipcomBulkUpdateCurrentSoftwareVersions[index];
            }
        }
        return null;
    }
}


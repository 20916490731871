import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ComponentCleaner} from '../../../component-cleaner';
import {convertToFormGroup} from '../../../helpers/kluh';
import {LocalAdminCredentials} from '../../../models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DesktopServerDaoService} from '../desktop-server-dao.service';

@Component({
    selector: 'app-desktop-server-local-admin',
    templateUrl: './desktop-server-local-admin.component.html',
    styleUrls: ['./desktop-server-local-admin.component.scss']
})
export class DesktopServerLocalAdminComponent extends ComponentCleaner {
    myForm: FormGroup;
    localAdminCredentials: LocalAdminCredentials = {
        login: '',
        password: '',
        clientId: ''
    };

    constructor(
        public dialogRef: MatDialogRef<DesktopServerLocalAdminComponent>,
        private fb: FormBuilder,
        private desktopServerDaoService: DesktopServerDaoService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();
        this.localAdminCredentials.clientId = data.desktopServer.clientId;
        this.myForm = this.fb.group(convertToFormGroup(this.localAdminCredentials), {});
    }


    onSave(): void {
        this.localAdminCredentials = this.myForm.value;
        this.desktopServerDaoService.saveLocalAdminWithoutConfirmation(this.localAdminCredentials).subscribe((desktopServer) => {
            if (desktopServer) {
                this.dialogRef.close(desktopServer);
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}

import {Directive, EmbeddedViewRef, Input, isDevMode, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, combineLatest, isObservable, Observable, of, ReplaySubject, Subject, zip} from 'rxjs';
import {UserAuthorityDaoService} from '../main/user-authority/user-authority-dao.service';
import {distinctUntilChanged, filter, map, mergeMap, scan, take, tap} from 'rxjs/operators';
import {isArray} from 'util';
import {BaseDTOIdLong} from '../models';
import {ComponentCleaner} from '../component-cleaner';
import {ManagerUserPermissionsDaoService} from '../main/manager-user/manager-user-permissions/manager-user-permissions-dao.service';

@Directive({
    selector: '[appIfPermission]'
})
export class IfPermissionDirective extends ComponentCleaner implements OnDestroy {

    private _permission$: Subject<string> = new ReplaySubject(1);
    private _objects$: Subject<BaseDTOIdLong | BaseDTOIdLong[] | Observable<BaseDTOIdLong> | Observable<BaseDTOIdLong[] | null>> = new ReplaySubject(1);
    private _type$: Subject<string> = new ReplaySubject(1);
    private _parentType$: Subject<string | null> = new BehaviorSubject(null);
    private _parent$: Subject<BaseDTOIdLong | Observable<BaseDTOIdLong> | null> = new BehaviorSubject(null);
    private _wrapperList$: Subject<PermissionWrapper[]> = new ReplaySubject(1);
    private _else$: Subject<TemplateRef<any> | null> = new BehaviorSubject(null);
    private _and$: Subject<boolean> = new BehaviorSubject(false);
    private _elseTemplateRef: TemplateRef<any> | null = null;
    private _viewRef: EmbeddedViewRef<any> | null = null;
    private _elseViewRef: EmbeddedViewRef<any> | null = null;
    private _and: boolean;
    private alreadyHavePermission = false;


    protected constructor(private userAuthority: UserAuthorityDaoService,
                          private managerUserPermissionsDao: ManagerUserPermissionsDaoService,
                          private templateRef: TemplateRef<any>,
                          private viewContainer: ViewContainerRef) {
        super();
        this.addSubscription(this.managerUserPermissionsDao.getMyUserPermissions().pipe(take(1)).subscribe((result) => {
            if (result.admin) {
                this.onResult(true);
            } else {
                this.initWrapperList();
                this.initObject();
            }
        }));
        // entra um array
    }

    @Input()
    set appIfPermission(object: string | PermissionWrapper[]) {
        this.debug('input', object);
        if (Array.isArray(object)) {
            this.debug('inputWrapperList.next', object);
            this._wrapperList$.next(object);
        } else if (object) {
            this.debug('inputPermission.next', object);
            this._permission$.next(object);
        }
    }

    // a referencia do else precisa estar dentro de um ng-template
    // exemplo: <ng-template #elseElement>xxx</ng-template>
    @Input()
    set appIfPermissionElse(templateRef: TemplateRef<any> | null) {
        this.debug('appIfPermissionElse');
        this._else$.next(templateRef);
    }

    @Input()
    set appIfPermissionAnd(and: boolean) {
        this.debug('appIfPermissionAnd');
        this._and$.next(and);
    }

    @Input()
    set appIfPermissionData(object: BaseDTOIdLong | BaseDTOIdLong[] | Observable<BaseDTOIdLong> | Observable<BaseDTOIdLong[]>) {
        this.debug('appIfPermissionData', object);
        this._objects$.next(object);
    }

    @Input()
    set appIfPermissionType(type: string) {
        this.debug('appIfPermissionType');
        this._type$.next(type);
    }

    @Input()
    set appIfPermissionParentType(parentType: string) {
        this.debug('appIfPermissionParentType');
        this._parentType$.next(parentType);
    }

    @Input()
    set appIfPermissionParent(object: BaseDTOIdLong | Observable<BaseDTOIdLong>) {
        this.debug('appIfPermissionParent', object);
        this._parent$.next(object);
    }

    private debug(i: string, x?: any): void {
        if (isDevMode()) {
            console.debug('step: ' + i);
            if (x) {
                console.debug(x);
            }
        }
    }

    private initWrapperList(): void {
        // this.debug('initWrapperList');
        const items$ = this._wrapperList$.pipe(
            // separa o array de entrada
            tap((x) => this.debug('wrapperList.tap 1', x)),
            mergeMap(values => values),
            tap((x) => this.debug('wrapperList.tap 2', x)),
            // normaliza em outro observable de array (Observable<[]>)
            mergeMap(value => this.normalizePermissionWrapper(value)),
            tap((x) => this.debug('wrapperList.tap 3', x)),
            // separa o array normalizado
            mergeMap(values => values),
            tap((x) => this.debug('wrapperList.tap 4', x)),
            // acumula as emissoes anteriores em um array, substitui duplicados
            scan((acc: PermissionResponse[], value: PermissionResponse) => {
                return this.arrayAccumulator(acc, value);
            }, [] as PermissionResponse[]),
            tap((x) => this.debug('wrapperList.tap 5', x)),
        );
        this.addSubscription(
            combineLatest([this._and$, this._else$, items$])
                .subscribe((result: [boolean, TemplateRef<any> | null, PermissionResponse[]]) => {
                    this._and = result[0];
                    this._elseTemplateRef = result[1];
                    this.onResult(this.filterThroughOperator(result[2]));
                }));
    }

    private initObject(): void {
        const items$ = this._objects$.pipe(
            tap((x) => this.debug('initObject.tap 1', x)),
            map((x) => {
                if (!x) {
                    return {id: null};
                } else {
                    return x;
                }
            }),
            tap((x) => this.debug('initObject.tap 2', x)),
            mergeMap((value) => {
                if (isObservable(value)) {
                    // noinspection UnnecessaryLocalVariableJS
                    const object: Observable<BaseDTOIdLong | BaseDTOIdLong[]> = value;
                    return object.pipe(
                        filter((x) => !!x),
                        map((x) => {
                            if (!isArray(x)) {
                                // caso seja apenas um item, retornar como array
                                return [x] as BaseDTOIdLong[];
                            } else {
                                return x as BaseDTOIdLong[];
                            }
                        })
                    );
                } else {
                    if (isArray(value)) {
                        return of(value);
                    } else {
                        return of([value]);
                    }
                }
            }),
            tap((x) => this.debug('initObject.tap 3', x)),
            mergeMap(values => values),
            tap((x) => this.debug('initObject.tap 4', x)),
            scan((acc: BaseDTOIdLong[], value: BaseDTOIdLong): BaseDTOIdLong[] => {
                const index = acc.findIndex(x => x.id === value.id);
                if (index > -1) {
                    acc[index] = value;
                } else {
                    acc.push(value);
                }
                return acc;
            }, [] as BaseDTOIdLong[]),
            tap((x) => this.debug('initObject.tap 5', x)),
        );
        const _permission$ = this._permission$.pipe(tap((x) => this.debug('initObject._permission$.tap', x)));
        const _type$ = this._type$.pipe(tap((x) => this.debug('initObject._type$.tap', x)));
        const _parentType$ = this._parentType$.pipe(tap((x) => this.debug('initObject._parentType$.tap', x)));
        const _parent$ = this._parent$.pipe(tap((x) => this.debug('initObject._parent$.tap', x)));
        this.addSubscription(
            combineLatest([_permission$, _type$, items$, _parentType$, _parent$])
                .subscribe((result: [string, string, BaseDTOIdLong[], string | null, BaseDTOIdLong | null]) => {
                    this.debug('initObject.subscribe');
                    const permission = result[0];
                    const type = result[1];
                    const items = result[2];
                    const parentType = result[3];
                    const parent = result[4];
                    const permissions = items.map((x) => {
                        return {
                            permission: permission,
                            type: type,
                            object: x,
                            parentType: parentType,
                            parent: parent
                        };
                    }) as PermissionWrapper[];
                    this._wrapperList$.next(permissions);
                }));
    }

    private arrayAccumulator(acc: PermissionResponse[], value: PermissionResponse): PermissionResponse[] {
        const index = acc.findIndex((x) => {
            return x.id === value.id && x.type === value.type && x.permission === value.permission;
        });
        if (index > -1) {
            acc[index] = value;
        } else {
            acc.push(value);
        }
        return acc;
    }

    private normalizePermissionWrapper(wrapper: PermissionWrapper): Observable<PermissionResponse[]> {
        this.debug('normalizePermissionWrapper');
        if (!isObservable(wrapper.object)) {
            let obArray: Observable<PermissionResponse[]>;
            if (isArray(wrapper.object)) {
                // retorna todos os itens em um observable de array
                const obs = wrapper.object.map((x) => {
                    return this.getObjectPermissionObservable(x.id, wrapper.type, wrapper.permission, wrapper.parentType, wrapper.parent);
                });
                // mapea cada item para um observable
                obArray = zip(...obs);
            } else {
                let id = null;
                if (wrapper.object) {
                    id = wrapper.object.id;
                }
                // retorna o item do observable como array por questao de normalizacao
                const obs = [this.getObjectPermissionObservable(id, wrapper.type, wrapper.permission, wrapper.parentType, wrapper.parent)];
                obArray = zip(...obs);
            }
            return obArray;
            // return obArray.pipe(
            //     tap((x) => this.debug('normalizePermissionWrapper.tap 1', x)),
            //     mergeMap(values => values),
            //     tap((x) => this.debug('normalizePermissionWrapper.tap 2', x)),
            //     scan((acc: PermissionResponse[], value: PermissionResponse): PermissionResponse[] => {
            //         return this.arrayAccumulator(acc, value);
            //     }, [] as PermissionResponse[]),
            //     tap((x) => this.debug('normalizePermissionWrapper.tap 3', x)),
            // );
        } else {
            const object: Observable<BaseDTOIdLong | BaseDTOIdLong[]> = wrapper.object;
            return object.pipe(
                // ignora nulos
                filter(x => !!x),
                // normaliza em array
                map((value) => {
                    if (!isArray(value)) {
                        // caso seja apenas um item, retornar como array
                        return [value] as BaseDTOIdLong[];
                    } else {
                        return value as BaseDTOIdLong[];
                    }
                }),
                // separa o array
                mergeMap(values => values),
                // transforma em observable (resposta da api)
                mergeMap((value: BaseDTOIdLong) => {
                    return this.getObjectPermissionObservable(value.id, wrapper.type, wrapper.permission, wrapper.parentType, wrapper.parent);
                }),
                // acumula as emissoes anteriores em um array, substitui duplicados
                scan((acc: PermissionResponse[], value: PermissionResponse): PermissionResponse[] => {
                    return this.arrayAccumulator(acc, value);
                }, [] as PermissionResponse[]));
        }
    }

    private getObjectPermissionObservable(id: number | null,
                                          type: string,
                                          permission: string,
                                          parentType: string | null,
                                          parent: BaseDTOIdLong | Observable<BaseDTOIdLong> | null): Observable<PermissionResponse> {
        this.debug('getObjectPermissionObservable');
        if (!type) {
            throw new Error(`Error checking permission ${permission} of id ${id}: type is invalid`);
        }
        if (!permission) {
            throw new Error(`Error checking permission for type ${type} of id ${id}: permission is invalid`);
        }
        if (parentType && !parent) {
            throw new Error(`Error checking parent permission ${type}.${permission} for parentType ${parentType}: parentId is invalid`);
        }
        if (!parentType && parent) {
            throw new Error(`Error checking parent permission ${type}.${permission}: parentType is invalid`);
        }

        if (parentType && parent) {
            if (!isObservable(parent)) {
                return this.userAuthority.getParentObjectPermission(parent.id, parentType, type, permission).pipe(map((x) => {
                    return {
                        id: id,
                        type: type,
                        value: x,
                        permission: permission,
                        parentType: parentType,
                        parent: parent
                    };
                }));
            } else {
                return parent.pipe(
                    tap((x) => this.debug('getObjectPermissionObservable.parent.tap 1', x)),
                    distinctUntilChanged((prev, curr) => {
                        return prev.id === curr.id;
                    }),
                    tap((x) => this.debug('getObjectPermissionObservable.parent.tap 2', x)),
                    mergeMap((value: BaseDTOIdLong) => {
                        if (!value || !value.id) {
                            throw new Error(`Error checking parent permission ${type}.${permission} for parentType ${parentType}: parent or parent.id is invalid`);
                        }
                        return this.userAuthority.getParentObjectPermission(value.id, parentType, type, permission).pipe(map((x) => {
                            return {
                                id: id,
                                type: type,
                                value: x,
                                permission: permission,
                                parentType: parentType,
                                parent: value
                            };
                        }));
                    }),
                    tap((x) => this.debug('getObjectPermissionObservable.parent.tap 3', x)));
            }
        } else {
            if (!id) {
                throw new Error(`Error checking permission: ${type}.${permission}, id is null`);
            }
            return this.userAuthority.getObjectPermission(id, type, permission).pipe(
                tap((x) => this.debug('getObjectPermissionObservable.tap 1', x)),
                map((x) => {
                    return {
                        id: id,
                        type: type,
                        value: x,
                        permission: permission,
                    };
                }),
                tap((x) => this.debug('getObjectPermissionObservable.tap 2', x)));
        }
    }

    private onResult(value: boolean): void {
        if (value || this.alreadyHavePermission) {
            if (this._elseViewRef) {
                this._elseViewRef = null;
                this.viewContainer.clear();
            }
            if (!this._viewRef) {
                this.alreadyHavePermission = true;
                this._viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
            }
        } else {
            if (this._viewRef) {
                this._viewRef = null;
                this.viewContainer.clear();
            }
            if (!this._elseViewRef && this._elseTemplateRef) {
                this._elseViewRef = this.viewContainer.createEmbeddedView(this._elseTemplateRef);
            }
        }
    }

    private filterThroughOperator(result: PermissionResponse[]): boolean {
        if (this._and) {
            for (const bool of result) {
                if (!bool.value) {
                    return false;
                }
            }
            return true;
        } else {
            for (const item of result) {
                if (item.value) {
                    return true;
                }
            }
            return false;
        }
    }

    ngOnDestroy(): void {
        this._permission$.complete();
        this._objects$.complete();
        this._type$.complete();
        this._parentType$.complete();
        this._parent$.complete();
        this._wrapperList$.complete();
        this._else$.complete();
        this._and$.complete();
        super.ngOnDestroy();
    }
}

export interface PermissionResponse {
    id?: number | null;
    type: string;
    value: boolean;
    permission: string;
    parentType?: string | null;
    parent?: BaseDTOIdLong | null;
}

export interface PermissionWrapper {
    object?: BaseDTOIdLong | Observable<BaseDTOIdLong> | BaseDTOIdLong[] | Observable<BaseDTOIdLong[]> | null;
    type: string;
    permission: string;
    parentType?: string | null;
    parent?: BaseDTOIdLong | Observable<BaseDTOIdLong> | null;
}

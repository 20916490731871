<form *ngIf="myForm" [formGroup]="myForm"
      (submit)="(myForm.valid && myForm.dirty && formData && onSubmit())" appDragDropFile (fileDropped)="fileDrop($event)">
    <input (change)="onFileChange($event)" name="file"
           id="fileToUpload" accept=".dll" type="file" style="display:none;">
    <div mat-dialog-title>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de arquivo de Dependencia de Plugin</span>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <mat-dialog-content fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-20">

<!--        <mat-form-field>-->
<!--            <input matInput formControlName="version" placeholder="Versão (x.x.x.x)">-->
<!--            <mat-error>-->
<!--                {{myForm.get('version').errors | error}}-->
<!--            </mat-error>-->
<!--        </mat-form-field>-->
        <mat-dialog-content fxLayout="column" fxLayoutAlign="space-between stretch">
            <mat-form-field>
                <input matInput formControlName="name" placeholder="Nome (identificador)">
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                {{getFileName()}}
            </div>
            <button type="button" mat-raised-button color="accent" onclick="document.getElementById('fileToUpload').click()">
                <mat-icon>cloud_upload</mat-icon>
            </button>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
        <button type="submit" mat-raised-button color="accent"
                [disabled]="(!myForm.valid || myForm.pristine || !formData)"
                aria-label="Salvar">
            <span *ngIf="myForm.get('id')?.value; else newUser">Salvar</span>
            <ng-template #newUser>Criar</ng-template>
        </button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

    </mat-dialog-actions>
</form>
import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {ADAdmin} from '../../models';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class AdAdminDaoService extends BaseDao<ADAdmin> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'ad-admin');
    }

    findByAdDomainIdAndAdUserId(adDomainId: number, adUserId: number): Observable<ADAdmin> {
        return this.http.get<ADAdmin>(this.url + '/find-by-ad-domain-id-and-ad-user-id?adDomainId=' + adDomainId + '&adUserId=' + adUserId);
    }


    findByADUserList(adUserIdList: number[]): Observable<ADAdmin[]> {
        return this.http.post<ADAdmin[]>(this.url + '/find-by-ad-user-id-list', adUserIdList);
    }

    isAdminUser(adDomainId: number): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/is-admin?adDomainId=' + adDomainId);
    }


}

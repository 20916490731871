import {Injectable} from '@angular/core';
import {BaseMonitoringDao} from '../../../base-monitoring-dao';
import {ImageFileTemplate} from '../../../models-custom-layout';
import {HttpClient} from '@angular/common/http';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {R2CloudHttpMonitoringService} from '../../../r2-cloud-http-monitoring.service';
import {R2CloudHttpCustomLayoutService} from '../../../r2-cloud-http-custom-layout.service';

@Injectable({
    providedIn: 'root'
})
export class ImageFileTemplateDaoService extends BaseMonitoringDao<ImageFileTemplate> {
    constructor(private httpAngular: HttpClient,
                http: R2CloudHttpCustomLayoutService,
                validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'image-file-template');
    }

    createAllAndReturnOnlyIds(entityList: ImageFileTemplate[]): Observable<number[]> {
        return this.http.post<number[]>(this.url + '/create-all-and-return-only-ids', entityList);
    }

    createOneAndReturnOnlyId(entity: ImageFileTemplate): Observable<number> {
        return this.http.post<number>(this.url + '/create-one-and-return-only-id', entity);
    }
}


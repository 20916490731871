import {BaseDataTable} from '../../../models-monitoring';

export abstract class TableDataService<T extends BaseDataTable> {

    protected constructor() {
    }
    listHandler(dataListFromWebsocket: T[], dataList: T[]): T[] {
        dataListFromWebsocket.forEach( ( data: T ) => {
            if (data.toRemove) {
                return this.remove(data, dataList);
            } else {
                return this.save(data, dataList);
            }
        });
        return dataList;
    }
    save(data: T, dataList: T[]): T[] {
        if (dataList) {
            const index: number = dataList.findIndex(x => x.unique === data.unique);
            if (index !== -1) {
                dataList[index] = data;
            } else {
                dataList.push(data);
            }
        }
        return dataList;
    }
    remove(data: T, dataList: T[]): T[] {
        if (dataList) {
            const index: number = dataList.findIndex(x => x.unique === data.unique);
            if (index !== -1) {
                dataList.splice(index, 1);
            }
        }
        return dataList;
    }
}

import {Injectable} from '@angular/core';

import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseDao} from '../../base-dao';
import {ManagerUserAccessPeriodTime} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class ManagerUserAccessPeriodTimeDaoService extends BaseDao<ManagerUserAccessPeriodTime> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'manager-user-access-period-time');
    }

}

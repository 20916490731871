import {Component, Inject, OnInit} from '@angular/core';
import {CustomerCreateEditComponent} from '../../main/customer/customer-create-edit/customer-create-edit.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-error-details',
    templateUrl: './error-details.component.html',
    styleUrls: ['./error-details.component.scss']
})
export class ErrorDetailsComponent implements OnInit {
    details: any;
    constructor(private dialogRef: MatDialogRef<CustomerCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit(): void {
        this.details = {
            request: {
                urlWithParams: this.data.req.urlWithParams,
                method: this.data.req.method,
                body: this.data.req.body
            },
            response: {
                body: this.data.err.error
            }
        };
    }

}

import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Assistant} from '../../../models-ai';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class AssistantDaoService extends BaseDao<any> {
    private aiURL = '';

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    public init(): Assistant {
        return {
            id: null,
            providerAssistantId: null,
            aiUserGroupId: null,
            vectorStoreId: null,
            name: null,
            description: null,
            model: null,
            instructions: null,
            tools: null,
            metadata: null,
            topP: null,
            temperature: null,
            responseFormat: null,
            createdAt: null,
        };
    }

    findByVectorStoreId(vectorStoreId: number): Observable<Assistant> {
        return this.http.get<Assistant>(this.aiURL + 'assistant/vector-store-id/' + vectorStoreId, null, null, true);
    }

    save(assistant: Assistant): Observable<Assistant> {
        return this.http.put<Assistant>(this.aiURL + 'assistant/', assistant, true);
    }
}


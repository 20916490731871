<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row">
                <mat-card>
                    <mat-card-header style="height: 130px;">
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>Meus uploads</div>

                        </mat-card-title>
                        <mat-card-subtitle>
                            Os arquivos vão aparecer dentro da pasta (Documents\R2Cloud-Uploads) no
                            servidor em nuvem.<br>
                            <small>* Atenção, os arquivos ficarão armazenados por 15 dias nesta lista.</small><br>
                            <small>* Somente servidores habilitados com o plugin <b>"upload file"</b> e com o usuário logado com acesso, irão aparecer na lista.</small>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content (drop)="onDrop($event)" (dragover)="onDragOver($event)"  (dragleave)="onDragEnd($event)"
                                      [ngClass]="{'drag-over': isDragging}" >

                        <div style="padding: 20px 20px 0 20px;" fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" class="min-width-230">
                                <mat-label>Projeto</mat-label>
                                <mat-select class="full-width" placeholder="Projeto" [formControl]="subProjectControl">
                                    <mat-option [value]="subProject.id"
                                                *ngFor="let subProject of subProjects; let i = index">{{subProject.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="min-width-230 ml-15">
                                <mat-label>Servidor</mat-label>
                                <mat-select class="full-width" placeholder="Servidor" [formControl]="desktopServerControl">
                                    <mat-option [value]="desktopServer.id"
                                                *ngFor="let desktopServer of desktopServers; let i = index">{{desktopServer.hostname}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="mt-15 ml-15">
                                <button type="button" mat-raised-button color="accent" (click)="openFileInput()"
                                        [disabled]="!hasDesktopServerSelected()">
                                    Adicionar arquivos
                                </button>
                                <input type="file" #fileInput style="display: none;" (change)="onAddFiles($event)"
                                       multiple>
                            </div>
                        </div>

                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            <!-- Position Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                                <td mat-cell *matCellDef="let element" class="icon">
                                    <img *ngIf="element.name" src="{{imageFileService.getIconOfExtension(element.name)}}" width="60" class="p-5">
                                </td>
                            </ng-container>

                            <!-- Position Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef><div class="pl-15">Nome</div></th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="text-max-400" title="{{element.name}}">{{element.name}}</div>
                                </td>
                            </ng-container>

                            <!-- Position Column -->
                            <ng-container matColumnDef="size">
                                <th mat-header-cell *matHeaderCellDef>Tamanho</th>
                                <td mat-cell *matCellDef="let element" class="w-100 no-padding">
                                    <div>{{element.size | bytes}}</div>
                                </td>
                            </ng-container>


                            <!-- Name Column -->
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef>Data de criação</th>
                                <td mat-cell *matCellDef="let element" class="w-190 no-padding">
                                    <div *ngIf="element.createdAt">
                                        {{element.createdAt | date: 'dd/MM/yyyy HH:mm - EEE'}}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" class="text-align-center w-150 no-padding">
                                    <div>
                                        <mat-icon *ngIf="element.changing" class="icon" title="Arquivo sem confirmação de salvamento no servidor">refresh</mat-icon>
                                        <mat-icon *ngIf="!element.changing" class="icon" title="Arquivo salvo no servidor" style="color: green;">check_circle</mat-icon>
                                    </div>

                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="totalDocs"></mat-paginator>


                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {R2CloudAdminService} from '../../r2-cloud-admin.service';
import {ComponentCleaner} from '../../../../component-cleaner';
import {AdUserServiceService} from '../../r2-cloud-admin-aduser/ad-user-service.service';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';

@Component({
    selector: 'app-r2-cloud-admin-user-service-type-choice',
    templateUrl: './r2-cloud-admin-user-service-type-choice.component.html',
    styleUrls: ['./r2-cloud-admin-user-service-type-choice.component.scss']
})
export class R2CloudAdminUserServiceTypeChoiceComponent extends ComponentCleaner {
    appCanCreateADUserPoolRelationship: PermissionWrapper[];
    constructor(
        public dialogRef: MatDialogRef<R2CloudAdminUserServiceTypeChoiceComponent>,
        public adminService: R2CloudAdminService,
        public adUserServiceService: AdUserServiceService,
        public customerGroupService: CustomerGroupService,
    ) {
        super();
        this.appCanCreateADUserPoolRelationship = [
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
    }
    onCancel(): void {
        this.dialogRef.close();
    }
}

<form>
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" *ngIf="!digitalCertificate">Enviar certificado digital</span>
            <span class="title dialog-title" *ngIf="digitalCertificate">Instalar certificado digital</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="p-16 message" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
        <div *ngIf="error" class="full-width" style="padding: 20px 40px 40px 40px;" >
            <h1 class="error-icon">Erro</h1>
            <h2 *ngIf="checkInvalidFile(errorMessage)">Arquivo inválido ou corrompido</h2>
            <h2 *ngIf="checkInvalidPassword(errorMessage)">Arquivo inválido ou senha incorreta</h2>
            <h2 *ngIf="checkCertificateAlreadyExists(errorMessage)">Esse certificado já foi cadastrado</h2>
            <div *ngIf="hasUnknownError(errorMessage)" style="margin-top: 40px; font-size: 10px;">
                {{errorMessage}}
            </div>
        </div>
        <div *ngIf="loading" class="full-width p-50" fxLayout="row" fxLayoutAlign="space-around center" >
            <mat-spinner [diameter]="24" color="primary"></mat-spinner>
        </div>
        <div *ngIf="!loading && !error" fxLayout="row" fxLayoutAlign="space-between start">
            <div class="full-width">
                <div *ngIf="hasBlockedFile(localUploadFiles)">
                    <mat-card style="background-color: #f44336;color: #ffffff; margin: 15px 15px 30px 15px;">
                        <mat-card-content fxLayoutAlign="start start" matDialogContent style="padding-left: 0;">
                            <mat-icon>error</mat-icon>
                            <div class="pl-10 pt-3">
                                <div>
                                    Somente arquivos ".pfx" e com tamanho menor que 50MB, serão enviados.
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <hr style="border-top: 0 solid #eee;margin-bottom: 25px;">
                </div>
                <div *ngIf="digitalCertificate">
                    <div class="float-left">
                        <div><b>Nome:</b></div>
                        <div>{{digitalCertificate.name}}</div>

                        <div class="mt-20"><b>Tamanho:</b></div>
                        <div>{{digitalCertificate.size | bytes}}</div>

                        <div class="mt-20"><b>Data de envio:</b></div>
                        <div>{{digitalCertificate.createdAt | date: 'dd/MM/yyyy HH:mm' }}</div>

                    </div>
                    <div class="float-right">
                        <div style="clear: both"></div>
                        <mat-form-field appearance="outline" class="float-right" style="height: 70px;width: 307px;">
                            <mat-label>Projeto</mat-label>
                            <mat-select class="full-width" placeholder="Projeto" [formControl]="subProjectControl">
                                <mat-option [value]="subProject.id"
                                            *ngFor="let subProject of subProjects; let i = index">{{subProject.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div style="clear: both"></div>
                        <mat-form-field appearance="outline" class="float-right" style="height: 70px;width: 307px;">
                            <mat-label>Servidor</mat-label>
                            <mat-select class="full-width" placeholder="Servidor"
                                        [formControl]="desktopServerControl">
                                <mat-option [value]="desktopServer.id"
                                            *ngFor="let desktopServer of desktopServers; let i = index">{{desktopServer.hostname}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div style="clear: both"></div>
                        <div class="font-size-10 text-align-center" style="width: 310px;">Somente servidores habilitados com o plugin "certificado digital" irão aparecer na lista.</div>
                        <div style="clear: both"></div>
                        <div class="float-right mt-30">
                            <button type="button" mat-raised-button color="accent" class="ml-10 float-right"
                                    [disabled]="!desktopServerControl.value"
                                    (click)="onInstallAll()">
                                Instalar
                            </button>

                            <button type="button" class="float-right pr-10" mat-raised-button color="primary"
                                    *ngIf="hasInstallation()"
                                    [disabled]="!desktopServerControl.value"
                                    (click)="onCheckAll()">
                                Verificar
                            </button>

                        </div>
                    </div>
                    <div style="clear: both"></div>
                    <hr style="border-top: 0 solid #eee;margin-bottom: 10px;margin-top: 30px">
                    <div class="full-width">
                        <table class="app-table app-table-hover" *ngIf="desktopServerControl.value">
                            <tr *ngFor="let adUsersPoolRelationship of adUsersPoolRelationshipAll;">
                                <td class="w-50">
                                    <img
                                        src="{{imageFileService.getManagerUserPicture(findManagerUser(adUsersPoolRelationship.managerUserId))}}?w=40&square=true"
                                        class="ml-8 pt-8 pb-8"
                                        width="40" height="40">
                                </td>
                                <td>
                                    <div *ngIf="findADUserPool(adUsersPoolRelationship.adUserPoolId) as adUserPool">
                                        <div
                                            *ngIf="!adUserPool.serviceUser">
                                            <div>{{findManagerUser(adUsersPoolRelationship.managerUserId)?.email}}</div>
                                            <div class="font-size-10">{{findADUserPool(adUserPool.id)?.login}}</div>
                                        </div>
                                        <div *ngIf="adUserPool.serviceUser">
                                            <div>
                                                <div>Usuário Serviço</div>
                                                <div class="font-size-10">{{findADUserPool(adUserPool.id)?.login}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </td>
                                <td class="w-100">
                                    <div
                                        *ngIf="findDigitalCertificateInstall(findADUserPool(adUsersPoolRelationship.adUserPoolId)?.login) as digitalCertificateInstall">
                                        <div *ngIf="digitalCertificateInstall.updatedAt">
                                            {{digitalCertificateInstall.updatedAt | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>
                                        <div
                                            *ngIf="!digitalCertificateInstall.updatedAt && digitalCertificateInstall.createdAt">
                                            {{digitalCertificateInstall.createdAt | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>
                                    </div>
                                </td>
                                <td class="w-30 pr-16">
                                    <div
                                        *ngIf="isInstalled(findADUserPool(adUsersPoolRelationship.adUserPoolId)?.login)">
                                        <button mat-icon-button matTooltip="Instalado" [matTooltipPosition]="'above'">
                                            <mat-icon class="success-icon">check_circle</mat-icon>
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="isInstalling(findADUserPool(adUsersPoolRelationship.adUserPoolId)?.login)">
                                        <button mat-icon-button matTooltip="Instalando..." [matTooltipPosition]="'above'" (click)="onCheck(findDigitalCertificateInstall(findADUserPool(adUsersPoolRelationship.adUserPoolId)?.login))">
                                            <mat-icon>refresh</mat-icon>
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="isError(findADUserPool(adUsersPoolRelationship.adUserPoolId)?.login)">
                                        <button mat-icon-button matTooltip="Erro!" [matTooltipPosition]="'above'">
                                            <mat-icon class="error-icon">error</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let adUser of adUsers;">
                                <td class="w-50">
                                    <img
                                        src="{{imageFileService.getManagerUserPicture(findManagerUser(adUser.managerUserId))}}?w=40&square=true"
                                        class="ml-8 pt-8 pb-8"
                                        width="40" height="40">
                                </td>
                                <td>
                                    <div>{{findManagerUser(adUser.managerUserId)?.email}}</div>
                                    <div class="font-size-10">{{adUser.login}}</div>
                                </td>
                                <td class="w-100">
                                    <div
                                        *ngIf="findDigitalCertificateInstall(adUser.login) as digitalCertificateInstall">
                                        <div *ngIf="digitalCertificateInstall.updatedAt">
                                            {{digitalCertificateInstall.updatedAt | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>
                                        <div
                                            *ngIf="!digitalCertificateInstall.updatedAt && digitalCertificateInstall.createdAt">
                                            {{digitalCertificateInstall.createdAt | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>
                                    </div>
                                </td>
                                <td class="w-30 pr-16">
                                    <div
                                        *ngIf="isInstalled(adUser.login)">
                                        <button mat-icon-button matTooltip="Instalado" [matTooltipPosition]="'above'">
                                            <mat-icon class="success-icon">check_circle</mat-icon>
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="isInstalling(adUser.login)">
                                        <button mat-icon-button [matTooltipPosition]="'above'" matTooltip="Instalando..." (click)="onCheck(findDigitalCertificateInstall(adUser.login))">
                                            <mat-icon>refresh</mat-icon>
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="isError(adUser.login)">
                                        <button mat-icon-button matTooltip="Erro!" [matTooltipPosition]="'above'">
                                            <mat-icon class="error-icon">error</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="!hasBlockedFile(localUploadFiles)">
                    <table *ngIf="localUploadFiles && !digitalCertificate" class="app-table">
                        <tr *ngFor="let customFile of localUploadFiles;" [ngClass]="{'blocked': customFile.blocked}">
                            <td class="w-240">
                                <div><b>Nome:</b></div>
                                <div>{{customFile.name}}</div>

                                <div class="mt-20"><b>Tamanho:</b></div>
                                <div>{{customFile.size | bytes}}</div>

                                <mat-form-field appearance="outline" class="w-300 mt-25" style="height: 64px">
                                    <mat-label>Senha do certificado</mat-label>
                                    <input matInput type="password" [formControl]="passwordControl"
                                           placeholder="Senha do certificado">
                                    <mat-error *ngIf="passwordControl.errors">
                                        Algum caracter não permitido ( aspas simples ou espaço )
                                    </mat-error>
                                </mat-form-field>
                                <div style="clear: both"></div>
                                <mat-form-field appearance="outline" class="w-300 mt-10">
                                    <mat-label>Repita a senha</mat-label>
                                    <input matInput type="password" [formControl]="repeatPasswordControl"
                                           placeholder="Repita a senha">
                                    <mat-error *ngIf="repeatPasswordControl.errors">
                                        Senhas não são iguais
                                    </mat-error>
                                </mat-form-field>

                                <mat-spinner *ngIf="customFile.status === FileStatus.SENDING" [diameter]="24"
                                             color="primary"></mat-spinner>
                                <mat-icon *ngIf="customFile.status === FileStatus.TO_SEND">pause_circle_outline</mat-icon>
                                <mat-icon *ngIf="customFile.status === FileStatus.SENT" class="success-icon">check_circle
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="button" mat-raised-button color="accent"
                *ngIf="!sendingFiles && !hasBlockedFile(localUploadFiles) && !digitalCertificate"
                [disabled]="!isValidPassword()" (click)="onSending()">Enviar
        </button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel(finished)">
            <span *ngIf="finished">Concluído</span>
            <span *ngIf="!finished">Cancelar</span>
        </button>
    </div>
</form>

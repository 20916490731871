export enum ConnectionState {
    OpeningRemotePort = 0,
    EstablishingSecureConnection = 1,
    ConfiguringRemoteConnection = 2,
    DetectingNetworkQuality = 3,
    SessionBrokerFindingDestination = 4,
    SessionBrokerLoadingDestination = 5,
    SessionBrokerBringingSessionOnline = 6,
    SessionBrokerRedirectingToDestination = 7,
    VirtualMachineLoading = 8,
    VirtualMachineWaking = 9,
    VirtualMachineStarting = 10,
    VirtualMachineStartingSessionMonitoring = 11,
    VirtualMachineRetryingSessionMonitoring = 12,
    Connected = 13,
    Disconnected = 14,
    Initialized = 15,
    Dismissed = 16,
}
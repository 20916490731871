import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesktopServerListComponent} from './desktop-server-list/desktop-server-list.component';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {DesktopServerCreateComponent} from './desktop-server-create/desktop-server-create.component';
import {DesktopServerDaoService} from './desktop-server-dao.service';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

import {KluhSharedModule} from '../../kluh-shared.module';
import {DesktopServerCreateTokenComponent} from './desktop-server-create-token/desktop-server-create-token.component';
import {JaversModule} from '../javers/javers.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {DesktopServerLocalAdminComponent} from './desktop-server-local-admin/desktop-server-local-admin.component';

const routes = [
    {
        path: 'desktop-server',
        component: DesktopServerListComponent,
        canActivate: [AuthService],
        data: {permissions: ['DesktopServer.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatSelectModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatSlideToggleModule,
        JaversModule,
        MatMenuModule
    ],
    declarations: [DesktopServerListComponent, DesktopServerCreateComponent, DesktopServerCreateTokenComponent, DesktopServerLocalAdminComponent],
    providers: [
        DesktopServerDaoService
    ],
    entryComponents: [
        DesktopServerCreateComponent, DesktopServerCreateTokenComponent
    ]
})
export class DesktopServerModule {
}

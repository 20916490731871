<div id="client-status-toolbar">
    <div fxLayout="row" class="mr-8 s-16"
         [matTooltip]="agentTooltip"
         [style.cursor]="agentStatusCursor"
         (click)="agentStatusClick($event)">
        <div *ngIf="clientStatus" class="client-status-icon">
            <!--fadeInOut-->
            <div [@fadeIn]>
                <fa-icon [ngClass]="agentIconClass" [icon]="agentIcon"></fa-icon>
            </div>
        </div>
        <div class="ml-16">
            Agente
        </div>
    </div>
    <ng-container *ngIf="clientStatus">
        <div class="client-update" *ngIf="clientStatus.status === R2CloudClientStatus.CLIENT_UPDATE">
            <div class="arrow_box">
                <div class="inner_box ">
                    <b>Atualizando agente.</b>
                </div>
            </div>
        </div>
    </ng-container>
</div>
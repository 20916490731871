import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {BaseSearchDao} from '../../base-search-dao';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {PageEvent} from '@angular/material/paginator';
import {UploadFile} from 'app/kluh-manager/models';

@Injectable()
export class UploadFileDaoService extends BaseSearchDao<UploadFile> implements DataSource<UploadFile> {
    private subject = new BehaviorSubject<UploadFile[]>([]);
    private totalSubject = new BehaviorSubject<number>(0);

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'upload-file');
    }

    uploadFile(file: File, subProjectId: number, desktopServerId: number): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.upload<void>(formData, this.url + `/create/${subProjectId}/${desktopServerId}`);
    }

    filter(params?: any): Observable<UploadFile[]> {
        return this.http.get<UploadFile[]>(this.url + '/filter', params);
    }

    connect(collectionViewer: CollectionViewer): Observable<UploadFile[] | ReadonlyArray<UploadFile>> {
        this.subject = new BehaviorSubject<UploadFile[]>([]);
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.totalSubject.complete();
        this.subject = null;
        this.totalSubject = null;
    }

    clearAllData(): void {
        this.subject.next(null);
        this.totalSubject.next(null);
    }

    load(field: string, value: string, page: PageEvent): void {
        if (this.subject && this.totalSubject) {
            this.search({field: field, value: value, limit: page.pageSize, page: page.pageIndex, fieldOrder: 'id', order: 'desc'}).subscribe(value => {
                this.subject.next(value.content);
                this.totalSubject.next(value.totalElements);
            });
        }
    }

    removeItem(id: number): void {
        const uploadFileList = this.subject.getValue().filter((value, key) => {
            return value.id !== id;
        });
        this.subject.next(uploadFileList);
    }

    get total$(): Observable<number> {
        if (!this.totalSubject) {
            this.totalSubject = new BehaviorSubject<number>(0);
        }
        return this.totalSubject.asObservable();
    }

    getAll(): UploadFile[] {
        return  this.subject.getValue();
    }
}

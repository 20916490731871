import {Injectable} from '@angular/core';
import {RoleIdSubProjectIdManagerUserId, RoleSubProjectManagerUseInBatch} from '../../models';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseIAMDao} from '../../base-iam-dao';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RoleSubProjectManagerUserDAOService extends BaseIAMDao<RoleIdSubProjectIdManagerUserId> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'role-sub-project-manager-user');
    }

    createInBatch(roleSubProjectManagerUseInBatch: RoleSubProjectManagerUseInBatch): Observable<void> {
        return this.http.post<void>(this.url + '/create-in-batch', roleSubProjectManagerUseInBatch);
    }
}

import {Injectable} from '@angular/core';
import {HipcomBulkUpdateDetail} from '../../../../../models';
import {BaseDao} from '../../../../../base-dao';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';

@Injectable()
export class HipcomBulkUpdateDetailDaoService extends BaseDao<HipcomBulkUpdateDetail> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'partners-hipcom-bulk-update-detail');
    }

    executeTask(hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): Observable<void> {
        return this.http.post<void>(this.url + '/send-hipcom-bulk-update-stomp/', hipcomBulkUpdateDetail);
    }

    forcedUpdateOnModule(currentSoftwareId: number, hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): Observable<void> {
        return this.http.post<void>(this.url + '/forced-update-on-module/' + currentSoftwareId, hipcomBulkUpdateDetail);
    }

    forcedUpdateAllModules(hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): Observable<void> {
        return this.http.post<void>(this.url + '/forced-update-all-modules/', hipcomBulkUpdateDetail);
    }

    scanModulesAgain(hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): Observable<void> {
        return this.http.post<void>(this.url + '/scan-modules-again/', hipcomBulkUpdateDetail);
    }

    executeAllTasks(): Observable<void> {
        return this.http.get<void>(this.url + '/execute-all-tasks');
    }

    disableAllTasks(): Observable<void> {
        return this.http.get<void>(this.url + '/disable-all-tasks');
    }

    enableAllTasks(): Observable<void> {
        return this.http.get<void>(this.url + '/enable-all-tasks');
    }
}

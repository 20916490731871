import {Injectable} from '@angular/core';
import {Thread} from '../../../models-ai';

@Injectable({
    providedIn: 'root'
})
export class ThreadService {
    public initThread(): Thread {
        return {
            id: null,
            assistantId: null,
            managerUserId: null,
            aiUserGroupId: null,
            title: null,
            providerToolResources: null,
            providerThreadId: null,
            provider: null,
            metadata: null,
            createdAt: null,
            updatedAt: null,
            private: null
        };
    }
}

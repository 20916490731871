import {Injectable} from '@angular/core';
import {Logger} from './ts/logger';

@Injectable({
    providedIn: 'root'
})
export class LoggerService extends Logger {

    constructor() {
        super();
    }
}

import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable, of, Subject, throwError} from 'rxjs';
import {BooleanValue, CredentialsExpiring, FirebaseUserDetails, ManagerUser, Permission} from '../../models';
import {ObjectPermission} from '../../helpers/ObjectPermission';
import {catchError, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {ParentObjectPermission} from '../../helpers/ParentObjectPermission';
import {CacheControl} from '../../helpers/CacheControl';
import {ManagerUserPermissionsDaoService} from '../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';


@Injectable()
export class UserAuthorityDaoService implements CacheControl, OnDestroy {
    private me$: Observable<FirebaseUserDetails>;
    private me$Time: moment.Moment;
    private myPermissions$: Observable<Permission[]>;
    private myPermissions: Permission[];
    private myObjectPermissions: ObjectPermission[] = [];
    private myParentObjectPermissions: ParentObjectPermission[] = [];
    private onDestroy$ = new Subject();

    constructor(private http: R2CloudHttpApiService,
                private validatorDAO: KluhManagerValidator,
                private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService) {
    }

    setCacheControl(ob: Observable<boolean>): void {
        ob.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.clearCache();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    clearCache(): void {
        // console.trace('clearCache called');
        this.me$ = null;
        this.myPermissions$ = null;
        this.myPermissions = [];
        this.myObjectPermissions = [];
        this.myParentObjectPermissions = [];
    }

    getMe(): Observable<FirebaseUserDetails> {
        // return Observable.of(null);
        const now = moment();
        if (this.me$Time && moment.duration(now.diff(this.me$Time)).minutes() > 30) {
            console.log('me observable is being cleared');
            this.me$ = null;
        }
        if (!this.me$) {
            // console.trace('me observable is not defined', this.me$);
            this.me$Time = now;
            this.me$ = this.http.get<FirebaseUserDetails>('me').pipe(catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    return of(null);
                }
                return throwError(error);
            }), shareReplay(1));
        }
        return this.me$;
    }

    getLogout(): Observable<void> {
        // console.trace('api logout called');
        return this.http.get<void>('logout');
    }

    getMyPermissions(): Observable<Permission[]> {
        if (!this.myPermissions$) {
            this.myPermissions$ = this.http.get<Permission[]>('permission/myPermissions').pipe(catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    return of([]);
                }
                return throwError(error);
            }), shareReplay(1));
        }
        this.myPermissions$.subscribe(permissions => {
            if (permissions) {
                this.myPermissions = permissions;
            }
        });
        return this.myPermissions$;
    }

    getObjectPermission(objectId: number, objectType: string, permissionSuffix: string): Observable<boolean> {
        const objectPermission = this.myObjectPermissions.find((value) => {
            return value.objectId === objectId && value.objectType === objectType && value.permissionSuffix === permissionSuffix;
        });
        if (objectPermission) {
            return of(objectPermission.value);
        } else {
            return this.hasPermission(objectType, objectId, permissionSuffix).pipe(map((result) => {
                this.myObjectPermissions.push({
                    value: result.value,
                    objectType: objectType,
                    objectId: objectId,
                    permissionSuffix: permissionSuffix
                });
                return result.value;
            }));
        }
    }

    getParentObjectPermission(parentId: number, parentType: string, objectType: string, permissionSuffix: string): Observable<boolean> {
        if (this.hasParentPermissionInMyPermissions(parentId, parentType, objectType, permissionSuffix)) {
            const parentObjectPermission = this.myParentObjectPermissions.find((value) => {
                return value.parentId === parentId && value.parentType === parentType && value.objectType === objectType && value.permissionSuffix === permissionSuffix;
            });
            if (parentObjectPermission) {
                return of(parentObjectPermission.value);
            } else {
                return this.hasParentPermission(parentType, parentId, objectType, permissionSuffix).pipe(map((result) => {
                    this.myParentObjectPermissions.push({
                        value: result.value,
                        parentType: parentType,
                        parentId: parentId,
                        objectType: objectType,
                        permissionSuffix: permissionSuffix
                    });
                    return result.value;
                }));
            }
        }
        return of(false);
    }

    hasPermission(objectType: string, objectId: number, permissionSuffix: string): Observable<BooleanValue> {
        return this.http.get<BooleanValue>('permission/has/' + permissionSuffix + '/' + objectId + '/' + objectType);
    }

    canCreate(objectType: string, permissionSuffix: string): Observable<BooleanValue> {
        return this.http.get<BooleanValue>('permission/can-create/' + permissionSuffix + '/' + objectType);
    }

    hasParentPermission(parentType: string, parentId: number, objectType: string, permissionSuffix: string): Observable<BooleanValue> {
        return this.http.get<BooleanValue>('permission/has-from-parent/' + permissionSuffix + '/' + parentType + '/' + parentId + '/' + objectType + '/');
    }

    setCredentialsExpiring(value: boolean, oldToken: string | null = null): Observable<ManagerUser> {
        const body: CredentialsExpiring = {
            value: value
        };
        return this.http.post<ManagerUser>('credentials-expiring', body);
    }

    private hasParentPermissionInMyPermissions(parentId: number, parentType: string, objectType: string, permissionSuffix: string): Boolean {
        const hasPermission = this.myPermissions?.find((value) => {
            if (parentType === 'CustomerGroup') {
                return value?.customerGroupIds?.indexOf(parentId) > -1 && value.name === objectType + '.' + permissionSuffix;
            } else if (parentType === 'SubProject') {
                return value?.subProjectIds?.indexOf(parentId) > -1 && value.name === objectType + '.' + permissionSuffix;
            } else {
                return true;
            }
        });
        return hasPermission != null;
    }

    hasParentPermissionCache(parentType: string, parentId: number, objectType: string, permissionSuffix: string): Observable<boolean> {
        // console.log('isAdmin: 0.1');
        return this.getMe().pipe(switchMap((user) => {
            // console.log('isAdmin: 1.0');
            if (!!(user && user.accountNonExpired && user.accountNonLocked && user.enabled && user.email)) {
                // console.log('isAdmin: 1.1 | ' + user.managerUser.id);
                return this.managerUserPermissionsDaoService.isAdminUser(user.managerUser.id).pipe(switchMap((isAdmin) => {
                    // console.log('isAdmin: 1.2');
                    if (isAdmin) {
                        // console.log('isAdmin: ' + user.managerUser.id);
                        return of(true);
                    } else {
                        // console.log('isAdmin: 3.0 ');
                        const parentObjectPermission = this.myParentObjectPermissions.find((value) => {
                            return of(value.parentId === parentId && value.parentType === parentType &&
                                value.objectType === objectType && value.permissionSuffix === permissionSuffix);
                        });
                        if (parentObjectPermission && parentObjectPermission.value != null) {
                            return of(parentObjectPermission.value);
                        } else {
                            if (!parentObjectPermission) {
                                return this.hasParentPermission(
                                    parentType,
                                    parentId,
                                    objectType,
                                    permissionSuffix).pipe(switchMap((hasPermission) => {
                                    const index = this.myParentObjectPermissions.findIndex((value) => {
                                        return value.parentId === parentId && value.parentType === parentType && value.objectType === objectType &&
                                            value.permissionSuffix === permissionSuffix;
                                    });
                                    if (index > -1) {
                                        this.myParentObjectPermissions[index].value = hasPermission.value;
                                    } else {
                                        this.myParentObjectPermissions.push(
                                            {
                                                permissionSuffix: permissionSuffix,
                                                parentId: parentId,
                                                parentType: parentType,
                                                objectType: objectType,
                                                value: hasPermission.value
                                            }
                                        );
                                    }
                                    return of(hasPermission.value);
                                }));
                            }
                            this.myParentObjectPermissions.push(
                                {
                                    permissionSuffix: permissionSuffix,
                                    parentId: parentId,
                                    parentType: parentType,
                                    objectType: objectType,
                                    value: null
                                }
                            );
                        }
                    }
                }));
            }
            // console.log('isAdmin: 3.1 ');
            return of(false);
        }));
    }

    isAdminUser(): Observable<boolean> {
        return this.getMe().pipe(
            switchMap((user) => {
                if (user) {
                    return this.managerUserPermissionsDaoService.isAdminUser(user.managerUser.id);
                } else {
                    return of(false);
                }
            })
        );
    }
}

import {Component, ViewChild} from '@angular/core';
import {CustomerGroup, ManagerUserOnline} from '../../../models';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserOnlineDetailsComponent} from '../manager-user-online-details/manager-user-online-details.component';
import {filter} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {FormControl} from '@angular/forms';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {MatOption} from '@angular/material/core';

@Component({
    selector: 'app-manager-user-online',
    templateUrl: './manager-user-online.component.html',
    styleUrls: ['./manager-user-online.component.scss', '../status.component.scss']
})
export class ManagerUserOnlineComponent extends ComponentCleaner {


    @ViewChild('allSelected') private allSelected: MatOption;
    showManagerUserOnline = false;
    loading = false;

    partnerCustomerGroupIds: number[] = [];
    partnerCustomerGroups: CustomerGroup[] = [];
    managerUsersOnline: ManagerUserOnline[];
    managerUsersOnlineFilter: ManagerUserOnline[] = [];
    searchFieldControl: FormControl = new FormControl();
    partnerCustomerGroupControl: FormControl = new FormControl();

    emptyCustomerGroups: CustomerGroup = {
        id: -2,
        comment: null,
        active: true,
        customerIds: [],
        managerGroupIds: [],
        softwareCompanyIds: [],
        name: null,
        subProjectIds: [],
        contractedUsers: 3,
        imageFileId: null,
        customPortalTemplateId: null,
        backgroundImageTemplateId: null,
        htmlId: null,
        url: null,
        modified: null,
        optlock: null,
        imageUUID: null,
        partnerCustomerGroupId: null,
        exceededMaximumUsersMessage: null,
        blockAllAccess: null,
        blockAllAccessMessage: null,
        localAdminUsersOnly: null,
    };

    constructor(private managerUserDao: ManagerUserDaoService,
                private customerGroupService: CustomerGroupService,
                public imageFileService: ImageFileService,
                private dialog: MatDialog) {
        super();
        // this.getManagerUsersOnline();

        const subscription2 = this.searchFieldControl.valueChanges.subscribe(() => {
            const searchField = this.searchFieldControl.value;
            if (searchField && searchField.length > 0) {
                this.managerUsersOnlineFilter = this.managerUsersOnline.filter(
                    managerUsersOnline => {
                        const search = searchField.toString().toLowerCase();
                        if (managerUsersOnline?.email?.toString().toLowerCase().indexOf(search) > -1) {
                            return managerUsersOnline;
                        }
                        if (managerUsersOnline?.displayName?.toString().toLowerCase().indexOf(search) > -1) {
                            return managerUsersOnline;
                        }
                        if (managerUsersOnline?.managerUserId?.toString().toLowerCase().indexOf(search) > -1) {
                            return managerUsersOnline;
                        }
                    });
            } else {
                this.managerUsersOnlineFilter = this.managerUsersOnline;
            }
        });
        this.addSubscription(subscription2);


        this.partnerCustomerGroupControl.valueChanges.subscribe((customerGroupIds: any[]) => {
            if (customerGroupIds) {
                const filteredPartnerCustomerGroupIds: number[] = [];
                this.partnerCustomerGroups.forEach((customerGroupIn) => {
                    if (customerGroupIn && customerGroupIds.indexOf(customerGroupIn.id) > -1 ||
                        (customerGroupIds.indexOf(-2) > -1 && !customerGroupIn.id) ||
                        customerGroupIds.indexOf(0) > -1) {
                        filteredPartnerCustomerGroupIds.push(customerGroupIn.id);
                    }
                });
                if (customerGroupIds.some((c) => c === -2)) {
                    filteredPartnerCustomerGroupIds.push(null);
                }
                this.managerUsersOnlineFilter = this.managerUsersOnline.filter(
                    managerUsersOnline => {
                        if (filteredPartnerCustomerGroupIds.some((c) => c === managerUsersOnline?.partnerCustomerGroupId)) {
                            return managerUsersOnline;
                        }
                        if (filteredPartnerCustomerGroupIds.some((c) => c === -2)) {
                            if (!managerUsersOnline?.partnerCustomerGroupId) {
                                return managerUsersOnline;
                            }
                        }
                    });

            } else {
                this.managerUsersOnlineFilter = this.managerUsersOnline;
            }
        });


    }

    getManagerUsersOnline(): void {
        this.showManagerUserOnline = true;
        this.loading = true;
        this.managerUserDao.getManagerUsersOnline().subscribe((managerUsersOnline) => {
            this.loading = false;
            this.managerUsersOnline = managerUsersOnline;
            this.managerUsersOnlineFilter = this.managerUsersOnline;
            this.populateCustomerGroupList(this.managerUsersOnline);
        });
    }

    private populateCustomerGroupList(managerUsersOnline: ManagerUserOnline[]): void {
        managerUsersOnline.forEach((managerUserOnline) => {
            const exist = this.partnerCustomerGroupIds.some((c) => c === managerUserOnline.partnerCustomerGroupId);
            if (!exist && managerUserOnline.partnerCustomerGroupId) {
                this.partnerCustomerGroupIds.push(managerUserOnline.partnerCustomerGroupId);
            }
        });
        if (this.partnerCustomerGroupIds.length > 0) {
            this.findCustomerGroupByIds(this.partnerCustomerGroupIds);
        }
    }

    private findCustomerGroupByIds(ids: number[]): void {
        this.partnerCustomerGroups = this.customerGroupService.customerGroups.filter((c) => ids.some(id => id === c.id));
    }

    managerUserOnlineDetails(managerUserOnline: ManagerUserOnline): void {
        const dialogRef = this.dialog.open(ManagerUserOnlineDetailsComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                managerUserOnline: managerUserOnline,

            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe(() => {
            dialogRef.close();
        });
        this.addSubscription(subscription);
    }


    disconnectSession(managerUserId: number, sessionId: string): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja encerrar a sessão do usuário?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.managerUserDao.disconnectSession({managerUserId, sessionId}).subscribe(() => {
                    this.getManagerUsersOnline();
                });
            }
        });
        this.addSubscription(subscription);
    }


    tosslePerOne(): void {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
        } else {
            if (this.partnerCustomerGroupControl.value.length === this.partnerCustomerGroups.length) {
                this.allSelected.select();
            }
        }
    }

    toggleAllSelection(): void {
        if (this.allSelected.selected) {
            const partnerCustomerGroups: CustomerGroup[] = [];
            this.partnerCustomerGroups.forEach(val => partnerCustomerGroups.push(Object.assign({}, val)));
            partnerCustomerGroups.push(this.emptyCustomerGroups);
            this.partnerCustomerGroupControl
                .patchValue([...partnerCustomerGroups.map(item => item.id), 0]);

        } else {
            this.partnerCustomerGroupControl.patchValue([]);
        }
    }
}

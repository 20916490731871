import {DotNetServerPluginService} from '../services/server-plugin.service';
import {DotNetServerPluginFile} from '../../../models';
import {MatDialog} from '@angular/material/dialog';
import {DotNetServerPluginFileCreateEditComponent} from '../server-plugin-file-create-edit/server-plugin-file-create-edit.component';
import {DotNetServerPluginFileDaoService} from '../services/server-plugin-file-dao.service';
import {Component, OnInit} from '@angular/core';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-server-plugin-file-card',
    templateUrl: './server-plugin-file-card.component.html',
    styleUrls: ['./server-plugin-file-card.component.scss']
})
export class DotNetServerPluginFileCardComponent implements OnInit {

    constructor(
        public serverPluginService: DotNetServerPluginService,
        public serverPluginFileDao: DotNetServerPluginFileDaoService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    onEdit(serverPluginFile: DotNetServerPluginFile): void {
        this.openCreateEditModal(serverPluginFile);
    }

    private openCreateEditModal(serverPluginFile: DotNetServerPluginFile): void {
        this.dialog.open(DotNetServerPluginFileCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                serverPluginFile,
                pluginName: this.getPluginName(serverPluginFile)
            }
        });
    }

    getPluginName(serverPluginFile: DotNetServerPluginFile): string {
        const serverPluginFromFile = this.serverPluginService.getServerPluginFromFile(serverPluginFile);
        if (serverPluginFromFile) {
            return serverPluginFromFile.name;
        }
        return '';
    }

    onDownload(serverPluginFile: DotNetServerPluginFile): void {
        this.serverPluginFileDao.download(serverPluginFile.id).subscribe((data) => {
            if (data) {
                const blob = new Blob([data], {type: 'application/octet-stream'});
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, this.getPluginName(serverPluginFile));
                } else {
                    const a = document.createElement('a') as HTMLAnchorElement;
                    a.style['display'] = 'none';
                    a.href = URL.createObjectURL(blob);
                    a.download = this.getPluginName(serverPluginFile);
                    a.click();
                }
            }
        });
    }

    onDelete(serverPluginFile: DotNetServerPluginFile): void {

        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa versão do plugin?<br><br>' + this.getPluginName(serverPluginFile) + '-' + serverPluginFile.version,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverPluginService.onServerPluginFileDeleteFinished(serverPluginFile, this.serverPluginFileDao.delete(serverPluginFile))
                    .subscribe(deleted => {

                    });
            }
        });
    }
}

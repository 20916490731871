import {Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import {filter, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthInfoService {
    constructor(private authService: AuthService) {
    }

    getWebClientId(): string {
        let _webClientId: string = localStorage.getItem('_webClientId');
        if (!_webClientId) {
            _webClientId = uuid().replace(/-/g, '');
            localStorage.setItem('_webClientId', _webClientId);
        }
        return _webClientId;
    }

    getFirebaseToken(): Observable<string> {
        return this.authService.getFirebaseToken().pipe(filter(o => !!o), take(1));
    }
}

import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective {
  @Output() debounceClick = new EventEmitter();
  private clickCount = 0;

  constructor() { }

  @HostListener('click', ['$event'])
  clickEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.clickCount === 0){
      this.debounceClick.emit(event);
      this.clickCount++;
    }
    setTimeout(() => {
      this.clickCount = 0;
    }, 4000);
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {twoZerosInHourOrMinute} from '../utils/utils-kluh';

@Pipe({
    name: 'twoZeros'
})
export class TwoZerosInHourOrMinutePipe implements PipeTransform {
    transform(num: any): any {
        if (num != null && typeof (num) !== 'undefined') {
            return twoZerosInHourOrMinute(+num);
        }
        return '-';
    }
}

import {Injectable, OnDestroy} from '@angular/core';
import {FeedRetrieverService} from './feed-retriever.service';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from './logger.service';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {Bookmark} from './ts/bookmark';
import {DesktopServer, RemoteApp} from '../models';
import {RemoteAppDaoService} from '../main/remote-app/remote-app-dao.service';
import {DesktopServerDaoService} from '../main/desktop-server/desktop-server-dao.service';
import {R2CloudStompService} from '../ws/r2-cloud-stomp.service';


@Injectable({
    providedIn: 'root'
})
export class FeedTrackerService implements OnDestroy {

    private desktopServerList: DesktopServer[] = [];
    private remoteAppList: RemoteApp[] = [];
    private subject = new Subject<Bookmark>();
    private subscription: Subscription;

    constructor(private h: FeedRetrieverService,
                private logger: LoggerService,
                private http: HttpClient,
                private stomp: R2CloudStompService,
                private dao: RemoteAppDaoService,
                private desktopServerDao: DesktopServerDaoService) {

    }

    getDesktopServer(desktopServerId: number): Observable<DesktopServer> {
        const desktopServer = this.desktopServerList.find((x) => {
            return x.id === desktopServerId;
        });
        if (desktopServer) {
            return of(desktopServer);
        } else {
            return this.desktopServerDao.getOne(desktopServerId);
        }
    }

    getRemoteApp(remoteAppId: number): Observable<RemoteApp> {
        const remoteApp = this.remoteAppList.find((x) => {
            return x.id === remoteAppId;
        });
        if (remoteApp) {
            return of(remoteApp);
        } else {
            return this.dao.getOne(remoteAppId);
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subject.complete();
    }
}

export interface RdpFileResponse {
    rdpFileString: string;
    bookmarkId: string;
}

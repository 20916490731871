<form [formGroup]="myForm" (submit)="(myForm.valid && myForm.dirty) && onSubmit()">
    <div class="dialog-content-wrapper">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">IPs ou FQDN de saída</span>
            </mat-toolbar-row>
        </mat-toolbar>


        <div class="mat-elevation-z2 card-list" fxLayout="column"
             fxLayoutAlign="space-between stretch">
            <div #firewallFQDNContentRef fxLayout="column" class="p-16" fxLayoutAlign="none stretch">

                <div formArrayName="myFormArray">
                    <mat-card *ngFor="let firewallFQDN of myFormControls.controls; let i = index"
                              [formGroupName]="i" style="padding: 25px 10px 25px 27px;margin-bottom: 10px;">
                        <mat-card-content>
                            <div>
                                <mat-form-field appearance="outline" style="width: 500px; height: 70px;">
                                    <mat-label>IP ou FQDN</mat-label>
                                    <input matInput formControlName="fqdn" required>
                                       <mat-error>
                                           {{getControlsOfFormGroup(firewallFQDN).controls.fqdn.errors | error}}
                                       </mat-error>
                                </mat-form-field>
                                <button type="button" class="float-right" style="margin: 15px 10px 0px 0px;"
                                        mat-raised-button color="warn"
                                        (click)="onDelete(firewallFQDN.value, i)">Delete
                                </button>
                            </div>
                            <div style="clear: both"></div>
                        </mat-card-content>
                    </mat-card>
                </div>

                <button type="button" mat-raised-button color="primary" class="text-align-center mt-20 mb-30"
                        (click)="onAddFirewallFQDN()">Add
                </button>

            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="button" mat-raised-button color="accent" [disabled]="!myForm.valid || !myForm.dirty" (click)="onSubmit()">Salvar</button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</form>



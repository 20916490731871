import {Injectable} from '@angular/core';
import {BaseSearchDao} from '../../base-search-dao';
import {TextFile} from '../../models';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class TextFileDaoService extends BaseSearchDao<TextFile>{

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'text-file');
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {GatewayServerComponent} from './gateway-server.component';
import {MatMenuModule} from '@angular/material/menu';
import {GatewayServerListComponent} from './gateway-server-list/gateway-server-list.component';
import {GatewayServerDaoService} from './gateway-server-dao.service';
import {GatewayServerEditComponent} from './gateway-server-edit/gateway-server-edit.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {GatewayServerNatConfigComponent} from './gateway-server-nat-config/gateway-server-nat-config.component';
import {GatewayServerNatConfigModalComponent} from './gateway-server-nat-config/gateway-server-nat-config-modal/gateway-server-nat-config-modal.component';
import {GatewayServerNatConfigDaoService} from './gateway-server-nat-config/gateway-server-nat-config-dao.service';
import {GatewayServerInUseComponent} from './gateway-server-in-use/gateway-server-in-use.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {GatewayServerShowAllComponent} from './gateway-server-show-all/gateway-server-show-all.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {GatewayServerBaseServerListComponent} from './gateway-server-base-server-list/gateway-server-base-server-list.component';
import {GatewayServerScriptInstallComponent} from './gateway-server-script-install/gateway-server-script-install.component';


const routes = [
    {
        path: 'gateway-server',
        component: GatewayServerComponent,
        canActivate: [AuthService],
        data: {
            permissions: ['GatewayServer.CREATE'],
            preCondition: true
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatSelectModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatCardModule,
        MatMenuModule,
        MatTooltipModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        MatCheckboxModule
    ],
    declarations: [
        GatewayServerComponent,
        GatewayServerListComponent,
        GatewayServerEditComponent,
        GatewayServerNatConfigComponent,
        GatewayServerNatConfigModalComponent,
        GatewayServerInUseComponent,
        GatewayServerShowAllComponent,
        GatewayServerBaseServerListComponent,
        GatewayServerScriptInstallComponent],
    providers: [GatewayServerDaoService, GatewayServerNatConfigDaoService]
})
export class GatewayServerModule {
}

export class AESEncryptor {
    constructor(password: string) {
        window.crypto.subtle.importKey('raw', AESEncryptor._stringToArrayBuffer(password), {
            name: 'PBKDF2',
            length: 256,
        }, false, ['deriveKey', 'deriveBits']).then((keyMaterial) => {
            window.crypto.subtle.deriveKey({
                name: 'PBKDF2',
                salt: AESEncryptor._stringToArrayBuffer('SalDoHimalaia'),
                iterations: 4096,
                hash: 'SHA-512',
            }, keyMaterial, {
                name: 'AES-CBC',
                length: 256
            }, true, ['encrypt', 'decrypt']).then((key) => {
                this.key = key;
            });
        });
    }

    private key: any;

    private static _stringToArrayBuffer(string: string): ArrayBuffer {
        const len = string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = string.charCodeAt(i);
        }
        return bytes.buffer as ArrayBuffer;
    }

    decrypt(username: string, domain: string, encrypted: string, auth: any): void {
        const rawData = atob(encrypted);
        const iv = AESEncryptor._stringToArrayBuffer(rawData.substring(0, 16));
        const encryptedData = AESEncryptor._stringToArrayBuffer(rawData.substring(16));
        window.crypto.subtle.decrypt({
            name: 'AES-CBC',
            iv: iv,
        }, this.key, encryptedData).then((result) => {
            // console.debug(new TextDecoder('utf-8').decode(result));
            try {
                auth.complete(username, domain, new TextDecoder('utf-8').decode(result));
                auth.delete();
            } catch (a) {
                console.error(a);
            }
        }, reason => {
            console.error(reason);
        });
    }
}

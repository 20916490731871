import {Component} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {Customer, SnapshotType} from '../../../models';
import {FormControl} from '@angular/forms';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {MatDialog} from '@angular/material/dialog';
import {CustomerDaoService} from '../../customer/customer-dao.service';

@Component({
  selector: 'app-customer-snapshots',
  templateUrl: './customer-snapshots.component.html',
  styleUrls: ['./customer-snapshots.component.scss', '../status.component.scss']
})
export class CustomerSnapshotsComponent {


    snapshots: JaversSnapshot<Customer>[];
    snapshotTypeControl: FormControl = new FormControl();
    sizeListControl: FormControl = new FormControl();
    snapshotType = SnapshotType;
    loading = false;


  constructor(private customerDaoService: CustomerDaoService, private dialog: MatDialog) {
      this.sizeListControl.setValue(5);
      this.snapshotTypeControl.setValue(SnapshotType.ALL);
      // this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
  }



    private snapshotsByTypeAndLimit(size: number, snapshotType: SnapshotType = SnapshotType.ALL): void {
        this.loading = true;
        this.snapshots = [];
        this.customerDaoService.snapshotsByTypeAndLimit(size, snapshotType).subscribe((subProjectsSnapshots) => {
            this.snapshots = subProjectsSnapshots;
            this.loading = false;
        });
    }

    refresh(): void {
        this.snapshotsByTypeAndLimit(this.sizeListControl.value, this.snapshotTypeControl.value);
    }

    onJaversHistory(id: number): void {
        EntityHistoryComponent.openHistory(id, this.customerDaoService, this.dialog);
    }


}

import {Component} from '@angular/core';
import {PointOptionsType} from 'highcharts';
import {AccessStatistics} from '../../../models';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {distinctUntilChanged} from 'rxjs/operators';
import {ComponentCleaner} from '../../../component-cleaner';
import {getArrayDateFromPeriod, Period} from '../../../helpers/kluh';

@Component({
    selector: 'app-access-chart',
    templateUrl: './access-chart.component.html',
    styleUrls: ['./access-chart.component.scss', '../status.component.scss']
})
export class AccessChartComponent extends ComponentCleaner {
    dataSubject = new Subject<PointOptionsType[]>();
    loading = false;
    showChart = false;
    periodFilterControl: FormControl = new FormControl();
    accessStatistics: AccessStatistics;

    public periodList: Array<any> = [
        {
            name: 'Hoje',
            url: Period.today
        },
        {
            name: 'Ontem',
            url: Period.yesterday
        },
        {
            name: 'Últimos 7 dias',
            url: Period.last7days
        },
        {
            name: 'Últimos 30 dias',
            url: Period.last30days
        },
        {
            name: 'Últimos 60 dias',
            url: Period.last60days
        },
        {
            name: 'Esse ano',
            url: Period.thisYear
        }
    ];

    constructor(
        private managerUserDao: ManagerUserDaoService,
        public imageFileService: ImageFileService
    ) {
        super();
        this.periodFilterControl.setValue(this.periodList[0]);
        this.periodFilterControl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe((period: any) => {
            if (!period) {
                this.unloadProject();
            } else {
                this.readData(this.periodFilterControl.value.url);
            }
        });
    }
    readData(period: string): void {
        this.loading = true;
        console.debug('periodo: ' + period);
        const fromAndTo = getArrayDateFromPeriod(period);
        this.managerUserDao.getAllStatistic(fromAndTo[0], fromAndTo[1])
            .subscribe((statistic) => {
                const data = [];
                for (let i = 0; i < statistic.length; i++) {
                    const point: PointOptionsType = {
                        x: statistic[i].x,
                        y: statistic[i].y
                    };
                    data.push(point);
                }
                this.loading = false;
                this.dataSubject.next(data);
            });
    }

    unloadProject(): void {
        this.dataSubject.next([]);
    }

    getData(): void {
        this.showChart = true;
        this.readData(this.periodFilterControl.value.url);
    }
}

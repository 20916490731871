import {Component, OnDestroy} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {ADUser, ADUserPoolRelationship, ManagerUser, RoleIdSubProjectIdManagerUserId} from '../../../models';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {ManagerUserAndAdUserCreateEditComponent} from '../../manager-user-and-ad-user/manager-user-and-ad-user-create-edit/manager-user-and-ad-user-create-edit.component';
import {filter} from 'rxjs/operators';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CrudOperationWrapper, theDateIsGreaterThanNowPlusMinutes} from '../../../helpers/kluh';
import {ManagerUserAdministratorComponent} from '../../iam/manager-user-administrator/manager-user-administrator.component';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AdUserPoolRelationshipEditComponent} from '../../ad-user-pool-relationship/ad-user-pool-relationship-edit/ad-user-pool-relationship-edit.component';
import {AdUserPoolRelationshipDaoService} from '../../ad-user-pool-relationship/ad-user-pool-relationship-dao.service';
import {combineLatest} from 'rxjs';
import {NewUserWizardComponent} from '../../manager-user-and-ad-user/new-user-wizard/new-user-wizard.component';
import {AdUserPoolRelationshipCreateComponent} from '../../ad-user-pool-relationship/ad-user-pool-relationship-create/ad-user-pool-relationship-create.component';
import {AdUserBatchCreateComponent} from '../../ad-user/ad-user-batch-create/ad-user-batch-create.component';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ManagerUserAdministratorInBatchComponent} from '../../iam/manager-user-administrator/manager-user-administrator-in-batch/manager-user-administrator-in-batch.component';
import {RoleSubProjectManagerUserDAOService} from '../../iam/role-sub-project-manager-user-dao.service';

@Component({
    selector: 'app-r2-cloud-admin-aduser',
    templateUrl: './r2-cloud-admin-aduser.component.html',
    styleUrls: ['./r2-cloud-admin-aduser.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminADUserComponent extends ComponentCleaner implements OnDestroy {

    public tabIndex = 0;
    // canAccess = false;

    public timerPendingChangesLoad = null;
    public readonly appCanCreateAdUser: PermissionWrapper[];
    public readonly appCanCreateAdministrator: PermissionWrapper[];
    public readonly appCanCreateADUserPoolRelationship: PermissionWrapper[];

    constructor(private adUserDao: AdUserDaoService,
                public dialogRef: MatDialogRef<R2CloudAdminADUserComponent>,
                public adminService: R2CloudAdminService,
                public adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
                public roleSubProjectManagerUserDAOService: RoleSubProjectManagerUserDAOService,
                public customerGroupService: CustomerGroupService,
                public userAuthorityDaoService: UserAuthorityDaoService,
                private dialog: MatDialog,
                private managerUserDao: ManagerUserDaoService) {
        super();
        this.appCanCreateAdUser = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
        this.appCanCreateAdministrator = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];

        this.appCanCreateADUserPoolRelationship = [
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
    }


    ngOnDestroy(): void {
        clearInterval(this.timerPendingChangesLoad);
    }

    openUserEditModal(adUser: ADUser): void {
        let managerUser = null;
        if (adUser) {
            managerUser = this.adminService.getManagerUser(adUser.managerUserId);
        }
        const dialogRef = this.dialog.open(ManagerUserAndAdUserCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adUser: adUser,
                managerUser: managerUser,
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((adUserSaved) => {
            const adUsers$ = this.adUserDao.filter({
                'subProjectId': this.adminService.subProject.id,
                'serviceUser': adUserSaved.serviceUser
            });
            const managerUsers$ = this.managerUserDao.filter({subProjectId: this.adminService.subProject.id});
            const managerUserOfADUserPoolBySubProjectIds$ = this.managerUserDao.findAllManagerUserOfADUserPoolBySubProjectId(this.adminService.subProject.id);
            combineLatest([adUsers$, managerUsers$, managerUserOfADUserPoolBySubProjectIds$]).subscribe(results => {
                const adUsers = results[0];
                const managerUsers = results[1];
                const managerUsersPool = results[2];
                this.populateManagerUsers(managerUsers, managerUsersPool);
                if (adUserSaved.serviceUser) {
                    throw new Error('serviceUser inside ADUser component');
                } else {
                    this.adminService.adUsers = [];
                    if (adUsers) {
                        this.adminService.adUsers = adUsers;
                    }
                }
            });
        });
        this.addSubscription(subscription);
    }

    openUserPoolEditModal(adUserPoolRelationship: ADUserPoolRelationship): void {
        let managerUser = null;
        if (adUserPoolRelationship) {
            managerUser = this.adminService.getManagerUser(adUserPoolRelationship.managerUserId);
        }
        const dialogRef = this.dialog.open(AdUserPoolRelationshipEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adUserPoolRelationship: adUserPoolRelationship,
                managerUser: managerUser,
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((adUserPoolRelationshipEdit) => {
            const managerUserOfADUserPoolBySubProjectIds$ = this.managerUserDao.findAllManagerUserOfADUserPoolBySubProjectId(this.adminService.subProject.id);
            const adUsersPoolRelationship$ = this.adUserPoolRelationshipDaoService.filter({
                subProjectId: this.adminService.subProject.id,
                serviceUser: adUserPoolRelationshipEdit.serviceUser
            });
            const managerUsers$ = this.managerUserDao.filter({subProjectId: this.adminService.subProject.id});

            combineLatest([adUsersPoolRelationship$, managerUserOfADUserPoolBySubProjectIds$, managerUsers$]).subscribe((results) => {
                const adUsersPoolRelationship = results[0];
                const managerUsersPool = results[1];
                const managerUsers = results[2];
                this.populateManagerUsers(managerUsers, managerUsersPool);
                if (adUserPoolRelationshipEdit.serviceUser) {
                    throw new Error('serviceUser inside ADUser component');
                } else {
                    this.adminService.adUsersPoolRelationship = adUsersPoolRelationship;
                }
                if (adUsersPoolRelationship) {
                    this.adminService.adUsersPoolRelationship = adUsersPoolRelationship;
                } else {
                    this.adminService.adUsersPoolRelationship = [];
                }
            });
        });
        this.addSubscription(subscription);
    }

    private populateManagerUsers(managerUsers: ManagerUser[], managerUsersPool: ManagerUser[]): void {
        if (managerUsers) {
            this.adminService.managerUsers = managerUsers;
        } else {
            this.adminService.managerUsers = [];
        }
        managerUsersPool.forEach((it) => {
            this.adminService.addOrReplaceManagerUser(it);
        });
        if ((!managerUsers || managerUsers?.length === 0) && (!managerUsersPool || managerUsersPool.length === 0)) {
            this.adminService.managerUsers = [];
        }
    }

    openUserAdministratorCreateModal(): void {
        this.dialog.open(ManagerUserAdministratorComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
    }

    openBatchUserAdministratorCreateModal(): void {
        const dialogRef = this.dialog.open(ManagerUserAdministratorInBatchComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: boolean | null) => {
            if (result) {
                const managerUsersAdministrator$ = this.managerUserDao.filter({administratorsInSubProjectId: this.adminService.subProject.id});
                const roleSubProjectManagerUsers$ = this.roleSubProjectManagerUserDAOService.getByParentId(this.adminService.subProject.id);

                forkJoin([managerUsersAdministrator$, roleSubProjectManagerUsers$]).subscribe(resultManagerUser => {
                    const managerUsersAdministrator = resultManagerUser[0];
                    const roleSubProjectManagerUserList = resultManagerUser[1];
                    if (managerUsersAdministrator) {
                        this.adminService.managerUsersAdministrator = managerUsersAdministrator;
                    } else {
                        this.adminService.managerUsersAdministrator = [];
                    }
                    if (roleSubProjectManagerUserList) {
                        this.adminService.roleSubProjectManagerUsers = roleSubProjectManagerUserList;
                    } else {
                        this.adminService.roleSubProjectManagerUsers = [];
                    }
                    this.dialogRef.close();
                });
            } else {
                this.dialogRef.close();
            }
        });
        this.addSubscription(subscription);
    }

    openUserAdministratorEditModal(roleIdSubProjectIdManagerUserId: RoleIdSubProjectIdManagerUserId): void {
        const dialogRef = this.dialog.open(ManagerUserAdministratorComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers,
                roleIdSubProjectIdManagerUserId: roleIdSubProjectIdManagerUserId
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[] | CrudOperationWrapper | null) => {
            if (result && result.constructor === Array) {
                const managerUserOperation = result[0];
                const adUserOperation = result[1];

                if (managerUserOperation.operation === 'CREATE' || adUserOperation.operation === 'CREATE') {
                    const managerUserCheck = this.adminService.managerUsers.find(x => x.id === managerUserOperation.data.id);
                    if (!managerUserCheck) {
                        this.adminService.addManagerUser(managerUserOperation.data);
                    }
                    const adUserCheck = this.adminService.adUsers.find(x => x.id === adUserOperation.data.id);
                    if (!adUserCheck) {
                        this.adminService.addADUser(adUserOperation.data);
                    }

                }

            }
        });
        this.addSubscription(subscription);
    }

    onLinkClick(event: MatTabChangeEvent): void {
        this.tabIndex = event.index;
    }

    theDateIsGreaterThanNowPlusMinutes(updatedAt: Date, minutes: number): boolean {
        return theDateIsGreaterThanNowPlusMinutes(updatedAt, minutes);
    }

    openUserCreateModal(): void {
        const dialogRef = this.dialog.open(NewUserWizardComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper | null) => {
            if (result) {
                const adUserOperation: ADUser = result.data;
                if (result.operation === 'CREATE') {
                    const managerUserCheck = this.adminService.managerUsers.find(x => x.id === adUserOperation.managerUserId);
                    if (!managerUserCheck) {
                        this.managerUserDao.getOne(adUserOperation.managerUserId).subscribe((managerUser) => {
                            this.adminService.addManagerUser(managerUser);
                            this.adminService.addOrReplaceADUser(adUserOperation);
                            this.reloadADUserWithSetTimout(adUserOperation.id, 5000);
                            this.dialogRef.close();
                        });
                    } else {
                        this.adminService.addOrReplaceADUser(adUserOperation);
                        this.dialogRef.close();
                    }
                } else if (result.operation === 'DELETE') {
                    this.adminService.removeADUser(adUserOperation.id);
                    this.dialogRef.close();
                } else {
                    this.dialogRef.close();
                }
            }

        });
        this.addSubscription(subscription);
    }

    openBatchUserCreateModal(): void {
        const dialogRef = this.dialog.open(AdUserBatchCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((adUsers: ADUser[] | null) => {
            if (adUsers) {
                adUsers.forEach((adUser) => {
                    this.adminService.addOrReplaceADUser(adUser);
                });
                const managerUserOfADUserPoolBySubProjectIds$ = this.managerUserDao.findAllManagerUserOfADUserPoolBySubProjectId(this.adminService.subProject.id);
                const managerUsers$ = this.managerUserDao.filter({subProjectId: this.adminService.subProject.id});
                forkJoin([managerUsers$, managerUserOfADUserPoolBySubProjectIds$]).subscribe((result) => {
                    this.adminService.managerUsers = result[0];
                    const managerUserOfADUserPoolBySubProjectIds = result[1];
                    if (managerUserOfADUserPoolBySubProjectIds) {
                        managerUserOfADUserPoolBySubProjectIds.forEach(manageUser => {
                            this.adminService.addOrReplaceManagerUser(manageUser);
                        });
                    }
                    this.reloadADUsersWithSetTimout(5000);
                    this.dialogRef.close();
                });

            } else {
                this.dialogRef.close();
            }
        });
        this.addSubscription(subscription);
    }

    private reloadADUsersWithSetTimout(timer: number): void {
        setTimeout(() => {
            this.adUserDao.filter({
                subProjectId: this.adminService.subProject.id,
                serviceUser: false
            }).subscribe((adUsersReload: ADUser[]) => {
                adUsersReload.forEach(adUser => {
                    this.adminService.addOrReplaceADUser(adUser);
                });
            });
        }, timer);
    }

    private reloadADUserWithSetTimout(adUserId: number, timer: number): void {
        setTimeout(() => {
            this.adUserDao.getOne(adUserId).subscribe((adUserReload: ADUser) => {
                if (adUserReload) {
                    this.adminService.addOrReplaceADUser(adUserReload);
                }
            });
        }, timer);
    }

    openUserPoolCreateModal(): void {
        const dialogRef = this.dialog.open(AdUserPoolRelationshipCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large'
        });
        const subscription = dialogRef.afterClosed().subscribe(() => {
            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }
}

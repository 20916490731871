import {Component, OnDestroy, OnInit} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComponentCleaner} from '../../../component-cleaner';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {ADUser, FirebaseUserDetails, RemoteApp} from '../../../models';
import {RemoteAppCreateEditComponent} from '../../remote-app/remote-app-create-edit/remote-app-create-edit.component';
import {filter} from 'rxjs/operators';
import {CrudOperationWrapper, formatUUID, theDateIsGreaterThanNowPlusMinutes} from '../../../helpers/kluh';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {RemoteAppServiceService} from '../../remote-app/RemoteAppServiceService';

@Component({
    selector: 'app-r2-cloud-admin-remote-app',
    templateUrl: './r2-cloud-admin-remote-app.component.html',
    styleUrls: ['./r2-cloud-admin-remote-app.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminRemoteAppComponent extends ComponentCleaner implements OnInit, OnDestroy {
    timerPendingChangesLoad = null;
    user: FirebaseUserDetails;
    remoteAppLoading: number[] = [];
    canAccess = false;
    appCanCreateList: PermissionWrapper[];
    protected readonly formatUUID = formatUUID;

    constructor(public adminService: R2CloudAdminService,
                private stomp: R2CloudStompService,
                public customerGroupService: CustomerGroupService,
                public userAuthorityDaoService: UserAuthorityDaoService,
                public remoteAppServiceService: RemoteAppServiceService,
                public imageFileService: ImageFileService,
                private dialog: MatDialog,
    ) {
        super();
        this.appCanCreateList = [
            {type: 'RemoteApp', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'RemoteApp', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
    }

    ngOnInit(): void {
        this.userAuthorityDaoService.getMe().subscribe((user) => {
            this.user = user;
        });
        this.userAuthorityDaoService.hasPermission('CustomerGroup', this.customerGroupService.customerGroup.id, 'WRITE').subscribe(userHasPermission => {
            if (userHasPermission && userHasPermission.value) {
                this.canAccess = true;
            }
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.timerPendingChangesLoad);
    }

    onCreateRemoteApp(): void {
        const remoteApp: RemoteApp = {
            id: null,
            active: true,
            comment: null,
            name: null,
            app: null,
            desktopServerId: null,
            adGroupIds: [],
            adUserIds: [],
            adUserPoolRelationshipIds: [],
            appArgs: null,
            serviceApp: false,
            subProjectId: this.adminService.subProject.id,
            alias: null,
            openDelay: 0,
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null,
            imageUUID: null,
            imageBase64: null,
            localUser: null
        };
        this.openRemoteAppModal(remoteApp);
    }

    openRemoteAppModal(remoteApp: RemoteApp): void {
        const dialogRef = this.dialog.open(RemoteAppCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                remoteApp: remoteApp,
                adDomains: this.adminService.adDomains,
                desktopServers: this.adminService.desktopServers,
                adUsers: this.adminService.adUsers,
                adGroups: this.adminService.adGroups,
                managerUsers: this.adminService.managerUsers,
                subProject: this.adminService.subProject,
                adUsersService: this.adminService.adUsersService,
                adUserServiceExternals: this.adminService.adUserServiceExternals
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'CANCEL') {
                return;
            } else if (result.operation === 'CREATE') {
                this.adminService.addRemoteApp(result.data);
            } else if (result.operation === 'SAVE') {
                this.adminService.replaceRemoteApp(result.data);
            } else if (result.operation === 'DELETE') {
                this.adminService.removeRemoteApp(result.data);
            }
            setTimeout(() => {
                this.remoteAppServiceService.loadRemoteAppAndPopulateAdminService(this.adminService.subProject.id);
            }, 8000);
        });
        this.addSubscription(subscription);
    }


    openRemoteApp(remoteAppId: number): void {
        this.remoteAppLoading.push(remoteAppId);
        this.stomp.send('/stomp/open', {remoteAppId: remoteAppId});
        this.removeRemoteAppLoading(remoteAppId);
    }

    removeRemoteAppLoading(remoteAppId: number): void {
        const remoteAppLoading = this.remoteAppLoading;
        const index = remoteAppLoading.findIndex((o) => o === remoteAppId);
        if (index > -1) {
            remoteAppLoading.splice(index, 1);
            this.remoteAppLoading = remoteAppLoading;
        }
    }
    isRemoteAppIsLoading(remoteAppId: number): boolean {
        if (this.remoteAppLoading) {
            const index = this.remoteAppLoading.findIndex((o) => o === remoteAppId);
            if (index > -1) {
                return true;
            }
        }
        return false;
    }


    managerUserCanAccessRemoteApp(remoteApp: RemoteApp): boolean {
        if (!remoteApp || !this.user) {
            return false;
        }
        const adUsers: ADUser[] = this.getAdUsersOfManagerUserId(this.user.managerUser.id);
        let canAccess = this.anyAdUserBelongsToTheRemoteAppAdUserList(remoteApp, adUsers);
        if (!canAccess) {
            canAccess = this.anyAdUserBelongsToTheRemoteAppAdGroupList(remoteApp, adUsers);
        }
        return canAccess;
    }

    getAdUsersOfManagerUserId(managerUserId: number): ADUser[] {
        const adUsers: ADUser[] = [];
        for (const adUser of this.adminService.adUsers) {
            if (adUser.managerUserId === managerUserId) {
                adUsers.push(adUser);
            }
        }
        return adUsers;
    }

    anyAdUserBelongsToTheRemoteAppAdGroupList(remoteApp: RemoteApp, adUsers: ADUser[]): boolean {
        let belongsToRemoteAppGroups = false;
        for (const adGroupId of remoteApp.adGroupIds) {
            const adGroup = this.adminService.getAdGroup(adGroupId);
            if (adGroup) {
                for (const adUserId of adGroup.adUserIds) {
                    for (const adUserManagerUser of adUsers) {
                        if (adUserManagerUser.id === adUserId) {
                            belongsToRemoteAppGroups = true;
                            break;
                        }
                    }
                }
            }
        }
        return belongsToRemoteAppGroups;
    }

    anyAdUserBelongsToTheRemoteAppAdUserList(remoteApp: RemoteApp, adUsers: ADUser[]): boolean {
        let belongsToRemoteAppAdUsers = false;
        for (const adUserId of remoteApp.adUserIds) {
            for (const adUser of adUsers) {
                if (adUserId === adUser.id) {
                    belongsToRemoteAppAdUsers = true;
                    break;
                }
            }
        }
        return belongsToRemoteAppAdUsers;
    }

    theDateIsGreaterThanNowPlusMinutes(updatedAt: Date, minutes: number): boolean {
        return theDateIsGreaterThanNowPlusMinutes(updatedAt, minutes);
    }
}

<button  type="button" mat-raised-button
         class="m-20"
         mat-button color="accent"
         (click)="onCreate()">Criar novo
</button>
<table mat-table matSort [dataSource]="backgroundImageTemplateDataSource" matSortActive="memory" matSortDirection="asc"
       class="width-100-percent mt-25">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-300"> Nome</th>
        <td mat-cell *matCellDef="let element" class="w-300">
            <div>{{element.name}}</div>
            <div *ngIf="element.public" class="primary-200-fg font-size-10">(público)</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="imageFileTemplateIds">
        <th mat-header-cell *matHeaderCellDef class="w-80"></th>
        <td mat-cell *matCellDef="let element" class="pl-10">
            <div *ngIf="element.imageFileTemplateIds">
                <span *ngFor="let imageFileTemplateId of element.imageFileTemplateIds; let i = index;" class="p-5">
                    <img *ngIf="i < 8" src="{{imageFileService.getPictureUrlFromImageFileTemplateId(imageFileTemplateId, 50)}}" width="50">
                </span>
                <span *ngIf="element.imageFileTemplateIds.length > 7">...</span>
            </div>
        </td>
    </ng-container>


    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="w-80"></th>
        <td mat-cell *matCellDef="let element" class="pl-10">
            <button  type="button" mat-raised-button
                     class="no-padding"
                     *ngIf="element.creatorCustomerGroupId == customerGroup.id || isAdmin"
                     mat-button color="accent"
                     (click)="onEdit(element)">Editar
            </button>

            <button type="button" mat-raised-button
                    *ngIf="!element?.public || isAdmin || element.creatorCustomerGroupId == customerGroup.id"
                class="ml-10"
                mat-button color="warn"
                (click)="onDelete(element)">Excluir
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="backgroundImageTemplateDetailsTable"></tr>
    <tr mat-row *matRowDef="let row; columns: backgroundImageTemplateDetailsTable;"
        [ngClass]="{'yellow-200-bg': row.public}"
    ></tr>
</table>

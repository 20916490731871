<td>
    <fa-icon class="s-16 windows-icon" [icon]="faWindows" style="float: left;"></fa-icon>
</td>
<td class="name-server pr-16" >
    <div class="text-max-200" style="width: 100%;"
         title="{{desktopServer.hostname}}">
        <div>{{desktopServer.hostname}}</div>
    </div>
    <div class="font-size-12 secondary-text text-max-120" style="margin-top: 0;width: 100%;"
         *ngIf="adminService.getAdDomainByDesktopServerId(desktopServer.id)?.adServerId == desktopServer.id"
         title="{{adminService.getAdDomain(desktopServer.adDomainId)?.domainName}}">
        {{adminService.getAdDomain(desktopServer.adDomainId)?.domainName}}
    </div>
    <div class="font-size-12 secondary-text text-max-120" style="margin-top: 0;width: 100%;">
        <div>{{desktopServer.privateIp}}</div>
    </div>
</td>
<td class=" font-size-12">
    <div class="text-max-210">{{desktopServer.connectionFQDN}}</div>
</td>
<td class="font-size-12 w-80">
    <div class="text-max-210">
        <div>{{version}}</div>
        <div class="red-A700-fg font-size-16" *ngIf="!desktopServer.serverPluginTemplateIds || desktopServer.serverPluginTemplateIds.length < 1">
            <b  (click)="onEditDesktopServer(desktopServer)" class="blink_me cursor-pointer" title="Inserir template para plugins">sem plugin</b>
        </div>
    </div>
</td>
<td class="text-align-center w-50" style="padding: 0;">
    <div class="client-connected"  title="Online" *ngIf="online; else offline">
        <fa-icon  [icon]="faCircle"></fa-icon>
    </div>
    <ng-template #offline>
        <div class="client-not-connected"  title="Offline - {{desktopServer?.lastLoginAt | date: 'dd/MM/yyyy HH:mm'}}">
            <fa-icon  [icon]="faCircle"></fa-icon>
        </div>
    </ng-template>
</td>
<td class="text-align-right p-0  pr-2" style="width: 100px">
    <button *appIfPermission="'WRITE'; data: desktopServer; type: 'DesktopServer'" type="button"
            mat-raised-button
            class="mat-green-600-bg" (click)="onEditDesktopServer(desktopServer)">
        Edit
    </button>
</td>
<td class="text-align-right p-0  pr-2" style="width: 162px">
    <button mat-raised-button style="width: 150px"  *appIfPermission="'WRITE'; data: desktopServer; type: 'DesktopServer'"
            [routerLink]="['/monitoring-details/processor']"
            [queryParams]="{desktopServerId: desktopServer.id, id: customerGroupService.customerGroup.id }"
            class="mat-green-600-bg">
        Monitoramento
    </button>
</td>
<td class="text-align-right p-0 pr-2" style="width: 100px">
    <button
        *ngIf="user && adminService.checkManagerUserIdHasADAdminOfADDomainAndNoPending(user.managerUser.id, desktopServer.adDomainId) || isAdmin || hasWritePermissionDesktopServer"
        type="button" mat-raised-button
        (mousedown)="onOpenModalADAdmin(desktopServer)"
        class="mat-green-600-bg">
        <span class="accent-A700-fg">Abrir</span>
    </button>

    <button
        *ngIf="user && !adminService.checkManagerUserIdHasADAdminOfADDomainAndNoPending(user.managerUser.id, desktopServer.adDomainId) && !isAdmin && !hasWritePermissionDesktopServer"
        type="button" mat-raised-button
        (mousedown)="openDesktopServer(desktopServer)"
        class="mat-green-600-bg">
        <span>Abrir</span>
    </button>

</td>
<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Criar usuários na filial ({{customer.name}}) da empresa ({{customerGroup.name}}) em lote.</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="space-between start" fxFill class="p-20">
        <div class="mb-10">
            <h3>Separar os e-mails por quebra de linha.</h3>
            <h3>E-mail já cadastrados na plataforma não serão cadastrados.</h3>
        </div>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Lista de e-mails ({{ getAmountOfEmails() }})</mat-label>
            <textarea rows="10" matInput class="full-width"
                      [formControl]="emailsControl"></textarea>
            <mat-error *ngIf="emailsControl.hasError('invalidEmail')">
                <div class="mb-30">
                    <div>Existe algum email inválido!</div>
                    <div> {{ emailsControl.errors.invalidEmail.message }}</div>
                </div>
            </mat-error>
        </mat-form-field>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
        <button type="button" mat-raised-button color="accent" (click)="onCreate()"
                [disabled]="emailsControl.invalid || emailsControl.pristine">Criar
        </button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

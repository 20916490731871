import {StompFrame} from '../ws/stomp-frame';

export interface StompEvent {
    type: StompEventType;
    connect?: StompConnect | null;
    close?: StompClose | null;
    frame?: StompFrame | null;
    error?: string | null;
}

export interface StompConnect {
    url: string;
    heartbeat: number;
}

export interface StompClose {
    code: number;
    reason: string;
    wasClean: boolean;
}

export enum StompEventType {
    OPEN,
    CLOSE,
    FRAME,
    ERROR
}

import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {randomId} from '../kluh';
import * as Highcharts from 'highcharts';
import {PointOptionsType} from 'highcharts';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-highcharts',
    templateUrl: './highcharts.component.html',
    styleUrls: ['./highcharts.component.scss']
})
export class HighchartsComponent implements OnInit, OnDestroy {

    @Input()
    private data$: Observable<PointOptionsType[]>;


    @Input()
    private yMax: number;

    @Input()
    private valueSuffix: string;

    @Input()
    private valuePrefix: string;

    @Input()
    private chartTitle: string;

    @Input()
    private height: number;

    private readonly divId = randomId('hc_');
    private readonly div: HTMLDivElement;
    private chart: Highcharts.Chart;
    private dataSubscription: Subscription;
    loading = true;

    constructor(
        renderer: Renderer2,
        private el: ElementRef
    ) {
        this.div = renderer.createElement('div');
        this.div.id = this.divId;
        renderer.addClass(this.div, 'full-width');
        renderer.appendChild(el.nativeElement, this.div);
    }

    ngOnInit(): void {
        this.destroy();
        this.dataSubscription = this.data$.subscribe((data) => {
            this.loading = false;
            if (this.chart) {
                this.chart.destroy();
            }
            this.chart = Highcharts.chart(this.divId, this.initOptions(data),
                // () => this.hideHighchartsLogo()
            );
        });
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    private destroy(): void {
        this.loading = false;
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        if (this.chart) {
            this.chart.destroy();
        }
    }

    private hideHighchartsLogo(): void {
        const elementsByClassName = this.div.getElementsByClassName('highcharts-credits');
        for (let i = 0; i < elementsByClassName.length; i++) {
            const element = elementsByClassName[i] as HTMLElement;
            element.style.display = 'none';
        }
    }

    private initOptions(data: PointOptionsType[]): Highcharts.Options {
        return {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                height: this.height
                // animation: {
                //     duration: 333,
                //     easing: 'easeInOutCirc'
                // }
            },
            title: {
                text: ''
            },
            legend: {
                enabled: false
            },
            time: {
                // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                useUTC: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e. %b',
                    week: '%e. %b',
                    month: '%b \'%y',
                    year: '%Y'
                }
            },
            yAxis: {
                title: {
                    text: this.chartTitle
                },
                allowDecimals: false,
                max: this.yMax
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    animation: false
                }
            },
            tooltip: {
                valuePrefix: this.valuePrefix,
                valueSuffix: this.valueSuffix
            },
            series: [{
                name: this.chartTitle,
                data: data,
                type: 'area',
                // animation: {
                //     duration: 333,
                //     easing: 'easeInOutCirc'
                // }
            }]
        };
    }
}

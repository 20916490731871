import * as moment from 'moment';
import {getDifferenceBetweenTwoDatesInMinutes} from '../../../../helpers/kluh';


export interface MomentChart {
    begin: string | null;
    end: string | null;
    diffMinutes: number | null;
}

export function getMomentForChart(begin: Date, end: Date): MomentChart {
    const output: MomentChart = {
        begin: null,
        end: null,
        diffMinutes: null
    };
    let beginMoment = moment(begin).startOf('day').toISOString(true);
    let endMoment = moment(end).endOf('day').toISOString(true);
    const diffMinutes = getDifferenceBetweenTwoDatesInMinutes(begin, end);

    if (diffMinutes < 60) {
        beginMoment = moment(begin).toISOString(true);
        endMoment = moment(end).toISOString(true);
    } else if (diffMinutes < 24 * 60) {
        beginMoment = moment(begin).startOf('hour').toISOString(true);
        endMoment = moment(end).endOf('hour').toISOString(true);
    }
    output.begin = beginMoment;
    output.end = endMoment;
    output.diffMinutes = diffMinutes;

    return output;
}

export function searchIndexOf(str: string, search: string): Boolean {
    return (str && str.toString().toLowerCase().indexOf(search) > -1);
}






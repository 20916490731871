import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {filter} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../component-cleaner';
import {SendFilesComponent} from './send-files/send-files.component';
import {FormControl} from '@angular/forms';
import {DesktopServer, SubProject} from '../../models';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {SubProjectDaoService} from '../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {DesktopServerDaoService} from '../desktop-server/desktop-server-dao.service';
import {MatPaginator} from '@angular/material/paginator';
import {UploadFileDaoService} from './upload-file-dao.service';
import {ImageFileService} from '../image-file/image-file-service';

@Component({
    selector: 'app-my-uploads',
    templateUrl: './my-uploads.component.html',
    styleUrls: ['./my-uploads.component.scss']
})
export class MyUploadsComponent extends ComponentCleaner implements OnInit {
    PLUGIN_NAME = 'Upload File';
    displayedColumns: string[] = ['id', 'name', 'size', 'createdAt', 'action'];
    dataSource: UploadFileDaoService;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    totalDocs = 0;

    isDragging = false;
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    desktopServerControl: FormControl = new FormControl();
    subProjectControl: FormControl = new FormControl();
    subProjects: SubProject[] = [];
    desktopServers: DesktopServer[] = [];
    desktopServerId: number;
    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<MyUploadsComponent>,
        public customerGroupService: CustomerGroupService,
        private subProjectDaoService: SubProjectDaoService,
        private desktopServerDaoService: DesktopServerDaoService,
        private uploadFileDaoService: UploadFileDaoService,
        public imageFileService: ImageFileService,
    ) {
        super();
        this.dataSource = this.uploadFileDaoService;
    }

    ngOnInit(): void {
        this.uploadFileDaoService.total$.subscribe(total => {
            this.totalDocs = total;
        });
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.loadSubProjects(customerGroup.id);
            }
        });

        this.subProjectControl.valueChanges.subscribe((subProjectId) => {
            this.loadDesktopServer(subProjectId);
        });
        this.desktopServerControl.valueChanges.subscribe((desktopServerId) => {
            this.desktopServerId = desktopServerId;
            if (desktopServerId) {
                this.findUploadFileByDesktopServerId(desktopServerId);
            }
        });

        this.addTimeout(setInterval(() => {
            if (this.checkHasChangingUploadFile()) {
                this.findUploadFileByDesktopServerId(this.desktopServerId);
            }
        }, 5000));
    }
    private checkHasChangingUploadFile(): boolean {
        return !!this.dataSource.getAll()?.find(uploadFile => uploadFile.changing);
    }
    private loadSubProjects(customerGroupId: number): void {
        this.unloadSubProjects();
        if (customerGroupId) {
            this.subProjectDaoService.getSubProjectsByCustomerGroupId(customerGroupId).subscribe((subProjects) => {
                if (subProjects) {
                    this.subProjects = subProjects;
                    this.subProjectControl.setValue(this.subProjects[0].id);
                }
            });
        }
    }

    private loadDesktopServer(subProjectId: number): void {
        this.unloadDesktopServers();
        if (subProjectId) {
            this.desktopServerDaoService.findAllWithPluginAndHasADUserLogin(subProjectId, this.PLUGIN_NAME).subscribe((desktopServers) => {
                if (desktopServers) {
                    this.desktopServers = desktopServers;
                    if (desktopServers.length === 1) {
                        this.desktopServerControl.setValue(this.desktopServers[0].id);
                    }
                }
            });
        }
    }

    private unloadSubProjects(): void {
        this.subProjects = [];
        this.subProjectControl.setValue('');
    }

    private unloadDesktopServers(): void {
        this.desktopServers = [];
        this.desktopServerControl.setValue('');
        this.dataSource.clearAllData();
    }

    private findUploadFileByDesktopServerId(desktopServerId: number): void {
        if (desktopServerId) {
            const pageEvent = {length: 0, pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize};
            this.dataSource.load('desktopServerId', desktopServerId.toString(), pageEvent);
        } else {
            this.dataSource.clearAllData();
        }
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = false;
        const files = event.dataTransfer?.files;
        if (files) {
            this.handleFiles(files);
        }
    }

    onDragOver(event: DragEvent): void {
        this.isDragging = true;
        event.preventDefault();
    }

    onDragEnd(event: DragEvent): void {
        this.isDragging = false;
        event.preventDefault();
        event.stopPropagation();
    }

    private handleFiles(files: FileList): void {
        this.openSendingFilesModal(files);
    }

    hasDesktopServerSelected(): boolean {
        const subProjectId = +this.subProjectControl.value;
        const desktopServerId = +this.desktopServerControl.value;
        return subProjectId > 0 && desktopServerId > 0;
    }

    openSendingFilesModal(files: FileList): void {
        if (files.length > 0 && this.hasDesktopServerSelected()) {
            const dialogRef = this.dialog.open(SendFilesComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog-x-large',
                data: {
                    files: files,
                    desktopServerId: +this.desktopServerControl.value,
                    subProjectId: +this.subProjectControl.value
                }
            });
            const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result) => {
                if (result) {
                    this.findUploadFileByDesktopServerId(+this.desktopServerControl.value);
                }
            });
            this.addSubscription(subscription);
        }
    }

    onAddFiles(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        this.handleFiles(currentTarget.files);
    }

    openFileInput(): void {
        this.fileInput.nativeElement.click();
    }

}

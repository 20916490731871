<ng-container *ngIf="session">
    <div fxLayout="row" fxLayoutAlign="start center"
         class="remote-app-hover"
         [class.remote-app-active]="isSessionActive()"
         (click)="onSessionClick(session)">
        <div [matTooltip]="session.getLabel()"
             [matTooltipDisabled]="!session.getLabel() || grouped"
             [class.grouped]="grouped"
             class="remote-app-icon-bg"
             matTooltipPosition="right"
             fxLayout="column"
             fxLayoutAlign="center center">
            <div class="remote-app" fxLayout="column" fxLayoutAlign="center center">
                <img *ngIf="session.getThumbnail(); else noThumbnail" [src]="session.getThumbnail()"
                     width="32"
                     height="32" class="remote-app-img"/>
                <ng-template #noThumbnail>
                    <img src="assets/icons/hourglass.png" width="32" height="32" class="remote-app-img" />
                </ng-template>

            </div>
            <div *ngIf="!grouped" class="remote-app-open-indicator-space"
                 [class.remote-app-open-indicator]="true"
                 [class.remote-app-open-indicator-active]="isSessionActive()">
            </div>
        </div>
        <div *ngIf="grouped" class="session-label" fxFlex>
            {{session.getLabel()}}
        </div>
    </div>
</ng-container>

import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HipcomBulkUpdateMessage} from '../../../../../models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HipcomBulkUpdateMessageDaoService} from './hipcom-bulk-update-message-dao.service';
import {convertToFormGroup, CrudOperationWrapper} from '../../../../../helpers/kluh';

@Component({
    selector: 'app-hipcom-bulk-update-message-edit',
    templateUrl: './hipcom-bulk-update-message-edit.component.html',
    styleUrls: ['./hipcom-bulk-update-message-edit.component.scss']
})
export class HipcomBulkUpdateMessageEditComponent {

    myForm: FormGroup;
    hipcomBulkUpdateMessage: HipcomBulkUpdateMessage;

    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdateMessageEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private hipcomBulkUpdateMessageDaoService: HipcomBulkUpdateMessageDaoService,
                private fb: FormBuilder) {

        this.hipcomBulkUpdateMessage = this.data.hipcomBulkUpdateMessage;
        if (!this.hipcomBulkUpdateMessage) {
            this.hipcomBulkUpdateMessage = {
                id: null,
                comment: null,
                active: true,
                message: null,
                timeInMinutes: 10,
                modified: null,
                optlock: null
            };
        }
        this.myForm = this.fb.group(convertToFormGroup(this.hipcomBulkUpdateMessage));
    }


    onSubmit(): void {
        const formValue: HipcomBulkUpdateMessage = this.myForm.value;
        if (formValue.id) {
            this.hipcomBulkUpdateMessageDaoService.save(formValue).subscribe(hipcomBulkUpdateMessage => {
                const crudOperation: CrudOperationWrapper = {
                    data: hipcomBulkUpdateMessage,
                    operation: 'SAVE'
                };
                this.dialogRef.close(crudOperation);
            });
        } else {
            this.hipcomBulkUpdateMessageDaoService.create(formValue).subscribe(hipcomBulkUpdateMessage => {
                const crudOperation: CrudOperationWrapper = {
                    data: hipcomBulkUpdateMessage,
                    operation: 'CREATE'
                };
                this.dialogRef.close(crudOperation);
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }


}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'error'
})
export class ErrorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      for (const key in value) {
        if (key === 'required') {
          return 'Requerido';
        } else if (value.hasOwnProperty(key)) {
          const message = value[key];
          if (key === 'max') {
            return 'max: ' + message.max;
          } else if (key === 'min'){
            return 'min: ' + message.min;
          } else {
            if (message === true) {
              return null;
            }
            return message;
          }
        }
      }
    }
    return null;
  }

}

import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {DigitalCertificate} from '../../models';
import {BaseSearchDao} from '../../base-search-dao';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {PageEvent} from '@angular/material/paginator';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ValueWrapper} from '../../helpers/value-wrapper';


@Injectable()
export class DigitalCertificateDaoService extends BaseSearchDao<DigitalCertificate> implements DataSource<DigitalCertificate> {

    private subject = new BehaviorSubject<DigitalCertificate[]>([]);
    private totalSubject = new BehaviorSubject<number>(0);

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'digital-certificate');
    }

    uploadFile(file: File, password: string, subProjectId: number, desktopServerId: number, customerGroupId: number): Observable<ValueWrapper> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('password', password);
        formData.append('customerGroupId', customerGroupId.toString());
        return this.http.upload<ValueWrapper>(formData, this.url + `/create`);
    }

    filter(params?: any): Observable<DigitalCertificate[]> {
        return this.http.get<DigitalCertificate[]>(this.url + '/filter', params);
    }

    connect(collectionViewer: CollectionViewer): Observable<DigitalCertificate[] | ReadonlyArray<DigitalCertificate>> {
        this.subject = new BehaviorSubject<DigitalCertificate[]>([]);
        return this.subject.asObservable();
    }

    clearAllData(): void {
        this.subject = new BehaviorSubject<DigitalCertificate[]>([]);
        this.totalSubject = new BehaviorSubject<number>(0);
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.totalSubject.complete();
        this.subject = null;
        this.totalSubject = null;
    }

    load(field: string, value: string, page: PageEvent): void {
        if (this.subject && this.totalSubject) {
            this.search(
                {field: field, value: value, limit: page.pageSize, page: page.pageIndex, fieldOrder: 'id', order: 'desc'}
            ).subscribe((value) => {
                this.subject.next(value.content);
                this.totalSubject.next(value.totalElements);
            });
        }
    }

    removeItem(id: number): void {
        const digitalCertificateList = this.subject.getValue().filter((value, key) => {
            return value.id !== id;
        });
        this.subject.next(digitalCertificateList);
    }

    get total$(): Observable<number> {
        if (!this.totalSubject) {
            this.totalSubject = new BehaviorSubject<number>(0);
        }
        return this.totalSubject.asObservable();
    }

    onRemove(digitalCertificate: DigitalCertificate): Observable<void> {
        return this.http.post<void>(this.url + '/on-remove', digitalCertificate);
    }
}

export interface LocalUploadFile {
    name: string | null;
    size: number | null;
    position: number | null;
    blocked: boolean | null;
    status: FileStatus | null;
}

export enum FileStatus {
    TO_SEND = 'TO_SEND',
    SENDING = 'SENDING',
    SENT = 'SENT',
}


import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ValueWrapper} from '../../helpers/value-wrapper';
import {DomainPathService} from '../../domain-path/domain-path.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupDaoPublicService {


    constructor(private http: HttpClient,
                private domainPathService: DomainPathService
    ) {

    }

    getImageUUID(customerGroupUrl: string): Observable<ValueWrapper> {
        const path = this.domainPathService.apiURL + 'customer-group/p/image-uuid/' + customerGroupUrl;
        return this.http.get<ValueWrapper>(path);
    }
}

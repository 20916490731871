<div fxLayout="column" id="confirmDialog" fxLayoutAlign="space-between stretch">
    <div>
        <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
            <mat-icon class="nav-link-icon" *ngIf="icon.startsWith('fa'); else notFa"
                      fontSet="fa"
                      [fontIcon]="icon"></mat-icon>
            <ng-template #notFa>
                <mat-icon class="icon">{{icon}}</mat-icon>
            </ng-template>
        </mat-toolbar>
    </div>
    <div class="p-16 message" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-body-2" [innerHTML]="message"></span>
    </div>

    <div *ngIf="!disableCancel">
        <button mat-raised-button type="button" color="primary" class="md-small" (click)="onCancel()">Cancelar</button>
    </div>
</div>

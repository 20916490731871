import {Pipe, PipeTransform} from '@angular/core';
import {FilterArgs} from './filter-args';

@Pipe({
  name: 'filterArgs',
  pure: false
})
export class FilterArgsPipe implements PipeTransform {

  transform(items: any[], filter: FilterArgs): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item[filter.field].indexOf(filter.value) !== -1);

  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Session} from '../../ts/session';

@Component({
    selector: 'app-session-single',
    templateUrl: './session-single.component.html',
    styleUrls: ['../session-view.component.scss']
})
export class SessionSingleComponent {
    private _session: Session;
    private _activeSession: string;
    private _grouped: boolean;

    constructor() {
    }


    @Input()
    set session(session: Session) {
        this._session = session;
    }

    get session(): Session {
        return this._session;
    }

    @Input()
    set grouped(value: boolean){
        this._grouped = value;
    }

    get grouped(): boolean {
        return this._grouped;
    }

    @Input()
    set activeSession(activeSession: string){
        this._activeSession = activeSession;
    }

    @Output()
    sessionClick = new EventEmitter<Session>();

    isSessionActive(): boolean {
        if (this._session && this._session.id){
            return this._session.id === this._activeSession;
        }
        return false;
    }

    onSessionClick(session: Session): void {
        this.sessionClick.emit(session);
    }
}

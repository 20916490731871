<form (submit)="onSubmit()" [formGroup]="myForm">

    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Agendamento da atualização:</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent >


        <table style="width: 170px">
            <thead>
            <tr>
                <th class="day-of-week" style="width: 40%"></th>
                <th class="tit col-start"></th>

            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="day-of-week">
                    SEG
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="mondayStartHours" name="mondayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="mondayStartMinutes" name="mondayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="day-of-week">
                    TER
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="tuesdayStartHours" name="tuesdayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="tuesdayStartMinutes" name="tuesdayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="day-of-week">
                    QUA
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="wednesdayStartHours" name="wednesdayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="wednesdayStartMinutes" name="wednesdayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="day-of-week">
                    QUI
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="thursdayStartHours" name="thursdayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="thursdayStartMinutes" name="thursdayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="day-of-week">
                    SEX
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="fridayStartHours" name="fridayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="fridayStartMinutes" name="fridayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="day-of-week">
                    SÁB
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="saturdayStartHours" name="saturdayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="saturdayStartMinutes" name="saturdayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="day-of-week">
                    DOM
                </td>
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                    type="number" formControlName="sundayStartHours" name="sundayStartHours">
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                  formControlName="sundayStartMinutes" name="sundayStartMinutes">
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="submit" mat-raised-button color="accent" aria-label="Salvar">
            Salvar
        </button>
        <button type="button" mat-raised-button color="warn" aria-label="Cancelar" (click)="onCancel()">
            Cancelar
        </button>
    </div>
</form>


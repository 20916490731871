<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>Versão do Agente nos Servidores</div>
            <button *ngIf="!loading" mat-raised-button type="button" color="primary" class="md-small" (click)="getData()">
                <span *ngIf="!alreadyRead">Show</span>
                <span *ngIf="alreadyRead">Update</span>
            </button>
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table *ngIf="desktopServerVersionCountList && desktopServerVersionCountList.length > 0"
               class="statistic-table width-100-percent" cellspacing="0" cellpadding="0">
            <thead>
            <tr>
                <th class="w-100"></th>
                <th></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr style="border-bottom: solid 2px #555555">
                <td>
                </td>
                <td>
                    <h4>Versões encontradas:</h4>
                    <ul>
                        <li *ngFor="let desktopServerVersionCount of desktopServerVersionCountList">
                            <div>versão: <b>{{desktopServerVersionCount.version}}</b> | qt :
                                <b>{{desktopServerVersionCount.amount}}</b></div>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr *ngFor="let desktopServerIdWithVersion of desktopServerIdWithVersionList">
                <td>
                    <div>
                        <div><b>{{desktopServerIdWithVersion.version}}</b></div>
                    </div>
                </td>
                <td>
                    <ul class="desktop-server-version">
                        <li *ngIf="statusService.getDesktopServer(desktopServerIdWithVersion.desktopServerId, desktopServerWithSubProjectIdsList) as result">
                            <div><small>DesktopServerID:
                                <b>{{result.desktopServerDTO.id}}</b></small>
                            </div>
                            <div><small>Hostname:
                                <b>{{result.desktopServerDTO.hostname}}</b></small>
                            </div>
                            <div><small>FQDN:
                                <b>{{result.desktopServerDTO.connectionFQDN}}</b></small>
                            </div>
                            <div *ngIf="
                            result &&
                            result.subProjectIDs > 0">
                                <small>Empresas:
                                    <ul>
                                        <li *ngFor="let subProjectId of result.subProjectIDs">
                                            -
                                            <b *ngIf="statusService.getSubProject(subProjectId, subProjects) as subProject">
                                                {{statusService.getCustomerGroup(subProject.customerGroupId, customerGroups)?.name}}
                                            </b>
                                        </li>
                                    </ul>
                                </small>
                            </div>
                        </li>
                    </ul>
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>

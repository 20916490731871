import {Component, OnInit} from '@angular/core';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {MatDialog} from '@angular/material/dialog';
import {ClientVersionExpression, DotNetServerPlugin, DotNetServerPluginFile} from '../../../models';
import {DotNetServerPluginCreateEditComponent} from '../server-plugin-create-edit/server-plugin-create-edit.component';
import {DotNetServerPluginFileCreateEditComponent} from '../server-plugin-file-create-edit/server-plugin-file-create-edit.component';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {DotNetServerPluginDaoService} from '../services/server-plugin-dao.service';

@Component({
    selector: 'app-server-plugin-card',
    templateUrl: './server-plugin-card.component.html',
    styleUrls: ['./server-plugin-card.component.scss']
})
export class DotNetServerPluginCardComponent implements OnInit {

    constructor(
        public serverPluginService: DotNetServerPluginService,
        public serverPluginDao: DotNetServerPluginDaoService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    onCreate(): void {
        const serverPlugin: DotNetServerPlugin = {
            active: true,
            comment: '',
            id: undefined,
            name: '',
            serverPluginFileIds: [],
            modified: null,
            optlock: null

        };
        this.openCreateEditModal(serverPlugin);
    }

    onCreateServerPluginFile(serverPlugin: DotNetServerPlugin): void {
        const serverPluginFile: DotNetServerPluginFile = {
            active: true,
            comment: '',
            id: undefined,
            maxClientVersion: undefined,
            maxClientVersionExpression: ClientVersionExpression.ANY,
            md5: '',
            minClientVersion: undefined,
            minClientVersionExpression: ClientVersionExpression.ANY,
            serverPluginId: serverPlugin.id,
            version: '',
            dependencyIds: [],
            modified: null,
            optlock: null,
            fileUUID: null
        };
        this.dialog.open(DotNetServerPluginFileCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                serverPluginFile,
                pluginName: serverPlugin.name
            }
        });
    }

    onEdit(serverPlugin: DotNetServerPlugin): void {
        this.openCreateEditModal(serverPlugin);
    }

    private openCreateEditModal(serverPlugin: DotNetServerPlugin): void {
        this.dialog.open(DotNetServerPluginCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                serverPlugin
            }
        });
    }

    onDelete(serverPlugin: DotNetServerPlugin): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse Plugin?<br><br>' + serverPlugin.name,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverPluginDao.remove(serverPlugin.id).subscribe(deleted => {
                    this.serverPluginService.deleteFromList(serverPlugin.id, this.serverPluginService.serverPlugins);
                });
            }
        });
    }
}

<div fxLayout="column" class="mt-26">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div>Usuários com acesso a Apps de Serviços ({{(adminService.adUsersService?.length + adminService.adUserServiceExternals?.length + adminService.findADUsersPoolRelationshipByADUserPoolService(true)?.length) }})</div>
                <div>
                    <button *appIfPermission="appCanCreateList"
                            type="button"
                            mat-raised-button color="accent" (click)="openServiceUserCreateModal()">
                        Criar
                    </button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="
        adminService.adUsersService && adminService.adUsersService.length > 0 ||
        adminService.adUserServiceExternals && adminService.adUserServiceExternals.length > 0 ||
        adminService.adUsersPoolService && adminService.findADUsersPoolRelationshipByADUserPoolService(true).length > 0" >
            <div fxLayout="column mt-4">
                <table class="remote-app-table" border="0"  cellspacing="0" cellpadding="0">
                    <thead>
                    <tr class="bg-white">
                        <th>Nome no AD</th>
                        <th>E-mails</th>
                        <th>Dominio</th>
                        <th>Status</th>
                        <th class="text-align-center min-width-88"></th>
                    </tr>
                    </thead>
                    <tr *ngFor="let adUserPoolRelationship of adminService.findADUsersPoolRelationshipByADUserPoolService(true)">
                        <td>
                            <div class="text-max-250" title="{{adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId).login}}" >{{adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId).login}}</div>
                        </td>
                        <td>
                            <div *ngFor="let managerUserId of adUserPoolRelationship?.managerUserServiceIds" style="height: 19px;clear: both;"  >
                                <div class="text-max-250" style="padding: 4px  4px  4px  4px;" title="{{adminService.getManagerUser(managerUserId)?.email}}" >
                                    {{adminService.getManagerUser(managerUserId)?.email}}
                                </div>
                            </div>
                        </td>
                        <td>{{adminService.getAdDomain(adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.adDomainId)?.name}}</td>
                        <td class="text-align-center">
                            <div *ngIf="adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.changing || adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.deleting">
                                <mat-icon *ngIf="!theDateIsGreaterThanNowPlusMinutes(adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.updatedAt,15)" title="Criando... {{adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.updatedAt}}">add_circle</mat-icon>
                                <mat-icon class="red-A700-fg"  *ngIf="theDateIsGreaterThanNowPlusMinutes(adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.updatedAt,15)" title="Algo errado, está demorando muito para executar a tarefa. {{adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId)?.updatedAt}}">error</mat-icon>
                            </div>
                        </td>
                        <td class="text-align-right p-0  pr-2">
                            <button *appIfPermission="'WRITE'; data: adminService.getAdUserPoolRelationship(adUserPoolRelationship.id); type: 'ADUserPoolRelationship'" type="button"
                                    mat-raised-button class="mat-green-600-bg"
                                    (click)="adUserServiceService.openUserPoolCreateModal(adUserPoolRelationship, null)">Edit
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let adUser of adminService.adUsersService">
                        <td>
                            <div class="text-max-250" title="{{adUser?.login}}" >{{adUser?.login}}</div>
                        </td>
                        <td>
                            <div *ngFor="let managerUserId of adUser?.managerUserServiceIds" style="height: 19px;clear: both;"  >
                               <div class="text-max-250" style="padding: 4px  4px  4px  4px;" title="{{adminService.getManagerUser(managerUserId)?.email}}" >
                                   {{adminService.getManagerUser(managerUserId)?.email}}
                               </div>
                            </div>
                        </td>
                        <td>{{adminService.getAdDomain(adUser?.adDomainId)?.name}}</td>
                        <td class="text-align-center">
                            <div *ngIf="adUser?.changing || adUser?.deleting">
                                <mat-icon *ngIf="!theDateIsGreaterThanNowPlusMinutes(adUser?.updatedAt,15)" title="Criando... {{adUser?.updatedAt}}">add_circle</mat-icon>
                                <mat-icon class="red-A700-fg"  *ngIf="theDateIsGreaterThanNowPlusMinutes(adUser?.updatedAt,15)" title="Algo errado, está demorando muito para executar a tarefa. {{adUser?.updatedAt}}">error</mat-icon>
                            </div>
                        </td>
                        <td class="text-align-right p-0  pr-2">
                            <button *appIfPermission="'WRITE'; data: adUser; type: 'ADUser'" type="button"
                                    mat-raised-button class="mat-green-600-bg"
                                    (click)="openServiceUserEditModal(adUser, null)">Edit
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let adUserService of adminService.adUserServiceExternals">
                        <td>
                            <div class="text-max-250" title="{{adUserServiceService.findADUser(adUserService.adUserId)?.login}}" >{{adUserServiceService.findADUser(adUserService.adUserId)?.login}}</div>
                        </td>
                        <td>
                            <div *ngFor="let managerUserId of adUserServiceService.findADUser(adUserService.adUserId)?.managerUserServiceIds" style="height: 19px;clear: both;"  >
                               <div class="text-max-250" style="padding: 4px  4px  4px  4px;" title="{{adUserServiceService.findManagerUser(managerUserId)?.email}}" >
                                   {{adUserServiceService.findManagerUser(managerUserId)?.email}}
                               </div>
                            </div>
                        </td>
                        <td>{{adminService.getAdDomain(adUserServiceService.findADUser(adUserService.adUserId)?.adDomainId)?.name}}</td>
                        <td class="text-align-center">
                            <div *ngIf="adUserServiceService.findADUser(adUserService.adUserId)?.changing || adUserServiceService.findADUser(adUserService.adUserId)?.deleting">
                                <mat-icon *ngIf="!theDateIsGreaterThanNowPlusMinutes(adUserServiceService.findADUser(adUserService.adUserId)?.updatedAt,15)" title="Criando... {{adUserServiceService.findADUser(adUserService.adUserId)?.updatedAt}}">add_circle</mat-icon>
                                <mat-icon class="red-A700-fg"  *ngIf="theDateIsGreaterThanNowPlusMinutes(adUserServiceService.findADUser(adUserService.adUserId)?.updatedAt,15)" title="Algo errado, está demorando muito para executar a tarefa. {{adUserServiceService.findADUser(adUserService.adUserId)?.updatedAt}}">error</mat-icon>
                            </div>
                        </td>
                        <td class="text-align-right p-0  pr-2">
                            <button *appIfPermission="'WRITE'; data: adUserService; type: 'ADUserServiceExternal'" type="button"
                                    mat-raised-button class="mat-green-600-bg"
                                    (click)="openServiceUserEditModal(adUserServiceService.findADUser(adUserService.adUserId), adUserService)">Edit
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="dialog-content-wrapper">
    <form [formGroup]="myForm">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de Servidor Linux usando token</span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <div *ngIf="token" class="mb-30 mt-10">
                <p>Instrução de como instalar o agente do Linux Server.</p>
                <code>
                    {{script}}
                </code>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <div *ngIf="script">
                <button *ngIf="!copied" type="button" mat-raised-button color="accent" (click)="onCopyScript(script)">Copiar código
                </button>
                <b class="blue-700-fg pl-10">{{copied}}</b>
            </div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </form>
</div>

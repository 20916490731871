import {Injectable} from '@angular/core';
import {LoggerService} from './logger.service';
import {CredentialStoreService} from './credential-store.service';
import {ObservableInput, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserAuthInfoService {
    private j: any = !1;

    constructor(private logger: LoggerService, private credentialStore: CredentialStoreService) {
    }

    private i = (): Promise<any>  => {
        return new Promise((resolve, reject) => {
            resolve();
        });
        // const b = c.defer();
        // return g.getUrlPath().then(function(c) {
        //     a.get(c + "logoff.aspx", {
        //         rdFeedRequest: !0
        //     }).then(function(a) {
        //         b.resolve(a)
        //     }, function(a) {
        //         b.reject(a)
        //     })
        // }, angular.noop), b.promise
    };

    logout(): void {
        const aa = (a: any) => {
            401 !== a.status && this.logger.error('Sign out unexpectedly failed, reason:' + JSON.stringify(a)), window.location.reload();
        };
        this.i().then(aa, aa);
    }

    isAuthenticated(): any {
        return this.j;
    }

    isInteractiveLoginRequired(): any {
        return !0;
    }

    getUserName(): any {
        return null;
    }

    getUPN(): any {
        return null;
    }

    getCachedToken(): any {
        return null;
    }

    login(bb: any, ii: any): any {
        // let k = c.defer(),
        let i = ii;
        let b = bb;
        let l = !1;
        // return b && g.getUrlPath().then((c) => {
        //     a.post(c + 'login.aspx', 'DomainUserName=' + encodeURIComponent(b) + '&UserPass=' + encodeURIComponent(i), {
        //         rdFeedRequest: !0,
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //     }).then(() => {
        //         l || (d.$broadcast('rdFeedTracker:loggedIn'), j = !0, this.credentialStore.storeCreds(b, i)), this.logger.log('Log in succeeded.'), k.resolve();
        //     }, function (a) {
        //         f.error('Log in failed.'), k.reject({
        //             result: 'HttpFailed',
        //             httpResult: a
        //         });
        //     });
        // }, angular.noop), k.promise;
        return new Promise((resolve, reject) => {
            if (!b) {
                const m = this.credentialStore.getSavedCreds();
                m ? (b = m.userName, i = m.password, l = !0) : setTimeout(() => {
                    reject({
                        result: 'ReLoginFailedNoSavedCreds'
                    });
                });
            }
            of(true).pipe(catchError((a, bbb): ObservableInput<any> => {
                this.logger.error('Log in failed.'), reject({
                    result: 'HttpFailed',
                    httpResult: a
                });
                return throwError(a);
            })).subscribe(() => {
                if (!l) {
                    this.j = !0, this.credentialStore.storeCreds(b, i);
                    this.logger.log('Log in succeeded.'), resolve();
                }
            });
        });
    }
}

<div class="dialog-content-wrapper">
    <form [formGroup]="myForm"
          id="marketplace-product-create-and-edit-form"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de produto ou serviço</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <div class="form-full-size">
                <mat-form-field appearance="outline">
                    <mat-label>Nome do prduto ou serviço</mat-label>
                    <input matInput formControlName="name" autocomplete="off"
                           id="marketplace-product-create-and-edit-name" >
                    <mat-error>
                        {{myForm.get('name').errors | error}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Nome da empresa</mat-label>
                    <input matInput formControlName="company" autocomplete="off"
                           id="marketplace-product-create-and-edit-company">
                    <mat-error>
                        {{myForm.get('company').errors | error}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Site da empresa</mat-label>
                    <input matInput formControlName="companyWebsite" autocomplete="off"
                           id="marketplace-product-create-and-edit-company-website">
                    <mat-error>
                        {{myForm.get('companyWebsite').errors | error}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Categoria</mat-label>
                    <mat-select matNativeControl formControlName="marketplaceCategoryId"
                                id="marketplace-product-create-and-edit-category-select" >
                        <mat-option [value]="category.id"  id="{{category.name}}"
                                    *ngFor="let category of marketplaceCategoryList">{{category.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                        {{myForm.get('marketplaceCategoryId').errors | error}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Descrição resumida</mat-label>
                    <textarea matInput formControlName="shortDescription"
                              id="marketplace-product-create-and-edit-short-description"
                              rows="3" maxlength="126"></textarea>
                    <mat-error>
                        {{myForm.get('shortDescription').errors | error}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Descrição completa</mat-label>
                    <textarea matInput formControlName="description"
                              id="marketplace-product-create-and-edit-description"
                              rows="15"></textarea>
                    <mat-error>
                        {{myForm.get('description').errors | error}}
                    </mat-error>
                </mat-form-field>

                <div class="full-width m-20">
                    <div>

                        <img src="{{imageFileService.getMarketPlaceProductAvatarPictureUrl(myForm.get('avatarUUID').value)}}"
                             width="344"
                             height="195"
                             *ngIf="!imageFileElement[0] && myForm.get('id').value">

                        <img [src]="'data:image/jpg;base64,' + imageFileElement[0]" width="344" height="195"
                             *ngIf="imageFileElement[0]">
                    </div>
                    <button style="width: 196px;margin-top: 5px;" type="button" mat-raised-button
                            class="green-800-bg green-800"
                            onclick="document.getElementById('avatarUpload').click()">
                        Alterar Foto (344x195px)
                    </button>
                    <input (change)="onImageFileChange($event, 0)" name="file"
                           id="avatarUpload" accept="image/*" type="file">
                </div>

                <div class="full-width m-20">
                    <div>

                        <img src="{{imageFileService.getMarketPlaceProductBannerPictureUrl(myForm.get('bannerUUID').value)}}"
                             width="450"
                             *ngIf="!imageFileElement[1] && myForm.get('id').value">

                        <img [src]="'data:image/jpg;base64,' + imageFileElement[1]" width="450"
                             *ngIf="imageFileElement[1]">
                    </div>
                    <button style="width: 196px;margin-top: 5px;" type="button" mat-raised-button
                            class="green-800-bg green-800"
                            onclick="document.getElementById('bannerUpload').click()">
                        Alterar Foto (880x250px)
                    </button>
                    <input (change)="onImageFileChange($event, 1)" name="file"
                           id="bannerUpload" accept="image/*" type="file">
                </div>


                <div class="full-width m-20">
                    <mat-form-field appearance="outline">
                        <mat-select placeholder="Empresas bloqueadas" formControlName="blockedSoftwareCompanyIds"
                                    id="marketplace-product-create-and-edit-blocked-software-select"
                                    [multiple]="true" #multiSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="softwareCompanyMultiFilterCtrl"
                                                       placeholderLabel="busca"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let softwareCompany of filteredSoftwareCompanyMulti | async; let i = index;"
                                        [value]="softwareCompany.id" id="marketplace-product-create-and-edit-blocked-software-select-id-{{i}}">
                                {{softwareCompany.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="mt-20 mb-20">
                    <mat-slide-toggle formControlName="active" name="active"  id="marketplace-product-create-and-edit-active">
                        Ativo
                    </mat-slide-toggle>
                </div>

            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>

            <button type="submit" mat-raised-button color="accent"
                    id="marketplace-product-create-and-edit-create"
                    [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="marketplaceProduct.id;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>


            <button type="button" mat-raised-button color="primary"
                    id="marketplace-product-create-and-edit-cancel"
                    (click)="onCancel()">Cancelar</button>

        </div>
    </form>
</div>

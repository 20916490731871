import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../../base-dao';
import {HipcomBulkUpdateMessage} from '../../../../../models';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';

@Injectable()
export class HipcomBulkUpdateMessageDaoService extends BaseDao<HipcomBulkUpdateMessage> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'partners-hipcom-bulk-update-message');
    }

    sendMessage(hipcomBulkUpdateMessage: HipcomBulkUpdateMessage, hipcomBulkUpdateDetailId: number): Observable<void> {
        return this.http.post<void>(this.url + '/send-message/' + hipcomBulkUpdateDetailId, hipcomBulkUpdateMessage);
    }

    sendMessageToAll(hipcomBulkUpdateMessage: HipcomBulkUpdateMessage): Observable<void> {
        return this.http.post<void>(this.url + '/send-message-to-all', hipcomBulkUpdateMessage);
    }
}

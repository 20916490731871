import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HipcomBulkUpdateCloseSoftware, HipcomBulkUpdatePath} from '../../../../../models';
import {CrudOperationWrapper} from '../../../../../helpers/kluh';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {HipcomBulkUpdateCloseSoftwareDaoService} from './hipcom-bulk-update-close-software-dao.service';

@Component({
    selector: 'app-hipcom-bulk-update-close-software-create-edit',
    templateUrl: './hipcom-bulk-update-close-software-create-edit.component.html',
    styleUrls: ['./hipcom-bulk-update-close-software-create-edit.component.scss'],
    queries: {
        'contentRef': new ViewChild('contentRef', {static: false})
    }
})
export class HipcomBulkUpdateCloseSoftwareCreateEditComponent extends ComponentCleaner {

    myForm: FormGroup;
    myFormArray: FormArray;
    contentRef!: ElementRef;
    myFormControls: FormGroup;

    constructor(public dialogRef: MatDialogRef<HipcomBulkUpdateCloseSoftwareCreateEditComponent>,
                private hipcomBulkUpdateCloseSoftwareDaoService: HipcomBulkUpdateCloseSoftwareDaoService,
                private fb: FormBuilder,
                private dialog: MatDialog) {
        super();
        this.myFormArray = this.fb.array([]);
        this.myForm = this.fb.group({myFormArray: this.myFormArray});
        this.myFormControls = this.myForm.controls['myFormArray'] as FormGroup;
        this.hipcomBulkUpdateCloseSoftwareDaoService.get().subscribe(hipcomBulkUpdateCloseSoftwareList => {
            if (hipcomBulkUpdateCloseSoftwareList) {
                for (const hipcomBulkUpdateCloseSoftware of hipcomBulkUpdateCloseSoftwareList) {
                    this.onAddHipcomBulkUpdatePath(hipcomBulkUpdateCloseSoftware);
                }
            } else {
                this.onAddHipcomBulkUpdatePath();
            }
        });
    }

    onAddHipcomBulkUpdatePath(hipcomBulkUpdateCloseSoftware?: HipcomBulkUpdateCloseSoftware): void {
        this.myFormArray.push(this.buildHipcomBulkUpdatePath(hipcomBulkUpdateCloseSoftware));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }


    buildHipcomBulkUpdatePath(hipcomBulkUpdateCloseSoftware?: HipcomBulkUpdateCloseSoftware): FormGroup {
        let bulkUpdateCloseSoftware: HipcomBulkUpdateCloseSoftware = {
            id: null, active: true, comment: null,
            name: null,
            path: null,
            executableName: null,
            service: null,
            modified: null,
            optlock: null
        };
        if (hipcomBulkUpdateCloseSoftware) {
            bulkUpdateCloseSoftware = hipcomBulkUpdateCloseSoftware;
        }
        return this.fb.group(bulkUpdateCloseSoftware);
    }

    onHipcomBulkUpdatePath(hipcomBulkUpdateCloseSoftware?: HipcomBulkUpdateCloseSoftware): void {
        this.myFormArray.push(this.buildHipcomBulkUpdatePath(hipcomBulkUpdateCloseSoftware));
        setTimeout(() => {
            this.contentRef.nativeElement.scrollTo(0, this.contentRef.nativeElement.scrollHeight);
        }, 50);
    }


    onSubmit(): void {
        const hipcomBulkUpdateCloseSoftwareList: HipcomBulkUpdateCloseSoftware[] = this.myForm.value.myFormArray;
        const hipcomBulkUpdateCreateList = [];
        const hipcomBulkUpdateSaveList = [];

        for (const hipcomBulkUpdate of hipcomBulkUpdateCloseSoftwareList) {
            if (hipcomBulkUpdate.id) {
                hipcomBulkUpdateSaveList.push(hipcomBulkUpdate);
            } else {
                hipcomBulkUpdateCreateList.push(hipcomBulkUpdate);
            }
        }
        this.hipcomBulkUpdateCloseSoftwareDaoService.saveAll(hipcomBulkUpdateSaveList).subscribe((hipcomBulkUpdateSavedList) => {
            this.hipcomBulkUpdateCloseSoftwareDaoService.createAll(hipcomBulkUpdateCreateList).subscribe((hipcomBulkUpdateCreatedList) => {
                const allHipcomBulkUpdate: HipcomBulkUpdatePath[] = [];
                if (hipcomBulkUpdateSavedList) {
                    for (const bulkUpdate of hipcomBulkUpdateSavedList) {
                        allHipcomBulkUpdate.push(bulkUpdate);
                    }
                }
                if (hipcomBulkUpdateCreatedList) {
                    for (const bulkUpdate of hipcomBulkUpdateCreatedList) {
                        allHipcomBulkUpdate.push(bulkUpdate);
                    }
                }
                const crudOperation: CrudOperationWrapper = {
                    operation: 'SAVE',
                    data: allHipcomBulkUpdate
                };
                this.dialogRef.close(crudOperation);
            });
        });
    }

    onRemove(bulkUpdate: HipcomBulkUpdateCloseSoftware): void {
        if (bulkUpdate?.id !== null) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Você tem certeza que deseja remover esse Software da lista? ' +
                        '<br><div class="warn-A700-fg">' + bulkUpdate.executableName + '</div>',
                    disableCancel: false,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                if (result) {
                    this.hipcomBulkUpdateCloseSoftwareDaoService.remove(bulkUpdate.id).subscribe((removed) => {
                        const crudOperation: CrudOperationWrapper = {
                            operation: 'DELETE',
                            data: bulkUpdate
                        };
                        this.dialogRef.close(crudOperation);
                    });
                }
            });
            this.addSubscription(subscription);
        } else {
            this.removeFromList(bulkUpdate);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    public removeFromList(bulkUpdate: HipcomBulkUpdateCloseSoftware): void {
        for (let i = 0; i < this.myFormArray.value.length; i++) {
            if (this.myFormArray.value[i] === bulkUpdate) {
                this.myFormArray.removeAt(i);
            }
        }
    }
}

<div fxLayout="column" class="mt-26" *appIfPermission="canReadList">
    <mat-card class="server-list-admin">
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div>R2 Scaling</div>
                <button style="width: 105px" type="button" *appIfPermission="canCreateList" (click)="openCreate()"
                        mat-raised-button color="accent">
                    Criar
                </button>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="mb-60" *ngFor="let desktopServerPool of desktopServerPoolList">
                <div fxLayoutAlign="space-between center" class="mb-15 server-pool-title">
                    <div class="font-size-18">{{ desktopServerPool.name }}</div>
                    <button style="width: 105px" type="button" mat-raised-button  class="mat-green-600-bg"  *appIfPermission="canEditList"
                    (click)="openEdit(desktopServerPool)">
                        Edit
                    </button>
                </div>
                <table class="remote-app-table width-90-percent ml-20" cellspacing="0" cellpadding="0">
                    <thead>
                    <tr class="bg-white">
                        <th class="w-30"></th>
                        <th class="w-80">Client Id</th>
                        <th>Hostname</th>
                        <th>IP</th>
                        <th class="w-60">Versão</th>
                        <th class="w-30">Status</th>
                        <th class="w-150"></th>
                    </tr>
                    </thead>
                    <tr *ngFor="let desktopServer of getAllDesktopServerByDesktopServerPoolId(desktopServerPool.id)" [ngClass]="{'blue-50-bg': desktopServer.id === desktopServerPool.appBaseDesktopServerId}">
                        <td style="color: #777777" title="Servidor criado dinamicamente"><fa-icon [icon]="faCopy" *ngIf="desktopServer.disposable" ></fa-icon></td>
                        <td>{{ formatUUID(desktopServer.clientId) }}</td>
                        <td>{{ desktopServer.hostname }}</td>
                        <td>{{ desktopServer.connectionFQDN }}{{ getStringConnectionPort(desktopServer.connectionPort) }}</td>
                        <td>{{ getDesktopServerVersion(desktopServer.id) }}</td>
                        <td>
                            <div class="client-connected ml-15"  title="Online" *ngIf="isDesktopServerConnected(desktopServer.id); else offline">
                                <fa-icon  [icon]="faCircle"></fa-icon>
                            </div>
                            <ng-template #offline>
                                <div class="client-not-connected ml-15"  title="Offline - {{desktopServer?.lastLoginAt | date: 'dd/MM/yyyy HH:mm'}}">
                                    <fa-icon  [icon]="faCircle"></fa-icon>
                                </div>
                            </ng-template>
                        </td>
                        <td>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <button type="button" mat-raised-button  class="mat-green-600-bg mr-5" *appIfPermission="canEditList"
                                        (click)="onEditDesktopServer(desktopServer)">
                                    Edit
                                </button>

                                <button
                                    *ngIf="user && adminService.checkManagerUserIdHasADAdminOfADDomainAndNoPending(user.managerUser.id, desktopServer.adDomainId) || isAdmin"
                                    type="button" mat-raised-button
                                    (mousedown)="onOpenModalADAdmin(desktopServer)"
                                    class="mat-green-600-bg">
                                    <span class="accent-A700-fg">Abrir</span>
                                </button>

                                <button
                                    *ngIf="user && !adminService.checkManagerUserIdHasADAdminOfADDomainAndNoPending(user.managerUser.id, desktopServer.adDomainId) && !isAdmin"
                                    type="button" mat-raised-button
                                    (mousedown)="openDesktopServer(desktopServer)"
                                    class="mat-green-600-bg">
                                    <span>Abrir</span>
                                </button>
                            </div>


                        </td>
                    </tr>
                </table>



                <mat-accordion class="width-90-percent ml-20 mt-10 desktop-server-pool " style="display: block" *ngIf="getAllDesktopServerByDesktopServerPoolIdDisposableAndOffline3Days(desktopServerPool.id).length > 0">
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Servidores offline
                            </mat-panel-title>
                            <mat-panel-description>
                                Servidores offline a muito tempo que foram criados dinâmicamente.
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <table class="remote-app-table full-width" cellspacing="0" cellpadding="0">
                            <thead>
                            <tr class="bg-white">
                                <th class="w-30"></th>
                                <th class="w-80">Client Id</th>
                                <th>Hostname</th>
                                <th>IP</th>
                                <th class="w-60">Login</th>
                                <th class="w-30">Status</th>
                                <th class="w-150"></th>
                            </tr>
                            </thead>
                            <tr *ngFor="let desktopServer of getAllDesktopServerByDesktopServerPoolIdDisposableAndOffline3Days(desktopServerPool.id)"
                                [ngClass]="{'blue-50-bg': desktopServer.id === desktopServerPool.appBaseDesktopServerId}">
                                <td style="color: #777777" title="Servidor criado dinamicamente"><fa-icon [icon]="faCopy" *ngIf="desktopServer.disposable" ></fa-icon></td>
                                <td>{{ formatUUID(desktopServer.clientId) }}</td>
                                <td>{{ desktopServer.hostname }}</td>
                                <td>{{ desktopServer.connectionFQDN }}{{ getStringConnectionPort(desktopServer.connectionPort) }}</td>
                                <td>{{desktopServer?.lastLoginAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                                <td>
                                    <div class="client-connected ml-15"  title="Online" *ngIf="isDesktopServerConnected(desktopServer.id); else offline">
                                        <fa-icon  [icon]="faCircle"></fa-icon>
                                    </div>
                                    <ng-template #offline>
                                        <div class="client-not-connected ml-15"  title="Offline - {{desktopServer?.lastLoginAt | date: 'dd/MM/yyyy HH:mm'}}">
                                            <fa-icon  [icon]="faCircle"></fa-icon>
                                        </div>
                                    </ng-template>
                                </td>
                                <td>

                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <button type="button" mat-raised-button  class="mat-green-600-bg mr-5" *appIfPermission="canEditList"
                                                (click)="onEditDesktopServer(desktopServer)">
                                            Edit
                                        </button>

                                        <button
                                            *ngIf="user && adminService.checkManagerUserIdHasADAdminOfADDomainAndNoPending(user.managerUser.id, desktopServer.adDomainId) || isAdmin"
                                            type="button" mat-raised-button
                                            (mousedown)="onOpenModalADAdmin(desktopServer)"
                                            class="mat-green-600-bg">
                                            <span class="accent-A700-fg">Abrir</span>
                                        </button>

                                        <button
                                            *ngIf="user && !adminService.checkManagerUserIdHasADAdminOfADDomainAndNoPending(user.managerUser.id, desktopServer.adDomainId) && !isAdmin"
                                            type="button" mat-raised-button
                                            (mousedown)="openDesktopServer(desktopServer)"
                                            class="mat-green-600-bg">
                                            <span>Abrir</span>
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        </table>
                    </mat-expansion-panel>
                </mat-accordion>


            </div>
        </mat-card-content>
    </mat-card>
</div>
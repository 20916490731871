import {Component, Inject} from '@angular/core';
import {AdDomainDaoService} from '../ad-domain-dao.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, CrudOperationWrapper} from '../../../helpers/kluh';
import {DesktopServerCreateComponent} from '../../desktop-server/desktop-server-create/desktop-server-create.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {filter} from 'rxjs/operators';
import {ADDomain, DesktopServer, SubProject} from '../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {Router} from '@angular/router';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-ad-domain-create',
    templateUrl: './ad-domain-create.component.html',
    styleUrls: ['./ad-domain-create.component.scss']
})
export class AdDomainCreateComponent extends ComponentCleaner {
    myForm: FormGroup;
    public subProject: SubProject;
    public adDomains: ADDomain[] = [];
    public projectAdDomains: ADDomain[] = [];
    public adDomain: ADDomain;
    public showADDomainForm = '';
    public adDomainFilterControl: FormControl = new FormControl();
    public desktopServers: DesktopServer[];

    constructor(public dialogRef: MatDialogRef<AdDomainCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private adDomainDaoService: AdDomainDaoService,
                private desktopServerDao: DesktopServerDaoService,
                private dialog: MatDialog,
                private router: Router,
                private fb: FormBuilder,
                public adminService: R2CloudAdminService) {
        super();
        this.adDomain = data.adDomain;
        this.subProject = data.subProject;
        this.projectAdDomains = data.projectAdDomains;
        this.desktopServers = data.desktopServers;
        // um adServer NÃO pode estar em mais de um domínio.


        if (!this.adDomain) {
            this.adDomain = {
                id: null,
                active: true,
                comment: null,
                name: '',
                subProjectIds: [this.subProject.id],
                domainName: '',
                adServerId: null,
                customerGroupId: null,
                modified: null,
                optlock: null
            };
            this.adDomainDaoService.getActive().subscribe((adDomains) => {
                this.adDomains = adDomains;
                if (this.projectAdDomains) {
                    this.projectAdDomains.forEach((adDomain) => {
                        const index: number = this.adDomains.findIndex(x => x.id === adDomain.id);
                        if (index !== -1) {
                            this.adDomains.splice(index, 1);
                        }
                    });
                }
            });
        } else {
            this.showADDomainForm = 'y';
        }

        this.myForm = this.fb.group(convertToFormGroup(this.adDomain), {asyncValidator: this.adDomainDaoService.validator});
        this.myForm.get('domainName').setValidators([Validators.required]);
        this.myForm.addControl('adDomains', new FormControl(''));
    }


    onSubmit(): void {
        let value: ADDomain;
        if (this.showADDomainForm === 'n') {
            value = this.adDomainFilterControl.value;
            value.subProjectIds.push(this.subProject.id);
        } else {
            value = this.myForm.value;
        }
        if (value.id) {
            this.adDomainDaoService.save(value).subscribe((adDomain) => {
                this.dialogRef.close(adDomain);
            });
        } else {
            this.adDomainDaoService.create(value).subscribe((adDomain) => {
                this.dialogRef.close(adDomain);
            });
        }
    }

    goToCustomerGroupId(customerGroupId: number): void {
        this.router.navigate(['admin'], {queryParams: {id: customerGroupId}}).then(p => {
            if (p) {
                this.dialogRef.close();
            } else {
                this.adminService.unload();
                window.location.href = '/admin?id=' + customerGroupId;
            }
        });
    }

    currentSubProjectIdIsEqualADDomainSubProjectId(): boolean {
        return this.subProject.customerGroupId === this.adDomain.customerGroupId;
    }


    onCancel(): void {
        this.dialogRef.close();
    }

    onSelectShowADDomain(input: string): void {
        this.showADDomainForm = input;
    }

    onEditAdServer(desktopServerId: number): void {
        this.desktopServerDao.getOne(desktopServerId).subscribe((desktopServerFromDao) => {
            const dialogRef = this.dialog.open(DesktopServerCreateComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog-large',
                data: {
                    desktopServer: desktopServerFromDao,
                    adDomains: this.adminService.adDomains,
                    subProject: this.adminService.subProject,
                    desktopServers: this.adminService.desktopServers,
                    project: this.adminService.getProject(this.adminService.subProject.projectId)

                }
            });
            const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
                this.desktopServerCallback(result);
                this.dialogRef.close();
            });
            this.addSubscription(subscription);
        });
    }

    private desktopServerCallback(result: CrudOperationWrapper[]): void {
        if (result && result.length === 2) {
            const desktopServerOperation = result[0];
            const subProjectOperation = result[1];
            const desktopServer: DesktopServer = desktopServerOperation.data;
            if (desktopServerOperation.operation === 'CREATE') {
                this.adminService.addDesktopServer(desktopServer);
            } else if (desktopServerOperation.operation === 'SAVE') {
                this.adminService.replaceDesktopServer(desktopServer);
            }
            if (subProjectOperation.operation === 'SAVE') {
                this.adminService.subProject = subProjectOperation.data;
            }
            if (subProjectOperation.operation === 'DELETE') {
                this.adminService.removeDesktopServer(desktopServer.id);
                this.adminService.subProject = subProjectOperation.data;
            }
        }
    }

    onRemove(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse dominio?<h2 class="warn-900-fg">Esse procedimento não tem volta</h2><br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adDomainDaoService.remove(this.adDomain.id).subscribe(() => {
                    this.dialogRef.close();
                    this.router.navigate(['/register']);
                });
            }
        });
    }

    onRemoveFromProject(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse dominio desse projeto?<h2 class="warn-900-fg">Esse procedimento não tem volta</h2><br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adDomainDaoService.removeFromProject(this.adDomain.id, this.subProject.id).subscribe(() => {
                    this.dialogRef.close();
                    this.router.navigate(['/register']);
                });
            }
        });
    }


    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.adDomain.id, this.adDomainDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.adDomain.id, this.adDomainDaoService, this.dialog);
    }

}

export enum KeyCodes {
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    Return = 13,
    Esc = 27,
    Backspace = 8,
    Tab = 9,
    Left = 37,
    Up = 38,
    Right = 39,
    Down = 40,
    CapsLock = 20,
    Delete = 46,
    End = 35,
    Home = 36,
    Insert = 45,
    NumLock = 144,
    Spacebar = 32,
    PageUp = 33,
    PageDown = 34,
    Shift = 16,
    Windows = 91,
    RWindows = 93,
    Ctrl = 17,
    Alt = 18,
    Pause = 19,
    ScrollLock = 145,
    PrintScreen = 44,
    Cmd1 = 91,
    Cmd2 = 93,
    Cmd3 = 224
}
export class NumericPadKeyCodes {
    144 = 'NumLock'; 
    96 = 'Numpad0'; 
    97 = 'Numpad1'; 
    98 = 'Numpad2'; 
    99 = 'Numpad3'; 
    100 = 'Numpad4'; 
    101 = 'Numpad5'; 
    102 = 'Numpad6'; 
    103 = 'Numpad7'; 
    104 = 'Numpad8'; 
    105 = 'Numpad9'; 
    106 = 'NumpadMultiply'; 
    107 = 'NumpadAdd'; 
    109 = 'NumpadSubtract'; 
    111 = 'NumpadDivide'; 
    108 = '"NumpadComma" inputting "," or "."'; 
    188 = '"NumpadComma" inputting ","'; 
    194 = '"NumpadComma" inputting ",", "NumpadComma" inputting "."'; 
    190 = '"NumpadComma" inputting "."'; 
    110 = '"NumpadDecimal" inputting "."'; 
    12 = 'NumpadEqual'; 
    187 = 'NumpadEqual'; 
    61 = 'NumpadEqual';
}
export class CtrlAltCharacterShortCuts {
    86 = 'Ctrl + Alt + V Excel paste special';
    107 = 'Ctrl + Alt + "+" Excel zoom in NumpadAdd';
    192 = 'Ctrl + Alt + "+" Excel zoom in Equal with shift Linux / Mac on Chromium 34';
    222 = 'Ctrl + Alt + "+" Excel zoom in Equal with shift Japanese';
    187 = 'Ctrl + Alt + "-"  Excel zoom out Minus Japanese keyboards, Excel zoom in Equal with shift non-Japanese';
    189 = 'Ctrl + Alt + "-" Excel zoom out';
    109 = 'Ctrl + Alt + "-"  Excel zoom out NumpadSubtract';
}

import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Permission} from '../../../../models';
import {BaseDao} from '../../../../base-dao';
import {Observable} from 'rxjs';
import {R2CloudHttpMonitoringService} from '../../../../r2-cloud-http-monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoringPermissionDaoService extends BaseDao<Permission> {
    constructor(http: R2CloudHttpMonitoringService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'permission');
    }

    clearCacheByWebClientId(): Observable<void> {
        return this.http.get<void>(this.url + '/clear-cache-by-web-client-id');
    }
}

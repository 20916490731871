import {KluhManagerValidator} from './validators/kluh-manager-validator';
import {AsyncValidatorFn} from '@angular/forms';
import {R2CloudHttpBase} from './r2-cloud-http.base';

export abstract class BaseIdStringDao<T extends BaseDTOIdString> {
    protected url: string;
    validator: AsyncValidatorFn;

    protected constructor(
        protected http: R2CloudHttpBase,
        protected validatorDAO: KluhManagerValidator,
        url: string
    ) {
        this.url = url;
        this.validator = this.validatorDAO.validator(this.url, this.http);
    }
}

export interface BaseDTOIdString {
    id: string | null;
    comment: string | null;
    active: boolean | null;
}

<div class="dialog-content-wrapper">
    <form [formGroup]="myForm"
          id="marketplace-product-faq-edit-form"
          (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro e edição do FAQ</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div #contentRef class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
            <div formArrayName="myFormArray">
                <mat-card *ngFor="let productFAQ of myFormControls.controls; let i = index"
                          [formGroupName]="i">
                    <mat-card-content>
                        <div>
                            <mat-form-field appearance="outline" class="mr-30 width-100-percent">
                                <mat-label>
                                    Pergunta
                                </mat-label>
                                <input matInput formControlName="question"
                                       id="marketplace-product-faq-edit-question-{{i+1}}"
                                       required autocomplete="off">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="mr-30 width-100-percent">
                                <mat-label>
                                    Resposta
                                </mat-label>
                                <textarea matInput formControlName="answer"
                                          id="marketplace-product-faq-edit-answer-{{i+1}}"
                                          rows="4" ></textarea>
                            </mat-form-field>

                        </div>

                        <button type="button" class="float-right" mat-raised-button color="warn"
                                id="marketplace-product-faq-edit-delete-{{i}}"
                                (click)="onRemove(productFAQ.value)">Delete
                        </button>
                        <div style="clear: both"></div>
                    </mat-card-content>
                </mat-card>
            </div>

            <button type="button" mat-raised-button color="primary"
                    id="marketplace-product-faq-edit-add"
                    class="text-align-center mt-20 mb-30"
                    (click)="onAddProductFAQ()">Add
            </button>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>

            <button type="submit" mat-raised-button color="accent"
                    id="marketplace-product-faq-edit-create"
                    [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="marketplaceProduct.id;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <button type="button" mat-raised-button color="primary"
                    id="marketplace-product-faq-edit-cancelar"
                    (click)="onCancel()">Cancelar</button>

        </div>
    </form>
</div>

<div class="product-detail-content-tab bg-grey" *ngIf="user">
    <div fxLayout="column" fxLayoutAlign="start start" class="footer">
        <div class="footer-inner">
            <div>
                <div *ngIf="!myComment" fxLayout="row" fxLayoutAlign="start start" class="reply mb-30"
                     style="width: 100%;">
                    <img class="avatar"
                         src="{{imageFileService.getManagerUserPictureUrl(user.managerUser.imageUUID, 40)}}"/>
                    <form fxFlex [formGroup]="myForm"
                           id="marketplace-product-comment-form"
                          (submit)="myForm.valid && commentRating>0 && myForm.dirty && onSubmit()"
                          autocomplete="off">
                        <textarea placeholder="coloque seu comentário..."
                                  id="marketplace-product-comment-text"
                                  formControlName="comment"></textarea>
                        <div>
                            <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="mb-10">
                                <div class="product-detail-rating rating-select">
                                    <mat-icon class="star1"
                                              id="marketplace-product-comment-star1"
                                              (mouseover)="marketplaceService.ratingSelect(1,5, elRef)"
                                              (mouseout)="marketplaceService.ratingUnSelect(1,5, elRef)"
                                              (click)="onRatingSelected(1,5)">
                                        star
                                    </mat-icon>
                                    <mat-icon class="star2"
                                              id="marketplace-product-comment-star2"
                                              (mouseover)="marketplaceService.ratingSelect(2,5, elRef)"
                                              (mouseout)="marketplaceService.ratingUnSelect(2,5, elRef)"
                                              (click)="onRatingSelected(2,5)">
                                        star
                                    </mat-icon>
                                    <mat-icon class="star3"
                                              id="marketplace-product-comment-star3"
                                              (mouseover)="marketplaceService.ratingSelect(3,5, elRef)"
                                              (mouseout)="marketplaceService.ratingUnSelect(3,5, elRef)"
                                              (click)="onRatingSelected(3,5)">
                                        star
                                    </mat-icon>
                                    <mat-icon class="star4"
                                              id="marketplace-product-comment-star4"
                                              (mouseover)="marketplaceService.ratingSelect(4,5, elRef)"
                                              (mouseout)="marketplaceService.ratingUnSelect(4,5, elRef)"
                                              (click)="onRatingSelected(4,5)">
                                        star
                                    </mat-icon>
                                    <mat-icon class="star5"
                                              id="marketplace-product-comment-star5"
                                              (mouseover)="marketplaceService.ratingSelect(5,5, elRef)"
                                              (mouseout)="marketplaceService.ratingUnSelect(5,5, elRef)"
                                              (click)="onRatingSelected(5,5)">
                                        star
                                    </mat-icon>
                                </div>
                                <div class="font-size-12 mr-5"> Nota</div>
                            </div>
                        </div>
                        <button type="submit" mat-raised-button color="accent"
                                [disabled]="(!myForm.valid || myForm.pristine) || (commentRating < 1)"
                        >Enviar comentário
                        </button>
                    </form>
                </div>
                <div *ngIf="myComment">
                    <div class="h2">Meu comentário</div>
                    <div class="messages">
                        <div class="message-row m-20">
                            <div fxLayout="row" fxLayoutAlign="start start" class="contact">
                                <div>
                                    <img class="avatar"
                                         src="{{imageFileService.getManagerUserPicture(adminService.getManagerUser(myComment.managerUserId))}}?w=40&square=true"/>
                                </div>
                                <div>
                                    <div fxLayout="column" fxLayoutAlign="start start">
                                        <div fxLayout="column" fxLayoutAlign="start start"
                                             class="width-100-percent">
                                            <div fxLayout="row" fxLayoutAlign="space-between start"
                                                 class="width-100-percent">
                                                <div class="line-height-18">
                                                    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                                                        <div class="product-detail-rating">
                                                            <mat-icon
                                                                class="{{marketplaceService.getStarRatingComment(myComment.rating, 1)}} font-size-20">
                                                                star
                                                            </mat-icon>
                                                            <mat-icon
                                                                class="{{marketplaceService.getStarRatingComment(myComment.rating, 2)}} font-size-20">
                                                                star
                                                            </mat-icon>
                                                            <mat-icon
                                                                class="{{marketplaceService.getStarRatingComment(myComment.rating, 3)}} font-size-20">
                                                                star
                                                            </mat-icon>
                                                            <mat-icon
                                                                class="{{marketplaceService.getStarRatingComment(myComment.rating, 4)}} font-size-20">
                                                                star
                                                            </mat-icon>
                                                            <mat-icon
                                                                class="{{marketplaceService.getStarRatingComment(myComment.rating, 5)}} font-size-20">
                                                                star
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxFlex fxLayout="row" fxLayoutAlign="end start"
                                                     class="edit-message"
                                                     *ngIf="user.managerUser.id === myComment.managerUserId">
                                                    <button mat-icon-button class="edit" aria-label="Edit"
                                                            id="marketplace-product-comment-edit"
                                                            title="Editar" (click)="onEdit(myComment)">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button mat-icon-button class="delete" aria-label="Delete"
                                                            id="marketplace-product-comment-delete"
                                                            title="Deletar" (click)="onDelete(myComment)">
                                                        <mat-icon>delete_forever</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                    <span class="username "
                                                          *ngIf="adminService.getManagerUser(myComment.managerUserId) as managerUser; else notFound">
                                                        <span *ngIf="managerUser?.displayName?.length > 1"> {{managerUser?.displayName}} </span>
                                                        <span *ngIf="managerUser?.displayName?.length < 1"> {{managerUser?.email}} </span>
                                                    </span>
                                                <ng-template #notFound class="username">
                                                    <span class="warn-A700-fg">{{myComment.email}}</span>
                                                </ng-template>
                                                <span
                                                    class="time  ml-5"> {{myComment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                                            </div>
                                        </div>
                                        <div class="message  mt-5">
                                            {{myComment.comment}}
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between center"
                                             class="actions"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-10 mb-20"/>
            <div fxLayout="row" *ngIf="marketplaceProductCommentList && marketplaceProductCommentList.length > 0"
                 fxLayoutAlign="start center" class="comment-count">
                <span *ngIf="marketplaceProductCommentList.length > 1">
                    {{marketplaceProductCommentList.length}} comentários
                </span>
                <span *ngIf="marketplaceProductCommentList.length == 1">
                    1 comentário
                </span>
                <mat-icon class="s-16" aria-hidden="true">keyboard_arrow_down</mat-icon>
            </div>
            <div class="messages">
                <div class="message-row m-20">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div fxLayout="row" fxLayoutAlign="start start" class="contact"
                             *ngFor="let comment of marketplaceProductCommentList">
                            <div>
                                <img class="avatar"
                                     src="{{imageFileService.getManagerUserPicture(adminService.getManagerUser(comment.managerUserId))}}?w=40&square=true"/>
                            </div>
                            <div>
                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div fxLayout="column" fxLayoutAlign="start start" class="width-100-percent">
                                        <div fxLayout="row" fxLayoutAlign="space-between start"
                                             class="width-100-percent">
                                            <div class="line-height-18">
                                                <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                                                    <div class="product-detail-rating">
                                                        <mat-icon
                                                            class="{{marketplaceService.getStarRatingComment(comment.rating, 1)}} font-size-20">
                                                            star
                                                        </mat-icon>
                                                        <mat-icon
                                                            class="{{marketplaceService.getStarRatingComment(comment.rating, 2)}} font-size-20">
                                                            star
                                                        </mat-icon>
                                                        <mat-icon
                                                            class="{{marketplaceService.getStarRatingComment(comment.rating, 3)}} font-size-20">
                                                            star
                                                        </mat-icon>
                                                        <mat-icon
                                                            class="{{marketplaceService.getStarRatingComment(comment.rating, 4)}} font-size-20">
                                                            star
                                                        </mat-icon>
                                                        <mat-icon
                                                            class="{{marketplaceService.getStarRatingComment(comment.rating, 5)}} font-size-20">
                                                            star
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex fxLayout="row" fxLayoutAlign="end start"
                                                 class="edit-message"
                                                 *ngIf="user.managerUser.id === comment.managerUserId">
                                                <button mat-icon-button class="edit" aria-label="Edit"
                                                        id="marketplace-product-comment-me-edit"
                                                        title="Editar" (click)="onEdit(comment)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                                <button mat-icon-button class="delete" aria-label="Delete"
                                                        title="Deletar" (click)="onDelete(comment)">
                                                    <mat-icon>delete_forever</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                        <span class="username "
                                              *ngIf="adminService.getManagerUser(comment.managerUserId) as managerUser; else notFound">
                                                        <span *ngIf="managerUser?.displayName?.length > 1"> {{managerUser?.displayName}} </span>
                                                        <span *ngIf="managerUser?.displayName?.length < 1"> {{managerUser?.email}} </span>
                                                    </span>
                                            <ng-template #notFound class="username">
                                                <span class="warn-A700-fg">{{comment.email}}</span>
                                            </ng-template>
                                            <span
                                                class="time  ml-5"> {{comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                                        </div>
                                    </div>
                                    <div class="message  mt-5">
                                        {{comment.comment}}
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="actions"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
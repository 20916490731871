import {RemoteApp, RemoteAppReadyStatus} from '../../models';

export class RemoteAppWrapper {
    public remoteApp: RemoteApp;
    public remoteAppReadyStatus: RemoteAppReadyStatus;
    public remoteAppId: number;
    public customerGroupId: number;
    public subProjectId: number;
    public name: string;
    public imageUUID: string;
    public loading;

    constructor(remoteAppId: number, remoteApp: RemoteApp, remoteAppReadyStatus: RemoteAppReadyStatus,
                loading: boolean = false, customerGroupId: number = 0, subProjectId: number = 0, name: string = '', imageUUID: string = '') {
        this.remoteAppId = remoteAppId;
        this.remoteApp = remoteApp;
        this.remoteAppReadyStatus = remoteAppReadyStatus;
        this.loading = loading;
        this.customerGroupId = customerGroupId;
        this.subProjectId = subProjectId;
        this.name = name;
        this.imageUUID = imageUUID;
    }
}

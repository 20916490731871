import {FirebaseOptions} from '@angular/fire/firebase.app.module';

export function firebaseJson(): FirebaseOptions {
    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', '/assets/js/firebase.json', false);
    httpRequest.send(null);
    if (httpRequest.status < 400) {
        return JSON.parse(httpRequest.responseText);
    }
    throw new Error('/assets/js/firebase.json 404');
}
import {EventProvider} from './event-provider';
import {Session} from './session';
import {SessionType} from './enum/session-type';
import {SessionFactoryEvent} from './enum/session-factory-event';
import {ConnectionEvent} from './enum/connection-event';
import {RedirectionsRequest} from './redirections-request';
import {ConnectionState} from './enum/connection-state';
import {ConnectionPropertiesRejectedError} from './connection-properties-rejected-error';
import {ConnectionPropertiesRejectedReason} from './enum/connection-properties-rejected-reason';
import {ConnectionProperties} from './connection-properties';
import {Bookmark} from './bookmark';
import {TrustedCollectionsService} from '../trusted-collections.service';
import {ConnectionFactory} from './connection-factory';
import {Logger} from './logger';
import {RdpConnection} from './rdp-connection';

export class SessionFactory {
    private e: any = {};
    events = new EventProvider();

    constructor(private connectionFactory: ConnectionFactory,
                private logger: Logger,
                private trustedCollections: TrustedCollectionsService) {
    }

    private onWindowCreated = (connection: any, c: any): void => {
        let d: Session;
        d = new Session(null, connection, SessionType.RemoteApp, this.logger), d.windowId = c,
            this.e[c] = d.id, this.events.fireEventCallback(SessionFactoryEvent.SessionCreated, d);
    };

    private onWindowDeleted = (a: any): void => {
        const c = this.e[a];
        c && (this.logger.log('[SessionFactory] Connection destroyed window'), delete this.e[a], this.events.fireEventCallback(SessionFactoryEvent.SessionDestroyed, c));
    };

    private onWindowActivated = (a: any): void => {
        const c = this.e[a];
        console.debug('(SessionFocused)');
        console.debug(a);
        c && (this.logger.log('[SessionFactory] Connection activated window'), this.events.fireEventCallback(SessionFactoryEvent.SessionFocused, c));
    };

    private onConnectionDestroyed = (a: RdpConnection): void => {
        this.events.fireEventCallback(SessionFactoryEvent.ConnectionDestroyed, a),
            a.events.unsubscribe(ConnectionEvent.ConnectionDestroyed, this.onConnectionDestroyed),
            a.events.unsubscribe(ConnectionEvent.WindowDeleted, this.onWindowDeleted),
            a.events.unsubscribe(ConnectionEvent.WindowActivated, this.onWindowActivated),
            a.events.unsubscribe(ConnectionEvent.WindowCreated, this.onWindowCreated);
    };

    private k = (a: any, connectionProperties: ConnectionProperties): void => {
        this.logger.log('[SessionFactory] Redirections request');
        const redirectionsRequest = new RedirectionsRequest(a, connectionProperties);
        this.events.fireEventCallback(SessionFactoryEvent.RedirectionsRequest, redirectionsRequest);
    };

    completeConnectionCreation(a: RdpConnection, c: ConnectionProperties, rememberCollection: boolean, bookmark: Bookmark, hadSuccessfulConnection: boolean): void {
        let m: Session = null;
        // bookmark.rdpConnection = connectionFactory;
        a.isExistingConnection || (this.events.fireEventCallback(SessionFactoryEvent.ConnectionCreated, bookmark.id, a)),
        this.trustedCollections && rememberCollection && (this.logger.debug('[SessionFactory] Trusting collection'), this.trustedCollections.trustCollection(c)),
            a.events.subscribe(ConnectionEvent.ConnectionDestroyed, this.onConnectionDestroyed),
            c.remoteApplicationMode ? (this.logger.debug('[SessionFactory] Launching remote app: ' + c.remoteApplicationProgram),
                a.events.subscribe(ConnectionEvent.WindowCreated, this.onWindowCreated),
                a.events.subscribe(ConnectionEvent.WindowDeleted, this.onWindowDeleted),
                a.events.subscribe(ConnectionEvent.WindowActivated, this.onWindowActivated),
            hadSuccessfulConnection || a.launchApplication(c.remoteApplicationProgram, c.remoteApplicationCmdLine, ''),
            a.getState() !== ConnectionState.Connected && (bookmark.desktopName = bookmark.workspaceName, m = new Session(bookmark, a, SessionType.Desktop, this.logger),
                this.events.fireEventCallback(SessionFactoryEvent.SessionCreated, m))) : (m = new Session(bookmark, a, SessionType.Desktop, this.logger),
                this.events.fireEventCallback(SessionFactoryEvent.SessionCreated, m));
    }

    createSession(bookmark: Bookmark, hadSuccessfulConnection: boolean, remoteAppId: number): void {
        const h = (connectionProperties: ConnectionProperties, rememberCollection: boolean): any => {
            try {
                this.connectionFactory.createConnection(connectionProperties, (rdpConnection: RdpConnection): void => {
                    this.completeConnectionCreation(rdpConnection, connectionProperties, rememberCollection, bookmark, hadSuccessfulConnection);
                }, remoteAppId);
            } catch (a) {
                return void (connectionProperties.connectionSettings.UseGatewayProtocol ? this.events.fireEventCallback(SessionFactoryEvent.ConnectionCreateFailed, a) :
                    this.events.fireEventCallback(SessionFactoryEvent.ConnectionCreateFailed,
                        new ConnectionPropertiesRejectedError(ConnectionPropertiesRejectedReason.GatewayNotSpecified, bookmark)));
            }
        };
        if (bookmark) {
            if (bookmark.rdpFileString) {
                const i = new ConnectionProperties(bookmark),
                    j = i.connectionSettings.EnableClipboard,
                    l = this.connectionFactory.findConnection(i);
                bookmark.connectionProperties = i;
                i.connectionSettings.EnablePrinting = true;
                if (i.gatewayHostName ||
                (i.connectionSettings.UseGatewayProtocol = !1, i.connectionSettings.UseSecureWebSockets = !0,
                    i.connectionSettings.GatewayHostName = i.hostName, i.connectionSettings.GatewayPort = 3392, i.connectionSettings.GatewayPath = 'rdp'),
                !j || l && l.getState() !== ConnectionState.Disconnected || this.trustedCollections && this.trustedCollections.isCollectionTrusted(i)) {
                    h(i, !1);
                } else {
                    const m = {
                        complete: h,
                        cancel: (): void => {
                            this.logger.error('[SessionFactory] Redirection confirmation cancelled');
                        }
                    };
                    this.k(m, i);
                }
            } else {
                // this.logger.debug('[SessionFactory] Retrieving RDP file: ' + bookmark.rdpFileURL), this.rdFeedTracker.getFeedFile(bookmark.rdpFileURL).then((connectionFactory: any): void => {
                //     bookmark.rdpFileString = connectionFactory.data, this.createSession(bookmark, g);
                // }, (connectionFactory: any): void => {
                //     const logger: any = new Error('Retrieving the RDP file for the connection failed.');
                //     logger.httpResponse = connectionFactory, this.events.fireEventCallback(SessionFactoryEvent.ConnectionCreateFailed, logger);
                // });
            }
        }
    }
}


import {Component, Input, OnChanges} from '@angular/core';
import {MarketplaceService} from '../../marketplace.service';
import {MarketplaceProduct} from '../../../../models';

@Component({
    selector: 'app-marketplace-product-detail-description',
    templateUrl: './marketplace-product-description.component.html',
    styleUrls: ['./marketplace-product-description.component.scss', '../marketplace-product.component.scss']
})
export class MarketplaceProductDescriptionComponent implements OnChanges {

    @Input()
    amountOfMarketplaceProductOrders: number;

    @Input()
    marketplaceProduct: MarketplaceProduct;

    @Input()
    reloadData: number;

    constructor(public marketplaceService: MarketplaceService) {
    }

    ngOnChanges(): void {
    }
}

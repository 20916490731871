<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">
            R2 Security - Instalar agente no PfSense
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-8" matDialogContent>
        <div class="mt-20 p-15" [hidden]="!customIPsUrl" >
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>URL dos IPs do R2</mat-label>
                <input matInput placeholder="URL dos IPs do R2" [formControl]="r2IpsUrlControl">
                <mat-error *ngIf="r2IpsUrlControl.hasError('invalidIpv4OrIpv6OrFqdn')">
                    <div class="mb-30">Existe algum IP ou FQDN inválido!</div>
                </mat-error>
                <mat-error *ngIf="r2IpsUrlControl.hasError('invalidLastCharacterIsNumber')">
                    <div class="mb-30">Existe FQDN com o último caracter como número!</div>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="pl-15 pr-15 pb-40 pt-15">
            <p>Instrução de como instalar o agente no PfSense.</p>
            <code class="p-20 display-block-clear">
                {{ script }}
            </code>
        </div>

    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
        <button type="button" *ngIf="!copied" mat-raised-button color="accent" (click)="onCopyScript(script)" >Copiar código</button>
        <b class="blue-700-fg pl-10">{{copied}}</b>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>
import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {LinuxServer, LinuxServerPrivateKey, LinuxServerWithSubProject, ServerPresence} from '../../models';
import {BaseServerDao} from '../desktop-server/base-server-dao';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class LinuxServerDaoService extends BaseServerDao<LinuxServer, LinuxServerPrivateKey> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'linux-server');
    }


    getAllLinuxServersWithSubProjectIds(): Observable<LinuxServerWithSubProject[]> {
        return this.http.get<LinuxServerWithSubProject[]>(this.url + '/get-all-linux-server-with-sub-project-ids');
    }


    getServersOffLine(): Observable<ServerPresence[]> {
        return this.http.get<ServerPresence[]>(this.url + '/servers-offline');
    }

    getServersOnLine(): Observable<ServerPresence[]> {
        return this.http.get<ServerPresence[]>(this.url + '/servers-online');
    }

}

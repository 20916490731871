<div class="dialog-content-wrapper">
    <div fxLayout="column" id="changePassword" fxLayoutAlign="space-between stretch">
        <div>
            <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center start">
                <mat-icon class="icon">lock_outline</mat-icon>
            </mat-toolbar>
        </div>
        <form (submit)="myForm.dirty && myForm.valid && onSubmit()"
              id="change-password-form"
              class="width-100-percent"
              [formGroup]="myForm">
            <div class="p-16 message width-100-percent change-password" fxLayout="column" fxLayoutAlign="start center" >
                <mat-form-field class="width-100-percent" appearance="outline">
                    <mat-label>Senha atual</mat-label>
                    <input type="password" matInput placeholder="Senha atual"
                           id="change-password-old-password"
                           formControlName="oldPassword"
                           name="oldPassword">
                </mat-form-field>
                <mat-form-field class="width-100-percent" appearance="outline">
                    <mat-label>Nova senha</mat-label>
                    <input type="password" matInput placeholder="Nova senha"
                           id="change-password-new-password"
                           formControlName="newPassword"
                           name="newPassword">
                    <mat-error *ngIf="myForm.get('newPassword').hasError('repeatedChars')">
                        <div>A senha não pode conter caracteres repetidos. Ex: (aaaa) (bbbb)</div>
                    </mat-error>
                    <mat-error *ngIf="myForm.get('newPassword').hasError('sequentialNumber')">
                        <div>A senha não pode conter números sequencias. Ex: (1234) (4567)</div>
                    </mat-error>
                    <mat-error *ngIf="myForm.get('newPassword').hasError('minLength')">
                        <div>A senha deve conter pelo menos 6 caracteres.</div>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="width-100-percent" appearance="outline">
                    <mat-label>Repita a nova senha</mat-label>
                    <input type="password" matInput placeholder="Repita a nova senha"
                           id="change-password-re-new-password"
                           formControlName="reNewPassword"
                           name="reNewPassword">

                    <mat-error *ngIf="myForm.get('reNewPassword').hasError('repeatedChars')">
                        <div>A senha não pode conter caracteres repetidos. Ex: (aaaa) (bbbb)</div>
                    </mat-error>
                    <mat-error *ngIf="myForm.get('reNewPassword').hasError('sequentialNumber')">
                        <div>A senha não pode conter números sequencias. Ex: (1234) (4567)</div>
                    </mat-error>
                    <mat-error *ngIf="myForm.get('reNewPassword').hasError('minLength')">
                        <div>A senha deve conter pelo menos 6 caracteres.</div>
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="myForm.hasError('notSame')">
                    Sua nova senha não está igual ao campo repita.
                </mat-error>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top">
                <div>
                    <button type="submit" mat-raised-button
                            id="change-password-save"
                            color="accent" [disabled]="!myForm.valid || myForm.pristine"
                            aria-label="Salvar">Salvar
                    </button>
                </div>
                <div>
                    <button type="button" mat-raised-button color="primary"
                            id="change-password-cancel"
                            (click)="onCancel()">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
</div>

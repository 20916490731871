import {Injectable} from '@angular/core';
import {LinuxServer, ServerType} from '../../models';
import {SshAccessPermissionDaoService} from './sshAccessPermission/ssh-access-permission-dao.service';
import {FooterService} from '../../../layout/components/footer/footer.service';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CustomerGroupService} from '../customer-group/customer-group.service';

@Injectable({
    providedIn: 'root'
})
export class TerminalService {

    countLoading = 0;

    constructor(
        private sshAccessPermissionDaoService: SshAccessPermissionDaoService,
        private footerService: FooterService,
        private dialog: MatDialog,
        private customerGroupService: CustomerGroupService
    ) {
    }

    openWebSSH(linuxServer: LinuxServer): void {
        this.sshAccessPermissionDaoService.getSshAccessPermission(linuxServer.id, ServerType.LINUX).subscribe((sshAccessPermission) => {
            if (sshAccessPermission) {
                if (sshAccessPermission.accessToken === 'GENERATING') {
                    this.countLoading++;
                    linuxServer.comment = 'LOADING';
                    console.log('GENERATING');
                    if (this.countLoading < 5) {
                        setTimeout(() => {
                            this.openWebSSH(linuxServer);
                        }, 5000);
                    } else {
                        linuxServer.comment = '';
                        this.countLoading = 0;
                        this.checkMessage();
                    }
                } else {
                    linuxServer.comment = '';
                    this.countLoading = 0;
                    const terminalTitle = this.customerGroupService.customerGroup.name + ' | ' + linuxServer.hostname + ' | ' + linuxServer.publicIp;
                    this.footerService.openTerminal(sshAccessPermission.accessToken, terminalTitle);
                }
            }
        });
    }

    private checkMessage(): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Verifique se o agente Linux está funcionando corretamente e tente novamente.',
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {

        });
    }
}

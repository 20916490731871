import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {ValueWrapper} from '../../helpers/value-wrapper';
import {RemoteApp, RemoteAppHtml5} from '../../models';
import {BaseSearchDao} from '../../base-search-dao';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';


@Injectable()
export class RemoteAppDaoService extends BaseSearchDao<RemoteApp> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'remote-app');
    }

    resize(base64Image: string): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(this.url + '/resize', base64Image);
    }

    getOneHtml5(remoteAppId: number): Observable<RemoteAppHtml5> {
        return this.http.get<RemoteAppHtml5>(this.url + '/html5/' + remoteAppId);
    }


    getAllRemoteAppsPendingOrDeleting(): Observable<RemoteApp[]> {
        return this.http.get<RemoteApp[]>(this.url + '/get-all-remote-apps-pending-or-deleting');
    }


    findAllImagesByRemoteAppName(name: string): Observable<ValueWrapper[]> {
        return this.http.get<ValueWrapper[]>(this.url + '/find-all-images-by-remote-app-name?name=' + name);
    }

    forceDelete(remoteAppId: number): Observable<string> {
        return this.http.remove(this.url + '/force-delete/' + remoteAppId);
    }

    markAsOpenInHTML5(remoteAppHtml5: RemoteAppHtml5): Observable<void> {
        return this.http.post<void>(this.url + '/mark-as-open-in-html5', remoteAppHtml5);
    }
}

import {EventProvider} from './event-provider';
import {ObjectHelper} from './object-helper';
import {RdpCredential} from './rdp-credential';
import {SessionState} from './enum/session-state';
import {ConnectionState} from './enum/connection-state';
import {ConnectionEvent} from './enum/connection-event';
import {SessionDelegate} from './session-delegate';
import {SessionEvent} from './enum/session-event';
import {SessionType} from './enum/session-type';
import {SessionDisconnectCode} from './session-disconnect-code';
import {RdpConnection} from './rdp-connection';
import {Bookmark} from './bookmark';
import {Logger} from './logger';

export class Session {
    events = new EventProvider();
    id: string = (new ObjectHelper()).createGuid();
    sessionType: SessionType;
    windowId: any = null;
    connection: RdpConnection;
    state: any;
    delegate: any;

    private thumbnail: any = null;
    private label: any = null;
    private applicationId: any = null;
    private rdpCredential = new RdpCredential();
    
    constructor(private bookmark: Bookmark, connection: RdpConnection, sessionType: SessionType, private logger: Logger){
        this.sessionType = sessionType;
        this.connection = connection;

        this.state = SessionState.Initialized, this.connection.getState() === ConnectionState.Connected && (this.state = SessionState.Connected), 
        this.connection && (this.connection.events.subscribe(ConnectionEvent.ConnectionConnectFailed, this.onConnectionConnectFailed),
            this.connection.events.subscribe(ConnectionEvent.ConnectionDestroyed, this.onConnectionDestroyed),
            this.connection.events.subscribe(ConnectionEvent.WindowDeleted, this.onWindowDeleted),
            this.connection.events.subscribe(ConnectionEvent.WindowIconChanged, this.onWindowIconChanged),
            this.connection.events.subscribe(ConnectionEvent.WindowTitleChanged, this.onWindowTitleChanged),
            this.connection.events.subscribe(ConnectionEvent.WindowApplicationIdChanged, this.onWindowApllicationIdChanged),
            this.connection.events.subscribe(ConnectionEvent.WindowLaunched, this.onWindowLaunched)),
            this.delegate = new SessionDelegate();
    }


    private canvasNeeded = (): any => {
        return this.delegate.canvasNeeded ? this.delegate.canvasNeeded(this.getConnectionId()) : null;
    };
    
    private monitorBounds = (): any => {
        return this.delegate.monitorBounds ? this.delegate.monitorBounds() : null;
    };
    
    private viewSizeChanged = (a: any, b: any): void => {
        this.events.fireEventCallback(SessionEvent.ViewSizeChanged, this, a, b);
    };
    
    private credentialsNeeded = (a: any, b: any): void => {
        this.rdpCredential.password ? setTimeout((): void => {
            a.complete(this.rdpCredential);
        }) : (a.cred = this.rdpCredential, this.rdpCredential.userName || (this.rdpCredential.userName = a.credentialHint()),
            this.events.fireEventCallback(SessionEvent.CredentialsNeeded, a, b));
    };
    
    private trustChallenge = (a: any): void => {
        this.events.fireEventCallback(SessionEvent.TrustChallenge, a);
    };
    
    private willConnect = (a: any, b: any): void => {
        this.events.fireEventCallback(SessionEvent.WillConnect, this.id, a, b);
    };
    
    private didConnect = (): void => {
        this.state = SessionState.Connected, this.events.fireEventCallback(SessionEvent.DidConnect, this.id), this.rdpCredential = new RdpCredential();
    };
    
    private willDisconnect = (): void => {
        this.events.fireEventCallback(SessionEvent.WillDisconnect);
    };
    
    private didDisconnect = (a: any): void => {
        this.state = SessionState.Disconnected, this.delegate.retireCanvas && this.delegate.retireCanvas(this.getConnectionId()), 
            this.events.fireEventCallback(SessionEvent.DidDisconnect, this.id, a), this.rdpCredential = new RdpCredential();
    };
    
    private onConnectionDestroyed = (a: any, b: any): void => {
        this.state = SessionState.Disconnected, this.events.fireEventCallback(SessionEvent.DidDisconnect, this.id, b);
    };
    
    private onConnectionConnectFailed = (a: any, b: any): void => {
        this.state = SessionState.Disconnected, this.events.fireEventCallback(SessionEvent.DidDisconnect, this.id, b);
    };
    
    private loginCompleted = (): void => {
        this.events.fireEventCallback(SessionEvent.LoginCompleted);
    };
    
    private connectionHealthStateChanged = (): void => {
        this.events.fireEventCallback(SessionEvent.ConnectionHealthStateChanged);
    };
    
    private mousePointerChanged = (a: any, b: any, c: any): void => {
        this.events.fireEventCallback(SessionEvent.MousePointerChanged, a, b, c);
    };
    
    private showDefaultMousePointer = (a: any): void => {
        this.events.fireEventCallback(SessionEvent.ShowDefaultMousePointer, a);
    };
    
    private getRemoteClipboardContent = (a: any, b: any): void => {
        this.logger.log('[Session] Remote clipboard data received ' + b);
        this.events.fireEventCallback(SessionEvent.GetRemoteClipboardContent, a, b);
    };
    
    private setRemoteClipboardFormatsComplete = (a: any): void => {
        this.logger.log('[Session] Remote Clipboard format set complete with result ' + a);
        this.events.fireEventCallback(SessionEvent.SetRemoteClipboardFormatsComplete, a);
    };
    
    private clipboardContentRequest = (a: any): void => {
        this.logger.log('[Session] Request for clipboard content received for format ' + a.GetFormat());
        this.events.fireEventCallback(SessionEvent.ClipboardContentRequest, a);
    };
    
    private onWindowDeleted = (a: any): void => {
        a === this.id && this.events.fireEventCallback(SessionEvent.DidDisconnect, this.id);
    };
    
    private onWindowIconChanged = (a: any, b: any, c: any): void => {
        if (this.logger.log('[Session] Window icon changed for window: ' + a), a === this.windowId) {
            if (c) { this.thumbnail = b, this.events.fireEventCallback(SessionEvent.WindowIconChanged); }
            else if (null === this.thumbnail) {
                const g = this.getBookmark();
                this.thumbnail = g ? g.thumbnail : b, this.events.fireEventCallback(SessionEvent.WindowIconChanged);
            }
        }
    };
    
    private onWindowTitleChanged = (a: any, b: any): void => {
        this.logger.log('[Session] Window title changed to ' + b), a === this.windowId && (this.label = b), this.events.fireEventCallback(SessionEvent.WindowTitleChanged);
    };
    
    private onWindowApllicationIdChanged = (a: any, b: any): void => {
        this.logger.log('[Session] Application id changed to: ' + b + ' for Window: ' + a), a === this.windowId && (this.applicationId = b),
            this.events.fireEventCallback(SessionEvent.ApplicationIdChanged);
    };
    
    private onWindowLaunched = (a: any, b: any): void => {
        this.logger.log('[Session] Window launched successfully with result ' + b), this.events.fireEventCallback(SessionEvent.WindowLaunched, this, a, b);
    };
    
    private error = (a: any): void => {
        this.events.fireEventCallback(SessionEvent.Error, this.id, a);
    };
    
    private displayDesktopBackground = (a: any): void => {
        this.events.fireEventCallback(SessionEvent.DisplayDesktopBackground, a, this.id);
    };

    connect(): any {
        this.state !== SessionState.Connecting && (this.state = SessionState.Connecting,
            this.connection.delegate.displayDesktopBackground = this.displayDesktopBackground,
            this.connection.delegate.canvasNeeded = this.canvasNeeded,
            this.connection.delegate.monitorBounds = this.monitorBounds,
            this.connection.delegate.viewSizeChanged = this.viewSizeChanged,
            this.connection.delegate.credentialsNeeded = this.credentialsNeeded,
            this.connection.delegate.trustChallenge = this.trustChallenge,
            this.connection.delegate.willConnect = this.willConnect,
            this.connection.delegate.didConnect = this.didConnect,
            this.connection.delegate.willDisconnect = this.willDisconnect,
            this.connection.delegate.didDisconnect = this.didDisconnect,
            this.connection.delegate.loginCompleted = this.loginCompleted,
            this.connection.delegate.connectionHealthStateChanged = this.connectionHealthStateChanged,
            this.connection.delegate.mousePointerChanged = this.mousePointerChanged,
            this.connection.delegate.showDefaultMousePointer = this.showDefaultMousePointer,
            this.connection.delegate.getRemoteClipboardContent = this.getRemoteClipboardContent,
            this.connection.delegate.setRemoteClipboardFormatsComplete = this.setRemoteClipboardFormatsComplete,
            this.connection.delegate.clipboardContentRequest = this.clipboardContentRequest,
            this.connection.delegate.error = this.error,
            this.connection.connect());
    }
    
    disconnect(a: any): void {
        this.state !== SessionState.Disconnecting && (this.state = SessionState.Disconnecting, this.windowId && (this.connection.closeWindow(this.windowId), this.windowId = null), 
            this.connection.events.unsubscribe(ConnectionEvent.WindowIconChanged, this.onWindowIconChanged), !0 !== a ? setTimeout((): void => {
            this.connection.disconnect(false);
        }, 1) : setTimeout((): void => {
            this.connection.disconnect(!0);
        }, 1));
    }
    
    mouseMove(a: any, b: any): void {
        this.connection.mouseMove(a, b);
    }
    
    mouseDown(a: any, b: any, c: any): void {
        this.connection.mouseDown(a, b, c);
    }
    
    mouseUp(a: any, b: any, c: any): void {
        this.connection.mouseUp(a, b, c);
    }
    
    mouseWheel(a: any, b: any): void {
        this.connection.mouseWheel(a, b);
    }
    
    keyDown(a: number): void {
        this.connection.keyDown(a);
    }
    
    keyUp(a: number): void {
        this.connection.keyUp(a);
    }
    
    keyUnicodeDown(a: any): void {
        this.connection.keyUnicodeDown(a);
    }
    
    keyUnicodeUp(a: any): void {
        this.connection.keyUnicodeUp(a);
    }
    
    sendTouchEvents(a: any, b: any): void {
        this.connection.sendTouchEvents(a, b);
    }
    
    setRemoteClipboardFormats(): void {
        this.connection.clipboardHandler.setRemoteClipboardFormats();
    }
    
    isClipboardReady(): void {
        return this.connection.isClipboardReady();
    }
    
    getBookmark(): Bookmark {
        return this.bookmark;
    }
    
    setFocus(a: boolean, b: boolean): void {
        this.logger.log('[Session] Session focus changed to ' + a + ' id:' + this.id + ' window id:' + this.windowId),
        this.sessionType === SessionType.RemoteApp && a && this.windowId && this.connection.activateWindow(this.windowId, b),
            this.connection.setFocus(a), this.events.fireEventCallback(SessionEvent.FocusChanged, a);
    }
    
    getFocus(): any {
        return this.connection.getFocus();
    }

    getThumbnail(): any {
        // return null !== this.f ? this.f : null !== this.a ? this.a.thumbnail : null;
        return this.thumbnail;
    }

    getLabel(): any {
        // return this.g || this.a && this.a.label() || 'no title';
        return this.label;
    }

    getApplicationId(): any {
        return null !== this.applicationId ? this.applicationId : this.id;
    }

    invalidateCanvas(): void {
        this.connection.invalidateCanvas();
    }

    getConnectionId(): any {
        return this.connection.id;
    }

    getSiblingCount(): any {
        return this.connection.getWindowCount();
    }

    getDisconnectReason(): any {
        return this.connection.disconnectReason && !this.connection.isAutoReconnecting() ? SessionDisconnectCode.locKey(this.connection.disconnectReason) : null;
    }
}

import {Component, OnInit} from '@angular/core';
import {MarketplaceService} from '../marketplace.service';
import {Router} from '@angular/router';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {MarketplaceOrderService} from './marketplace-order.service';
import {CustomerGroup, FirebaseUserDetails, ManagerUser, MarketplaceOrder, MarketplaceProduct} from '../../../models';
import {MarketplaceOrderDaoService} from './marketplace-order.dao.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {MarketplaceProductDaoService} from '../marketplace-product/marketplace-product.dao.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {FormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
    selector: 'app-marketplace-order',
    templateUrl: './marketplace-order.component.html',
    styleUrls: ['./marketplace-order.component.scss']
})
export class MarketplaceOrderComponent implements OnInit {
    user: FirebaseUserDetails = null;
    marketplaceOrderList: MarketplaceOrder[] = [];
    marketplaceOrderFilterList: MarketplaceOrder[] = [];
    marketplaceProductList: MarketplaceProduct[] = [];
    customerGroup: CustomerGroup;
    marketplaceOrderUnReadFilterList: MarketplaceOrder[] = [];
    marketplaceOrderReadFilterList: MarketplaceOrder[] = [];
    marketplaceOrderCancelFilterList: MarketplaceOrder[] = [];
    marketplaceOrderConcludedFilterList: MarketplaceOrder[] = [];

    displayedColumns: string[] = [
        'id', 'managerUserName', 'marketplaceProductAvatarId', 'createdAt'
    ];

    public searchMultiFilterCtrl: FormControl = new FormControl();
    public filteredSearchMulti: ReplaySubject<MarketplaceOrder[]> = new ReplaySubject<MarketplaceOrder[]>(1);
    protected _onDestroy = new Subject<void>();

    constructor(
        public marketplaceService: MarketplaceService,
        public marketplaceOrderService: MarketplaceOrderService,
        public marketplaceOrderDaoService: MarketplaceOrderDaoService,
        public marketplaceProductDaoService: MarketplaceProductDaoService,
        public customerGroupDaoService: CustomerGroupDaoService,
        private managerUserDaoService: ManagerUserDaoService,
        private customerGroupService: CustomerGroupService,
        public adminService: R2CloudAdminService,
        private userAuthorityDao: UserAuthorityDaoService,
        public imageFileService: ImageFileService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
            this.customerGroupDaoService.getByAuthenticatedManagerUser().subscribe((customerGroup) => {
                if (customerGroup) {
                    this.customerGroup = customerGroup;
                    this.findOrderListBySelectedType(this.marketplaceOrderDaoService.findAllByAuthenticatedManagerUser());
                }
            });
        });
    }

    findOrderListBySelectedType(marketplaceOrder$: Observable<MarketplaceOrder[]>): void {
        marketplaceOrder$.subscribe((marketplaceOrderList) => {
                if (marketplaceOrderList) {
                    let newOrderExist = false;
                    marketplaceOrderList.map(marketplaceOrder => {
                        if (!this.marketplaceOrderService.findById(this.marketplaceOrderList, marketplaceOrder.id)) {
                            this.marketplaceOrderList.push(marketplaceOrder);
                            newOrderExist = true;
                        }
                    });
                    this.marketplaceOrderService.updateOrderMenuBadgeByMarketplaceOrderList(this.marketplaceOrderList, this.customerGroup.id);
                    if (newOrderExist) {
                        const managerUserIds: number[] = [];
                        const marketplaceProductIds: number[] = [];
                        for (const orderList of this.marketplaceOrderList) {
                            managerUserIds.push(orderList.managerUserId);
                            marketplaceProductIds.push(orderList.marketplaceProductId);
                        }

                        let marketplaceProductList$: Observable<MarketplaceProduct[]>;
                        if (marketplaceProductIds && marketplaceProductIds.length > 0) {
                            marketplaceProductList$ = this.marketplaceProductDaoService.findAllByIdList(marketplaceProductIds);
                        } else {
                            marketplaceProductList$ = of(this.marketplaceProductList);
                        }

                        let managerUserList$: Observable<ManagerUser[]>;
                        if (managerUserIds && managerUserIds.length > 0) {
                            managerUserList$ = this.managerUserDaoService.findAllByIdList(managerUserIds);
                        } else {
                            managerUserList$ = of([]);
                        }

                        forkJoin([marketplaceProductList$, managerUserList$]).subscribe((result) => {
                            if (result[0]) {
                                this.marketplaceProductList = result[0];
                            }
                            if (result[1]) {
                                result[1].map(managerUserMap => {
                                    if (!this.adminService.getManagerUser(managerUserMap.id)) {
                                        this.adminService.addManagerUser(managerUserMap);
                                    }
                                });
                            }
                            this.refreshDataSource();
                        });

                        this.filterBanksMulti();
                        this.searchMultiFilterCtrl.valueChanges
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe(() => {
                                this.filterBanksMulti();
                            });
                    }
                }
            }
        );
    }

    protected filterBanksMulti(): void {
        let search = this.searchMultiFilterCtrl.value;
        if (!search) {
            this.marketplaceOrderFilterList = this.marketplaceOrderList;
            this.refreshDataSource();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.marketplaceOrderFilterList = this.marketplaceOrderList.filter((bank) => {
            let createdAt = '';
            if (bank.createdAt) {
                const dateCreatedAt = new Date(bank.createdAt);
                createdAt = (dateCreatedAt.getDate() + '-' + (dateCreatedAt.getMonth() + 1) + '-' +
                    dateCreatedAt.getFullYear() + ' ' + dateCreatedAt.getHours() + ':' + dateCreatedAt.getMinutes());
            }
            return (
                (bank.id + ''.toLowerCase()).indexOf(search) > -1 ||
                bank.description.toLowerCase().indexOf(search) > -1 ||
                this.marketplaceService.findById(bank.marketplaceProductId, this.marketplaceProductList)?.name.toLowerCase().indexOf(search) > -1 ||
                this.adminService.getManagerUser(bank.managerUserId)?.displayName.toLowerCase().indexOf(search) > -1 ||
                this.adminService.getManagerUser(bank.managerUserId)?.email.toLowerCase().indexOf(search) > -1 ||
                createdAt.indexOf(search) > -1
            );
        });
        this.refreshDataSource();
    }

    refreshDataSource(): void {
        this.marketplaceOrderUnReadFilterList = this.marketplaceOrderService.findAllUnread(this.marketplaceOrderFilterList, this.customerGroup.id);
        this.marketplaceOrderReadFilterList = this.marketplaceOrderService.findAllRead(this.marketplaceOrderFilterList, this.customerGroup.id);
        this.marketplaceOrderCancelFilterList = this.marketplaceOrderService.findAllCancel(this.marketplaceOrderFilterList);
        this.marketplaceOrderConcludedFilterList = this.marketplaceOrderService.findAllConcluded(this.marketplaceOrderFilterList);
    }

    onOpenRequest(marketplaceOrder: MarketplaceOrder): void {
        this.router.navigate(['/marketplace-order/detail'], {queryParams: {marketplaceOrderId: marketplaceOrder.id}});
    }

    onSelectOrderType(event: MatTabChangeEvent): void {
        if (event.index === 0) {
            this.findOrderListBySelectedType(this.marketplaceOrderDaoService.findAllByAuthenticatedManagerUser());
        } else if (event.index === 1) {
            this.findOrderListBySelectedType(this.marketplaceOrderDaoService.findAllCanceledByAuthenticatedManagerUser());
        } else if (event.index === 2) {
            this.findOrderListBySelectedType(this.marketplaceOrderDaoService.findAllConcludedByAuthenticatedManagerUser());
        }
        this.refreshDataSource();
    }

    onUpdateMarketplaceOrderList(event: MarketplaceOrder[]): void {
        this.marketplaceOrderList = event;
        this.marketplaceOrderService.updateOrderMenuBadgeByMarketplaceOrderList(this.marketplaceOrderList, this.customerGroup.id);
    }
}

<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column" style="max-width: 950px;">
            <div class="row" *appIfPermission="canEditList; else cannotAccess">


                <mat-card>
                    <mat-card-header>
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>R2 Security Servers</div>
                            <div>
                                <button type="button" mat-raised-button color="accent" aria-label="open menu"
                                        (click)="create()">
                                    Criar
                                </button>
                                <button type="button" mat-raised-button aria-label="open menu"
                                        (click)="scriptInstall()">
                                    Instalar agente
                                </button>
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu" *appIsAdmin>
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" style="max-width: 372px">
                                    <button mat-menu-item (click)="allLoggedUsersIps()">
                                        <mat-icon>search</mat-icon>
                                        <span>IPs Usuários</span>
                                    </button>
                                    <button mat-menu-item (click)="showAllGatewayServers()">
                                        <mat-icon>search</mat-icon>
                                        <span>Todos R2 Security Servers</span>
                                    </button>
                                    <button mat-menu-item (click)="serverWithoutR2Security()">
                                        <mat-icon>search</mat-icon>
                                        <span>Servidores sem R2 Security</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </mat-card-title>
                    </mat-card-header>


                    <mat-card-content>
                        <app-gateway-server-list *ngIf="reloadChild"></app-gateway-server-list>
                    </mat-card-content>
                </mat-card>

            </div>
            <ng-template #cannotAccess>
                <div class="row">
                    <h2>
                        Sem permissão
                    </h2>
                </div>
            </ng-template>
        </div>
    </div>
</div>

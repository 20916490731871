import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackgroundImageService} from '../../helpers/background-image.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ComponentCleaner} from '../../component-cleaner';
import {AuthService} from '../../auth.service';
import {RemoteAppDaoService} from '../remote-app/remote-app-dao.service';
import {convertToFormGroup, isWindowsOS} from '../../helpers/kluh';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {RemoteAppWrapper} from './remote-app-wrapper';
import {StompConnectionStatus} from '../../ws/stomp-connection-status.enum';
import {ClientDownloadService} from './client-download.service';
import {ClientSettingsDaoService} from './client-settings-dao.service';

import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {AdUserDaoService} from '../ad-user/ad-user-dao.service';
import {AdDomainDaoService} from '../ad-domain/ad-domain-dao.service';
import {
    ADUserPoolRelationshipLogoff,
    ClientSettings,
    ClientUpdate,
    CustomerGroup,
    DesktopServer,
    ManagerUser,
    R2CloudClientStatus,
    RemoteAppReady,
    RemoteAppReadyStatus,
    SubProject
} from '../../models';
import {fuseAnimations} from '../../../../@fuse/animations';
import {DesktopServerDaoService} from '../desktop-server/desktop-server-dao.service';
import {DesktopServerService} from '../desktop-server/desktop-server.service';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {SubProjectDaoService} from '../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {USER_TOPIC} from '../../../../main';
import {MatDialog} from '@angular/material/dialog';
import {R2CloudClientService} from '../../ws/r2-cloud-client.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';
import {ImageFileService} from '../image-file/image-file-service';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {AdUserPoolRelationshipDaoService} from '../ad-user-pool-relationship/ad-user-pool-relationship-dao.service';
import {CustomPortalTemplate} from '../../models-custom-layout';
import {CustomLayoutService, initCustomPortalTemplate} from '../custom-layout/custom-layout.service';
import {CustomerGroupTemplateDaoService} from '../custom-layout/customer-group-template/customer-group-template-dao.service';

@Component({
    selector: 'app-desktop',
    templateUrl: './desktop.component.html',
    styleUrls: ['./desktop.component.scss'],
    animations: fuseAnimations
})
export class DesktopComponent extends ComponentCleaner implements OnInit, OnDestroy {

    // https://www.flaticon.com/packs/google-2
    waiting = false;
    timeoutLoadApps = false;
    timer = null;
    timerForButton = null;
    waitingTimer = 0;
    waitingTimerForButton = 10;
    clientConnected = false;
    clientConnectedOnce = false;
    clientExists = false;
    showInstall = false;
    private showInstallIntervalId: any | null;
    showReconnectButton = false;
    afterInstall = false;
    clientUpdate = false;
    remoteAppWrapperList: RemoteAppWrapper[] = [];
    remoteAppWrapperCustomList: RemoteAppWrapper[] = [];
    stompConnectionStatus: StompConnectionStatus;
    StompConnectionStatus = StompConnectionStatus;
    myForm: FormGroup;
    managerUser: ManagerUser;
    private clientSettings: ClientSettings;
    customerGroup: CustomerGroup;
    private subProjects: SubProject[] = [];
    desktopServerList: DesktopServer[] = null;
    private customerGroupAlreadyLoaded = false;
    desktopServerLoading = false;
    messageLoadingShow = false;
    private waitingCommunicationIntervalId: any | null;
    waitingCommunicationRestartButtonShow = false;

    subProjectFilterControl: FormControl = new FormControl();
    subProjectIdList: number[] = [];

    adUserPoolRelationshipLogoffList: ADUserPoolRelationshipLogoff[] = [];
    defaultCustomPortalTemplate: CustomPortalTemplate = initCustomPortalTemplate();

    constructor(private remoteAppDaoService: RemoteAppDaoService,
                private r2CloudClientService: R2CloudClientService,
                private clientSettingsDao: ClientSettingsDaoService,
                private managerUserDaoService: ManagerUserDaoService,
                private customerGroupTemplateDaoService: CustomerGroupTemplateDaoService,
                private adUserDaoService: AdUserDaoService,
                private adDomainDaoService: AdDomainDaoService,
                private desktopServerDaoService: DesktopServerDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private subProjectDaoService: SubProjectDaoService,
                private customerGroupService: CustomerGroupService,
                private stomp: R2CloudStompService,
                private auth: AuthService,
                private dialog: MatDialog,
                private backgroundImage: BackgroundImageService,
                private clientDownload: ClientDownloadService,
                private sanitizer: DomSanitizer,
                public desktopServerService: DesktopServerService,
                public imageFileService: ImageFileService,
                private adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
                private fb: FormBuilder,
                private customLayoutService: CustomLayoutService,
    ) {
        super();
        this.addSubscription(this.customLayoutService.getDefaultCustomerGroupTemplate$().subscribe(defaultCustomerGroupTemplate => {
            this.defaultCustomPortalTemplate = defaultCustomerGroupTemplate;
        }));
    }

    ngOnDestroy(): void {
        this.remoteAppWrapperCustomList = [];
    }

    ngOnInit(): void {
        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (!this.customerGroup || typeof this.customerGroup === 'undefined') {
                if (customerGroup && customerGroup.id) {
                    this.customerGroup = customerGroup;
                    this.loadSubProjectsAndReloadRemoteApps(customerGroup.id);
                }
            } else if (customerGroup && customerGroup.id && this.customerGroup.id !== customerGroup.id) {
                this.customerGroup = customerGroup;
                this.loadSubProjectsAndReloadRemoteApps(customerGroup.id);
            }
        }));
        this.setTimeoutLoadApps(4);

        this.addSubscription(this.stomp.connectionStatus$.subscribe((status: StompConnectionStatus) => {
            // console.debug('desktop component onConnectionChange: ' + status);
            this.stompConnectionStatus = status;
            this.setTimerToShowTheReconnectButton(status, 10);
            this.onStatusOK(status);
        }));

        this.addSubscription(this.stomp.stompTopic<ClientUpdate>(`${USER_TOPIC}/client-update`).subscribe(() => {
            this.clientUpdate = true;
        }));

        this.addSubscription(
            this.r2CloudClientService.clientConnectionChanges().subscribe((result) => {
                this.showInstall = false;
                this.clientUpdate = false;
                this.clientExists = false;
                this.clientConnected = false;
                switch (result.status) {
                    case R2CloudClientStatus.CONNECTED:
                        this.clientConnected = true;
                        this.clientConnectedOnce = true;
                        break;
                    case R2CloudClientStatus.CLIENT_UPDATE:
                        this.clientUpdate = true;
                        break;
                    case R2CloudClientStatus.NOT_CONNECTED:
                        break;
                    case R2CloudClientStatus.INSTALLED:
                        this.clientExists = true;
                        break;
                    case R2CloudClientStatus.NOT_INSTALLED:
                        clearInterval(this.showInstallIntervalId);
                        this.showInstallIntervalId = setTimeout(() => {
                            this.showInstall = true;
                        }, 5000);
                        break;
                    case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                        break;
                    case R2CloudClientStatus.OPENING:
                        break;

                }
                clearInterval(this.waitingCommunicationIntervalId);
                this.waitingCommunicationRestartButtonShow = false;
                this.waitingCommunicationIntervalId = setTimeout(() => {
                    this.waitingCommunicationRestartButtonShow = true;
                }, 8000);
            })
        );

        this.addSubscription(this.adUserPoolRelationshipDaoService.findAllMyADUserPoolRelationshipLogoff()
            .subscribe((adUserPoolRelationshipLogoffList) => {
                if (adUserPoolRelationshipLogoffList) {
                    this.adUserPoolRelationshipLogoffList = adUserPoolRelationshipLogoffList;
                }
            }));
    }

    private setTimeoutLoadApps(seconds: number): void {
        setInterval(() => {
            this.timeoutLoadApps = true;
        }, seconds * 1000);
    }

    private setTimerToShowTheReconnectButton(status: StompConnectionStatus, seconds: number): void {
        if (status === StompConnectionStatus.DISCONNECTED && !this.showReconnectButton) {
            if (this.waitingTimerForButton === seconds) {
                console.debug('entrou reconnect onConnectionChange');
                clearInterval(this.timerForButton);
                this.timerForButton = setInterval(() => {
                    this.waitingTimerForButton--;
                    if (this.waitingTimerForButton < 1) {
                        clearInterval(this.timerForButton);
                        if (status === StompConnectionStatus.DISCONNECTED && !this.showReconnectButton) {
                            this.showReconnectButton = true;
                            console.debug('show button reconnect onConnectionChange ' + status);
                        }
                    }
                }, 1000);
            }
        }
    }

    private onStatusOK(status: StompConnectionStatus): void {
        if (status === StompConnectionStatus.OK) {
            clearInterval(this.timerForButton);
            this.showReconnectButton = false;
            this.waitingTimerForButton = 10;
            console.debug('hide button reconnect onConnectionChange');
        }
    }

    private readRemoteApps(): void {
        this.customerGroupAlreadyLoaded = true;
        this.remoteAppWrapperCustomList = [];
        const subscription1 = this.stomp.stompTopic<RemoteAppReady[]>(`${USER_TOPIC}/remote-apps-ready`).subscribe((remoteAppReadyList: RemoteAppReady[]) => {
            for (const remoteAppReady of remoteAppReadyList) {
                switch (remoteAppReady.status) {
                    case RemoteAppReadyStatus.CLIENT_NOT_CONNECTED:
                    default:
                        this.clientSettingsDao.getMyClientSettings().subscribe((clientSettings) => {
                            this.clientSettings = clientSettings;
                            this.setForm();
                        });
                        clearInterval(this.timer);
                        const find = this.remoteAppWrapperList.find((value) => {
                            return value.remoteAppId === remoteAppReady.remoteAppId;
                        });
                        if (find) {
                            find.remoteAppReadyStatus = remoteAppReady.status;
                        } else {
                            if (remoteAppReady.remoteAppId !== 0) {
                                const remoteAppWrapper = new RemoteAppWrapper(remoteAppReady.remoteAppId,
                                    null, remoteAppReady.status,
                                    false,
                                    remoteAppReady.customerGroupId,
                                    remoteAppReady.subProjectId,
                                    remoteAppReady.name,
                                    remoteAppReady.imageUUID
                                );
                                const indexRemoteApp = this.remoteAppWrapperList.findIndex((o) => {
                                    return o.remoteAppId === remoteAppWrapper.remoteAppId;
                                });
                                if (indexRemoteApp < 0) {
                                    this.remoteAppWrapperList.push(remoteAppWrapper);
                                }
                                const index = this.subProjects.findIndex((o) => {
                                    return o.id === remoteAppWrapper.subProjectId;
                                });
                                if (index > -1) {
                                    const indexUpdateRemoteApp = this.remoteAppWrapperCustomList.findIndex((o) => {
                                        return o.remoteAppId === remoteAppWrapper.remoteAppId;
                                    });
                                    if (indexUpdateRemoteApp > -1) {
                                        this.remoteAppWrapperCustomList[indexUpdateRemoteApp] = remoteAppWrapper;
                                    } else {
                                        this.remoteAppWrapperCustomList.push(remoteAppWrapper);
                                    }
                                }
                            }
                        }
                        break;
                }
            }
            this.subProjectIdList = this.getDistinctSubProjectIdFromList(this.remoteAppWrapperCustomList);
            if (this.subProjectIdList.length > 1) {
                this.subProjectFilterControl.patchValue(this.subProjectIdList[0]);
            }
        });
        this.addSubscription(subscription1);
    }

    downloadLatestVersion(): void {
        this.afterInstall = true;
        this.showInstall = false;
        this.clientDownload.download();
    }

    connectStomp(): void {
        this.clientConnected = false;
        this.clientExists = false;
        this.showInstall = false;
        this.stompConnectionStatus = StompConnectionStatus.DISCONNECTED;
    }

    restartApps(): void {
        this.loadingShow();
        this.waiting = true;
        this.waitingTimer = 90;
        this.timer = setInterval(() => {
            this.waitingTimer--;
            if (this.waitingTimer < 1) {
                clearInterval(this.timer);
                location.reload();
            }
        }, 1200);
    }

    startClient(e: Event): void {
        this.r2CloudClientService.startClientClick(e);
    }


    onOpen(remoteAppWrapper: RemoteAppWrapper): void {
        if (this.stompConnectionStatus === StompConnectionStatus.OK && this.clientConnected) {
            remoteAppWrapper.loading = true;
            this.stomp.send('/stomp/open',
                {remoteAppId: remoteAppWrapper.remoteAppId});
            setTimeout(() => {
                remoteAppWrapper.loading = false;
            }, 5000);
        }
    }

    onSubmit(): void {
        this.clientSettingsDao.saveMyClientSettings(this.myForm.value).subscribe((clientSettings) => {
            this.clientSettings = clientSettings;
            this.setForm();
        });
    }

    onCancel(): void {
        this.setForm();
    }

    private setForm(): void {
        this.myForm = this.fb.group(convertToFormGroup(this.clientSettings), {asyncValidator: this.clientSettingsDao.validator});
    }

    isWindowsOS(): boolean {
        return isWindowsOS();
    }

    helpLink(): void {
        this.customerGroupTemplateDaoService.getHelpLink();
    }

    private loadSubProjectsAndReloadRemoteApps(customerGroupId: number): void {
        this.subProjectDaoService.getSubProjectsByCustomerGroupId(customerGroupId).subscribe((subProjects) => {
            if (subProjects) {
                this.subProjects = subProjects;
                if (!this.customerGroupAlreadyLoaded) {
                    this.readRemoteApps();
                }
                this.remoteAppWrapperCustomList = [];

                for (const remoteAppWrapper of this.remoteAppWrapperList) {
                    const index = this.subProjects.findIndex((o) => {
                        return o.id === remoteAppWrapper.subProjectId;
                    });
                    if (index > -1) {
                        this.remoteAppWrapperCustomList.push(remoteAppWrapper);
                    }
                }
            }
        });
    }

    getTooltip(remoteAppWrapper: RemoteAppWrapper): string {
        if (remoteAppWrapper.remoteAppReadyStatus === RemoteAppReadyStatus.SERVER_NOT_CONNECTED) {
            return 'Servidor desconectado, o aplicativo talvez não abra';
        } else if (remoteAppWrapper.remoteAppReadyStatus === RemoteAppReadyStatus.CLIENT_NOT_CONNECTED) {
            return 'Client desconectado';
        } else if (remoteAppWrapper.remoteAppReadyStatus === RemoteAppReadyStatus.PREPARING) {
            return 'Aplicação ainda não foi configurada no Servidor';
        }
    }

    findDesktopServerOnline(): void {
        if (!this.desktopServerList) {
            this.desktopServerLoading = true;
            this.addSubscription(this.userAuthorityDaoService.getMe().subscribe((user) => {
                if (user) {
                    this.addSubscription(this.desktopServerDaoService.getAllDesktopServersManagerUser(user.managerUser.id).subscribe((desktopServerList) => {
                        if (desktopServerList) {
                            this.desktopServerList = desktopServerList;
                            this.desktopServerLoading = false;
                        }
                    }));
                }
            }));
        }
    }

    onChangeVpnAccess(): void {
        const connectUsingVpn: boolean = this.myForm.get('connectUsingVpn').value;
        if (!connectUsingVpn) {
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Se você não estiver conectado nessa VPN, você não conseguirá abrir aplicativos.',
                    disableCancel: false,
                    confirmButtonValue: 'Ativar',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                this.myForm.get('connectUsingVpn').setValue(!!result);
            });
        }
    }

    loadingShow(): boolean {
        this.messageLoadingShow = true;
        setTimeout(() => {
            this.messageLoadingShow = false;
        }, 8000);
        return true;
    }

    refreshBrowser(): void {
        window.location.reload();
    }

    findSubProjectById(subProjectId: number): SubProject {
        return this.subProjects.find(subProject => {
            return subProject.id === subProjectId;
        });
    }

    getDistinctSubProjectIdFromList(remoteAppWrapperCustomList: RemoteAppWrapper[]): number[] {
        const subProjectIds: number[] = [];
        remoteAppWrapperCustomList.forEach(remoteApp => {
            if (!subProjectIds.includes(remoteApp.subProjectId)) {
                subProjectIds.push(remoteApp.subProjectId);
            }
        });
        return subProjectIds;
    }

    logoffMyUserOnDesktopServer(desktopServer: DesktopServer, force: boolean): void {
        let login = this.desktopServerService.getAdUser(desktopServer.adDomainId)?.login;
        if (login) {
            this.logoff(login, desktopServer.id, force);
        }
        if (this.existsDesktopServerIdInMyADUserPoolRelationshipDesktopServerIdList(desktopServer.id)) {
            const adUserPoolRelationshipLogoff = this.findADUserPoolRelationshipLogoffByDesktopServerId(desktopServer.id);
            if (adUserPoolRelationshipLogoff) {
                login = adUserPoolRelationshipLogoff.adUserPoolLogin;
                this.logoff(login, desktopServer.id, force);
            }
        }
        this.logoffLocalAdmin(login, desktopServer.id, force);
    }

    private logoff(login: string, desktopServerId: number, force: boolean): void {
        if (force) {
            this.desktopServerService.onUserReset(login, desktopServerId);
        } else {
            this.desktopServerService.onUserLogOff(login, desktopServerId);
        }
    }

    private logoffLocalAdmin(login: string, desktopServerId: number, force: boolean): void {
        if (force) {
            this.desktopServerService.onUserResetLocalAdmin(login, desktopServerId);
        } else {
            this.desktopServerService.onUserLogOffLocalAdmin(login, desktopServerId);
        }
    }


    existsDesktopServerIdInMyADUserPoolRelationshipDesktopServerIdList(desktopServerId: number): boolean {
        return this.findADUserPoolRelationshipLogoffByDesktopServerId(desktopServerId) !== null;
    }

    private findADUserPoolRelationshipLogoffByDesktopServerId(desktopServerId: number): ADUserPoolRelationshipLogoff {
        for (const adUserPoolRelationshipLogoff of this.adUserPoolRelationshipLogoffList) {
            if (adUserPoolRelationshipLogoff.desktopServerId === desktopServerId) {
                return adUserPoolRelationshipLogoff;
            }
        }
        return null;
    }


}

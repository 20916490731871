<mat-card *ngIf="adminService.customers && adminService.customers.length > 0">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Horário de acesso dos usuários</div>

            <button
                *appIfPermission="appCanCreateList"
                type="button" mat-raised-button color="accent" (click)="openCreateManagerUserAccessPeriodModal()">
                Criar
            </button>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="column mt-4">


            <table class="app-table" border="0" cellspacing="0">
                <thead>
                <tr class="bg-white">
                    <th class="w-245">Nome</th>
                    <th class="w-60">DOM</th>
                    <th class="w-60">SEG</th>
                    <th class="w-60">TER</th>
                    <th class="w-60">QUA</th>
                    <th class="w-60">QUI</th>
                    <th class="w-60">SEX</th>
                    <th class="w-60">SAB</th>
                    <th></th>
                </tr>
                </thead>
                <tr *ngFor="let managerUserAccessPeriodProfile of managerUserAccessPeriodService.managerUserAccessPeriodProfiles">
                    <td>
                        <div class="text-max-210">{{managerUserAccessPeriodProfile.name}}</div>
                    </td>
                    <td *ngFor="let managerUserAccessPeriodTimes of getManagerUserAccessPeriodTimeFromProfileId(managerUserAccessPeriodProfile.id)">
                        <div>{{formatStringWithLeadingZero(managerUserAccessPeriodTimes.startHours)}}
                            :{{formatStringWithLeadingZero(managerUserAccessPeriodTimes.startMinutes)}}</div>
                        <div>{{formatStringWithLeadingZero(managerUserAccessPeriodTimes.stopHours)}}
                            :{{formatStringWithLeadingZero(managerUserAccessPeriodTimes.stopMinutes)}}</div>
                    </td>
                    <td>
                        <button
                            *appIfPermission="'WRITE';type: 'ManagerUserAccessPeriodProfile';data: managerUserAccessPeriodProfile"
                            mat-button color="accent"
                            (click)="editManagerUserAccessPeriod(managerUserAccessPeriodProfile, getManagerUserAccessPeriodTimeFromProfileId(managerUserAccessPeriodProfile.id))">
                            Editar
                        </button>
                    </td>
                </tr>

            </table>


        </div>
    </mat-card-content>
</mat-card>
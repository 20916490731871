import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Permission} from '../../../../models';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-iamadd-permissions-modal',
    templateUrl: './iamadd-permissions-modal.component.html',
    styleUrls: ['./iamadd-permissions-modal.component.scss']
})
export class IAMAddPermissionsModalComponent implements OnInit {
    displayedColumns: string[] = ['select', 'name', 'comment'];
    indeterminate = false;
    dataSource: MatTableDataSource<Permission>;
    selection: SelectionModel<Permission>;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    private permissions: Permission[];

    constructor(public dialogRef: MatDialogRef<IAMAddPermissionsModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.setPermissionsTable(this.data.permissions);
    }

    private setPermissionsTable(rolePermissions: Permission[]): void {
        this.selection = new SelectionModel<Permission>(true, []);
        this.dataSource = new MatTableDataSource(rolePermissions);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    applyFilter(value: string): void {
        this.dataSource.filter = value.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        if (this.indeterminate) {
            this.indeterminate = false;
            this.selection.clear();
        } else {
            this.indeterminate = true;
            this.dataSource.filteredData.forEach(row => this.selection.select(row));
        }
    }

    onSubmit(): void {
        this.dialogRef.close(this.selection.selected);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}

import {Injectable} from '@angular/core';
import {ChartDataService} from '../chart-data.service';
import {WebsocketStompService} from '../../websocket-stomp/websocket-stomp.service';
import {MemoryUsageDaoService} from './memory-usage-dao.service';
import {MonitoringStompService} from '../../websocket-stomp/monitoring-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class MemoryUsageService extends ChartDataService<MemoryUsageDaoService> {
    constructor(
        webStompService: MonitoringStompService,
        service: MemoryUsageDaoService,
    ) {
        super(
            'MemoryUsage',
            service,
            webStompService
        );
    }
}

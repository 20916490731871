<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Criar usuários em lote no projeto.</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="space-between start" fxFill class="p-20">

        <mat-form-field *ngIf="adDomains && adDomains.length > 1" class="full-width mb-10">
            <mat-select placeholder="Domínio"  [formControl]="adDomainControl">
                <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                    {{adDomain.name}} | {{adDomain.domainName}}
                </mat-option>
            </mat-select>
            <mat-error>
                {{adDomainControl.errors | error}}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="adminService.roles?.length > 0" class="full-width mb-10" appearance="outline">
            <mat-select placeholder="Grupos (Opcional)"  [formControl]="adGroupControl">
                <mat-option *ngFor="let adGroup of adminService.adGroups" [value]="adGroup.id">
                    {{adGroup.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                {{adGroupControl.errors | error}}
            </mat-error>
        </mat-form-field>

        <div class="mb-10">Separar os e-mails por quebra de linha, os e-mails precisam já estar cadastrados como usuário em alguma
            empresa.
        </div>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Lista de e-mails ({{ getAmountOfEmails() }})</mat-label>
            <textarea rows="10" matInput class="full-width"
                      [formControl]="emailsControl"></textarea>
            <mat-error *ngIf="emailsControl.hasError('invalidEmail')">
                <div class="mb-30">
                    <div>Existe algum email inválido!</div>
                    <div> {{ emailsControl.errors.invalidEmail.message }}</div>
                </div>
            </mat-error>
        </mat-form-field>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
        <button type="button" mat-raised-button color="accent" (click)="onCreate()"
                [disabled]="emailsControl.invalid || emailsControl.pristine">Criar
        </button>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

<div fusePerfectScrollbar class="page-layout simple">
    <div class="content p-24">
        <div fxLayout="column" style="max-width: 950px;">
            <div class="row" *appIfPermission="canEditList; else cannotAccess">
                <app-ad-user-pool-addomain-list></app-ad-user-pool-addomain-list>
                <app-ad-user-pool-list></app-ad-user-pool-list>
            </div>
            <ng-template #cannotAccess>
                <div class="row">
                    <h2>
                        Sem permissão
                    </h2>
                </div>
            </ng-template>
        </div>
    </div>
</div>
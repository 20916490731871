import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {ADDomain} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class AdDomainDaoService extends BaseDao<ADDomain> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'ad-domain');
    }

    getMyDomain(adUserId: number): Observable<ADDomain> {
        return this.http.get<ADDomain>(this.url + '/my-domain/' + adUserId);
    }

    removeFromProject(adDomainId: number, subProjectId: number): Observable<string> {
        return this.http.remove(this.url + '/remove-from-project/' + adDomainId + '/' + subProjectId);
    }

    findAllBySubProjectId(subProjectId: number): Observable<ADDomain[]> {
        return this.http.get<ADDomain[]>(this.url + '/find-all-by-sub-project-id/' + subProjectId);
    }

}

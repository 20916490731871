import {Component, OnInit} from '@angular/core';
import {DesktopServer, FirebaseUserDetails, LinuxServer} from '../../../models';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ServerCreateDialogComponent} from '../server-create-dialog/server-create-dialog.component';
import {DesktopServerCreateComponent} from '../../desktop-server/desktop-server-create/desktop-server-create.component';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {LinuxServerCreateComponent} from '../../linux-server/linux-server-create/linux-server-create.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {DesktopServerCreateTokenComponent} from '../../desktop-server/desktop-server-create-token/desktop-server-create-token.component';
import {AuthService} from '../../../auth.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';
import {LinuxServerCreateTokenComponent} from '../../linux-server/linux-server-create-token/linux-server-create-token.component';


@Component({
    selector: 'app-r2-cloud-admin-desktop-server',
    templateUrl: './r2-cloud-admin-server.component.html',
    styleUrls: ['./r2-cloud-admin-server.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminServerComponent extends ComponentCleaner implements OnInit {

    user: FirebaseUserDetails;
    appCanCreateList: PermissionWrapper[];
    canAccess = false;

    ngOnInit(): void {

    }

    constructor(private desktopServerDao: DesktopServerDaoService,
                private linuxServerDao: LinuxServerDaoService,
                private dialog: MatDialog,
                private auth: AuthService,
                private userAuthorityDao: UserAuthorityDaoService,
                // private desktopServerStatus: DesktopServerStatusService,
                // private linuxServerStatus: LinuxServerStatusService,
                // private router: Router,
                // private stomp: ManagerStompService,
                public adminService: R2CloudAdminService,
                public customerGroupService: CustomerGroupService) {
        super();

        this.appCanCreateList = [
            {type: 'DesktopServer', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'DesktopServer', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
            {type: 'LinuxServer', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'LinuxServer', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];

        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
        });
    }


    // getDesktopServerIdWithVersion(desktopServerId: number): DesktopServerIdWithVersion {
    //     // let desktopServerIdWithVersion: DesktopServerIdWithVersion;
    //     // if (this.desktopServerIdWithVersions) {
    //     //     const index = this.desktopServerIdWithVersionList.findIndex((o) => o.desktopServerId === desktopServerId);
    //     //     if (index > -1) {
    //     //         desktopServerIdWithVersion = this.desktopServerIdWithVersionList[index];
    //     //     }
    //     // }
    //     // return desktopServerIdWithVersion;
    //
    // }

    // onCreateServerToken(): void{
    //     this.dialog.open(DesktopServerCreateTokenComponent, {
    //         disableClose: true,
    //         panelClass: 'generic-edit-dialog-large'
    //     });
    // }

    onCreateServerToken(): void {
        const serverTypeDialogRef = this.dialog.open(ServerCreateDialogComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                subProject: this.adminService.subProject,
                adDomains: this.adminService.adDomains
            }
        });
        this.addSubscription(serverTypeDialogRef.afterClosed().subscribe((choice) => {
            let dialogRef;
            switch (choice) {
                case 'windows':
                    dialogRef = this.dialog.open(DesktopServerCreateTokenComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog-large',
                        data: {
                            disableClose: true,
                            panelClass: 'generic-edit-dialog-large'
                        }
                    });
                    if (dialogRef) {
                        this.addSubscription(dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[]) => {
                            this.desktopServerCallback(result);
                        }));
                    }
                    break;
                case 'linux':
                    dialogRef = this.dialog.open(LinuxServerCreateTokenComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog-large',
                        data: {
                            disableClose: true,
                            panelClass: 'generic-edit-dialog-large'
                        }
                    });
                    if (dialogRef) {
                        this.addSubscription(dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[]) => {
                            this.linuxServerCallback(result);
                        }));
                    }
                    break;
            }

        }));
    }

    onCreateServer(): void {
        const metricTypeDialogRef = this.dialog.open(ServerCreateDialogComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                subProject: this.adminService.subProject,
                adDomains: this.adminService.adDomains
            }
        });
        this.addSubscription(metricTypeDialogRef.afterClosed().subscribe((choice) => {
            let dialogRef;
            switch (choice) {
                case 'windows':
                    dialogRef = this.dialog.open(DesktopServerCreateComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog-large',
                        data: {
                            adDomains: this.adminService.adDomains,
                            subProject: this.adminService.subProject,
                            desktopServers: this.adminService.desktopServers,
                            project: this.adminService.getProject(this.adminService.subProject.projectId)
                        }
                    });
                    if (dialogRef) {
                        this.addSubscription(dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[]) => {
                            this.desktopServerCallback(result);
                        }));
                    }
                    break;
                case 'linux':
                    dialogRef = this.dialog.open(LinuxServerCreateComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog',
                        data: {
                            subProject: this.adminService.subProject,
                            linuxServers: this.adminService.linuxServers,
                            project: this.adminService.getProject(this.adminService.subProject.projectId)

                        }
                    });
                    if (dialogRef) {
                        this.addSubscription(dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[]) => {
                            this.linuxServerCallback(result);
                        }));
                    }
                    break;
            }

        }));
    }

    linuxServerCallback(result: CrudOperationWrapper[]): void {
        if (result && result.length === 2) {
            const linuxServerOperation = result[0];
            const subProjectOperation = result[1];
            const linuxServer: LinuxServer = linuxServerOperation.data;
            if (linuxServerOperation.operation === 'CREATE') {
                this.adminService.addLinuxServer(linuxServer);
            } else if (linuxServerOperation.operation === 'SAVE') {
                this.adminService.replaceLinuxServer(linuxServer);
            }
            if (subProjectOperation.operation === 'SAVE') {
                this.adminService.subProject = subProjectOperation.data;
            }
            if (subProjectOperation.operation === 'DELETE') {
                this.adminService.removeLinuxServer(linuxServer.id);
                this.adminService.subProject = subProjectOperation.data;
            }
        }
    }

    // onSupportDesktopServer(desktopServer: DesktopServer): void {
    //     this.router.navigate(['/support'], {queryParams: {desktopServerId: desktopServer.id, subProjectId: this.adminService.subProject.id}});
    // }
    //
    // onSupportLinuxServer(linuxServer: LinuxServer): void {
    //     this.router.navigate(['/support'], {queryParams: {linuxServerId: linuxServer.id, subProjectId: this.adminService.subProject.id}});
    // }


    public desktopServerCallback(result: CrudOperationWrapper[]): void {
        if (result && result.length === 2) {
            const desktopServerOperation = result[0];
            const subProjectOperation = result[1];
            const desktopServer: DesktopServer = desktopServerOperation.data;
            if (desktopServerOperation.operation === 'CREATE') {
                this.adminService.addDesktopServer(desktopServer);
            } else if (desktopServerOperation.operation === 'SAVE') {
                this.adminService.replaceDesktopServer(desktopServer);
            }
            if (subProjectOperation.operation === 'SAVE') {
                this.adminService.subProject = subProjectOperation.data;
            }
            if (subProjectOperation.operation === 'DELETE') {
                this.adminService.removeDesktopServer(desktopServer.id);
                this.adminService.subProject = subProjectOperation.data;
            }
        }
    }

}


<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">
            Servidores sem R2 Security
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2 pb-8" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="pb-8" matDialogContent>

        <mat-card class="mt-15">
            <mat-card-content class="padding-none">
                <div *appIsAdmin class="full-width" fxLayout="row" style="padding: 10px 10px 0 10px;"
                     fxLayoutAlign="start center">
                    <div>
                        <mat-form-field class="full-width min-width-450" style="max-width: 450px;"
                                        appearance="outline">
                            <mat-label>Selecionar parceiro</mat-label>
                            <mat-select class="full-width" placeholder="Selecionar parceiro"
                                        [formControl]="partnerCustomerGroupControl" multiple>
                                <mat-option [value]="0" #allSelected (click)="toggleAllSelection()">Todos
                                </mat-option>
                                <mat-option [value]="-2" (click)="tosslePerOne()">Sem parceiro</mat-option>
                                <mat-option
                                    *ngFor="let partnerCustomerGroup of partnerCustomerGroups; let i = index;"
                                    [value]="partnerCustomerGroup.id" (click)="tosslePerOne()">
                                    {{ partnerCustomerGroup.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="ml-20">
                        <mat-form-field class="full-width w-150" appearance="outline">
                            <mat-label>Filtros NAT:</mat-label>
                            <mat-select class="full-width" placeholder="Filtros NAT"
                                        [formControl]="gatewayServerInUseControl">
                                <mat-option [value]="'ALL'">Todos</mat-option>
                                <mat-option [value]="'WITH_NAT'">Com NAT</mat-option>
                                <mat-option [value]="'WITHOUT_NAT'">Sem NAT</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="ml-20">
                        <mat-form-field class="full-width w-150" appearance="outline">
                            <mat-label>Filtros Ativo:</mat-label>
                            <mat-select class="full-width" placeholder="Filtros Ativo"
                                        [formControl]="gatewayServerNatConfigActiveControl">
                                <mat-option [value]="'ALL'">Todos</mat-option>
                                <mat-option [value]="'ACTIVE'">Ativo</mat-option>
                                <mat-option [value]="'INACTIVE'">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="ml-20">
                        <mat-form-field class="full-width w-150" appearance="outline">
                            <mat-label>Servidor:</mat-label>
                            <mat-select class="full-width" placeholder="Servidor"
                                        [formControl]="serverTypeControl">
                                <mat-option [value]="'ALL'">Todos</mat-option>
                                <mat-option [value]="'WINDOWS'">Windows</mat-option>
                                <mat-option [value]="'LINUX'">Linux</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *appIsAdmin class="full-width" fxLayout="row" style="padding: 10px 10px 0 10px;"
                     fxLayoutAlign="space-between center">
                    <div><b>Total:</b>  {{ baseServerFilteredList.length }}</div>
                    <div>
                        <div><b class="w-70" style="display: inline-block;">Com NAT:</b>  {{ amountServerNAT(baseServerFilteredList, true) }}</div>
                        <div><b class="w-70" style="display: inline-block;">Sem NAT:</b>  {{ amountServerNAT(baseServerFilteredList, false) }}</div>
                    </div>
                    <div>
                        <div><b class="w-50" style="display: inline-block;">Ativo:</b> {{ amountServerActive(baseServerFilteredList, true) }}</div>
                        <div><b class="w-50"  style="display: inline-block;">Inativo:</b> {{ amountServerActive(baseServerFilteredList, false) }}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <table *ngIf="(baseServerFilteredList && baseServerFilteredList.length > 0)"
               class="app-table mt-15" border="0" cellspacing="0">
            <thead>
            <tr class="bg-white">
                <th>R2 Security ativo</th>
                <th>Com Nat</th>
                <th class="p-20">Empresa</th>
                <th class="p-20">Hostname</th>
                <th></th>
            </tr>
            </thead>
            <tr *ngFor="let baseServer of baseServerFilteredList">
                <td class="w-30 p-20">
                    <div>
                        <mat-icon class="success-icon" *ngIf="baseServer.gatewayServerNatConfigActive">check_circle
                        </mat-icon>
                        <mat-icon class="error-icon" *ngIf="!baseServer.gatewayServerNatConfigActive">error</mat-icon>
                    </div>
                </td>
                <td class="w-30 p-20">
                    <div *ngIf="findDesktopServerByClientId(baseServer.clientId) as desktopServer" fxLayout="row"
                         fxLayoutAlign="start center">
                        <mat-icon class="success-icon" *ngIf="desktopServerHasNatConfig(desktopServer)">check_circle
                        </mat-icon>
                        <mat-icon class="error-icon" *ngIf="!desktopServerHasNatConfig(desktopServer)">error</mat-icon>
                    </div>

                    <div *ngIf="findLinuxServerByClientId(baseServer.clientId) as linuxServer" fxLayout="row"
                         fxLayoutAlign="start center">
                        <mat-icon class="success-icon" *ngIf="linuxServerHasNatConfig(linuxServer)">check_circle
                        </mat-icon>
                        <mat-icon class="error-icon" *ngIf="!linuxServerHasNatConfig(linuxServer)">error</mat-icon>
                    </div>

                </td>
                <td class="w-210 p-20">
                    <div *ngIf="findDesktopServerByClientId(baseServer.clientId) as desktopServer">
                        <div>{{ customerGroupByDesktopServer(desktopServer).name }}</div>
                    </div>
                    <div *ngIf="findLinuxServerByClientId(baseServer.clientId) as linuxServer">
                        <div>{{ customerGroupByLinuxServer(linuxServer).name }}</div>
                    </div>
                </td>
                <td class="w-210 p-20">
                    <div *ngIf="findDesktopServerByClientId(baseServer.clientId) as desktopServer" fxLayout="row"
                         fxLayoutAlign="start center">
                        <div>
                            <fa-icon class="s-16 windows-icon mr-5" [icon]="faWindows" style="color: #2E8DEF"></fa-icon>
                        </div>
                        <div>{{ desktopServer.hostname }}</div>
                    </div>

                    <div *ngIf="findLinuxServerByClientId(baseServer.clientId) as linuxServer" fxLayout="row"
                         fxLayoutAlign="start center">
                        <div>
                            <fa-icon class="s-16 windows-icon" [icon]="faLinux" style="color: #1E2129"></fa-icon>
                        </div>
                        <div>{{ linuxServer.hostname }}</div>
                    </div>
                </td>
                <td class="text-align-right p-0">
                    <div fxLayout="row" fxLayoutAlign="end start">
                        <div class="mr-10">
                            <div *ngIf="findDesktopServerByClientId(baseServer.clientId) as desktopServer">
                                <button type="button" mat-stroked-button color="primary" class="mat-green-600-bg "
                                        (click)="openProjectCustomerGroup(customerGroupByDesktopServer(desktopServer))"
                                >Abrir
                                </button>
                            </div>
                            <div *ngIf="findLinuxServerByClientId(baseServer.clientId) as linuxServer">
                                <button type="button" mat-stroked-button color="primary" class="mat-green-600-bg "
                                        (click)="openProjectCustomerGroup(customerGroupByLinuxServer(linuxServer))"
                                >Abrir
                                </button>
                            </div>

                        </div>
                    </div>
                </td>
            </tr>
        </table>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

    </div>
</div>
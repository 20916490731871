import {Component, OnInit} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {fuseAnimations} from '../../../../@fuse/animations';
import {R2CloudAdminService} from './r2-cloud-admin.service';
import {CustomerGroup} from '../../models';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: 'app-r2-cloud-admin',
    templateUrl: './r2-cloud-admin.component.html',
    styleUrls: ['./r2-cloud-admin.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminComponent extends ComponentCleaner implements OnInit {
    public customerGroup: CustomerGroup;


    constructor(public adminService: R2CloudAdminService,
                private customerGroupService: CustomerGroupService,
                private route: ActivatedRoute,
                private router: Router
    ) {
        super();
    }

    ngOnInit(): void {

        this.customerGroupService.get().subscribe((customerGroup) => {
            this.customerGroup = customerGroup;
            if (this.customerGroupService.customerGroups && this.customerGroupService.customerGroups.length > 0 && !this.customerGroup) {
                this.route.queryParamMap.subscribe((queryParamMap) => {
                    if (!(queryParamMap && queryParamMap.has('id'))) {
                        if (!this.customerGroupService.customerGroup) {
                            this.router.navigate(['/admin'], {queryParams: {id: this.customerGroupService.customerGroups[0].id}});
                        }
                    }
                });
            }
        });
    }
}

import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {
    DesktopServer,
    DesktopServerCredentials,
    DesktopServerIdWithVersion,
    DesktopServerPrivateKey,
    DesktopServerWithSubProject,
    LocalAdminCredentials,
    ServerPresence
} from '../../models';
import {BaseServerDao} from './base-server-dao';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class DesktopServerDaoService extends BaseServerDao<DesktopServer, DesktopServerPrivateKey> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'desktop-server');
    }

    saveLocalAdminWithoutConfirmation(localAdminCredentials: LocalAdminCredentials): Observable<DesktopServer> {
        return this.http.put<DesktopServer>(this.url + '/save-local-admin-without-confirmation', localAdminCredentials);
    }
    findByAdDomainId(adDomainId: number): Observable<DesktopServer[]> {
        return this.filter({adDomainId: adDomainId});
    }

    getServersOffLine(): Observable<ServerPresence[]> {
        return this.http.get<ServerPresence[]>(this.url + '/servers-offline');
    }

    getServersOnLine(): Observable<ServerPresence[]> {
        return this.http.get<ServerPresence[]>(this.url + '/servers-online');
    }

    getAllDesktopServersWithSubProjectIds(): Observable<DesktopServerWithSubProject[]> {
        return this.http.get<DesktopServerWithSubProject[]>(this.url + '/get-all-desktop-server-with-sub-project-ids');
    }

    getAllDesktopServersManagerUser(managerUserId: number): Observable<DesktopServer[]> {
        return this.http.get<DesktopServer[]>(this.url + '/get-all-desktop-servers-of-manager-user?managerUserId=' + managerUserId);
    }

    getDesktopServerIdWithVersion(desktopServerIds: number[]): Observable<DesktopServerIdWithVersion[]> {
        return this.http.post<DesktopServerIdWithVersion[]>(this.url + '/servers-versions', desktopServerIds);
    }

    getLocalAdmin(desktopServerId: number): Observable<DesktopServerCredentials> {
        return this.http.get<DesktopServerCredentials>(this.url + '/get-local-admin/?desktopServerId=' + desktopServerId);
    }

    getAllByCustomerGroupId(customerGroupId: number): Observable<DesktopServer[]> {
        return this.http.get<DesktopServer[]>(this.url + '/get-all-by-customer-group-id/?customerGroupId=' + customerGroupId);
    }

    findAllSubProjectNames(desktopServerId: number): Observable<string[]> {
        return this.http.get<string[]>(this.url + '/find-all-sub-project-name/' + desktopServerId);
    }

    findAllWithPlugin(subProjectId: number, pluginName: string): Observable<DesktopServer[]> {
        return this.http.get<DesktopServer[]>(this.url + '/find-all-with-plugin/?subProjectId=' + subProjectId + '&pluginName=' + pluginName);
    }

    findAllWithPluginAndHasADUserLogin(subProjectId: number, pluginName: string): Observable<DesktopServer[]> {
        return this.http.get<DesktopServer[]>(
            this.url + '/find-all-with-plugin-and-has-ad-user-login/?subProjectId=' + subProjectId + '&pluginName=' + pluginName
        );
    }

    findAllByDesktopServerPoolIds(desktopServerPoolIds: number[]): Observable<DesktopServer[]> {
        return this.http.post<DesktopServer[]>(
            this.url + '/find-all-by-desktop-server-pool-ids/', desktopServerPoolIds
        );
    }
    getRandomDesktopServerByDesktopServerId(desktopServerId: number, remoteAppId: number): Observable<DesktopServer> {
        return this.http.get<DesktopServer>(
            this.url + '/get-random-desktop-server-by-desktop-server-id/' + desktopServerId + '/' + remoteAppId
        );
    }
    findAllByDesktopServerIds(desktopServerIds: number[]): Observable<DesktopServer[]> {
        return this.http.post<DesktopServer[]>(
            this.url + '/find-all-by-desktop-server-ids/', desktopServerIds
        );
    }
}

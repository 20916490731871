import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched} from '../../../../helpers/kluh';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomPortalTemplate, ImageFileTemplate} from '../../../../models-custom-layout';
import {Observable, of} from 'rxjs';
import {ImageFileTemplateDaoService} from '../../image-file-template/image-file-template-dao.service';
import {CustomPortalTemplateDaoService} from '../custom-portal-template-dao.service';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {ImageFileService} from '../../../image-file/image-file-service';
import {CustomerGroup} from '../../../../models';
import {initCustomPortalTemplate} from '../../custom-layout.service';

@Component({
    selector: 'app-custom-portal-edit',
    templateUrl: './custom-portal-edit.component.html',
    styleUrls: ['./custom-portal-edit.component.scss']
})
export class CustomPortalEditComponent implements OnInit {

    myForm: FormGroup;
    imageFileElement: any;
    isAdmin = false;
    customPortalTemplate: CustomPortalTemplate;
    customerGroup: CustomerGroup;

    constructor(private dialogRef: MatDialogRef<CustomPortalEditComponent>,
                private fb: FormBuilder,
                public imageFileService: ImageFileService,
                private imageFileTemplateDaoService: ImageFileTemplateDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private customPortalTemplateDaoService: CustomPortalTemplateDaoService,
                private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.customerGroup) {
            this.customerGroup = data.customerGroup;
        }
        if (!data.customPortalTemplate) {
            this.customPortalTemplate = initCustomPortalTemplate();
        } else {
            this.customPortalTemplate = JSON.parse(JSON.stringify(data.customPortalTemplate));
        }
        this.myForm = this.fb.group(convertToFormGroup(this.customPortalTemplate));
        this.myForm.get('name').setValidators([Validators.required]);
        this.myForm.get('title').setValidators([Validators.required]);

        this.userAuthorityDaoService.isAdminUser().subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
    }

    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                this.imageFileElement = window.btoa(binaryString);
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.myForm);
        }
    }

    ngOnInit(): void {
    }


    onDelete(customPortalTemplate: CustomPortalTemplate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse tema? ' +
                    '<br><h2>' + customPortalTemplate.name + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'Remover',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.customPortalTemplateDaoService.delete(customPortalTemplate.id).subscribe(() => {
                    const crudOperation: CrudOperationWrapper = {
                        data: customPortalTemplate,
                        operation: 'DELETE'
                    };
                    this.dialogRef.close(crudOperation);
                });
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const customPortalTemplateValue: CustomPortalTemplate = this.myForm.value;
        let imageFileTemplate$: Observable<number>;
        if (this.imageFileElement) {
            const imageFileTemplate: ImageFileTemplate = {
                id: null,
                image: this.imageFileElement,
                createdAt: null,
                updatedAt: null
            };
            imageFileTemplate$ = this.imageFileTemplateDaoService.createOneAndReturnOnlyId(imageFileTemplate);
        } else {
            imageFileTemplate$ = of(0);
        }
        imageFileTemplate$.subscribe((imageFileTemplateId) => {
            if ((imageFileTemplateId && imageFileTemplateId !== 0) || customPortalTemplateValue.logoId != null) {
                if (imageFileTemplateId && imageFileTemplateId !== 0) {
                    customPortalTemplateValue.logoId = imageFileTemplateId;
                }
                let operation: CrudOperation;
                let saveOrCreate$;
                if (customPortalTemplateValue.id) {
                    operation = 'SAVE';
                    saveOrCreate$ = this.customPortalTemplateDaoService.save(customPortalTemplateValue);
                } else {
                    customPortalTemplateValue.creatorCustomerGroupId = this.customerGroup.id;
                    operation = 'CREATE';
                    saveOrCreate$ = this.customPortalTemplateDaoService.create(customPortalTemplateValue);
                }
                saveOrCreate$.subscribe((customPortalTemplate) => {
                    if (customPortalTemplate) {
                        const crudOperation: CrudOperationWrapper = {
                            data: customPortalTemplate,
                            operation: operation
                        };
                        this.dialogRef.close(crudOperation);
                    }
                });
            }
        });
    }
}
